import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaCode: 'ASITLS'
}

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers:{
        setAreaCode(state, action) {
            state.areaCode = action.payload;
            // localStorage.setItem('name', state.userName)
        }
    }
});

export const { setAreaCode } = areaSlice.actions;

export default areaSlice.reducer;