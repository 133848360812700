import React, { useState } from 'react'
import { useNavigate, Link, NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import headerAndFooterJson from '../../Config/headerAndFooter.json'

import './layout.css';
import '../../App.css';
import { store } from '../../store';
import { useSelector } from 'react-redux';

function Footer() {

    const currentYear = new Date().getFullYear(); // 2020

    const previousYear =  currentYear-1;
    const location = useLocation();
    const route = location.pathname;

    let progressState = store.getState();

  let language = progressState?.lang?.lang;

  let selectedLanguage = useSelector((state)=> state?.lang?.lang);


  return (
    <>
       <footer className={` ${route != '/dashboard/data-search-old' && route != '/dashboard/data-analysis' && route != '/dashboard/profile'  ? 'footer_Section' : 'footer_Section footer_dashboard'} ${route != '/dashboard/data-search' && route != '/dashboard/data-search-result' ? '' : 'footer-result'} `}>
            {(route != '/dashboard/data-analysis')  && route != '/dashboard/data-search-old' && route != '/dashboard/data-search' && route != '/dashboard/profile' && route != '/dashboard/data-search-result' &&
                <div className='footer_top'>
                    <div className='custom_container'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <img className='footerLogo' src="assets/lib/images/home/eea_undp_logo.png"/>
                            </div>
                            <div className='col-md-9'>
                            <div className='headerright'>
                                <Navbar expand="lg">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                        {/* <NavLink to='/home' className='nav-link' activeClassName="active">Home</NavLink> */}
                                        <NavLink to='/home' className='nav-link' activeClassName="active">{headerAndFooterJson?.home[selectedLanguage]}</NavLink>

                                        {/* <NavLink to='/about' className='nav-link' activeClassName="active">About</NavLink> */}
                                        <NavLink to='/about' className='nav-link' activeClassName="active">{headerAndFooterJson?.about[selectedLanguage]}</NavLink>

                                        {/* <NavLink to='/information-kiosk'  className='nav-link' activeClassName="active">Information Kiosk</NavLink> */}
                                        <NavLink to='/information-kiosk'  className='nav-link' activeClassName="active">{headerAndFooterJson?.['information-kiosk'][selectedLanguage]}</NavLink>

                                        {/* <NavLink to='/dashboard/data-analysis'  className='nav-link' activeClassName="active">Data Analysis</NavLink> */}
                                        <NavLink to='/dashboard/data-analysis'  className='nav-link' activeClassName="active">{headerAndFooterJson?.['data-analysis'][selectedLanguage]}</NavLink>

                                        {/* <NavLink to='/dashboard/data-search'  className='nav-link' activeClassName="active">Data Search</NavLink>  */}
                                        <NavLink to='/dashboard/data-search'  className='nav-link' activeClassName="active">{headerAndFooterJson?.['data-search'][selectedLanguage]}</NavLink> 

                                        {/* <NavLink to='/dashboard/profile'  className='nav-link' activeClassName="active">Disaster Profile</NavLink>                              */}
                                        <NavLink to='/dashboard/profile'  className='nav-link' activeClassName="active">{headerAndFooterJson?.['disaster-profile'][selectedLanguage]}</NavLink>                             

                                        {/* <NavLink to='/contact'  className='nav-link' activeClassName="active">Contact</NavLink> */}
                                        <NavLink to='/contact'  className='nav-link' activeClassName="active">{headerAndFooterJson?.contact[selectedLanguage]}</NavLink>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                            <ul className='sociallink'>
                                <a href='javascript:void(0)'><img src="assets/lib/images/home/twitter.svg"/></a>
                                <a href='javascript:void(0)'><img src="assets/lib/images/home/facebook.svg"/></a>
                                <a href='javascript:void(0)'><img src="assets/lib/images/home/youtube.svg"/></a>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={route != '/dashboard/data-search-old' && route != '/dashboard/data-analysis' && route != '/dashboard/profile' ? 'footer_bottom' : 'footer_bottom dashboardItm'}>
                <div className={route != '/dashboard/data-search-old' && route != '/dashboard/data-search'  && route != '/dashboard/data-analysis' && route != '/dashboard/profile' && route != '/dashboard/data-search-result' ? 'custom_container' : 'container-fluid'}>
                    <div className='row align-items-center'>
                        <div className='col-md-8 col-sm-8'>
                            <p>{headerAndFooterJson?.copyright?.[selectedLanguage]} © {previousYear}-{currentYear}. {headerAndFooterJson?.['all-rights-reserved']?.[selectedLanguage]}</p>
                        </div>
                        <div className='col-md-4 text-right col-sm-4'>
                            <p className="text-center text-sm-right"><span>
                            {/* Powered by */}
                            {headerAndFooterJson?.poweredBy?.[selectedLanguage]}
                            </span> <a href="http://www.itechmission.org/" target="_blank" className=''>iTech Mission</a></p>
                            {/* <a href="http://www.itechmission.org/" target="_blank"><img src={route != '/dashboard/data-search' && route != '/dashboard/data-analysis' && route != '/dashboard/profile' ? 'assets/lib/images/home/itm_logo.svg' : 'assets/lib/images/home/itmlogo-dashboard.svg'}/></a> */}
                        </div>
                    </div>
                </div>
            </div>
       </footer>
    </>

  );
}

export default Footer;