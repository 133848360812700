
import React, { useEffect, useRef, useState } from "react";


import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Pending } from "@mui/icons-material";



const CustomFilter = (props) => {
 
    const [status, setStatus] = useState(2);
    const [state, setState] = useState({
        statusData:[]
        })



    useEffect(()=>{


        if(props.statusFilter != null && props.statusFilter !=''){

            setState({...state, statusData:props.statusFilter})

        
   
        }

        

    },[props])


    const handleChange = (event) => {
        setStatus(event.target.value);

        console.log(event.target.value)

        props.readCallBack(event.target.value)
      };
    

    return (

        <>
   {state.statusData!=null && state.statusData!='' && 
   <div className="col-md-2 d-flex show_data align-items-center">
    <label className="mb-0">Status</label>
<Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          placeholder="Status"
          onChange={handleChange}
         
        >
           {state.statusData.map((item) => (
            <MenuItem
            
              key={item.value}
              value={item.value}
              
            >
              {item.label}
            </MenuItem>
          ))}
     
        </Select>
      </FormControl>
    </Box>
   </div>
   
   
}
      
        </>


    );


}


export default CustomFilter;