import React from 'react';
import ChartData from '../../../Common/components/echart';


var dashboardTemplate = function () {



  return (
    <>
   <div className='main'>
    <section className='dataSec'>
      <div className='col-lg-12'>
        <div className='row'>
          <div className='col-lg-6'>
            <ChartData/>
          </div>
        </div>
      </div>
    </section>
   </div>
    </>
  );
};

export default dashboardTemplate;
