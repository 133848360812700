


import * as jsx from './summary.module.jsx';
import * as $ from 'jquery';
import "react-toastify/dist/ReactToastify.css";
import AdminService from '../../../services/admin.service';
import { Component } from 'react'

class SummaryPage extends Component {
      constructor() {
          super()
          this.state = {
              filteredData: [],
              searchText: '',
              modal: false,
              data: [],
              adminEmail:[],
              isEdit:false,
              editModeId:null,
              areaList:[],
              parentCallback:[],
              areaData:[],
              data:[],
              format:[],
              createdAt:[],
              dumpsiteLastDate:[],
              indicatorLastDate:[],
              userLastDate:[],
              departmentLastDate:[],
              schemeLastDate:[],
              dataLastDate:[],
              reourceLastdate:[],
              areaLastdate:[],
              manageLastDate:[],
            
              loader:false
          }
      }
      componentDidMount() {
        
            this.getAllAreaData();
        AdminService.checkToken();
        
            
        }
    
  

        getdate(date){
          var d = new Date(date);
                    
          var date = [
           d.getFullYear(),
           ('0' + (d.getMonth() + 1)).slice(-2),
           ('0' + d.getDate()).slice(-2)
         ].join('-');

         return date
        }

      getAllAreaData = async () => {

    
            AdminService.httpGet('/data-retrieval/data/datamanager/data/summary').then((res) => {
              console.log(res.data);
            

                 
                    
              if (res) {
                  let d = res.data;
                
                 
                 
                //  console.log("ssss-->" , this.state.areaData.AreacreatedAt.createdAt);
                 
                  this.setState({ areaData: d })
      
                  console.log('ar',d)
              if(res.data.IuscreatedAt != undefined){
                this.indicatorLastDate = res.data.IuscreatedAt.createdAt; 
                let data =  this.getdate(res.data.IuscreatedAt.createdAt)
                this.setState({ IuscreatedAt: data })
              }
              if(res.data.UsercreatedAt != undefined){
                this.userLastDate = res.data.UsercreatedAt.createdAt;
                let data =  this.getdate(res.data.UsercreatedAt.createdAt)
                this.setState({ UsercreatedAt: data })
              }
              if(res.data.DeptcreatedAt != undefined){
                this.departmentLastDate = res.data.DeptcreatedAt.createdAt;
                let data =  this.getdate(res.data.DeptcreatedAt.createdAt)
                this.setState({ DeptcreatedAt: data })
              }
              if(res.data.DatacreatedAt != undefined){
                this.dataLastDate = res.data.DatacreatedAt.createdAt;
                let data =  this.getdate(res.data.DatacreatedAt.createdAt)
                this.setState({ DatacreatedAt: data })
              }
              if(res.data.ResourcecreatedAt != undefined){
                this.reourceLastdate = res.data.ResourcecreatedAt.createdAt;
                let data =  this.getdate(res.data.ResourcecreatedAt.createdAt)
                this.setState({ ResourcecreatedAt: data })
              }
              
              if(res.data.AreacreatedAt != undefined){
              let data =  this.getdate(res.data.AreacreatedAt.createdAt)
              this.setState({ AreacreatedAt: data })
                // console.log("aaaa=====>" , data) 
                this.areaLastdate = res.data.AreacreatedAt.createdAt;
              }
              if(res.data.FacilitycreatedAt != undefined){
                let data =  this.getdate(res.data.FacilitycreatedAt.createdAt)
                this.setState({ FacilitycreatedAt: data })
                  // console.log("aaaa=====>" , data) 
                  this.dumpsiteLastdate = res.data.FacilitycreatedAt.createdAt;
                }
              if(res.data.manageplancreatedAt != undefined){
                this.manageLastDate = res.data.manageplancreatedAt.createdAt;
                
                let data =  this.getdate(res.data.manageplancreatedAt.createdAt)
                this.setState({ manageplancreatedAt: data })
              }
            }
      
          }).catch((err) => {
              
              console.log('errrr',err)
          });
        
      
      }
       


           
      render() {
            return jsx.default.bind(this)();
        }

}

export default SummaryPage;