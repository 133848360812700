import React, { useEffect, useLayoutEffect, useState } from 'react'

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import $, { isEmptyObject } from 'jquery';
import * as constants from '../../Config/app.constants';
import { json, useSearchParams } from 'react-router-dom';
import { WindowSharp } from '@mui/icons-material';
import adminService from '../../services/admin.service';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import headerAndFooterJson from '../../Config/headerAndFooter.json';

function ForgetModal() {
  // console.log("cccc");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('')
  //   function handleUserInput(event) {
  //     const name = event.target.name;
  //     const value = event.target.value;
  //     this.setState({[name]: value},
  //     () => { this.validateField(name, value)});
  // }

  let language = useSelector((state)=> state?.lang?.lang)

  function createCaptcha(divId) {
    // console.log("calll");
    let captchaElem = document.getElementById('captcha1');
    if(captchaElem != null) {
      captchaElem.innerHTML = "";
    }
    
    // console.log("cheeccckkkckc" , document.getElementById(divId) );
    //clear the contents of captcha div first 
    var charsArray =
      "23456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ@#$%^&*";
    var lengthOtp = 6;
    var captcha = [];

    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }

    var canv = document.createElement("canvas");
    canv.id = divId;
    canv.width = 120;
    canv.height = 46;
    var ctx = canv.getContext("2d");

    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    var imageObj = new Image();
    imageObj.onload = function () {
      //ctx.drawImage(imageObj, 0, 10);
      //ctx.rotate(Math.PI * 2 / (i * 5));
      //ctx.font = "40pt Calibri";
      //ctx.fillText("My TEXT!", 20, 20);
      ctx.font = "25px Mulish";


      ctx.beginPath();
      ctx.moveTo(0, 30);
      //ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 2;
      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(0, 65);
      //ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 2;

      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();
      canv.setAttribute('dir', 'ltr');


      ctx.strokeText(captcha.join(""), 15, 40);
    };

    imageObj.src = 'assets/lib/images/captchabg.png';

    if (divId == 'captchaForgot') {
      // this.setState({ forgotCaptchaCode: captcha.join("") });
    }
    else {
      // this.setState({ captchaCode: captcha.join("") });
    }
    let divEl = document.getElementById(divId);
    if(divEl != null) {
      divEl.appendChild(canv);
    }

    // console.log("ccc--" , canv);
    // adds the canvas to the body element

  }
  // setTimeout(()=>{

  // } , 200)




  // }, );
  // })



  async function forgotPassword() {

    console.log("dsgdfS");
    const data = {
      email: email
    }

    adminService.httpUserPost('/data-import/users/forgetPasswordMail', data).then((res)=>{
       if(res.success === true){
          setShow(false);
  
          swal({
            title: 'A mail is sent to your email ID to reset your password.',
            html: '<img src="assets/lib/images/icon/registration-icon.svg">',
            icon: "warning",
            className: "swal-back",
            showCancelButton: false,
          }) 
       }
       else {
        toast.error(res.message)
       }
    }) .catch(error => {
      toast.error(error.message)
    })





    // console.log("email---" , email);
    // const data = await fetch(constants.server + "api/data-retrieval/users/submit-forgot-password-form", {

    //   method: 'post',


    //   body: JSON.stringify({ "email": email }),


    // })
    // const res = await data.json()
    // console.log(res);
    // alert(res.message)
    // console.log("cccc");


    //  .then((res) => {
    //     console.log("resss", res)
    //     if (res.status == 1) {

    //       this.toast.success(this.translate.instant('Email_sent_successfully'));
    //       this.forgetPasswordForm.reset();

    //       $('#forgotPassword').hide();

    //     }
    //     else {
    //       this.toastr.error(this.translate.instant('invalid_login'));
    //       $('#cpatchaForgotTextboxrellax').val('');
    //       this.toastr.error(res.data);
    //       createCaptcha('captcha1');
    //       this.forgetPasswordForm.reset();
    //       $('#forgotPassword').hide();
    //     }
    //   })

  }





  function handleClose() {
    setShow(false);
  }

  const changeEmail = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const handleShow = () => {
    setShow(true);

    $(document).ready(function () {
      $('#homeUserProfile').trigger('click');
    })


  }

  useEffect(() => {
    return () => {
      createCaptcha('captcha1')
    };
  }, [show])


  return (
    <>

      <a variant="primary" className="pointer fog-pass " onClick={handleShow}>
      {/* Forgot Password ? */}
      {headerAndFooterJson.forgotPassword[language]}
      </a>

      <Modal show={show} onHide={handleClose}
        centered
        className='forgot_popup'>
        <Modal.Header closeButton>
          <Modal.Title>
          {/* Forgot password */}
          {headerAndFooterJson.forgotPassword[language]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div id="MainForgetBody">

            <div className="form-group">
              <label className="mat_lables">Email</label>
              <div className="form-field">

                <input className="form-control" value={email} onChange={(e) => { changeEmail(e) }} autoComplete="off" maxLength="50"></input>
              </div>
            </div>
            <div className="form-group-captcha no-gutters row position-relative ">
              {/* <div id="captchaForgot" className="col-xs-4 plr-0"></div> */}

              <div className="col-md">
                <div id="captcha1" ></div>
              </div>
              <div className="col-md-auto text-center">
                <button id="createCaptcha"
                  onClick={() => createCaptcha('captcha1')}
                  type="button" className="btn  refresh-btn">
                  <img src="assets/lib/images/refresh-icon.svg" className="img-fluid" alt="" />
                </button>
              </div>

              <div className="col-md captcha_input">
                <fieldset>

                  <input className="captcha-position" placeholder="captcha" name="captcha"
                    id="cpatchaForgotTextboxrellax" autoComplete="off"
                    maxLength="6" />

                </fieldset>
                <div className="invalid-cap"></div>

              </div>
            </div>
          </div>

          <div className="form-group colored">

            <button className="login-b btn btn-primary w-100" style={{
              'color': '#fff !important', 'backgroundColor': '#1c3156 !important',
              'borderColor': '#3b63ab !important', 'display': 'flex !important', 'justifyContent': 'center !important',
              'borderRadius': '10px !important'
            }}
              type="submit" onClick={forgotPassword} color="warn">
              {/* Submit */}
              {headerAndFooterJson.submit[language]}
              </button>


          </div>



        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>

    </>
  )

}


export default ForgetModal;