import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import domtoimage from "dom-to-image";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import * as $ from "jquery";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "@mui/material/Button";
import DataTable from "react-data-table-component";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import ExploreVisualiseModal from "../data-search/explore-visualise-modal";
import dataSearchResultLang from '../../../Config/data-search-results-component.json';

import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "@mui/material/Tooltip";
import { width } from "@mui/system";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import geoJSONData2 from "../../../Config/map/json/TLS_L02_2019.json";
import geoJSONData3 from "../../../Config/map/json/TLS_L03_2019.json";
import geoJSONData4 from "../../../Config/map/json/TLS_L04_2019.json";
import { Switch } from "@mui/material";
import { Link } from "@material-ui/core";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DataSearchResultChart(props) {
  // console.log('drs_sources',props)
  const formatUtil = echarts.format;
  const chart = useRef(null);
  const { data, type } = props;

  const [title, setTitle] = useState("");
  const [showData, setShowData] = useState([]);
  const lang = useSelector((state) => state.lang.lang);
  const [loader, setLoader] = useState(false);
  const [swap, setSwap] = useState(false);

  const [noData,setNoData] = useState(false)
  // =============================================================
  const [disaster, setDisaster] = useState("");
  const [form, setForm] = useState("");
  const [indicator, setIndicator] = useState("");
  const [timeperiod, setTimeperiod] = useState("");
  const [source, setSource] = useState("");
  const [zoomData, setZoomData] = useState(1.25);
  const [isCompass, setIsCompass] = useState(false);
  const [dat, setDat] = useState([]);
  const [srcList, setSourceList] = useState([]);
  // =============================================================
  let chartInstance = null;

  const [label, setLabel] = useState(false);
  const [legend, setLegend] = useState([]);

  const [mapState, setMapState] = useState({
    uniDisasters: [],
    uniForms: [],
    uniIndicators: [],
    uniNewIndicators: [],
    uniTP: [],
    uniSrc: [],
    uniStage: [],
  });

  const toggleCompass = () => {
    if (isCompass) {
      setIsCompass(false);
    } else {
      setIsCompass(true);
    }
  };

  const defOption = {
    title: {
      show: true,
      textStyle: {
        color: "#333",
        fontSize: 14,
        fontFamily: "Mulish",
        fontWeight: "400",
      },
      text: "Loading...",
      left: "center",
      top: "center",
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    series: [],
  };

  const [optionD, setOptionD] = useState(defOption);
  const [uCase, setUCase] = useState(null);

  useEffect(() => {
    let uc = getUseCase();
    setUCase(uc);
    renderChart(uc, type);
  }, [dat, props.type]);

  useEffect(()=> {
    let srcList = [];
    data.map((x)=> {
      if(!srcList.includes(x.source)) {
        srcList.push(x.source);
      }
    });
    setDat(data.filter(x => x.source == srcList[0]))
    setSourceList(srcList);
    if(srcList?.length > 0) {
      setSource(srcList[0]);
    }
  }, [data])

  useEffect(()=> {
    console.log("Sudhanshu", data);
    setDat(data.filter(x => x.source == source))
  }, [source])

  useEffect(() => {
    if (props.type != "map") {
      let image = document.getElementById("label");

      image.src = "assets/lib/images/home/label_on.svg";
    }
  }, [props.type]);

  const getUseCase = () => {
    
    let [ind, unit, subgroup, area, timeperiod] = ["", "", "", "", ""];
    let [cind, cunit, csubgroup, carea, ctimeperiod] = [0, 0, 0, 0, 0];
    let d = [];

    let dis = [];
    let stage = [];
    let forms = {};
    let inds = [];
    let newInds = {};
    let tperiods = {};
    let areas = [];
    let src = {};
    for (let row of dat) {
      // console.log("Sudhanshu row", row);
      // console.log("Sudhanshu row.subgroup", row.subgroup);

      if (ind == "") {
        ind = row.disaster + "-" + row.stage + "-" + row.indicator;
        cind += 1;
      } else if (ind != row.disaster + "-" + row.stage + "-" + row.indicator) {
        cind = cind <= 1 ? cind + 1 : cind;
      }

      if (unit == "") {
        unit = row.unit;
        cunit += 1;
      } else if (unit != row.unit) {
        cunit = cunit <= 1 ? cunit + 1 : cunit;
      }

      if (subgroup == "") {
        subgroup = row.subgroup;
        csubgroup += 1;
      } else if (subgroup != row.subgroup) {
        csubgroup = csubgroup <= 1 ? csubgroup + 1 : csubgroup;
      }

      if (area == "") {
        area = row.element.areaDetails?.name?.[lang];
        carea += 1;
      } else if (area != row.element.areaDetails?.name?.[lang]) {
        carea = carea <= 1 ? carea + 1 : carea;
      }

      if (timeperiod == "") {
        timeperiod = row.timeperiod;
        ctimeperiod += 1;
      } else if (timeperiod != row.timeperiod) {
        ctimeperiod = ctimeperiod <= 1 ? ctimeperiod + 1 : ctimeperiod;
      }

      d.push({
        disaster: row.disaster ? row.disaster : dataSearchResultLang?.["baseline-data"][this.props.lang],
        stage: row.stage ? row.stage : dataSearchResultLang?.["baseline-data"][this.props.lang],
        area: row.area,
        area_code: row.area_code,
        area_level: row.area_level,
        parent_id: row.parent_id,
        indicator: row.indicator,
        unit: row.unit,
        subgroup: row.subgroup,
        timeperiod: row.timeperiod,
        value: row.datavalue,
        source: row.source,
      });

      let y = areas?.filter((a) => {
        return a.area_code == row.area_code;
      });
      if (y.length == 0) {
        areas.push({
          area_code: row.area_code,
          area: row.area,
        });
      }

      if (!dis?.includes(row.disaster)) {
        dis.push(row.disaster);
        forms[row.disaster] = [];
      }

      if (!stage?.includes(row.stage)) {
        stage.push(row.stage);
      }

      if (!forms[row.disaster]?.includes(row.stage)) {
        forms[row.disaster].push(row.stage);
        newInds[row.disaster+'-'+row.stage] = [];
      }
      if (
        !inds?.includes(row.indicator + " | " + row.unit + " | " + row.subgroup)
      ) {
        inds.push(row.indicator + " | " + row.unit + " | " + row.subgroup);
      }
      let x = newInds[row.disaster+'-'+row.stage].filter(y=> (y.indicator == row.indicator && y.unit == row.unit && y.subgroup == row.subgroup));
      if(x.length == 0) {
        newInds[row.disaster+'-'+row.stage].push({
          indicator: row.indicator,
          unit: row.unit,
          subgroup: row.subgroup,
        });
        tperiods[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup] = [];
      }
      if (!tperiods[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup]?.includes(row.timeperiod)) {
        tperiods[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup].push(row.timeperiod);
        src[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup+'-'+row.timeperiod] = [];
      }
      if (!src[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup+'-'+row.timeperiod]?.includes(row.source)) {
        src[row.disaster+'-'+row.stage+row.indicator + "-" + row.unit + "-" + row.subgroup+'-'+row.timeperiod].push(row.source);
      }
      
    }

    setShowData(d);
    setMapState({
      uniDisasters: dis,
      uniForms: forms,
      uniIndicators: inds,
      uniNewIndicators: newInds,
      uniTP: tperiods,
      uniSrc: src,
      uniStage: stage,
    });
    setDisaster(dis?.[0]);
    setForm(forms?.[dis?.[0]]?.[0]);
    setIndicator(newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]);
    setTimeperiod(tperiods?.[dis?.[0]
                    +'-'+forms?.[dis?.[0]]?.[0]
                        +newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.indicator
                    +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.unit
                    +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.subgroup]?.[0]);
    
    setSource(src[
          dis?.[0]
          +'-'+forms?.[dis?.[0]]?.[0]
          +newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.indicator
      +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.unit
      +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.subgroup
      +'-'+tperiods?.[dis?.[0]
          +'-'+forms?.[dis?.[0]]?.[0]
              +newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.indicator
          +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.unit
          +'-'+newInds?.[dis?.[0]+'-'+forms?.[dis?.[0]]?.[0]]?.[0]?.subgroup]?.[0]]?.[0]);
    
      if (cind && cunit && csubgroup && carea && ctimeperiod) {
      return useCaseMatrix[cind][cunit][csubgroup][carea][ctimeperiod];
    }
    return null;
  };

  const ChartlabelNev = () => {
    let image = document.getElementById("label");

    if (image.getAttribute("src") === "assets/lib/images/home/label_on.svg") {
      image.src = "assets/lib/images/home/label-off.svg";
    } else {
      image.src = "assets/lib/images/home/label_on.svg";
    }

    const chartInstance = echarts.getInstanceByDom(chart.current);
    if (chartInstance) {
      let option = optionD;
      let newSeries = [];
      option?.series?.forEach((seri) => {
        seri.label = {
          show: !seri.label.show,
          position: type == "treemap" ? "center" : "top",
          formatter: function (params) {
            let val = (+params.value).toLocaleString();
            if(type == "treemap") {
              return `${params.data.name} \n ${val}`;
            }
            return val;
          },
        };
        newSeries.push(seri);
      });
      option.series = newSeries;
      chartInstance.clear();
      setOptionD(option);
      chartInstance.setOption(option);
    }
  };

  const renderChart = (useCase, type, swap = false) => {
    setSwap(swap);
    const renderInstance = echarts.getInstanceByDom(chart.current);
    if (renderInstance) {
      chartInstance = renderInstance;
    } else {
      chartInstance = echarts.init(chart.current);
    }
    setTitle(getTitle(useCase?.useCase, type));
    let optionData = getOptionData(useCase?.useCase, type, swap);
    setOptionD(optionData);

    // let elem = chart.current?.getEchartsInstance();
    chartInstance.clear();
    chartInstance.setOption(optionData);
  };

  const downloadChart = () => {
    
    setLoader(true);
    console.log("This is the download button!!");
    $("div#og").css({'height': 'auto', 'padding': '10px', 'width': '100%'});
    $(".drsIndicators_img").hide();
    $(".dsrBarchart_list").hide();
    $(".legends, .filter, .content_arrow ").hide();
    $(".drs_sources").hide();
    $(".mapSource-hide").addClass('d-block');
    $(".multiple-value.drsChartss").css('flex-direction', 'column')
    $(".dsrChart .maps_source").css('position', 'unset')
    $(".dsrDropdown").addClass("dsrdropDownload")
    $(".legendsNumber.legendsChart").css({ 'bottom': '40px', 'left': '10px'});
    // mapSource-hide

    // $(".dropdown-toggle").hide();

    let node1 = document.getElementById("og");
    let node2 = document.getElementById("copy");
    let mapSource = document.getElementById("downloadchartds"); 
    
    mapSource.style.display = "block !important";
    console.log("mapSource",mapSource);
    node2.innerHTML = node1.innerHTML;
    node2.style.display = "block";

    let div = $(".downloadChartBG");
    div.css("background-color : white");

    domtoimage.toBlob(node1).then((blob) => {
      saveFile(blob);
      $(".dsrBarchart_list").show();
      $(".drsIndicators_img").show();
      $(".legends, .filter, .content_arrow ").show();
      $(".drs_sources").show();
      $(".multiple-value.drsChartss").css('flex-direction', '')
      $('.downloadchartds').css('display', 'block');
      $(".mapSource-hide").removeClass('d-block');
      $(".dsrChart .maps_source").css('position', 'absolute')
      $("div#og").css({'height': '100%', 'padding': '', 'width': ''});
      $(".dsrDropdown").removeClass("dsrdropDownload");
      $(".legendsNumber.legendsChart").css({ 'bottom': '20px', 'left': '0px'});
      setLoader(false);

      // $(".dropdown-toggle").show();
      // $('.countryName').css('display', 'none');
      node2.style.display = "none";
    });
  };

  const saveFile = async (blob) => {
    const a = document.createElement("a");
    const dt = Date.now();
    a.download = `TERS_${title}_${dt}.png`;
    a.href = URL.createObjectURL(blob);
    a.click();
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  };

  // const showLabel = (e) => {
  //   console.log("", e)
  //   if (label) {
  //     setLabel(false);
  //     // $(".countryLabel").addClass("show");
  //     // $(".img-label").attr("src", "assets/lib/images/home/label-off.svg");
  //   } else {
  //     setLabel(true);
  //     // $(".countryLabel").removeClass("show");
  //     // $(".img-label").attr("src", "assets/lib/images/home/label_on.svg");
  //   }
  // };

  const showLabel = () => {
    if (label === true) {
      setLabel(false);
    } else {
      setLabel(true);
    }
  };

  useEffect(() => {
    renderChart(uCase, type);
  }, [label]);

  const getTitle = (useCase, type) => {
    console.log("This is showdata console--", showData);
    let title = "";
    let disaster = mapState.uniDisasters.length <= 1 ? showData?.[0]?.disaster + " - ": "";
    let stage = mapState.uniStage.length <= 1 ? showData?.[0]?.stage + " - ": "";
    if (
      type == "line" ||
      type == "bar" ||
      type == "column" ||
      type == "funnel"
    ) {
      switch (useCase) {
        case "UC1":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC2":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit;
          break;
        case "UC3":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC4":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC5":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;

        case "UC6":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC7":
          title = showData?.[0]?.indicator + " | " + showData?.[0]?.unit;
          break;
        case "UC8":
          title = showData?.[0]?.unit + " | " + showData?.[0]?.subgroup;
          break;
        case "UC9":
          title = showData?.[0]?.subgroup;
          break;
        case "UC10":
          title = "";
          break;
        case "UC11":
          title = showData?.[0]?.indicator + " | " + showData?.[0]?.subgroup;
          break;
        case "UC12":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC13":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC14":
          title =
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC15":
          title =
            showData?.[0]?.indicator + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC16":
          title =
            showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC17":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit;
          break;
        case "UC18":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC19":
          title =
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC20":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC21":
          title = showData?.[0]?.indicator;
          break;
        case "UC22":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC23":
          title = showData?.[0]?.area + " - " + showData?.[0]?.indicator;
          break;
        case "UC24":
          title =
            showData?.[0]?.indicator + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC25":
          title = showData?.[0]?.unit + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC26":
          title = showData?.[0]?.unit;
          break;
        case "UC27":
          title = showData?.[0]?.area + " - " + showData?.[0]?.unit;
          break;
        case "UC28":
          title = showData?.[0]?.area + " - " + showData?.[0]?.subgroup;
          break;
        case "UC29":
          title =
            showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC30":
          title = showData?.[0]?.timeperiod;
          break;
        case "UC31":
          title = showData?.[0]?.timeperiod;
          break;
        case "UC32":
          title = showData?.[0]?.area;
          break;
      }
    } else if (
      type == "pie" ||
      type == "doughnut" ||
      type == "treemap" ||
      type == "radar"
    ) {
      switch (useCase) {
        case "UC1":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC2":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC3":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC4":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC5":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.unit;
          break;
        case "UC6":
          title = "";
          // title = showData?.[0]?.disaster + "-" + showData?.[0]?.stage;
          break;
        case "UC7":
          title = showData?.[0]?.indicator + " | " + showData?.[0]?.unit;
          break;
        case "UC8":
          title = showData?.[0]?.unit + " | " + showData?.[0]?.subgroup;
          break;
        case "UC9":
          title = showData?.[0]?.subgroup;
          break;
        case "UC10":
          title = "";
          // title = showData?.[0]?.disaster + "-" + showData?.[0]?.stage;

          break;
        case "UC11":
          title = showData?.[0]?.indicator + " | " + showData?.[0]?.subgroup;
          break;
        case "UC12":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC13":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC14":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC15":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.indicator +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC16":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC17":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.indicator;
          break;
        case "UC18":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup;
          break;
        case "UC19":
          title =
            showData?.[0]?.unit +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC20":
          title =
            showData?.[0]?.area +
            " - " +
            showData?.[0]?.unit +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC21":
          title = showData?.[0]?.indicator;
          break;
        case "UC22":
          title =
            showData?.[0]?.indicator +
            " | " +
            showData?.[0]?.subgroup +
            " (" +
            showData?.[0]?.timeperiod +
            ")";
          break;
        case "UC23":
            title = showData?.[0]?.area + " - " + showData?.[0]?.indicator;
          break;
        case "UC24":
          title =
            showData?.[0]?.indicator + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC25":
          title = showData?.[0]?.unit + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC26":
          title = showData?.[0]?.unit;
          break;
        case "UC27":
          title = showData?.[0]?.area + " - " + showData?.[0]?.unit;
          break;
        case "UC28":
          title = showData?.[0]?.area + " - " + showData?.[0]?.subgroup;
          break;

        case "UC29":
          title =
            showData?.[0]?.subgroup + " (" + showData?.[0]?.timeperiod + ")";
          break;
        case "UC30":
          title = showData?.[0]?.area + " (" + showData?.[0]?.timeperiod + ")";
          break;

        case "UC31":
          title = showData?.[0]?.timeperiod;
          break;
        case "UC32":
          title = showData?.[0]?.area;
          break;
      }
    }

    title = disaster + stage + title;
    return title;
  };

  const getOptionData = (useCase, type, swap) => {
    let labelData = label;

    let oData = {
      title: {
        show: true,
        textStyle: {
          color: "#333",
          fontSize: 14,
          fontFamily: "Mulish",
          fontWeight: "400",
        },
        // text: "No Data",
        text: `${dataSearchResultLang.noData[lang]}`,
        left: "center",
        top: "center",
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        show: false,
      },
      series: [],
      source: "",
    };

    if (type == "map") {
      let dataToShow = showData?.filter(
        (x) =>
          x.disaster == disaster &&
          x.stage == form &&
          indicator.indicator == x.indicator &&
          indicator.unit == x.unit &&
          indicator.subgroup == x.subgroup &&
          x.timeperiod == timeperiod &&
          x.source == source
      );

      let dataMap = [];
      let nameMap = {};
      let min = 0;
      let max = 0;
      let minLevel = 5;
      let maxLevel = 0;
      for (let i = 0; i < dataToShow?.length; i++) {
        if (!Object.keys(nameMap).includes(dataToShow[i].area_code)) {
          if (minLevel > dataToShow[i].area_level) {
            minLevel = dataToShow[i].area_level;
          }
          if (maxLevel < dataToShow[i].area_level) {
            maxLevel = dataToShow[i].area_level;
          }
          dataMap.push({
            value: +dataToShow[i].value,
            name: dataToShow[i].area,
            code: dataToShow[i].area_code,
            level: dataToShow[i].area_level,
            // parent_id: dataToShow[i].parent_id,
          });
          nameMap[dataToShow[i].area_code] = dataToShow[i].area;
          if (max < +dataToShow[i].value) {
            max = +dataToShow[i].value;
          }
          if (min > +dataToShow[i].value) {
            min = +dataToShow[i].value;
          }
        }
      }

      if (dataMap?.length == 0) {
        setNoData(true);
        return oData;
      }
      setNoData(false);
      if(minLevel == 1 && maxLevel == 1) {
        return {
          title: {
            show: true,
            textStyle: {
              color: "#333",
              fontSize: 14,
              fontFamily: "Mulish",
              fontWeight: "400",
            },
            text: "Please select sub-national areas to view map",
            left: "center",
            top: "center",
          },
          xAxis: {
            show: false,
          },
          yAxis: {
            show: false,
          },
          series: [],
          source: "",
        };
      }

      let d = getLegendData(dataMap);

      let geoJSONData = null;
      let newCoords = [];
      if (minLevel < 3) {
        geoJSONData = JSON.parse(JSON.stringify(geoJSONData2));

        let smallerregions3 = dataMap?.filter((x) => x.level == 3);
        smallerregions3.map((item) => {
          let x = geoJSONData3?.features?.filter((geo) => {
            return item.code === geo?.properties?.["ID_"];
          });
          x.map((geo) => {
            newCoords.push(geo);
          });
        });
        let smallerregions4 = dataMap?.filter((x) => x.level == 4);
        smallerregions4.map((item) => {
          let x = geoJSONData4?.features?.filter((geo) => {
            return item.code === geo?.properties?.["ID_"];
          });
          x.map((geo) => {
            newCoords.push(geo);
          });
        });
        geoJSONData.features.push(...newCoords);
      } else {
        // let newCoords = [];

        dataMap?.map((item) => {
          let x = null;
          if (item.level == 3) {
            x = geoJSONData3?.features?.filter((geo) => {
              return item.code === geo?.properties?.["ID_"];
            });
          } else {
            x = geoJSONData4?.features?.filter((geo) => {
              return item.code === geo?.properties?.["ID_"];
            });
          }
          x.map((geo) => {
            newCoords.push(geo);
          });
        });
        if (newCoords?.length == 0) {
          return oData;
        }
        geoJSONData = { type: "FeatureCollection", features: newCoords };
      }

      echarts.registerMap("TLS", geoJSONData);

      return {
        // title: {
        //   text: title,
        // },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return (
              params.name +
              "\n" +
              (isNaN(params.value) ? " " : params.value)
            );
          },
        },
        visualMap: {
          show: false,
          min: min,
          max: max,
          pieces: d.legends,
          orient: "vertical",
          showLabel: true,
          text: [indicator.unit],
          textStyle: {
            color: "#333",
            fontSize: 12,
          },

          formatter: legendFormatter,
        },
        // geo: {
        //   map: "TLS",
        //   regions: [
        //     {
        //       name: "North Pole",
        //       itemStyle: {
        //         normal: {
        //           areaColor: "#ff0000", // Adjust the fill color of the North Pole symbol
        //         },
        //       },
        //       label: {
        //         show: true,
        //         position: "insideBottom",
        //         distance: 10,
        //         color: "#ffffff", // Adjust the label color of the North Pole symbol
        //       },
        //       value: 1,
        //     },
        //   ],
        // },
        series: [
          {
            name: "Timor-Leste",
            type: "map",
            roam: true,
            map: "TLS",
            zoom: zoomData,
            label: {
              show: labelData,
              formatter: function (params) {
                return (
                  params.name +
                  "\n" +
                  (isNaN(params.value) ? " " : params.value)
                );
              },
            },
            emphasis: {
              disabled: true,
              // label: {
              //   show: false,
              // },
              // itemStyle: {
              //   areaColor: "none",
              // },
            },
            select: {
              disabled: true
            },
            data: dataMap,
            nameMap: nameMap,
            // title:showData?.[0]?.unit
          },
        ],
      };
    }

    if (useCase == "UC1") {
      oData = {
        title: {
          show: true,
          textStyle: {
            color: "#333", //'#B8372B',
            fontSize: 50,
            fontFamily: "Mulish",
            fontWeight: "400",
          },
          text: (+showData?.[0]?.value).toLocaleString(), //(+result.data?.[0]?.new_value)?.toLocaleString(),
          left: "center",
          top: "center",
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [],
        source: "",
      };
      return oData;
    }

    if (type == "line" || type == "bar" || type == "funnel") {
      if (useCase == "UC2" || useCase == "UC12") {
        //TP(multiple) on x-axis and Subgroup on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
        if (swap) {
          showData.forEach((row) => {
            let sgName = row.timeperiod;

            let tp = row.subgroup;
            if (useCase == "UC12") {
              tp = row.unit;
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = row.timeperiod;

            let sgName = row.subgroup;
            if (useCase == "UC12") {
              sgName = row.unit;
            }
            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              colorBy: "data",
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (
        useCase == "UC3" ||
        useCase == "UC7" ||
        useCase == "UC8" ||
        useCase == "UC9" ||
        useCase == "UC10" ||
        useCase == "UC11" ||
        useCase == "UC13"
      ) {
        //Area(multiple) on x-axis and others on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = row.area;
            if (useCase != "UC3" && useCase != "UC13") {
              sgName = sgName + "-" + row.timeperiod;
            }

            let tp;
            if (useCase == "UC8") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"") + row.indicator;
            } else if (useCase == "UC9") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"") + row.indicator + "-" + row.unit;
            } else if (useCase == "UC10") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit + "-" + row.subgroup;
            } else if (useCase == "UC11" || useCase == "UC13") {
              tp = row.unit;
            } else {
              tp = row.subgroup;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }

            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = row.area;
            if (useCase != "UC3" && useCase != "UC13") {
              tp = tp + "-" + row.timeperiod;
            }

            let sgName;
            if (useCase == "UC8") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            } else if (useCase == "UC9") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit;
            } else if (useCase == "UC10") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit + "-" + row.subgroup;
            } else if (useCase == "UC11" || useCase == "UC13") {
              sgName = row.unit;
            } else {
              sgName = row.subgroup;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }

            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              colorBy: "data",
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (useCase == "UC4") {
        //TP(multiple) on x-axis and Area on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = row.timeperiod;

            let tp = row.area;

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = row.timeperiod;

            let sgName = row.area;

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            console.log("This is vData--", vData);
            seri.push({
              name: sg,
              data: vData[sg],
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (useCase == "UC5") {
        //if subgroup is multiple and on x-axis and value on y-axis
        let sgArr = [];
        let vData = [];
        let legendData = [];
        let colorPalette = [
          "#A6170A",
          "#ED9C94",
          "#CF655B",
          "#B8372B",
          "#7D1B12",
          "#440A05",
        ];
        //let tpName = "";
        if (swap) {
          showData.forEach((row) => {
            sgArr.push(row.value);
            vData.push(row.subgroup);
          });
        } else {
          showData.forEach((row) => {
            sgArr.push(row.subgroup);
            vData.push(row.value);
          });
        }

        if (type == "line" || type == "bar") {
          oData = {
            legend: {
              orient: "vertical",
              top: "5%",
              right: 0,
              bottom: 0,
              itemGap: 14,
              type: "scroll",
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              show: false,
              textStyle: {
                color: "#333",
              },
            },
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // bottom: "3%",
              top: "10%",
              y2: 30,
            },
            xAxis: [
              {
                type: "category",
                data: sgArr,
                axisLabel: {
                  color: "#333",
                  width: 100,
                  lineHeight: 14,
                  overflow: "truncate",
                  rotate: -40,
                  interval: 0
                },
                axisLine: {
                  lineStyle: {
                    color: "#333",
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  color: "#333",
                },
                splitLine: {
                  lineStyle: {
                    color: "rgba(255, 255, 255, 0.7)",
                    type: "dashed",
                  },
                },
              },
            ],
            series: [
              {
                name: "Value",
                data: vData,
                colorBy: "data",
                type: type,
                label: {
                  show: false,
                  position: "top",
                  formatter: function (params) {
                    return (+params.value).toLocaleString();
                  },
                },
                emphasis: {
                  focus: "series",
                },
                lineStyle: {
                  color: "#A6170A",
                  width: 1,
                },
                itemStyle: {
                  color: function (params) {
                    var dataIndex = params.dataIndex;
                    return colorPalette[dataIndex % colorPalette.length];
                  },
                  // borderColor: colorPalette[i]
                },
              },
            ],
          };
        } else if (type == "funnel") {
          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgArr,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: [
              {
                name: "Value",
                data: vData,
                type: type,
                left: "10%",
                top: 60,
                bottom: 60,
                width: "80%",
                // min: 0,
                // max: 100,
                minSize: "0%",
                maxSize: "100%",
                sort: "ascending",
                gap: 2,
                label: {
                  show: false,
                  position: "inside",
                  formatter: function (params) {
                    return (+params.value).toLocaleString();
                  },
                },
                emphasis: {
                  focus: "series",
                },
                lineStyle: {
                  color: "#A6170A",
                  //color: this.colors[i],
                  width: 1,
                },
                itemStyle: {
                  borderWidth: 1,
                  borderColor: "#A6170A",
                  color: "#A6170A",
                  //borderColor: this.colors[i],
                  //color: this.colors[i]
                },
              },
            ],
          };
        }
      } else if (useCase == "UC6") {
        //if subgroup is multiple, on x-axis and area on y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
        if (swap) {
          showData.forEach((row) => {
            let tp = row.subgroup;
            let sgName = row.area;

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let sgName = row.subgroup;
            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }
            let tp = row.area;

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }
        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          tpArr.forEach((tp, i) => {
            seri.push({
              name: tp,
              data: vData[tp],
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: tpArr,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: sgNames,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          tpArr.forEach((tp, i) => {
            seri.push({
              name: tp,
              data: vData[tp],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: tpArr,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (
        useCase == "UC14" ||
        useCase == "UC15" ||
        useCase == "UC16" ||
        useCase == "UC22"
      ) {
        //tp(Indicator/US/IU multiple) on x-axis and sg(Area) on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
        if (swap) {
          showData.forEach((row) => {
            let sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            if (useCase == "UC14") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            } else if (useCase == "UC15") {
              sgName = row.unit + "-" + row.subgroup;
            } else if (useCase == "UC16") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit;
            } else if (useCase == "UC22") {
              sgName = row.unit;
            }

            let tp = row.area;

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            if (useCase == "UC14") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            } else if (useCase == "UC15") {
              tp = row.unit + "-" + row.subgroup;
            } else if (useCase == "UC16") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit;
            } else if (useCase == "UC22") {
              tp = row.unit;
            }

            let sgName = row.area;
            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              color: colorPalette,
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (
        useCase == "UC17" ||
        useCase == "UC18" ||
        useCase == "UC19" ||
        useCase == "UC20"
      ) {
        //tp(Subgroup/Indicator multiple) on x-axis and sg(timeperiod/Area/Subgroup) on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = row.subgroup;
            if (useCase != "UC17") {
              sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            }

            let tp = row.timeperiod;
            if (useCase == "UC19") {
              tp = row.area;
            } else if (useCase == "UC20") {
              tp = row.subgroup;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = row.subgroup;
            if (useCase != "UC17") {
              tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            }

            let sgName = row.timeperiod;
            if (useCase == "UC19") {
              sgName = row.area;
            } else if (useCase == "UC20") {
              sgName = row.subgroup;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (useCase == "UC21" || useCase == "UC23" || useCase == "UC24") {
        //tp(US multiple) on x-axis and sg(Area/TP) on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = row.unit + "-" + row.subgroup;

            let tp = row.area + "-" + row.timeperiod;
            if (useCase == "UC23") {
              tp = row.area;
            } else if (useCase == "UC24") {
              tp = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = row.unit + "-" + row.subgroup;

            let sgName = row.area + "-" + row.timeperiod;
            if (useCase == "UC23") {
              sgName = row.area;
            } else if (useCase == "UC24") {
              sgName = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }
        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i],
                borderColor: colorPalette[i],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (
        useCase == "UC25" ||
        useCase == "UC26" ||
        useCase == "UC27" ||
        useCase == "UC28" ||
        useCase == "UC29"
      ) {
        //tp(IU multiple) on x-axis and sg(timeperiod/Area) on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            if (useCase == "UC25" || useCase == "UC26" || useCase == "UC27") {
              sgName = sgName + "-" + row.subgroup;
            } else if (useCase == "UC28" || useCase == "UC29") {
              sgName = sgName + "-" + row.unit;
            }

            let tp = row.area;
            if (useCase == "UC26") {
              tp = tp + "-" + row.timeperiod;
            } else if (useCase == "UC27" || useCase == "UC28") {
              tp = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            if (useCase == "UC25" || useCase == "UC26" || useCase == "UC27") {
              tp = tp + "-" + row.subgroup;
            } else if (useCase == "UC28" || useCase == "UC29") {
              tp = tp + "-" + row.unit;
            }

            let sgName = row.area;
            if (useCase == "UC26") {
              sgName = sgName + "-" + row.timeperiod;
            } else if (useCase == "UC27" || useCase == "UC28") {
              sgName = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      } else if (useCase == "UC30" || useCase == "UC31" || useCase == "UC32") {
        //tp(IUS multiple) on x-axis and sg(timeperiod/Area) on Y-axis
        let tpArr = [];
        let vData = {};
        let d = {};
        let sgNames = [];
        let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];

        if (swap) {
          showData.forEach((row) => {
            let sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit + "-" + row.subgroup;

            let tp = row.area;
            if (useCase == "UC32") {
              tp = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        } else {
          showData.forEach((row) => {
            let tp = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "-" + row.unit + "-" + row.subgroup;

            let sgName = row.area;
            if (useCase == "UC32") {
              sgName = row.timeperiod;
            }

            if (!sgNames.includes(sgName)) {
              sgNames.push(sgName);
            }

            if (!tpArr.includes(tp)) {
              tpArr.push(tp);
            }
            if (!(tp in d)) {
              d[tp] = {};
            }
            d[tp][sgName] = row.value;
          });
        }

        for (let t of tpArr) {
          for (let sg of sgNames) {
            if (sg in vData) {
              vData[sg].push(d[t][sg]);
            } else {
              vData[sg] = [d[t][sg]];
            }
          }
        }

        let seri = [];

        if (type == "line" || type == "bar") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              colorBy: "data",
              type: type,
              label: {
                show: false,
                position: "top",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                color: colorPalette[i % colorPalette.length],
                borderColor: colorPalette[i % colorPalette.length],
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              orient: "vertical",
              top: "5%",
              // icon: 'rect',
              right: 0,
              bottom: 0,
              // height: 'auto',
              itemGap: 14,
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "0%",
              right: "18%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            xAxis: {
              type: "category",
              data: tpArr,
              axisLabel: {
                color: "#333",
                width: 100,
                lineHeight: 14,
                overflow: "truncate",
                rotate: -40,
                interval: 0
              },
              axisLine: {
                lineStyle: {
                  color: "#333",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#333",
              },
              splitLine: {
                lineStyle: {
                  color: "#333",
                  type: "dashed",
                },
              },
            },
            toolbox: {
              show: false,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: true },
                magicType: { show: true, type: ["bar", "line"] },
                // restore: { show: true },
                saveAsImage: { show: true, title: "Download" },
              },
            },
            series: seri,
          };
        } else if (type == "funnel") {
          sgNames.forEach((sg, i) => {
            seri.push({
              name: sg,
              data: vData[sg],
              type: type,
              left: "10%",
              top: 60,
              bottom: 60,
              width: "80%",
              // min: 0,
              // max: 100,
              minSize: "0%",
              maxSize: "100%",
              sort: "ascending",
              gap: 2,
              label: {
                show: false,
                position: "inside",
                formatter: function (params) {
                  return (+params.value).toLocaleString();
                },
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                color: "#A6170A",
                //color: this.colors[i],
                width: 1,
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: "#A6170A",
                color: "#A6170A",
                //borderColor: this.colors[i],
                //color: this.colors[i]
              },
            });
          });

          oData = {
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "cross",
              },
            },
            legend: {
              data: sgNames,
              // orient: 'vertical',
              // top: 'center',
              // icon: 'rect',
              // right: 0,
              bottom: 0,
              // height: 'auto',
              type: "scroll",
              show: true,
              formatter: function (name) {
                return name.length > 17 ? name.substring(0, 17) + "..." : name;
              },
              tooltip: {
                show: true,
                trigger: "item",
              },
              textStyle: {
                color: "#333",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              // height: 'auto',
              // bottom: '3%',
              top: "10%",
              y2: 30,
            },
            series: seri,
          };
        }
      }
    } else if (type == "pie") {
      let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
      if (
        useCase == "UC2" ||
        useCase == "UC3" ||
        useCase == "UC5" ||
        useCase == "UC14"
      ) {
        //1-dimension multiple
        let iusArr = [];
        let vData = [];
        // let legendData = [];
        let tpName = "";
        showData.forEach((row) => {
          if (useCase == "UC2") {
            iusArr.push(row.timeperiod);
          } else if (useCase == "UC3") {
            iusArr.push(row.area);
          } else if (useCase == "UC5") {
            iusArr.push(row.subgroup);
          } else if (useCase == "UC14") {
            iusArr.push((mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator);
          }

          vData.push(row.value);
          // tpName = row.time_period?.start_time_period;
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            itemGap: 14,
            data: iusArr,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              // radius: '75%',
              center: ["40%", "50%"],
              data: vData,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      } else if (
        useCase == "UC4" ||
        useCase == "UC6" ||
        useCase == "UC12" ||
        useCase == "UC13" ||
        useCase == "UC15" ||
        useCase == "UC16" ||
        useCase == "UC17" ||
        useCase == "UC18" ||
        useCase == "UC19" ||
        useCase == "UC20" ||
        useCase == "UC22" ||
        useCase == "UC23" ||
        useCase == "UC24"
      ) {
        //2-dimensions are multiple

        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC6") {
            sgName = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC12") {
            sgName = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC13") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC15") {
            sgName = row.subgroup;
            tp = row.unit;
          } else if (useCase == "UC16") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.unit;
          } else if (useCase == "UC17") {
            sgName = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC18") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.timeperiod;
          } else if (useCase == "UC19") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.area;
          } else if (useCase == "UC20") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.subgroup;
          } else if (useCase == "UC22") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.area;
          }
          legendData.push(sgName + "-" + tp);
          seri.push({
            name: sgName + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            itemGap: 14,
            bottom: 20,
            data: legendData,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              // radius: '55%',
              center: ["40%", "50%"],
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      } else if (
        useCase == "UC7" ||
        useCase == "UC8" ||
        useCase == "UC9" ||
        useCase == "UC10" ||
        useCase == "UC11" ||
        useCase == "UC21" ||
        useCase == "UC25" ||
        useCase == "UC26" ||
        useCase == "UC27" ||
        useCase == "UC28" ||
        useCase == "UC29" ||
        useCase == "UC30" ||
        useCase == "UC31" ||
        useCase == "UC32"
      ) {
        //3+ dimensions are multiple
        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.subgroup;
          let area = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC8") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC9") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC10") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC11") {
            sgName = row.area;
            area = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC21") {
            sgName = row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC25") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC26") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC27") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC28") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.timeperiod;
          } else if (useCase == "UC29") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.area;
          } else if (useCase == "UC30") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.subgroup;
          } else if (useCase == "UC31") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC32") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.timeperiod;
          }
          legendData.push(sgName + "-" + area + "-" + tp);
          seri.push({
            name: sgName + "-" + area + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            itemGap: 14,
            data: legendData,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              // radius: '55%',
              center: ["40%", "50%"],
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      }
    } else if (type == "doughnut") {
      let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
      if (
        useCase == "UC2" ||
        useCase == "UC3" ||
        useCase == "UC5" ||
        useCase == "UC14"
      ) {
        //1-dimension multiple
        let iusArr = [];
        let vData = [];
        // let legendData = [];
        let tpName = "";
        showData.forEach((row) => {
          if (useCase == "UC2") {
            iusArr.push(row.timeperiod);
          } else if (useCase == "UC3") {
            iusArr.push(row.area);
          } else if (useCase == "UC5") {
            iusArr.push(row.subgroup);
          } else if (useCase == "UC14") {
            iusArr.push((mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator);
          }

          vData.push(row.value);
          // tpName = row.time_period?.start_time_period;
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            itemGap: 14,
            data: iusArr,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["40%", "70%"],
              //   center: ['40%', '50%'],
              data: vData,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      } else if (
        useCase == "UC4" ||
        useCase == "UC6" ||
        useCase == "UC12" ||
        useCase == "UC13" ||
        useCase == "UC15" ||
        useCase == "UC16" ||
        useCase == "UC17" ||
        useCase == "UC18" ||
        useCase == "UC19" ||
        useCase == "UC20" ||
        useCase == "UC22" ||
        useCase == "UC23" ||
        useCase == "UC24"
      ) {
        //2-dimensions are multiple

        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC6") {
            sgName = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC12") {
            sgName = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC13") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC15") {
            sgName = row.subgroup;
            tp = row.unit;
          } else if (useCase == "UC16") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.unit;
          } else if (useCase == "UC17") {
            sgName = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC18") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.timeperiod;
          } else if (useCase == "UC19") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.area;
          } else if (useCase == "UC20") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.subgroup;
          } else if (useCase == "UC22") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.area;
          }
          legendData.push(sgName + "-" + tp);
          seri.push({
            name: sgName + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            itemGap: 14,
            data: legendData,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["40%", "70%"],
              // center: ['40%', '50%'],
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      } else if (
        useCase == "UC7" ||
        useCase == "UC8" ||
        useCase == "UC9" ||
        useCase == "UC10" ||
        useCase == "UC11" ||
        useCase == "UC21" ||
        useCase == "UC25" ||
        useCase == "UC26" ||
        useCase == "UC27" ||
        useCase == "UC28" ||
        useCase == "UC29" ||
        useCase == "UC30" ||
        useCase == "UC31" ||
        useCase == "UC32"
      ) {
        //3+ dimensions are multiple
        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.subgroup;
          let area = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC8") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC9") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC10") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC11") {
            sgName = row.area;
            area = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC21") {
            sgName = row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC25") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC26") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC27") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC28") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.timeperiod;
          } else if (useCase == "UC29") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.area;
          } else if (useCase == "UC30") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.subgroup;
          } else if (useCase == "UC31") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC32") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.timeperiod;
          }
          legendData.push(sgName + "-" + area + "-" + tp);
          seri.push({
            name: sgName + "-" + area + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            itemGap: 14,
            data: legendData,
            formatter: function (name) {
              return name.length > 17 ? name.substring(0, 34) + "..." : name;
            },
            tooltip: {
              show: true,
              trigger: "item",
            },
            textStyle: {
              color: "#333",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["40%", "70%"],
              // center: ['40%', '50%'],
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
      }
    } else if (type == "treemap") {
      let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
      if (
        useCase == "UC2" ||
        useCase == "UC3" ||
        useCase == "UC5" ||
        useCase == "UC14"
      ) {
        //1-dimension multiple
        let iusArr = [];
        let vData = [];
        // let legendData = [];
        let tpName = "";
        showData.forEach((row) => {
          if (useCase == "UC2") {
            tpName = row.timeperiod;
          } else if (useCase == "UC3") {
            tpName = row.area;
          } else if (useCase == "UC5") {
            tpName = row.subgroup;
          } else if (useCase == "UC14") {
            tpName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
          }

          vData.push({ name: tpName, value: row.value });
          // tpName = row.time_period?.start_time_period;
        });

        oData = {
          tooltip: {
            formatter: function (info) {
              var value = info.value;
              var treePathInfo = info.treePathInfo;
              var treePath = [];
              for (var i = 1; i < treePathInfo.length; i++) {
                treePath.push(treePathInfo[i].name);
              }
              return [
                '<div class="tooltip-title">' +
                  formatUtil.encodeHTML(treePath.join("/")) +
                  "</div>",
                formatUtil.addCommas(value),
              ].join("");
            },
          },
          series: [
            {
              name: "",
              type: type,
              label: {
                show: false,
                formatter: function (params) {
                  let val = (+params.value).toLocaleString();
                  return `${params.data.name}, ${val}`;
                },
              },
              data: vData,
              color: colorPalette,

              breadcrumb: {
                show: true,
              },
              levels: [
                {
                  itemStyle: {
                    borderWidth: 0,
                    gapWidth: 5,
                  },
                },
                {
                  itemStyle: {
                    gapWidth: 1,
                  },
                },
                {
                  colorSaturation: [0.35, 0.5],
                  itemStyle: {
                    gapWidth: 1,
                    borderColorSaturation: 0.6,
                  },
                },
              ],
            },
          ],
        };
      } else if (
        useCase == "UC4" ||
        useCase == "UC6" ||
        useCase == "UC12" ||
        useCase == "UC13" ||
        useCase == "UC15" ||
        useCase == "UC16" ||
        useCase == "UC17" ||
        useCase == "UC18" ||
        useCase == "UC19" ||
        useCase == "UC20" ||
        useCase == "UC22" ||
        useCase == "UC23" ||
        useCase == "UC24"
      ) {
        //2-dimensions are multiple

        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC6") {
            sgName = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC12") {
            sgName = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC13") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC15") {
            sgName = row.subgroup;
            tp = row.unit;
          } else if (useCase == "UC16") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.unit;
          } else if (useCase == "UC17") {
            sgName = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC18") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.timeperiod;
          } else if (useCase == "UC19") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.area;
          } else if (useCase == "UC20") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.subgroup;
          } else if (useCase == "UC22") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.area;
          }
          // legendData.push(sgName+'-'+tp);
          seri.push({
            name: sgName + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            formatter: function (info) {
              var value = info.value;
              var treePathInfo = info.treePathInfo;
              var treePath = [];
              for (var i = 1; i < treePathInfo.length; i++) {
                treePath.push(treePathInfo[i].name);
              }
              return [
                '<div class="tooltip-title">' +
                  formatUtil.encodeHTML(treePath.join("/")) +
                  "</div>",
                formatUtil.addCommas(value),
              ].join("");
            },
          },
          series: [
            {
              name: "",
              type: type,
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              breadcrumb: {
                show: true,
              },
            },
          ],
        };
      } else if (
        useCase == "UC7" ||
        useCase == "UC8" ||
        useCase == "UC9" ||
        useCase == "UC10" ||
        useCase == "UC11" ||
        useCase == "UC21" ||
        useCase == "UC25" ||
        useCase == "UC26" ||
        useCase == "UC27" ||
        useCase == "UC28" ||
        useCase == "UC29" ||
        useCase == "UC30" ||
        useCase == "UC31" ||
        useCase == "UC32"
      ) {
        //3+ dimensions are multiple
        let legendData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.subgroup;
          let area = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC8") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC9") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC10") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC11") {
            sgName = row.area;
            area = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC21") {
            sgName = row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC25") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC26") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC27") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC28") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.timeperiod;
          } else if (useCase == "UC29") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.area;
          } else if (useCase == "UC30") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.subgroup;
          } else if (useCase == "UC31") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC32") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.timeperiod;
          }
          // legendData.push(sgName+'-'+area+'-'+tp);
          seri.push({
            name: sgName + "-" + area + "-" + tp,
            value: row.value,
          });
        });

        oData = {
          tooltip: {
            formatter: function (info) {
              var value = info.value;
              var treePathInfo = info.treePathInfo;
              var treePath = [];
              for (var i = 1; i < treePathInfo.length; i++) {
                treePath.push(treePathInfo[i].name);
              }
              return [
                '<div class="tooltip-title">' +
                  formatUtil.encodeHTML(treePath.join("/")) +
                  "</div>",
                formatUtil.addCommas(value),
              ].join("");
            },
          },
          series: [
            {
              name: "",
              type: type,
              data: seri,
              color: colorPalette,
              label: {
                show: false,
              },
              breadcrumb: {
                show: true,
              },
            },
          ],
        };
      }
    } else if (type == "radar") {
      let colorPalette = ["#B8372B", "#CF655B", "#ED9C94", "#FFCBC6"];
      if (
        useCase == "UC2" ||
        useCase == "UC3" ||
        useCase == "UC5" ||
        useCase == "UC14"
      ) {
        //1-dimension multiple
        let iusArr = [];
        let vData = [];
        // let legendData = [];
        let tpName = "";
        let max = 0;
        showData.forEach((row) => {
          if (useCase == "UC2") {
            iusArr.push({ name: row.timeperiod });
          } else if (useCase == "UC3") {
            iusArr.push({ name: row.area });
          } else if (useCase == "UC5") {
            iusArr.push({ name: row.subgroup });
          } else if (useCase == "UC14") {
            iusArr.push({ name: (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator });
          }
          max = +row.value > max ? +row.value : max;
          vData.push(row.value);
          // tpName = row.time_period?.start_time_period;
        });

        iusArr = iusArr.map((ele) => {
          return { max: max, name: ele.name };
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          radar: {
            // shape: 'circle',
            indicator: iusArr,
          },
          // legend: {
          //     type: 'scroll',
          //     orient: 'vertical',
          //     right: 10,
          //     top: 20,
          //     bottom: 20,
          //     data: iusArr,
          //     formatter: function (name) {
          //       return name.length > 17?name.substring(0, 17) + '...':name;
          //     },
          //     tooltip: {
          //       show: true,
          //       trigger: 'item'
          //     },
          //     textStyle: {
          //       color: "#fff"
          //     }
          // },
          series: [
            {
              name: "",
              type: type,
              label: {
                show: false,
              },
              data: [
                {
                  value: vData,
                },
              ],
            },
          ],
        };
      } else if (
        useCase == "UC4" ||
        useCase == "UC6" ||
        useCase == "UC12" ||
        useCase == "UC13" ||
        useCase == "UC15" ||
        useCase == "UC16" ||
        useCase == "UC17" ||
        useCase == "UC18" ||
        useCase == "UC19" ||
        useCase == "UC20" ||
        useCase == "UC22" ||
        useCase == "UC23" ||
        useCase == "UC24"
      ) {
        //2-dimensions are multiple

        let legendData = [];
        let vData = [];
        let max = 0;
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC6") {
            sgName = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC12") {
            sgName = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC13") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC15") {
            sgName = row.subgroup;
            tp = row.unit;
          } else if (useCase == "UC16") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.unit;
          } else if (useCase == "UC17") {
            sgName = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC18") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.timeperiod;
          } else if (useCase == "UC19") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.area;
          } else if (useCase == "UC20") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            tp = row.subgroup;
          } else if (useCase == "UC22") {
            sgName = row.area;
            tp = row.unit;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC23") {
            sgName = row.unit + "|" + row.subgroup;
            tp = row.area;
          }
          legendData.push({ name: sgName + "-" + tp });
          max = +row.value > max ? +row.value : max;
          vData.push(row.value);
          // tpName = row.time_period?.start_time_period;
        });

        legendData = legendData.map((ele) => {
          return { max: max, name: ele.name };
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          radar: {
            // shape: 'circle',
            indicator: legendData,
          },
          // legend: {
          //     type: 'scroll',
          //     orient: 'vertical',
          //     right: 10,
          //     top: 20,
          //     bottom: 20,
          //     data: iusArr,
          //     formatter: function (name) {
          //       return name.length > 17?name.substring(0, 17) + '...':name;
          //     },
          //     tooltip: {
          //       show: true,
          //       trigger: 'item'
          //     },
          //     textStyle: {
          //       color: "#fff"
          //     }
          // },
          series: [
            {
              name: "",
              type: type,
              label: {
                show: false,
              },
              data: [
                {
                  value: vData,
                },
              ],
            },
          ],
        };
      } else if (
        useCase == "UC7" ||
        useCase == "UC8" ||
        useCase == "UC9" ||
        useCase == "UC10" ||
        useCase == "UC11" ||
        useCase == "UC21" ||
        useCase == "UC25" ||
        useCase == "UC26" ||
        useCase == "UC27" ||
        useCase == "UC28" ||
        useCase == "UC29" ||
        useCase == "UC30" ||
        useCase == "UC31" ||
        useCase == "UC32"
      ) {
        //3+ dimensions are multiple
        let legendData = [];
        let max = 0;
        let vData = [];
        let seri = [];
        showData.forEach((row) => {
          let sgName = row.subgroup;
          let area = row.area;
          let tp = row.timeperiod;
          if (useCase == "UC8") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC9") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC10") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC11") {
            sgName = row.area;
            area = row.timeperiod;
            tp = row.unit;
          } else if (useCase == "UC21") {
            sgName = row.unit + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC25") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC26") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.subgroup;
            area = row.area;
            tp = row.timeperiod;
          } else if (useCase == "UC27") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.subgroup;
            tp = row.timeperiod;
          } else if (useCase == "UC28") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.timeperiod;
          } else if (useCase == "UC29") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.area;
          } else if (useCase == "UC30") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator;
            area = row.unit;
            tp = row.subgroup;
          } else if (useCase == "UC31") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.area;
          } else if (useCase == "UC32") {
            sgName = (mapState?.uniDisasters?.length > 1? row.disaster + "-":"") + (mapState.uniStage?.length > 1? row.stage + "-":"")+ row.indicator + "|" + row.unit;
            area = row.subgroup;
            tp = row.timeperiod;
          }

          legendData.push({ name: sgName + "-" + area + "-" + tp });
          max = +row.value > max ? +row.value : max;
          vData.push(row.value);
          // tpName = row.time_period?.start_time_period;
        });

        legendData = legendData.map((ele) => {
          return { max: max, name: ele.name };
        });

        oData = {
          tooltip: {
            trigger: "item",
          },
          radar: {
            // shape: 'circle',
            indicator: legendData,
          },
          // legend: {
          //     type: 'scroll',
          //     orient: 'vertical',
          //     right: 10,
          //     top: 20,
          //     bottom: 20,
          //     data: iusArr,
          //     formatter: function (name) {
          //       return name.length > 17?name.substring(0, 17) + '...':name;
          //     },
          //     tooltip: {
          //       show: true,
          //       trigger: 'item'
          //     },
          //     textStyle: {
          //       color: "#fff"
          //     }
          // },
          series: [
            {
              name: "",
              type: type,
              label: {
                show: false,
              },
              data: [
                {
                  value: vData,
                },
              ],
            },
          ],
        };
      }
    }

    return oData;
  };

  
  useEffect(() => {
    renderChart(uCase, type);
  }, [disaster, form, indicator, timeperiod, source, zoomData]);

  const onChange = (val, key) => {
    debugger;
    let ind = '';
    let tp = '';
    switch (key) {
      case "disaster":
        setDisaster(val);
        setForm(mapState.uniForms?.[val][0]);
        ind = mapState.uniNewIndicators?.[val+'-'+mapState.uniForms?.[val][0]][0];
        setIndicator(ind);
        tp = mapState.uniTP?.[val+'-'+mapState.uniForms?.[val][0]+ind.indicator+'-'+ind.unit+'-'+ind.subgroup][0];
        setTimeperiod(tp);
        setSource(mapState.uniSrc?.[val+'-'+mapState.uniForms?.[val][0]+ind.indicator+'-'+ind.unit+'-'+ind.subgroup+'-'+tp][0]);
        break;
      case "form":
        setForm(val);
        ind = mapState.uniNewIndicators?.[disaster+val][0];
        setIndicator(ind);
        tp = mapState.uniTP?.[disaster+'-'+val+ind.indicator+'-'+ind.unit+'-'+ind.subgroup][0];
        setTimeperiod(tp);
        setSource(mapState.uniSrc?.[disaster +'-'+ val+ind.indicator+'-'+ind.unit+'-'+ind.subgroup+'-'+tp][0]);
        break;
      case "indicator":
        setIndicator(val);
        tp = mapState.uniTP?.[disaster+'-'+form+val.indicator+'-'+val.unit+'-'+val.subgroup][0];
        setTimeperiod(tp);
        setSource(mapState.uniSrc?.[disaster+'-'+form+val.indicator+'-'+val.unit+'-'+val.subgroup+'-'+tp][0]);
        break;
      case "timeperiod":
        setTimeperiod(val);
        setSource(mapState.uniSrc?.[disaster+'-'+form+indicator.indicator+'-'+indicator.unit+'-'+indicator.subgroup+'-'+val][0]);
        break;
      case "src":
        setSource(val);
        break;
    }
  };

  const zoomin = () => {
    if (zoomData < 5) {
      setZoomData(zoomData + 0.2);
    }
  };

  const zoomout = () => {
    if (zoomData > 1) {
      setZoomData(zoomData - 0.2);
    }
  };

  const reset = () => {
    setZoomData(1.25);
  };

  const legendFormatter = (value) => {
    if (isNaN(value)) {
      return "No Data";
    }
    return value;
  };

  const onSwap = (temp) => {
    renderChart(uCase, type, temp);
    let image = document.getElementById("label");
    image.src = "assets/lib/images/home/label_on.svg";
  };

  const getLegendData = (mapData) => {
    
    if (mapData != null && mapData.length > 0) {
      let valueData = [];
      // let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];
      // ["#D2D2D2", "#ED9C94", "#CF655B", "#B8372B", "#FFCBC6"],
      let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];
      // console.log("areaCode:", props.areaCode);
      let areaObj = mapData?.filter((o) => o.code == "ASITLS");

      // setTotal(areaObj?.[0]);
      // setDupTotal(areaObj?.[0]);

      mapData?.forEach((item) => {
        if (item.code != "ASITLS") {
          valueData.push(+item.value);
        }
      });
      let s = new Set(valueData);
      let unicValData = [...s];

      unicValData.sort(function (a, b) {
        return a - b;
      });

      let unitVal = 1;

      var finalLegends = [];
      var collectionAreaData = [];
      if (unicValData?.length >= 4) {
        unitVal = Math.floor(unicValData?.length / 4);
      }
      let j = 0;
      let k = 0;
      let from = unicValData[0];
      let to = unicValData[0];
      if(from == null) {
        return { legends: finalLegends, collection: collectionAreaData };
      }
      for (let i = 0; i < unicValData.length; i++) {
        if (j++ >= unitVal && k < 3) {
          j = 0;

          let obj = {
            color: colors[k++],
            label: "" + from + " - " + to,
            min: from,
            max: to,
          };
          from = to + 1;

          finalLegends.push(obj);
        }

        let valMapData = mapData?.filter(
          (item) => item.value == unicValData[i] && item.code != "ASITLS"
        );
        valMapData.forEach((item) => {
          let obj = {
            color: colors[k],
            value: item.value,
            area: item.area,
            code: item.code,
          };
          collectionAreaData.push(obj);
        });
        to = unicValData[i];
      }

      let obj = {
        color: colors[k++],
        label: "" + from + " - " + to,
        min: from,
        max: to,
      };
      finalLegends.push(obj);
      // finalLegends.push({
      //   color: "#FFCBC6",
      //   label: "No Data",
      //   value: NaN,
      //   symbol: 'circle'
      // });

      // this.setState({ legends: finalLegends, collection: collectionAreaData })

      console.log("finalLegends", finalLegends);
      console.log("collectionAreaData", collectionAreaData);
      setLegend(finalLegends);
      return { legends: finalLegends, collection: collectionAreaData };
    }
    return null;
  };

  return (
    <>
      <div id="og" className={type == "map" ? "position-relative" : ""}>
        <div
          className={
            type == "map" ? "dsr_barchart dsr_Mapchart" : "dsr_barchart"
          }
        >
          {type == "map" && (
            <div className="dsrDropdown">
              <ul>
                <li>
                  {/* <span>Disaster</span> */}
                  <span>{dataSearchResultLang.Disaster[lang]}</span>

                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {disaster}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {mapState?.uniDisasters?.map((x) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              onChange(x, "disaster");
                            }}
                          >
                            {x}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  {/* <span>Form</span> */}
                  <span>{dataSearchResultLang.Form[lang]}</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {form}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {mapState?.uniForms?.[disaster]?.map((x) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              onChange(x, "form");
                            }}
                          >
                            {x}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  {/* <span>Indicator</span> */}
                  <span>{dataSearchResultLang.Indicator[lang]}</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {indicator?.indicator}{" "}
                      <span>
                        {" | " + indicator?.unit + " | " + indicator?.subgroup}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {mapState?.uniNewIndicators?.[disaster+'-'+form]?.map((x) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              onChange(x, "indicator");
                            }}
                          >
                            {x.indicator}{" "}
                            <span>{" | " + x.unit + " | " + x.subgroup}</span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  {/* <span>Time Period</span> */}
                  <span>{dataSearchResultLang.time_period[lang]}</span>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {timeperiod}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {mapState?.uniTP?.map((x) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              onChange(x, "timeperiod");
                            }}
                          >
                            {x}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {timeperiod}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {mapState?.uniTP?.[disaster+'-'+form+indicator?.indicator+'-'+indicator?.unit+'-'+indicator?.subgroup]
                      ?.sort((a, b) => b.localeCompare(a)) // Sort the array in descending order
                      .map((x) => (
                        <Dropdown.Item
                          onClick={(e) => {
                            onChange(x, "timeperiod");
                          }}
                        >
                          {x}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>

                </li>
              </ul>
            </div>
          )}

          <div className="dsrBarchart_section">
            <div className="dsrBarchart_list">
              <ul>
                {props.type !== "map" && (
                  <li
                    onClick={(e) => {
                      ChartlabelNev();
                    }}
                  >
                    <a href="javascript:void(0);">
                      <img
                        id="label"
                        src="assets/lib/images/home/label_on.svg"
                      />
                    </a>
                  </li>
                )}

                {props.type !== "map" &&
                  props.type !== "pie" &&
                  props.type !== "doughnut" &&
                  props.type !== "radar" &&
                  props.type !== "treemap" && (
                    <li
                      onClick={(e) => {
                        onSwap(!swap);
                      }}
                    >
                      <a href="javascript:void(0);">
                        <img src="assets/lib/images/home/resets.svg" />
                      </a>
                    </li>
                  )}
                {/* <li>
                  <a href="javascript:void(0);">
                    <img src="assets/lib/images/home/setting.svg" />
                  </a>
                </li> */}
                <li
                  onClick={(e) => {
                    downloadChart();
                  }}
                >
                  <a href="javascript:void(0);">
                    <img src="assets/lib/images/home/download.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="drsIndicators">
            <div className="drsIndicators_text">
              {(props.type != "map") && (<span>{title}</span>)}
            </div>
          </div>
        </div>
        <div
          className={
            showData?.length == 1
              ? "single-value drsChartss dsrChartBox"
              : "multiple-value drsChartss dsrChartBox"
          }
        >
          <div
            ref={chart}
            style={{
              height: "calc(100% - 39px)",
              width: "calc(100vw - 110px)",
            }}
            className={type == "map" ? "chartsdsr chartdsrMap" : "chartsdsr"}
          />

          {type == "map" && (
            <>
              {isCompass == true && (
                <span class="comapss datasearchCompass">
                  <img src="assets/map/compass.svg" />
                </span>
              )}
              <div className="legends">
                <ul>
                  <li>
                    <Tooltip title={dataSearchResultLang?.["zoomIn"]?.[lang]} placement="right">
                      <a
                        style={{ cursor: "pointer" }}
                        to=""
                        onClick={(e) => zoomin()}
                      >
                        <img src="assets/lib/images/home/zoom-in.svg" />
                      </a>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title={dataSearchResultLang?.["zoomOut"]?.[lang]} placement="right">
                      <a
                        style={{ cursor: "pointer" }}
                        to=""
                        onClick={(e) => zoomout()}
                      >
                        <img src="assets/lib/images/home/zoom-out.svg" />
                      </a>
                    </Tooltip>
                  </li>
                  <li className="wmtm_reset">
                    <Tooltip title={dataSearchResultLang?.["reset"]?.[lang]} placement="right">
                      <a
                        style={{ cursor: "pointer" }}
                        to=""
                        onClick={(e) => reset()}
                      >
                        <img src="assets/lib/images/home/reset.svg" />
                      </a>
                    </Tooltip>
                  </li>
                  <li>
                    {label ? (
                      <Tooltip title={dataSearchResultLang?.["labelOff"]?.[lang]} placement="right">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => showLabel(e)}
                        >
                          <img
                            src="assets/lib/images/home/label_on.svg"
                            //  src="assets/lib/images/home/label-off.svg"
                            alt="onof"
                            className="img-label"
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title={dataSearchResultLang?.["labelOn"]?.[lang]} placement="right">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => showLabel(e)}
                        >
                          <img
                            // src="assets/lib/images/home/label-off.svg"
                             src="assets/lib/images/home/label_on.svg"
                            alt="on"
                            className="img-label"
                          />
                        </a>
                      </Tooltip>
                    )}
                  </li>
                  <li>
                    <Tooltip title={dataSearchResultLang?.["northPole"]?.[lang]} placement="right">
                      <a
                        to=""
                        style={{ cursor: "pointer" }}
                        onClick={(e) => toggleCompass()}
                      >
                        <img src="assets/lib/images/home/north_pole.svg" />
                      </a>
                    </Tooltip>
                  </li>
                </ul>
              </div>
              { !noData &&
               <div class="legendsNumber legendsChart">
               <p> {indicator?.unit}</p>
               <ul>
               {
                legend?.map((l, i) => {
                  return (<li>
                  <span class={"lnColor"+(i+1)}>

                  </span>{l.label}</li>
                  )
                })
               }
               
               <li> 
               <span class="lnColor5">
                 {/* No Data */}
              
               </span> {dataSearchResultLang.noData[lang]}
               </li>
               </ul>
               </div>
              }

              <div className="drs_sources">
                {/* <span>Source</span> */}
                {dataSearchResultLang.Source[lang]}
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {source}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {mapState?.uniSrc?.[disaster+'-'+form+indicator?.indicator+'-'+indicator?.unit+'-'+indicator?.subgroup+'-'+timeperiod]
                      ?.map((x) => {
                      return (
                        <Dropdown.Item
                          onClick={(e) => {
                            onChange(x, "src");
                          }}
                        >
                          {x}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="mapSource-hide">
                <span> {dataSearchResultLang.Source[lang]}: {source}</span>
               
              </div>
          
            </>
          )}
     
        </div>
        <div id="downloadchartds" className="downloadchartds">
            <div className={type == "map" ? "d-none" : "drs_sources chartSource"}>
              {/* <div className="drs_sources"> */}
                {/* <span>Source</span> */}
                {dataSearchResultLang.Source[lang]}
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {source}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {srcList?.map((x) => {
                      return (
                        <Dropdown.Item
                          onClick={(e) => {
                            onChange(x, "src");
                          }}
                        >
                          {x}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            {/* </div> */}
          </div>
      </div>
      <div className="downloadChartBG">
        <div id="copy" className="" style={{ display: "none" }}></div>
      </div>
      <div
        className={loader ? "loader text-center " : "loader text-center hide"}
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>
    </>
  );
}

//indicator, unit, subgroup, area, timeperiod
const useCaseMatrix = {
  1: {
    //indicator
    1: {
      //unit
      1: {
        //subgroup
        1: {
          //area
          1: {
            //timeperiod
            useCase: "UC1",
          },
          2: {
            useCase: "UC2",
          },
        },
        2: {
          1: {
            useCase: "UC3",
          },
          2: {
            useCase: "UC4",
          },
        },
      },
      2: {
        1: {
          1: {
            useCase: "UC5",
          },
          2: {
            useCase: "UC17",
          },
        },
        2: {
          1: {
            useCase: "UC6",
          },
          2: {
            useCase: "UC7",
          },
        },
      },
    },
    2: {
      1: {
        1: {
          1: {
            useCase: "UC13",
          },
          2: {
            useCase: "UC12",
          },
        },
        2: {
          1: {
            useCase: "UC22",
          },
          2: {
            useCase: "UC11",
          },
        },
      },
      2: {
        1: {
          1: {
            useCase: "UC15",
          },
          2: {
            useCase: "UC23",
          },
        },
        2: {
          1: {
            useCase: "UC24",
          },
          2: {
            useCase: "UC21",
          },
        },
      },
    },
  },
  2: {
    1: {
      1: {
        1: {
          1: {
            useCase: "UC14",
          },
          2: {
            useCase: "UC18",
          },
        },
        2: {
          1: {
            useCase: "UC19",
          },
          2: {
            useCase: "UC8",
          },
        },
      },
      2: {
        1: {
          1: {
            useCase: "UC20",
          },
          2: {
            useCase: "UC27",
          },
        },
        2: {
          1: {
            useCase: "UC25",
          },
          2: {
            useCase: "UC26",
          },
        },
      },
    },
    2: {
      1: {
        1: {
          1: {
            useCase: "UC16",
          },
          2: {
            useCase: "UC28",
          },
        },
        2: {
          1: {
            useCase: "UC29",
          },
          2: {
            useCase: "UC9",
          },
        },
      },
      2: {
        1: {
          1: {
            useCase: "UC30",
          },
          2: {
            useCase: "UC32",
          },
        },
        2: {
          1: {
            useCase: "UC31",
          },
          2: {
            useCase: "UC10",
          },
        },
      },
    },
  },
};

export default DataSearchResultChart;
