import React from 'react';
import lazyLoader from './Common/components/lazy/lazyLoader.js';
import {Helmet} from "react-helmet";
import './Common/custom.js'
import { HashRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';
import Area from './admin/pages/area/area';
import Masterlist from './admin/pages/master-list/masterlist';
import DataApprove from './admin/pages/data_approve/data_approve';
import Resources from './admin/pages/manage-resource/resources';
import Actor from './admin/pages/manage-actor/actor.js';
import Login from './Common/pages/login';
import Indicator from './admin/pages/indicator';
import Disaster from './admin/pages/disaster/index.js';
import User from './admin/pages/user';
import Popper from 'popper.js';
import SummaryPage from './admin/pages/summary';
import { ToastContainer } from 'react-toastify';
import DashboardComponent from './dashboard/dashboard.jsx';
import DataEntry from './admin/pages/data-entry/index.js';
import ManageForm from './admin/pages/manage-form/index.js';
import Metadata from './admin/pages/metadata/index.js';
import Generatereport from './admin/pages/generatereport/index.js';
import history from './services/history';
import Log from './admin/pages/log/index.js';
import { store } from './store';
import { Provider } from 'react-redux';
import { login } from './features/loginSlice';
import GeoData from './dashboard/pages/geo-data/geo-data.js';
import HomePage from './dashboard/pages/home/home.js';
import InformationPage from './dashboard/pages/information-kiosk/information-kiosk.js';
import DataanalyticsPage from './dashboard/pages/data-analytics/data-analytics.js';
import ContactPage from './dashboard/pages/contact/contact.js';
import ProfilePage from './dashboard/pages/profile/profile.js';
import TrackerPage from './dashboard/pages/tracker/tracker.js';
import DashboardPage from './dashboard/pages/dashboard/dashboard.js';
import ConfirmEmail from './Common/pages/confirmEmail/ConfirmEmail.js';
import { RolesAuthRoute } from './Common/components/auth/rolesAuthGuard.js';
import ForgotPassword from './Common/pages/forgotPassword/ForgotPassword.js';
import LanguagePage from './admin/pages/language/language.js'
import AboutPage from './dashboard/pages/about/about.js';
import DataSearch from './dashboard/pages/data-search/data-search.js';
import DataSearchresult from './dashboard/pages/data-search-results/data-search-result.js';

import DataSearchComponent from './dashboard/pages/data-search-component/index.js';

import ManageAggregations from './admin/pages/manage-aggregations/aggregation.js';
import FormMaster from './admin/pages/form-master/index.js';
import Webdataentry from './dashboard/pages/web-data-entry/web-data-entry.js';
//import AdminHomeComponent from './admin/pages/adminHome.jsx';
const AdminHomeComponent = lazyLoader(() => import('./admin/pages/adminHome.jsx'));


function App() {

  // const jsonUser =  JSON.parse(localStorage.getItem('userInfo'));


  const getLoginInfoFrmLocalStorage = () => {
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');
      const userInfo = localStorage.getItem('userInfo');
     
     
      if (token && token != '' && email && email != '' && userInfo && userInfo != '') {
        return {
          email: email,
          token: token,
          userInfo: JSON.parse(userInfo)
        };
      }else{
        // history.push('/home');
        return null;
      }

    }
    catch (e) {
      console.log(e)
    }
  }

  const loginInfo = getLoginInfoFrmLocalStorage()
  if (loginInfo) {
    store.dispatch(login(loginInfo));
  }




  return (
    <Provider store={store}>
      <Router history={history}>
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        <div className="App">

          <Routes>
            <Route path='/' element={<Navigate to="/home" />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgetPassword' element={<ForgotPassword />}/>
            <Route path='/confirmEmail' element={<ConfirmEmail />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/information-kiosk' element={<InformationPage/>} />
            {/* <Route path='/dashboard/data-analysis' element={<TrackerPage />   } /> */}
            <Route path='/contact' element={<ContactPage/>} />
            <Route path='/about' element={<AboutPage/>} />
            <Route path='/web-data-entry' element={<Webdataentry />} />
            
            <Route path='/dashboard' element={
                
                  <DashboardComponent /> 
                                         
              }>
              <Route path='/dashboard/data-analysis' element={<TrackerPage />   } />
            </Route>


            <Route path='/admin' element={
              <RolesAuthRoute roles={[1,2,4,7]}>
                <AdminHomeComponent />
              </RolesAuthRoute>   
              
              
              } >
              <Route path='Summary' element= {<RolesAuthRoute roles={[1]}><SummaryPage /></RolesAuthRoute>} />
              <Route path='import-area' element={<RolesAuthRoute roles={[1]}><Area /></RolesAuthRoute>} />
              <Route path='manage-indicator' element={<RolesAuthRoute roles={[1]}><Indicator /></RolesAuthRoute>} />
              <Route path='disaster' element={<RolesAuthRoute roles={[1]}><Disaster /></RolesAuthRoute>} />
              <Route path='import-metadata' element={<RolesAuthRoute roles={[1]}><Metadata /></RolesAuthRoute>} />
              <Route path='user-management' element={<RolesAuthRoute roles={[1]}><User/></RolesAuthRoute>} />
              <Route path='data-entry' element={<RolesAuthRoute roles={[2,1]}><DataEntry/></RolesAuthRoute>} />
              <Route path='data-approve' element={<RolesAuthRoute roles={[4,1]}><DataApprove/></RolesAuthRoute>} />
              <Route path='manage-form' element={<RolesAuthRoute roles={[1,2,4,7]}><ManageForm/></RolesAuthRoute>} />
              <Route path='form-master' element={<RolesAuthRoute roles={[1,2]}><FormMaster/></RolesAuthRoute>} />

              <Route path='manage-aggregation' element={<RolesAuthRoute roles={[1]}><ManageAggregations/></RolesAuthRoute>} />
              <Route path='manage-resource' element={<RolesAuthRoute roles={[1]}><Resources/></RolesAuthRoute>} />
              <Route path='master-list' element={<RolesAuthRoute roles={[1]}><Masterlist/></RolesAuthRoute>} />
              <Route path='generate-report' element={<RolesAuthRoute roles={[1]}><Generatereport/></RolesAuthRoute>} />
              <Route path='import-log' element={<RolesAuthRoute roles={[1,2]}><Log/></RolesAuthRoute>} />
              <Route path='manage-language' element={<RolesAuthRoute roles={[1]}><LanguagePage/></RolesAuthRoute>} />
            </Route>

            {/* dahboard routes */}

            <Route path='/dashboard' element={
                // <RolesAuthRoute roles={[1,2,4,5]}>
                  <DashboardComponent /> 
                // </RolesAuthRoute>                              
              }>


              <Route path='dashboard' element={<DashboardPage />} />
              
              {/* <Route path='profile' element={ <RolesAuthRoute roles={[5]}><ProfilePage /></RolesAuthRoute>}  /> */}
              <Route path='geo-data' element={<GeoData />} />
              <Route path='data-search-old' element={<DataSearch />} />
              <Route path='data-search-result' element={<DataSearchresult />} />

              <Route path='data-search' element={<DataSearchComponent />} />
              <Route path='profile' element={<DataanalyticsPage/>} />
              
            </Route>


            <Route path='*' element={<HomePage />} />
          </Routes>

        </div>

        <ToastContainer />
      </Router>
    </Provider>

  );
}

export default App;
