import { Component } from 'react'
import './masterlist.css';
import * as jsx from './masterlist.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';

import swal from 'sweetalert';
class Masterlist extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.facilityName = 'Facility';
        this.state = {
            data: [],
            bulkDelete: [],
            filteredData: [],
            searchText: '',
            filterCat: constants.masterList[0],
            isEdit: false,
            editEntry: [],
            modal: false,
            loader: false,
            filteredData: [],
            selectedFilter: constants.masterList[0],
            nameFacility: constants.masterList[0],
            type: '',
            masterListTypes: constants.masterList,
            treeData: [],
            columns : [

                {
                    name: <div class='dt-header'>Operating Temperature</div>,
                    selector: row => row.label,
                    sortable: true,
                },
                // {
                //     name: 'Parent Waste Type',
                //     selector: row => row.parent,
                //     sortable: true,
                //     omit: true
                // },
                {
                    name: 'Action',
                    width: "200px",
                    cell: row =>
                        <>
        
                            <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeDataStatus(e, row._id, row.status)}> {row.status == 1 ? 'Hide' : 'Show'}</a> */}
                                    <a class="dropdown-item text-bold" onClick={(e) => { this.editMode(e, row) }} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                                        style={{
                                            'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                            'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                                        }}
                                        ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                                        cdk-describedby-host="">Edit</a>
                                    <a class="dropdown-item text-danger text-bold" onClick={(e) => { this.deleteEntry(e, row._id, row.label) }} href="javascript:;">Delete</a>
                                </div>
                            </div>
        
                        </>,
                    sortable: false,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true
                },
            ]
        }  
        
        
    
    }
    
    handleChange = ({ selectedRows }) => {
        console.log('Selected Rows: ', selectedRows);
    };

    

    getSelectType = (e) => {
        let dt = e.target.value;
        this.setState({ nameFacility: dt })

        console.log('dt check karlo ye loo->>', dt)
        $('.dt-header').html(dt);
        // this.setState({ facilityTypes: false, wasteTypes: false, actorsTypes: false })
        // constants.masterListType.forEach((t)=> {
        //     let stat = false;
        //     if(t == dt) {
        //         stat = true;
        //     }
        //     this.setState({[t]: stat});
        // });
        let col = this.state.columns;
        if(dt == constants.masterListType[3]) {
            col = [

                {
                    name: <div class='dt-header'>Operating Temperature</div>,
                    selector: row => row.label,
                    sortable: true,
                },
                // {
                //     name: 'Parent Waste Type',
                //     selector: row => row.parent,
                //     sortable: true,
                //     omit: false
                // },
                {
                    name: 'Action',
                    width: "200px",
                    cell: row =>
                        <>
        
                            <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeDataStatus(e, row._id, row.status)}> {row.status == 1 ? 'Hide' : 'Show'}</a>
                                    <a class="dropdown-item text-bold" onClick={(e) => { this.editMode(e, row) }} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                                        style={{
                                            'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                            'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                                        }}
                                        ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                                        cdk-describedby-host="">Edit</a>
                                    <a class="dropdown-item text-danger text-bold" onClick={(e) => { this.deleteEntry(e, row._id, row.label) }} href="javascript:;">Delete</a>
                                </div>
                            </div>
        
                        </>,
                    sortable: false,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true
                },
            ];
        } else {
            col = [

                {
                    name: <div class='dt-header'>Operating Temperature</div>,
                    selector: row => row.label,
                    sortable: true,
                },
                // {
                //     name: 'Parent Waste Type',
                //     selector: row => row.parent,
                //     sortable: true,
                //     omit: true
                // },
                {
                    name: 'Action',
                    width: "200px",
                    cell: row =>
                        <>
        
                            <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeDataStatus(e, row._id, row.status)}> {row.status == 1 ? 'Hide' : 'Show'}</a>
                                    <a class="dropdown-item text-bold" onClick={(e) => { this.editMode(e, row) }} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                                        style={{
                                            'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                            'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                                        }}
                                        ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                                        cdk-describedby-host="">Edit</a>
                                    <a class="dropdown-item text-danger text-bold" onClick={(e) => { this.deleteEntry(e, row._id, row.label) }} href="javascript:;">Delete</a>
                                </div>
                            </div>
        
                        </>,
                    sortable: false,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true
                },
            ];
        }

        this.setState({ filteredData: this.state.data?.[dt], columns: col, selectedFilter: dt });
        console.log("dt check DEKHO DEKHO Facility", this.state.nameFacility)

    };


    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data?.[this.state.nameFacility];
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.label && item.label.toLowerCase().includes(val.toLowerCase())
                    // || (item.parent && item.parent.toLowerCase().includes(val.toLowerCase()))
                    // || (item.label && item.label.toLowerCase().includes(val.toLowerCase()))
                ));
        }
        this.setState({ filteredData: fd });
    }

    changeDataStatus(e, id, status) {
        this.setState({ loader: true })
        let changed = 0;
        if (status == 1) {
            changed = 0;
        }
        else {
            changed = 1;
        }

        let statusData = { "id": id, "status": changed };
        AdminService.httpPost('/data-import/master/updateStatus', statusData).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                console.log(res)

                if (res.success == true) {
                    toast.success("Masterlist status updated successfully");
                    this.getData();
                }
                else {
                    toast.error("Failed to update status");

                }

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }


    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };


    deleteEntry = (e, id,label) => {
        console.log("rishav--",this.state.selectedFilter)
        // delete
        swal({
            title: "Are you sure you want to delete?",
            // text: "Once deleted, you will not be able to recover this master-list!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    
                    const dataToBeSend = {
                        "id": id,
                        "name" : label,
                        "type" : this.state.selectedFilter
                    }

                    AdminService.httpPost('/data-import/master/deleteMaster', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            console.log(res)

                            if (res.success == true) {
                                toast.success("Masterlist deleted successfully");
                                this.getData();
                            }else if(res.status == false){
                                toast.error(res.message);
                                this.getData();
                            }
                            else {
                                toast.error("Failed to delete masterlist");

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });






    }


    editMode (event, row) {
        
        event.preventDefault();
        this.setState({isEdit:true,
                        modal: true,
                        editModeId:row});
    
        
                // this.setState({editModeId:row});
            
       
                        
        this.openEditModal()
        
    }
    
    
    
    // openAddModal = () => {
    
    //     if (this.state.modal == false) {
    
    //         this.setState({ modal: true })
    
    //     } else {
    //         this.setState({ modal: false,
    //                         isEdit: false,
    //                         editModeId: null })
    //     }
    
    // }
    
    
    openEditModal(){
        if($('#EditAreaModal').hasClass('width350')){
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else{
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getData();
        AdminService.checkToken();

    }

    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }

   

    getData = async () => {
        this.setState({ loader: true, searchText: "" });

        

        AdminService.httpGet('/data-import/master/getAll').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;


                // d.forEach((ele  , index)=>{ele.index = index })
                console.log('checkFilter', d)
         
                this.setState({data: d,  filteredData: d[this.state.selectedFilter], treeData: d[constants.masterListType[3]] })


                console.log('wstyp', d['Waste Type'])
                // if(this.state.filterCat=="actor"){
                //     this.setState({ filteredData: d.Actors })
                // }else if(this.state.filterCat=="waste"){
                //     this.setState({ filteredData: d.Waste_Types })
                // }else {
                //     this.setState({ filteredData: d.Facility_Types })
                // }
                
                console.log('mc stan', d)
                this.setState({ loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }


    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];
        let labels = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
            labels.push(item.label)
            console.log("tsest=======>", ids)
        })

        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete?",
                // text: "Once deleted, you will not be able to recover this master-list!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "id": ids.toString(),
                            "labels": labels.toString(),
                            "type" : this.state.selectedFilter
                        }


                        AdminService.httpPost('/data-import/master/deleteMultiple', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log("eeeee", res)

                                if (res.success == 1) {
                                    toast.success("Masterlist deleted successfully");
                                    this.getData();
                                }else if(res.status == false){
                                    toast.error(res.message);
                                    this.getData();
                                }
                                else {
                                    toast.error("Failed to delete masterlist");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }


        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }


    // open add modal=====================//


    openAddModal = () => {


        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({ ...this.state.editEntry, editEntry: [] })
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({ editModeId: null })
        }

    }

    // add new dump site=====================//
    saveDumpData = (saveData) => {
        this.setState({ loader: true })
        if (saveData.id != null && this.state.isEdit === true) {
            //edit flow
            const getData = {
                "id":saveData.id,
                "type": this.state.selectedFilter,
                "label": saveData.facility_type,
                "parent": saveData.parent
            }
            AdminService.httpPost('/data-import/master/updateMaster', getData).then((res) => {
                    if (res) {
                        let d = res.getdata;
                        if (res.success == true) {
                            this.setState({ loader: false })
                            toast.success(this.state.selectedFilter+" updated successfully");
                            this.openAddModal();
                            this.getData();
                        }

                        else {
                            this.openAddModal();
                            this.getData();
                            this.setState({ loader: false })

                            toast.error('Failed to update '+this.state.selectedFilter);
                        }
                    }
                }).catch((err) => {
                    this.setState({ loader: false })
                    toast.error('Failed to update '+this.state.selectedFilter);
                    console.log(err)
                });

            return;            
        }
        else {
            
            const getData = {
                "type": this.state.selectedFilter,
                "label": saveData.facility_type,
                "parent": saveData.parent
            }

            AdminService.httpPost('/data-import/master/add', getData).then((res) => {
                if (res) {
                    let d = res.getdata;
                    if (res.success == true) {
                        this.setState({ loader: false })
                        toast.success(this.state.selectedFilter+" added successfully");
                        this.openAddModal();
                        this.getData();
                    }

                    else {
                        this.openAddModal();
                        this.getData();
                        this.setState({ loader: false })

                        toast.error('Failed to add '+this.state.selectedFilter);


                    }


                }
            }).catch((err) => {
                this.setState({ loader: false })
                toast.error('Failed to add '+this.state.selectedFilter);

                console.log(err)
            });

            return;
            

            // return false

        }


    }

}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
)(Masterlist);
