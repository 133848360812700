import L from 'leaflet';

export const pol = [
    [31.487979640048422,-25.764423210662585],
[31.48379735186637,-25.761738038279816],
[31.482283014949246,-25.760765721860537],
[31.48155837182145,-25.76030043601628],
[31.473110821307387,-25.755080422823198],
[31.468185202085465,-25.751938322895228],
[31.467379498565037,-25.751436097397914],
[31.467043162012374,-25.75088690031015],
[31.466610935246536,-25.750790512771914],
[31.466055215375945,-25.750666585294653],
[31.465545764725334,-25.75054250673128],
[31.465281688699008,-25.75047524014019],
[31.465444476781215,-25.750390730847528],
[31.465580294195263,-25.750330355761264],
[31.46556187697911,-25.750314227319677],
[31.4653262662942,-25.750151288151358],
[31.46426059395128,-25.749462036943385],
[31.461830867700428,-25.748014018731908],
[31.458245234729873,-25.7457364928448],
[31.449399932061283,-25.7402627862985],
[31.445102589113276,-25.737525344419055],
[31.441601825164128,-25.735391432168797],
[31.43738613166471,-25.73276673199905],
[31.433620195415756,-25.730581255320658],
[31.424424926968868,-25.724297854062684],
[31.415230621695912,-25.718013854755554],
[31.41739315397092,-25.721542806155526],
[31.420691558768453,-25.726924869087384],
[31.41999378378614,-25.72731123222752],
[31.414923834167666,-25.730118328398078],
[31.40924477102942,-25.733262308808435],
[31.36742821144287,-25.756400455816333],
[31.36730703589103,-25.75646747509387],
[31.360789554594305,-25.75656554526364],
[31.360612139039063,-25.757799222655933],
[31.360873971256808,-25.758258477646564],
[31.361109046845115,-25.758481951081137],
[31.36146953379199,-25.758698288395237],
[31.3616178400913,-25.758787289801546],
[31.361905257121975,-25.75909317800773],
[31.3619840341359,-25.759305246240103],
[31.362258109724053,-25.759505035129223],
[31.36220695089014,-25.759811764201515],
[31.362234140992882,-25.760189053182216],
[31.36253472859488,-25.76054207665544],
[31.363200090212047,-25.760941360355446],
[31.363748038141523,-25.761270178575842],
[31.36417835474731,-25.761457781651245],
[31.364673605102837,-25.76158625789941],
[31.364986614141003,-25.76173877482455],
[31.36509158660749,-25.761997944149755],
[31.365228720628977,-25.7621273161227],
[31.3653658537512,-25.762256688994967],
[31.36550989726402,-25.762515760294036],
[31.365849026211663,-25.76269179359133],
[31.366174773247224,-25.762749936560283],
[31.366435768196197,-25.76292616590979],
[31.366566645634236,-25.76313809924386],
[31.366528230193694,-25.763350458856564],
[31.366789481449473,-25.763609233379384],
[31.367180634378826,-25.763761547957017],
[31.368014052306705,-25.763747650733478],
[31.368534451303958,-25.763581241980717],
[31.36887271330511,-25.763474253234165],
[31.369224143179736,-25.76341439975471],
[31.36940671634693,-25.763496483575807],
[31.36951177424902,-25.76377923492373],
[31.369694639695922,-25.763955655828795],
[31.369851899645482,-25.76427365070839],
[31.369982894894747,-25.764520957975776],
[31.370139862564656,-25.76474461397271],
[31.370244778373888,-25.764980194979955],
[31.370388249018504,-25.765050584916537],
[31.37062247744541,-25.76499102641469],
[31.370857107869256,-25.765061183426837],
[31.371039684633786,-25.765143265449296],
[31.371274389701398,-25.765237005383426],
[31.371431031816712,-25.765354528788407],
[31.371600438909184,-25.765389473745188],
[31.37179636970677,-25.765577652386696],
[31.371953085566474,-25.765718759613037],
[31.372123009769155,-25.765918795815708],
[31.372657833891992,-25.7662004445948],
[31.37282728055459,-25.766247180562914],
[31.373049076753546,-25.766376328604622],
[31.373296734756536,-25.766446447845226],
[31.373440173025642,-25.76650504227382],
[31.37342755643664,-25.76663479016338],
[31.373480200950553,-25.766811541119694],
[31.373467546590064,-25.766929497098545],
[31.373559077789253,-25.76704718597881],
[31.373660015897087,-25.7672049189718],
[31.373706821112933,-25.76735464709924],
[31.37374678158875,-25.76743246543589],
[31.373799944112193,-25.76749826073609],
[31.37379347528872,-25.767546228775416],
[31.373840149203602,-25.767653999932122],
[31.373952943973222,-25.767737625191216],
[31.37412520101725,-25.76778513367691],
[31.374317035402896,-25.767742682978394],
[31.374475790025713,-25.767706310797564],
[31.374674393608416,-25.767711793964054],
[31.374800145810127,-25.767705476226695],
[31.374846125448357,-25.76759147366755],
[31.374984963685165,-25.767537170803735],
[31.375104172419697,-25.767554845179916],
[31.375137458127256,-25.767614698659372],
[31.375058287210265,-25.76769881764625],
[31.375144944083956,-25.767890399322425],
[31.37514520758532,-25.76797431416321],
[31.375198276579226,-25.768010139556225],
[31.37526437764899,-25.76797999967721],
[31.37529066663103,-25.767919993313],
[31.37537662742949,-25.76788980217259],
[31.37545604206275,-25.76788360314572],
[31.375502227645768,-25.767835532583717],
[31.375508620926155,-25.767763588618664],
[31.375548148828045,-25.76770354808025],
[31.375600973206417,-25.767661455311895],
[31.375660434581448,-25.767625337639174],
[31.37571984109786,-25.767571238921505],
[31.37571970889752,-25.76752928195077],
[31.375799124430102,-25.7675230829239],
[31.375852286953545,-25.767588879123423],
[31.375972004704295,-25.767768387401134],
[31.37604504584226,-25.7678401254214],
[31.376104734745752,-25.76787593462666],
[31.376244028039537,-25.767965482820728],
[31.376383093804805,-25.76798310593557],
[31.376548960266348,-25.76810255478904],
[31.376688178017048,-25.7681681279567],
[31.376932911424774,-25.768107555019583],
[31.37717121378057,-25.768106938084657],
[31.377198033362617,-25.76821475870412],
[31.37714549406934,-25.76834676119404],
[31.37713272819292,-25.76849664220623],
[31.377093294719828,-25.768586652651834],
[31.377040698769292,-25.768700673197486],
[31.37696816844624,-25.76879076997801],
[31.376909008344114,-25.768922788655743],
[31.37692915135932,-25.769012646216595],
[31.376982562095634,-25.76915636057754],
[31.37700293173998,-25.769318144116994],
[31.37714884674193,-25.769407675223988],
[31.377314640358406,-25.769503147252408],
[31.37746047891801,-25.769568702433617],
[31.37764617003478,-25.769676111163562],
[31.37775244382027,-25.76978971981964],
[31.377839029647532,-25.76995732377145],
[31.377832846808474,-25.770095199733646],
[31.37778696519632,-25.7702391730993],
[31.377708136921058,-25.770431182852747],
[31.37764247112318,-25.770599183405523],
[31.37751730527947,-25.770791312768836],
[31.377451506381988,-25.77091735635088],
[31.377445228214754,-25.771025262405885],
[31.377485363159053,-25.771157023877493],
[31.377485799330202,-25.771294882752613],
[31.377605409162356,-25.771438426242355],
[31.377685016250553,-25.771492165231223],
[31.377724904780564,-25.771546006742767],
[31.37776486975298,-25.771623824180097],
[31.377731998632896,-25.7716958355943],
[31.377652675730474,-25.77173200542768],
[31.377639702110628,-25.771815954442673],
[31.377613488671614,-25.771899936732666],
[31.377567303088597,-25.771948008193988],
[31.37756089182176,-25.77201395727826],
[31.377561080679357,-25.77207389619332],
[31.377548088173796,-25.77215185032759],
[31.37754839214466,-25.77224775223192],
[31.377561897263774,-25.77233163199918],
[31.377602070878936,-25.77247538143365],
[31.37769514621408,-25.772601012226914],
[31.37780121405484,-25.772648687986532],
[31.377880784270815,-25.77269043901248],
[31.37791416800451,-25.772780260600484],
[31.377921015442553,-25.77285217039133],
[31.377914870375037,-25.77300203341707],
[31.377961511914293,-25.773097814812274],
[31.37798837106652,-25.773217623394544],
[31.377995238289657,-25.773295526267475],
[31.37803516638985,-25.77336135484262],
[31.37812824172505,-25.773486985635827],
[31.37816822738182,-25.773570797054617],
[31.378168511567594,-25.773660704078168],
[31.378234900420352,-25.773720471222703],
[31.3783011741599,-25.773744273579325],
[31.378387327413293,-25.773774019555333],
[31.378499959405644,-25.773803697182814],
[31.378566234044513,-25.773827500438756],
[31.37865242506939,-25.77386923257899],
[31.378857791553912,-25.77391665023316],
[31.378944155248632,-25.77401232730699],
[31.379023954791705,-25.774126004311597],
[31.379116861054342,-25.774197689271887],
[31.37921623524113,-25.774221405293588],
[31.37932228599476,-25.774263086172425],
[31.37937204998019,-25.774298919659373],
[31.379421813066244,-25.77433475404564],
[31.37951458622922,-25.774364481135876],
[31.3795807466542,-25.77435232140249],
[31.37966014689829,-25.7743401256962],
[31.379752843618917,-25.774345877760027],
[31.3797793996996,-25.774369784437965],
[31.379825948608698,-25.77443559592598],
[31.3798394753116,-25.774525468775323],
[31.379846400091367,-25.774621353592522],
[31.379836508448136,-25.774633366736452],
[31.379826616804962,-25.774645380779646],
[31.379826674361595,-25.77466336182465],
[31.379846876732017,-25.774771200430507],
[31.379827266115512,-25.77484917165191],
[31.37977031205031,-25.774973270899693],
[31.380004929883683,-25.775031619813376],
[31.380154855861974,-25.775078398049573],
[31.38026557769433,-25.775084005322526],
[31.38041533460006,-25.775077717262775],
[31.38063674229187,-25.77507713809939],
[31.38071487629054,-25.77507398597561],
[31.380793010289267,-25.77507083295251],
[31.380942804067217,-25.775076336803465],
[31.381196601741806,-25.775022606807795],
[31.381482977357564,-25.774974687331905],
[31.38167790181376,-25.774838564148354],
[31.381859954273523,-25.774749643681048],
[31.38196372974238,-25.774619655672552],
[31.382074188073375,-25.774542715074176],
[31.382230209656484,-25.774459758910723],
[31.382444895815354,-25.77439433682912],
[31.382724908727596,-25.774393601183704],
[31.382907242675174,-25.774393120945717],
[31.383102562833074,-25.774380814622873],
[31.383441296978333,-25.77441530002602],
[31.38370190701744,-25.774455886429962],
[31.383955909737494,-25.77446700924503],
[31.384203363594395,-25.774466356337257],
[31.3843987601947,-25.77447763203702],
[31.384529244628993,-25.774553937714018],
[31.384665996438628,-25.77455357618652],
[31.38473126833344,-25.774600572958036],
[31.384738026738603,-25.774677205988212],
[31.384855393661553,-25.77472406516341],
[31.384894712920698,-25.774800610959403],
[31.384927747717427,-25.77494792800445],
[31.384908497729043,-25.775036422193068],
[31.384947950087906,-25.775154240575716],
[31.384928699200145,-25.775242733865014],
[31.38495505203406,-25.775337002600565],
[31.38496828465867,-25.77540182573364],
[31.384961963323974,-25.775460805072044],
[31.38494945285504,-25.775620035436248],
[31.384956383930046,-25.775749732064412],
[31.384930658822896,-25.77585003525087],
[31.38489851705299,-25.775979836200463],
[31.38490535279982,-25.776080053051942],
[31.384912149875845,-25.776168477992826],
[31.384873459242783,-25.776286504118843],
[31.384893299186444,-25.77638078994147],
[31.384939092665036,-25.77644552673962],
[31.385037020741947,-25.776521917852165],
[31.385239332229446,-25.7766569951242],
[31.385304614016775,-25.77670693897403],
[31.385369896703423,-25.77675688372318],
[31.385526453383193,-25.776839016107658],
[31.38569614825866,-25.77695648915062],
[31.385865710034523,-25.77703268960687],
[31.385918016403366,-25.777097409317946],
[31.385898708858406,-25.777168214741266],
[31.38589236773862,-25.777221297225026],
[31.38601596426537,-25.777179696385815],
[31.386113702585362,-25.777197125247085],
[31.386185488269746,-25.77724410403215],
[31.38624428774375,-25.777302909801392],
[31.386329307851327,-25.77741471082021],
[31.386440223937257,-25.77747927494852],
[31.386518654712177,-25.77756750923311],
[31.38659711426544,-25.77764435630195],
[31.38676061461075,-25.777731602231597],
[31.386951055946554,-25.77782379533187],
[31.38706836621219,-25.777916182685658],
[31.38722959936655,-25.778021697443364],
[31.38737620504787,-25.778127249073236],
[31.387639864688083,-25.77824573205487],
[31.387933252317794,-25.7785098062825],
[31.388402499675692,-25.778879352999695],
[31.389003950971585,-25.77942070080468],
[31.38941450587481,-25.779730808629893],
[31.38982506437526,-25.780040915555787],
[31.39061684459176,-25.78063471811771],
[31.391434881414682,-25.781308206908193],
[31.390889521734096,-25.781667930330684],
[31.39028188310249,-25.78243427502173],
[31.389541903835834,-25.78346526321127],
[31.389172858940356,-25.784760269870503],
[31.388907350294005,-25.785487522830863],
[31.38880288684379,-25.78577365562967],
[31.388798207671186,-25.785845909860825],
[31.3887580844181,-25.786465555341067],
[31.38913524569506,-25.786921642020104],
[31.389804236976033,-25.787346616652997],
[31.390824980988157,-25.78752801620493],
[31.391905751149523,-25.7880132544081],
[31.393273297125006,-25.78862624400591],
[31.39470037462263,-25.789147350769554],
[31.3959760827301,-25.78995914359956],
[31.397039826926516,-25.7908278293408],
[31.39737872564774,-25.79151651667388],
[31.39758877040549,-25.792074132315292],
[31.39771315293973,-25.79253738479241],
[31.3973461243242,-25.79322222392642],
[31.396946219192728,-25.793856603899087],
[31.396512947414635,-25.79501243597508],
[31.396109750764424,-25.796039738742877],
[31.39606130428581,-25.796163260625917],
[31.395617470869638,-25.797266405020537],
[31.395340013830605,-25.79778852082353],
[31.394868288840485,-25.798422369296873],
[31.394777838626396,-25.798538963702185],
[31.39433845775369,-25.799095366158156],
[31.39408848309705,-25.799729330644027],
[31.393656298599353,-25.80050460110641],
[31.3931344948611,-25.801197791344975],
[31.392506998299382,-25.801772839444936],
[31.392164401565935,-25.802506430827066],
[31.391858712109922,-25.80342649663544],
[31.391595189166708,-25.80431395392577],
[31.391254975636684,-25.80516984411298],
[31.390615100806315,-25.80684468573361],
[31.390342034257515,-25.807502536213008],
[31.389898600140327,-25.808025087287888],
[31.38934098000226,-25.808516170185044],
[31.388670156802334,-25.80854396013558],
[31.387865856224323,-25.808494116110523],
[31.387007117884252,-25.808385922272464],
[31.386177516679084,-25.8081599433267],
[31.38493067231275,-25.807680160411053],
[31.384083403026693,-25.80741522103557],
[31.383527043738127,-25.807065745387774],
[31.38297278256789,-25.80687151520823],
[31.382231984918235,-25.806718934431217],
[31.38158165996566,-25.806667927582737],
[31.38116276474983,-25.806848119345204],
[31.38106144802748,-25.80689170139084],
[31.380761790324414,-25.807483170212095],
[31.38074504674654,-25.807605453728684],
[31.382285083589807,-25.808761253429054],
[31.383128046022534,-25.809395377994235],
[31.389892988370775,-25.814483937283853],
[31.39011182310219,-25.814648530305362],
[31.393143827125982,-25.816892630190694],
[31.397458853443368,-25.82008608320615],
[31.398022379429335,-25.820509275382733],
[31.398585909911844,-25.8209324648613],
[31.39860817802503,-25.820949096023924],
[31.398677840410073,-25.821001122703592],
[31.398721793875723,-25.821033949756952],
[31.398812389780005,-25.821101611150368],
[31.40012811771851,-25.822084239196215],
[31.400517184220746,-25.822374800256796],
[31.400608910572828,-25.822443304315016],
[31.400767857650578,-25.822562006731175],
[31.42499264469484,-25.84064851611606],
[31.425126116677006,-25.840748142113],
[31.425479561932264,-25.841011954637963],
[31.427234207592676,-25.842321604958045],
[31.42938854074862,-25.843929509139514],
[31.43049330662086,-25.84475402917508],
[31.448301180854855,-25.82387811688153],
[31.44926457420081,-25.8227484757615],
[31.451086942408494,-25.820611555279015],
[31.446314004687622,-25.816781937136625],
[31.441903745561433,-25.81324295469858],
[31.441927529931604,-25.813174054938656],
[31.441998481944438,-25.813079875235985],
[31.44219589122747,-25.812907324113667],
[31.442237163814127,-25.81285006158106],
[31.442308902733714,-25.812645759893883],
[31.442446540375613,-25.8124899982148],
[31.44260157989902,-25.81242859880075],
[31.442772257733566,-25.812389086187352],
[31.442874106854617,-25.812290569054483],
[31.443023510326782,-25.812188388083598],
[31.443147004330854,-25.811984287844552],
[31.443331727777093,-25.811619694593162],
[31.443384151057785,-25.811477280651957],
[31.443428483138064,-25.81122440927902],
[31.44343370010523,-25.811072209814597],
[31.44345661033435,-25.810958138007607],
[31.443419731835206,-25.81069531473696],
[31.443390670243275,-25.810281819050658],
[31.443325747285485,-25.809932338006945],
[31.44331847716603,-25.809452432783473],
[31.443300355826807,-25.809272593555306],
[31.44327255058778,-25.809169778562364],
[31.443121879071498,-25.808956586177487],
[31.44306094820422,-25.80876830321455],
[31.443100932062407,-25.80823111567338],
[31.44312180262915,-25.808131763070378],
[31.443125250629862,-25.807825580785902],
[31.443172619720656,-25.807504823188992],
[31.44323500029509,-25.807305331975442],
[31.44330535515809,-25.807185789592495],
[31.443666058841586,-25.806710176833178],
[31.44382120538438,-25.806599222076386],
[31.443956082107547,-25.8065614406579],
[31.44429906375086,-25.80638871416778],
[31.444452169731903,-25.80629247681628],
[31.444528586924775,-25.80627473229299],
[31.4446715791301,-25.806274023627225],
[31.444766972018215,-25.806290882318308],
[31.444852590174946,-25.80633385642227],
[31.44495684678111,-25.80645476037887],
[31.44504239838801,-25.80650640035003],
[31.445177304788842,-25.806503368735434],
[31.445292116737846,-25.806433424862632],
[31.445394957811118,-25.806282009607003],
[31.44546204094047,-25.806221417784172],
[31.445743615975175,-25.806134535180604],
[31.44595563834207,-25.80611411697288],
[31.44614009109239,-25.806134492013143],
[31.44635001893829,-25.80622049957634],
[31.446435871818096,-25.80624614194585],
[31.44664099008969,-25.806272771770978],
[31.446765434677104,-25.80627211886315],
[31.44688037612849,-25.806236653198937],
[31.447435258730252,-25.806225197634717],
[31.447511941223183,-25.80619860558113],
[31.447763791865555,-25.805977136735407],
[31.447976857446065,-25.805863020861693],
[31.448187148618047,-25.805727520008986],
[31.44831192685399,-25.80568317713687],
[31.448444458145275,-25.80568493531149],
[31.44865471963965,-25.805753698374417],
[31.448817621036426,-25.805744079225803],
[31.448933161436287,-25.80565625683073],
[31.448962546784173,-25.80556879236582],
[31.44896368262789,-25.80542039613431],
[31.44892616111349,-25.805307051878913],
[31.448928849187098,-25.805173544823163],
[31.44894826195275,-25.8051385531017],
[31.449092717355086,-25.805050370977767],
[31.449248864843298,-25.804915939419004],
[31.449441515813362,-25.804783829909752],
[31.449586472138037,-25.804643293752974],
[31.44973274417083,-25.804561154173882],
[31.449802269858935,-25.80448827671256],
[31.449823767253122,-25.804424395169633],
[31.449824234001255,-25.804363195405074],
[31.44969774165753,-25.804058677765738],
[31.449689369868565,-25.80390119478426],
[31.449738098734315,-25.803813489301035],
[31.44986323939702,-25.803760208067104],
[31.449988013136362,-25.80376821742925],
[31.45011228775195,-25.803828759789383],
[31.450168104174736,-25.80382235931438],
[31.450337904270896,-25.80371297567325],
[31.450386632237326,-25.803625179358505],
[31.450407443448796,-25.80343258414638],
[31.45038883107975,-25.803362687937636],
[31.45033173042509,-25.80329300127096],
[31.450198083974442,-25.803206311122324],
[31.450179339405054,-25.803153927411756],
[31.450180272002,-25.803031437051118],
[31.450247293078178,-25.802823715242198],
[31.450313547931955,-25.802703549629086],
[31.450400898182977,-25.80259803307274],
[31.450468502919136,-25.80254501983677],
[31.45052626997142,-25.802527150307697],
[31.450765569675355,-25.80239173578991],
[31.450862290862062,-25.80231227529123],
[31.450920123564913,-25.802285649063435],
[31.450968616808268,-25.802241540015018],
[31.451068019773345,-25.802080293370807],
[31.451136006721413,-25.80199658897135],
[31.451203993669424,-25.801912884571948],
[31.451408534576274,-25.80160472377895],
[31.451515025997708,-25.801525052838883],
[31.451669578088627,-25.801445050948303],
[31.451706050094174,-25.801438979625175],
[31.45185086342667,-25.801367854942498],
[31.45190886070543,-25.80133238208373],
[31.451976729842272,-25.801270521317463],
[31.452009300588827,-25.80121322730855],
[31.45204408996284,-25.801153149897914],
[31.452107899559962,-25.801062296787507],
[31.45220917491355,-25.801110557106483],
[31.45226676300075,-25.801137999918694],
[31.45245019591988,-25.801201974991102],
[31.45263021321381,-25.80123932473498],
[31.45279179440581,-25.80125063730702],
[31.452947477843907,-25.80123753778207],
[31.453106790945697,-25.801208058005443],
[31.453269464814014,-25.80115920863051],
[31.45343814255631,-25.801107106407812],
[31.45360919540809,-25.801057536675955],
[31.453783850044715,-25.80101362278043],
[31.453958556841997,-25.800977286572447],
[31.45412886325647,-25.800956638138302],
[31.454295486047897,-25.800961146439704],
[31.454459228310782,-25.800991879871333],
[31.454616299402744,-25.801049158591752],
[31.45476557966782,-25.801129648814197],
[31.454910271591814,-25.80122577285107],
[31.455053735941192,-25.801332781382712],
[31.455195491578706,-25.801448540317836],
[31.45533479386569,-25.801573182756158],
[31.455473482815023,-25.801703962168062],
[31.455616412967117,-25.801836821711902],
[31.45576369314,-25.80197160310695],
[31.455914974396592,-25.802108890912564],
[31.456070257636327,-25.802249888421557],
[31.45611079367825,-25.802286820880056],
[31.45615521569073,-25.80232729396937],
[31.4561722380584,-25.80234280367739],
[31.456200411120108,-25.802368472127228],
[31.456228584181872,-25.802394139677745],
[31.456327732638783,-25.802482871287452],
[31.456390457653526,-25.80253900607022],
[31.45655433301613,-25.80268627185393],
[31.456719462033618,-25.80283690020275],
[31.456885631566706,-25.802989768762757],
[31.457049747947565,-25.803145039412016],
[31.45720938030871,-25.80330100343849],
[31.457365998142336,-25.80345627318843],
[31.457520401845102,-25.80360898166913],
[31.457673684992642,-25.80375888966097],
[31.457828167835714,-25.803906796661295],
[31.45798371907341,-25.8040520893324],
[31.458140149848077,-25.804193673199393],
[31.458292898798277,-25.80433136210263],
[31.45844049912978,-25.80446320811052],
[31.45858375033987,-25.80459209265092],
[31.458726759632327,-25.804720470873008],
[31.458872278932574,-25.804849409372707],
[31.45902129299833,-25.80497597276286],
[31.459172201036324,-25.805097068275018],
[31.459322895934974,-25.805212907249825],
[31.45947207008004,-25.805324477142847],
[31.459618441937607,-25.805431218575848],
[31.45975688986863,-25.805540281158983],
[31.459889628903284,-25.80565593397415],
[31.46001182788359,-25.805782950622643],
[31.460122154014243,-25.805922321258095],
[31.46022236816782,-25.806075375977798],
[31.460309401857444,-25.806241772140027],
[31.460384935916125,-25.80641899793835],
[31.460451718671948,-25.80660182831167],
[31.460506948736736,-25.80678524414367],
[31.460548891318354,-25.806962309862683],
[31.460574344830206,-25.807128384966973],
[31.460589180046668,-25.807294701089518],
[31.46059537008034,-25.80746728458746],
[31.460588752868716,-25.80764528560138],
[31.46057298415593,-25.807826567342147],
[31.460550198932594,-25.808013237820603],
[31.46051852840742,-25.808201830150324],
[31.460482748879826,-25.80839434442339],
[31.460444087924373,-25.808591098999955],
[31.46040043842953,-25.808788014555205],
[31.46036292410969,-25.808971458266114],
[31.460324637272265,-25.80914108119589],
[31.46028949806191,-25.809296324865443],
[31.460260762024575,-25.80943796179247],
[31.460237869781906,-25.809564766200936],
[31.46022162083119,-25.80967961772012],
[31.460216605312155,-25.80979590995321],
[31.46023282728322,-25.80992159380645],
[31.4602721546363,-25.81005373299331],
[31.46033594984425,-25.810188701447373],
[31.46041898065147,-25.81032526889601],
[31.460510177302865,-25.810467760178938],
[31.460608177325582,-25.81061574721889],
[31.46071623536602,-25.810767148984667],
[31.46083027030079,-25.8109240995675],
[31.46094865435697,-25.81108611962867],
[31.46107048011862,-25.811247579412225],
[31.46119009085004,-25.811401569426835],
[31.461301378355984,-25.81154237897755],
[31.46140335338208,-25.81167217093389],
[31.461495589649815,-25.8117977477678],
[31.461578248137698,-25.81191950967758],
[31.46164900589696,-25.81204204500432],
[31.46170506333806,-25.81216831521556],
[31.461743270135855,-25.81229751991458],
[31.46176899074635,-25.8124340352025],
[31.461782545328276,-25.812571751085386],
[31.461804263056365,-25.81270818633368],
[31.461847193093547,-25.81283680377544],
[31.461912748274756,-25.81295637763469],
[31.462003463788847,-25.813066427673448],
[31.462119499715186,-25.813163351207663],
[31.46225906910081,-25.813243468211454],
[31.46241806114466,-25.813309391215398],
[31.462561008383886,-25.813354958064792],
[31.462595270755173,-25.813365660896466],
[31.46262360119829,-25.8132323615842],
[31.462621376275536,-25.813130343390583],
[31.462631428897396,-25.813099893245408],
[31.462676730446788,-25.813055429864164],
[31.462729281431223,-25.813029086023448],
[31.46285296789023,-25.813011272252368],
[31.463044019865663,-25.812955784082135],
[31.46313683709542,-25.812898080881666],
[31.463230721820423,-25.812807339287247],
[31.463311293881134,-25.81275490971126],
[31.463335524315028,-25.81272874663432],
[31.463344564300257,-25.812668602673853],
[31.463328337832593,-25.812598971765112],
[31.46332747178542,-25.812478834930232],
[31.46339716474739,-25.812321916723022],
[31.463405993391916,-25.812130261302457],
[31.463378195347502,-25.811957044681833],
[31.463397758299948,-25.811731054944175],
[31.46337695788037,-25.811664777607348],
[31.463245872698906,-25.811497849046475],
[31.463161571149612,-25.811437099842237],
[31.46308598223237,-25.81141125512528],
[31.463042544078178,-25.811370324280972],
[31.46304454686839,-25.811270034584368],
[31.46313262287231,-25.81114753882781],
[31.46324378717111,-25.811064142895816],
[31.463292528627335,-25.811007303044562],
[31.463332706739095,-25.810902380040773],
[31.463354848947176,-25.810749585225153],
[31.463488680658145,-25.8102643569145],
[31.463573278084368,-25.810093670986078],
[31.463619168689718,-25.80994495189799],
[31.46362286040676,-25.809765859107074],
[31.463595663109402,-25.80969599167662],
[31.463492199705286,-25.809630971592014],
[31.46346458961915,-25.80958294959339],
[31.463447398178914,-25.809495629919354],
[31.463461528326945,-25.809026580412308],
[31.46343676279639,-25.80891518408845],
[31.463438930162567,-25.808805778863587],
[31.46348211380854,-25.80855082915741],
[31.46361469905918,-25.808130324155115],
[31.46369501930974,-25.807937356623654],
[31.463823286015895,-25.807739814241017],
[31.463932205606852,-25.807618965143092],
[31.464052740841396,-25.80756144360572],
[31.46410134919796,-25.807521843758025],
[31.46421894994461,-25.80737677771606],
[31.464279560653267,-25.807218804604076],
[31.46432901167458,-25.80713975689423],
[31.464401715566794,-25.80709114404101],
[31.464570443671107,-25.807015868087774],
[31.464728855652197,-25.80698006877509],
[31.46478377455162,-25.806947939595716],
[31.46488150207972,-25.80684662377263],
[31.464973533301986,-25.806793615932577],
[31.465086891946555,-25.80663520305211],
[31.46524035136116,-25.806608084895174],
[31.465361266109596,-25.806546137793987],
[31.465474458379617,-25.806396481612296],
[31.46553298535906,-25.806343489960057],
[31.46560075377181,-25.806308071060585],
[31.465720742218537,-25.80628990475526],
[31.465774052230756,-25.80625453172121],
[31.465794123300213,-25.80621059983929],
[31.46579070857439,-25.806153564835085],
[31.465757882420405,-25.806122979791667],
[31.465700976019264,-25.806105741586634],
[31.46566833242764,-25.80607082360956],
[31.465660975074,-25.80597435693096],
[31.465685996911304,-25.805921564028893],
[31.465734455081076,-25.80589496118347],
[31.465782300812577,-25.805890294601397],
[31.46582897382814,-25.805942769143428],
[31.466048515426678,-25.805959125113475],
[31.46607279442395,-25.80594586910655],
[31.466109025411242,-25.805827146905244],
[31.466215920628258,-25.805674170426585],
[31.466341902157126,-25.805607694339585],
[31.466406173106463,-25.805519480739406],
[31.466400103581975,-25.805370205870247],
[31.46645351341897,-25.805334742004675],
[31.466461556056004,-25.80519859993541],
[31.46659685456126,-25.805067285426844],
[31.466684608607864,-25.80504299293972],
[31.46681862467983,-25.80504229236783],
[31.466922633972445,-25.80509440088582],
[31.467005642296613,-25.805105869040574],
[31.46710691405292,-25.80507015966009],
[31.467204357395246,-25.80499926160661],
[31.46731231561091,-25.804888794282363],
[31.467460953760053,-25.804879200314815],
[31.467549992937847,-25.80495337999446],
[31.46764033703198,-25.804979266080238],
[31.46769186188999,-25.805022967735738],
[31.46772868193318,-25.805079895720553],
[31.46778038935355,-25.805119173610933],
[31.46782328341783,-25.805123279016073],
[31.467944758443878,-25.80505230182223],
[31.468060322226165,-25.8050253032751],
[31.468142629978445,-25.804985232182673],
[31.468310760932923,-25.80496239569794],
[31.468359052728147,-25.804944457820454],
[31.468437880104148,-25.804856108422598],
[31.468478333408427,-25.80479763090591],
[31.468489320425874,-25.804715587554256],
[31.46848832487632,-25.804675870794767],
[31.468411977830556,-25.804557873447038],
[31.468392809680495,-25.80450152012901],
[31.46839293558554,-25.804355836252796],
[31.468407178148823,-25.804326093874067],
[31.468577903647372,-25.804278696904305],
[31.468710802761393,-25.804273395400855],
[31.469005239899957,-25.804216934164117],
[31.469119209184214,-25.80421548265838],
[31.46911831705671,-25.804343477769237],
[31.469127857964338,-25.804360867959645],
[31.469175477965962,-25.80437497562457],
[31.46931129807797,-25.80437679405378],
[31.469490328815652,-25.80431140794508],
[31.469680303403322,-25.804290305353277],
[31.469931853672165,-25.804231904278936],
[31.470050421190024,-25.80415741523251],
[31.470211341380377,-25.804152290895445],
[31.470628961357534,-25.804029176405322],
[31.470771261984225,-25.80395533217279],
[31.470832770216248,-25.803900885417477],
[31.47089703127301,-25.80386168307018],
[31.470990865635997,-25.803837098303404],
[31.471180940947704,-25.803841808052937],
[31.471223708207617,-25.80386432617769],
[31.47130492778024,-25.80397824509987],
[31.47135249921854,-25.80400525983481],
[31.47150874922943,-25.804004302056853],
[31.471584927202684,-25.804053428422947],
[31.471741828322763,-25.804065917308208],
[31.471784491261303,-25.80403689528646],
[31.471793692225162,-25.803968265323135],
[31.471760224854563,-25.803877028202237],
[31.471760037795548,-25.803829731057192],
[31.47179732818421,-25.80377852096194],
[31.47195250620325,-25.80373342805524],
[31.472361276851473,-25.803743474381804],
[31.47240879433042,-25.803731766108058],
[31.472437212907096,-25.803706579694847],
[31.472445356268224,-25.803572333396403],
[31.47245853043688,-25.80355008596763],
[31.472510781947108,-25.803525455335432],
[31.47275776006262,-25.803471306255744],
[31.47285756682328,-25.803469447357088],
[31.472928990080902,-25.803501349008002],
[31.472967122234934,-25.803536787692565],
[31.47299576474279,-25.803593380230268],
[31.47303389689688,-25.80362881981415],
[31.4730909912563,-25.803643075867228],
[31.473157455652085,-25.80363184783147],
[31.473214255933215,-25.803547176660857],
[31.473261653802354,-25.80350532131348],
[31.47337551606728,-25.80345214709888],
[31.473480109919194,-25.803450451876813],
[31.47355125888356,-25.803413392613948],
[31.47358914822064,-25.80336272301122],
[31.473588926088098,-25.803306760898238],
[31.473612554875558,-25.803255684801968],
[31.473660004006035,-25.803226737423927],
[31.473883352434825,-25.80322366174255],
[31.473973721709967,-25.80325595279993],
[31.47411154101485,-25.803259384612886],
[31.474305990629034,-25.80316090615088],
[31.47456674096236,-25.803084955706083],
[31.47486310085145,-25.80308723818547],
[31.475114963185035,-25.803183267793543],
[31.475257585769043,-25.803191106284487],
[31.475300381807244,-25.803170656600457],
[31.475342973699355,-25.803124302844253],
[31.475432819568994,-25.80294998275548],
[31.47547066034275,-25.802912219323446],
[31.475556184970003,-25.80287989589044],
[31.475584552285284,-25.802841803306535],
[31.475579380284216,-25.802695324429635],
[31.475602991085225,-25.80263991539971],
[31.475721480362097,-25.8025222759822],
[31.475745124437992,-25.802475442887328],
[31.4757417672688,-25.802258914017614],
[31.47577958286149,-25.80216509674176],
[31.47589269059523,-25.801998643922218],
[31.475930441436788,-25.8019134016821],
[31.475982185729322,-25.80171212711292],
[31.47600534237273,-25.801492983914045],
[31.47607103425088,-25.80118876395028],
[31.476089178073153,-25.800913493163137],
[31.476131518155114,-25.8007292193779],
[31.476296802755314,-25.800345601868003],
[31.47630461786389,-25.80023022064813],
[31.476246748288872,-25.799871435218506],
[31.476241247135874,-25.799642727729633],
[31.47626867735761,-25.79952033179785],
[31.476344175443387,-25.79937476123621],
[31.476341479275902,-25.79917438688841],
[31.476239506947763,-25.798708333023114],
[31.476181863102568,-25.798555797212202],
[31.475966663430825,-25.798299975163673],
[31.47591337410296,-25.798189035695373],
[31.47585068506112,-25.797971617396172],
[31.475845477087148,-25.797816021192375],
[31.475878518179115,-25.79770028563962],
[31.47587821690621,-25.79757509281626],
[31.47579574727598,-25.79739763499282],
[31.47566164576841,-25.79719994691999],
[31.475592951953217,-25.797051327656618],
[31.475524684416712,-25.79695957432483],
[31.475495905211915,-25.79689404702259],
[31.475452208053014,-25.79663802442525],
[31.475388999203005,-25.796564575894422],
[31.475383687806982,-25.796382893955354],
[31.475339781106072,-25.79604906471252],
[31.475320459171883,-25.795979354663416],
[31.475248647407227,-25.795873802134224],
[31.47516295100945,-25.795769328791437],
[31.47512379093024,-25.795718416371756],
[31.475070513293588,-25.795684922021508],
[31.475053649206586,-25.795675932398296],
[31.475038481240972,-25.79567723012002],
[31.47501508088135,-25.79568979364899],
[31.47499789573635,-25.795704209781434],
[31.47497587044012,-25.79571552055478],
[31.474950398941814,-25.795727467148822],
[31.474927648792004,-25.7957300392099],
[31.47490555604662,-25.79572449219154],
[31.474888261184333,-25.79571143583405],
[31.474884065846993,-25.795697089848773],
[31.474883968720235,-25.79567273890575],
[31.474880434384602,-25.795650897970518],
[31.47487759792284,-25.79563155175464],
[31.474872013132938,-25.795614711949327],
[31.47486677728,-25.79560191369734],
[31.474799686956032,-25.795505099880415],
[31.47477218568787,-25.7954346946554],
[31.474788131567095,-25.795032250737734],
[31.474735353054143,-25.794948840416623],
[31.474728607239456,-25.794857155433306],
[31.474579130922223,-25.79470338305464],
[31.474554689147624,-25.79464984731254],
[31.47454434514549,-25.794505820886855],
[31.47462074884851,-25.794387778573082],
[31.47469222156883,-25.794333387575705],
[31.474768536239026,-25.7943179749945],
[31.47486864517191,-25.79426872542132],
[31.474925654995047,-25.79418802475749],
[31.474929961848318,-25.794117876738596],
[31.47490113587878,-25.794065257405634],
[31.474834136386335,-25.794015922396852],
[31.47471391681387,-25.793847521645773],
[31.47465634941102,-25.793737860114106],
[31.474651317704172,-25.793651223924826],
[31.47468412677108,-25.793577553261457],
[31.47483157511715,-25.7934980918634],
[31.474888705449473,-25.793447629104776],
[31.474950674134334,-25.793410328823597],
[31.47501652069593,-25.793395761605098],
[31.475045037298685,-25.79337057609115],
[31.47504966431063,-25.793305841990957],
[31.475030410724912,-25.793253371945525],
[31.475030201182904,-25.793175566199352],
[31.475001288878445,-25.793101283997032],
[31.47490959580125,-25.79301014670085],
[31.47487606637742,-25.79295302985838],
[31.47485944780533,-25.7927610947487],
[31.47489723911633,-25.792636499075115],
[31.474935271445645,-25.79257255907629],
[31.474948460003475,-25.792179149747938],
[31.475075524316026,-25.791738704077886],
[31.475255624347653,-25.791446747269504],
[31.475311840968743,-25.791392676430803],
[31.475387037781616,-25.791372572985836],
[31.475482456750058,-25.79132315793737],
[31.475511324088416,-25.791286146338564],
[31.475506436273065,-25.79113560342529],
[31.475545126906127,-25.791087095792818],
[31.475674085190917,-25.79104713891428],
[31.47582693576453,-25.790948432923813],
[31.475855551292682,-25.79092324740992],
[31.47588412635139,-25.790863399326383],
[31.475969850628132,-25.790783416321574],
[31.47600791713171,-25.79072814129063],
[31.476045891904448,-25.790625207587198],
[31.47605382572351,-25.790464693890442],
[31.475985543797833,-25.790268685751187],
[31.47597539674723,-25.790099567341088],
[31.47604196816235,-25.789967022560006],
[31.476118167719335,-25.789873803333307],
[31.476165652822658,-25.78983041194391],
[31.47629875158617,-25.78977942937712],
[31.476423061275284,-25.78977405682724],
[31.47647560506516,-25.789749424396348],
[31.47662803835334,-25.789571558280727],
[31.47676635498334,-25.789453944044226],
[31.476880244227914,-25.789311495928757],
[31.47697509572413,-25.789220471047884],
[31.477122667277342,-25.78899911371809],
[31.477279898448614,-25.788799749308964],
[31.47738474411068,-25.788689916006774],
[31.477475438940417,-25.788631669615825],
[31.477776131763108,-25.788529949097835],
[31.477909681086942,-25.78846722857969],
[31.47801464815751,-25.78838772311491],
[31.47805269397668,-25.788328115150364],
[31.478062178226992,-25.78829369359903],
[31.478071660678665,-25.788259272947016],
[31.478091192154864,-25.788139896938674],
[31.47813573287783,-25.78793439915313],
[31.478157345385227,-25.78766736975325],
[31.478360495940194,-25.787420280121808],
[31.478439883593808,-25.787420019318404],
[31.47851927124742,-25.78741975761568],
[31.478587070237154,-25.78735792922498],
[31.47865404544791,-25.787090751437006],
[31.47869784333085,-25.786700437575064],
[31.478969450279976,-25.786555797811786],
[31.479468122555886,-25.78647201427202],
[31.479751730957673,-25.78649161409669],
[31.48003533756082,-25.786511213921358],
[31.48077366387355,-25.786480798849766],
[31.480602859234637,-25.786155284738527],
[31.480432054595667,-25.785829769728025],
[31.48033122890314,-25.785344181688572],
[31.48043594686152,-25.784732498805738],
[31.48046922357588,-25.78453811933872],
[31.480706548368573,-25.78384681587778],
[31.48105818688589,-25.78308404329391],
[31.481339430970024,-25.78267759829248],
[31.482210027770634,-25.78121987369815],
[31.482497102159584,-25.77996246769368],
[31.481976481929166,-25.77764967219457],
[31.482186237105225,-25.775334453921857],
[31.483348107229403,-25.773677369414997],
[31.484364682883836,-25.772219145696965],
[31.484428577916617,-25.772007867069362],
[31.484725016046696,-25.7710276204283],
[31.48476595318624,-25.770270270756328],
[31.48479294363949,-25.76977093837803],
[31.484422682860554,-25.768515715028173],
[31.484321206058837,-25.767088624040696],
[31.484891423000818,-25.766961019235623],
[31.48593828242582,-25.766454698225687],
[31.48664578177744,-25.765949504965647],
[31.487475192326315,-25.765080746379283],
[31.487705423266732,-25.76480062644771],
[31.487979640048422,-25.764423210662585]]



export const pol2 = [
 [31.451086942408494,-25.820611555279015],
[31.44926457420081,-25.8227484757615],
[31.448301180854855,-25.82387811688153],
[31.43049330662086,-25.84475402917508],
[31.42938854074862,-25.843929509139514],
[31.427234207592676,-25.842321604958045],
[31.425479561932264,-25.841011954637963],
[31.425126116677006,-25.840748142113],
[31.42499264469484,-25.84064851611606],
[31.400767857650578,-25.822562006731175],
[31.400608910572828,-25.822443304315016],
[31.400517184220746,-25.822374800256796],
[31.40012811771851,-25.822084239196215],
[31.398812389780005,-25.821101611150368],
[31.398721793875723,-25.821033949756952],
[31.398677840410073,-25.821001122703592],
[31.39860817802503,-25.820949096023924],
[31.398585909911844,-25.8209324648613],
[31.398022379429335,-25.820509275382733],
[31.397458853443368,-25.82008608320615],
[31.393143827125982,-25.816892630190694],
[31.39011182310219,-25.814648530305362],
[31.389892988370775,-25.814483937283853],
[31.383128046022534,-25.809395377994235],
[31.382285083589807,-25.808761253429054],
[31.38074504674654,-25.807605453728684],
[31.380761790324414,-25.807483170212095],
[31.38106144802748,-25.80689170139084],
[31.38116276474983,-25.806848119345204],
[31.38158165996566,-25.806667927582737],
[31.382231984918235,-25.806718934431217],
[31.38297278256789,-25.80687151520823],
[31.383527043738127,-25.807065745387774],
[31.384083403026693,-25.80741522103557],
[31.38493067231275,-25.807680160411053],
[31.386177516679084,-25.8081599433267],
[31.387007117884252,-25.808385922272464],
[31.387865856224323,-25.808494116110523],
[31.388670156802334,-25.80854396013558],
[31.38934098000226,-25.808516170185044],
[31.389898600140327,-25.808025087287888],
[31.390342034257515,-25.807502536213008],
[31.390615100806315,-25.80684468573361],
[31.391254975636684,-25.80516984411298],
[31.391595189166708,-25.80431395392577],
[31.391858712109922,-25.80342649663544],
[31.392164401565935,-25.802506430827066],
[31.392506998299382,-25.801772839444936],
[31.3931344948611,-25.801197791344975],
[31.393656298599353,-25.80050460110641],
[31.39408848309705,-25.799729330644027],
[31.39433845775369,-25.799095366158156],
[31.394777838626396,-25.798538963702185],
[31.394868288840485,-25.798422369296873],
[31.395340013830605,-25.79778852082353],
[31.395617470869638,-25.797266405020537],
[31.39606130428581,-25.796163260625917],
[31.396109750764424,-25.796039738742877],
[31.396512947414635,-25.79501243597508],
[31.396946219192728,-25.793856603899087],
[31.3973461243242,-25.79322222392642],
[31.39771315293973,-25.79253738479241],
[31.39758877040549,-25.792074132315292],
[31.39737872564774,-25.79151651667388],
[31.397039826926516,-25.7908278293408],
[31.3959760827301,-25.78995914359956],
[31.39470037462263,-25.789147350769554],
[31.393273297125006,-25.78862624400591],
[31.391905751149523,-25.7880132544081],
[31.390824980988157,-25.78752801620493],
[31.389804236976033,-25.787346616652997],
[31.38913524569506,-25.786921642020104],
[31.3887580844181,-25.786465555341067],
[31.388798207671186,-25.785845909860825],
[31.38880288684379,-25.78577365562967],
[31.388907350294005,-25.785487522830863],
[31.389172858940356,-25.784760269870503],
[31.389541903835834,-25.78346526321127],
[31.39028188310249,-25.78243427502173],
[31.390889521734096,-25.781667930330684],
[31.391434881414682,-25.781308206908193],
[31.39061684459176,-25.78063471811771],
[31.38982506437526,-25.780040915555787],
[31.38941450587481,-25.779730808629893],
[31.389003950971585,-25.77942070080468],
[31.388402499675692,-25.778879352999695],
[31.387933252317794,-25.7785098062825],
[31.387639864688083,-25.77824573205487],
[31.38737620504787,-25.778127249073236],
[31.38722959936655,-25.778021697443364],
[31.38706836621219,-25.777916182685658],
[31.386951055946554,-25.77782379533187],
[31.38676061461075,-25.777731602231597],
[31.38659711426544,-25.77764435630195],
[31.386518654712177,-25.77756750923311],
[31.386440223937257,-25.77747927494852],
[31.386329307851327,-25.77741471082021],
[31.38624428774375,-25.777302909801392],
[31.386185488269746,-25.77724410403215],
[31.386113702585362,-25.777197125247085],
[31.38601596426537,-25.777179696385815],
[31.38589236773862,-25.777221297225026],
[31.385898708858406,-25.777168214741266],
[31.385918016403366,-25.777097409317946],
[31.385865710034523,-25.77703268960687],
[31.38569614825866,-25.77695648915062],
[31.385526453383193,-25.776839016107658],
[31.385369896703423,-25.77675688372318],
[31.385304614016775,-25.77670693897403],
[31.385239332229446,-25.7766569951242],
[31.385037020741947,-25.776521917852165],
[31.384939092665036,-25.77644552673962],
[31.384893299186444,-25.77638078994147],
[31.384873459242783,-25.776286504118843],
[31.384912149875845,-25.776168477992826],
[31.38490535279982,-25.776080053051942],
[31.38489851705299,-25.775979836200463],
[31.384930658822896,-25.77585003525087],
[31.384956383930046,-25.775749732064412],
[31.38494945285504,-25.775620035436248],
[31.384961963323974,-25.775460805072044],
[31.38496828465867,-25.77540182573364],
[31.38495505203406,-25.775337002600565],
[31.384928699200145,-25.775242733865014],
[31.384947950087906,-25.775154240575716],
[31.384908497729043,-25.775036422193068],
[31.384927747717427,-25.77494792800445],
[31.384894712920698,-25.774800610959403],
[31.384855393661553,-25.77472406516341],
[31.384738026738603,-25.774677205988212],
[31.38473126833344,-25.774600572958036],
[31.384665996438628,-25.77455357618652],
[31.384529244628993,-25.774553937714018],
[31.3843987601947,-25.77447763203702],
[31.384203363594395,-25.774466356337257],
[31.383955909737494,-25.77446700924503],
[31.38370190701744,-25.774455886429962],
[31.383441296978333,-25.77441530002602],
[31.383102562833074,-25.774380814622873],
[31.382907242675174,-25.774393120945717],
[31.382724908727596,-25.774393601183704],
[31.382444895815354,-25.77439433682912],
[31.382230209656484,-25.774459758910723],
[31.382074188073375,-25.774542715074176],
[31.38196372974238,-25.774619655672552],
[31.381859954273523,-25.774749643681048],
[31.38167790181376,-25.774838564148354],
[31.381482977357564,-25.774974687331905],
[31.381196601741806,-25.775022606807795],
[31.380942804067217,-25.775076336803465],
[31.380793010289267,-25.77507083295251],
[31.38071487629054,-25.77507398597561],
[31.38063674229187,-25.77507713809939],
[31.38041533460006,-25.775077717262775],
[31.38026557769433,-25.775084005322526],
[31.380154855861974,-25.775078398049573],
[31.380004929883683,-25.775031619813376],
[31.37977031205031,-25.774973270899693],
[31.379827266115512,-25.77484917165191],
[31.379846876732017,-25.774771200430507],
[31.379826674361595,-25.77466336182465],
[31.379826616804962,-25.774645380779646],
[31.379836508448136,-25.774633366736452],
[31.379846400091367,-25.774621353592522],
[31.3798394753116,-25.774525468775323],
[31.379825948608698,-25.77443559592598],
[31.3797793996996,-25.774369784437965],
[31.379752843618917,-25.774345877760027],
[31.37966014689829,-25.7743401256962],
[31.3795807466542,-25.77435232140249],
[31.37951458622922,-25.774364481135876],
[31.379421813066244,-25.77433475404564],
[31.37937204998019,-25.774298919659373],
[31.37932228599476,-25.774263086172425],
[31.37921623524113,-25.774221405293588],
[31.379116861054342,-25.774197689271887],
[31.379023954791705,-25.774126004311597],
[31.378944155248632,-25.77401232730699],
[31.378857791553912,-25.77391665023316],
[31.37865242506939,-25.77386923257899],
[31.378566234044513,-25.773827500438756],
[31.378499959405644,-25.773803697182814],
[31.378387327413293,-25.773774019555333],
[31.3783011741599,-25.773744273579325],
[31.378234900420352,-25.773720471222703],
[31.378168511567594,-25.773660704078168],
[31.37816822738182,-25.773570797054617],
[31.37812824172505,-25.773486985635827],
[31.37803516638985,-25.77336135484262],
[31.377995238289657,-25.773295526267475],
[31.37798837106652,-25.773217623394544],
[31.377961511914293,-25.773097814812274],
[31.377914870375037,-25.77300203341707],
[31.377921015442553,-25.77285217039133],
[31.37791416800451,-25.772780260600484],
[31.377880784270815,-25.77269043901248],
[31.37780121405484,-25.772648687986532],
[31.37769514621408,-25.772601012226914],
[31.377602070878936,-25.77247538143365],
[31.377561897263774,-25.77233163199918],
[31.37754839214466,-25.77224775223192],
[31.377548088173796,-25.77215185032759],
[31.377561080679357,-25.77207389619332],
[31.37756089182176,-25.77201395727826],
[31.377567303088597,-25.771948008193988],
[31.377613488671614,-25.771899936732666],
[31.377639702110628,-25.771815954442673],
[31.377652675730474,-25.77173200542768],
[31.377731998632896,-25.7716958355943],
[31.37776486975298,-25.771623824180097],
[31.377724904780564,-25.771546006742767],
[31.377685016250553,-25.771492165231223],
[31.377605409162356,-25.771438426242355],
[31.377485799330202,-25.771294882752613],
[31.377485363159053,-25.771157023877493],
[31.377445228214754,-25.771025262405885],
[31.377451506381988,-25.77091735635088],
[31.37751730527947,-25.770791312768836],
[31.37764247112318,-25.770599183405523],
[31.377708136921058,-25.770431182852747],
[31.37778696519632,-25.7702391730993],
[31.377832846808474,-25.770095199733646],
[31.377839029647532,-25.76995732377145],
[31.37775244382027,-25.76978971981964],
[31.37764617003478,-25.769676111163562],
[31.37746047891801,-25.769568702433617],
[31.377314640358406,-25.769503147252408],
[31.37714884674193,-25.769407675223988],
[31.37700293173998,-25.769318144116994],
[31.376982562095634,-25.76915636057754],
[31.37692915135932,-25.769012646216595],
[31.376909008344114,-25.768922788655743],
[31.37696816844624,-25.76879076997801],
[31.377040698769292,-25.768700673197486],
[31.377093294719828,-25.768586652651834],
[31.37713272819292,-25.76849664220623],
[31.37714549406934,-25.76834676119404],
[31.377198033362617,-25.76821475870412],
[31.37717121378057,-25.768106938084657],
[31.376932911424774,-25.768107555019583],
[31.376688178017048,-25.7681681279567],
[31.376548960266348,-25.76810255478904],
[31.376383093804805,-25.76798310593557],
[31.376244028039537,-25.767965482820728],
[31.376104734745752,-25.76787593462666],
[31.37604504584226,-25.7678401254214],
[31.375972004704295,-25.767768387401134],
[31.375852286953545,-25.767588879123423],
[31.375799124430102,-25.7675230829239],
[31.37571970889752,-25.76752928195077],
[31.37571984109786,-25.767571238921505],
[31.375660434581448,-25.767625337639174],
[31.375600973206417,-25.767661455311895],
[31.375548148828045,-25.76770354808025],
[31.375508620926155,-25.767763588618664],
[31.375502227645768,-25.767835532583717],
[31.37545604206275,-25.76788360314572],
[31.37537662742949,-25.76788980217259],
[31.37529066663103,-25.767919993313],
[31.37526437764899,-25.76797999967721],
[31.375198276579226,-25.768010139556225],
[31.37514520758532,-25.76797431416321],
[31.375144944083956,-25.767890399322425],
[31.375058287210265,-25.76769881764625],
[31.375137458127256,-25.767614698659372],
[31.375104172419697,-25.767554845179916],
[31.374984963685165,-25.767537170803735],
[31.374846125448357,-25.76759147366755],
[31.374800145810127,-25.767705476226695],
[31.374674393608416,-25.767711793964054],
[31.374475790025713,-25.767706310797564],
[31.374317035402896,-25.767742682978394],
[31.37412520101725,-25.76778513367691],
[31.373952943973222,-25.767737625191216],
[31.373840149203602,-25.767653999932122],
[31.37379347528872,-25.767546228775416],
[31.373799944112193,-25.76749826073609],
[31.37374678158875,-25.76743246543589],
[31.373706821112933,-25.76735464709924],
[31.373660015897087,-25.7672049189718],
[31.373559077789253,-25.76704718597881],
[31.373467546590064,-25.766929497098545],
[31.373480200950553,-25.766811541119694],
[31.37342755643664,-25.76663479016338],
[31.373440173025642,-25.76650504227382],
[31.373296734756536,-25.766446447845226],
[31.373049076753546,-25.766376328604622],
[31.37282728055459,-25.766247180562914],
[31.372657833891992,-25.7662004445948],
[31.372123009769155,-25.765918795815708],
[31.371953085566474,-25.765718759613037],
[31.37179636970677,-25.765577652386696],
[31.371600438909184,-25.765389473745188],
[31.371431031816712,-25.765354528788407],
[31.371274389701398,-25.765237005383426],
[31.371039684633786,-25.765143265449296],
[31.370857107869256,-25.765061183426837],
[31.37062247744541,-25.76499102641469],
[31.370388249018504,-25.765050584916537],
[31.370244778373888,-25.764980194979955],
[31.370139862564656,-25.76474461397271],
[31.369982894894747,-25.764520957975776],
[31.369851899645482,-25.76427365070839],
[31.369694639695922,-25.763955655828795],
[31.36951177424902,-25.76377923492373],
[31.36940671634693,-25.763496483575807],
[31.369224143179736,-25.76341439975471],
[31.36887271330511,-25.763474253234165],
[31.368534451303958,-25.763581241980717],
[31.368014052306705,-25.763747650733478],
[31.367180634378826,-25.763761547957017],
[31.366789481449473,-25.763609233379384],
[31.366528230193694,-25.763350458856564],
[31.366566645634236,-25.76313809924386],
[31.366435768196197,-25.76292616590979],
[31.366174773247224,-25.762749936560283],
[31.365849026211663,-25.76269179359133],
[31.36550989726402,-25.762515760294036],
[31.3653658537512,-25.762256688994967],
[31.365228720628977,-25.7621273161227],
[31.36509158660749,-25.761997944149755],
[31.364986614141003,-25.76173877482455],
[31.364673605102837,-25.76158625789941],
[31.36417835474731,-25.761457781651245],
[31.363748038141523,-25.761270178575842],
[31.363200090212047,-25.760941360355446],
[31.36253472859488,-25.76054207665544],
[31.362234140992882,-25.760189053182216],
[31.36220695089014,-25.759811764201515],
[31.362258109724053,-25.759505035129223],
[31.3619840341359,-25.759305246240103],
[31.361905257121975,-25.75909317800773],
[31.3616178400913,-25.758787289801546],
[31.36146953379199,-25.758698288395237],
[31.361109046845115,-25.758481951081137],
[31.360873971256808,-25.758258477646564],
[31.360612139039063,-25.757799222655933],
[31.360789554594305,-25.75656554526364],
[31.36730703589103,-25.75646747509387],
[31.36742821144287,-25.756400455816333],
[31.40924477102942,-25.733262308808435],
[31.414923834167666,-25.730118328398078],
[31.41999378378614,-25.72731123222752],
[31.420691558768453,-25.726924869087384],
[31.41739315397092,-25.721542806155526],
[31.415230621695912,-25.718013854755554],
[31.413686089141777,-25.71840296712321],
[31.41331553338523,-25.718496317650875],
[31.3155111519182,-25.74309736485145],
[31.315241779784344,-25.743443575060894],
[31.300942980134494,-25.761817553637172],
[31.264448060729478,-25.80874191260915],
[31.23943366982803,-25.829188711589723],
[31.24159355989491,-25.83171838129823],
[31.24465610399608,-25.835283651819054],
[31.250072410413452,-25.841184110058578],
[31.257837415064728,-25.855771449093083],
[31.25792823490019,-25.855942037894692],
[31.259389544192175,-25.857811930775938],
[31.263557283142177,-25.86346272462498],
[31.26417286998202,-25.864372905085474],
[31.266117373318195,-25.867753525904448],
[31.266822734081984,-25.86897978029259],
[31.271580389623352,-25.877250086122558],
[31.271839290950595,-25.87760773480909],
[31.281004291814668,-25.890266662976046],
[31.278630796574078,-25.8960522229317],
[31.280180110823494,-25.89738945815691],
[31.280824830301754,-25.897592157252234],
[31.281469551578596,-25.897794853649543],
[31.28275844464656,-25.89796715835763],
[31.28495179758744,-25.899186542824623],
[31.28505770534889,-25.89925146488315],
[31.285038871746565,-25.89951560925789],
[31.2857292426105,-25.90099139493691],
[31.286000745238255,-25.90261127808799],
[31.286180435179006,-25.903378957372922],
[31.286199949568072,-25.90363528034385],
[31.286263082875053,-25.90446449573983],
[31.28628681688315,-25.905087722320502],
[31.286448899896868,-25.905535062193337],
[31.286702293775875,-25.9059460325833],
[31.287169583309662,-25.906708699946478],
[31.28746342779499,-25.907364024230276],
[31.287323092187023,-25.907520936142248],
[31.287031710944746,-25.907883729850198],
[31.286718844898814,-25.90831509326688],
[31.286405811579016,-25.90867792924297],
[31.286222315707334,-25.908893663111996],
[31.286309124566515,-25.908981598821697],
[31.286460833001115,-25.9090498258887],
[31.28655824126986,-25.909044739323235],
[31.28665565043798,-25.909039651858393],
[31.28730526132881,-25.909097104147975],
[31.287781823772207,-25.909213633802096],
[31.287955729273563,-25.909506978264403],
[31.288021388776144,-25.909800536765317],
[31.287806133346407,-25.91031002518747],
[31.287482487127136,-25.91076099112678],
[31.28727745878774,-25.911025717362918],
[31.287072431347667,-25.911290443599057],
[31.286791746641825,-25.911604267422945],
[31.286548079830084,-25.911788722871222],
[31.286402765575247,-25.91189872524592],
[31.28590586586307,-25.912330450190098],
[31.28571163388483,-25.91258536392337],
[31.285647721764974,-25.91301623271295],
[31.285547775609416,-25.91350777966028],
[31.285620203409735,-25.913623561078452],
[31.285679425565093,-25.91385555289139],
[31.28568714174827,-25.914014697819994],
[31.28566570191066,-25.9142468497123],
[31.285592692248997,-25.914379627417816],
[31.285475826248444,-25.914572175865203],
[31.285351691027756,-25.9147912660041],
[31.285396104047038,-25.914963603087756],
[31.28555045019317,-25.91510919613245],
[31.285748763294862,-25.91524143874136],
[31.28594688484094,-25.915294102141047],
[31.28609354717952,-25.915293811660035],
[31.2862692045602,-25.91515420000627],
[31.28634956528026,-25.91502803861306],
[31.286451667110782,-25.914795729339403],
[31.28658319835597,-25.914603150315088],
[31.286773343814843,-25.914390559576646],
[31.28695625332847,-25.914217773731252],
[31.287066665794043,-25.914389978614565],
[31.287324060756873,-25.914694526830885],
[31.28772803981724,-25.914965624763738],
[31.288073113283588,-25.915137362898918],
[31.288506233975568,-25.91532882136795],
[31.288792660852664,-25.915507306217137],
[31.289042261391273,-25.91561954700444],
[31.28929186282926,-25.915731787791742],
[31.289438719421412,-25.91581107472126],
[31.289585576013565,-25.91589036165084],
[31.28971043428919,-25.91596969264714],
[31.289806169818917,-25.916135292909416],
[31.28990213377648,-25.916393737381043],
[31.29004199903909,-25.916612303215175],
[31.290211084174246,-25.91678438758936],
[31.290490087147873,-25.916923095424465],
[31.290754309763656,-25.917015409033866],
[31.29096734656582,-25.91716750957289],
[31.291217360792643,-25.917445537681374],
[31.29150433535682,-25.91784286265795],
[31.29165874895216,-25.918011660010052],
[31.29181316434608,-25.918180457362155],
[31.29202645026055,-25.918432031013367],
[31.2923938511953,-25.91872971560383],
[31.292915645940354,-25.919186249245968],
[31.293106739284667,-25.919358285956093],
[31.293437150204397,-25.919523408678344],
[31.29373806515963,-25.91962227474812],
[31.294031596680895,-25.919701260404793],
[31.29420762997819,-25.91971416567617],
[31.294405532089684,-25.919673974074612],
[31.294551920135063,-25.919560938286622],
[31.294683623150718,-25.9194413005755],
[31.294815177778275,-25.919261977558165],
[31.294873103111286,-25.918963434115142],
[31.29498924785554,-25.918485716942257],
[31.29508377199852,-25.918160573351713],
[31.29523725839283,-25.917954678065882],
[31.29555191139167,-25.91768213762049],
[31.29618157531951,-25.917282951946618],
[31.29635732443103,-25.91718311730699],
[31.29653307174391,-25.917083282667306],
[31.2968408008623,-25.91697654573096],
[31.297185377902906,-25.916942681759338],
[31.297522788246113,-25.91697514818452],
[31.297852949154333,-25.91704078700269],
[31.29816124394631,-25.917159522693794],
[31.29849163867823,-25.917318003922674],
[31.298690119953108,-25.917509912952085],
[31.298808120898116,-25.917774936863793],
[31.29894845560682,-25.9181725487241],
[31.299052008043816,-25.918523814022763],
[31.299118794396918,-25.918835365259838],
[31.299155963377075,-25.919034238639142],
[31.2992442983857,-25.9191666898908],
[31.299405765363815,-25.919219409947743],
[31.29969194942396,-25.919291766701633],
[31.299919584920985,-25.919410666069325],
[31.30020615568958,-25.919635549640873],
[31.300448829649838,-25.919900313648498],
[31.30062538635252,-25.920118794047085],
[31.300706590636707,-25.92033083889703],
[31.300721678562695,-25.920496599238618],
[31.300832083833654,-25.920655530128556],
[31.301089157738488,-25.920814157047687],
[31.301309717369634,-25.921032543916738],
[31.301486276770333,-25.92125102161731],
[31.3016260682885,-25.92142978615567],
[31.30175146255999,-25.921714686883263],
[31.301781404588212,-25.921953365155616],
[31.30179681537078,-25.922245126811106],
[31.301768224124316,-25.922536980197435],
[31.301820115905684,-25.92275571690277],
[31.301952676874578,-25.92297428633418],
[31.30220235655355,-25.923106399440712],
[31.302503168986107,-25.92315882541942],
[31.302752307273238,-25.923078724603386],
[31.302898689922642,-25.922965679822198],
[31.3030743059345,-25.92281278518186],
[31.30327911933591,-25.922606774782764],
[31.30351333427302,-25.922427228733568],
[31.3037111347611,-25.92234723493692],
[31.303931090047797,-25.92232687878237],
[31.3041733008572,-25.922405950773964],
[31.304488795621467,-25.92246497327983],
[31.304686614096,-25.922391609285285],
[31.304884413684817,-25.92231161279068],
[31.305067444606948,-25.92219185816765],
[31.305111156154965,-25.922079027425127],
[31.305037410847888,-25.9219200227908],
[31.30483151016614,-25.921701610740683],
[31.304654740324054,-25.921403557428164],
[31.304360294192293,-25.920973116715913],
[31.304175970944357,-25.920588866982598],
[31.304138657173326,-25.920336940797142],
[31.304218677949677,-25.920084769996095],
[31.304474461327402,-25.919739386263643],
[31.30484020840896,-25.91938050640522],
[31.304957047429866,-25.919187941770076],
[31.30533579960752,-25.91818576156362],
[31.305473611717844,-25.917595252319018],
[31.305617814410652,-25.9166399870457],
[31.30565380527895,-25.91637795967506],
[31.30568979524793,-25.916115933203685],
[31.305740665399412,-25.915936770265716],
[31.30582093618733,-25.915784071677535],
[31.306011649118375,-25.91580356538219],
[31.306224497962262,-25.91587606422894],
[31.30643006499554,-25.91596847316646],
[31.306650539191082,-25.91615369393776],
[31.306819510112348,-25.916272705720758],
[31.306885612081373,-25.916312355930415],
[31.306995266418483,-25.916179490990658],
[31.307119516752323,-25.916020067272257],
[31.30721459038108,-25.915920390913243],
[31.30728764590816,-25.915814128818965],
[31.307375230882258,-25.91565478334155],
[31.307418782350965,-25.91548226729276],
[31.30741830121366,-25.91529658067259],
[31.307381170904364,-25.91511760479358],
[31.307314640858067,-25.91491216366535],
[31.307233701873884,-25.914806228024986],
[31.307079552679284,-25.91474687007195],
[31.306852263421263,-25.914760615310115],
[31.30667639020328,-25.914807407935484],
[31.30656654790789,-25.91486732616613],
[31.30642728788905,-25.914894146647498],
[31.306360931411916,-25.914755022426277],
[31.30630917093157,-25.914589339426414],
[31.30623556681803,-25.91448338759824],
[31.306249786898206,-25.91431093360268],
[31.306263956616363,-25.914118585704102],
[31.30627101629443,-25.914012463904044],
[31.306255767389814,-25.913787019155563],
[31.306240552659403,-25.91357483760862],
[31.306232654813243,-25.913356009172446],
[31.306232226735915,-25.91319021825393],
[31.30622448267377,-25.913031074224648],
[31.306172466785938,-25.91276591631396],
[31.306135340073922,-25.912586938636252],
[31.306112811157334,-25.912381404877863],
[31.306031310096955,-25.912056624613513],
[31.306001499369756,-25.911871000046574],
[31.30584709836495,-25.911712166283394],
[31.30574411340018,-25.911586380806796],
[31.30576585630928,-25.911486860030493],
[31.305941843741152,-25.911486488610535],
[31.30607383543918,-25.91148620982068],
[31.3062498921189,-25.91151236480374],
[31.306367355269288,-25.911565170296342],
[31.306536215573942,-25.91164439427331],
[31.30663156079794,-25.911650824425976],
[31.306704837558243,-25.91163077404093],
[31.30671929775741,-25.911551163355455],
[31.30672627020124,-25.911411882652203],
[31.306652632812813,-25.91129266762249],
[31.306490898736058,-25.911133850047122],
[31.30635851403423,-25.910981601119943],
[31.306094001836755,-25.910776578176524],
[31.305983617250206,-25.910624281585285],
[31.305902424657233,-25.91041887103404],
[31.305813918777403,-25.910220107372027],
[31.30571054170821,-25.909941795077714],
[31.305607370583743,-25.909743061992685],
[31.305584997249866,-25.90959721174187],
[31.305599524898184,-25.90954412835879],
[31.305695056281877,-25.909623507019205],
[31.30580555957897,-25.90982222391648],
[31.305923243063262,-25.90996124111831],
[31.306040910359798,-25.91009362582014],
[31.3061733616114,-25.910272402049657],
[31.30632022270015,-25.9103516718921],
[31.306591535570988,-25.910351099023956],
[31.306862797180372,-25.910330629554892],
[31.307090131404493,-25.910336780018326],
[31.307346984076105,-25.910415816037016],
[31.307655236599942,-25.91052126874149],
[31.307801960991753,-25.910547484878464],
[31.30780900088473,-25.910434730578288],
[31.30769143611093,-25.91034213727977],
[31.307434409870154,-25.91019678525339],
[31.30716263384852,-25.910018305800122],
[31.306861595686144,-25.90986641480316],
[31.306678002687704,-25.909760695899365],
[31.306465027938714,-25.909635145145785],
[31.3064134239404,-25.909529146552813],
[31.306408019914215,-25.909414393059762],
[31.30661597194961,-25.909490961338747],
[31.3068330503051,-25.909554109934163],
[31.307064080742805,-25.909607866909482],
[31.307299382060876,-25.909649752833786],
[31.307537323788495,-25.90967944664908],
[31.30776395294413,-25.909700736299897],
[31.307971931959116,-25.909724614199547],
[31.308158859643697,-25.90976129664648],
[31.308323136103922,-25.909814361143788],
[31.30846820124657,-25.909893356693033],
[31.30860085934222,-25.909997910974766],
[31.308720176894724,-25.91012490244225],
[31.30882103136554,-25.910267686004886],
[31.308911773859336,-25.91041420625055],
[31.309000061203903,-25.910553522027385],
[31.309092191351624,-25.910678965761633],
[31.309192618644488,-25.91078493377762],
[31.309307694094855,-25.91086969218253],
[31.309441365726514,-25.910931427043693],
[31.309596116567604,-25.910967336073668],
[31.309766181963766,-25.91097405940525],
[31.309941690056974,-25.910951836258164],
[31.310110741917242,-25.910897518105912],
[31.31026583899734,-25.910812253382744],
[31.31039569570487,-25.91069444039607],
[31.310497563711635,-25.910547546931696],
[31.310560985700988,-25.910380058093153],
[31.310585771915896,-25.910199655889357],
[31.31057131081741,-25.91001431910547],
[31.310530622790054,-25.90983194378913],
[31.310485664781652,-25.90965306323824],
[31.31044999317271,-25.909480958179643],
[31.31041928312345,-25.90931493703465],
[31.310411118178592,-25.909155344243686],
[31.310419587993636,-25.909092718154398],
[31.310537541274584,-25.909086688200034],
[31.31404008138469,-25.90890979605018],
[31.318933830432,-25.908662498675312],
[31.325207165578263,-25.908345244837108],
[31.327166783820076,-25.908246669248342],
[31.329126397565233,-25.90814806578055],
[31.330787710684376,-25.90806445131335],
[31.334400456416006,-25.907051359632476],
[31.34132425141314,-25.905103030580335],
[31.358593822047283,-25.900241722777253],
[31.368914476022326,-25.89733533515755],
[31.37204112379885,-25.896454671345964],
[31.38483201578498,-25.892902326780927],
[31.38530214627707,-25.8927717353273],
[31.38666140679851,-25.892394151368933],
[31.39346251538285,-25.89049430296967],
[31.39755454130909,-25.889351038019925],
[31.39763232097488,-25.889329482169842],
[31.397693459585525,-25.88931197416821],
[31.398009369836586,-25.88923505155634],
[31.399130712012777,-25.88896200659127],
[31.409930045435317,-25.885914504550726],
[31.411566746807978,-25.885454599350226],
[31.41995865716632,-25.883096163257278],
[31.42098939804231,-25.88281864776235],
[31.4256621683744,-25.881455884381523],
[31.434768866190268,-25.878874848995054],
[31.437003619421546,-25.878329590038504],
[31.440214701847083,-25.877429903766995],
[31.440415086986718,-25.877373756393695],
[31.44057842725266,-25.877327988995432],
[31.44062415508074,-25.877315176354273],
[31.440954949111642,-25.87722248772758],
[31.449628557711833,-25.874791826181763],
[31.450445569307647,-25.874562838104453],
[31.450949518805317,-25.874421590583893],
[31.451393356718086,-25.874297190063203],
[31.45403007812564,-25.87355812360846],
[31.459344768346455,-25.872068258143543],
[31.45882182516715,-25.867893287745005],
[31.45862199490921,-25.86629783557663],
[31.458544992257657,-25.865683031146943],
[31.453444211181477,-25.861878368292025],
[31.453511707998985,-25.86185740779308],
[31.453612681180346,-25.861826053829304],
[31.453802809552087,-25.861774457025547],
[31.453992725683804,-25.861729504413063],
[31.45418250871586,-25.86168673805247],
[31.454370824054365,-25.861638903112862],
[31.45455276230018,-25.861578234847627],
[31.454721360002793,-25.861500333773336],
[31.4548850492057,-25.86141285401993],
[31.455045374944177,-25.8613145158522],
[31.455200285864578,-25.861200811867946],
[31.45534633756347,-25.861070165555645],
[31.4554783562412,-25.86092193929602],
[31.45558918869017,-25.860760718732138],
[31.455667017818655,-25.86059768603434],
[31.45572051399057,-25.860438387321665],
[31.455760082361962,-25.860280556302598],
[31.45579943579537,-25.860128674298835],
[31.455849889560795,-25.859981993973804],
[31.45590968188634,-25.859841530662038],
[31.455988045212166,-25.85971342043797],
[31.456082229411436,-25.859596540048244],
[31.456183724199548,-25.859488491001002],
[31.456288047355542,-25.859381777447027],
[31.456396212415314,-25.859267752404833],
[31.45651027432973,-25.859144468842146],
[31.456629965100888,-25.85901246275489],
[31.456755952924937,-25.85887389431474],
[31.456888932078584,-25.858733002925703],
[31.45702324402754,-25.85859550108148],
[31.45715408819069,-25.85846352197393],
[31.45728063719173,-25.858337732000678],
[31.4574006751011,-25.85821706366653],
[31.457512174847068,-25.858102639325296],
[31.457625276285512,-25.857988587303396],
[31.457745875371927,-25.857868826385186],
[31.4578787996669,-25.857745303602826],
[31.458020824201753,-25.857619194370272],
[31.458168903871865,-25.857492657060448],
[31.458322400158693,-25.857367240305848],
[31.458483100015087,-25.85724542533609],
[31.45864916342822,-25.857125957596793],
[31.458818907766442,-25.85701075893934],
[31.458988252805682,-25.856902416713126],
[31.459153301783488,-25.85680242469209],
[31.459307652426276,-25.85671398536209],
[31.45944604729732,-25.856634855813923],
[31.45956400417549,-25.85655796827558],
[31.459651732141765,-25.856476304437876],
[31.459698904281083,-25.856389518061746],
[31.459699344948888,-25.856324854108664],
[31.459699463659376,-25.856307481005388],
[31.459659763087643,-25.856237475978674],
[31.459592446134536,-25.85619161595025],
[31.45950882807,-25.856170566418484],
[31.459412748999227,-25.85616835228757],
[31.45930378444217,-25.85616960594251],
[31.45917918337267,-25.8561693649242],
[31.459038733550756,-25.85616611117706],
[31.458882970073034,-25.85615258537348],
[31.458714452410106,-25.856124652430708],
[31.45854161170604,-25.85608239328775],
[31.458371733368836,-25.85602412621239],
[31.458213300703335,-25.85594793205138],
[31.45807271778176,-25.85585151483548],
[31.457955855378486,-25.85573375490884],
[31.457853133015703,-25.855600094968338],
[31.457755347031707,-25.85545757670576],
[31.45765620037338,-25.85531145755766],
[31.45755201121642,-25.855166191866203],
[31.45744720692312,-25.85502506215687],
[31.45734525887667,-25.85488846682921],
[31.457253395827593,-25.854760488805482],
[31.45718015593951,-25.85464395555408],
[31.45712924891575,-25.854527664220257],
[31.457103662304235,-25.854403447161303],
[31.457099473262133,-25.854267466070667],
[31.457112013408732,-25.85411707784084],
[31.45713984113081,-25.853952870628405],
[31.457182878187325,-25.853777324763655],
[31.457242163295234,-25.85359294755642],
[31.457311987558228,-25.85340064822128],
[31.457381358562884,-25.853200584139586],
[31.457445873228494,-25.85299395680562],
[31.45749494023937,-25.852779167224753],
[31.45752847775708,-25.85255690697562],
[31.457549449947237,-25.852329604227805],
[31.45755435934626,-25.852099607111143],
[31.457545260905135,-25.8518709167094],
[31.45752813061881,-25.851648842619966],
[31.457515884550503,-25.851543182172065],
[31.457503637582874,-25.851437520824902],
[31.457476584177073,-25.851237936981136],
[31.457457479878826,-25.851056094962757],
[31.457448168298356,-25.850885245357745],
[31.457450675608243,-25.85072101026634],
[31.45746628514098,-25.850561285275035],
[31.457493765724735,-25.850405188148898],
[31.457526024406548,-25.85025978935772],
[31.457552731573344,-25.85013114403705],
[31.457572449209238,-25.850004366608175],
[31.457569300682735,-25.849876281565116],
[31.457538324434154,-25.849760096351304],
[31.45747663723705,-25.84965786411908],
[31.457383653632746,-25.84956926291113],
[31.45726868520171,-25.84949256243175],
[31.457134559412452,-25.849430666591957],
[31.456986531903,-25.849380458341443],
[31.456829220692043,-25.849345428848437],
[31.45666764039936,-25.849327901061713],
[31.45651419717251,-25.849322539303728],
[31.456377537093715,-25.849315735033088],
[31.456260087433122,-25.849294312282666],
[31.456161367053483,-25.84924938485119],
[31.456078335346945,-25.849178127068853],
[31.456012753186087,-25.849082377149955],
[31.455959498032485,-25.848969311684357],
[31.455908082891767,-25.848841867857857],
[31.455855254916116,-25.848700871248184],
[31.455801865763533,-25.848545441418935],
[31.455749116928246,-25.848374830134276],
[31.455700504075082,-25.84818850589477],
[31.45565949588905,-25.847988575812053],
[31.45562163533026,-25.84777810657431],
[31.455582254017884,-25.847561823219507],
[31.455530972876147,-25.847344657629776],
[31.45546306956504,-25.84713477744799],
[31.455381025314125,-25.84693305861373],
[31.455290629958654,-25.846740225081305],
[31.455200048443544,-25.846554621198777],
[31.455118963769223,-25.846375659708883],
[31.45505234109271,-25.846210011782546],
[31.45500105995103,-25.846062451920602],
[31.454967362353955,-25.845930178734704],
[31.454950820224212,-25.84580204063161],
[31.454953407573726,-25.845669875364422],
[31.45497181759538,-25.845533467095777],
[31.454994549758737,-25.845391829269488],
[31.455013252959304,-25.845251606076715],
[31.455021818102466,-25.845117572018296],
[31.45501885573566,-25.844988047160598],
[31.45499422960006,-25.844862949665355],
[31.454936492225386,-25.84474313568711],
[31.45485039203203,-25.844630205119813],
[31.454740813238004,-25.844523383647186],
[31.454615277772916,-25.84441915042339],
[31.454479765229053,-25.844314356022664],
[31.45434283805156,-25.84420241201167],
[31.45420649813144,-25.84408443894563],
[31.454074533413063,-25.843955686605625],
[31.453946010400102,-25.84381535729284],
[31.453820876032637,-25.84366907806543],
[31.45370462696701,-25.843515515228773],
[31.45360211774357,-25.84335600337681],
[31.45351679186649,-25.843191154947817],
[31.453449181734413,-25.84301998428481],
[31.453399555345243,-25.84284120985393],
[31.453370553108584,-25.842655018714083],
[31.45336289538136,-25.84246026512909],
[31.45337308829744,-25.84225854719415],
[31.453397207215403,-25.842052026879514],
[31.453434054238244,-25.841842839175627],
[31.453477703733085,-25.84163170443958],
[31.453527464121237,-25.841420942022808],
[31.453584589057584,-25.841214848886125],
[31.45364622229539,-25.841015265042415],
[31.453703213232757,-25.840822697709314],
[31.453755347831077,-25.840643096801443],
[31.453802920168698,-25.84047344689202],
[31.4538451298489,-25.84030094343376],
[31.45388152271414,-25.840120701309274],
[31.453914206775266,-25.839934670248795],
[31.45394446266687,-25.839746318937443],
[31.453973651962542,-25.83955799370642],
[31.45399947959237,-25.83937233586454],
[31.454021358299087,-25.839188733872902],
[31.454039181063422,-25.8390102796007],
[31.454054390397857,-25.838841617146898],
[31.454064527555943,-25.83867700883684],
[31.454071598925225,-25.838515415953623],
[31.454076161185924,-25.838362120215606],
[31.454079309712426,-25.8382251246897],
[31.4540837919335,-25.83810242928365],
[31.454088359590173,-25.838024155890025],
[31.453690693770568,-25.83821258724106],
[31.453426404604954,-25.838362548292935],
[31.453277983192436,-25.83839113234484],
[31.453129560880598,-25.838419716396743],
[31.452758598630453,-25.838019688058125],
[31.452424296344248,-25.83754432171304],
[31.452312863148165,-25.837385865665112],
[31.45188756026272,-25.836978149022343],
[31.45182212109404,-25.836915415913666],
[31.45175668282468,-25.836852682805045],
[31.451348532708664,-25.83638590228753],
[31.451162677915306,-25.836085589878053],
[31.450533382709523,-25.835753221134212],
[31.449927170402646,-25.83551582799305],
[31.44966750734966,-25.83535433223659],
[31.449407845195935,-25.83519283558087],
[31.449048209007685,-25.83494148226356],
[31.44886818811642,-25.834761705088567],
[31.44856867520423,-25.834600329841294],
[31.44822917303793,-25.834403012289158],
[31.447949724000637,-25.83427764139998],
[31.447650212887083,-25.834116264354066],
[31.447450269314515,-25.833936547433666],
[31.447250459740985,-25.833792896025273],
[31.447110229353655,-25.83359496063889],
[31.44700985062417,-25.833396903844005],
[31.446790253267636,-25.83328937910153],
[31.44667028730396,-25.83318154769023],
[31.44641070159264,-25.833038079743517],
[31.446110790280784,-25.832768500765667],
[31.445750561439297,-25.832354841402775],
[31.44564991561117,-25.83208464998654],
[31.445669167398194,-25.83190425767532],
[31.44565886836216,-25.831789722717474],
[31.445861294962867,-25.831545582862304],
[31.446047735214847,-25.831340476281923],
[31.446094551222586,-25.83129763797558],
[31.446132550277014,-25.831297341199274],
[31.44637128520668,-25.831127545599713],
[31.446494236020214,-25.83101406914392],
[31.446569842923964,-25.830909403346254],
[31.44662951204242,-25.830730137885496],
[31.446645925569044,-25.830585847958446],
[31.446636209293672,-25.830043128687407],
[31.446606404861768,-25.829888330182257],
[31.446499981788804,-25.82962156967966],
[31.446491486792752,-25.82919149409213],
[31.446536556317085,-25.829055511202853],
[31.446766709016458,-25.82880613009877],
[31.446802929211856,-25.82870402107369],
[31.44692383136976,-25.828550474424787],
[31.446997555093162,-25.82842279587527],
[31.44707062141208,-25.828252697204164],
[31.447150920978288,-25.827990778651497],
[31.447217517574416,-25.827850936771256],
[31.447316237954055,-25.827527763595356],
[31.447546492276842,-25.827041081081006],
[31.44778218300138,-25.826754403293023],
[31.447992288013722,-25.826533063050363],
[31.448069210625647,-25.826487514187363],
[31.448157820826793,-25.826473069276688],
[31.44825656818614,-25.826555355445237],
[31.448296382072442,-25.826640078776563],
[31.448357488307465,-25.82684361424134],
[31.448358477561726,-25.826894609398664],
[31.448436096248884,-25.8271128047121],
[31.44846742503171,-25.82724864370988],
[31.448591595325922,-25.82748664389345],
[31.44869156306521,-25.82762795796384],
[31.44881177184584,-25.82774113674401],
[31.448917122027638,-25.82774902469771],
[31.449021645732444,-25.827722886801723],
[31.449115472900814,-25.82763729922192],
[31.44939337330561,-25.827198305057664],
[31.44964813235549,-25.82683845213279],
[31.449843849114416,-25.826610926353112],
[31.450042563313673,-25.82641128225481],
[31.45006983075814,-25.826343048892568],
[31.45006761572796,-25.826152962788967],
[31.45001773842796,-25.825991727835913],
[31.449946570577822,-25.8258446392187],
[31.449923919353466,-25.825762299990117],
[31.449935740042406,-25.825696912982096],
[31.44998520455357,-25.82561714761323],
[31.45001713318419,-25.82559421310242],
[31.450087266813966,-25.82549434608717],
[31.45014395288115,-25.825451386372322],
[31.45023648772377,-25.82528880693286],
[31.45026458344381,-25.82525455535341],
[31.450292678264532,-25.82522030377396],
[31.45037012967782,-25.825077718961495],
[31.45046224993291,-25.82483182902564],
[31.450546535294393,-25.82472903022051],
[31.45064208016794,-25.82470309916863],
[31.450682359903055,-25.824699994708965],
[31.45074641591441,-25.824733644641924],
[31.450829093288064,-25.824733117639198],
[31.450920119068314,-25.82467272006994],
[31.450966269577748,-25.824587098315874],
[31.451059763996966,-25.82446720969392],
[31.4512475190578,-25.824166774976618],
[31.451322590864947,-25.824081153222608],
[31.45147479122869,-25.823820363319044],
[31.451612515205454,-25.823529560340887],
[31.451768505312316,-25.823269299238746],
[31.451890958800732,-25.82313388472096],
[31.452010972428468,-25.823064552387166],
[31.45210661622741,-25.823038800300367],
[31.452368935877757,-25.823019570097017],
[31.4524097471122,-25.82302494894219],
[31.452488162598684,-25.823084548812858],
[31.45253248028979,-25.823146602034],
[31.45255771886366,-25.823305926827004],
[31.452724087146976,-25.82380826294093],
[31.452865716879387,-25.82407337408688],
[31.45290537338434,-25.82411603522678],
[31.452985575823732,-25.82414710590416],
[31.45315839674265,-25.82414313719596],
[31.453220242220482,-25.82411974672891],
[31.45330753311623,-25.824047897192656],
[31.45338359327826,-25.823907662308727],
[31.453452718768006,-25.82370128588559],
[31.453580213855844,-25.823423149858456],
[31.453617942214294,-25.82330009472355],
[31.453661327308453,-25.823222153179756],
[31.4538877010566,-25.822563791885443],
[31.45394671636791,-25.822347969882856],
[31.453960370774553,-25.822319494648923],
[31.453974961375422,-25.822222688026613],
[31.4540695844438,-25.821981751556564],
[31.454106736336882,-25.821838478762743],
[31.454156297974805,-25.821732988286726],
[31.454155734999233,-25.821690114906858],
[31.454209833716902,-25.82151898831063],
[31.454234363625005,-25.821361132110496],
[31.454312181062335,-25.821080894367753],
[31.45433210014636,-25.82049890889749],
[31.454291429206137,-25.820137714184114],
[31.454231106280588,-25.81985357518232],
[31.454235174813505,-25.819430230120986],
[31.45420108511206,-25.81912091639714],
[31.454095165659396,-25.81870044287109],
[31.453999483189648,-25.81847968808711],
[31.453867692939752,-25.817969303040854],
[31.453837232002684,-25.817748524874503],
[31.453841448923754,-25.81704189426796],
[31.453802284348,-25.817037664756356],
[31.453344036598082,-25.81703909467842],
[31.453025394206634,-25.817076154840606],
[31.45260740640674,-25.817185657192283],
[31.45214997973784,-25.817403481085762],
[31.451772658381515,-25.81772925420165],
[31.451594026942814,-25.817910142039352],
[31.45141539640349,-25.81809103077643],
[31.451157408787424,-25.818362331955996],
[31.451086942408494,-25.820611555279015]

]



const ping1 = 'https://cdn-icons-png.flaticon.com/512/7954/7954340.png';
const pinr1 = 'https://cdn-icons-png.flaticon.com/512/4931/4931226.png';

const ping = L.icon({
    iconUrl: ping1,
    iconSize: [24, 30],
    iconAnchor: [0, 44],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const pinr = L.icon({
    iconUrl: pinr1,
    iconSize: [24, 30],
    iconAnchor: [0, 44],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });
export const markers = [
    {
        'loc': [-26.364545099553485, 31.09648745829029],
        'pin': ping
    },
    {
        'loc': [-26.339212216112486, 31.461178574850244],
        'pin': pinr
    },
    {
        'loc': [-26.418991999601815, 31.362231372682817],
        'pin': ping
    },
    {
        'loc': [-26.056375726665802, 31.43573500857862],
        'pin': ping
    },
    {
        'loc': [-26.37720946016673, 31.55164458826047],
        'pin': ping
    },


    {
        'loc': [-26.656646923374833, 31.392552824051357],
        'pin': pinr
    },
    {
        'loc': [-26.633905460478, 31.48867296329972],
        'pin': pinr
    },
    {
        'loc': [-26.423965568442306, 31.53107890708576],
        'pin': pinr
    },
 
 

  ] ;