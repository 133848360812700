import React, { useState, useEffect } from "react";
import './data-search.css';
import * as jsx from './data-search.module';
import ExploreVisualiseModal from "./explore-visualise-modal";

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AdminService from '../../../services/admin.service';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DataSearch = (props) => {
  const [loader, setLoader] =useState(false);
  const [filterData, setFilterData] = useState([]);
  const [indicatorList, setIndicatorList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedInds, setSelectedInds] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    setLoader(true);

    AdminService.httpNoAuthGet('/data-retrieval/data/get-ius-area-list').then((res)=>{
      if(res){
          setFilterData(res);
          setAreaList(res?.area);
          res?.indicator.forEach(x => {
            x.label = ""+x.name+' | '+x.unit;
          });
          setIndicatorList(res?.indicator);
          setLoader(false);
      }
    })
    .catch((err) => {
        setLoader(false);
        console.log(err)
    });

  },[props]);

  const search = () => {
    if(selectedInds?.length == 0) {
      toast.warning("Please select Indicator to search data!");      
      return;
    }

    // if(selectedAreas?.length == 0) {
    //   toast.warning("Please select Area to search data!");
    //   return;
    // }

    setLoader(true);
    let sInds = [];
    selectedInds.forEach(ind => {
      sInds.push(ind._id);
    })
    let areas = [];
    selectedAreas.forEach(a=> {
      areas.push(a.area_code);
    })
    let dataToSend = {
      "iu_id": sInds,
      "area": areas
    };
    AdminService.httpUserPost('/data-retrieval/data/get-iu-chart-data', dataToSend).then((res)=> {
      let data = res?.data;
      setLoader(false);
      //navigate to data search result
      if(data) {
        navigate('/dashboard/data-search-result', {
          state: {
            data: data
          }
        });
      }
      
    }).catch((err) => {
      setLoader(false);
      console.log(err)
    });
  }

  const handleChange = (e) => {
    // to find out if it's checked or not; returns true or false
    const checked = e.target.checked;
    
    // to get the checked value
    const checkedValue = e.target.value;
    
    // to get the checked name
    const checkedName = e.target.name;
    
    //then you can do with the value all you want to do with it.
  };

  return (
        <>
          <div className="custom-tabber">
            <div className="d-flex justify-content-center align-items-center flex-wrap">
              <div className="dataSearch w-100">
                <ul>
                  <li>Search & Visualise</li>
                  <li data-toggle="modal" data-target="#exploreVisualise">Explore & Visualise</li>
                </ul>
              </div>
              <div className="dataSearchInput">
                <ul>
                  <li>
                    <Autocomplete
                      multiple
                      options={indicatorList}
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        setSelectedInds(newValue);
                      }}
                      getOptionLabel={(option) => option.label}                      
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      style={{ }}
                      renderInput={(params) => (
                        <TextField {...params}  placeholder="Search Disaster Indicator" />
                      )}
                    />
                  </li>
                  <li>
                    <Autocomplete
                      multiple
                      options={areaList}
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        setSelectedAreas(newValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.area_code}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                        )}
                      style={{  }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Geographic Areas" />
                      )}
                    />
                  </li>
                </ul>
              </div>
              <div className="w-100 text-center">
                <Button variant="contained" onClick={()=>search()} className="dsBtn"><img src="assets/lib/images/search.svg" /> Search</Button>
              </div>
            </div>
          </div>    



          {/* =========================================================================================== */}
          <div class="modal fade exploreVisualise" id="exploreVisualise" tabindex="-1" aria-labelledby="DataLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">                                
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt="" /></span>
                        </button>
                        <ExploreVisualiseModal />
                    </div>                                   
                </div>
            </div>
          </div>
          {/* =========================================================================================== */}

        </>
    );




    


}
export default DataSearch;
