
import React, { useEffect, useRef, useState } from "react";

// import Select from 'react-select';

import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InfoIcon from '@mui/icons-material/Info';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';


import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import AdminService from '../../services/admin.service';
import { text } from "@fortawesome/fontawesome-svg-core";
import * as $ from 'jquery';

const DataEntryModal = (props) => {
    const form = useRef();

    const [unit, setUnit] = useState({
        unit: '',
        ius: '',
        unitName: ''
    })
    // new update
    // const [type,setType] = useState('');


    const [subData, setSubGroup] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [labelData, setLabelData] = useState([]);
    const [valueRadio, setValueRadio] = useState();
    const [dfInd, setDfInd] = useState({
        dfLabel: '',
        dfUnit: ''
    });
    const [areaTree, setAreaTree] = useState([]);
    const [getTreeData, setTreeData] = useState([]);
    const [TimeMsg, setTimeMsg] = useState('Please enter time period!')

    const [indLabel, setIndLabel] = useState({
        indCode: '',
        indText: '',

    })

    const [disasters, setDisasters] = useState([]);


    const [FormState, setFormState] = useState(
        {
            indicData: '',
            subGroup: '',
            areaCode: '',
            areaName: '',
            timePeriod: '',
            source: '',
            dataValue: '',
            footnote: null,
            disasterCode: null
        });
    
    const [fLevel, setFLevel] = useState([]);
    const [type, setType] = useState('');
    const [expanded, setExpanded] = useState([]);

    const toggleArrow = (e) => {
        e.target.parentElement.querySelector(".nested").classList.toggle("active");
        e.target.classList.toggle("caret-down");
    }

    console.log('dataentrymodalprop',props)
    
    useEffect(() => {
        
        const filterInd =  props.indicatorData;
        const filterIndData = filterInd.filter(x =>x.unit.en =="Tons" || x.unit.en =="Number" || x.unit.en =="USD" || x.unit.en =="Hectare" || x.unit.en =="Km")
        

        setLabelData(filterIndData)
        
        if (props.editData == null || props.editData == undefined || props.editData.length == 0) {
            setFormState({
                ...FormState,
                indicData: '',
                subGroup: '',
                areaCode: '',
                areaName: '',
                footnote: '',
                dataValue: '',
                source: '',
                timePeriod: '',
                disasterCode: null
            });



            setIndLabel({ ...indLabel, indCode: '', indText: '' })
            setUnit({ ...unit, unit: '', ius: '', unitName: '' })

        }
        else {



            const data = props.editData[0];
            
            // console.log('ed eddyy', data)


            setFormState({
                ...FormState,
                indicData: data.indicator,
                subGroup: data.iusData[0].subgroup.name,
                areaCode: data.area_code,
                areaName: data.areaData[0].name,
                footnote: data.footnote,
                dataValue: data.new_value,
                source: data.source.publisher,
                timePeriod: data.time_period.start_time_period,
                disasterCode: {
                    label: data.disasterData?.[0]?.title?.en != undefined ? data.disasterData?.[0]?.title?.en : 'None',
                    code: data.disasterData?.[0]?.disaster_code
                }
            });

            console.log('data.disasterData?.[0]?.title?.en',data.disasterData?.[0]?.title?.en)

            let i = -1;
            let j = -1;
            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id == props.editData[0].iu_id) {
                    // console.log('index', i)
                    setDfInd({ ...dfInd, dfLabel: i, dfUnit: 0 })
                    // console.log(dfInd)


                    item.subgroup?.forEach((item2, index2) => {
                        j = j + 1;
                        setSubGroup([...subData, item2])
                        if (item2._id == props.editData[0].ius_id) {
                            setDfInd({ ...dfInd, dfLabel: i, dfUnit: j })
                        }

                    })

                }

            })


            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id == props.editData[0].ius_id) {
                    // console.log('index', index)
                    setDfInd({ ...dfInd, dfUnit: i })


                }
            })
            // console.log("This is indicatorrr--", data)
            setIndLabel({ ...indLabel, indCode: data.iu_id, indText: data.indicator })
            setUnit({ ...unit, unit: data.iusData[0].subgroup.name, ius: data.ius_id, unitName: data.iusData[0].unit })
            getDisasterData(data.iu_id, false);
        }


        if (props.areaTree != '' && props.areaTree != null) {
            // console.log("test by react team111111111", areaTree)
            let flatLevel = [];


            props.areaTree.forEach((item) => {


                item.areaSubData = [];

                flatLevel.push(item)


            })

            setFLevel(flatLevel);
            // console.log('level1', flatLevel)
            const data = setLevelData(flatLevel)
            const levelCount = getHowManyLevel(data);

            // console.log('level1', data)

            setAreaTree(data)




        }

    }, [props]);

    useEffect(()=> {

        let aArrs = [];
        props.loginArea.forEach(areaCde => {
            let code = areaCde;
            // let areaArr = [];
            do {
                let n = fLevel.filter(x => x.area_code == code);

                if (n?.length > 0) {
                    if(!aArrs.includes(code)) {
                        aArrs.push(code);
                    }
                    // areaArr.push(n[0]?.label);
                    code = n[0]?.parent_id;
                } else {
                    code = '';
                }

            } while (code != '')
            // aArrs.push(areaArr.reverse());
        });
        setExpanded(aArrs);
    }, [props.loginArea, fLevel]);

    const getDisasterData = (iuId, flag = true) => {
        
        const dataToBeSend = {
            "_id": iuId
        }

        AdminService.httpPost('/data-retrieval/ius/get-indicator-disaster', dataToBeSend).then((res)=>{
            
            if(res){
                // console.log("Sudhanshu Data:", res);                        
                let disasters = res.data?.[0].disaster;
                let d = [];
                disasters?.forEach(x => {
                    d.push({
                        label: x?.[0]?.level_name?.en == undefined ? 'None' : x?.[0]?.level_name?.en,
                        code: x?.[0]?.level_code == undefined ? null : x?.[0]?.level_code 
                    });
                })
                setDisasters(d);
                if(d?.length > 0 && flag) {
                    setFormState({
                        ...FormState, disasterCode: d[0]
                    });
                }
            }

        }).catch((err) => {
            // this.setState({ loader: false })
            console.log(err)
        });
        
    };

    console.log('disasters',disasters);


    const getSubIndigator = (e, v, r) => {
        
        // console.log("rrrr", r + "    gggg    " + JSON.stringify(v))

        if (r != 'clear') {

            if(['Number', 'Hectare lost', 'Hectare', 'Tons', 'USD', 'Km', 'Local Currency']?.includes(v.unit?.en)){
                $('#typeChange').attr('type', 'number');
            } else{
                $('#typeChange').attr('type', 'text');
            }

            console.log("v of indic", v)

            setFormState({ ...FormState, indicData: v.name })

            setIndLabel({ ...indLabel, indCode: v._id, indText:  v.name })

            // console.log('lb', labelData)

            getDisasterData(v._id);

            labelData?.map(item => {

                // if (item._id == v._id) {
                //     console.log("This is item in subgroupd", item)

                //         setSubGroup([{
                //             ...subData,
                //             label: item.subgroup[0].name.en, value: item.subgroup[0]._id, name: item.unit.en
                //         }])
                // }

                if (item._id == v._id) {
                    console.log("This is item in subgroup", item);
                    if (item.subgroup.length > 1) {
                      const subgroups = item.subgroup.map((subgroup) => ({
                        label: subgroup.name.en,
                        value: subgroup._id,
                        name: item.unit.en,
                      }));
                      setSubGroup(subgroups);
                    } else if (item.subgroup.length === 1) {
                      setSubGroup([
                        {
                          label: item.subgroup[0].name.en,
                          value: item.subgroup[0]._id,
                          name: item.unit.en,
                        },
                      ]);
                    }
                  }


            });

        }
        else {
            setFormState({
                ...FormState, indicData: '', subGroup: ''
            });
            setIndLabel({indText: ''})

            setSubGroup([...subData, subData])
        }


    }

    const setDisaster = (e, v, r) => {
        if (r != 'clear') {
            setFormState({
                ...FormState, disasterCode: v
            });

        }
        else {

            setFormState({
                ...FormState, disasterCode: null
            });
        }

    }

    const getUnit = (e, v, r) => {
        
        if (r != 'clear') {

            setUnit({ ...unit, unit: v.label, ius: v.value, unitName: v.name })
            setFormState({
                ...FormState, subGroup: v.label
            });

        }
        else {

            setUnit({ ...unit, unit: '', ius: '', unitName: '' })
            setFormState({
                ...FormState, subGroup: ''
            });
        }


    }

    const handleChange = (event) => {
        
        event.preventDefault();
        setFormState({ ...FormState, [event.target.name]: event.target.value });
        console.log("unit testtttt", dfInd);

        // if(['Number', 'Hectare lost', 'Hectare', 'Tons', 'USD', 'Km', 'Local Currency']?.includes(unit)){
            
        //     }

    }
   
    

    const handleSubmit = (e) => {
        e.preventDefault();
        let dataToSend = [];


        if (props.isEdit == true) {
            dataToSend = {
                "id": props.editData[0]._id,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote,
                "disaster": FormState.disasterCode?.code
            }
            // console.log("Data being sent ----", dataToSend)

        }
        else {
            // console.log(unit)
            dataToSend = {
                "id": null,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote,
                "disaster": FormState.disasterCode?.code

            }

        }
        props.newDataEntry(dataToSend);
        // console.log("This is dataToSend dataEntry-->", dataToSend)

        
            setFormState({
                ...FormState,
                areaCode: '',
                areaName: '',
                timePeriod: '',
                source: '',
                dataValue: '',
                footnote: null,
                disasterCode: null
            })
    
            setIndLabel({
                ...indLabel,
                indCode: '',
                indText: '',
            })
            setUnit({
                ...unit,
                unit: '',
                ius: ''
            })
            setDisasters([]);
        





    }

    // area tree sctructure=========================//

    const getHowManyLevel = (obj) => {
        let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
        while (/}{/g.test(res)) {
            res = res.replace(/}{/g, '')
        }
        return res.replace(/}/g, '').length
    }




    const setLevelData = (flat) => {
        
        var roots = []

        var all = {}

        flat.forEach(function (item) {
            all[item.area_code] = item
        })
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code]
            if (item.parent_id === null || item.parent_id === '') {
                roots.push(item)
            } else if (item.parent_id in all) {
                var p = all[item.parent_id]
                if (!('areaSubData' in p)) {
                    p.areaSubData = []
                }
                p.areaSubData.push(item)
            }
        })

        return roots
    }


    const renderTestTree = (nodes) => {
        // console.log('<><><>',nodes,props.loginArea) 
        if(JSON.parse(localStorage.getItem('userInfo')).role!=1){
            nodes = nodes.filter(a=> props.loginArea.includes(a.area_code) || a.areaSubData?.filter( b=> props.loginArea.includes(b.area_code) || b.areaSubData?.filter(c=> props.loginArea.includes(c.area_code) || c.areaSubData?.some(d=> props.loginArea.includes(d.area_code) )  ).length > 0 ).length>0)
        }
        return (
            <>
            {nodes && nodes.map(node=> {
                
                return (
                    <li key={node.value}>
                            <span className={expanded.includes(node.value)?"caret caret-down":"caret"} onClick={(e)=>toggleArrow(e)}></span>
                            <div 
                            className={!props.loginArea.includes(node.value) && JSON.parse(localStorage.getItem('userInfo')).role!=1 ? 'text-gray':'' } >
                                <input 
                                disabled={!props.loginArea.includes(node.value) && JSON.parse(localStorage.getItem('userInfo')).role!=1   } 
                                value={FormState.areaCode}
                                checked={ FormState.areaCode==node.value } 
                                onChange={(e)=>{ setFormState({ ...FormState, areaCode: node.area_code, areaName: node.label }) }} 
                                type="checkbox" name="arearadio" /> 
                                <span for="arearadio">{node.label}</span></div>
                            <ul className={expanded.includes(node.value)?"nested active":"nested"}>
                                {
                                    renderTestTree(node.areaSubData)
                                }
                            </ul>
                    </li>
                )
            })

            }
            </>
        );
    }


    const renderTree = (nodes) => (

        nodes.map((item) => {
            return (<>

                <TreeItem
                    className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
                    disabled={!props.loginArea.includes(item.area_code)}
                    key={item.area_code} nodeId={item._id} label={item.name}
                    onClick={() => { setFormState({ ...FormState, areaCode: item.area_code, areaName: item.name }) }} >

                    {Array.isArray(item.areaSubData) && item.areaSubData != null
                        ? renderTree(item.areaSubData)
                        : null}
                </TreeItem>

            </>);


        })


    );
    
    const cancelModal = (event) => {
        event.preventDefault();
        props.closeModal(event.target);

      }


    return (

        <>
            <div className="formBox dataEntryForm">
                <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0 metadata_form">
                            <div className="subgroup_indicator  mb-30">
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label className="label">Indicator <span className="text-muted">| Unit</span></label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">



                                               <Autocomplete
                                                    value={({name: indLabel?.indText})}
                                                    readOnly={props.isEdit}
                                                    onChange={(event, value, reason) => { getSubIndigator(event, value, reason) }}
                                                    options={labelData?.map((option) =>  option)}
                                                    // readOnly= {props.isEdit} 
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextValidator  {...params}
                                                    value={indLabel?.indText}
                                                        name="indicData"
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                    />}
                                                    getOptionLabel={(option) => (option.unit ? option.name?.en + ' | '+option.unit?.en:option.name?.en) || ""}

                                                /> 
                                                {console.log("labelData",   )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Subgroup</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">
                                                {console.log("This is subgroup optionss--", subData)}
                                                <Autocomplete
                                                    value={{ label: FormState.subGroup }}
                                                    onChange={(event, value, reason) => { getUnit(event, value, reason); }}
                                                    options={subData}
                                                    renderInput={(params) => <TextValidator  {...params}
                                                        name="subGroup"
                                                        value={FormState.subGroup}
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                    />}
                                                    defaultValue={subData[dfInd.dfUnit]}
                                                    getOptionLabel={(option) => option.label}
                                                />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Disaster</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown">
                                        <div className="mat-form-field-wrapper">
                                        <Autocomplete
                                            value={FormState.disasterCode}
                                            onChange={(event, value, reason) => { setDisaster(event, value, reason); }}
                                            options={disasters }
                                            // options={FormState.disasterCode}
                                            renderInput={(params) => <TextValidator  {...params}
                                                name="disaster"
                                                value={FormState.disasterCode?.label}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            />}

                                            getOptionLabel={(option) => option.label}
                                            disableClearable
                                        />
                                        {console.log('disasters',disasters)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Area</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown dataEntryModal">
                                        <div className="mat-form-field-wrapper area-btn">
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    <TextValidator
                                                        className=" w-100"


                                                        label=""
                                                        onChange={handleChange}
                                                        name="pareaName"
                                                        value={FormState.areaName}
                                                        validators={['required']}
                                                        errorMessages={['Please select a area!']}
                                                    />

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <TreeView

                                                        aria-label="file system navigator"
                                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                                        defaultExpandIcon={<ChevronRightIcon />}

                                                        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                    >
                                                        {renderTree(areaTree)}

                                                    </TreeView>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                            <Dropdown >
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    {FormState.areaName}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                    <ul id="myUL1">
                                                        {renderTestTree(areaTree)}
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="inPuthidden ">
                                        <TextValidator
                                            type="hidden"
                                            style={{ border: "0px", outline: 'none' }}
                                            name="password"
                                            autoComplete="off"
                                            className=" w-100"
                                            value={FormState.areaName}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Time Period</label>
                                    </span>
                                </div>



                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100"
                                                onChange={handleChange}
                                                name="timePeriod"
                                                value={FormState.timePeriod}

                                                inputProps={{ pattern: "^[0-9]{4}$|^[0-9]{4}.(1[0-2]|0[1-9])$|^[0-9]{4}.(1[0-2]|0[1-9]).(3[01]|[12][0-9]|0[1-9])$|^[0-9]{4}-[0-9]{4}$|^[0-9]{4}.(1[0-2]|0[1-9])-[0-9]{4}.(1[0-2]|0[1-9])$|^[0-9]{4}.(1[0-2]|0[1-9]).(3[01]|[12][0-9]|0[1-9])-[0-9]{4}.(1[0-2]|0[1-9]).(3[01]|[12][0-9]|0[1-9])$" }}

                                                validators={['required']}
                                                errorMessages={['Please enter a valid time period format!']}
                                            />
                                            <Tooltip title="Valid formats: YYYY, YYYY.MM, YYYY.MM.DD, YYYY-YYYY, YYYY.MM-YYYY.MM, YYYY.MM.DD-YYYY.MM.DD" placement="top">
                                                <a style={{ 'float': 'right', 'margin': '-36px -30px 0 0', 'cursor': 'pointer', 'color': '#A6170A' }} href="javascript:;"><InfoIcon /></a>

                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Source</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100" label="" onChange={handleChange} name="source" value={FormState.source} validators={['required']} errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Data Value</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator id="typeChange" className="w-100" label="" onChange={handleChange} name="dataValue" value={FormState.dataValue} validators={['required']} errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Footnote</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100" label="" onChange={handleChange} name="footnote" value={FormState.footnote} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                                <Button className="addbtn" color="error" variant="contained" type="submit">
                                    {props.editData[0] != null ? 'Update' : 'Add'}
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>


        </>


    );


}


export default DataEntryModal