import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMapEvents } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
// import GeoHash from '@jollie/geohash';
import { styled } from '@mui/material/styles';
import { pol, pol2, markers } from './mapview-data';
import * as $ from 'jquery';
import 'leaflet/dist/leaflet.css';
import './mapview.css'
// import {cordData} from './SWZ_L03_2022'

import "leaflet-boundary-canvas";

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import FaciliyModal from '../../../Common/components/modal/facility.modal';
import FilterForm from '../tracker/filter';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
function MapView(props) {

  const jsonFile = "assets/map/json/level2.json";

  var exceptions = {
    'Eswatini': [-26.28144330014251, 31.235107445706586],
    'Eswatini': [-26.323900540511485, 31.556833542547704]
  }

  var southWest = L.latLng(-27.415238956839033, 30.691342436728974),
    northEast = L.latLng(-25.799508485159393, 31.968734069248967);
  var bounds = L.latLngBounds(southWest, northEast);

  // $(document).ready(function () {

  //   let width = $(window).width();

  //   console.log('width', width)
  //   if(width < 1950 && width > 1701) {
  //     map.options.minZoom = 8.49;
  //     map.options.setZoom = 8.49;
  //     map.options.zoom = 8.49;
  //   }
  //   else if (width < 1700 && width > 1490) {
  //     map.options.minZoom = 8.3;
  //     map.options.setZoom = 8.49;
  //     map.options.zoom = 8.49;
  //   }
  //   else if (width < 1400) {
  //     map.options.minZoom = 8;
  //     map.options.setZoom = 8;
  //     map.options.zoom = 8;
  //   }

  // })






  const [reset, setReset] = useState(false);

  const [areaData, setAreaData] = useState([]);

  const [filters, setFilters] = useState({
    isFilter: false,
    location: '',
    facility_name: '',
    waste_types: '',
    profileType: '',
    date_awarded_from: '',
    date_awarded_to: '',
    area_sq_mtr: '',
    permissionStatus: '',
    mapData: []


  });


  const [show, setShow] = useState(false);
  const [dumpData, setDumpData] = useState();
  const handleClose = () => setShow(false);
  const [marker, setMarkers] = useState([]);
  const [mapObj, setMapObj] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [loader, setLoader] = useState(false)
  const [isCompass, setCompass] = useState(false)
  const [filterAreaData, setFilterAreaData] = useState([])
  const pinr = 'assets/map/red.svg';
  const ping = 'assets/map/green.svg';


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const pinMB = L.icon({
    iconUrl: ping,
    iconSize: [14, 14],
    iconAnchor: [7, 14],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const pinMB2 = L.icon({
    iconUrl: pinr,
    iconSize: [14, 14],
    iconAnchor: [7, 14],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });



  const filterData = (data) => {




    const filData = {
      location: data.location,
      facility_name: data.facName,
      permissionStatus: data.permType,
      area_sq_mtr: data.area_sq_mtr,
      wasteType:Array[data.wasteType],
      facility_type: data.facType,
      date_awarded_from: data.date_awarded_from,
      date_awarded_to: data.date_awarded_to,

    }

    console.log('match', filData)


    // setLoader(true)

    let needData = Object.fromEntries(Object.entries(filData).filter(([_, v]) => v != null && v != ''));

    var dData = [];

    if (data.permType != '3') {
      dData = [];

      dData = filters.mapData.filter(function (item) {
        for (var key in needData) {
          if (item[key] === undefined && item[key] === null || item[key] != needData[key])
            return false;
        }
        return true;
      });

    } else if(data.permType == '3' && 
    data.location=='' && data.facName=='' &&
    data.facType=='' &&
     data.areaSqf==undefined && data.dateFrom==undefined && data.dateTo==undefined) {

   
      filters.mapData.filter(function (item) {
        dData.push(item)
      })
    



    }
    else{
      filters.mapData.filter(function (item) {
        for (var key in needData) {
          if ( item[key] == needData[key] &&
             item['permissionStatus'] == 1 ){

              dData.push(item)
          
            
             }
          if ( item[key] == needData[key] && item['permissionStatus'] == 0){

            dData.push(item)
        
          
          }

        }
       
      });

     
    }



    map.eachLayer((layer) => {
      layer.remove();
    });

    setLoader(false)

    updateMarkers(dData);

    $('#closeFiler').trigger('click');



  }






  const [map, setMap] = useState(null);
  useEffect(() => {

    setFilterAreaData(props.areaList)
console.log('arealist in mapview====>', props.areaList)


    const markerData = props.dumpDataMain;

    setDumpData(props.dumpDataMain)
    setFilters({ ...filters, mapData: markerData })





    //  regeneratemap



    const fetchGeoJSON = async () => {
      const response = await fetch(
        jsonFile
      );
      const geoJSON = await response.json();

      const mapObjc = L.geoJSON(geoJSON, {
        style: countriesStyle,
        onEachFeature: onEachFeatures,

      }).addTo(map);

      function countriesStyle(feature) {
        return {
          fillColor: "#e7e7e7",
          fillOpacity: 1,
          color: '#fff',
          opacity: 1,
          weight: 1,
        }
      }

      function onEachFeatures(feature, layer) {
        // DO THIS FOR EACH COUNTRY
        // events
        layer.on({
          mouseover: function () {
            this.setStyle({
              'fillColor': '#d4d1d1',
            });
          },
          mouseout: function () {
            this.setStyle({
              'fillColor': '#e7e7e7',
            });
          },
          click: function () {
            console.log(feature.properties)
            // TODO Link to page
            // alert('Clicked on ' + feature.properties.Name1_)
          }
        });

        var label = L.marker(exceptions[feature.properties.Name1_] || layer.getBounds().getCenter(), {
          icon: L.divIcon({

            html: '<p class="show">' + feature.properties.Name1_ + '</p>',
            iconSize: [0, 0]
          })
        }).addTo(map);
        label.bindTooltip(
          feature.properties.name,
          {
            permanent: true,
            direction: 'center',
            className: 'countryLabel'
          }
        );
      }

      setMapObj(...mapObj, mapObjc)



    }


    fetchGeoJSON();

    if (markerData != '' && markerData != null && markerData) {


      for (var i = 0; i < markerData.length; i++) {
        const datato = markerData[i];
        var aMarker = new L.marker([markerData[i].latitude, markerData[i].longitude], { zIndexOffset: 1000, icon: markerData[i].permissionStatus == 1 ? pinMB : pinMB2 })

          .addTo(map).on('click', (e) => { sendModalData(datato) });;
      }




      // map.fitBounds(mapObj.getBounds());
      map.panTo({
        lat: -26.55139426368066,
        lng: 31.455374702908358
      })
    }




    console.log('dumps', dumpData)
  }, [props, props.areaList]);




  const updateMarkers = (markerData) => {



    const fetchGeoJSON = async () => {
      const response = await fetch(
        jsonFile
      );
      const geoJSON = await response.json();

      const mapObjc = L.geoJSON(geoJSON, {
        style: countriesStyle,
        onEachFeature: onEachFeatures,

      }).addTo(map);

      function countriesStyle(feature) {
        return {
          fillColor: "#e7e7e7",
          fillOpacity: 1,
          color: '#fff',
          opacity: 1,
          weight: 1,
        }
      }

      function onEachFeatures(feature, layer) {
        // DO THIS FOR EACH COUNTRY
        // events
        layer.on({
          mouseover: function () {
            this.setStyle({
              'fillColor': '#d4d1d1',
            });
          },
          mouseout: function () {
            this.setStyle({
              'fillColor': '#e7e7e7',
            });
          },
          click: function () {
            console.log(feature.properties)
            // TODO Link to page
            // alert('Clicked on ' + feature.properties.Name1_)
          }
        });

        var label = L.marker(exceptions[feature.properties.Name1_] || layer.getBounds().getCenter(), {
          icon: L.divIcon({

            html: '<p>' + feature.properties.Name1_ + '</p>',
            iconSize: [0, 0]
          })
        }).addTo(map);
        label.bindTooltip(
          feature.properties.name,
          {
            permanent: true,
            direction: 'center',
            className: 'countryLabel'
          }
        );
      }

      setMapObj(...mapObj, mapObjc)



    }


    fetchGeoJSON();

    if (markerData != '' && markerData != null && markerData) {


      for (var i = 0; i < markerData.length; i++) {
        const datato = markerData[i];
        var aMarker = new L.marker([markerData[i].latitude, markerData[i].longitude], { zIndexOffset: 1000, icon: markerData[i].permissionStatus == 1 ? pinMB : pinMB2 })

          .addTo(map).on('click', (e) => { sendModalData(datato) });;
      }




      // map.fitBounds(mapObj.getBounds());
      map.panTo({
        lat: -26.55139426368066,
        lng: 31.455374702908358
      })
    }

  }

  // map.on( 'mousewheel', function() {
  //     console.log("zoom", map.getZoom())

  //     let dt = map.getZoom() ;


  //     if(dt > 8.495 ){
  //       setReset(true)    
  //        console.log(reset)
  //     }else{
  //       setReset(false)    
  //        console.log(reset)
  //     }
  // });

  const mapZoomIn = () => {
    let dt = map.getZoom() + 0.5;
    map.setZoom(dt)
    map.options.minZoom = map.getZoom() + 0.5;
    console.log(dt)

  }

  const mapZoomOut = () => {
    let dt = map.getZoom() - 1;
    map.setZoom(dt)

    if (dt > 7) {
      map.options.minZoom = map.getZoom() - 1;
    }
  }

  const mapReset = () => {
    map.fitBounds(mapObj.getBounds());
    setReset(false)
  }







  const downloadMap = () => {
    alert("data")
    setLoader(true)
    $('.legends, .filter, .content_arrow ').hide();

    var node = document.getElementById('mainDownload');

    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var a = document.createElement('a');
        a.href = dataUrl;
        a.download = "tracker.jpg";
        document.body.appendChild(a);
        console.log('downloadimg',a)
        a.click();
        document.body.removeChild(a);
        $('.legends, .filter, .content_arrow ').show();
        setLoader(false)
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  //   leaflet_zoom_out() {
  //  map.setZoom(this.map_global.getZoom() - 1)
  //   }
  // resetMapZoom() {

  //   this.map_global.fitBounds(this.mapObject.getBounds());

  // }

  const sendModalData = (data) => {

    setModalData(data)

    $('#facilityModal').modal();

  }

  const labelToggle = () => {

    $('.leaflet-marker-icon p').toggleClass('show')
    if($('.leaflet-marker-icon p.show').length){
     $('.img-label').attr('src','assets/lib/images/home/label_on.svg');
    } else{
      $('.img-label').attr('src','assets/lib/images/home/label-off.svg');
    }
  }


  const center = [-26.55342478168653, 31.470847641656025];
  const position = [-26.55139426368066, 31.455374702908358];




  const parisStyle = {
    fillColor: 'red',
    fillOpacity: 0.5,
    weight: 10,
    color: 'black',
    outline: 'black',
    opacity: 0
  };


  const geo2Style = {
    fillColor: 'green',
    fillOpacity: 0.5,
    weight: 0,
    color: 'transparent',
    opacity: 0
  };




  return (
    <>
      <div className='mapBox tracker_Map' id="mainDownload" style={{ background: '#fff' }}>

        <div className="tracker_left_box detail_box">
          <div className="map_value">
            <h3 onClick={(e) => setShow(true)}>Number of Facilities: <span >{props.dumpDataMain?.length}</span></h3>
          </div>
          <div className="map_area text-center position-relative" >
            <div id="mainMapData"  >
              <MapContainer

                center={center}
                zoom={7}
                maxBounds={bounds}
                maxZoom={20}
                whenCreated={setMap}
              >

              </MapContainer>
            </div>

            {isCompass == true && <span class="comapss" ><img src="assets/map/compass.svg" /></span>}
            <div className="filter" data-toggle="modal" data-target="#filtermodal">
              <img src="assets/lib/images/home/filter_icon.svg" alt="" />
            </div>

            <div className="legends">
              <ul>
                <li><Link to='' onClick={mapZoomIn}><img src='assets/lib/images/home/zoom-in.svg' /></Link></li>
                <li><Link to='' onClick={mapZoomOut}><img src='assets/lib/images/home/zoom-out.svg' /></Link></li>
                <li className='wmtm_reset'><Link to='' onClick={mapReset}><img src='assets/lib/images/home/reset.svg' /></Link></li>
                {/* <li className='hr_li position-relative'></li> */}
                <li ><Link to='' onClick={labelToggle}><img src='assets/lib/images/home/label_on.svg' className='img-label'/></Link> </li>
                <li><Link to='' onClick={downloadMap}><img src='assets/lib/images/home/download.svg' /></Link> </li>
                <li><Link to='' onClick={(e) => { isCompass == true ? setCompass(false) : setCompass(true) }}><img src='assets/lib/images/home/north_pole.svg' /></Link> </li>

              </ul>
            </div>
          </div>

          <div className="bottom_box d-flex align-items-start flex-column">
            <div className="main_permision">
              <p>Permission Type</p>
            </div>

            <div className="permission_values d-flex align-items-center justify-content-between w-100">
              <div className="new_permission_type permission_type">
                <ul className='permission_type_ul'>
                  <li>Permitted</li>
                  <li>Non-permitted</li>
                </ul>
              </div>

              <div className="source_area d-flex align-items-center">

                <div className="content_arrow">
                  <p> <Tooltip title="Maps are for graphical purposes only. They do not represent a legal survey. The boundaries and the names shown and the designations used on these maps are for demonstration purposes. While every effort has been made to ensure that these data are accurate and reliable within the limits of the current state of the art, I-Tech Mission cannot assume liability for any damages caused by any errors or omissions in the data, nor as a result of the failure of the data to function on a particular system. Users of our maps and other analysis products are solely responsible for interpretations made from these products." placement="top"><Link to=''>Disclaimer <img src='assets/lib/images/home/hover_arrow.svg' /></Link></Tooltip> </p>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>


      <div className={loader == true ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

      <FaciliyModal modalTrigger={show} modalData={modalData} />

      <FilterForm sendData={filterAreaData} areaList={filterAreaData} dumpData={dumpData} filterData={filterData} />
    </>
  );
}

export default MapView;