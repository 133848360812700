import { Component } from 'react'
import './about.css';
import * as jsx from './about.mudule';

import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";

class AboutPage extends Component {
    constructor() {
        super()
        this.state = {
       
            loader:false
        }
    }

    render() {
        return jsx.default.bind(this)();
    }






}

function mapStateToProps(state) {
    console.log('mapping state to props')
    return {
      lang: state.lang?.lang
    }
 }

export default connect(mapStateToProps)(AboutPage);