import $ from 'jquery';

$(document).ready(function(){

  $('.modal-btn').click(function(){
    
        console.log('modalon');
    let target = $(this).attr('data-target');

    if($(target).hasClass('width350')){
        $(target).removeClass('width350')
        $(target).addClass('width0')
        $('.overlay').removeClass('dblock')
    }
    else{
        $(target).addClass('width350')
        $(target).removeClass('width0')
        $('.overlay').addClass('dblock')
    }

  })

});