import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
// ======================================== Checkbox Start
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { Multiselect } from "multiselect-react-dropdown";
// ======================================== Checkbox End
// ======================================== Tree View Start
import PropTypes from 'prop-types';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Select from "react-select";
import MenuItem from '@mui/material/MenuItem';
import AdminService from '../../../../services/admin.service';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import adminService from "../../../../services/admin.service";
import $ from 'jquery';
import { ConstructionOutlined } from "@mui/icons-material";
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);



    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        handleSelection(event);
    };




    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});

CustomContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    displayIcon: PropTypes.node,
    expansionIcon: PropTypes.node,
    icon: PropTypes.node,
    label: PropTypes.node,
    nodeId: PropTypes.string.isRequired,
};

function CustomTreeItem(props) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
}


function AssignIndicatorModal(props) {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [chked, setChked] = useState(0);
    const [allSelected, setAllSelected] = useState(false);
    const [checked, setChecked] = useState({});
    const [search, setSearch] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);
    const [trueDisasterIndicatorData, setTrueDisasterIndData] = useState([]);
    const [dtdata, setDtData] = useState([]);

    const [trueAssignedData, setTrueAssignedData] = useState([]);
    const [searchText, setSearchText] = useState('');

    // const [boolean, setBoolean] = useState(false)
    const [filteredDisasters, setFilteredDisasters] = useState([])
    const [filteredIndicator, setFilteredIndicator] = useState([])
    const [backupData, setBackupData] = useState([])
    const [viewAssignedData, setViewAssignedData] = useState([])
    const [sflag, setSFlag] = useState(false);
    const [viewSelectedIndicator, setViewSelectedIndicator] = useState(false);

    // const rowSelectCritera = ;
    // const rowSelectCritera = row => {
    //     props.isAssigned?.forEach(item => {
    //         if (item._id == row.iu_id) {
    //             return true;
    //         }
    //     })
    //     return false;
    // };


    const viewAssignedHandleChange = (e) => {

        setViewSelectedIndicator(e.target.checked)

        // if (props.isAssigned?._id === props.indicatorData?.iu_id) {
        setViewAssignedData(props.isAssigned)


        // }


    }

    const onChange = (e) => {
        setSearchText(e.target.value)
    }

    // const clear = (e) => {
    //     e.preventDefault();
    //     let x = props?.indicatorData?.filter(x => x?.isDisasterIndicator == true);

    //     setSearchText('');
    //     setDtData(x)
    // }

    const onSearch = (e) => {

        let value = e.target.value;
        setSearchText(value)

        // let word = $("#searchBar").val;
        // word = value;

        let d = trueDisasterIndicatorData;
        let d2 = props.isAssigned;

        let fd = null;
        let kd = null;

        switch (viewSelectedIndicator) {
            case true:
                setAfterSearch(true);
                if (value?.length > 0) {
                    kd = d2?.filter(
                        item => (item && item?.name.en.toLowerCase().includes(value.toLowerCase())
                            || item?.unit.en.toLowerCase().includes(value.toLowerCase())
                        )
                    )
                    setTrueAssignedData(kd)
                } else {
                    setTrueAssignedData(d2)
                }
                break;

            case false:
                setSearch(true);
                if (value?.length > 0) {
                    fd = d?.filter(
                        item => (item && item?.name_all?.en?.toLowerCase().includes(value.toLowerCase())
                            || item?.unit_all.en?.toLowerCase().includes(value.toLowerCase())
                        )
                    )

                    setFilteredIndicator(fd)
                } else {
                    setFilteredIndicator(d)

                }
        }

    }





    const handleModalClose = () => {
        // Reset search text and default data when modal is closed
        setSearchText('');
        setFilteredIndicator(trueDisasterIndicatorData);

        setViewSelectedIndicator(false)

    }

    function assignOnClick(e, i) {

        // let id = props.indicatorData?.[i]?.["iu_id"];
        let checkedArr = [];
        if (checked) {
            Object.entries(checked).map(([key, value]) => {
                if (key != '*' && value) {
                    checkedArr.push(key);
                }
            })
        }
        let unchecked = props.isAssigned?.filter(x => !checkedArr?.includes(x._id))

        let assignedIds = props.isAssigned?.map(x => x._id);

        let checkd = trueDisasterIndicatorData?.filter(y => checkedArr?.includes(y.iu_id) && !assignedIds?.includes(y.iu_id));

        const assignedId = [];

        let checkedID = [];
        let unCheckedID = [];
        let storeClassificationID = [];

        checkd?.forEach((i) => {
            let id = i?.iu_id;
            checkedID.push(id);
        })
        unchecked?.map((data) => {

            let id = data?._id != null && data?._id;
            let classificationID = data?.disaster?._id;
            unCheckedID?.push(id);
            storeClassificationID?.push(classificationID);

        })

        let unCheckedData = {
            'indicatorId': unCheckedID,
            'classificationId': storeClassificationID
        };

        // viewAssignedData?.forEach(element => {
        //     assignedId.push(element);
        // });
        // props.isAssigned?.map(element => {
        //     return(
        //         assignedId.push(element)
        //     )
        // });
        // // assignedId.push(e);
        // props?.isAssigned.push(assignedId)
        // setViewAssignedData(assignedId)


        // const data = {
        //     "_id": props.disasterID,
        //     "indicator_id": getCheckedRowID
        // }

        // AdminService.httpPost('/data-import/disaster/assign_all_indicator', data).then((res)=>{
        //     if (res.status == 1){
        //         toast.success(res.message)
        //     }
        //     else {
        //         toast.error(res.message)
        //     }
        // })

        const data = {
            "_id": props.disasterID,
            "checked": checkedID,
            "unChecked": unCheckedData
        }

        AdminService.httpPost('/data-import/disaster/assign_indicator', data).then((res) => {
            if (res.status == 1) {
                toast.success("Indicator has been assigned!");
                props.callBack();
                setSearchText('');
                setFilteredIndicator(trueDisasterIndicatorData);
                setViewSelectedIndicator(false);
                // $('#searchBar').val='';
            } else {
                toast.error("Failed to assign indicator.");
                setSearchText('');
                setFilteredIndicator(trueDisasterIndicatorData);
                setViewSelectedIndicator(false);
            }

        })

    }


    const afterData = afterSearch == true ? trueAssignedData : props?.isAssigned;



    // const filterIndicators = () => {
    //     let indicatorName = [];
    //     trueDisasterIndicatorData?.map((i => {
    //         let p = i.name_all?.en;
    //         indicatorName.push(p);
    //     }))
    //     setFilteredIndicator(indicatorName)
    //     setBackupData2(indicatorName)
    // }

    const filterDisasters = () => {
        let disasterName = [];
        props.filteredData?.map((i => {
            let p = i.title;
            disasterName.push(p);
        }))
        setFilteredDisasters(disasterName)
        setBackupData(disasterName)
    }



    // const hideIndicator = (section) => {
    //     if (viewSelectedIndicator) {
    //         let hide = true;
    //         props.indicatorData[section]?.forEach(child => {
    //             if (isAssigned.includes(child.value)) { hide = false }
    //         })
    //         return hide;
    //     } else {
    //         return false;
    //     }
    // }


    // const completedTasks = filteredIndicator.filter((task) => isAssigned.includes(task));

    // const viewAssigned = (event) => {
    //     completedTasks?.map((task, index)=>{
    //         setAssignedIndicators(true)
    //     })
    // }

    // ================================================================== Data Table


    // useEffect(() => {
    //     console.log("useeffect", 1)
    //     // filterIndicator  s();
    //     filterDisasters();

    //     var inData = [];

    //     props.indicatorData?.forEach(item => {

    //         inData.push({ 'label': item.name, 'value': item.unit });


    //     })

    // }, [props]);

    useEffect(() => {
        console.log("useeffect", 11)
        console.log("props for assigneddataa--", props?.isAssigned)
        let x = props?.indicatorData?.filter(x => x?.isDisasterIndicator == true);

        const uniqueDataArray = Array.from(new Set(x?.map(item => item?.iu_id)))?.map(id => {
            return x.find(item => item?.iu_id === id);
        });

        setTrueDisasterIndData(uniqueDataArray);
        let d = search === true ? filteredIndicator : x;
        setDtData(d);

        let chk = checked;
            if (d?.length > 0 && !sflag) {
                setSFlag(true);
                d?.forEach((x) => {
                    let flag = false;
                    for (let i = 0; i < props.isAssigned.length; i++) {
                        if (x.iu_id == props.isAssigned[i]._id) {
                            flag = true;
                        }
                    }
                    chk[x.iu_id] = flag;
                });
            
        }    

        chk['*'] = false;
    }, [props, filteredIndicator])

    useEffect(() => {
        console.log("useeffect", 12)

        let chk = checked;

        let x = props?.indicatorData?.filter(x => x?.isDisasterIndicator == true);

        const uniqueDataArray = Array.from(new Set(x?.map(item => item?.iu_id)))?.map(id => {
            return x.find(item => item?.iu_id === id);
        });

        setTrueDisasterIndData(uniqueDataArray);
        let d = search === true ? filteredIndicator : x;
        setDtData(d);

        if (props.isAssigned?.length == d?.length) {
            chk['*'] = false;
            setAllSelected(false);
        }

        if (props.isAssigned?.length == uniqueDataArray?.length) {
            setAllSelected(true)
        } else {
            setAllSelected(false)
        }
        
        Object.entries(chk)?.map(([key, value])=>{

          let x = props?.isAssigned?.filter(i =>{

                return i._id == key;

            })
             if (x?.length > 0){
                chk[key] = true;
             }else {
                chk[key] = false;
             }

            // for(let i=0; i < props?.isAssigned?.length; i++){
            //    if( props?.isAssigned[i]?._id == chk[key]){
            //     chk[key] = true;
            //    }
            //    else {
            //     chk[key] = false;
            //    }
            // }
            // chk[key] = true;
           
        })
        setChecked(chk);
        setChked(chked + 1);

        if (props.isAssigned?.length == 0) {
            setTrueAssignedData([]);
        } else {
            setTrueAssignedData(props.isAssigned)
        }
        console.log("props?.isAssigned",props?.isAssigned)
    }, [props, afterSearch])


    useEffect(() => {
        console.log("useeffect", 2)

        let chk = checked;

        // chk['*'] = false;

        let trueChk = Object.entries(chk).filter(([key, value]) => { return value == true; });
        if (viewSelectedIndicator == true ? (trueChk?.length == afterData?.length) : (trueChk?.length == dtdata?.length)) {
            chk['*'] = false;
            setAllSelected(false);
        }

    }, [viewSelectedIndicator, checked]);

    const allSelectedF = (status) => {

        let chk = checked;
        Object.keys(chk).forEach((key) => {
            chk[key] = status;
        })
        chk['*'] = status;
        setAllSelected(status);
        return chk;
    }

    const handleChkboxChange = (e, iu_id) => {

        let chk = checked;
        if (iu_id == '*' && e.target.checked) {
            chk = allSelectedF(true);
        } else if (iu_id == '*') {
            chk = allSelectedF(false);
        } else if (e.target.checked) {
            let trueChk = Object.entries(chk).filter(([key, value]) => { return value == true; });

            if (viewSelectedIndicator == true ? (trueChk?.length == afterData?.length) : (trueChk?.length == dtdata?.length)) {
                chk = allSelectedF(true);
            } else {
                chk[iu_id] = true;

            }
        } else {
            let trueChk = Object.entries(chk).filter(([key, value]) => { return value == true; });
            if (viewSelectedIndicator == true ? (trueChk?.length > afterData?.length) : (trueChk?.length > dtdata?.length)) {
                chk['*'] = false;
            }
            chk[iu_id] = false;
        }
        setChecked(chk);
        setChked(chked + 1);



    }

    const [assignedIds, setAssignedIds] = useState([]);

    useEffect(() => {
        console.log("useeffect", 3)

        let dChecked = [];
        for (let i = 0; i < props.isAssigned.length; i++) {
            dChecked.push(props.isAssigned[i]._id);
        }
        setAssignedIds(dChecked);

    }, [props.isAssigned]);


    // const handleRowSelected = useCallback((state) => {
    //     let indicator_iuID = [];
    //     state?.selectedRows?.map((ele) => {
    //         let id = ele?.iu_id;
    //         indicator_iuID.push(id)
    //     })

    //     setGetCheckedRowID(indicator_iuID)


    //     setSelectedRows(state.selectedRows);

    // })



    // const dthandleChange = ({ selectedRows }) => {
    //     // You can set state or dispatch with something like Redux so we can use the retrieved data
    //     console.log('Selected Rows: ', selectedRows);
    //   };
    // ============================================================== Data Table

    // const checkboxCustom = React.forwardRef(() => {
    //     return (

    //         <>
    //                 <Checkbox
    //                     checked={trueDisasterIndicatorData?.includes(props?.isAssigned)}
    //                 />
    //         </>
    //     )
    // })

    // let assigned = Array(trueDisasterIndicatorData)?.filter(item=> item?.includes(props?.isAssigned))


    return (
        <>
            {/* <div className="modal fade timePeriodPopup datasummary_popup assignindicator" id="assignindicator" tabindex="-1" aria-labelledby="assignindicator" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Assign Disaster</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt=""></img> </span>
                            </button>
                        </div> */}
            {/* <div className="modal-body">
                            <div className='aisBar'>
                                <div className='ai_searchbar'>
                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                    <input onKeyUp={(e) => onSearchDisaster(e)} type="search" autoComplete="off" placeholder="Search..." />
                                </div>
                            </div>
                            <div className='ai_treelist'>
                                <TreeView
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    sx={{}} */}
            {/* > */}
            {/* { */}
            {/* // filteredDisasters?.map((element, index) => { */}
            {/* //     return ( */}
            {/* //         <CustomTreeItem nodeId="1" label={ */}
            {/* //             <div className="ai_treeContent">
                                        //                 <Typography variant="body2" className=''>
                                        //                     {element} */}
            {/* Women and young persons with disabilities realizing their sexual and reproductive health and rights and living a life free from gender-based violence, and disability-inclusive achievement of the three transformative results */}
            {/* </Typography>
                                                        <Button onClick={(e) => takeAssignedID(element, index)} variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="#assignmodal"> Assign</Button>
                                                    </div>
                                                }>
                                                </CustomTreeItem>
                                            )
                                        })
                                    } */}
            {/* <CustomTreeItem nodeId="6" className='ai_tree2' label={
                                        <div className="ai_treeContent">
                                            <Typography variant="body2" className=''>
                                                Strengthened agency of women and young persons with disabilities in addressing sexual and reproductive health and rights and gender-based violence and stronger demand of related services
                                            </Typography>
                                            <Button variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="#assignmodal"> Assign (0)</Button>
                                        </div>
                                    }>
                                    <CustomTreeItem nodeId="7" label={
                                        <div className="ai_treeContent">
                                            <Typography variant="body2" className=''>
                                                MAINSTREAMING & TARGETED ACTION Strengthen capacity to mainstream disability inclusion and implement disability-specific interventions at all levels
                                            </Typography>
                                            <Button variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="#assignmodal"> Assign (1)</Button>
                                        </div>
                                    } />
                                    <CustomTreeItem nodeId="8" label={
                                        <div className="ai_treeContent">
                                            <Typography variant="body2" className=''>
                                                POSITIONING & POLICY DIALOGUE Strengthen the positioning of disability-inclusive protection and response to GBV and advancing SRHR at all levels
                                            </Typography>
                                            <Button variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="#assignmodal"> Assign (2)</Button>
                                        </div>
                                    } />
                                    <CustomTreeItem nodeId="9" label={
                                        <div className="ai_treeContent">
                                            <Typography variant="body2" className=''>
                                                PARTICIPATION Establish or strengthen innovative mechanisms and capacities for systemic engagement and participation of women and young persons with disabilities and OPDs
                                            </Typography>
                                            <Button variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="#assignmodal"> Assign (3)</Button>
                                        </div>
                                    } />
                                </CustomTreeItem> */}

            {/* </TreeView>
                            </div>
                        </div> */}
            {/* <div className="modal-footer">
                            <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                                <Button variant="contained" className="mb-0 text-capitalize mr-3 closeBtn" data-dismiss="modal"><img src="assets/lib/images/home/arrow_right.svg" /> Close</Button>
                                <Button variant="contained" className="mb-0 text-unset"> Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="modal fade timePeriodPopup datasummary_popup assignindicator assignmodal" id="assignmodal" tabindex="-1" aria-labelledby="assignindicator" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.disasterName}
                                {/* Women and young persons with disabilities realizing their sexual and reproductive health and rights and living a life free from gender-based violence, and disability-inclusive achievement of the three transformative results */}
                            </h5>
                            <button onClick={handleModalClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt=""></img> </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='aisBar pb-1'>
                                <div className='ai_searchbar'>
                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                    <input onChange={(e) => { onSearch(e) }} value={searchText} id="searchBar" type="search" autoComplete="off" placeholder="Search..." />
                                </div>
                                <FormGroup className='viewAssigned'>
                                    <FormControlLabel control={<Checkbox checked={viewSelectedIndicator} onChange={(e) => { viewAssignedHandleChange(e) }} />} label={
                                        <>
                                            <div className='va_name'>View Assigned <small>| Assigned: {props.isAssigned?.length} / {trueDisasterIndicatorData?.length}</small> <span></span></div>
                                        </>
                                    } />
                                </FormGroup>
                            </div>
                            <div className="indicator_datatable">
                                {/* <DataTable
                                    columns={viewSelectedIndicator == true ? afterColumns : dtcolumns}
                                    persistTableHead
                                    data={viewSelectedIndicator == true ? afterData : dtdata}
                                    // selectableRows={selectedRows}
                                    // selectableRowsComponent={Checkbox}
                                    // selectableRowsComponentProps={{ checked: assigned }}
                                    // // selectableRowSelected={assigned}
                                    // onSelectedRowsChange={handleRowSelected}
                                    className='main_indi_datatable'
                                    noDataComponent="No Data"
                                /> */}
                                <table>
                                    <thead>
                                        <tr>
                                            <td><Checkbox onChange={(e) => handleChkboxChange(e, '*')} checked={!!allSelected} inputProps={{ 'aria-label': 'controlled' }} /></td>
                                            <td>Indicator</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewSelectedIndicator == false && dtdata?.map(row => {
                                            return (
                                                <tr key={row?._id}>
                                                    <td>
                                                        <Checkbox onChange={(e) => handleChkboxChange(e, row?.iu_id)} checked={!!checked[row?.iu_id] ? checked : ''} />
                                                    </td>
                                                    <td>
                                                        {`${row?.name_all?.en} | ${row?.unit_all?.en}`}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {viewSelectedIndicator == true && afterData?.map(row => {
                                            return (
                                                <tr key={row?._id}>
                                                    <td>
                                                        <Checkbox onChange={(e) => handleChkboxChange(e, row?._id)} checked={!!checked[row?._id] ? checked : ''} />
                                                    </td>
                                                    <td>
                                                        {`${row?.name?.en} | ${row?.unit?.en}`}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </div>

                            {/* <div className='ai_treelist am_section'>
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Indicator</label>
                                    </span>
                                </div>

                                <TreeView
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    sx={{}}
                                >
                                    {
                                        viewSelectedIndicator === true ?

                                            props.isAssigned?.map(((element, index) => {
                                                return (
                                                    <>
                                                    ==========this section was commented start =========
                                                        <div id="indicatorsName">{element}</div>
                                                <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                                                    <Button variant="contained" className="mb-0 text-unset" data-dismiss="modal"> Assign <img src="assets/lib/images/home/arrow_right.svg" /></Button>
                                                </div>
                                                        ==========this section was commented end =========
                                                        <CustomTreeItem nodeId="1" label={
                                                            <div className="ai_treeContent">
                                                                <Typography variant="body2" className=''>
                                                                    {element}
                                                                </Typography>

                                                                ==========this section was commented start =========
                                                                //  isAssigned?.map(item=>{

                                                                return (
                                                                ==========this section was commented end =========
                                                                <>

                                                                    <Button onClick={(e) => assignOnClick(element, index)} variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="" disabled={true}>Assigned</Button>

                                                                </>
                                                            </div>
                                                        }>
                                                        </CustomTreeItem>

                                                    </>
                                                )
                                            }))

                                            :

                                            props.indicatorData?.map(((element, index) => {
                                                return (
                                                    <>
                                                       ==========this section was commented start =========
                                                        <div id="indicatorsName">{element}</div>
                                                <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                                                    <Button variant="contained" className="mb-0 text-unset" data-dismiss="modal"> Assign <img src="assets/lib/images/home/arrow_right.svg" /></Button>
                                                </div>
                                                         ==========this section was commented end =========
                                                        <CustomTreeItem nodeId="1" label={
                                                            <div className="ai_treeContent">
                                                                <Typography variant="body2" className=''>
                                                                    {element?.name_all.en} | {element?.unit_all.en} | {element?.subgroup_all.en}
                                                                </Typography>



                                                                {props.isAssigned?.includes(element) ?

                                                                    <>



                                                                        <Button onClick={(e) => assignOnClick(element, index)} variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="" disabled={true}>Assigned</Button>

                                                                    </>

                                                                    :


                                                                    isAssigned?.includes(element) ?


                                                                        <>



                                                                            <Button onClick={(e) => assignOnClick(element, index)} variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target="" disabled={true}>Assigned</Button>

                                                                        </>
                                                                        :

                                                                        <Button onClick={(e) => assignOnClick(element, index)} variant="contained" className="mb-0 text-unset" data-toggle="modal" data-target=""> Assign</Button>






                                                                }
                                                            </div>
                                                        }>
                                                        </CustomTreeItem>

                                                    </>
                                                )
                                            }))

                                    }
                                </TreeView>
                            </div> */}
                        </div>
                        <div className="modal-footer justify-content-center py-3">
                            <Button onClick={(e) => assignOnClick(e)} variant="contained" className="mb-0 text-unset assign_btn" data-dismiss="modal" data-target=""> OK <img class="" src="assets/lib/images/home/thick_arrow.svg"></img></Button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AssignIndicatorModal;
