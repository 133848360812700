import { Component } from 'react'
import './dashboard.css';
import * as jsx from './dashboard.mudule';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";

class DashboardPage extends Component {
    constructor() {
        super()
        this.state = {
       
            loader:false
        }
    }

    render() {
        return jsx.default.bind(this)();
    }






}


export default connect(
   
  )(DashboardPage);