import React from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from '../../Layout/BreadCrumbs/Breadcrubms';
import Footer from '../../Layout/footer';
import Header from '../../Layout/header';
import Sidebar from '../../Layout/sidebar';
import AddAreaModal from '../../Modals/addArea.modal';
import Modal from 'react-bootstrap/Modal';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import DataEntryModal from '../../Modals/dataEntry.modal';
import { Pending } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import RemarksModal from './remarks-modal';
			  

// const handleChange = ({ selectedRows }) => {
//   // You can set state or dispatch with something like Redux so we can use the retrieved data
//   console.log('Selected Rows: ', selectedRows);
// };


const dataEntryTemplate = function () {
//   const  openDataViewModal = async () => {

//     // if(this.state.scrollableModal==false){
//     //     this.setState({scrollableModal:true})
//     // }
//     // else{
//         this.setState({scrollableModal:false})
//     // }
  
// }

const  openCloseModal = async () => {

  // if(this.state.scrollableModal==false){
  //     this.setState({scrollableModal:true})
  // }
  // else{
      this.setState({scrollableModal:false})
  // }

}


const  closeModalClose = async () => {

    // if(this.state.scrollableModal==false){
    //     this.setState({scrollableModal:true})
    // }
    // else{
        this.setState({disaaprovest:false})
    // }
  
}

const  closeModalClosePublis = async () => {

  // if(this.state.scrollableModal==false){
  //     this.setState({scrollableModal:true})
  // }
  // else{
      this.setState({publishModal:false})
  // }

}

  return (
    <>

      {/* 
      <Breadcrumbs />

      <Sidebar /> */}

      <div className="main" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection data-entry' id="areaSection">


        
          {/* <div className='col-lg-12 col-md-12'>
            <div className="row header-div new-h-d pt-0 pl-0"> */}
              {/* nazib */}
              {/* <div className="col-md-1 ">
              <div class="form-group form-inline">                            
                  <label >Show</label>
                      <select id="dataLimit"  className='form-control' value={this.state.dataLimit} onChange={this.updateDataLimit}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                  </div>
     

              </div>

              <div className="col-md-2">

              <div class="form-group form-inline">                            
                  <label >Status</label>
                      <select id="statusSelect" className='form-control'  onChange={this.getDataByStatus}>
                        <option value="2">Pending</option>
                        <option value="1">Approved</option>
                        <option value="3">Disapproved</option>
                        </select>
                  </div>
              </div> */}
              {/* <div className="col-md-12 pl-2">
              <div class="">     */}
 
                {/* <SearchComponent.default onSearch={props.onSearch} searchText={props.searchText} /> */}
                <subHeaderComponentMemo.default parentCallBack={this.sendStatus}  
                                                statusFilter = {this.state.statusData} 
                                                onSearch={this.onSearch} 
                                                PropDeleteBulk={this.deleteBulkData}
                                                parentCallBackDelete ={this.deleteAllData}
                                               
                                                searchText={this.state.searchText} 
                                                value='1'
                                                checkData = {this.state.bulkDelete}
                                                currStatus={this.state.currStatus}
                                                nRows={this.state.filteredData?.length}
                                                /> {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}
         
                {/* </div>
              </div>
            </div>
          </div> */}
            
            <DataTable
              columns={this.state.currStatus==1 && this.columns1 || this.state.currStatus==3 && this.columns2 ||  this.columns  }
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[100]}
              paginationServer
              paginationTotalRows={this.state.totalElements}
              paginationPerPage={this.state.rowsPerPage}
              paginationComponentOptions={{
                noRowsPerPage: false
              }}
              onChangePage={page => this.setPage(page)}
              style={{ display: "flex" }}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              selectableRows={this.state.currStatus == 1 ? false : true}
               onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className='dataTableScroll'
              noDataComponent="No Data"
            />
            {console.log('this.state.filteredData',this.state.filteredData)}
          </section>
        </div>
      </div>






      {/* modal area begin here============== */}



      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

      <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img
                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
        </div>
            <div class="right-tab-heading are_head">
              <h2 class="form-heading">
                <span class="form-heading_he">
                  <span class="ddf">
                    {this.state.isEdit==true ? 'Edit Entry': 'Add Entry'}
                  </span>
                </span>
              </h2>
            </div>
            <DataEntryModal 
              indicatorData = {this.state.indicatorData} 
              closeModal={this.openAddModal}
              subGroupData={this.state.subIndData}
              areaTree = {this.state.areaData}
              newDataEntry ={this.saveData}
              editData = {this.state.editEntry}
              isEdit = {this.state.isEdit}
              loginArea = {this.state.loginArea}
            />


   
      </div>

     
      
      <RemarksModal remarkData={this.state.remarkData} />


      <Modal show={this.state.scrollableModal} onHide={openCloseModal} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Reason of Disapproval</Modal.Title>
          </Modal.Header>
          <Modal.Body>please recheck the data</Modal.Body>
        </Modal>






{/* 
      <Footer /> */}

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>

    </>
  );
};

export default dataEntryTemplate;
