import React, { useEffect, useRef, useState } from "react";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import makeAnimated from "react-select/animated";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";


import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

import Tooltip from "@mui/material/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
// ================================== Password
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// ================================== Password

import { omit } from "lodash";
import { toast } from "react-toastify";

function AddUserModal(props) {
  const form = useRef();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "This field is required",
      },
    })
  );

  const [, forceUpdate] = useState();
  const [areaTree, setAreaTree] = useState([]);
  const [callData, setParentAreaData] = useState([]);
  const [callDataSupervisor, setParentAreaDataSupervisor] = useState([]);
  const [flatAreas, setFlatArea] = useState([]);
  const [areaExpand, setAreaExpanded] = useState([]);
  const [areaChecked, setAreaChecked] = useState([]);
  const [getTreeData, setTreeData] = useState([]);
  const [formError, setError] = useState({ error: false });
  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const validate = (e, name, value) => { };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  console.log("This is userRole consoled --", userInfo)

  let userRole = userInfo?.role;
  let userArea = userInfo?.area;
  console.log("This is userRole consoled --", userRole)

  const [roleValue, setRoleValue] = useState();

  const [role, setRole] = useState([
    {
      label: "Data Entry",
      value: 2,
    },
    {
      label: "Data Approve",
      value: 4,
    },
    {
      label: "Data Collector",
      value: 6,
    },
    ...(userRole != 7
      ? [
        {
          label: "Supervisor",
          value: 7,
        },
      ]
      : []),
  ]);

  const [state, setState] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    user_role: "",
    areaList: [],
    organization: "",
    designation: "",
  });

  const getAreaList = (e) => {
    // console.log('userrole', userRole, userArea, e);
    // if((userRole == 7 && !userArea.includes(e))) {
    //   toast.error("Area assignment for this area not permitted");
    //   return;
    // }
    setAreaChecked(e);
    console.log("asas", e);

    var dataArr = [];

    e?.forEach((item) => {
      props.areaTree?.forEach((dt) => {
        if (dt.area_code == item) {
          dataArr.push({ code: dt.area_code, name: dt.name });
        }
      });
    });

    setState({ ...state, areaList: dataArr });
    console.log("This arealistt--", dataArr)
  };

  // const expandedState = ()=> {
  //   // console.log("Sudhanshu areaCode: ", areaCode, FormState.pareaCode);
  //   if(FormState.pareaCode != areaCode) {
  //     let area = flatAreas.filter(a => a.value == areaCode);
  //     console.log("Sudhanshu areaCode: ", area);
  //     setFormState({...FormState, pareaCode: areaCode, pareaName: area?.[0]?.label});
  //     setAreaChecked([areaCode]);
  //     let ae = new Set(['TLS', ...areaExpand, areaCode]);
  //     setAreaExpanded([...ae]);
  //   }
  // };


  useEffect(() => {
    // console.log("sfdrrr", props.editMode, props.isEdit);


    const facilities1 = props.facilityData;
    // setfacilityType(props.editMode?.facility_type);
    if (props.isEdit == true) {
      let test = "";

      let areaCodes = [];

      var dataArr = [];
      props.editMode?.area_name?.forEach((item) => {
        props.areaTree?.forEach((dt) => {
          if (dt.area_code == item[0].area_code) {
            dataArr.push({ code: dt.area_code, name: dt.name });
            areaCodes.push(dt.area_code);
          }
        });
      });

      setAreaChecked(areaCodes);

      console.log("sfdrrr", props.editMode?.role);
      setState({
        id: props.editMode?._id._id,
        name: props.editMode?.name,
        email: props.editMode?.email,
        // password:  props.editMode?.password,
        user_role: props.editMode?.role,
        areaList: dataArr,
        organization: props.editMode?.organization,
        designation: props.editMode?.designation,
      });

      let rlData = props.editMode.role;

      setRoleValue(rlData);
    } else {
      setState({
        id: "",
        name: "",
        email: "",
        password: "",
        user_role: "",
        areaList: [],
        organization: "",
        designation: "",
      });
      setRoleValue('');
      setAreaChecked([]);
      setAreaExpanded([]);

    }
    if (
      props.areaTree != "" &&
      props.areaTree != null &&
      props.areaTree.length > 0
    ) {
      let d = props.areaTree;
      console.log("complete areas-",d)

      let flatLevel = [];
      let filter1 = props.areaTree.filter(a=> a.level==1 || userArea.includes(a.area_code) || a.areaSubData?.filter(b=> userArea.includes(b.area_code) || b.areaSubData?.filter(c => userArea.includes(c.area_code) || c.areaSubData?.filter(d=> userArea.includes(d.area_code)).length>0  )    ).length>0)
      console.log(filter1);
      if(userRole == 7){
        filter1?.forEach((item) => {
              var obj = {
                value: item.area_code,
                label: item.name,
                parent: item.parent_id,
                level: item.level,
              };
              flatLevel.push(obj);
          
        }); 
        // setFlatArea(flatLevel);
        console.log("level1", flatLevel);
        const data = setLevelData(flatLevel);
  
        setParentAreaData(data);
        console.log('dataaa',data)
      } else{
        d?.forEach((item) => {
          var obj = {
            value: item.area_code,
            label: item.name,
            parent: item.parent_id,
            level: item.level,
          };
          flatLevel.push(obj);
        });
        // setFlatArea(flatLevel);
        console.log("level1", flatLevel);
        const data = setLevelData(flatLevel);
  
        setParentAreaData(data);
        console.log('dataaa',data)

      }

     
      // console.log("test by team ",areaTree)
      // let flatLevel = [];

      // props.areaTree.forEach((item) => {

      //     item.areaSubData = [];

      //     flatLevel.push(item)

      // })

      // console.log('level1', flatLevel)
      // const data = setLevelData(flatLevel)
      // const levelCount = getHowManyLevel(data);

      // console.log("test by react team",data)

      // setAreaTree(data)
    }
  }, [props]);

  // area tree sctructure=========================//

  const getHowManyLevel = (obj) => {
    let res = JSON.stringify(obj).replace(/[^{|^}]/g, "");
    while (/}{/g.test(res)) {
      res = res.replace(/}{/g, "");
    }
    return res.replace(/}/g, "").length;
  };

  const setLevelData = (flat) => {
    var roots = [];

    var all = {};

    flat.forEach(function (item) {
      all[item.value] = item;
    });
    console.log("what is all--",all)

    Object.keys(all).forEach(function (value) {
      console.log("this value maybe--", value)
      var item = all[value];
      // console.log("this item maybe--", item.parent == null || item.parent === ""?"yes":"no")
      console.log("this item maybe--", item )
      if (item.parent == null || item.parent === "") {
        roots.push(item);
      } else if (item.parent in all) {
        var p = all[item.parent];
        console.log("This is p in all", p)
        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
        console.log("This is p in all", p.children)
        // roots.push(p.children)
        // roots.push(item);

      }
    });
    console.log("roots?-",roots)
    return roots;
  };

  // const renderTree = (nodes) => (

  //   nodes.map((item) => {
  //       return (<>

  //           <TreeItem
  //             className={item.areaSubData?.length > 0? 'has-child':'no-child'}
  //             key={item.area_code} nodeId={item._id} label={item.name}
  //             onClick={() => { setState({...state, areaId: item.area_code, areaName: item.name }) }} >

  //               {Array.isArray(item.areaSubData) && item.areaSubData != null
  //                   ? renderTree(item.areaSubData)
  //                   : null}
  //           </TreeItem>

  //       </>);

  //   })

  // );

  const inputhandle = (e) => {
    let name, value, label;
    setRole(e.target.value);
    // console.log("ee-" ,e.target.value);
    name = e.target.name;
    value = e.target.value;
    label = e.target.label;
    setState({ ...state, [name]: value });
    setState({ ...state, [name]: value });

    // if (!isValidEmail(e.target.value)) {
    //   setError('Email is invalid');
    // } else {
    //   setError(null);
    // }
    // setMessage(e.target.value);

    // e.persist();
    // validate(e,name,value);

    //Let's set these values in state
    // setValues({
    //     ...values,
    //     [name]:value,
    // })
  };

  const [emailError, setEmailError] = useState("");
  // const [formError, setError] = useState({error:false});

  // ============================================================================== Password Show/Hide Start
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // ============================================================================== Password Show/Hide End
  // ============================================================================== Validation Start

  const makeid = (length) => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      console.log("This is makid wala pass-", text)
    return text;

    
  };

  const revertMakeID = (password) => {
    return password.substring(0, 10); // Extract the first 10 characters
  };

  const handleChange = (event) => {

    if (event.target.name == "userRole") {
      let selectedVal = event.target.value;
      setRoleValue(selectedVal);
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataToSend = [];
    let passwordString = makeid(10) + state.password;
    
    let password = btoa(passwordString);

    if (props.isEdit == true) {
      dataToSend = {
        id: state.id,
        name: state.name,
        email: state.email,
        password: state.password != undefined ? password : null,
        user_role: roleValue,
        area: areaChecked,
        organization: state.organization,
        designation: state.designation,
        status: 1,
      };
    } else {
      dataToSend = {
        id: null,
        name: state.name,
        email: state.email,
        password: password,
        user_role: roleValue,
        area: areaChecked,
        organization: state.organization,
        designation: state.designation,
        status: 1,
      };
    }
    if (simpleValidator.current.allValid()) {
      console.log("santea========>", dataToSend);
      props.parentCallback(dataToSend);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
    if (setRoleValue == "") {
      setError({ error: true, msg: 'This field is required', input: 'ptype' })
      return;

    }
  };

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  };
  // ============================================================================== Validation End

  ////////////=======multiple delete function ===========////////////////

  // console.log("ttttttttzzzzzzzzzzz===========>",props.facilityData?.User_Roles)

  return (
    <>
      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            {/* <form onSubmit={(e)=>updateData(e)} noValidate="" style={{ 'position': 'relative' }} id="addUserForm" className="ng-untouched ng-pristine ng-invalid" > */}
            <div className="gallery-collections mt-0 metadata_form">
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Name</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <TextValidator
                        name="name"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.name}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Designation</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <TextValidator
                        name="designation"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.designation}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Organization</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <TextValidator
                        name="organization"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.organization}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Email</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style autocomplete">
                    <div className="mat-form-field-wrapper">
                      <TextValidator
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="w-100"
                        //  readOnly = {props.isEdit}
                        disabled={props.isEdit}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={state.email}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                    {/* readOnly={state.editMode? true :false} */}
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Role</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style roleInput">
                    <div className="mat-form-field-wrapper">

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="userRole"
                        value={parseInt(roleValue)}
                        placeholder=""
                        onChange={handleChange}
                      >
                        {role.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="inPuthidden">
                        <TextValidator
                          type="hidden"
                          style={{ border: "0px", outline: 'none' }}
                          name="password"
                          autoComplete="off"
                          className=" w-100"
                          value={roleValue}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                      {/* <Select onChange={(e) => { inputhandle(e) }} name="role" id="user_role" >
                              <MenuItem value={2}>Data Entry</MenuItem>
                              <MenuItem value={4}>Data Approver</MenuItem>
                            </Select> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Area</label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                          {state.areaList?.length > 0 &&
                            state.areaList.slice(0, 2).map((d, i) => {
                              console.log("This is arealist consoled-", d)
                              return (
                                <>
                                  <span className="badge">{d.name} </span>
                                </>
                              );
                            })}
                          {state.areaList?.length > 2 ? (
                            <>
                              <span>{state.areaList?.length - 1}+</span>
                            </>
                          ) : (
                            ""
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="treeviewCheckbox">
                          <CheckboxTree
                            nodes={callData}
                            checkModel="all"
                            checked={areaChecked}
                            expanded={areaExpand}
                            onCheck={getAreaList}
                            onExpand={setAreaExpanded}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="inPuthidden">
                      <TextValidator
                        type="hidden"
                        style={{ border: "0px", outline: 'none' }}
                        name="password"
                        autoComplete="off"
                        className=" w-100"
                        value={state.areaList}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Password</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style autocomplete password">
                    <div className="mat-form-field-wrapper">
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                      >
                        {/* <OutlinedInput
                          name="password"
                          inputProps={{
                            autocomplete: "new-password",
                            form: { autocomplete: "off" },
                          }}
                          autoComplete="off"
                          id="outlined-adornment-password"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type={showPassword ? "text" : "password"}
                          value={state.password}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        /> */}
                      </FormControl>
                      <TextValidator
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        autoComplete="off"
                        className="w-100"
                        onChange={(e) => { handleChange(e) }}
                        value={state.password}
                        validators={ props.isEdit == true ? []:['required']}
                        errorMessages={['This field is required']} />

                      <IconButton
                        style={{
                          float: 'right',
                          marginTop: '0',
                          marginRight: '10px'
                        }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}

                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
                <Button
                  className="addbtn"
                  color="error"
                  variant="contained"
                  type="submit"
                >
                  {props.isEdit ? "Update" : "Add"}
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
}

export default AddUserModal;
