import React,  {useEffect, useState} from "react";
// ======================================================= Modal start
import Modal from 'react-bootstrap/Modal';
// ======================================================= Modal End
// ======================================================= Dropdown End
import Dropdown from 'react-bootstrap/Dropdown';
// ======================================================= Dropdown End
import DataTable from 'react-data-table-component';


// const Datasummarypopup = (props) => {
 function Datasummarypopup(props) {
    console.log(props);

   const columns = [
        {
            name: '#',
            selector: (row, index) => index+1,
            // sortable: false,
            width: "50px"
        },
        {
            name: 'Assigned Area - User',
            selector: row => row.area?.name?.en  + (row.hasOwnProperty("household") ? `-Household-${row?.household}` :"") +" | "+ row.userData?.name,
            sortable: true,
        },
        {
            name: 'Completion Status',
            selector: row => <span className={(row?.ansCount == row?.quesCount) ? 'green' : 'red'}>{row?.ansCount + ' of ' + row?.quesCount}</span>,
            sortable: true,
        },
    ];  

    useEffect(() => {
        console.log("SUmmary", props)
    }, [props])

    // <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    //   Launch demo modal
    // </button>
    
    return (
        <>
            <div class="modal fade datasummary_popup" id="datasummary_popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                        <div class="summary_head">
                            <h6  class="mb-0">Data Entry Summary | { props.datasummaryFormData !== null ? props.datasummaryFormData?.name : '' }</h6>
                            <p  class="date mb-0">Created Date ( { props.datasummaryFormData !== null ? props.datasummaryFormData.date : '' } )</p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt=""></img> </span></button>
                    </div>
                    <div class="modal-body">
                        <div class="data_summarytable mt-4">
                            <DataTable
                                columns={columns}
                                data={props.dataSummary}
                                direction="auto"
                                fixedHeaderScrollHeight="500px"
                                pagination
                                paginationRowsPerPageOptions={[10, 25, 50,100]}
                                style={{ display: "flex" }}
                                // subHeader
                                // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
                                persistTableHead
                                noDataComponent="No Data"
                            //   selectableRows
                            //   onSelectedRowsChange={handleChange}
                            //   clearSelectedRows={handleChangeData}
                            />
                            </div>
                    </div>
                                    <div class="modal-footer">
                                        <button type="button" className="mb-0 text-unset ml-2 mx-auto dsp_btn" data-dismiss="modal">Close</button>
                                        {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </>
    );
    

}


export default React.memo(Datasummarypopup)