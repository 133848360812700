import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import disasterProfile from '../../../Config/disasterProfile.json';

function MetaDataModal(props) {
  const {sector, iuId, metaData, indicator, unit, type} = props;
 

  let language = useSelector((state)=> state?.lang?.lang)
  
  return (
    <>
      <span className='metadata' data-toggle="modal" data-target={"#metadatamodal"+iuId+type}><img src='assets/lib/images/home/indicator.svg' /></span>
          <div class="modal fade maps_popup metadatamodal" id={"metadatamodal"+iuId+type} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="modal-title h4">{indicator} <span> | {unit}</span></div>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    {/* <h4>Indicator Definition</h4> */}
                    <h4>{disasterProfile.mt1[language]}</h4>
                    <p>{metaData?.mt1 && metaData?.mt1?.[language] != "" ? metaData?.mt1?.[language]: '--'}</p>
                    {/* <h4>Method of Computation</h4> */}
                    <h4>{disasterProfile.mt2[language]}</h4>
                    <p>{metaData?.mt2 && metaData?.mt2?.[language] != "" ?metaData?.mt2?.[language]: '--'}</p>
                    {/* <h4>Overview</h4> */}
                    <h4>{disasterProfile.mt3[language]}</h4>
                    <p>{metaData?.mt3 && metaData?.mt3?.[language] != "" ?metaData?.mt3?.[language]: '--'}</p>
                    {/* <h4>Comments and Limitations</h4> */}
                     <h4>{disasterProfile.mt4[language]}</h4>
                    <p>{metaData?.mt4 && metaData?.mt4?.[language] != "" ?metaData?.mt4?.[language]: '--'}</p>
                    {/* <h4>Data Collection for Global Monitoring</h4> */}
                    <h4>{disasterProfile.mt5[language]}</h4>
                    <p>{metaData?.mt5 && metaData?.mt5?.[language] != "" ?metaData?.mt5?.[language]: '--'}</p>
                    {/* <h4>Obtaining Data</h4> */}
                    <h4>{disasterProfile.mt6[language]}</h4>
                    <p>{metaData?.mt6 && metaData?.mt6?.[language] != "" ?metaData?.mt6?.[language]: '--'}</p>
                    {/* <h4>Data Availability</h4> */}
                    <h4>{disasterProfile.mt7[language]}</h4>
                    <p>{metaData?.mt7 && metaData?.mt7?.[language] != "" ?metaData?.mt7?.[language]: '--'}</p>
                    {/* <h4>Treatment of Missing Values</h4> */}
                    <h4>{disasterProfile.mt8[language]}</h4>
                    <p>{metaData?.mt8 && metaData?.mt8?.[language] != "" ?metaData?.mt8?.[language]: '--'}</p>
                    {/* <h4>Regional and Global Estimates</h4> */}
                    <h4>{disasterProfile.mt9[language]}</h4>
                    <p>{metaData?.mt9 && metaData?.mt9?.[language] != "" ?metaData?.mt9?.[language]: '--'}</p>
                  </div>              
                </div>
              </div>
            </div>

    </>
  );
}


export default MetaDataModal;