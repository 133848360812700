import { Component } from "react";
import "./user.css";
import * as jsx from "./user.module.jsx";
import * as $ from "jquery";
import * as constants from "../../../Config/app.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Usermodel from "../../Modals/addUser.model";
import swal from "sweetalert";
import AdminService from "../../../services/admin.service";
import { connect } from "react-redux";
import { id } from "date-fns/locale";
import Dropdown from 'react-bootstrap/Dropdown';
class User extends Component {
  
  constructor() {
    super();
     let userInfo = JSON.parse(localStorage.getItem('userInfo'));
     let userRole = userInfo?.role;
    this.state = {
      filteredData: [],
      searchText: "",
      modal: false,
      data: [],
      adminEmail: [],
      isEdit: false,
      editModeId: null,
      areaList: [],
      parentCallback: [],
      areaData: [],
      loader: false,
      bulkDelete: [],
      noSupervisorData: [],
      userRole:userRole,
    };
  }

  editMode(event, row_id) {
    event.preventDefault();
    this.setState({ isEdit: true, modal: true });

    this.state.data.forEach((item) => {
      if (item._id == row_id) {
        console.log("This is item edit console--", item)
        this.setState({ editModeId: item });
      }
    });

    this.openEditModal();
  }

  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false, isEdit: false, editModeId: null });
    }
  };

  

  openEditModal() {
    if ($("#EditAreaModal").hasClass("width350")) {
      $("#EditAreaModal").removeClass("width350");
      $("#EditAreaModal").addClass("width0");
      $(".overlay").removeClass("dblock");
    } else {
      $("#EditAreaModal").addClass("width350");
      $("#EditAreaModal").removeClass("width0");
      $(".overlay").addClass("dblock");
    }
  }

  // openEditModal(){
  //     if($('#EditAreaModal').hasClass('width350')){
  //         $('#EditAreaModal').removeClass('width350')
  //         $('#EditAreaModal').addClass('width0')
  //         $('.overlay').removeClass('dblock')
  //     }
  //     else{
  //         $('#EditAreaModal').addClass('width350')
  //         $('#EditAreaModal').removeClass('width0')
  //         $('.overlay').addClass('dblock')
  //     }
  // }

  // editMode (event, row) {
  //     this.setState({isEdit:true})
  //     this.openEditModal()
  //     event.preventDefault();

  //             this.setState({editModeId:row})

  //     this.setState({isEdit:true})

  //     this.openAddModal()

  // }

  // filter table and get data====================//

  // onSearch = (e) => {
  //   this.setState({ searchText: e.target.value });
  //   let d = this.state.data;
  //   console.log("hello", d);
  //   let fd = d.filter(
  //     (item) =>
  //       (item.ius_list[0].name &&
  //         item.areaData[0].area_code
  //           .toLowerCase()
  //           .includes(this.state.searchText.toLowerCase())) ||
  //       (item._id && item.areaData[0].createdAt)
  //   );
  //   this.setState({ filteredData: fd });
  // };
  columns = [
    // {
    //     name: '#',
    //     selector: (row, index) => index+1,
    //     // sortable: true,
    //     // width: "50px"
    // },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Organization",
      selector: (row) => row.organization,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
    },

    {
      name: "Assigned Area",
      selector: (row) => (
        <>
          {row?.area_name?.length > 0 &&
            row?.area_name?.slice(0, 2).map((d, i) => {
              return (
                <>
                  <span className="badge">{d?.[0]?.name?.en} </span>
                </>
              );
            })}
          {row?.area_name?.length > 2 ? (
            <>
              <span>{row?.area_name?.length - 1}+</span>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },

    {
      name: "Role",
      // selector: row => row.role,
      selector: (row) =>
        row.role == 2 ? (
          <span className="">Data Entry</span>
        ) : row.role == 4 ? (
          <span className="">Data Approve</span>
        ) : row.role== 6 ? (
          <span className="">Data Collector</span>
        ) : (
          <span className="">Supervisor</span>
        ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          {/* <div class="dropdown table-action">
            <button
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              Select
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a>
              <a
                class="dropdown-item"
                onClick={(e) => this.editMode(e, row._id)}
                href="javascript:;"
              >
                Edit
              </a>
              <a
                class="dropdown-item"
                onClick={(e) => {
                  this.deleteEntry(row._id._id);
                }}
                href="javascript:;"
              >
                Delete
              </a>
            </div>
          </div> */}
          <Dropdown className='table-action'>
              <Dropdown.Toggle id="dropdown-basic">
                  Select
              </Dropdown.Toggle>

              <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => this.editMode(e, row._id)} href="javascript:;">Edit</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => {this.deleteEntry(row._id._id);}} href="javascript:;">Delete</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          {/* <span className="action_specific ng-star-inserted">
                        <img   onClick={(e) => this.editMode(e, row)} className="cursor-pointer modal-btn" data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host=""

                        />
                          <label  className="switchone switch-checked" 
                        mattooltipposition="above" ng-reflect-position="above" 
                        ng-reflect-message="Status" aria-describedby="cdk-describedby-message-26" 
                        cdk-describedby-host="" 
                        style={{'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 
                        'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)', 'marginRight': '20px'}}>
                <input onChange={(e) => this.changeStatus(e, row._id)} 
                    data-size="mini" 
                    type="checkbox" 
                    defaultChecked={row.status? row.status == 1: false}/>
                    <div>
                    </div>
                </label>                
                <span class="deleteIcon text-success" onClick={(e)=>{this.deleteEntry(row._id)}}><i class="fa fa-trash"></i></span>
                </span> */}
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  makeid(length) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
  //==========add and edit api user=========///
  saveUserData = (saveData) => {
    if (this.state.isEdit && saveData.id != null) {
      AdminService.httpPut("/data-import/users/update/" + saveData.id, saveData)
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            let d = res.data;
            // console.log('testbysangeeta',d)

            if (res.status != 0 && res.success == true) {
              this.setState({ loader: false });
              toast.success("User updated successfully");
              this.openAddModal();
              this.getData();
            } else if (res.status == 0) {
              this.setState({ loader: false });
              toast.error("Failed to update user");
            } else {
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          toast.error(err);
          this.setState({ loader: false });
          console.log(err);
        });
    } else {
      AdminService.httpPost("/data-import/users/add", saveData)
        .then((res) => {
          if (res) {
            this.setState({ loader: false });
            let d = res.getdata;
            if (res.success == true) {
              this.setState({ loader: false });
              toast.success("User added successfully");
              this.openAddModal();
              this.getData();
            } else {
              this.setState({ loader: false });
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          console.log(err);
        });
    }

    //   console.log("function called with state");
    //   console.log(saveData);
    // this.getData();
    //   this.openAddModal();
  };

  // openAddModal = () => {

  //     if (this.state.modal == false) {

  //         this.setState({ modal: true })

  //     } else {
  //         this.setState({ modal: false })
  //         this.setState({ isEdit: false })
  //         this.setState({ editModeId: null })
  //     }

  // }

  componentDidMount() {
    this.getData();
    this.getAllAreaData();
    this.getUserRoleData();

    // AdminService.checkToken();
  }
  render() {
    return jsx.default.bind(this)();
  }

  testClickEvent(param) {
    alert("Row Click Event");
  }

  ///============get api==========///

  getData = () => {
    this.setState({ loader: true, searchText: "" });

    let adminEmail = this.props.email;

    fetch(constants.server + "api/data-retrieval/users/all/" + adminEmail, {
      method: "get",
      headers: new Headers({
        Authorization: this.props.token,
        "Content-Type": "application/json",
      }),
      body: null,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ loader: false });
        // console.log("Data-->" , data.data);
        let d = data.data.records;
        console.log("=======Sangeeta==========", data.data.records);

        console.log('dddd',d);
        let noSupervisorData = d;
        if(this.state.userRole == 7){
          
          noSupervisorData = d?.filter((x)=> x?.role !=7)
          

          console.log("entereddddd", noSupervisorData)
        }

        this.setState({ data: d, filteredData: noSupervisorData, noSupervisorData:  noSupervisorData});
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  ///==========change status api=============

  changeStatus(event, id, status) {
    let changed = 0;
    if (status == 1) {
      changed = 0;
    } else {
      changed = 1;
    }
    let statusData = { userId: id, userStatus: changed };

    fetch(constants.server + "api/data-import/users/update-status", {
      method: "POST",
      body: JSON.stringify(statusData),
      headers: {
        Authorization: this.props.token,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //toastr
        toast.success("Status updated successfully");
        this.getData();
      })
      .catch((err) => {
        //toastr
        toast.error("Error in status update");
      });
  }
  ///////===========delete user api ============//////////

  deleteEntry = (id) => {
    swal({
      title: "Are you sure to delete this user?",
      //text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          selectedIds: id,
        };

        AdminService.httpPost("/data-import/users/delete", data)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res;

              console.log(res);

              if (res.success == true) {
                toast.success("User deleted successfully");
                this.getData();
              } else {
                toast.error("Failed to delete user");
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  handleChange = ({ selectedRows }) => {
    this.setState({ bulkDelete: selectedRows });
  };

  handleChangeData = ({ toggleCleared }) => {
    this.setState({ bulkDelete: toggleCleared });
  };

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) => {
    let ids = [];

    this.state.bulkDelete.forEach((item) => {
      ids.push(item._id._id);
    
    });

    if (ids?.length > 0) {
      swal({
        title: "Are you sure you want to delete?",
        // text: "Once deleted, you will not be able to recover this user!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            selectedIds: ids.toString(),
          };
          AdminService.httpPost("/data-import/users/delete", data)
            .then((res) => {
              if (res) {
                this.setState({ loader: false });
                let d = res;

                console.log(res);

                if (res.success == true) {
                  toast.success("User deleted successfully");
                  this.getData();
                } else {
                  toast.error("Failed to delete user");
                }
              }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      });
    } else {
      swal({
        title: "No Record Selected",
        text: "Please select at a record to delete.",
        icon: "warning",
        button: true,
      });

      return false;
    }
  };
  ////get area data////

  getAllAreaData = async () => {
    AdminService.httpGet("/data-retrieval/area/getAreaData")
      .then((res) => {
        if (res) {
          let d = res.data;
          this.setState({ areaData: d });

          console.log("ar", d);
        }
      })
      .catch((err) => {
        console.log("errrr", err);
      });
  };

  ////<==============serach function=====>////
  onSearch = (val) => {
    this.setState({ searchText: val });
    // let d = this.state.data;
    console.log("this state userRole", this.state.userRole )
    let d = this.state.userRole == 7 ? this.state.noSupervisorData : this.state.data;
    let fd = null;
    if (val == "") {
      fd = d;
    } else {
      fd = d.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(val.toLowerCase())) 
          || (item.email && item.email.toLowerCase().includes(val.toLowerCase()))
          || (item.Organization && item.Organization.toLowerCase().includes(val.toLowerCase()))
          || (item.designation && item.designation.toLowerCase().includes(val.toLowerCase()))
         
      );
    }

    this.setState({ filteredData: fd });
  };

  ///////=======get facility ==========////////

  getUserRoleData = async () => {
    this.setState({ loader: true });

    AdminService.httpGet("/data-retrieval/data/masterData")
      .then((res) => {
        if (res) {
          let d = res[0].data;

          console.log("somra test==============>", d);
          this.setState({ facilityData: d });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  email: state.login.userEmail,
});

export default connect(mapStateToProps)(User);
