import React from 'react';
import ReactECharts from 'echarts-for-react';


const ChartData = () => {
    const options = {
      grid: false,
      withInnerLines:false,
      propsForBackgroundLines: {
        strokeWidth: 0
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: {
          show: false, // Hide full Line
          symbol: 'none'
      },
      lineThickness: 0,
      tickThickness: 0

      },

      yAxis:{
        lineThickness: 0,
        gridThickness: 0,
        tickLength: 0
      },

      series: [
        {
          data: [820, 932, 901, 934, 1290, 1330, 1320],
         type: 'line',
          smooth: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
    };
  
    return <ReactECharts option={options} />;
  };
  
  export default ChartData;