// import { Modal } from 'bootstrap';
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const AlertModal = ({modalStatus}) => {

  const [show, setShow] = useState(false);



  useEffect(() => {
    if(modalStatus == 10){
      setTimeout(() => {
        setShow(true);
      }, 1000);
    }
   
  }, []);

  return (
    <>
      <Modal className="custom-modal disapprove_popup da_popup"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-hcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-hcenter">
            Please select form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-items-center">
          <Row className="text-right justify-content-center">
            <Col>
              <Button onClick={() => setShow(false)} className="btn-close">
                Ok
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(AlertModal);
