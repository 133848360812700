import React, { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdminService from '../../../services/admin.service';
export default function Inittabs(props) {

    const [FormState, setFormState] = useState(
        {
            typeOfWaste:''

    });
    const [invalid, setInvalid] = useState('')
    const [invalid2, setInvalid2] = useState('')

    const [typeOfProfile, setTypeOfProfile] = useState([])
    const handleMultiSelectChange = (event) => {
        const {
            target: { value },
        } = event;
        setInvalid('')

        setTypeOfProfile(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

     

    };




    const textChange = (event) => {
        setFormState({ ...FormState, typeOfWaste: event.target.value });

        setInvalid2('')

    }


    const profileType = [
        "Generator",
        "Collector",
        "Recycler",
        "Importer/Exporter",
        "Disposer",
        "Reclaimer"
    ]


   const  goToProfile = () =>{

    const id1 = localStorage.getItem('userInfo')
    const gData = JSON.parse(id1)
     
        const data = {
            user_id:gData.id,
            profile_type:typeOfProfile,
            waste_type:FormState.typeOfWaste
        }

         if(typeOfProfile==null || typeOfProfile=='' && FormState.typeOfWaste=='' ||   FormState.typeOfWaste==null ){
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            return;
        }
        else if(typeOfProfile==null || typeOfProfile=='' && FormState.typeOfWaste!='' ){
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('')
            return;
        }
        else if(FormState.typeOfWaste==null || FormState.typeOfWaste=='' && typeOfProfile!=''){
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            setInvalid('');
            return;
        }
        else{

        
    
    
       
            props.step1Call(data)
           

            setInvalid('');
            setInvalid2('')
            props.startData(data)
        }




      

    }



 

    return (
        <>
            <div className='profileInit'>
                <div className='container'>
                    <div className="row px-110">
                        <div className='col-md-12'>
                            <h4 className='profile-heading'>Welcome to Your Dashboard</h4>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style">
                                <div className="mat-form-field-wrapper">
                                    <span>Profile Type</span>
                                    <Select
                                        id="profileType"
                                        name='profileType'
                                        className='profile-select'
                                        multiple
                                        value={typeOfProfile}
                                        onChange={handleMultiSelectChange}
                                    >
                                        {profileType.map((profileType) => (
                                            <MenuItem
                                                className="multiselectCheckbox"
                                                key={profileType}
                                                value={profileType}
                                            >
                                                {profileType}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <div dangerouslySetInnerHTML={{__html: invalid? invalid:''}}></div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style">
                                <div className="mat-form-field-wrapper">
                                    <span>Waste Type</span>
                                    <Select
                                        name='typeOfWaste'
                                        className='profile-select'
                                        value={FormState.typeOfWaste}
                                        onChange={textChange}
                                    >
                                        <MenuItem     value={"Solid"}>Solid</MenuItem>
                                        <MenuItem     value={"Hazardous"}>Hazardous</MenuItem>
                                    </Select>
                                    <div  dangerouslySetInnerHTML={{__html: invalid2? invalid2:''}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='align-content-end col-md-2 d-flex flex-wrap'>
                            <button type='submit' onClick={goToProfile} className=''> Go <img src="assets/lib/images/home/arrow_right.svg" /></button>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-11'>
                            <img src="assets/lib/images/profile-dashboard.svg" className="img-fluid mt-100" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}