import { Component } from 'react'
import './profile.css';
import * as jsx from './profile.module.jsx';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminService from '../../../services/admin.service';
import { connect, useDispatch } from "react-redux";
import eventBus from '../../../features/eventBus';
import { useNavigate } from 'react-router';
import history from '../../../services/history';

class ProfilePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value:0,
            loader:false,
            sendStartData:null,
            profileOpen:false,
            pData:[],
            sendAreaData:[],
            isSaveAndExit: false
        }
    }


    componentDidMount() {
        this.profileDone()
        this.getAreaData()
      
    }



    render() {
        return jsx.default.bind(this)();
    }
    handleChange = (event, newValue) => {
        this.setState({value : newValue} );
    };

    completion =(e)=>{
    localStorage.setItem('perc', e);
       console.log('dsdsdsdsdsdsdsds',e)
        eventBus.dispatch("completion", { message: e });
    }

    isSaveAndExit =(e)=>{
        console.log("Kya hai ye pagalpan? ----", e)
        if(e==true){
            this.setState({isSaveAndExit: true})
        } else{
            this.setState({isSaveAndExit: false})
        }
    }
    // applyCoupon = () => {
       
    //     eventBus.dispatch("couponApply", { message: "coupone applied" });
    //   };

    getAreaData = async () => {
        this.setState({ loader: true })
    
        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
            
                    if(res){
                        this.setState({loader:false})
                        let d = res.data;
                        this.setState({sendAreaData: d})
                    }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    
    
    }




    profileDone = () => {
        this.setState({loader:true})
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        console.log('dsaasasassasa', gData.id)
      

        AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
        .then(data=>{

            if(data.success === true){
                this.setState({loader: false})
                console.log('GET DATA CHECKUP FOR PROGRESS-----',localStorage.getItem(data.data[0]))
             this.setState({pData: [data.data[0], data.data[0]]})
             if(data.data!=null && data.data!=''){
                this.setState({profileOpen:true})
                // eventBus.dispatch("completion", { message: data.data[0] });
             }
         
             console.log(this.state.profileDone)
            }
            else {
                throw new Error("Error in getting actor info")
            }
        })
    }



    getStartData =(e)=>{
        this.setState({sendStartData:e})
        this.setState({profileOpen:true})
  
      }

    saveStep1 = (data) => {
        console.log('data', data)

        this.setState({loader: true})

        AdminService.httpUserPost('/data-import/actor/add', data).then((res)=>{

            console.log(res['success'])
            if(res['success']===true){
                this.setState({loader: false})
                    // this.getDataAgain()
                
                toast.success('Profile updated successfully!') 

           if(this.state.isSaveAndExit == true){
            
            history.push('/dashboard/tracker')
           }



            }else if(res['success']==false){
                this.setState({loader: false})
                this.getDataAgain()
                toast.error('Profile updation failed!') 
            }
            
        }).catch((err)=>{
            this.setState({loader: false})
            toast.error(err) 
            console.log('Profile js updation failed error:---',err)
        })

    }





getDataAgain =()=>{
    const id1 = localStorage.getItem('userInfo')
    const gData = JSON.parse(id1)
    AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
    .then(data=>{

        console.log('tab data is', data.data[0])
        
        if(data.success === true){
          let dt =   data?.data?.[0]
          this.setState({pData:dt})
          this.forceUpdate();
        }
        else {
            throw new Error("Error in getting actor info")
        }
    })
}


}


export default connect(
   
  )(ProfilePage);