import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';


function ProfileModal(props) {

    const [show, setShow] = useState(false);

useEffect(()=>{

console.log('test',props.profileData)

},[props])

    return (
<>



          <div className="modal maps_popup fade" id="actorprofile" tabindex="-1" aria-labelledby="actorprofileLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <div class="modal-title h4">Actor Profile Detail</div>
                  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div className='facility_details'>
                    <div className='fd_text pl-0'>
                      <ul className='fd_text_box'>

                      <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/username.svg' />
                          </div>
                          
                          <div className='fdt_text'>
                            <h5>Name</h5>
                            <h4>{props.profileData?.contact_person? props.profileData?.contact_person: '--'}</h4>
                          </div>
                        </li>
                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/email.svg' />
                          </div>
                          
                          <div className='fdt_text'>
                            <h5>Email</h5>
                            <h4>{props.profileData?.usersData?.[0]?.email? props.profileData?.usersData?.[0]?.email: '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/mappointer.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Address</h5>
                            <h4>{props.profileData?.address!=null? props.profileData?.address: '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/user.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Position</h5>
                            <h4>{props.profileData?.position!=null? props.profileData?.position : '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/call.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Phone</h5>
                            <h4>{props.profileData?.phone!=null? props.profileData?.phone : '--'}</h4>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/fax.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Fax</h5>
                            <h4>{props.profileData?.fax!=null? props.profileData?.fax : '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/company_number.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Company Registration Number</h5>
                            <h4>{props.profileData?.company_registration_no!=null? props.profileData?.company_registration_no : '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/date.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Registration Date</h5>
                            <h4>{props.profileData?.date_of_registration!=null?props.profileData?.date_of_registration : '--'}</h4>
                          </div>
                        </li>

                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/global.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Registration Country</h5>
                            <h4>Eswatini</h4>
                          </div>
                        </li>

                        <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/facility-type.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Profile Type</h5>
                        <h4>{props.profileData.profile_type!=null? props.profileData.profile_type?.map((item, index)=>{ return (<>{props.profileData?.profile_type?.length==index+1? item: item + ', '}</>);}) : '--'}</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/delete.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Waste Type</h5>
                        <h4>{props.profileData?.waste_type} </h4>
                      </div>
                    </li>
                      </ul>
                    </div>
                  </div>
                  <div class="accordion" id="actorprofileExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="hapOne">
                        <button type="button" aria-expanded="false" class="accordion-button collapsed" data-toggle="collapse" data-target="#apOne">Accordion 1</button>
                      </h2>
                      <div class="accordion-collapse collapse" id="apOne" aria-labelledby="hapOne" data-parent="#actorprofileExample">
                        <div class="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="hapTwo">
                        <button type="button" aria-expanded="false" class="accordion-button collapsed" data-toggle="collapse" data-target="#apTwo">Accordion 2</button>
                      </h2>
                      <div class="accordion-collapse collapse" id="apTwo" aria-labelledby="hapTwo" data-parent="#actorprofileExample">
                        <div class="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="hapThree">
                        <button type="button" aria-expanded="false" class="accordion-button collapsed" data-toggle="collapse" data-target="#apThree">Accordion 3</button>
                      </h2>
                      <div class="accordion-collapse collapse" id="apThree" aria-labelledby="hapThree" data-parent="#actorprofileExample">
                        <div class="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="hapFour">
                        <button type="button" aria-expanded="false" class="accordion-button collapsed" data-toggle="collapse" data-target="#apFour">Accordion 4</button></h2>
                      <div class="accordion-collapse collapse" id="apFour" aria-labelledby="hapFour" data-parent="#actorprofileExample">
                        <div class="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

</>



    );
}


export default ProfileModal;