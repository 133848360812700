import React from 'react';
import DataTable from "react-data-table-component";
import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import AddUserModal from "../../Modals/addUser.model";



var userTemplate = function () {
  // console.log("this" , this.state.filteredData);

  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let userRole = userInfo?.role;

  return (
    <>
      <div className="main" id="main">
      <div class="container-fluid areaCont">  
        <section className="areaSection" id="areaSection">
          <subHeaderComponentMemo.default
          PropDeleteBulk={this.deleteBulkUser}
            onSearch={this.onSearch}
            searchText={this.state.searchText}
            nRows={this.state.filteredData?.length}
            checkData = {this.state.bulkDelete}
          />
          <DataTable
            columns={this.columns}
            // data={userRole == 7 ? this.state.noSupervisorData : this.state.filteredData}
              data={this.state.filteredData}
            direction="auto"
            fixedHeaderScrollHeight="500px"
            pagination
            paginationRowsPerPageOptions={[10, 25, 50,100]}
            style={{ display: "flex" }}
            // subHeader
            // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
            persistTableHead
            selectableRows
            onSelectedRowsChange={this.handleChange}
            clearSelectedRows={this.handleChangeData}
            className='dataTableScroll'
            noDataComponent="No Data"
          />
        </section>
        </div>
      </div>
      {/* <AddUserModal /> */}

      {/* modal area begin here============== */}
      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

<div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
        <img
          src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
      </div>
      <div class="right-tab-heading are_head">
        <h2 class="form-heading">
          <span class="form-heading_he">
            <span class="ddf">
              {this.state.isEdit==true ? 'Edit user': 'Add user'}
            </span>
          </span>
        </h2>
      </div>

      <AddUserModal
          parentCallback={this.saveUserData}
          sendData={this.state.data}
          isEdit = {this.state.isEdit}
          areaTree = {this.state.areaData}
          newDataEntry ={this.saveUserData}
          editMode = {this.state.editModeId}
          facilityData={this.state.facilityData}
          editData = {this.state.editEntry}
          closeModal={this.openAddModal}
        
        />  
        <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>
   

      </div>
      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>
   
    </>
  );
};

export default userTemplate;
