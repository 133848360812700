import React,  {useEffect, useState} from "react";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { RenderTree, data } from "./sampleData";
import AdminService from '../../../services/admin.service';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      color: "rgba(81, 185, 201, 0.8)"
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "rgba(160, 81, 201, 1)"
      }
    }
  }
}));





export default function RecursiveTreeView(props) {

    const [data , setData ] = useState([])
 
const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      color: "rgba(81, 185, 201, 0.8)"
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "rgba(160, 81, 201, 1)"
      }
    }
  }
}));

    useEffect(()=>{
  
      console.log('propsdata',props.areaData)


      if(props.areaData!=null && props.areaData!=''){

        let flatLevel = [];

        props.areaData.forEach((item) => {
  
  
          item.areaSubData = [];
  
          flatLevel.push(item)
  
  
        })
  
  
        console.log('level1', flatLevel)
        const dataMain = setLevelData(flatLevel)
        setData(dataMain)
        console.log('>>>>>>d',data, );

      }


    },[props.areaData])



    const setLevelData = (flat) => {

      var roots = []
  
      var all = {}
  
      flat.forEach(function (item) {
        all[item.area_code] = item
      })
      Object.keys(all).forEach(function (area_code) {
        var item = all[area_code]
        if (item.parent_id === null || item.parent_id === '') {
          roots.push(item)
        } else if (item.parent_id in all) {
          var p = all[item.parent_id]
          if (!('areaSubData' in p)) {
            p.areaSubData = []
          }
          p.areaSubData.push(item)
        }
      })
  
      return roots
    }


  const [selected, setSelected] = useState([]);





  //node is always the root "Parent"
  //id is id of node clicked
  function getChildById(node, id) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    console.log('working');
    //gets all freshly selected or unselected nodes
    const allNode = getChildById(data, nodes.id);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    setSelected(array);
  }

  

  const renderTree = (nodes) => {
  

if(nodes!=null){
  console.log('nodes', nodes)
  nodes.map((item) => {
     
    return (<>
      <TreeItem
        key={item.id}
        nodeId={item.id}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.some((item) => item === item.id)}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                //onClick={(e) => e.stopPropagation()}
                className={useStyles.checkbox}
              />
            }
            label={<>{item.name}</>}
            key={item.id}
          />
        }
      >
        {Array.isArray(item.areaSubData)
          ? item.areaSubData.map((item) => renderTree(item))
          : null}
      </TreeItem>
    </>);
  


   })
}

    
    // return (
    //   <TreeItem
    //     key={nodes.id}
    //     nodeId={nodes.id}
    //     label={
    //       <FormControlLabel
    //         control={
    //           <Checkbox
    //             checked={selected.some((item) => item === nodes.id)}
    //             onChange={(event) =>
    //               getOnChange(event.currentTarget.checked, nodes)
    //             }
    //             //onClick={(e) => e.stopPropagation()}
    //             className={classes.checkbox}
    //           />
    //         }
    //         label={<>{nodes.name}</>}
    //         key={nodes.id}
    //       />
    //     }
    //   >
    //     {Array.isArray(nodes.children)
    //       ? nodes.children.map((node) => RenderTreeWithCheckboxes(node))
    //       : null}
    //   </TreeItem>
    // );
  };

  return (
    <>
      <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpanded={["0", "3", "4"]} defaultExpandIcon={<ChevronRightIcon />} >
        {setTimeout(()=>{renderTree(data)},2000)}
      </TreeView>      


    </>
  );
}
