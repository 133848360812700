import React, { useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
// ======================================================= Tab Start
import PropTypes from "prop-types";
import $ from "jquery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// ======================================================= Tab End
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// ======================================================= Dropdown End
import Dropdown from "react-bootstrap/Dropdown";
// ======================================================= Dropdown End
// ======================================================= Modal start
import Modal from "react-bootstrap/Modal";
// ======================================================= Modal End
// ======================================================= Table Start
import Table from "react-bootstrap/Table";
// ======================================================= Table End
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
// ======================================================= Checkbox Start
import Checkbox from "@mui/material/Checkbox";
// ======================================================= Checkbox End
// ======================================================= Datatable Start
import FixedHeaderStory from "react-data-table-component";
// ======================================================= Datatable End
import Tooltip from "@mui/material/Tooltip";

import AdminService from "../../services/admin.service";
import RecursiveTreeView from "../pages/manage-form/area";

// ======================================================== Multiselect Dropdown Start
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Stack } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";

import swal from "sweetalert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["JPG", "PNG"];

const document = ["PDF", "Doc"];
const AudioFormateType = ["MP3", "MP4", "WAV"];
const VideoFormateType = ["MP4", "AVI", "WEBM"];

var areaSelected = [];
// ======================================================== Multiselect Dropdown End

const FormMasterModal = (props) => {
  console.log("data--->", props.editModeId);
  $(".tabsection1").on("click", function (e) {
    e.stopPropagation();
    e.preventDefault();
    $(".tabSection2").addClass("d-none");
    $(".tabSection1").removeClass("d-none");
    setValue(0);
  });

  $(".tabsection2").on("click", function (e) {
    e.stopPropagation();
    e.preventDefault();
    $(".tabSection1").addClass("d-none");
    $(".tabSection2").removeClass("d-none");
    setValue(1);
  });

  const form = useRef();
  const [copyForm, setCopyForm] = useState({
    target: "",
    copy: "",
  });
  const [FormState, setFormState] = useState({
    id: "",
    formName: "",
    description: "",
    time_period_format: "",
    time_period: "",
    source: "",
    area: "",
    assigned_user: "",
  });

  useEffect(() => {
    if (props.isEdit == true) {
      setSurveyEditMode(true);
      // console.log("data-------", props.editModeId);
      setFormState({
        id: props.editModeId._id,
        formName: props.editModeId.name.en ?? props.editModeId.name.en,
        description: props.editModeId.description.en ?? props.editModeId.description,
        time_period_format: props.editModeId.time_period_format,
        time_period: props.editModeId.time_period,
        source: props.editModeId.source,
        area: props.editModeId.area,
        assigned_user: props.editModeId.area,
      });

      setTimeFormatValue(props.editModeId.time_period_format);
      getQuestions(props.editModeId._id);
      get_mangage_section();
    } else {
      setSurveyEditMode(false);
      setFormState({
        formName: "",
        description: "",
        time_period_format: "",
        time_period: "",
        source: "",
        area: "",
        assigned_user: "",
      });

      setTimeFormatValue("");
    }

    if (props.copyForm) {
      setCopyForm({ ...copyForm, target: props.copyForm.name });
    }
  }, [props]);
  const [timeFormtValue, setTimeFormatValue] = useState(0);
  const [timeFormat, setTimeFormat] = useState([
    { label: "YYYY", value: "YYYY" },
    { label: "YYYY.MM", value: "YYYY.MM" },
    { label: "YYYY.MM.DD", value: "YYYY.MM.DD" },
    { label: "YYYY - YYYY", value: "YYYY - YYYY" },
    { label: "YYYY.MM - YYYY.MM", value: "YYYY.MM - YYYY.MM" },
    { label: "YYYY.MM.DD - YYYY.MM.DD", value: "YYYY.MM.DD - YYYY.MM.DD" },
  ]);

  const [timeValue, setTimeValue] = useState(0);
  const [timeSelect, setTime] = useState([
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
  ]);

  const submitCopyForm = (e) => {
    e.preventDefault();

    const data = {
      from_survey_id: props.copyForm._id,
      survey_name: copyForm.copy,
    };

    props.saveCopyForm(data);
  };

  // ======================================================= Tab Start
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        // id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const [assigned_list, setassigned_list] = useState([]);
  const handleChange = (event) => {
    // console.log("ev: ", event.target.value);

    // console.log("abc", FormState);

    if (event.target.name == "time_period_format") {
      let selectedVal = event.target.value;
      setTimeFormatValue(selectedVal);
      setFormState({ ...FormState, [event.target.name]: selectedVal });
    } else if (event.target.name == "time_period") {
      let selectedVal = event.target.value;
      setTimeValue(selectedVal);
      setFormState({ ...FormState, [event.target.name]: selectedVal });
    } else {
      setFormState({ ...FormState, [event.target.name]: event.target.value });
    }
  };

  // ======================================================= Tab Start
  // ======================================================= Dropdown Start
  const [format, setFormat] = useState("");
  const [section, setSection] = useState([]);
  const [mandatory, setmandatory] = useState(true);
  const [aggregate, setAggregate] = useState(true);

  const [year, setData] = useState("2023");
  const [month, setMonth] = useState("01");
  const [day, setDay] = useState("01");
  const [indicatorUnit, setIndicatorUnit] = useState();
  const [subgroupList, setSubgroupList] = useState();
  const [subgroupid, setSubgroupid] = useState();
  const [iuid, setiuid] = useState();
  const [subgroup2, setSubgroup] = useState();
  const [qustion_text, setqustion_text] = useState();
  const [dateFormat, setDateFormat] = useState();
  const [defaultValue, setdefaultValue] = useState("");

  const [miniValue, setminiValue] = useState("");
  const [maxvalue, setmaxvalue] = useState("");
  const [decimal, setDecimal] = useState("");
  const [hint, setHint] = useState("");

  const [subsection, subbSection] = useState("");

  const [sizeInImage, setsizeInImage] = useState();
  const [sizeInAudio, setSizeInAudio] = useState();
  const [sizeInVideo, setSizeInVideo] = useState();

  const [row, setRow] = useState("1");
  const [column, setColumn] = useState("1");
  // const [gridColumns, setGridColumns] = useState({
  //   1: "A",
  //   2: "B",
  //   3: "C",
  //   4: "D",
  //   5: "E",
  // });

  const [gridColumns, setGridColumns] = useState({
    "1": {
        "en": "A",
        "pt": "",
        "tet": ""
    },
    "2": {
        "en": "B",
        "pt": "",
        "tet": ""
    },
    "3": {
        "en": "C",
        "pt": "",
        "tet": ""
    },
    "4": {
        "en": "D",
        "pt": "",
        "tet": ""
    },
    "5": {
        "en": "E",
        "pt": "",
        "tet": ""
    }
});

const [gridRows,setGridRows] =useState({
  "1": {
      "en": "K",
      "pt": "",
      "tet": ""
  },
  "2": {
      "en": "L",
      "pt": "",
      "tet": ""
  },
  "3": {
      "en": "M",
      "pt": "",
      "tet": ""
  },
  "4": {
      "en": "N",
      "pt": "",
      "tet": ""
  },
  "5": {
      "en": "O",
      "pt": "",
      "tet": ""
  },
  "6": {
      "en": "P",
      "pt": "",
      "tet": ""
  },
  "7": {
      "en": "Q",
      "pt": "",
      "tet": ""
  },
  "8": {
      "en": "R",
      "pt": "",
      "tet": ""
  },
  "9": {
      "en": "S",
      "pt": "",
      "tet": ""
  },
  "10": {
      "en": "T",
      "pt": "",
      "tet": ""
  },
  "11": {
      "en": "U",
      "pt": "",
      "tet": ""
  },
  "12": {
      "en": "V",
      "pt": "",
      "tet": ""
  },
  "13": {
      "en": "W",
      "pt": "",
      "tet": ""
  },
  "14": {
      "en": "X",
      "pt": "",
      "tet": ""
  },
  "15": {
      "en": "Y",
      "pt": "",
      "tet": ""
  },
  "16": {
      "en": "Z",
      "pt": "",
      "tet": ""
  }
})

  // const [gridRows, setGridRows] = useState({
  //   1: "K",
  //   2: "L",
  //   3: "M",
  //   4: "N",
  //   5: "O",
  //   6: "P",
  //   7: "Q",
  //   8: "R",
  //   9: "S",
  //   10: "T",
  //   11: "U",
  //   12: "V",
  //   13: "W",
  //   14: "X",
  //   15: "Y",
  //   16: "Z",
  // });

  // const [tempGridNames, setTempGridNames] = useState({
  //   1: "A",
  //   2: "B",
  //   3: "C",
  //   4: "D",
  //   5: "E",
  // });

  const [tempGridNames, setTempGridNames] = useState({
    "1": {
      "en": "A",
      "pt": "",
      "tet": ""
  },
  "2": {
      "en": "B",
      "pt": "",
      "tet": ""
  },
  "3": {
      "en": "C",
      "pt": "",
      "tet": ""
  },
  "4": {
      "en": "D",
      "pt": "",
      "tet": ""
  },
  "5": {
      "en": "E",
      "pt": "",
      "tet": ""
  }
  })



  const [gridQuestions, setGridQuestions] = useState({});
  const [activeGridQuestion, setActiveGridQuestion] = useState({
    type: "",
    indicatorUnit: "",
    subgroup: "",
    numeric: { default: 0, minimum: 0, maximum: 0,decimal:0 },
    singleChoice: { selectedOption: [], iusMap: {} },
    aggregate: true
  });
  const [activeGrid, setActiveGrid] = useState();
  const [gridSubgroups, setGridSubgroups] = useState([]);
  const [gridSelectedOption, setGridSelectedOption] = useState([]);
  const [gridOptionMapping, setGridOptionMapping] = useState([]);

  const checkGridValidation = (key) => {
    console.log(gridQuestions[key]?.type);
    if (gridQuestions[key]?.type == "") {
      return false;
    } else if (gridQuestions[key]?.type == "Textbox") {
      if (
        gridQuestions[key]?.indicatorUnit == "" ||
        gridQuestions[key]?.subgroup == ""
      ) {
        return false;
      } else {
        return true;
      }
    } else if (gridQuestions[key]?.type == "Numeric") {
      if (
        gridQuestions[key]?.indicatorUnit == "" ||
        gridQuestions[key]?.subgroup == ""
      ) {
        return false;
      } else {
        return true;
      }
    } else if (gridQuestions[key]?.type == "Single Choice") {
      if (gridQuestions[key]?.singleChoice?.selectedOption.length == 0) {
        return false;
      } else {
        let status = true;
        gridQuestions[key]?.singleChoice?.selectedOption.forEach((x) => {
          if (
            gridQuestions[key]?.singleChoice?.iusMap[x?.name]?.indicatorUnit ==
            "" ||
            gridQuestions[key]?.singleChoice?.iusMap[x?.name]?.subgroup == ""
          ) {
            status = false;
          }
        });
        return status;
      }
    }
  };

  const changeNumericValue = (key, value) => {
    let preValue = activeGridQuestion;
    preValue.numeric[key] = value;
    // console.log("changeNumericValue:: ", preValue, value)
    setActiveGridQuestion({ ...activeGridQuestion, ...preValue });
  };
  const changeSelectedOption = ({ selectedRows }) => {
    setGridSelectedOption(selectedRows);
  };
  const confirmGridSelectedOption = () => {
    let preValue = activeGridQuestion;
    preValue.singleChoice.selectedOption = gridSelectedOption;
    gridSelectedOption.forEach((x) => {
      preValue.singleChoice.iusMap[x.name] = {
        indicatorUnit: "",
        subgroup: "",
      };
    });
    // console.log(gridSelectedOption);
    setActiveGridQuestion({ ...preValue, ...activeGridQuestion });
  };
  const changeGridMapIndicator = (value, key) => {
    let preValue = activeGridQuestion;
    preValue.singleChoice.iusMap[key].indicatorUnit = value;
    console.log(key, value, preValue);
    setActiveGridQuestion({ ...preValue, ...activeGridQuestion });
  };
  const changeGridSubgroup = (value, key) => {
    let preValue = activeGridQuestion;
    preValue.singleChoice.iusMap[key].subgroup = value;
    setActiveGridQuestion({ ...preValue, ...activeGridQuestion });
  };
  const changeSingleChoiceValue = () => { };
  const changeActiveQuestion = (key, value) => {
    let preValue = activeGridQuestion;
    preValue[key] = value;
    if (key == "indicatorUnit") {
      preValue.subgroup = "";
    }
    // console.log("changeActiveQuestion:: ", preValue, value)
    setActiveGridQuestion({ ...activeGridQuestion, ...preValue });
  };

  const saveGridQuestion = () => {
    let preValue = gridQuestions;
    preValue[activeGrid] = activeGridQuestion;
    // console.log("saveGridQuestion:: ", gridQuestions, activeGridQuestion);
    setGridQuestions({ ...gridQuestions, ...preValue });
  };

  // ======================================================= Dropdown End
  // ======================================================= Datepicker Modal Start
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userchecked, setuserchecked] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  // ======================================================= Datepicker Modal End
  // ======================================================= Data Table Start
  const columns = [
    {
      name: "Area",
      selector: (row) => row.area,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => (
        <Tooltip title={row.user} placement="top">
          <p>{row.user}</p>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <img
            src="assets/lib/images/manage-form/trash.svg"
            onClick={() => {
              deleteClick(row.area);
            }}
          />
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const data = assigned_list;

  const columns1 = [
    {
      name: "Question",
      selector: (row) => row.question.en ?? row.question,
      sortable: true,
    },
    {
      name: "Section",
      selector: (row) => row.section.en ?? row.section,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Mandatory Question",
      selector: (row) => row.mandatoryquestion,
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div class="dropdown table-action">
            <button
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              Select
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#addquestion"
                onClick={() => {
                  QuestionEdit(row);
                }}
              >
                Edit
              </a>
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                onClick={() => {
                  copyQuestion(row._id);
                }}
              >
                Copy Question
              </a>
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                onClick={() => {
                  deleteEntry(row._id);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // console.log("questionData", questionData);
  const [subSection, setSubSection] = useState([]);
  const [newSubSection, setNewSubSection] = useState("");

  const data1 = questionData;
  const [manageSection, setmanageSection] = useState();
  const [getmanageSection, setgetmanageSection] = useState([]);
  const [manageOption, setmanageOption] = useState();
  const [getmanageOption, setgetmanageOption] = useState([]);
  const updateOption = () => {
    let obj = {
      name: activeOption.name,
    };
    // console.log(activeOption);
    AdminService.httpPut(`/data-import/option/update/${activeOption._id}`, obj)
      .then((x) => {
        toast.success(x.message);
        get_mangage_option();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const updateSubSection = () => {
    let obj = {
      name: activeSubSection.name,
      section_id: activeSubSection.section_id,
      id: activeSubSection._id,
    };
    AdminService.httpPut(
      `/data-import/section/update-subsection/${activeSubSection._id}`,
      obj
    )
      .then((x) => {
        toast.success(x.message);
        get_mangage_section();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const deleteSection = (row) => {
    // console.log(row);
    AdminService.httpPost(`/data-import/section/delete`, { id: row._id })
      .then((x) => {
        toast.success(x.message);
        get_mangage_section();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };
  const deleteSubSection = (row) => {
    // console.log(row);
    AdminService.httpPost(`/data-import/section/delete-subsection`, {
      id: row._id,
    })
      .then((x) => {
        toast.success(x.message);
        get_mangage_section();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  const deleteMultiSubSection = () => {
    let ids = [];
    selectedSubSections.forEach((x) => {
      ids.push(x._id);
    });
    AdminService.httpPost(`/data-import/section/selected-subsection/delete`, {
      id: JSON.stringify(ids),
    })
      .then((x) => {
        toast.success(x.message);
        get_mangage_section();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  const deleteOption = (row) => {
    // console.log(row);
    AdminService.httpPost(`/data-import/option/delete`, { id: row._id })
      .then((x) => {
        toast.success(x.message);
        get_mangage_option();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };
  const deleteMultiOption = () => {
    let ids = [];
    selectedOption.forEach((x) => {
      ids.push(x._id);
    });
    AdminService.httpPost(`/data-import/option/selected/delete`, {
      id: JSON.stringify(ids),
    })
      .then((x) => {
        toast.success(x.message);
        get_mangage_option();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };
  const managecolumns = [
    {
      name: "#",
      selector: (row) => row.question + 1,
      sortable: true,
    },
    {
      name: "Section",
      selector: (row) => row.section,
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div class="dropdown table-action">
            <button
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              Select
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                onClick={() => {
                  {
                    setActiveOption(row);
                    // console.log(row);
                  }
                }}
                data-toggle="modal"
                data-target="#editsection"
              >
                Edit
              </a>
              <a
                class="dropdown-item"
                href="javascript:void(0); "
                onClick={() => {
                  deleteSection(row);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const managecolumnsOption = [
    {
      name: "#",
      selector: (row) => row.question + 1,
      sortable: true,
    },
    {
      name: "Option",
      selector: (row) => row.option,
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div class="dropdown table-action">
            <button
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              Select
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                onClick={() => {
                  {
                    setActiveOption(row);
                    // console.log(row);
                  }
                }}
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#editsection"
              >
                Edit
              </a>
              <a
                class="dropdown-item"
                onClick={() => {
                  deleteOption(row);
                }}
                href="javascript:void(0);"
              >
                Delete
              </a>
            </div>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const managedata = getmanageSection;

  const managesubcolumns = [
    {
      name: "#",
      selector: (row, i) => i + 1,
      sortable: true,
    },
    {
      name: "Section",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div class="dropdown table-action">
            <button
              class="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              Select
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                onClick={() => {
                  {
                    setActiveSubSection(row);
                    // console.log(row);
                  }
                }}
                data-toggle="modal"
                data-target="#editsubsection"
              >
                Edit
              </a>
              <a
                class="dropdown-item"
                href="javascript:void(0); "
                onClick={() => {
                  deleteSubSection(row);
                }}
              >
                Delete
              </a>
            </div>
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // ======================================================= Data Table End

  // ======================================================= Multiselect Dropdown Start

  const [personName, setPersonName] = useState([]);

  const [userdata, setuserdata] = useState([]);
  const [area, setarea] = useState([
    {
      checkbox: false,
      val: "Chhattisgarh",
      disable: false,
    },
    {
      checkbox: false,
      val: "Delhi",
      disable: false,
    },
    {
      checkbox: false,
      val: "Panjab",
      disable: false,
    },
    {
      checkbox: false,
      val: "new Delhi",
      disable: false,
    },
    {
      checkbox: false,
      val: "himachal pradesh",
      disable: false,
    },
  ]);

  // disable_bg

  const handleTable = (selectedtableRows) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    // console.log("Selected Rows: ", selectedtableRows.selectedRows);
  };
  const [userSelected, setuserSelected] = useState();

  // ---------------------------

  const setFormData = () => {
    // alert()

    setFormState({
      ...FormState,
      formName: FormState.formName,
      description: FormState.description,
      time_period_format: FormState.time_period_format,
      time_period: FormState.time_period,
      source: FormState.source,
      area: "",
      assigned_user: "",
    });

    let obj = {
      area: FormState.area,
      assigned_user: FormState.assigned_user,
      description: FormState.description,
      name: FormState.formName,
      source: FormState.source,
      status: 1,
      time_period: FormState.time_period,
      time_period_format: FormState.time_period_format,
    };
    // console.log(obj)
    // return
    if (surveyEditMode) {
      let editSId = FormState.id;
      AdminService.httpPut(`/data-import/survey/update/${editSId}`, obj).then(
        (res) => {
          // console.log("update->", obj);
          if (res.success == true) {
            toast.success(res.message);
          }
        }
      );
    } else {
      AdminService.httpPost("/data-import/survey/add", obj).then((res) => {
        // console.log("check->", obj);
        if (res.status == 200) {
          toast.success(res.message);
          a11yProps(1);
          setFormState({ id: res.data._id });
        }
      });
    }
    // closePopUp()
    $(".tabsection2").click();
  };
  const previousClick = () => {
    $(".tabsection1").click();
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    // console.log("FormState:: ", FormState);
  };

  // ======================================================= Multiselect Dropdown Start

  const handleDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [age, setAge] = useState("");
  const [searchSeaction, setseachSection] = useState("");
  const [indiUnitSearch, SetIndiUnitSearch] = useState("");
  const [subgroupSearch, SetSubgroupSearch] = useState("");
  const [questionEditMode, setQuestionEditMode] = useState(false);
  const [surveyEditMode, setSurveyEditMode] = useState(false);
  const handleSelect = (event) => {
    setAge(event.target.value);
  };

  const [subgroup, subGroup] = useState("");
  const [iuData, setiuData] = useState([]);
  const [editModeId1, seteditmodeId1] = useState("");

  const handleSelect1 = (event) => {
    subGroup(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState([]);

  const [finalSelectedOption, setFinalSelectedOption] = useState([]);
  const [mappingIndicatorValue, setmappingIndicatorValue] = useState({});
  const [finalMapingValue, setFinalMapvalue] = useState({});
  const [activeOption, setActiveOption] = useState({ name: "" });

  const [activeSubSection, setActiveSubSection] = useState({ name: "" });

  const selectManageOption = ({ selectedRows }) => {
    // console.log("selectedRows", selectedRows);
    setSelectedOption(selectedRows);
  };

  const confirmSelectedOption = () => {
    // console.log("confirmSelectedOption-------->", selectedOption);
    setFinalSelectedOption(selectedOption);

    let indicatorValueMap = {};
    selectedOption.forEach((x) => {
      if (!indicatorValueMap.hasOwnProperty(x.name)) {
        indicatorValueMap[x.name] = {
          indicatorUnit: "",
          subgroup: "",
        };
      }
    });
    setmappingIndicatorValue(indicatorValueMap);
    setFinalMapvalue(indicatorValueMap);
  };

  const clearInitialMapping = () => {
    let indicatorValueMap = mappingIndicatorValue;
    Object.keys(indicatorValueMap).forEach((x) => {
      indicatorValueMap[x] = {
        indicatorUnit: "",
        subgroup: "",
      };
    });
    setmappingIndicatorValue({
      ...mappingIndicatorValue,
      ...indicatorValueMap,
    });
  };

  const checkMappingValidation = () => {
    let status = true;
    // console.log(finalMapingValue);
    if (Object.keys(finalMapingValue).length > 0) {
      Object.keys(finalMapingValue).forEach((x) => {
        if (
          finalMapingValue[x].indicatorUnit == "" ||
          finalMapingValue[x].subgroup == ""
        ) {
          status = false;
        }
      });
    } else {
      status = false;
    }

    return status;
  };

  const changeMapIndicatorValue = (value, key) => {
    let preValue = mappingIndicatorValue;
    //   console.log("daaaaa" , value , key);
    if (preValue[key]) {
      preValue[key].indicatorUnit = value;
    } else {
      preValue[key] = { indicatorUnit: value };
    }
    setmappingIndicatorValue({ ...mappingIndicatorValue, ...preValue });
  };

  const changeMapSubgroupVAlue = (value, key) => {
    // console.log("datttttttt0" , value , key);
    let preValue = mappingIndicatorValue;
    preValue[key].subgroup = value;
    setmappingIndicatorValue({ ...mappingIndicatorValue, ...preValue });
  };

  const getdata = () => {
    // console.log("caaaaaaaaaaallllll");
    // https://itmcloud.org/ters_apigateway/api/data-retrieval/users/data-entry-user
    AdminService.httpGet("/data-retrieval/users/data-entry-user").then(
      (res) => {
        res.data.records.forEach((ele) => {
          ele.checkbox = false;
          if (ele.areaData.length) {
            ele.area = ele.areaData[0].name;
          } else {
            ele.area = "";
          }
        });

        // console.log("data------<" , res.data.records);
        setuserdata(res.data.records);
      }
    );
  };

  const [indicatorUnitData, setIndicatorUnitData] = useState({});
  const get_mangage_section = () => {
    AdminService.httpGet("/data-retrieval/section/get-section").then((res) => {
      let a = res.data.forEach((ele, index) => {
        ele.question = index;
        ele.section = ele.name["en"] === undefined ? ele.name : ele.name?.en;
        ele.name = ele.name["en"] === undefined ? ele.name : ele.name?.en;
      });

      // console.log("data------<", res.data);
      setgetmanageSection(res.data);
    });

    AdminService.httpGet("/data-retrieval/ius/get-form-ius-maping").then(
      (res) => {
        let obj = {};
        res.data.forEach((x) => {
          obj[`${x.name} | ${x.unit}`] = x.subgroup;
        });
        // console.log(res);
        setiuData(res.data);
        setIndicatorUnitData(obj);
      }
    );
    let obj = {};
    for (let i = 0; i < 16; i++) {
      for (let j = 0; j < 5; j++) {
        obj["" + i + j] = {
          type: "",
          indicatorUnit: "",
          subgroup: "",
          aggregate: true,
          numeric: {
            default: 0,
            minimum: 0,
            maximum: 0,
            decimal:0,
          },
          singleChoice: { selectedOption: [], iusMap: {} },
        };
      }
    }
    setGridQuestions(obj);

    // console.log("setGridQuestions:: ", obj, indicatorUnitData);
    AdminService.httpGet("/data-retrieval/section/get-sub-section").then(
      (res) => {
        res.data.forEach(ele => {
          ele.name = ele.name.en ?? ele.name
        })
        setSubSection(res.data);
      }
    );
  };

  const get_mangage_option = () => {
    AdminService.httpGet("/data-retrieval/option/get-option").then((res) => {
      res.data.forEach((ele, index) => {
        ele.question = index;
        ele.option = ele.name?.en;

        ele.section = ele.name["en"] === undefined ? ele.name : ele.name?.en;
        ele.name = ele.name["en"] === undefined ? ele.name : ele.name?.en;
      });
      setgetmanageOption(res.data);
      // console.log("data------<", res.data);
    });
  };

  const changearea = (event) => {
    // console.log("call", event.target.checked);

    if (event.target.checked) {
      for (const item of area) {
        if (item.val == event.target.value) {
          item.checkbox = true;
        }
      }

      setarea([...area]);
      areaSelected.push(event.target.value);
    } else {
      for (const item of area) {
        if (item.val == event.target.value) {
          item.checkbox = false;
        }
      }

      setarea([...area]);
      areaSelected = areaSelected.filter((ele) => {
        return ele != event.target.value;
      });
    }
  };

  const arr = assigned_list;
  const assignval = () => {
    areaSelected.forEach((ele) => {
      arr.push({ area: ele, user: userSelected });
    });
    // console.log("data000,", areaSelected, arr);

    for (const item of area) {
      // console.log(item.checkbox , item.disable);
      item.disable = item.checkbox;
    }
    for (const item of userdata) {
      item.checkbox = false;
    }

    setarea([...area]);

    setuserchecked(false);
    setassigned_list(arr);
    areaSelected = [];
    // setassigned_list([])
    // /
  };

  const deleteClick = (areava) => {
    var data = [];
    for (const item of area) {
      console.log("calll", area, item);

      if (areava == item.val) {
        item.checkbox = false;
        item.disable = false;
      }
    }

    data = assigned_list.filter((ele) => {
      return ele.area != areava;
    });
    setassigned_list(data);
    // console.log(data);
  };

  const [questionSearchText, setQuestionSearchText] = useState('')

  const addsection = () => {

    let obj = {
      name: manageSection,
    };
    AdminService.httpPost("/data-import/section/add", obj).then((res) => {
      if (res.status == 201) {
        toast.success(res.message);

        setmanageSection("");
        get_mangage_section();
      }
    });
  };

  const addSubsection = () => {
    AdminService.httpPost("/data-import/section/add-subsection", {
      name: newSubSection,
      section_id: section._id,
    }).then((res) => {
      if (res.status == true) {
        toast.success(res.message);

        setNewSubSection("");
        get_mangage_section();
      }
    });
  };

  const addoption = () => {
    // console.log("vall->" ,manageSection);
    // https://itmcloud.org/gimtstagingapigateway//api
    // Payload:
    // {
    //     "name": "Test"
    // }

    let obj = {
      name: manageOption,
    };
    AdminService.httpPost("/data-import/option/add", obj).then((res) => {
      console.log("callllll->", obj);
      if (res.success) {
        toast.success(res.message);

        setmanageOption("");
        get_mangage_option();
      }
    });
  };

  const selectUser = (e) => {
    setuserSelected(e.target.value);
    for (const item of userdata) {
      if (`${item.name} | ${item.area}` == e.target.value) {
        item.checkbox = true;
      } else {
        item.checkbox = false;
      }
    }
  };

  // get Question ------------------------------------
  const getQuestions = async (id) => {
    let data111 = [];
    AdminService.httpGet("/data-retrieval/section/get-section").then((res) => {
      res.data.forEach((ele, index) => {
        ele.question = index;
        ele.section = ele.name.en;
      });
      data111 = res.data;
    });
    console.log("data111", data111);

    AdminService.httpGet(`/data-retrieval/survey/get-survey/${id}`).then(
      (res) => {
        // console.log('check->'  , res.survey[0].questionsList);
        // {
        //     id: 1,
        //     question: 'ukkj',
        //     section: 'Personal Details',
        //     type: 'Date',
        //     mandatoryquestion: 'Yes',
        // },

        res.survey[0].questionsList.forEach((ele) => {
          ele.question = ele.title;
          ele.mandatoryquestion = ele.mandatory == true ? "Yes" : "No";
          ele.section = ele.sectionDetails.name;
          //  console.log("getmanageSection" , getmanageSection);
          //  data111.forEach((item)=>{
          //      if(ele.section_id = item._id){
          //         // console.log("item" , item.name);
          //         ele.section = item.name
          //     }
          //  })
        });

        // console.log("qqqqqq" , res.survey[0].questionsList , section );
        setQuestionData(res.survey[0].questionsList);
      }
    );
  };

  // add question Api -----------------------------------
  const addquestion = () => {
    const optionArr = [];
    const options = [];
    var iuID = "";
    let slectedGridquestions = {
      rows: {
        count: 0,
        names: {},
      },
      columns: {
        count: 0,
        names: {},
      },
      questions: [],
    };
    console.log(
      "add-personName",
      format,
      finalMapingValue,
      finalSelectedOption
    );
    // return false

    let selectedFinal = []
    if (format == "Single Choice" || format == "Multi Choice") {
      // console.log(finalMapingValue);
      Object.entries(finalMapingValue).forEach(function ([key, value], index) {
        let iuID = "";
        const iuname = value.indicatorUnit.split("|");
        iuData.forEach((ele1) => {
          if (ele1.name + " " == iuname[0]) {
            iuID = ele1._id;
          }
        });
        let optionObj = { iu: iuID, ius_id: value.subgroup, option: key };
        optionArr.push(optionObj);
      });

      optionArr.forEach((ele) => {
        finalSelectedOption.forEach((item) => {
          if (ele.option == item.name || ele.option == item.name.en) {
            ele.option_text = item._id;
          }
        });
      });
      // console.log(optionArr);
    } else if (format == "Grid") {
      for (const tm in gridQuestions) {
        if (gridQuestions[tm]['type'] != '') {
          let type = gridQuestions[tm]['type']
          let cr = String(tm).split('')
          if (type == "Textbox") {
            type = 'text'
            let obj = {
              "column": Number(cr[1]) + 1,
              "row": Number(cr[0]) + 1,
              "type": type,
              "column_name": "A",
              "row_name": "V",
              "options": [
                {
                  "ius_id": gridQuestions[tm]['subgroup']._id,
                  "option_text": ""
                }
              ]
            }
            selectedFinal.push(obj)
          }
          else if (type == "Numeric") {
            type = 'numeric'
            let obj = {
              "column": Number(cr[1]) + 1,
              "row": Number(cr[0]) + 1,
              "type": "numeric",
              "column_name": "B",
              "row_name": "V",
              "default_numeric_value": gridQuestions[tm]['numeric'].default,
              "maximum_numeric_value": gridQuestions[tm]['numeric'].maximum,
              "minimum_numeric_value": gridQuestions[tm]['numeric'].minimum,
              "decimal_value":gridQuestions[tm]['numeric'].decimal,
              "options": [
                {
                  "ius_id": gridQuestions[tm]['subgroup']._id,
                  "option_text": ""
                }
              ],
              "aggregate": gridQuestions[tm].aggregate ?? true
            }
            selectedFinal.push(obj)
          }
          else if (type == 'Single Choice') {
            type = 'single_select'
            let obj = {
              "column": Number(cr[1]) + 1,
              "row": Number(cr[0]) + 1,
              "column_name": "B",
              "row_name": "W",
              "type": "single_select",
              "options": []
            }
            selectedFinal.push(obj)
          }

        }
      }

      // console.log("selectedFinal:: ", selectedFinal)

      // console.log("gridQuestions:: ", gridQuestions);
      slectedGridquestions.questions = gridQuestions;
      slectedGridquestions.rows.count = row;
      slectedGridquestions.columns.count = column;
      // let temp_cols = {};
      // console.log("gridQuestions:: ", gridColumns)
      // for(let col in gridColumns) {
      //   temp_cols[col] = {'en':gridColumns[col],'pt':"",'tet':""};
      // }
      // // slectedGridquestions.columns.names = gridColumns;
      // slectedGridquestions.columns.names = temp_cols;

      let temp_cols = {};
      console.log("gridQuestions:: ", gridColumns);
      for (let col in gridColumns) {
          if (typeof gridColumns[col] === 'string') {
              temp_cols[col] = {
                  'en': gridColumns[col],
                  'pt': "",
                  'tet': ""
              };
          } else {
              temp_cols[col] = gridColumns[col];
          }
      }
      slectedGridquestions.columns.names = temp_cols;


      let temp_rows = {};
      for(let row in gridRows) {
        // console.log(row,"Sudhanshu");
        // temp_rows[row] = {'en':gridRows[row],'pt':"",'tet':""};

          if (typeof gridRows[row] === 'string') {
            temp_rows[row] = {
                'en': gridRows[row],
                'pt': "",
                'tet': ""
            };
        } else {
          temp_rows[row] = gridRows[row];
        }
      }

      slectedGridquestions.rows.names = temp_rows;
      // console.log("Sudhanshu", slectedGridquestions);
      // for (let key in gridRows) {
      //   let value = gridRows[key];
      //   slectedGridquestions.rows.names.en[key] = ""+value;
      //   slectedGridquestions.rows.names.pt[key] = "#" + value;
      //   slectedGridquestions.rows.names.tet[key] = "#" + value;
      // }

      // for (let key in gridColumns) {
      //   let value = gridColumns[key];
      //   slectedGridquestions.columns.names.en[key] = ""+value;
      //   slectedGridquestions.columns.names.pt[key] = "#" + value;
      //   slectedGridquestions.columns.names.tet[key] = "#" + value;
      // }

      console.log("slectedGridquestions:: ", slectedGridquestions);
      // if(slectedGridquestions.length){
      //     JSON.stringify(slectedGridquestions)
      // }
      // return
      let finalGrid = [];
      selectedFinal.forEach(e => {
        // let obj = Object.values(grid2['columns'])
        // console.log(e, e.column, grid2.columns, grid2.columns.names[e.column])
        e.column_name = slectedGridquestions.columns.names[e.column].en
        e.row_name = slectedGridquestions.rows.names[e.row].en
      });
    } else {
      options.push({
        option_text: "",
        ius_id: subgroupid?._id,
      });

      const iuname = indicatorUnit.split("|");
      // console.log("data---" ,iuData , iuname , indicatorUnit );
      iuData.forEach((ele) => {
        if (ele.name + " " == iuname[0]) {
          iuID = ele._id;
        }
      });
    }
    // console.log("dat11111", optionArr, format);

    // return false

    let temp_format = format;
    let imageFormat = personName;
    let fileSizeValueGet;
    if (temp_format === "Image") {
      fileSizeValueGet = sizeInImage;
    } else if (temp_format === "Audio") {
      fileSizeValueGet = sizeInAudio;
    } else {
      fileSizeValueGet = sizeInVideo;
    }
    // console.log(fileSizeValueGet, "fileSizeValueGet");
    let filetype = {
      file_format: imageFormat,
      file_size: fileSizeValueGet,
      hint: hint,
    };
    // console.log(filetype, "filetype hints");
    if (temp_format == "Image") {
      temp_format = "image";
    }
    if (temp_format == "Document") {
      temp_format = "document";
    }
    if (temp_format == "Single Choice") {
      temp_format = "single_select";
    }
    if (temp_format == "Multi Choice") {
      temp_format = "multi_select";
    }
    if (temp_format == "Video") {
      temp_format = "Video";
    }
    if (temp_format == "Audio") {
      temp_format = "Audio";
    }
    // return false

    let obj = {
      id: "",
      section_id: section._id,
      title: qustion_text,
      ques_type: temp_format,
      mandatory: mandatory,
      aggregate: aggregate,
      file: "",
      grid: JSON.stringify(slectedGridquestions),
      grid_org: JSON.stringify(selectedFinal),
      dependent_ques_id: "",
      dependent_option_id: "",
      iu:
        temp_format == "single_select" || temp_format == "multi_select" ? "" : iuID,
      ius_id:
        temp_format == "single_select" ||
          temp_format == "multi_select" ||
          temp_format.toLowerCase() == "grid"
          ? ""
          : subgroupid?._id,
      time_period_format: dateFormat,
      file_format: imageFormat,
      file_size: sizeInImage,
      file_hint: hint,
      default_numeric_value: defaultValue,
      maximum_numeric_value: maxvalue,
      minimum_numeric_value: miniValue,
      decimal_value: decimal,
      options_list:
        temp_format == "single_select" || temp_format == "multi_select"
          ? finalSelectedOption
          : "",
      row: 1,
      column: 1,
      options:
        temp_format == "single_select" || temp_format == "multi_select"
          ? JSON.stringify(optionArr)
          : JSON.stringify(options),
      survey_id: FormState.id,
      filetype: JSON.stringify(filetype),
      sub_section_id: subsection?._id,
    };

    // console.log("atattt00",JSON.parse(obj.grid),JSON.parse(obj.grid_org));
    // return false
    setDecimal("");
    setmaxvalue("")
    setminiValue("")
    if (questionEditMode) {
      AdminService.httpPut(`/data-import/question/update/${editModeId1}`, obj).then((res) => {
        // console.log("check->", props.editModeId._id);
        if (res.status == true) {
          toast.success(res.message);
          getQuestions(props.editModeId._id);
        } else {
          toast.success("something went wrong");
        }
      });
    } else {

      AdminService.httpPost("/data-import/question/add", obj).then((res) => {
        // console.log("check->", props);
        if (res.status == true) {
          if (props.isEdit) {
            getQuestions(props.editModeId._id);
          } else {
            getQuestions(FormState.id);
          }
          toast.success(res.message);
        } else {
          toast.success("something went wrong");
        }
      });
    }
  };
  //  Edit Quesition ------------------------------------

  const QuestionEdit = async (row) => {
    console.log(row);
    setQuestionEditMode(true);
    // QuestionEditId = row._id
    setmandatory(row.mandatory)
    setAggregate(row.aggregate)
    seteditmodeId1(row._id);
    const iusId = row.type != 'grid' ? row.options[0]?.ius_id : null;
    // console.log("Data-----12", row);

    // const iuname = indicatorUnit.split('|')
    //     console.log("data---" ,iuData , iuname , indicatorUnit );
    let iusdata = "";
    let iudata = {};
    let check = false;

    iuData.forEach((ele) => {
      if (check == false) {
        iudata = ele;
      }
      ele.subgroup.forEach((item) => {
        if (item._id == iusId) {
          // console.log("iusdata", item);
          iusdata = item.name;
          check = true;
        }
      });
      // if(ele._id==row.){

      //     iuID =  ele._id
      //    }
    });

    getmanageSection.forEach((ele) => {
      if (ele._id == row.section_id) {
        setSection(ele);
      }
    });

    let subsec = subSection.filter(x => x._id == row.sub_section_id)[0]
    subbSection(subsec)
    // let subsectest =  subSection.filter(x=> x._id = row.sub_section_id )
    console.log("This is sub sec--", subSection)
    console.log("This is sub sectest--", row)

    const finalIndicator = `${iudata.name} | ${iudata.unit}`;

    // console.log("data-23--setIndicatorUnit>", finalIndicator);
    setqustion_text(row.title.en ?? row.title);
    setFormat(row.type);
    setIndicatorUnit(finalIndicator);
    setSubgroupList(indicatorUnitData[finalIndicator]);
    setSubgroup(iusdata);
    setdefaultValue(row.default_numeric_value);
    setminiValue(row.minimum_numeric_value);
    setmaxvalue(row.maximum_numeric_value);
    setDecimal(row.decimal_value)
    setSubgroupid({ _id: iusId });

    if (row.type == 'date') {
      setDateFormat(row.time_period_format)
    }

    if (row.type == "image") {
      setFormat("Image");
      setHint(row.file.hint);
      setsizeInImage(row.file.file_size);

      setPersonName(row.file.file_format);
    }

    if (row.type == "single_select" || row.type == 'multi_select') {
      if (row.type == 'multi_select') {
        setFormat('Multi Choice')
      } else {
        setFormat("Single Choice");

      }
      const tempOption = [];
      const iusId = [];

      const tempOptiondata = await AdminService.httpGet(
        "/data-retrieval/option/get-option"
      );

      row.options.forEach((item) => {
        tempOptiondata.data.forEach((ele, i) => {
          if (item.option_text == ele._id) {
            ele.option = ele.name;
            ele.question = tempOption.push(ele);
          }
        });
        iusId.push(item);
      });


      let iusdata = [];
      let iudata = {};
      let check = false;
      const allfinalIndicators = [];

      console.log("datyattttttttt", iusId);
      iusId.forEach((id) => {
        iuData.forEach((ele) => {
          if (check == false) {
            iudata = ele;
          }
          ele.subgroup.forEach((item) => {
            if (item._id === id.ius_id) {
              iusdata.push({
                item: `${ele.name} | ${ele.unit}`,
                ius: item.name,
                optionId: id.option_text,
                ius_id: id.ius_id,
              });
              // console.log("iusdatamain", iusdata);
              check = true;
            }
          });
        });

        // allfinalIndicators.push(`${iudata.name} | ${iudata.unit}`)
      });

      // console.log("iusdatamain11", iusdata, tempOption);

      let indicatorValueMap = {};

      iusdata.forEach((ele) => {
        let option = tempOption.filter(x => x._id === ele.optionId)[0].name.en
        indicatorValueMap[option] = {
          indicatorUnit: ele.item,
          subgroup: ele.ius_id
        }
      });
      // console.log(indicatorValueMap);
      setmappingIndicatorValue(indicatorValueMap);
      setFinalMapvalue(indicatorValueMap);
      setFinalSelectedOption(tempOption);
      setSelectedOption(tempOption);

      // console.log("Data---12-->", indicatorValueMap, tempOption);
    }

    if (row.type == "document") {
      setFormat("Document");
      setsizeInImage(row.file.file_size);

      setPersonName(row.file.file_format);
    }

    if (row.type == 'grid') {
      setFormat('Grid')
      let gridData = JSON.parse(row.grid)
      console.log(gridData,"jdkjksdhkdsj");
      setRow(gridData.rows.count)
      setColumn(gridData.columns.count)
      setGridQuestions(gridData.questions)
      setGridColumns(gridData.columns.names)
      setGridRows(gridData.rows.names)

    }
  };

  // Delete Question ---------------------------------------------------------------
  const deleteEntry = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Question!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const dataToBeSend = {
          id: id,
        };

        AdminService.httpPost(
          "/data-import/question/delete-question",
          dataToBeSend
        )
          .then((res) => {
            if (res) {
              toast.success(res.message);
              getQuestions(props.editModeId._id);
            }
          })
          .catch((err) => {
            // this.setState({ loader: false })
            console.log(err);
          });
      }
    });
  };

  const copyQuestion = (id) => {
    const dataToBeSend = {
      id: id,
    };
    AdminService.httpPost("/data-import/question/copy-question", dataToBeSend)
      .then((res) => {
        if (res) {
          toast.success(res.message);
          getQuestions(props.editModeId._id);
        }
      })
      .catch((err) => {
        // this.setState({ loader: false })
        console.log(err);
      });
  };

  const checkQuestionfun = () => {
    setQuestionEditMode(false);
    setqustion_text("");
    setFormat("");
    setdefaultValue();
    setminiValue();
    setmaxvalue();
    setDecimal("")
    setFinalMapvalue({})
    setFinalSelectedOption([])
    setSubgroup();
    setIndicatorUnit();

    setSection("");
    subbSection('')
    get_mangage_section();

    // setSubgroupid('')
  };

  //   const callingFunc =()=>{
  //     // if(props.editModeId){

  //     //     getQuestions(props.editModeId._id)
  //     // }

  //   }

  //   https://itmcloud.org/gimtstagingapigateway//api/data-import/question/copy-question
  //   console.log("Data----->callll" , );
  // ======================================================= Multiselect Dropdown End

  const closePopUp = () => {
    props.closeFunc();
    getQuestions();
    // console.log("testing Load api");
    window.location.reload(true);
  };
  const rowSelectCritera = (selectedRows) => {
    return selectedOption.findIndex(x => x._id == selectedRows._id) == -1 ? false : true
  };
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSubSections, setSelectedSubSections] = useState([]);

  const [gridIndiSearchValue, setGridIndiSearchValue] = useState('')

  const selectManageSection = (data) => {
    // console.log(data.selectedRows);
    setSelectedSections(data.selectedRows);
  };
  const selectSubSection = ({ selectedRows }) => {
    setSelectedSubSections(selectedRows);
  };
  const deleteSelectedSections = () => {
    let id = [];
    selectedSections.forEach((row) => {
      id.push(row._id);
    });
    AdminService.httpPost(`/data-import/section/selected/delete`, {
      id: JSON.stringify(id),
    })
      .then((x) => {
        toast.success(x.message);
        setmanageSection("");
        get_mangage_section();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  // Mange Section Serch Functionality Start Here //

  const [mangeSearch, setMangeSearch] = useState("");
  const [serachMangeData, setSerachMangeData] = useState([]);

  const searchfilterTable = (event) => {
    const e = event.target.value;
    setMangeSearch(e);
    if (mangeSearch.length === "") {
      setSerachMangeData(managedata);
    } else {
      const filterResult = managedata.filter((item) => {
        return item.name.toLowerCase().includes(mangeSearch.toLowerCase());
      });
      setSerachMangeData(filterResult);
      // console.log(filterResult);
    }
  };

  // Mange Section Serch Functionality End Here //

  // Mange Option Section Serch Functionality Start Here //

  const [mangeOptionSearch, setMangeOptionSearch] = useState("");

  const [optionSearchValue, setOptionSearchValue] = useState('')



  // Mange Option Section Serch Functionality End Here //

  return (
    <>
      <div className="rightTab_Heading position-relative">
        <h2>{props.isEdit == true ? "Edit Form" : "Create Form"}</h2>

        <div className="rightTab_form">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  <div className="label-text tabsection1">
                    <div className={value == 0 ? "rtf_num" : "rtf_num"}>1</div>
                    <div
                      className="rtf_text"
                      onClick={() => {
                        get_mangage_section();
                      }}
                    >
                      Setup
                    </div>
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                disabled={
                  FormState.formName == "" ||
                  FormState.description == "" ||
                  FormState.time_period == "" ||
                  FormState.time_period_format == "" ||
                  FormState.source == ""
                }
                label={
                  <div className="label-text tabsection2">
                    <div className={value == 1 ? "rtf_num" : "rtf_num"}>2</div>
                    <div className="rtf_text">Question</div>
                  </div>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </div>
      </div>

      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={onFormSubmit}
        >
          {/* <Stack sx={{ width: '100%' }} className="h-100"> */}

          <div className="tabSection1">
            <div className="mf_form">
              <div className="mt_formTop">
                <div className="row align-items-center mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <label>Form Name</label>
                      <div className="mat-form-field-wrapper">
                        <TextValidator
                          name="formName"
                          autoComplete="off"
                          className="w-100"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={FormState.formName}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <label>Description</label>
                      <div className="mat-form-field-wrapper">
                        <TextValidator
                          autoComplete="off"
                          className="w-100"
                          name="description"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={FormState.description}
                          id="outlined-basic"
                          multiline
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-30 d-none">
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Time Period Format</label>
                      <div className="mat-form-field-wrapper">
                        {/* <Dropdown>
                                                <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY')}>YYYY</Dropdown.Item>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY.MM')}>YYYY.MM</Dropdown.Item>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY.MM.DD')}>YYYY.MM.DD</Dropdown.Item>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY - YYYY')}>YYYY - YYYY</Dropdown.Item>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY.MM - YYYY.MM')}>YYYY.MM - YYYY.MM</Dropdown.Item>
                                                    <Dropdown.Item  onClick={settimeFormat('YYYY.MM.DD - YYYY.MM.DD')}>YYYY.MM.DD - YYYY.MM.DD</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="time_period_format"
                          value={timeFormtValue}
                          placeholder=""
                          onChange={handleChange}
                        >
                          {timeFormat.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style datePicker">
                      <label>Time Period</label>
                      <div
                        className="mat-form-field-wrapper"
                        data-toggle="modal"
                        data-target="#timeperiod"
                      >
                        <TextValidator
                          autoComplete="off"
                          className="w-100"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="time_period"
                          value={FormState.time_period}
                          id="outlined-basic"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-30 d-none">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <label>Enter Source</label>
                      <div className="mat-form-field-wrapper">
                        <TextValidator
                          autoComplete="off"
                          className="w-100"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="source"
                          value={FormState.source}
                          id="outlined-basic"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-30 assign_user d-none">
                  <div className="col-md-6 text-right">
                    <Button
                      variant="contained"
                      data-toggle="modal"
                      data-target="#selectarea"
                    >
                      <img src="assets/lib/images/home/area-i.svg" />
                      Area
                      <img
                        className="checktick"
                        src="assets/lib/images/home/check_tick.svg"
                      />
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button
                      variant="contained"
                      onClick={getdata}
                      className="btnDisable"
                      data-toggle="modal"
                      data-target="#assignusers"
                    >
                      <img src="assets/lib/images/home/user-i.svg" />
                      Assign User
                      <img
                        className="checktick"
                        src="assets/lib/images/home/check_tick.svg"
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button
                  disabled={
                    FormState.formName == "" || FormState.description == ""
                      ? true
                      : false
                  }
                  onClick={setFormData}
                  variant="contained"
                >
                  {surveyEditMode}Next
                  <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
          <div className="h-100 tabSection2 d-none">
            <div className="questionTable">
              <div className="pb-3">
                <div className="row align-items-center">
                  {data1.length > 0 && (
                    <div className="col-md-6">
                      <div class="tables_list_data">
                        <img src="assets/lib/images/manage-form/dlt.svg" />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 ml-auto">
                    {data1.length > 0 && (
                      <div className="searchbar-icon">
                        <img
                          className="search-imgs"
                          src="assets/lib/images/manage-form/search.svg"
                        />
                        <input
                          type="search"
                          autoComplete="off"
                          className="inpuut"
                          placeholder="Search...test"
                          value={questionSearchText}
                          onChange={(e) => setQuestionSearchText(e.target.value)}
                        />
                      </div>
                    )}
                    <div
                      className="qt_Filter"
                      data-toggle="modal"
                      data-target="#addquestion"
                      onClick={() => {
                        checkQuestionfun();
                      }}
                    >
                      <img src="assets/lib/images/svg/add1.svg" />
                    </div>
                  </div>
                </div>
              </div>
              {data1.length > 0 && (
                <FixedHeaderStory
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  columns={columns1}
                  data={data1.filter(x => (x.title.en ?? x.title).toLowerCase().includes(questionSearchText.toLowerCase()))}
                  selectableRows
                  pagination
                  persistTableHead
                  noDataComponent="No Data"
                  className="dataTableScroll formMaster"
                />
              )}
            </div>
            <div className="row align-items-center btn_saveNext justify-content-center mx-0">
              <Button
                variant="contained"
                className="mr-3 cancelbtn"
                onClick={previousClick}
              >
                <img src="assets/lib/images/home/arrow_right.svg" /> Previous
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  closePopUp();
                }}
                variant="contained"
              >
                Save and Finish
                <img src="assets/lib/images/home/arrow_right.svg" />
              </Button>
            </div>
          </div>
          {/* </Stack> */}
        </ValidatorForm>
      </div>

      {/* ================================================= Datepicker Modal Start ==================================================  */}
      <div
        className="modal fade timePeriodPopup"
        id="timeperiod"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Select Time Period
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center mb-30" id="year">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Year</label>
                    <div className="mat-form-field-wrapper">
                      {/* <Dropdown>
                                                <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="time_period"
                        value={timeValue}
                        placeholder=""
                        onChange={handleChange}
                      >
                        {timeSelect.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30 d-none" id="yymm">
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Year</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>{year}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2020");
                            }}
                          >
                            2020
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2021");
                            }}
                          >
                            2021
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2022");
                            }}
                          >
                            2022
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2023");
                            }}
                          >
                            2023
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2024");
                            }}
                          >
                            2024
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2025");
                            }}
                          >
                            2025
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Month</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>{month}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("01");
                            }}
                          >
                            01
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("02");
                            }}
                          >
                            02
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("03");
                            }}
                          >
                            03
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("04");
                            }}
                          >
                            04
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("05");
                            }}
                          >
                            05
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("06");
                            }}
                          >
                            06
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("07");
                            }}
                          >
                            07
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("08");
                            }}
                          >
                            08
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("09");
                            }}
                          >
                            09
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("10");
                            }}
                          >
                            10
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("11");
                            }}
                          >
                            11
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("12");
                            }}
                          >
                            12
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30 d-none" id="yymmdd">
                <div className="col-md-4">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Year</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>{year}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2020");
                            }}
                          >
                            2020
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2021");
                            }}
                          >
                            2021
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2022");
                            }}
                          >
                            2022
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2023");
                            }}
                          >
                            2023
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2024");
                            }}
                          >
                            2024
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setData("2025");
                            }}
                          >
                            2025
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Month</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>{month}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("01");
                            }}
                          >
                            01
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("02");
                            }}
                          >
                            02
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("03");
                            }}
                          >
                            03
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("04");
                            }}
                          >
                            04
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("05");
                            }}
                          >
                            05
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("06");
                            }}
                          >
                            06
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("07");
                            }}
                          >
                            07
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("08");
                            }}
                          >
                            08
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("09");
                            }}
                          >
                            09
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("10");
                            }}
                          >
                            10
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("11");
                            }}
                          >
                            11
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setMonth("12");
                            }}
                          >
                            12
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label>Day</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>{day}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("01");
                            }}
                          >
                            01
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("02");
                            }}
                          >
                            02
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("03");
                            }}
                          >
                            03
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("04");
                            }}
                          >
                            04
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("05");
                            }}
                          >
                            05
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("06");
                            }}
                          >
                            06
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("07");
                            }}
                          >
                            07
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("08");
                            }}
                          >
                            08
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("09");
                            }}
                          >
                            09
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("10");
                            }}
                          >
                            10
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("11");
                            }}
                          >
                            11
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              setDay("12");
                            }}
                          >
                            12
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tpp_year d-none" id="fromto-yy">
                <h4>From</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>To</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tpp_yymm d-none" id="fromto-yymm">
                <h4>From</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Month</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{month}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>To</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Month</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{month}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tpp_yymmdd d-none" id="fromto-yymmdd">
                <h4>From</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Month</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{month}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Day</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{day}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>To</h4>
                <div className="row align-items-center mb-30">
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Year</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{year}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2020");
                              }}
                            >
                              2020
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2021");
                              }}
                            >
                              2021
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2022");
                              }}
                            >
                              2022
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2023");
                              }}
                            >
                              2023
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2024");
                              }}
                            >
                              2024
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setData("2025");
                              }}
                            >
                              2025
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Month</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{month}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setMonth("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Day</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{day}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("01");
                              }}
                            >
                              01
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("02");
                              }}
                            >
                              02
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("03");
                              }}
                            >
                              03
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("04");
                              }}
                            >
                              04
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("05");
                              }}
                            >
                              05
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("06");
                              }}
                            >
                              06
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("07");
                              }}
                            >
                              07
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("08");
                              }}
                            >
                              08
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("09");
                              }}
                            >
                              09
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("10");
                              }}
                            >
                              10
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("11");
                              }}
                            >
                              11
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDay("12");
                              }}
                            >
                              12
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button variant="contained" data-dismiss="modal">
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================== Datepicker Modal End ===================================================  */}
      {/* ===================================================== Copy Start ====================================================== */}
      <div
        className="modal fade timePeriodPopup selectArea copyModal-manage"
        id="copymodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <ValidatorForm
            className="main_react_form w-100"
            ref={form}
            onSubmit={submitCopyForm}
          >
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <h5 className="modal-title" id="exampleModalLabel">
                  Copy Form
                </h5>
                <button
                  type="button"
                  id="closeCopy"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row align-items-center ">
                  <div className="col-md-12 mb-4">
                    <div className="form-group mb-0 inputform_style">
                      <label> Source Form</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          value={copyForm.target}
                          style={{ opacity: 0.7, cursor: "not-allowed" }}
                          dissabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <label> Target Form</label>
                      <div className="mat-form-field-wrapper">
                        <TextValidator
                          className="w-100"
                          value={copyForm.copy}
                          onChange={(e) =>
                            setCopyForm({ ...copyForm, copy: e.target.value })
                          }
                          autoComplete="off"
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                  <Button variant="contained" type="sumit">
                    Ok <img src="assets/lib/images/home/arrow_right.svg" />
                  </Button>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </div>
      </div>
      {/* ===================================================== Copy End ====================================================== */}

      {/* =============================================== Add Question Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea addquestion"
        id="addquestion"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addquestionCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addquestionTitle">
                {`${questionEditMode ? 'Edit' : 'Add'} Question`}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body px-0 pb-0">
              <div className="add_q_form">
                {/*__________________ section start________________ */}
                <div className="row align-items-end mb-30">
                  <div className="col-md-8">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Section</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle value={section.name}>
                            {section.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="d_search">
                              <TextField
                                type="search"
                                placeholder="Search..."
                                value={searchSeaction}
                                onChange={(e) => {
                                  setseachSection(e.target.value);
                                }}
                              />
                              <img
                                class="search-imgs"
                                src="assets/lib/images/manage-form/search.svg"
                              />
                            </div>
                            {getmanageSection
                              .filter((value) =>
                                value.name.includes(searchSeaction)
                              )
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((ele) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        setSection(ele);
                                      }}
                                    >
                                      {ele.name}
                                    </Dropdown.Item>
                                  </>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="msBtn">
                      <Button
                        variant="contained"
                        className="mb-0 text-capitalize"
                        data-toggle="modal"
                        data-target="#managesection"
                      >
                        Manage Section
                      </Button>
                    </div>
                  </div>
                </div>
                {/*___________________ Section End___________________ */}
                {/*___________________ Subsection Start___________________ */}

                <div className="row align-items-end mb-30">
                  <div className="col-md-8">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Sub-section </label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown
                          className={
                            section._id == undefined ? "disableEvents" : ""
                          }
                        >
                          <Dropdown.Toggle>{subsection?.name}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="d_search">
                              <TextField
                                type="search"
                                placeholder="Search..."
                              />
                              <img
                                class="search-imgs"
                                src="assets/lib/images/manage-form/search.svg"
                              />
                            </div>
                            {subSection
                              .filter((x) => x.section_id == section._id)
                              .map((x) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        subbSection(x);
                                      }}
                                    >
                                      {x.name}
                                    </Dropdown.Item>
                                  </>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="msBtn">
                      <Button
                        disabled={section._id == undefined}
                        variant="contained"
                        className="mb-0 text-capitalize"
                        data-toggle="modal"
                        data-target="#managesubsection"
                      >
                        Manage Sub-Section
                      </Button>
                    </div>
                  </div>
                </div>
                {/*___________________ Subsection End___________________ */}
                <div className="row align-items-end mb-30">
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style">
                      <label>Question Text</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          id="outlined-basic"
                          value={qustion_text}
                          onChange={(e) => {
                            setqustion_text(e.target.value);
                          }}
                          multiline
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-end mb-30">
                  <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Type</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle value={format}>
                            {format}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("text");
                              }}
                            >
                              Textbox
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("time");
                              }}
                            >
                              Time
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("numeric");
                                setdefaultValue("");
                                setminiValue("");
                                setmaxvalue("");
                                setDecimal("")
                              }}
                            >
                              Numeric
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("date");
                                setDateFormat();
                              }}
                            >
                              Date
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Single Choice");
                                // setFinalSelectedOption([])
                              }}
                            >
                              Single Choice
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Multi Choice");
                              }}
                            >
                              Multi Choice
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Image");
                                setHint();
                                setsizeInImage();
                                setPersonName([]);
                              }}
                            >
                              Image
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Audio");
                                setSizeInAudio();
                                setPersonName([]);
                              }}
                            >
                              Audio
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Video");
                                setSizeInVideo();
                                setPersonName([]);
                              }}
                            >
                              Video
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Document");
                              }}
                            >
                              Document
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setFormat("Grid");
                              }}
                            >
                              Grid
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  {/* ========================================================== Data Start =================================================== */}
                  <div
                    className={
                      format == "date" ? "col-md-6" : "col-md-6 d-none"
                    }
                    id="date"
                  >
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Time Period Format</label>
                      <div className="mat-form-field-wrapper">
                        <Dropdown>
                          <Dropdown.Toggle>{dateFormat}</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY");
                              }}
                            >
                              YYYY
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY.MM");
                              }}
                            >
                              YYYY.MM
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY.MM.DD");
                              }}
                            >
                              YYYY.MM.DD
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY - YYYY");
                              }}
                            >
                              YYYY - YYYY
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY.MM - YYYY.MM");
                              }}
                            >
                              YYYY.MM - YYYY.MM
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                setDateFormat("YYYY.MM.DD - YYYY.MM.DD");
                              }}
                            >
                              YYYY.MM.DD - YYYY.MM.DD
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  {/* =========================================================== Data End ==================================================== */}
                  {/* ========================================================== Image Start =================================================== */}
                  <div
                    className={
                      format == "Image" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="image1"
                  >
                    <div className="form-group mb-0 inputform_style dropdown_format">
                      <label>Format</label>
                      <div className="mat-form-field-wrapper">
                        <FormControl>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            className="profile-select"
                            onChange={handleDropdown}
                            input={<OutlinedInput label="" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem
                                className="formatList"
                                key={name}
                                value={name}
                              >
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      format == "Image" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="image2"
                  >
                    <div className="form-group mb-0 inputform_style">
                      <label>Size(MB)</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          value={sizeInImage}
                          onChange={(e) => {
                            setsizeInImage(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* ========================================================== Image End =================================================== */}
                  {/*/////// Audio Dropdown Start Here //////*/}

                  <div
                    className={
                      format == "Audio" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="audio1"
                  >
                    <div className="form-group mb-0 inputform_style dropdown_format">
                      <label>Format</label>
                      <div className="mat-form-field-wrapper">
                        <FormControl>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            className="profile-select"
                            onChange={handleDropdown}
                            input={<OutlinedInput label="" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {AudioFormateType.map((name) => (
                              <MenuItem
                                className="formatList"
                                key={name}
                                value={name}
                              >
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      format == "Audio" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="audio2"
                  >
                    <div className="form-group mb-0 inputform_style">
                      <label>Size(MB)</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          value={sizeInAudio}
                          onChange={(e) => {
                            setSizeInAudio(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/*/////// Audio Dropdown End Here //////*/}
                  {/*/////// Video Dropdown Start Here //////*/}

                  <div
                    className={
                      format == "Video" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="video1"
                  >
                    <div className="form-group mb-0 inputform_style dropdown_format">
                      <label>Format</label>
                      <div className="mat-form-field-wrapper">
                        <FormControl>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            className="profile-select"
                            onChange={handleDropdown}
                            input={<OutlinedInput label="" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {VideoFormateType.map((name) => (
                              <MenuItem
                                className="formatList"
                                key={name}
                                value={name}
                              >
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      format == "Video" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="Video2"
                  >
                    <div className="form-group mb-0 inputform_style">
                      <label>Size(MB)</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          value={sizeInVideo}
                          onChange={(e) => {
                            setSizeInVideo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/*/////// Video Dropdown End Here //////*/}
                  {/* ========================================================== Document Start =================================================== */}
                  <div
                    className={
                      format == "Document" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="Document"
                  >
                    <div className="form-group mb-0 inputform_style dropdown_format">
                      <label>Format</label>
                      <div className="mat-form-field-wrapper">
                        <FormControl>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            className="profile-select"
                            onChange={handleDropdown}
                            input={<OutlinedInput label="" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {document.map((name) => (
                              <MenuItem
                                className="formatList"
                                key={name}
                                value={name}
                              >
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      format == "Document" ? "col-md-3" : "col-md-3 d-none"
                    }
                    id="Document2"
                  >
                    <div className="form-group mb-0 inputform_style">
                      <label>Size(MB)</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          value={sizeInImage}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              Math.ceil(Math.abs(e.target.value || 0)) || 0
                            );
                          }}
                          onChange={(e) => {
                            setsizeInImage(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* ========================================================== Document End =================================================== */}
                </div>
                {/* ========================================================== Grid Start =================================================== */}
                <div id="grid" className={format == "Grid" ? "" : "d-none"}>
                  <div className="row align-items-end mb-30">
                    <div className="col-md-6">
                      <div className="form-group mb-0 inputform_style fp_dropdown">
                        <label>Column</label>
                        <div className="mat-form-field-wrapper d-flex align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle>{column}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setColumn("1");
                                }}
                              >
                                1
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setColumn("2");
                                }}
                              >
                                2
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setColumn("3");
                                }}
                              >
                                3
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setColumn("4");
                                }}
                              >
                                4
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setColumn("5");
                                }}
                              >
                                5
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <img
                            className="arrowUp"
                            data-toggle="modal"
                            onClick={() => {
                              setTempGridNames(gridColumns);
                            }}
                            data-target="#columnheader"
                            src="assets/lib/images/home/arrow_upp.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0 inputform_style fp_dropdown">
                        <label>Row</label>
                        <div className="mat-form-field-wrapper d-flex align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle>{row}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                Object.keys(gridRows).map(r => {
                                  return <Dropdown.Item onClick={(e) => { setRow(r); }}>{r}</Dropdown.Item>
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                          <img
                            className="arrowUp"
                            data-toggle="modal"
                            onClick={() => {
                              setTempGridNames(gridRows);
                            }}
                            data-target="#rowheader"
                            src="assets/lib/images/home/arrow_upp.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-30">
                    <div className="col-md-12 areaTable">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th >#</th>
                            {Object.keys(gridColumns).map((c) => {
                              {console.log(gridColumns[c],"change this 1")}
                              return c <= column ? (
                                <th>{gridColumns[c]?.en?gridColumns[c]?.en:gridColumns[c]}</th>
                              ) : null;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                        {console.log(gridRows,"change this 1")}
                          {Object.keys(gridRows).map((r, ri) => {
                            return Number(r) <= Number(row) ? (
                              <tr>
                                <td>{gridRows[r]?.en?gridRows[r]?.en:gridRows[r]}</td>
                                {Object.keys(gridColumns).map((c, ci) => {
                                  return c <= column ? (
                                    <td>
                                      <img
                                        className={checkGridValidation("" + ri + ci) ? "validGrid" : ""}
                                        onClick={() => {
                                          setActiveGridQuestion(gridQuestions["" + ri + ci]);
                                          setActiveGrid("" + ri + ci);
                                          console.log(gridQuestions["" + ri + ci]);
                                        }}
                                        data-toggle="modal"
                                        data-target="#addgridoptions"
                                        src="assets/lib/images/home/arrow_upp.svg"
                                      />
                                    </td>
                                  ) : null;
                                })}
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* ========================================================== Grid End =================================================== */}

                {/* ========================================================== Image Start =================================================== */}
                <div
                  className={
                    format == "Image"
                      ? "row align-items-end mb-3 "
                      : "row align-items-end mb-3 d-none"
                  }
                  id="image"
                >
                  <div className="col-md-8">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Hint</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          id="outlined-basic"
                          value={hint}
                          onChange={(e) => {
                            setHint(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========================================================== Image End =================================================== */}

                {/* ====================================================== Single Choice Start =============================================== */}
                <div
                  id="singleChoice"
                  className={
                    format == "Single Choice" || format == "Multi Choice"
                      ? ""
                      : "d-none"
                  }
                >
                  <div className="row align-items-end mb-3">
                    <div className="col-md-8">
                      <div className="form-group mb-0 inputform_style fp_dropdown">
                        <label>Option</label>
                        <div className="mat-form-field-wrapper">
                          <TextField
                            id="outlined-basic"
                            value={finalSelectedOption.length}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="msBtn">
                        <Button
                          variant="contained"
                          className="mb-0 text-capitalize"
                          data-toggle="modal"
                          data-target="#manageoption"
                          onClick={get_mangage_option}
                        >
                          Manage Option
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="iusm_btn mb-30">
                    <div className="msBtn">
                      <Button
                        variant="contained"
                        className="mb-0 text-capitalize"
                        disabled={finalSelectedOption.length < 1}
                        onClick={() =>
                          setmappingIndicatorValue({
                            ...mappingIndicatorValue,
                            ...finalMapingValue,
                          })
                        }
                        data-toggle="modal"
                        data-target="#indicatorunit"
                      >
                        Indicator - Unit - Subgroup Mapping
                      </Button>
                      {checkMappingValidation() ? (
                        <img src="assets/lib/images/home/check_tick.svg" />
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* ======================================================= Single Choice End ================================================ */}

                {/* ======================================================== Number Start ================================================= */}
                <div
                  className={
                    format == "numeric"
                      ? "row align-items-end mb-30"
                      : "row align-items-end mb-30 d-none"
                  }
                  id="number"
                >
                  <div className="col-md-3">
                    <div className="form-group mb-0 inputform_style">
                      <label>Default Value</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={defaultValue}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              Math.ceil(Math.abs(e.target.value || 0)) || 0
                            );
                          }}
                          onChange={(e) => {
                            setdefaultValue(e.target.value);
                          }}
                        />
                        {defaultValue < miniValue ? (
                          <>Invalid default value</>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-0 inputform_style">
                      <label>Minimum Value</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={miniValue}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              Math.ceil(Math.abs(e.target.value || 0)) || 0
                            );
                          }}
                          onChange={(e) => {
                            setminiValue(e.target.value);
                            if (value <= 0) {
                              value = 0;
                            }
                          }}
                        />
                        {maxvalue != "" && maxvalue < miniValue ? (
                          <>Invalid minimum value</>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-0 inputform_style">
                      <label>Maximum Value</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          value={maxvalue}
                          InputProps={{ inputProps: { min: 0 } }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              Math.ceil(Math.abs(e.target.value || 0)) || 0
                            );
                          }}
                          onChange={(e) => {
                            setmaxvalue(e.target.value);
                          }}
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-0 inputform_style">
                      <label>Decimal Place</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          type="number"
                          value={decimal}
                          InputProps={{ inputProps: { min: 0 } }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              Math.ceil(Math.abs(e.target.value || 0)) || 0
                            );
                          }}
                          onChange={(e) => {
                            setDecimal(e.target.value);
                          }}
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* ======================================================== Number End ================================================= */}

                <div
                  className={
                    format == "Single Choice" ||
                      format == "Multi Choice" ||
                      format == "Grid"
                      ? "d-none"
                      : "indicatorUnit"
                  }
                >
                  <div className="col-md-12">
                    <div className="row align-items-end mb-0">
                      <div className="col-md-12">
                        <h4>Indicator - Unit - Subgroup Mapping</h4>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style fp_dropdown">
                          <label>Indicator | Unit</label>
                          <div className="mat-form-field-wrapper">
                            <Dropdown>
                              <Dropdown.Toggle value={indicatorUnit}>
                                {indicatorUnit}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div className="d_search">
                                  <TextField
                                    type="search"
                                    placeholder="Search..."
                                    value={indiUnitSearch}
                                    onChange={(e) => {
                                      SetIndiUnitSearch(e.target.value);
                                    }}
                                  />
                                  <img
                                    class="search-imgs"
                                    src="assets/lib/images/manage-form/search.svg"
                                  />
                                </div>
                                {/* <Dropdown.Item  onClick={(e)=>{setIndicatorUnit('Adolescent girls by category | Percent')}}>Adolescent girls by category | <span> Percent</span></Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setIndicatorUnit('Breastfeeding children aged 6-23 months receiving adequate diet | Percent')}}>Breastfeeding children aged 6-23 months receiving adequate diet | <span> Percent</span></Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setIndicatorUnit('YYYY.MM.DD')}}>Cases falling under Cyber Crimes/Information Technology Act | <span>Number</span></Dropdown.Item>                                                         */}
                                {indicatorUnitData != undefined
                                  ? Object.keys(indicatorUnitData)
                                    .filter((y) =>
                                      y
                                        .toLocaleLowerCase()
                                        .includes(
                                          indiUnitSearch.toLocaleLowerCase()
                                        )
                                    )
                                    .map((x) => {
                                      return (
                                        <>
                                          <Tooltip
                                            title={x}
                                            placement="right"
                                          >
                                            <Dropdown.Item
                                              onClick={(e) => {
                                                setIndicatorUnit(x);
                                                setSubgroupList(
                                                  indicatorUnitData[x]
                                                );
                                                setSubgroup();
                                              }}
                                            >
                                              {x}
                                            </Dropdown.Item>
                                          </Tooltip>
                                        </>
                                      );
                                    })
                                  : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style fp_dropdown">
                          <label>Subgroup </label>
                          <div className="mat-form-field-wrapper">
                            <Dropdown>
                              <Dropdown.Toggle>{subgroup2}</Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div className="d_search">
                                  <TextField
                                    type="search"
                                    placeholder="Search..."
                                    value={subgroupSearch}
                                    onChange={(e) => {
                                      SetSubgroupSearch(e.target.value);
                                    }}
                                  />
                                  <img
                                    class="search-imgs"
                                    src="assets/lib/images/manage-form/search.svg"
                                  />
                                </div>

                                {indicatorUnit != undefined
                                  ? subgroupList
                                    .filter((y) =>
                                      y.name
                                        .toLocaleLowerCase()
                                        .includes(subgroupSearch)
                                    )
                                    .map((x) => {
                                      return (
                                        <>
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              setSubgroup(x.name);
                                              setSubgroupid(x);
                                            }}
                                          >
                                            {x.name}
                                          </Dropdown.Item>
                                        </>
                                      );
                                    })
                                  : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-end mb-30">
                  <div
                    className={
                      "col-md-12 " +
                      (format == "numeric" ? "" : " hide-aggregate")
                    }
                  >
                    <div className="form-group mb-0 inputform_style labelCheckbox">
                      <FormControlLabel
                        onChange={(e) => {
                          setAggregate(e.target.checked);
                        }}
                        control={<Checkbox checked={aggregate == true} />}
                        label="Aggregate"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-0 inputform_style labelCheckbox">
                      {/* <input onChange={(e) => {
                          console.log(e.target.checked);
                          setmandatory(e.target.checked);
                        }} 
                        checked={mandatory} type="checkbox" /> */}
                      <FormControlLabel
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setmandatory(e.target.checked);
                        }}
                        checked={mandatory}
                        control={<Checkbox />}
                        label="Mandatory Question"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                  disabled={
                    ((decimal != "" && maxvalue != "" && maxvalue < miniValue) ||
                      defaultValue < miniValue) &&
                    format == "numeric"
                  }
                  onClick={addquestion}
                >{questionEditMode ? "Update" : "Add"} <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Add Question End =================================================== */}
      {/* ===================================================== Column Header Start ====================================================== */}
      <div
        className="modal fade timePeriodPopup selectArea copyModal-manage"
        id="columnheader"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Column Header
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center ">
                <div className="col-md-12">
                  {Object.keys(gridColumns).map((c) => {
                    return c <= column ? (
                      <div className="form-group mb-0 inputform_style">
                        <label>{c}</label>
                        <div className="mat-form-field-wrapper">
                          <TextField
                            onChange={(e) => {
                              setTempGridNames({
                                ...tempGridNames,
                                ...{ [c]: e.target.value },
                              });
                            }}
                            value={tempGridNames[c]?.en}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button
                  variant="contained"
                  data-dismiss="modal"
                  onClick={() => {
                    setGridColumns(tempGridNames);
                  }}
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================================== Column Header End ====================================================== */}
      {/* ===================================================== Row Header Start ====================================================== */}
      <div
        className="modal fade timePeriodPopup selectArea copyModal-manage"
        id="rowheader"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Row Header
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center ">
                <div className="col-md-12">
                  {Object.keys(gridRows).map((r) => {
                    return Number(r) <= Number(row) ? (
                      <div className="form-group mb-0 inputform_style">
                        <label>{r}</label>
                        <div className="mat-form-field-wrapper">
                          <TextField
                            onChange={(e) => {
                              setTempGridNames({
                                ...tempGridNames,
                                ...{ [r]: e.target.value },
                              });
                            }}
                            value={tempGridNames[r]?.en}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button
                  variant="contained"
                  data-dismiss="modal"
                  onClick={() => {
                    setGridRows(tempGridNames);
                  }}
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================================== Row Header End ====================================================== */}
      {/* =============================================== Add Grid Options Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea assignusers"
        id="addgridoptions"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Add Grid Options
              </h5>
              {/* <p>{activeGrid}</p> */}
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0">
              <div className="row align-items-end mb-30">
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <label onClick={() => console.log(activeGridQuestion)} >Type</label>
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle>
                          {activeGridQuestion?.type}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              changeActiveQuestion("type", "Textbox");
                            }}
                          >
                            Textbox
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              changeActiveQuestion("type", "Numeric");
                            }}
                          >
                            Numeric
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              changeActiveQuestion("type", "Single Choice");
                            }}
                          >
                            Single Choice
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  activeGridQuestion.type == "Numeric"
                    ? "row align-items-end mb-30"
                    : "row align-items-end mb-30 d-none"
                }
                id="number"
              >
                <div className="col-md-3">
                  <div className="form-group mb-0 inputform_style">
                    <label>Default Value</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        InputProps={{ inputProps: { min: 0, max: 199 } }}
                        value={activeGridQuestion.numeric.default}
                        onChange={(e) => {
                          changeNumericValue("default", e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-0 inputform_style">
                    <label>Minimum Value</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        InputProps={{ inputProps: { min: 0, max: 199 } }}
                        value={activeGridQuestion.numeric.minimum}
                        onChange={(e) => {
                          changeNumericValue("minimum", e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-0 inputform_style">
                    <label>Maximum Value</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        InputProps={{ inputProps: { min: 0, max: 199 } }}
                        value={activeGridQuestion.numeric.maximum}
                        onChange={(e) => {
                          changeNumericValue("maximum", e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-0 inputform_style">
                    <label>Decimal Place</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        InputProps={{ inputProps: { min: 0, max: 199 } }}
                        value={activeGridQuestion.numeric.decimal}
                        onChange={(e) => {
                          changeNumericValue("decimal", e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="singleChoice"
                className={
                  activeGridQuestion.type == "Single Choice" ? "" : "d-none"
                }
              >
                <div className="row align-items-end mb-3">
                  <div className="col-md-8">
                    <div className="form-group mb-0 inputform_style fp_dropdown">
                      <label>Option</label>
                      <div className="mat-form-field-wrapper">
                        <TextField
                          value={
                            activeGridQuestion.singleChoice.selectedOption
                              .length
                          }
                          id="outlined-basic"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="msBtn">
                      <Button
                        variant="contained"
                        className="mb-0 text-capitalize"
                        data-toggle="modal"
                        data-target="#manageoption2"
                        onClick={get_mangage_option}
                      >
                        Manage Option
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="iusm_btn mb-30">
                  <div className="msBtn">
                    <Button
                      variant="contained"
                      className="mb-0 text-capitalize"
                      disabled={
                        activeGridQuestion.singleChoice.selectedOption.length <
                        1
                      }
                      data-toggle="modal"
                      data-target="#indicatorunit2"
                    >
                      Indicator - Unit - Subgroup Mapping
                    </Button>
                    { }
                    <img src="assets/lib/images/home/check_tick.svg" />
                  </div>
                </div>
              </div>
              {/*  */}
              <div
                className={
                  activeGridQuestion.type == "Single Choice"
                    ? "d-none "
                    : "indicatorUnit"
                }
              >
                <div className="col-md-12">
                  <div className="row align-items-end mb-0">
                    <div className="col-md-12">
                      <h4>Indicator - Unit - Subgroup Mapping</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0 inputform_style fp_dropdown">
                        <label>Indicator | Unit</label>
                        <div className="mat-form-field-wrapper">
                          <Dropdown>
                            <Dropdown.Toggle>
                              {activeGridQuestion.indicatorUnit}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="d_search">
                                <TextField
                                  type="search"
                                  placeholder="Search..."
                                  onChange={(e) => setGridIndiSearchValue(e.target.value)}
                                  value={gridIndiSearchValue}
                                />
                                <img
                                  class="search-imgs"
                                  src="assets/lib/images/manage-form/search.svg"
                                />
                              </div>
                              {indicatorUnitData != undefined
                                ? Object.keys(indicatorUnitData)
                                  .filter((y) => y.toLocaleLowerCase().includes(gridIndiSearchValue.toLocaleLowerCase()))
                                  .map((x) => {
                                    return (
                                      <>
                                        <Tooltip title={x} placement="right">
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              changeActiveQuestion(
                                                "indicatorUnit",
                                                x
                                              );
                                              setGridSubgroups(
                                                indicatorUnitData[x]
                                              );
                                            }}
                                          >
                                            {x}
                                          </Dropdown.Item>
                                        </Tooltip>
                                      </>
                                    );
                                  })
                                : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-0 inputform_style fp_dropdown">
                        <label>Subgroup </label>
                        <div className="mat-form-field-wrapper">
                          <Dropdown>
                            <Dropdown.Toggle>
                              {activeGridQuestion.subgroup.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="d_search">
                                <TextField
                                  type="search"
                                  placeholder="Search..."
                                />
                                <img
                                  class="search-imgs"
                                  src="assets/lib/images/manage-form/search.svg"
                                />
                              </div>
                              {activeGridQuestion.indicatorUnit != ""
                                ? gridSubgroups.map((x) => {
                                  return (
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        changeActiveQuestion("subgroup", x);
                                      }}
                                    >
                                      {x.name}
                                    </Dropdown.Item>
                                  );
                                })
                                : null}
                              {/* <Dropdown.Item  onClick={(e)=>{setFormat('Total ')}}>Total </Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFormat('Rural')}}>Rural</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFormat('Urban')}}>Urban</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "col-md-12 " +
                  (activeGridQuestion.type == "Numeric" ? "" : " hide-aggregate")
                }
              >
                <div className="form-group mb-0 inputform_style labelCheckbox">
                  <FormControlLabel
                    onChange={(e) => {
                      changeActiveQuestion('aggregate', e.target.checked);
                    }}
                    control={<Checkbox checked={activeGridQuestion.aggregate != undefined ? activeGridQuestion.aggregate : true} />}
                    label="Aggregate"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  onClick={saveGridQuestion}
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                >
                  Add <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Add Grid Options End =================================================== */}
      {/* =============================================== Add  Options Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea assignusers indicatorunit"
        id="indicatorunit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 onClick={() => checkMappingValidation()} class="modal-title" id="assignusersTitle">
                Indicator - Unit - Subgroup Mapping
              </h5>
              <button
                type="button"
                onClick={clearInitialMapping}
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0">
              <div className="areaTable">
                <Table striped bordered hover className="indicatorunitTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="d-flex align-items-center indicatorunitTableoption">
                          <div className="d-flex align-items-center">
                            Option1
                          </div>
                          <div class="btn-group">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="assets/lib/images/home/sorting.svg" />
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                              >
                                Sort A - Z
                              </a>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                              >
                                Sort Z - A
                              </a>
                              <div className="areaSearch">
                                <input type="search" placeholder="Search..." />
                                <img
                                  className="search-imgs"
                                  src="assets/lib/images/manage-form/search.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th>Indicator | Unit</th>
                      <th>Subgroup</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* { console.log("finalSelectedOption", finalSelectedOption) } */}
                    {finalSelectedOption.map((x, i) => {
                      return (
                        <>
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              <TextField value={x.name.en ?? x.name} />
                            </td>
                            <td>
                              <div className="addquestion">
                                <div className="form-group mb-0 inputform_style dropdown_format">
                                  <div className="mat-form-field-wrapper">
                                    {/* {mappingIndicatorValue[x.name].indicatorUnit} */}
                                    <input onBlur={(e) => { Object.keys(indicatorUnitData).includes(e.target.value) ? console.log('') : changeMapIndicatorValue('', x.name.en ?? x.name) }} value={mappingIndicatorValue[x.name.en ?? x.name]?.indicatorUnit} autoComplete="off" onChange={(e) => { changeMapIndicatorValue(e.target.value, x.name.en ?? x.name) }} list="options" name="search" id="search"></input>
                                    <datalist id="options" className="indicatorUnitdrop">
                                      {Object.keys(indicatorUnitData).map(
                                        (indicator) => {
                                          return (
                                            <option
                                              onClick={(e) => { changeMapIndicatorValue(indicator, x.name.en ?? x.name); }}
                                            >
                                              {indicator}
                                            </option>
                                          );
                                        }
                                      )}
                                      {/* <MenuItem value={2}>{'indicator'}</MenuItem> */}
                                    </datalist>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="addquestion addquestiondrp">
                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                  <div className="form-group mb-0 inputform_style dropdown_format">
                                    <div className="mat-form-field-wrapper">
                                      <Select

                                        value={mappingIndicatorValue[x.name.en ?? x.name]?.subgroup}
                                        onChange={(e) => { changeMapSubgroupVAlue(e.target.value, x.name.en ?? x.name); }}
                                        className="indicatorUnitdrop"
                                      >
                                        {indicatorUnitData[mappingIndicatorValue[x.name.en ?? x.name]?.indicatorUnit]?.map((subgroup) => {
                                          return (
                                            <MenuItem value={subgroup._id}>{subgroup.name}</MenuItem>
                                          );
                                        })
                                        }
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  onClick={() => {
                    setFinalMapvalue({
                      ...mappingIndicatorValue,
                      ...finalMapingValue,
                    });
                  }}
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                >
                  Add <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade timePeriodPopup selectArea assignusers indicatorunit"
        id="indicatorunit2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Indicator - Unit - Subgroup Mapping 2
              </h5>
              <button
                type="button"
                onClick={clearInitialMapping}
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0">
              <div className="areaTable">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="d-flex align-items-center">Option</div>
                        <div class="btn-group">
                          <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src="assets/lib/images/home/sorting.svg" />
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a class="dropdown-item" href="javascript:void(0)">
                              Sort A - Z
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)">
                              Sort Z - A
                            </a>
                            <div className="areaSearch">
                              <input type="search" placeholder="Search..." />
                              <img
                                className="search-imgs"
                                src="assets/lib/images/manage-form/search.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </th>
                      <th>Indicator | Unit</th>
                      <th>Subgroup</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeGridQuestion.singleChoice.selectedOption.map(
                      (x, i) => {
                        return (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <TextField defaultValue={x.name} />
                              </td>
                              <td>
                                <div className="addquestion">
                                  <div className="form-group mb-0 inputform_style dropdown_format">
                                    <div className="mat-form-field-wrapper">
                                      {/* {mappingIndicatorValue[x.name].indicatorUnit} */}
                                      <Select
                                        value={
                                          activeGridQuestion.singleChoice
                                            .iusMap[x.name]?.indicatorUnit
                                        }
                                        onChange={(e) => {
                                          changeGridMapIndicator(
                                            e.target.value,
                                            x.name
                                          );
                                        }}
                                        className="indicatorUnitdrop"
                                      >
                                        {Object.keys(indicatorUnitData).map(
                                          (indicator) => {
                                            return (
                                              <MenuItem value={indicator}>
                                                {indicator}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                        {/* <MenuItem value={2}>{'indicator'}</MenuItem> */}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="addquestion addquestiondrp">
                                  <div className="form-group mb-0 inputform_style fp_dropdown">
                                    <div className="form-group mb-0 inputform_style dropdown_format">
                                      <div className="mat-form-field-wrapper">
                                        {console.log(
                                          "sssjj",
                                          mappingIndicatorValue[x.name]
                                            ?.subgroup
                                        )}
                                        <Select
                                          value={
                                            activeGridQuestion.singleChoice
                                              .iusMap[x.name]?.subgroup
                                          }
                                          onChange={(e) => {
                                            changeGridSubgroup(
                                              e.target.value,
                                              x.name
                                            );
                                          }}
                                          className="indicatorUnitdrop"
                                        >
                                          {activeGridQuestion.singleChoice
                                            .iusMap[x.name]?.indicatorUnit !=
                                            undefined &&
                                            activeGridQuestion.singleChoice
                                              .iusMap[x.name]?.indicatorUnit != ""
                                            ? indicatorUnitData[
                                              activeGridQuestion.singleChoice
                                                .iusMap[x.name]?.indicatorUnit
                                            ].map((subgroup) => {
                                              return (
                                                <MenuItem value={subgroup}>
                                                  {subgroup.name}
                                                </MenuItem>
                                              );
                                            })
                                            : null}
                                          {/* <MenuItem value={'subgroup.name'}>{'subgroup.name'}</MenuItem> */}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  onClick={() => {
                    setFinalMapvalue({
                      ...mappingIndicatorValue,
                      ...finalMapingValue,
                    });
                  }}
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                >
                  Add <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Add Grid Options End =================================================== */}
      {/* =============================================== Manage Section Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea"
        id="managesection"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Manage Section
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0 px-5">
              <div className="row align-items-end mb-30">
                <div className="col-md-8">
                  <div className="form-group mb-0 inputform_style">
                    <label>Section</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        id="outlined-basic"
                        value={manageSection}
                        onChange={(e) => {
                          setmanageSection(e.target.value);
                        }}
                        name="manageSection"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="msBtn btn_saveNext p-0">
                    <Button
                      variant="contained"
                      className="mb-0 text-capitalize"
                      onClick={() => addsection()}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-md-12">
                  <div className="questionTable ms_table">
                    <div className="pb-3">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div class="tables_list_data">
                            <button
                              disabled={selectedSections.length < 1}
                              onClick={() => deleteSelectedSections()}
                              className="delete_button_section"
                            >
                              <img src="assets/lib/images/manage-form/dlt.svg" />
                            </button>
                          </div>
                        </div>
                        <div className="col-md-6 ml-auto">
                          <div className="searchbar-icon mr-0">
                            <img
                              className="search-imgs"
                              src="assets/lib/images/manage-form/search.svg"
                            />
                            <input
                              type="search"
                              autoComplete="off"
                              className="inpuut"
                              placeholder="Search..."
                              value={mangeSearch}
                              onChange={searchfilterTable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FixedHeaderStory
                      fixedHeader
                      fixedHeaderScrollHeight="300px"
                      columns={managecolumns}
                      data={serachMangeData}
                      selectableRows
                      onSelectedRowsChange={selectManageSection}
                      pagination
                      persistTableHead
                      noDataComponent="No Data"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Manage Section End =================================================== */}

      {/* =============================================== Manage Sub Section Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea"
        id="managesubsection"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Manage Sub-Section
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0 px-5">
              <div className="row align-items-end mb-30">
                <div className="col-md-8">
                  <div className="form-group mb-0 inputform_style">
                    <label>Section</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        value={newSubSection}
                        onChange={(e) => setNewSubSection(e.target.value)}
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="msBtn btn_saveNext p-0">
                    <Button
                      variant="contained"
                      onClick={() => addSubsection()}
                      className="mb-0 text-capitalize"
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-md-12">
                  <div className="questionTable ms_table">
                    <div className="pb-3">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div class="tables_list_data">
                            <button
                              onClick={() => deleteMultiSubSection()}
                              className="delete_button_section"
                            >
                              <img src="assets/lib/images/manage-form/dlt.svg" />
                            </button>
                          </div>
                        </div>
                        <div className="col-md-6 ml-auto">
                          <div className="searchbar-icon mr-0">
                            <img
                              className="search-imgs"
                              src="assets/lib/images/manage-form/search.svg"
                            />
                            <input
                              type="search"
                              autoComplete="off"
                              className="inpuut"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FixedHeaderStory
                      fixedHeader
                      fixedHeaderScrollHeight="300px"
                      columns={managesubcolumns}
                      data={subSection.filter(
                        (x) => x.section_id == section._id
                      )}
                      selectableRows
                      onSelectedRowsChange={selectSubSection}
                      pagination
                      persistTableHead
                      noDataComponent="No Data"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  className="mb-0 text-capitalize"
                  data-dismiss="modal"
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Manage Section End =================================================== */}

      {/* =============================================== Manage Option Start ================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea"
        id="manageoption"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Manage Option
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0 px-5">
              <div className="row align-items-end mb-30">
                <div className="col-md-8">
                  <div className="form-group mb-0 inputform_style">
                    <label>Option</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        id="outlined-basic"
                        value={manageOption}
                        onChange={(e) => {
                          setmanageOption(e.target.value);
                        }}
                        name="manageOption"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="msBtn btn_saveNext p-0">
                    <Button
                      variant="contained"
                      className="mb-0 text-capitalize"
                      onClick={addoption}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-md-12">
                  <div className="questionTable ms_table">
                    <div className="pb-3">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div class="tables_list_data">
                            <img
                              onClick={deleteMultiOption}
                              src="assets/lib/images/manage-form/dlt.svg"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ml-auto">
                          <div className="searchbar-icon mr-0">
                            <img
                              className="search-imgs"
                              src="assets/lib/images/manage-form/search.svg"
                            />
                            <input
                              type="search"
                              autoComplete="off"
                              className="inpuut"
                              placeholder="Search..."
                              value={optionSearchValue}
                              onChange={(e) => setOptionSearchValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FixedHeaderStory
                      fixedHeader
                      fixedHeaderScrollHeight="300px"
                      columns={managecolumnsOption}
                      data={getmanageOption.filter(x => x.option.toLowerCase().includes(optionSearchValue.toLowerCase()))}
                      onSelectedRowsChange={selectManageOption}
                      selectableRows
                      // selectableRowSelected={rowSelectCritera}
                      pagination
                      persistTableHead
                      noDataComponent="No Data"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  className="mb-0 text-capitalize"
                  onClick={confirmSelectedOption}
                  data-dismiss="modal"
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Manage Section End =================================================== */}
      {/* ================================================Manage Option 2 Start================================================== */}
      <div
        class="modal fade timePeriodPopup selectArea"
        id="manageoption2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="assignusersCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="assignusersTitle">
                Manage Option
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-0 px-5">
              <div className="row align-items-end mb-30">
                <div className="col-md-8">
                  <div className="form-group mb-0 inputform_style">
                    <label>Option</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        id="outlined-basic"
                        value={manageOption}
                        onChange={(e) => {
                          setmanageOption(e.target.value);
                        }}
                        name="manageOption"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="msBtn btn_saveNext p-0">
                    <Button
                      variant="contained"
                      className="mb-0 text-capitalize"
                      onClick={addoption}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-md-12">
                  <div className="questionTable ms_table">
                    <div className="pb-3">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div class="tables_list_data">
                            <img
                              onClick={deleteMultiOption}
                              src="assets/lib/images/manage-form/dlt.svg"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ml-auto">
                          <div className="searchbar-icon mr-0">
                            <img
                              className="search-imgs"
                              src="assets/lib/images/manage-form/search.svg"
                            />
                            <input
                              type="search"
                              autoComplete="off"
                              className="inpuut"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FixedHeaderStory
                      fixedHeader
                      fixedHeaderScrollHeight="300px"
                      columns={managecolumnsOption}
                      data={getmanageOption}
                      onSelectedRowsChange={changeSelectedOption}
                      selectableRows
                      // selectableRowSelected={rowSelectCritera}
                      pagination
                      noDataComponent="No Data"
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">
                <Button
                  variant="contained"
                  className="mb-0 text-capitalize"
                  onClick={confirmGridSelectedOption}
                  data-dismiss="modal"
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================================================Manage Option 2 End=================================================== */}
      {/* ===================================================== Edit Section Start ====================================================== */}
      <div
        className="modal fade timePeriodPopup selectArea copyModal-manage"
        id="editsection"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Section
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center ">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <label>Section</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        onChange={(e) => {
                          setActiveOption({
                            ...activeOption,
                            ...{ name: e.target.value },
                          });
                        }}
                        value={activeOption.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button
                  variant="contained"
                  data-dismiss="modal"
                  onClick={updateOption}
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================================== Edit Section End ====================================================== */}
      {/* ===================================================== Edit subSection Start ====================================================== */}
      <div
        className="modal fade timePeriodPopup selectArea copyModal-manage"
        id="editsubsection"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Section
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center ">
                <div className="col-md-12">
                  <div className="form-group mb-0 inputform_style">
                    <label>Section</label>
                    <div className="mat-form-field-wrapper">
                      <TextField
                        onChange={(e) => {
                          setActiveSubSection({
                            ...activeSubSection,
                            ...{ name: e.target.value },
                          });
                        }}
                        value={activeSubSection.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div className="row align-items-center btn_saveNext justify-content-center mx-0">
                <Button
                  variant="contained"
                  data-dismiss="modal"
                  onClick={updateSubSection}
                >
                  Ok <img src="assets/lib/images/home/arrow_right.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================================== Edit subSection End ====================================================== */}
    </>
  );
};

export default FormMasterModal;
