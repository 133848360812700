import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
// import AddAreaModal from '../../Modals/addArea.modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Sonnet from '../../components/Sonnet';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import ProfileModal from '../../../Common/components/modal/profile.modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Datasummarypopup from "./modal/data-summary";
import Dataentrypopup from "./modal/dataentry";
import AddCalculation from "./modal/add-calculation";
import ManageFormModal from '../../Modals/manageForm.modal';
import ViewDataentry from "./modal/dataentry-view";
import ViewDatavalidation from "./modal/datavalidation-view";
import ViewDataaggregate from "./modal/dataaggregate-view";
import PendingView from "./modal/pending-view";
import InProgressView from "./modal/inProgressView";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NewModal from "../../Modals/new-modal";
import AlertModal from "./modal/AlertModal";

const manageFormTemplate = function () {
  const openCloseModal = async () => {

    this.setState({ scrollableModal: false })

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let dataToSend = [];

    dataToSend = {

      "id": this.state.id,
      "registrationdate": this.state.date_of_registration,
      "approvaldate": this.state.approvaldate,
      "role": this.state.role,
      "location": this.state.location,

    }


    console.log("test done by itm", dataToSend)



  }


  const closeModalClose = async () => {


    this.setState({ disaaprovest: false })


  }

  const closeModalClosePublis = async () => {


    this.setState({ publishModal: false })


  }

  const closeDeleteModal = async () => {


    this.setState({ deleteApprovedData: false })


  }


  const DataTableMain = () => {
    // console.log("data-->" ,this.state.openTab ,  this.columnsPending , this.columnsApproved   ,this.columnsRejected);


    return (
      <>

        <AlertModal modalStatus={this.state.age} />
        <DataTable
          columns={this.columnsPending}
          data={this.state.filteredData}
          direction="auto"
          fixedHeaderScrollHeight="500px"
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          style={{ display: "flex" }}
          // selectableRows
          persistTableHead
          selectableRows={false}
          noDataComponent="No Data"
          className="managePlan newManageplan"
        />
      </>
    )

  }

  //  console.log('pendingcount',this.state.tbStatus?.[0]?.count)
  return (
    <>


      <div className="main actor_page manageFormPage" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection area_margin' id="areaSection">

            <subHeaderComponentMemo.default onSearch={this.onSearch} searchText={this.state.searchText} nRows={this.state.filteredData?.length} />
            <div className="facility_dropdown manageFromDrop">
              <div className="form-group mb-0 inputform_style">
                <div className="mat-form-field-wrapper">
                  <label>Select Form</label>
                  <Select
                    value={this.state.age}
                    onChange={(e) => { this.selectForm(e) }}
                    displayEmpty
                    inputProps={{}}
                  >
                    {this.state.formList.map((item, index) => (

                      <MenuItem
                        key={index}
                        value={item}
                      >
                        {item.name?.en }
                      </MenuItem>

                    ))}


                  </Select>
                </div>
              </div>
            </div>
            <div className="tab_box ma_tables zzzzz">
              <div className="tabsLists">

                <div className="tl_lists">
                  <ToggleButtonGroup
                    color="primary"
                    value={this.state.alignment}
                    exclusive
                    onChange={this.changeTab}
                    aria-label="Platform">
                    {[1, 4, 7].includes(this.role) && <ToggleButton value="Pending">Pending <span className="tlList_number">{this.state.tbStatus?.[0]?.count}</span></ToggleButton>}
                    {[1, 4, 7].includes(this.role) && <ToggleButton value="In-Progress">In-Progress <span className="tlList_number">{this.state.tbStatus?.[1]?.count}</span></ToggleButton>}
                    {[1, 4, 7].includes(this.role) && <ToggleButton value="Completed">Completed <span className="tlList_number">{this.state.tbStatus?.[2]?.count}</span></ToggleButton>}
                    {[1, 7].includes(this.role) && <ToggleButton value="Validated">Validated <span className="tlList_number">{this.state.tbStatus?.[3]?.count}</span></ToggleButton>}
                    {[1, 4, 7].includes(this.role) && <ToggleButton value="Calculated">Calculated <span className="tlList_number">{this.state.tbStatus?.[4]?.count}</span></ToggleButton>}
                    {[1, 7].includes(this.role) && <ToggleButton value="Cancelled">Cancelled <span className="tlList_number">{this.state.tbStatus?.[5]?.count}</span></ToggleButton>}
                  </ToggleButtonGroup>
                </div>
              </div>
              <div className="manageActor">
                <DataTableMain />
              </div>

              <NewModal />

            </div>



          </section>
        </div>

      </div>

      <div className="modal fade area-user" id="area-user">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-radius-0">
            <button type="button" className="close position-absolute" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <img src="assets/lib/images/cross.svg" alt="" />
              </span>
            </button>
            <div className="modal-body p-0">

              <div className="table-scroll">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log('this.state.assignedAreaToUserData',this.state.assignedAreaToUserData)} */}
                    {
                      Object.keys(this.state.assignedAreaToUserData)
                        .map((area) => {
                          return (
                            <>
                              <tr>
                                <td> {area}</td>
                                <td> {this.state.assignedAreaToUserData[area]} </td>
                              </tr>
                            </>
                          )

                        })
                    }
                    {/* <tr>
                      <td>Delhi</td>
                      <td>Admin</td>
                    </tr>
                    <tr>
                      <td>Delhi</td>
                      <td>Admin</td>
                    </tr>
                    <tr>
                      <td>Delhi</td>
                      <td>Admin</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form manageFormModal width350' : 'gallery-sidebar add-area-modal add-form manageFormModal width0'}>

        {
          this.state.formName != '' && [1, 7].includes(this.role) ?
            <div id="openDataModal" onClick={(e) => this.openAddModal(e)} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className="gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
            </div>
            : ''
        }
        <div className="rightTab_step">

          {/* <div class="right-tab-heading are_head">
              <h2 class="form-heading">
                <span class="form-heading_he">
                  <span class="ddf">
                    {this.state.isEdit==true ? 'Edit Form': 'Create Form'}
                  </span>
                </span>
              </h2>
            </div> */}
          {this.state.showFormModal &&
            <ManageFormModal
              isEdit={this.state.isEdit}
              formId={this.state.formId}
              disasterList={this.state.disasterList}
              areaData={this.state.areaData}
              mainViewData={this.state.mainViewData}
              editModeData={this.state.editModeData}
              // copyForm = {this.state.copyForm}
              // saveCopyForm = {this.createCopy}
              activeForm={this.state.formName}
              users={this.state.dataEntryUsers}
              approvers={this.state.dataApproveUser}
              surveyId={this.state.age._id}
              userRole={this.role}
              closeFormModal={this.closeForm}

            />
          }
        </div>



      </div>

      {/* {console.log('thiss',this.state.getOptionsData)} */}

      <div className={this.state.modal ? 'overlay dblock' : 'overlay'} ></div>
      {/* <Dataentrypopup surveyRecord={this.state.dataEntryAnswers} dataEntryQuestion={this.state.dataEntryQuestion}  dataEnteryOptions={this.state.dataEnteryOptions} dataentryFormData={this.state.dataentryFormData} data = {this.state.dataEntryData}/> */}
      <Datasummarypopup dataSummary={this.state.summaryData} datasummaryFormData={this.state.datasummaryFormData} />
      <ViewDatavalidation loader={this.state.loader} getOptions={this.state.getOptionsData} row={this.state.rowForCompleted} openDataEntryModal={this.state.openDataEntryModal} name={this.state.dataValidatationName} alignment={this.state.alignment} data={this.state.dataForValidation} surveyId={this.state.activeSurvay} />
      <AddCalculation />
      <ViewDataentry selectedArea={this.state.areaForView} getData={this.openDataEntryModal} rowId={this.state.rowIdForPending} viewData={this.state.viewData} alignment={this.state.alignment} surveyRecord={this.state.dataEntryAnswers} dataEntryQuestion={this.state.dataEntryQuestion} dataEnteryOptions={this.state.dataEnteryOptions} dataentryFormData={this.state.dataentryFormData} data={this.state.dataEntryData} />

      <ViewDataaggregate />
      <PendingView mainViewData={this.state.mainViewData} alignment={this.state.alignment} />
      <InProgressView getData={this.openDataEntryModal} selectedArea={this.state.areaForView} dataEnteryOptions={this.state.dataEnteryOptions} viewMode={this.state.viewMode} dataEntryQuestion={this.state.dataEntryQuestion} data={this.state.dataEntryData} surveyRecord={this.state.dataEntryAnswers} dataentryFormData={this.state.dataentryFormData} />
      {/* <ProfileModal profileData={this.state.profileData}/>
      
      <Datasummarypopup />

      <Dataentrypopup />
      <AddCalculation />
      <ViewDataentry />
      <ViewDatavalidation />
      <ViewDataaggregate /> */}



      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>


    </>


  );
};

export default manageFormTemplate;