import { Component } from 'react'
import './data_approve.css';
import Button from 'react-bootstrap/Button';
import * as jsx from './data_approve.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import swal from 'sweetalert';
import "react-toastify/dist/ReactToastify.css";
import Dropdown from 'react-bootstrap/Dropdown';
import AdminService from '../../../services/admin.service';
import Tooltip from '@mui/material/Tooltip';
class DataApprove extends Component {



    arr = ["dxgf"]

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            setScrollableModal: false,
            // mainData: [],
            isEdit: false,
            scrollableModal: false,
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData: false,
            disscrollableModal: false,
            editModeId: null,
            modal: false,
            remarkdata: '',
            loader: false,
            pageNumber: 1,
            totalElements: 100,
            dtotalElements: 100,
            rowsPerPage: 100,
            dataLimit: 10,
            dataStatus: 2,
            bulkDelete: [],
            indicatorData: [],

            statusData: [
                { "label": "Pending", "value": 2 },
                { "label": "Approved", "value": 1 },
                { "label": "Disapproved", "value": 3 }
            ]
        }
        // this.globalState = stateUtilityInstance.default;



    }



    sendStatus = (e) => {

        this.setState({ dataStatus: e })
        this.getData(e);
    }



    componentDidMount() {
        this.getData(this.state.dataStatus);
        // this.getAllIndicators();
        // AdminService.checkToken();
    }


    render() {
        return jsx.default.bind(this)();
    }



    // update data limit===================//


    updateDataLimit = (e) => {


        this.setState({ dataLimit: e.target.value })
        this.getData(this.state.dataStatus);

    }





    getDataByStatus = (e) => {
        // console.log("data-----" + e.target.value)
        const val = e.target.value;
        this.setState({ dataStatus: val })
        // console.log("------aa--" + val)
        // console.log("data-----" + e.target.value)
        // console.log("state--" + this.state.dataStatus)

        this.getData(e.target.value);
    }







    // filter table and get data====================//

    onSearch = (val, enterPressed = false) => {
        this.setState({ searchText: val });
        if(enterPressed) {
            let d = this.state.data;
            let fd = null;
            if (val == '') {
                fd = d;
                this.setState({ filteredData: fd, totalElements: this.state.dtotalElements });
            } else {
                this.getData(this.state.dataStatus, 1, val.toLowerCase())
            }
        }
        if(val?.length == 0){
            this.getData(this.state.dataStatus, 1, val.toLowerCase())
        }
    }


    fnsortIUS = (rowA, rowB) => {
        const a = rowA?.disasterData?.[0]?.title?.en;
        const b = rowB?.indicator?.en;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };



    columns = [

        {
            name: 'Disaster',
            selector: (row, index) => row.disasterCode == null ? 'None' :  row.disasterData?.[0]?.title?.en,
            sortable: true,
            sortFunction: this.fnsortIUS
        },
        {
            name: 'Indicator | Unit | Subgroup',
            selector: (row, index) =>
                <> <p className='mb-0 grey_text'><span>{row?.iusData?.[0]?.name}</span> |
                    <span className='text-mute padding_text'>{row.iusData?.[0]?.unit}</span> |
                    <span className='text-mute padding_text'>{row.iusData?.[0]?.subgroup?.name}</span></p></>,
            sortable: true,
            sortFunction: this.fnsortIUS

        },

        {
            name: 'Area',
            selector: row => row.areaData?.[0]?.name,
            sortable: true,
            width: "170px"
        },
        {
            name: 'Time Period',
            selector: row => row.time_period?.start_time_period,
            sortable: true,
            width: "140px"
        },
        {
            name: 'Source',
            selector: row => <Tooltip title={row.source?.publisher} placement="top">
                <p>{row.source.publisher}</p>
            </Tooltip>,
            sortable: true,
            // width: "300px"
        },
        {
            name: 'Value',
            selector: row => row.new_value,
            sortable: true,
            width: "100px"
        },

        {
            name: 'Action',
            width: "150px",
            cell: row =>
                <>

                    {this.state.dataStatus == 1 &&

                        <>
                            {/* <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="pop-up-btn dropdown-item" onClick={(e) => this.deldata(row._id, row.value)} >
                                        <span _ngcontent-nud-c5="" title="Delete">
                                            Delete
                                        </span>
                                    </a>
                                     <a className="pop-up-btn dropdown-item" onClick={(e) => this.enableDis(row._id, row.status)} href="javascript:;">
                                    
                                        {row.status == 1 ? 'Hide' : 'Show'}
                                    
                                    </a> 

                                     <a class="dropdown-item text-bold" onClick={(e) => this.changeDataStatus(e, row._id, row.status )}  href="javascript:;" > {row.status==1? 'Hide':'Show'}</a> 
                                </div>
                            </div> */}
                            <Dropdown className='table-action'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Select
                                </Dropdown.Toggle>            
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => this.deldata(row._id, row.value)} href="javascript:;">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>














                        //      <span >
                        //         <button className="pop-up-btn" onClick={(e)=>this.deleteApproved(row._id,row.value)} >
                        //                 <span _ngcontent-nud-c5="" title="Approve">
                        //         <img _ngcontent-blx-c5 class="cursor-pointer" src="assets/lib/images/icon/del.svg" />
                        //         </span></button>
                        //       <label  className="switchone switch-checked" 
                        //             mattooltipposition="above" ng-reflect-position="above" 
                        //             ng-reflect-message="Status" aria-describedby="cdk-describedby-message-26" 
                        //             cdk-describedby-host="" 
                        //             style={{'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 
                        //             'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'}}>
                        //     <input onChange={(e) => this.enableDis( row._id,row.status)} 
                        //             data-size="mini" 
                        //             type="checkbox" 
                        //             defaultChecked={row.status? row.status == 1: false}/>
                        //     <div>
                        //     </div>
                        //     </label>  
                        //    </span>

                    }

                    {this.state.dataStatus == 2 &&

                        <>
                            {/* <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="pop-up-btn dropdown-item" onClick={(e) => this.approveData(row._id, row.value)} >
                                        <span _ngcontent-nud-c5="" title="Approve">
                                            Approve
                                        </span>
                                    </a>
                                    <a className="pop-up-btn dropdown-item" onClick={(e)=>{this.disapproveData(row._id,3)}} >
                                    <span _ngcontent-nud-c5="" title="Disapprove">
                                        Disapprove
                                    </span>
                                </a> 
                                    <a className="pop-up-btn dropdown-item" onClick={(e) => this.cancelModal(row._id)} >
                                        <span _ngcontent-nud-c5="" title="Disapprove">
                                            Disapprove
                                        </span>
                                    </a>
                                </div>
                            </div> */}

                            <Dropdown className='table-action'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Select
                                </Dropdown.Toggle>            
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => this.approveData(row._id, row.value)} href="javascript:;">Approve</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => this.cancelModal(row._id)} href="javascript:;">Disapprove</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }

                    {this.state.dataStatus != 1 && this.state.dataStatus != 2 &&
                        //                           <div>

                        //                              <button className="pop-up-btn" onClick={(e)=>this.openViewModal(row.remark)} >
                        //                             {/* <span _ngcontent-nud-c5=""  title="Approve"> */}
                        //                             <img  src="assets/lib/images/eye.svg" />
                        //                             {/* </span> */}
                        //                             </button>



                        // </div>

                        <>
                            {/* <div class="dropdown table-action">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    Select
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="pop-up-btn dropdown-item" onClick={(e) => this.openViewModal(row.remark)}  >
                                        <span _ngcontent-nud-c5="" title="Approve">
                                            View
                                        </span>
                                    </a>
                                </div>
                            </div> */}
                            <Dropdown className='table-action'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Select
                                </Dropdown.Toggle>            
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => this.openViewModal(row.remark)} href="javascript:;">View</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }





                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];






    openViewModal = async (e) => {

        // if(this.state.scrollableModal==false){
        //     this.setState({scrollableModal:true})
        //     this.setState({remarkdata:e})
        // }
        // else{

        //     this.setState({scrollableModal:false})
        // }
        console.log("pooja",e);
        swal({
            title: "Reason of Disapproval",
            // text: e,
            text: e=="" || e==null ? 'No data' : e,
            className: "disapproval_modal"
        })

    }

    cancelModal = async (e) => {

        if (this.state.disaaprovest == false) {
            this.setState({ disaaprovest: true })
            this.setState({ remarkdata: e })
        }
        else {

            this.setState({ disaaprovest: false })
        }

    }

    publishAll = (e) => {

        console.log("length++--" + this.state.filteredData)
        if (this.state.data == "") {
            toast.error("No record found to approve");
        } else {


            if (this.state.publishModal == false) {
                this.setState({ publishModal: true })
                this.setState({ remarkdata: e })
            }
            else {

                this.setState({ publishModal: false })
            }
        }

    }

    deleteApproved = (e, value) => {

        if (this.state.deleteApprovedData == false) {
            this.setState({ deleteApprovedData: true })
            this.setState({ remarkdata: e })
        }
        else {

            this.setState({ deleteApprovedData: false })
        }

    }

    setPage = (page) => {
        this.getData(this.state.dataStatus, page);
    }

    getData = async (e, page = this.state.pageNumber, keyword=this.state.searchText) => {
        if(keyword != '' && keyword.length < 3) {
            this.setState({ searchText: keyword });
            return;    
        }
        this.setState({ loader: true, searchText: keyword, pageNumber: page });
        // this.setState({ loader: true, searchText: "", pageNumber: page });
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        console.log("saved--" + savedItem)
        let userid = savedItem.id;
        const offset = (page - 1) * this.state.rowsPerPage;
        const dataToBeSend = {
            "size": this.state.rowsPerPage,
            "totalElements": 0,
            "totalPages": 0,
            "pageNumber": 0,
            "filterKeyWord": keyword,
            "dataStatus": "inactive",
            "userId": userid,
            "startOffset": offset,
            "endOffset": 0,
            "status": e == '' || e == null ? 2 : e,
        }

        AdminService.httpPost('/data-retrieval/data/get-approve-data', dataToBeSend).then((res) => {
            if (res) {
                let d = res.data
                let t = res.totalElements;
                // let t = res.totalElements;
                // console.log("T---" + t)

                // if (t != 0) {

                //     this.setState({ pageNumber: t })




                //     console.log("t--" + t)
                //     const dataToBeSend1 = {
                //         "size": t,
                //         "totalElements": 0,
                //         "totalPages": 0,
                //         "pageNumber": 0,
                //         "filterKeyWord": "",
                //         "dataStatus": "inactive",
                //         "userId": userid,
                //         "startOffset": 0,
                //         "endOffset": 0,
                //         "status": e == '' || e == null ? 2 : e,
                //     }

                //     AdminService.httpPost('/data-retrieval/data/get-approve-data', dataToBeSend1).then((res) => {
                //         if (res) {

                //             this.setState({ loader: false })
                //             // let t = res.totalElements;
                //             // this.setState({pageNumber:t})
                //             // console.log("t--"+t)
                //             let d = res.data;
                //             // d.forEach((ele  , index)=>{ele.index = index })
                //             console.log("res---" + d)
                //             this.setState({ filteredData: d, data: d, loader: false })
                //         }

                //     })

                // } else {
                // let d = res.data;
                d.forEach((ele, index) => { ele.index = index })
                if(keyword != '') {
                    this.setState({filteredData: d, loader: false, totalElements:t})    
                } else {
                    this.setState({filteredData: d, data:d, loader: false, totalElements:t, dtotalElements:t})
                }
                // this.setState({ filteredData: d, data: d, loader: false, totalElements: t })
                // this.setState({ filteredData: d, data: d, loader: false })
                // }


            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });






    }



    // getData = async (e) => {
    //     this.setState({loader:true})
    //     const savedItem = JSON.parse(localStorage.getItem("userInfo"));
    //         console.log("saved--"+savedItem)
    //         let userid = savedItem.id;

    //     const dataToBeSend = {
    //         "size":10,
    //         "totalElements":0,
    //         "totalPages":0,
    //         "pageNumber":0,
    //         "filterKeyWord":"",
    //         "dataStatus":"inactive",
    //         "userId":userid,
    //         "startOffset":0,
    //         "endOffset":0,
    //         "status":e=='' || e==null?2:e,
    //     }
    //     this.setState({ loader: true })

    //     AdminService.httpPost('/data-retrieval/data/get-approve-data', dataToBeSend).then((res)=>{
    //                 if(res){

    //                     this.setState({loader:false})
    //                     let t = res.totalElements;
    //                     console.log("T---"+t)

    //                     if(t!=0){

    //                         this.setState({pageNumber:t})




    //                         console.log("t--"+t)
    //                         const dataToBeSend1 = {
    //                             "size":t,
    //                             "totalElements":0,
    //                             "totalPages":0,
    //                             "pageNumber":0,
    //                             "filterKeyWord":"",
    //                             "dataStatus":"inactive",
    //                             "userId":userid,
    //                             "startOffset":0,
    //                             "endOffset":0,
    //                             "status":e=='' || e==null?2:e,
    //                         }

    //                         AdminService.httpPost('/data-retrieval/data/get-approve-data', dataToBeSend1).then((res)=>{
    //                             if(res){

    //                                 this.setState({loader:false})
    //                                 // let t = res.totalElements;
    //                                 // this.setState({pageNumber:t})
    //                                 // console.log("t--"+t)
    //                                 let d = res.data;
    //                                 // d.forEach((ele  , index)=>{ele.index = index })
    //                                 console.log("res---"+d)
    //                                 this.setState({filteredData: d, data:d})
    //                             }

    //                 })

    //                     }else{
    //                         let d = res.data;
    //                                 d.forEach((ele  , index)=>{ele.index = index })

    //                                 this.setState({filteredData: d, data:d})
    //                     }


    //                 }

    //     }).catch((err)=>{
    //         this.setState({loader:false})
    //         console.log(err)
    //     });






    // }
    //// all approve dataa api //////
    approveAllData = (e, value) => {
        console.log("What is this value?? -", value)
        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to approve all data?",
            // text: "Once Approved, you will not be able to disapprove this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        value: value,
                        status: 1

                    }

                    console.log("This is dataTobe sendd---", dataToBeSend)
                    AdminService.httpPost('/data-import/data/publish-all', dataToBeSend).then((res) => {
                        if (res) {
                            console.log("This is resssss after approve--", res)
                            this.setState({ loader: false })
                            // let d = res.data;
                            // this.setState({ filteredData: d, data: d })
                            // this.getData(this.state.dataStatus);
                            // this.getData(2);
                            if (res.status == true) {
                                console.log("This is publish-all----->", res)
                                toast.success("All data approved successfully");
                                // this.openAddModal();
                                this.getData(this.state.dataStatus)
                            }
                            else {
                                toast.error("Failed to approve data");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });



    }

    approveData = (e, id1, value) => {

        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to approve this data?",
            // text: "Once Approved, you will not be able to disapprove this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        id: id1,
                        status: 1

                    }


                    AdminService.httpPut('/data-import/data/approvedata/' + e, dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getData(this.state.dataStatus);
                            this.getData();
                            if (res.success === true) {
                                toast.success("Data approve successfully");
                                // this.openAddModal();
                                // this.getData();
                            }
                            else {
                                toast.error("Failed to approve data");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });





    }



    publishAllData = (e) => {
        this.setState({ loader: true })
        // const dataToBeSend = {
        //     value: value, 
        //     status: 1

        // }


        AdminService.httpPost('/data-import/data/publish-all', "").then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                this.setState({ filteredData: d, data: d })
                this.getData(this.state.dataStatus);
                if (res.status === true) {
                    toast.success("All data approved successfully");
                    // this.openAddModal();
                    // this.getData();
                }
                else {
                    toast.error("Failed to approve data");

                }
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }

    disapproveData = (e, value) => {

        // console.log("e--" + e)
        // console.log("value--" + value)


        this.setState({ loader: true })
        const dataToBeSend = {
            data_id: e,
            remark: value,
            status: 3

        }


        AdminService.httpPost('/data-import/data/reject-row-data-approve', dataToBeSend).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                this.setState({ filteredData: d, data: d })
                this.getData(this.state.dataStatus);
                if (res.status === 1) {
                    toast.success("Data disapproved successfully");
                    // this.openAddModal();
                    this.getData();
                }
                else {
                    toast.error("Failed to disapprove data");

                }
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }

    enableDis = (e, value) => {

        console.log("e--" + e)
        if (value == 0) {
            value = 1;
        } else {
            value = 0;
        }



        // this.setState({loader:true})
        const dataToBeSend = {

            show: value

        }


        AdminService.httpPut('/data-import/data/approvedata/' + e, dataToBeSend).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                // this.setState({filteredData: d, data:d})
                this.getData(this.state.dataStatus);

                if (res.message == 'Status updated successfully') {
                    toast.success("Status updated successfully");
                    // this.openAddModal();
                    // this.getData();
                }
                else {
                    toast.error("Failed to update status ");

                }
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }

    deldata = (e, value) => {

        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to delete?",
            // text: "Once deleted, you will not be able to recover this data-approve",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {

                        data_id: e

                    }


                    AdminService.httpPost('/data-import/data/del-data-approved', dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getData(this.state.dataStatus);

                            console.log("This is response from del-data-approved-->", res)
                            if (res.message == 'Data deleted') {
                                toast.success("Data deleted successfully");
                                // this.openAddModal();
                                // this.getData();
                            }
                            else {
                                toast.error("Failed to delete data");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });

    }

    // get all indicators===================//

    // getAllIndicators = async () => {


    //     AdminService.httpGet('/data-retrieval/ius/get-ius-maping').then((res)=>{
    //                 if(res){

    //                     let d = res.data;
    //                     console.log(d)

    //                     let bindData = [];
    //                     d.forEach(item => {

    //                         bindData.push({label: item.name, value: item._id})

    //                     });
    //                     this.setState({indicatorData: bindData})


    //                 }

    //     }).catch((err)=>{

    //         console.log(err)
    //     });






    // }


    // open add modal=====================//


    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({ editModeId: null })
        }

    }

    ///multiple delete

    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };


    deleteAllData = (e, value) => {

              
        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to Delete all data?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    
                    AdminService.httpPost('/data-import/data/deleteAll').then((res) => {
                        console.log("This is responsee of api--", res)
                        if (res) {
                            
                            this.setState({ loader: false })
                            if (res.status == 1) {
                                
                                toast.success("All data delete successfully");
                                this.getData(this.state.dataStatus == "approve") 
                            }
                            else {
                                toast.error("Failed to delete data");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });



    }




    //////////=========checkbox delete============/////////

    deleteBulkData = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
        })

        if (ids?.length > 0) {
            swal({
                title: "Are you sure you want to delete? ",
                // text: "Once deleted, you will not be able to recover this data-approve",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/data/delete_multiple_data', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res.data;


                                console.log(res)

                                if (res.success == 1) {
                                    toast.success("Data deleted successfully");
                                    this.getData(this.state.dataStatus);
                                }
                                else {
                                    toast.error("Failed to delete data");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }
        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }
    }



}







export default DataApprove;
