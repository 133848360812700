import React, { useEffect, useRef, useState } from "react";

import Resources from "../pages/manage-resource/resources.js";

import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const AggregationAddModal = (props) => {
  const form = useRef();

  const surveyRef = useRef();
  const disasterRef = useRef();
  const periodicityRef = useRef();
  

  // const referenceMRef = useRef();

  const frequencyRef = useRef();
  const urlRef = useRef();
  const imageRef = useRef();

  let imageBackground = "";
  let urlpath = "";

  const [subData, setSubGroup] = useState([]);
  const [areaTree, setAreaTree] = useState([]);
  const [image, setImage] = useState([]);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [typestate, setType] = useState("");
  const [innovationLogoBase64, setInnovationLogoBase64] = useState([]);
  // const [filename, setFilename] = useState([]);
  const [documentState, setDocumentState] = useState([]);
  const [referenceState, setReferenceState] = useState([]);
  const [iddata, setIdData] = useState([]);
  const [urlState, setURLState] = useState([]);
  const [urlDataState, setURLData] = useState([]);
  const [docDataState, setDocDataState] = useState([]);
  const [referenceDataState, setReferenceDataState] = useState();
  const [formError, setError] = useState({ error: false });
  const [documentdataSate, setDocumentDataState] = useState();
  const [referenceMetDataState, setReferenceMetDataState] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [state, setState] = useState({
    survey: [],
    frequency: [],
    disaster: [],
    disasterID: "",
    survey_id: "",
  });

  const [imagedatastate, setImageDataState] = useState();

  useEffect(() => {
    setAreaTree(props.areaTree);

    console.log("This is edited data--", state?.survey_id)
  });


  useEffect(() => {
    if (props.isEdit == true) {
      console.log("props in editing-", props.editData)
      getEditData(props.editData[0]);
    } else {
      setStartDate(null)
      setEndDate(null)
      setState({
        survey: [],
        frequency: [],
        disaster: [],
        disasterID: "",
        survey_id: ""
      })

      return false;
    }
  }, [props.isEdit, props.editData]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  const getEditData = (data) => {
    console.log('data', data)
    console.log("======>>>", data)
    if (data != null) {
      setIdData(data["_id"]);
      setTitle(data["title"].en);

      setType(data["type"]);
      // documentBackground = data["document"];
      imageBackground = data["image"];
      setImage(data["image"]);

      setURLData(data["url"]);
      urlpath = data["url"];

      if (data['type'] === 'Document') {
        setDocumentState(true)
        setDocDataState(data["document"]);
      }
      else if (data['type'] === 'Reference Document') {

        setReferenceState(true)
        setReferenceDataState(data["document"]);
      }




      setDescription(data["description"].en);
    }
  };

  // const handleChange = (e, input) => {
  //   let value = e.target.innerText;
  //   console.log('onChange func-', e.target.dataset)
  //   if (input === 'survey') {
  //     setState((prevState) => ({
  //       ...prevState,
  //       survey: value
  //     }));
  //   } else if (input === 'frequency') {
  //     setState((prevState) => ({
  //       ...prevState,
  //       frequency: value
  //     }))
  //   } else if (input === 'disaster') {
  //     setState((prevState) => ({
  //       ...prevState,
  //       disaster: value
  //     }))
  //   }
  // };

  const handleAutocompleteChange = (event, newValue) => {
    console.log("event--", event, "valueee--", newValue)
    let selectedValueName = "";
    let selectedValueID = "";
    if (newValue) {
      selectedValueName = newValue.name;
      selectedValueID = newValue.id;
    }
    setState((prevState) => ({
      ...prevState,
      survey: selectedValueName,
      survey_id: selectedValueID,
    }));
  };

  const handleAutocompleteBlur = () => {
    if (!state.survey) {
      // No value is selected, reset the state to the initial value
      setState((prevState) => ({
        ...prevState,
        survey: null,
        survey_id: null,
      }));
    }
  };



  const getSurvey = async (event) => {
    setTitle(surveyRef.current.value);

    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };

  const getFrequency = async (event) => {
    setDescription(frequencyRef.current.value);

    if (event.target.title.value == null || event.target.title.value == "") {
      setError({ error: false, msg: "This field is required", input: "title" });
      return;
    }
  };


  const onDataSubmit = (e) => {
    e.preventDefault();
    console.log("This is e of data submitting-", e)

    let dataToSend = [];

    //   {
    //     "form_type": "Damage observation form",
    //     "disaster": "Earthquake",
    //     "disasterID": "64671d4d47df16f2a70175b9",
    //     "periodicity": "Daily",
    //     "from_date": "2023-04-25T18:30:00.000Z",
    //     "to_date": "2023-04-28T18:30:00.000Z",
    //     "survey_id" : "643d189b03a7e5593109f344"
    // }

    dataToSend = {
      disasterID: state?.disasterID,
      periodicity: state?.frequency,
      survey_id: state?.survey_id,
      from_date: startDate,
      to_date: endDate,
    };
    props.newDataEntry(dataToSend);
    console.log("Data to send---", dataToSend)

  };

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  };
  // ====================================================== Form Validation Start

  // ====================================================== Form Validation End

  const frequency = ([
    {
      'label': "Daily"
    },
    // {
    //   'label': "Weekly"
    // },
    {
      'label': "Monthly"
    },
    {
      'label': "Annually"
    }
  ]);


  const handleSelectChangeSurvey = (event) => {
    setState((prevState) => ({
      ...prevState,
      survey_id: event.target.value,
    }));
  };
  const handleSelectChangeDisaster = (event) => {
    setState((prevState) => ({
      ...prevState,
      disasterID: event.target.value,
    }));
  };
  const handleSelectChangeFrequency = (event) => {
    setState((prevState) => ({
      ...prevState,
      frequency: event.target.value,
    }));
  };


  return (
    <>
      <div className="user-form">
        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={(e) => { onDataSubmit(e) }}
          id="addUserForm"
        >
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            {/* <form onSubmit={onDataSubmit} className="data_entry_form" id="dataEntryForm"> */}
            <div className="gallery-collections mt-0">
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Survey</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      {/* <TextField required autoComplete="off" id="indicator" type='text' name='indicator' value={state.indicator} 
                                                        onChange={(e) => handleInput(e, 'indicator', false, -1, null)} 
                                                        onBlur={()=>simpleValidator.current.showMessageFor('indicator')} />  */}
                      {/* <Autocomplete
                        id="free-solo-demo1"
                        value={state.survey}
                        filterSelectedOptions
                        // options={props.formList ? props.formList?.map((item) => item?.name?.en) : []}
                        options={props.formList ? props.formList.map((item) => ({ name: item.name.en, id: item._id })) : []}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={handleAutocompleteChange}
                        renderInput={(params) => <TextValidator className="w-100"
                          {...params} label=""
                          name="survey"
                          value={state.survey}
                          validators={['required']} errorMessages={['This field is required']}
                        />}


                      /> */}

                      <Select
                        ref={surveyRef}
                        id="psurvey"
                        name="psurvey"
                        value={state?.survey_id}
                        onChange={handleSelectChangeSurvey}
                        required
                      >
                        {props?.formList?.map((item) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name?.en}
                          </MenuItem>
                        ))}
                      </Select>



                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Disaster</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                    <Select
                        ref={disasterRef}
                        id="pdisaster"
                        name="pdisaster"
                        value={state?.disasterID}
                        onChange={handleSelectChangeDisaster}
                        required
                      >
                        {props?.disasterData?.map((item) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.title?.en}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <Autocomplete
                        id="free-solo-demo2"
                        value={state.disaster}
                        filterSelectedOptions
                        // onChange={(e) => { handleChange(e, 'disaster') }}
                        options={props.disasterData ? props.disasterData?.map((item) => item?.title?.en) : []}
                        getOptionLabel={(option) => option || ''}

                        renderInput={(params) => <TextValidator className="w-100"
                          {...params} label=""
                          name="disaster"
                          value={state.disaster}
                          validators={['required']} errorMessages={['This field is required']}
                        />}


                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Periodicity</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                    <Select
                        ref={periodicityRef}
                        id="pfrequency"
                        name="pfrequency"
                        value={state.frequency}
                        onChange={handleSelectChangeFrequency}
                        required
                      >
                        {frequency?.map((item, index) => (
                          <MenuItem key={item?.label+'#'+index} value={item?.label}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <Autocomplete
                        id="free-solo-demo2"
                        value={state.frequency}
                        filterSelectedOptions
                        // onChange={(e) => { handleChange(e, 'frequency') }}
                        options={frequency ? frequency?.map((item) => item?.label) : []}
                        getOptionLabel={(option) => option || ''}

                        renderInput={(params) => <TextValidator className="w-100"
                          {...params} label=""
                          name="frequency"
                          value={state.frequency}
                          validators={['required']} errorMessages={['This field is required']}
                        />}


                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label>Date</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style datePicker aggregateDate">
                    <div className="mat-form-field-wrapper">
                      <ul className="rs_formlist">
                        <li>
                          <span className="date_formto">From</span>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                maxDate={new Date()}
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} required/>}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </li>
                        <li>
                          <span className="date_formto">To</span>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack>
                              <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                maxDate={new Date()}
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} required />}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="contained"
                  onClick={cancelModal}
                  className="cancelbtn"
                >
                  Cancel
                </Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? "Update" : "Add"}
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>
    </>
  );
};

export default AggregationAddModal;
