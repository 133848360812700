import React, { Component, useEffect } from "react";
import $ from "jquery";
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import DataSearchResultChart from "./data-searchchart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


import * as subHeaderComponentMemo from "../../../Common/components/TableSubHeader";
import dataSearchResultLang from '../../../Config/data-search-results-component.json';
import { Button } from "@mui/material";

let DataSearchResultTemplate = function (props) {



  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;



  return (
    <>
      <div className="dsrResult">
        <ul>
          <li
            className={
              this.state.type == "table" ? "active enabled" : " enabled"
            }
            onClick={(e) => this.changeType("table")}
          >
            <img src="assets/lib/images/charts/icon-1.svg" />
          </li>

          <li
            className={
              (this.state.type == "line" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("line")}
          >
            <img src="assets/lib/images/charts/icon-2.svg" />
          </li>

          <li
            className={
              (this.state.type == "bar" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("bar")}
          >
            <img src="assets/lib/images/charts/icon-3.svg" />
          </li>

          {/* <li className={(this.state.type=='column'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('column')} ><img src="assets/lib/images/charts/icon-4.svg" /></li> */}

          <li
            className={
              (this.state.type == "pie" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("pie")}
          >
            <img src="assets/lib/images/charts/icon-5.svg" />
          </li>

          <li
            className={
              (this.state.type == "doughnut" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("doughnut")}
          >
            <img src="assets/lib/images/charts/icon-6.svg" />
          </li>

          {/* <li className={(this.state.type=='scatter'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('scatter')}><img src="assets/lib/images/charts/icon-7.svg" /></li> */}

          {/* <li className={(this.state.type=='funnel'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('funnel')}><img src="assets/lib/images/charts/icon-8.svg" /></li> */}

          <li
            className={
              (this.state.type == "map" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("map")}
          >
            <img src="assets/lib/images/charts/icon-9.svg" />
          </li>

          {/* <li className={(this.state.type==''?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('table')}><img src="assets/lib/images/charts/icon-10.svg" /></li> */}

          <li
            className={
              (this.state.type == "treemap" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("treemap")}
          >
            <img src="assets/lib/images/charts/icon-11.svg" />
          </li>

          <li
            className={
              (this.state.type == "radar" ? "active " : " ") +
              (this.state.selectedRows.length > 0 ? "enabled" : "disabled")
            }
            onClick={(e) => this.changeType("radar")}
          >
            <img src="assets/lib/images/charts/icon-12.svg" />
          </li>

          {/* <li className={(this.state.type=='venn'?'active ':' ')+(this.state.selectedRows.length>0? 'enabled': 'disabled')} onClick={(e)=>this.changeType('venn')}><img src="assets/lib/images/charts/icon-13.svg" /></li> */}
        </ul>
      </div>

      <div
        className={this.state.type == "table" ? "dsrTable" : "dsrTable d-none"}
      >
        {this.props.data?.length > 0 && (
          <div className="tableData h-100">
            <div className="dsrTableTop d-flex align-items-center justify-content-between">
              <div class="dropdown">
                <button
                  className="viewSelection dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="assets/lib/images/icon/metadata.svg" /> 
                  {/* View
                  Selection */}
                  {dataSearchResultLang.viewSec[this.props.lang]}
                </button>
                <div
                  class="viewSelectionBox dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className="d-flex h-100">
                    <div class="disasterSelected_List">
                      <h5>
                        {/* Disaster  */}
                        {dataSearchResultLang.Disaster[this.props.lang]}
                       
                        (
                        {"*" in this.props.selDisaster
                          ? Object.keys(this.props.selDisaster)?.length - 1
                          : Object.keys(this.props.selDisaster)?.length})
                        
                      </h5>
                      <ul>
                        {Object.entries(this.props.selDisaster).map(
                          ([key, value]) => {
                            if (key == "*") {
                              console.log("value-title", value);
                              return <></>;
                            } else {
                              return (
                                <li key={value._id}>
                                  {value?.title?.[this.props.lang]
                                    ? value?.title?.[this.props.lang]
                                    : value}
                                </li>
                              );
                            }
                          }
                        )}
                      </ul>
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                        {/* Form  */}
                        {dataSearchResultLang.Form[this.props.lang]}
                        (
                        {this.props.selStage?.includes("*")
                          ? this.props.selStage?.length - 1
                          : this.props.selStage?.length}
                        )
                      </h5>
                      <ul>
                        {this.props.selStage.map((item) => {
                          return item != "*" && <li>{item}</li>;
                        })}
                      </ul>
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                      {/* Area  */}
                      {dataSearchResultLang.Area[this.props.lang]}
                      ({this.props.selAreaList?.length})</h5>
                      {this.props.areaLevel?.map((a) => {
                        return (
                          <ul className="dsl_breadcrumb">
                            {a?.map((x) => {
                              return <li>{x}</li>;
                            })}
                          </ul>
                        );
                      })}
                    </div>
                    <div class="disasterSelected_List">
                      <h5>
                        {/* Indicator  */}
                        {dataSearchResultLang.Indicator[this.props.lang]}
                        (
                          { Object.entries(this.props.selIUSdata)?.filter(([key,value]) =>  key != value?.iu_id+'#' && key != '*')?.length }
                        )
                      </h5>
                      <ul>
                        {Object.entries(this.props.selIUSdata)?.map(
                          ([key, ius]) => {
                            return (
                              !(ius == true)  && !key.endsWith('#') && (
                                <li>
                                  {ius.ind_name} <span>| {ius.unit}</span>{" "}
                                  <span>| {ius.subgroup_name}</span>
                                </li>
                              )
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dsrTablesearch">
                {/* <input type="search" placeholder="Search..." />
                                <img className='dsrtsearchIcon' src="assets/lib/images/icon/searchicon.svg" /> */}
                <subHeaderComponentMemo.default
                  onSearch={this.onSearch}
                  searchText={this.state.searchText}
                  nRows={this.state.filteredData?.length}
                />

                <Button
                  className="dsrTableReset"
                  onClick={() => this.props.callBack()}
                >
                  <img src="assets/lib/images/icon/reset-2.svg" />
                </Button>
                <Button
                  className="dsrTableReset"
                  onClick={(e) => this.downloadCsvData()}
                >
                  <img src="assets/lib/images/home/download.svg" />
                </Button>
              </div>
            </div>
            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{ inkDisabled: true }}
              onSelectedRowsChange={(s) => this.selectedRowsChange(s)}
              clearSelectedRows={this.state.toggledClearRows}
              sortIcon={<ArrowDownwardIcon />}
              persistTableHead
              className="dsrTableComponent"
              noDataComponent="No Data"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
          </div>
        )}
        {this.props.data?.length == 0 && (
          <div className="tableNodata">
            {/* <p>No Data</p> */}
            <p>
              {dataSearchResultLang.noData[this.props.lang]}
            </p>
          </div>
        )}
      </div>

      <div
        className={this.state.type != "table" ? "dsrChart" : "dsrChart d-none"}
      >
        <DataSearchResultChart
          data={this.state.selectedRows}
          type={this.state.type}
        />
        
      </div>
    </>
  );
};
export default DataSearchResultTemplate;
