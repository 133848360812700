import React,  {useState, useEffect} from "react";
// ======================================================= Tab Start
import PropTypes from 'prop-types';
import $ from 'jquery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// ======================================================= Tab End
import Button from '@mui/material/Button';
// ======================================================= Table Start
import Table from 'react-bootstrap/Table';

// ======================================================= Checkbox End
// ======================================================= Datatable Start
import FixedHeaderStory from 'react-data-table-component';
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }





 function PendingView(props) {
    console.log(props);

   const [assignedUserData,setAssignedUserData] = useState({})

    useEffect(()=>{
        console.log('pprop',props);
        if(props.mainViewData.AU){
            let userData = {}
            props?.mainViewData?.AU.forEach(data=>{
                data?.area?.forEach(area=>{
                    userData[area?.name?.en] = data?.user?.name
                })
            })
            setAssignedUserData(userData)
        }

    },[props.mainViewData]) 
let i=0
    const columns1 = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: '60px'
        },
        {
            name: 'Question',
            selector: row => row.question,
            width: '450px',
            sortable: true,
        },
        {
            name: 'Section',
            selector: row => row?.section,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Type',
            // selector: row => <p className="type-dm">{row?.type}</p>,
            selector: row => row?.type,
            sortable: true
        },
        {
            name: 'Mandatory',
            selector: row => row.mandatoryquestion,
            // width: "100px",
            sortable: true,
        },   
    ];



    // sort by  property ASCENDING And DESCENDING (A - Z / Z - A) //
    const [sortOrderArea,setSortOrderArea] = useState(true)
    const [sortOrderUser,setSortOrderUser] = useState(true)

    const [sortBy,setSortBy] = useState('area')

    const [areaSearchValue,setAreaSearchValue] = useState('')
    const [userSearchValue,setUserSearchValue] = useState('')

    const day = new Date(props.mainViewData?.createdAt);
    const m = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const currentDate = day.getDate() + ' ' + m[day.getMonth()] + ' ' + day.getFullYear();
    const createdDate = props.mainViewData?.createdAt




    return (
        <>
            <div className="modal fade timePeriodPopup selectArea viewModal-manage" id="viewModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header align-items-center">
                                <div class="summary_head">
                                    <h5 className="modal-title" id="exampleModalLabel">Form Details | { props.mainViewData?.name?.en }</h5>   
                                    <p  class="date mb-0 p-0">Created Date ( {currentDate} )</p>
                                </div>
                                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body viewModal p-0">
                                    {/* <button class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary Mui-selected css-1h9z7r5-MuiButtonBase-root-MuiTab-root button-setup" tabindex="0" type="button" role="tab" aria-selected="true" id="simple-tab-0" aria-controls="simple-tabpanel-0"><div class="label-text"><div class="rtf_num">1</div><div class="rtf_text">Setup</div></div><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button> */}
                                    <div className="rightTab_form ">
                                        <Box sx={{ borderBottom: 1 }}>
                                            <Tabs   aria-label="basic tabs example">
                                                <Tab label={<div className='label-text tabsection1'><div className="rtf_num">1</div><div className="rtf_text">Setup</div></div>} {...a11yProps(0)} />
                                            </Tabs>
                                        </Box>
                                    </div>  
                                    <div className="viewModal_body px-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <table class="table table-striped table-bordered table-hover custom-formDetail"> 
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">
                                                                Details
                                                            </th>
                                                            </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            From Date
                                                            </td>
                                                            <td>{props.mainViewData?.from_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                            To Date
                                                            </td>
                                                            <td>{props.mainViewData?.to_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                            Description
                                                            </td>
                                                            <td>{props.mainViewData?.description?.en}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>   
                                            <div className="col-md-6 mb-4">
                                                <div class="row">
                                                <div className="col-md-12 areaTable">
                                                    <Table striped bordered hover custom-formDetail>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="d-flex align-items-center">
                                                                                Area 
                                                                            <span className="areaCount">{Object.keys(assignedUserData).length}</span>
                                                                        </div>
                                                                        <div class="btn-group">
                                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <img src="assets/lib/images/home/sorting.svg" />
                                                                            </button>
                                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                                <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderArea(true);setSortBy('area')}}> Sort A - Z </a>
                                                                                <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderArea(false);setSortBy('area')}}> Sort Z - A </a>
                                                                                <div className="areaSearch">                                        
                                                                                    <input value={areaSearchValue} onChange={(e)=> setAreaSearchValue(e.target.value) } type="search" placeholder="Search..." />   
                                                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="d-flex align-items-center">
                                                                            User 
                                                                            <span className="areaCount">{[...new Set(Object.values(assignedUserData))].length}</span>
                                                                        </div>
                                                                        <div class="btn-group">
                                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <img src="assets/lib/images/home/sorting.svg" />
                                                                            </button>
                                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                                <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderUser(true);setSortBy('user')}} > Sort A - Z </a>
                                                                                <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderUser(false);setSortBy('user')}} > Sort Z - A </a>
                                                                                <div className="areaSearch">                                        
                                                                                    <input  value={userSearchValue} onChange={(e)=> setUserSearchValue(e.target.value) } type="search" placeholder="Search..." />   
                                                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
														{
															Object.keys(assignedUserData)
                                                            .filter(x=> x.toLowerCase().includes(areaSearchValue.toLowerCase()))
                                                            .filter(y=>assignedUserData[y]?.toLowerCase().includes(userSearchValue?.toLowerCase()))
                                                            .sort((a,b)=> { return sortBy=='area'?sortOrderArea?a.localeCompare(b):b.localeCompare(a) : sortOrderUser?assignedUserData[a].localeCompare(assignedUserData[b]):assignedUserData[b].localeCompare(assignedUserData[a])} )
                                                            .map((area)=>{
																return (
																	<>
                                                                        <tr>
                                                                        <td> {area}</td>
                                                                        <td> {assignedUserData[area]} </td>
                                                                        </tr>
																	</>
																)
																	
															})
														}

                                                                {/* <td> Chhattisgarh</td> */}
                                                                        
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {/* <div className="col-md-7 pl-0 areaTable">
                                                    <Table striped bordered hover className="assignTable">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className="d-flex align-items-center">
                                                                        User 
                                                                    </div>
                                                                    <div class="btn-group">
                                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <img src="assets/lib/images/home/sorting.svg" />
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                            <a class="dropdown-item" href="javascript:void(0)"> Sort A - Z </a>
                                                                            <a class="dropdown-item" href="javascript:void(0)"> Sort Z - A </a>
                                                                            <div className="areaSearch">                                        
                                                                                <input type="search" placeholder="Search..." />   
                                                                                <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <RadioGroup className="atBody" row aria-labelledby="demo-row-radio-buttons-group-label" name="assign-radio">
                                                        <tbody className="w-100 d-inline-table">
                                                            
                                                        {
                                                            props.mainViewData['AU'] ? props.mainViewData['AU'].map((ele)=>{
                                                                return (
                                                                    <>
                                                                    <tr>
                                                                    <td> {ele.user.name}</td>
                                                                    </tr>
                                                                    </>
                                                                )
                                                            }) : ''
                                                        } 
                                                                                                                                
                                                        </tbody>
                                                        </RadioGroup> 
                                                    </Table>
                                                </div> */}
                                            </div>    
                                            </div>   
                                        </div>
                                        <div className="assignedList">
                                        <div className="row m-0">
                                            <div className="col-md-12 px-0">												
                                            {/* { JSON.stringify(assignTableData11)  } */}
                                                <FixedHeaderStory
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="300px"
                                                    columns={columns1}
                                                    data={ props.mainViewData['questionsList'] ? props.mainViewData['questionsList'].map((ele)=>{
                                                        return (
                                                            
                                                            {'question' : ele.title?.en, section: ele.sectionData?.name?.en, type: (ele.type == 'single_select') ? 'Single Select' : (ele.type == 'date') ? 'Date' : (ele.type == 'numeric') ? 'Numeric' : (ele.type == 'grid') ? 'Grid' : (ele.type == 'audio') ? 'Audio' :(ele.type == 'image') ? 'Image' :(ele.type == 'text') ? 'Text' : (ele.type == 'multi_select') ? 'Multi Select' : ele.type, 'mandatoryquestion': (ele.mandatory) ? 'Yes' : 'No'}
                                                        )
                                                    }) : [] }
                                                    // selectableRows
                                                    persistTableHead
                                                    noDataComponent="No Data"
                                                />                                    
                                            </div>
                                        </div>
                                
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">                           
                                    <div className="row align-items-center btn_saveNext justify-content-center mx-0">  
                                    {                                       
                                        props.alignment != 'Pending' ?  <Button variant="contained" data-dismiss="modal" data-toggle="modal" data-target="#dataentryview" className="text-capitalize mb-0" >  Next <img src="assets/lib/images/home/arrow_right.svg" /></Button> : null
                                    }
                                                                  
                                    </div>                    
                                </div>
                            </div>
                        </div>
                </div>
        </>
    )
}

export default  React.memo(PendingView)