import React, { useEffect, Component } from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import adminService from '../../../services/admin.service';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import contactJson from '../../../Config/contact.json'


var contactTemplate = function () {

    const handleChange = (event) =>{

        this.setState({
            [event.target.name]: event.target.value
        });
    }


    const handleSubmit =(event)=>{
        event.preventDefault();
        this.setState({loader: true});

        const data = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        }

        adminService.httpUserPost('/data-import/users/contact-us', data).then((res) => {  

            if(res.success === true){
                this.setState({loader: true})
                swal(
                    `${contactJson?.['message-sent']?.[this.props.lang]}`,
                    `${contactJson?.['received-enquiry']?.[this.props.lang]}`,
                    "success"
                ).then((ok)=>{
                    if(ok){
                        this.setState({
                            loader: true,
                            name: "",
                            email: "",
                            message: ""
                        })
                    }
                })
            } else {
                toast.error(res.message)
            }
      
          }).catch(error => {
            toast.error(error.message)
          })
    }

    return (
      <>
        <Header />   
        <section className='pageBanner' style={{backgroundImage: "url(assets/lib/images/home/contact_us.jpg)"}}>
            <div className='custom_container h-100'>
                <div className='row align-items-end h-100'>
                    <div className='col-md-12'>
                        {/* <h2>Contact Us</h2> */}
                        <h2>{contactJson?.['contact-us'][this.props.lang]}</h2>
                    </div>
                </div>
            </div>   
        </section>
        <section className='contact_form'>
            <div className='custom_container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='cf_form'>
                            {/* <h3>Contact</h3> */}
                            <h3>{contactJson?.contact[this.props.lang]}</h3>
                            <form action='' onSubmit={handleSubmit} method='POST'>
                            <div className='cf_form_list'>
                                <div className='cfl_form'>
                                    {/* <label>Name</label> */}
                                    <label>{contactJson?.name[this.props.lang]}</label>
                                    <TextField onChange={handleChange} value={this.state.name} id="name" name="name" variant="outlined" required/>
                                </div>                           
                                <div className='cfl_form'>
                                    {/* <label>Email Address</label> */}
                                    <label>{contactJson?.['email-address'][this.props.lang]}</label>
                                    <TextField onChange={handleChange} value={this.state.email} id="email" type="email" name="email" variant="outlined" required/>
                                </div>
                                <div className='cfl_form'>
                                    {/* <label>Enquiry Message</label> */}
                                    <label>{contactJson?.['enquiry-message'][this.props.lang]}</label>
                                    <TextField type='text' onChange={handleChange} value={this.state.message} id="message" name="message" multiline  rows={6} variant="outlined" required/>
                                </div>
                                <div className='cfl_form'>
                                    {/* <Button type='submit' variant="contained">Submit <img src='assets/lib/images/home/arrow_right.svg' /></Button> */}
                                    <Button type='submit' variant="contained">{contactJson?.['submit-btn'][this.props.lang]} <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-4 pl-45'>
                        <div className='contact_address'>
                            {/* <h3>Regional Office</h3> */}
                            <h3>{contactJson?.['regional-office'][this.props.lang]}</h3>
                            <ul>
                                <li><span><img src='assets/lib/images/home/map_pointer.svg' /></span> <p>Rua Caicoli, Postu Administrativu Vera Cruz, Munisípiu Dili</p></li>
                                <li><span><img src='assets/lib/images/home/call_us.svg' /></span> <p><a href="tel:3322597">3322597</a> or <a href="tel:78141715">78141715</a></p></li>
                                <li><span><img src='assets/lib/images/home/mail.svg' /></span> <p><a href="mailto:grec@apc.gov.tl">grec@apc.gov.tl</a></p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        <Footer />
      </>
    );


};


export default contactTemplate;
