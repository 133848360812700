import { Component, useRef, useState } from 'react'
import './login.css';
import ForgetModal from '../../components/forget.modal';
import AddRegisterNowModal from '../../components/modal/addRegisterNowmodal';
// ======================================== Password Hide/Show
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LoginForm from '../../components/login-form';
// ======================================== Password Show/hide
var loginTemplate = function () {



  return (
    <>
      <div className="h-100vh">
        <div className="h-100">
          <div className="container-fluid h-100" style={{ 'overflowX': 'hidden' }}>
            <div className="row h-100">
              <div className="punjab_gov_logo">
                <img src="assets/images/logo_wmis.svg"
                  className="img-resposive timor-logo" />
              </div>
              <div className="pyramid h-100">
                <img className="img-responsive" src="assets/lib/images/bg/data-analysis.jpg" />
              </div>
            </div>
          </div>
        </div>

        <section className="login-tab">
          <div className="right_modal_wid width3501">
            <div className="panel panel-default">
            </div>
            <div className="right_modal_wid-inner">
              <div className="form-div">


                <LoginForm />


              </div>
            </div>
          </div>

          {/* modelcode */}
          <div className={'form-group'}>



          </div>

          {/* modal */}

          {/* <div className="modal fade " id="forgotPassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
             <div className="modal-dialog" role="document">
        <div className="modal-content forget-form">
        <div className = "modal-header">
        <button type = "button" className="close" data-dismiss="modal"><img src=""/><img src="assets/lib/images/close_btn.svg"/></button>
            <h1 className = "modal-title" style={{'marginTop': '15px'}}>FORGOT PASSWORD</h1>
       </div>
        </div>
        </div>


        
        <div className="form-group">
              <label className="mat_lables">Email or User ID</label>
              <div className="form-field">
              
                <input  className="form-control" formcontrolname="email" autoComplete="off" maxLength="50"></input>
              </div>
      
            </div>
            <div className="form-group-captcha">
              <div id="captchaForgot" className="col-xs-4 plr-0"></div>
              <div className="col-xs-3 plr-0 text-center">isModal={this.resetLogin} 
              <button style={{'marginTop': '15px'}} id="createCaptcha" onClick={() => this.createCaptcha('captcha')}
                                    type="button" className="btn  refresh-btn"><i className="fa fa-refresh"></i>
                                </button>
                               
                             
                </div>
                </div>
                <div className="col-xs-12 captcha_input">
              <div className="col-xs-12 captcha_input">
                                <fieldset>
                                <input className="captcha-position" placeholder="captcha" name="captcha"
                                    id="cpatchaForgotTextboxrellax" autoComplete="off"
                                    maxLength="6" onChange={(e)=> this.handleUserInput(e)} />
                                </fieldset>
                                <div className="invalid-cap"></div>
                <div className="invalid-cap"></div>
              </div>
            </div>
            <div className="form-group colored" style={{'paddingTop': '10px'}}>
             
              <button className="login-b btn btn-primary w-100" style={{'color': '#fff !important', 'backgroundColor': '#1c3156 !important',
                                    'borderColor': '#3b63ab !important', 'display': 'flex !important', 'justifyContent': 'center !important', 
                                    'borderRadius': '10px !important'}} 
                                    type="submit" color="warn">Submit</button>
         
    
        </div>
        </div> */}





        </section>
      </div>

    </>
  )
}

export default loginTemplate;