import React, { useState, useEffect } from "react";
export default function MetaDataindiactor (props) {
    console.log("props" , props.data);
    return(
        <>
            <div class="modal fade export_popups indicatormetadata" id='indicatormetadata' tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <button type="button"  className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <img src="assets/lib/images/cross.svg" alt="" />
                        </span>
                    </button>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <h4>Goal</h4>
                                <p>{props.data.goal}</p>     
                            </li>
                            <li>
                                <h4>Target</h4>
                                <p>{props.data.target}</p>     
                            </li>
                            <li>
                                <h4>Sector</h4>
                                <p>{props.data.sector}</p>     
                            </li>
                            <li>
                                <h4>Subsector</h4>
                                <p>{props.data.subsector}</p>     
                            </li>
                        </ul>                                    
                    </div>              
                </div>
              </div>
            </div>
        </>
    );
}