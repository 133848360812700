import React from 'react';
import DataTable from 'react-data-table-component';

import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';

var logTemplate = function() {

    return (
    <>
      <div className="main import-data" id="main">
      <div className="vertical-centered-box" style={{'display': 'none'}}>
        <div className="content">
          <div className="loader-circle"></div>
          <div className="loader-line-mask one">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-mask two">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-mask three">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-mask four">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
      <div className='container-fluid areaCont'>
      <section className='areaSection' id="areaSection">
        <subHeaderComponentMemo.default onSearch={this.onSearch} 
                                        searchText={this.state.searchText} 
                                        PropDeleteBulk={this.deleteBulkUser}
                                        checkData = {this.state.bulkDelete}
                                        nRows={this.state.filteredData?.length}
                                        />
        <DataTable
            columns={this.columns}
            data={this.state.filteredData}
            direction="auto"
            fixedHeaderScrollHeight="500px"
            pagination
            selectableRows
            paginationRowsPerPageOptions={[10, 25, 50,100]}
            style={{ display: "flex" }}
            persistTableHead
            className='dataTableScroll'
            onSelectedRowsChange={this.handleChange}
            clearSelectedRows={this.handleChangeData}
            noDataComponent="No Data"
        />
      </section>
      </div>

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>


      </div>      
      </>
    )
  }
  
  export default logTemplate;