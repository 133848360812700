import React, { useState } from "react";
import DataTable from 'react-data-table-component';
import MasterListModal from '../../Modals/masterList.modal';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


  const masterlistTemplate = function () {           
      
    ///////
        
    return (
        <>
            <div className="main" id="main">
                <div className='container-fluid areaCont'>
                <section className='areaSection' id="areaSection">
                    <subHeaderComponentMemo.default onSearch={this.onSearch} 
                                                   parentCallBack={this.sendStatus} 
                                                    searchText={this.state.searchText} 
                                                    PropDeleteBulk={this.deleteBulkUser}
                                                    value='4'
                                                      checkData = {this.state.bulkDelete}
                                                    nRows={this.state.filteredData?.length}
                                                    />
                    <div className="facility_dropdown">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                            <Select id="ptype" name="ptype" value={this.state.selectedFilter} onChange={this.getSelectType}>
                                {
                                    this.state.masterListTypes.map((type)=> {
                                        return (
                                            <MenuItem value={type}>{type}</MenuItem>            
                                        )
                                    })
                                }
                            </Select>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={this.state.columns}
                        data={this.state.filteredData}
                        direction="auto"
                        fixedHeaderScrollHeight="500px"
                        pagination
                        paginationRowsPerPageOptions={[10, 25, 50,100]}
                        style={{ display: "flex" }}
                        // subHeader
                        // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
                        persistTableHead
                        selectableRows
                        onSelectedRowsChange={this.handleChange}
                        className='dataTableScroll'
                        noDataComponent="No Data"
                    />
            
                </section>
                </div>
            </div>



            {/* modal area begin here============== */}



            <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>
                <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
                        <img src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
                </div>
                <div class="right-tab-heading are_head">
                    <h2 class="form-heading form-heading text_trnsfrm-all-labels">
                        <span class="form-heading_he">
                            <span class="ddf">{this.state.isEdit==true ? 'Edit '+ this.state.selectedFilter: 'Add ' + this.state.selectedFilter}</span>
                        </span>
  
                    </h2>
                </div>
                <MasterListModal
                    sendData={this.state.data}
                    closeModal={this.openAddModal}
                    parentCallback={this.saveDumpData}
                    editData = {this.state.editEntry}
                    editMode = {this.state.editModeId}
                    isEdit = {this.state.isEdit}
                    columnsState = {this.state.selectedFilter}
                    treeData = {this.state.treeData}
                />
                <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
                    <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
                </div>
            </div>
            <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>

        </>
        );
  };

  export default masterlistTemplate;
