import React, { Component, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Link } from "@material-ui/core";
import ReactEcharts from "echarts-for-react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CountUp, { useCountUp } from "react-countup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MetaDataModal from "../../../Common/components/modal/metadata.modal";
import Counter from "../../../Common/components/counter";

import disasterProfileJSON from "../../../Config/disasterProfile.json";
import { CSVLink } from "react-csv";
import { event } from "jquery";
import { toast } from "react-toastify";
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import {setGlovalLevel} from "../../../features/formStateSlice"

var dataanalyticsTemplate = function () {
  const options = [
    { label: "2019", value: 1 },
    { label: "2020", value: 2 },
  ];

  const headers = [
    { label: "Indicator", key: "third" },
    { label: "Subgroup", key: "fourth" },
    { label: "Source", key: "fiveth" },
    { label: "TimePeriod", key: "first" },
    { label: "Value", key: "sec" },
  ];

  const headersForAllData = [
    { label: "Indicator", key: "indicator" },
    { label: "Unit", key: "unit" },
    { label: "Subgroup", key: "subGroup" },
    { label: "Time Period", key: "timePeriod" },
    { label: "value", key: "value" },
    { label: "source", key: "source" },
  ];

  // Csv File Name Structure Start Herre

  let dTime =
    new Date().toISOString().split("T")[0].replace(/-/g, "") +
    "_" +
    new Date().getHours() +
    "" +
    new Date().getMinutes() +
    "" +
    new Date().getSeconds();
  let fileNameFormate = "TERS_" + this.state.selectedCharId + dTime;
  // Csv File Name Structure End Herre
  const adddata = (fname) => {
    this.surveyLink.link.click();
  };

  const onChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  const searchShuffledIndicator = (e) => {
    this.setState({ search: true });

    let value = e.target.value;
    let data = [];

    this.state.sectorIus?.forEach((ius) => {
      ius?.iusList?.map((byH) => {
        let s = byH;
        data?.push(s);
      });
    });

    let d = data;

    let fd = null;

    if (value?.length > 0) {
      fd = d?.filter(
        (item) =>
          (item &&
            item?.name?.[this.props.lang]
              ?.toLowerCase()
              .includes(value.toLowerCase())) ||
          item?.unit?.[this.props.lang]
            ?.toLowerCase()
            .includes(value.toLowerCase())
        // item => (item && item?.toLowerCase().includes(value.toLowerCase())
        // item => {console.log("This is item console on searhc--", item)}
      );

      // console.log("This is filtered search indicator--", fd);

      this.setState({ shuffledData: fd });
    } else {
      this.setState({ shuffledData: d });
    }
  };

  const downloadAllData = () => {
    this.setState({ loader: true });
    let ids = [];
    Object.values(this.state.subgroups).map((value, index) => {
      // console.log('dddd', value)
      value.map((data) => {
        ids.push(data._id);
      });
    });
    let dataToSend = {
      "area": [
        this.currentAreaCode
      ],
      "ius_id": ids,
      "disaster": this.props?.formStateAreaCode
    }


    // if (ids.length > 0) {
    adminService
      .httpPost("/data-retrieval/data/get-All-chart-data", dataToSend)
      .then((result) => {
        console.log('alldata', result.data)
        let allData = [];
        result.data.map((data) => {
          allData.push({
            // indicator: data.indicator.en,
            // unit: data.unit.en,
            // subGroup: data.subgroupData.subgroup.name.en,
            // timePeriod: data.time_period.start_time_period,
            // value: data.new_value,
            // source: data.source.publisher,
            indicator: data?.indicator?.en ? data?.indicator?.en : data?.subgroupData?.name?.en,
            unit: data?.unit?.en ? data?.unit?.en : data?.subgroupData?.unit?.en,
            subGroup: data?.subgroupData?.subgroup?.name?.en,
            timePeriod: data?.time_period?.start_time_period,
            value: data?.new_value ? data?.new_value:data?.value,
            source: data?.source.publisher,
          });
        });

        this.setState({ allChartData: allData });

        if (allData.length != 0) {
          this.allData.link.click();
          this.setState({ loader: false });
        } else {
          toast.error("No Data To Download");
          this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const handleChange =async (e)=>{
    await this.setState({filterDataBySource : e.target.value})
  }
  // console.log('currentAreaCode', this.currentAreaCode)

  // console.log(fileNameFormate, "fileNameFormate");
//   let allSources = this.state?.allSources.filter((value, index, self) => {
//     return self.indexOf(value) == index;
// })
//   console.log('allsourcesjsx',this.state?.allSources.filter((value, index, self) => {
//     return self.indexOf(value) == index;
// }))


  return (
    <>
      <section className="main_profile_area_bg">
        <section className="profile_boxes">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 text-right mb-3 d-flex justify-content-end">
                <img src="assets/lib/images/icon/cog.svg"
                  data-toggle="modal"
                  data-target="#customize_indicator"
                  alt=""
                  className="img-fluid cursor-pointer mr-3"
                  onClick={() => this.checkSelectedIndicator()}
                />
                <img src="assets/lib/images/icon/download-circle.svg"
                  alt=""
                  className="img-fluid cursor-pointer"
                  onClick={() => {
                    downloadAllData();
                  }}
                />
              </div>
            </div>
            <div className="row">
              {this.state.sectorIus.length > 0 &&
                this.state.indicatorData.map((item) => {
                  return (
                    <div className="col-md-6 col-lg-3 pb_column">
                      <div className="outer_profile_card h-100 pink_border">
                        <div className="main_profile_card h-100 pink_shade">
                          <div className="head_box d-flex align-items-start">
                            {/* <img src="assets/lib/images/home/indicator.svg" /><h3>People Affected</h3> */}
                            <MetaDataModal
                              sector={item.sector}
                              iuId={item.iu_id}
                              metaData={item.metaData}
                              // indicator={item.name.en}
                              indicator={item.name[this.props.lang]}
                              unit={item.unit[this.props.lang]}
                              type={"keyfact"}
                            />
                            <h3>
                              {/* {item.name.en} */}
                              {item.name[this.props.lang] ? item.name[this.props.lang] : item?.name?.en}
                              {/* <span className="unit_span"> | Number</span> */}
                              <span className="unit_span">
                                {" "}
                                | {item?.unit?.[this.props.lang]}
                              </span>
                            </h3>
                          </div>
                          <div className="mid_box d-flex align-items-center">
                            <img
                              src="assets/lib/images/home/indicator.svg"
                              className="extra_space_img"
                            />
                            {/* <div className="mid_area_content extra_space">
                            <p>{item.subgroup}</p>
                            <Counter
                                  startAgain={this.state.isCounting} 
                                  start={0}
                                  end={item.value ? item.value : 0} 
                                  duration={4}
                                  formatfn={num => num.toLocaleString()} 
                                />
                          </div> */}
                            {/* <div className="mid_area_content">
                            <p>Indirectly</p>
                            <h5>2,492</h5>
                          </div> */}
                          </div>
                          <div className="last_box d-flex align-items-end justify-content-between">
                            <div className="source_area">
                              {/* <Tooltip title='Dummy' placement="top"> <p>Source <img src="assets/lib/images/home/hover_arrow.svg" /> </p></Tooltip> */}
                              {item.source ? (
                                <Tooltip title={item.source} placement="top">
                                  <Link to="">
                                    {
                                      disasterProfileJSON?.Source?.[
                                      this.props.lang
                                      ]
                                    }
                                    <img src="assets/lib/images/home/hover_arrow.svg" />
                                  </Link>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="big_count">
                              {/* <span className='bigCountName'>{item.subgroup}</span> */}
                              <span className="bigCountName">
                                {item.period ? item.period : " "}
                              </span>
                              <h1>
                                {item.value ? (
                                  <CountUp
                                    //  startAgain={this.state.isCounting}
                                    start={0}
                                    // end={item.value ? item.value : null}
                                    end={item.value ? item.value : "-"}
                                    duration={4}
                                    // formatfn={(num) => num.toLocaleString()}
                                    separator=","
                                  />
                                ) : (
                                  "-"
                                )}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        {this.state.sectorIus.map((sector, indexval) => {
          return (
            <>
              <section className="chart_area">
                <div className="container-fluid">
                  <div className="row">
                    {sector.iusList.map((iu) => {
                        // console.log('this.state.source',this.state.source[sector.level_name.en + iu._id],this.defOption);
                        // console.log('get-chart-data',sector.level_name.en + iu._id+iu.name?.[this.props?.lang],this.state.selectedOptionsTP[sector.level_name.en + iu._id])
                        // this.state.selectedOptionsTP[sector.level_name.en + iu._id]?.length > 0 && this.state.selectedOptionsTP[sector.level_name.en + iu._id]?.map((item)=>{
                        //     console.log('get-chart-data',sector.level_name.en + iu._id+iu.name?.[this.props?.lang]+item.value)
                          
                        // })
                      return (
                        <>
                        {
                          (
                          <div
                          id={"div-" + sector.level_name.en + iu._id}
                          className={
                            "col-md-6 mb-50 " +
                            (iu["isEnabled"] !== undefined &&
                              iu["isEnabled"] == false
                              ? "hide-card"
                              : "")
                          }
                        >
                          <div className="inner_chart_box">
                            <div className="upper_box">
                              <div
                                style={{ display: "none" }}
                                className="ec_indicator hArea w-100"
                              >
                                <p className="mb-0">
                                  {this.props?.disasterNameType} <span className="pipeMeta">|</span> {" "}
                                  {this.state.currentAreaName}
                                </p>
                              </div>
                              <div className="chart_head d-flex  justify-content-between">
                                <h4>
                                  <span className="drop_i_icon">
                                    <MetaDataModal
                                      sector={sector.level_name.en}
                                      iuId={iu._id}
                                      metaData={iu.metadata?.[0]}
                                      // indicator={iu.name?.en}
                                      indicator={iu.name?.[this.props.lang]}
                                      unit={iu.unit?.[this.props.lang]}
                                      type={"chart"}
                                    />
                                  </span>
                                  {/* {iu.name?.en} */}
                                  {iu.name?.[this.props.lang]}{" "}
                                  <span className="light-text">
                                    <span className="pipeMeta mx-1">|</span> {iu.unit?.[this.props.lang]}
                                  </span>
                                </h4>
                                {/* <img src="assets/lib/images/home/white_i_icon.svg" alt="" /> */}
                               {this.state?.responsedChart?.[sector.level_name.en+iu._id] && <div className="d-flex">
                                  {/* {
                                    console.log(this.state.chartType=== "table"?"hello":"hi","value get chart type")
                                  } */}
                                  {/* {
                                    console.log(this.state.source[
                                      sector.level_name.en + iu._id
                                      sector.level_name. en + iu._id
                                    ],"check indicator list")
                                  } */}

                                  {this.state.source[
                                    sector.level_name.en + iu._id] ||
                                    (iu["isEnabled"] !== undefined &&
                                      iu["isEnabled"] == true)
                                   ? (
                                    <>
                                      <div className="chartLabel">
                                        <button
                                          type="button"
                                          className="dropdown-toggle show btn btn-success"
                                          // data-toggle="collapse"
                                          // data-target={
                                          //   "#ShareCollapse" + iu._id
                                          // }
                                          aria-expanded="false"
                                          aria-controls="collapseExample"
                                          onClick={(e) => {
                                            this.dropdownClose();
                                          }}
                                        >
                                          <img
                                            src="assets/lib/images/home/dots.svg"
                                            alt="svg_icon"
                                          />
                                        </button>
                                        <div
                                          className="collapse chartLabelcollapse"
                                        // id={"ShareCollapse" + iu._id}
                                        >
                                          <div className="card card-body">
                                            <ul>
                                              {!this.optionData[
                                                sector.level_name.en + iu._id
                                              ]?.hasOwnProperty(
                                                "tableData"
                                              ) && (
                                                  <li
                                                    onClick={(e) => {
                                                      this.labelChart(
                                                        sector.level_name.en,
                                                        iu._id,
                                                        this.state
                                                          .selectedChartType
                                                      );
                                                    }}
                                                  >
                                                    <Tooltip title={this.state.labelon ? `${disasterProfileJSON.labelOff[this.props.lang]}` : `${disasterProfileJSON.labelOn[this.props.lang]}`} placement="right">
                                                      <img
                                                        alt="dnd"
                                                        id={
                                                          "img" +
                                                          sector.level_name.en +
                                                          iu._id
                                                        }
                                                        src="assets/lib/images/home/label_on.svg"
                                                      />
                                                    </Tooltip>
                                                  </li>
                                                )}

                                              <li
                                                className="multiCollapseShare collapsed position-relative"
                                                // data-toggle="collapse"
                                                // data-target={
                                                //   "#multiCollapseShare" + iu._id
                                                // }
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls={
                                                  "multiCollapseShare" + iu._id
                                                }
                                              >
                                                <Tooltip title={disasterProfileJSON.share[this.props.lang]} placement="right">
                                                  <img src="assets/lib/images/home/share.svg" />
                                                </Tooltip>

                                                <div className="collapse multi-collapse shareBtn"
                                                // id={"multiCollapseShare" + iu._id}
                                                >
                                                  <div className="card card-body">
                                                    <ul>
                                                      <li
                                                        key={
                                                          "fbShare" +
                                                          sector.level_name.en +
                                                          iu._id
                                                        }
                                                      >
                                                        <FacebookShareButton
                                                          className={
                                                            "fb" +
                                                            sector.level_name.en +
                                                            iu._id
                                                          }
                                                          // Disable calling the dialog if we don't have a url yet.
                                                          openShareDialogOnClick={
                                                            this.state.url !== "none"
                                                          }
                                                          url={this.state.url}
                                                          onClick={() =>
                                                            this.onClickHandler(
                                                              "fb",
                                                              sector.level_name.en,
                                                              iu._id
                                                            )
                                                          }
                                                        >
                                                          <FacebookIcon size={25} />
                                                        </FacebookShareButton>
                                                      </li>
                                                      <li
                                                        key={
                                                          "lnShare" +
                                                          sector.level_name.en +
                                                          iu._id
                                                        }
                                                      >
                                                        <LinkedinShareButton
                                                          className={
                                                            "ln" +
                                                            sector.level_name.en +
                                                            iu._id
                                                          }
                                                          // Disable calling the dialog if we don't have a url yet.
                                                          openShareDialogOnClick={
                                                            this.state.url !== "none"
                                                          }
                                                          url={this.state.url}
                                                          onClick={() =>
                                                            this.onClickHandler(
                                                              "ln",
                                                              sector.level_name.en,
                                                              iu._id
                                                            )
                                                          }
                                                        >
                                                          <LinkedinIcon size={25} />
                                                        </LinkedinShareButton>
                                                      </li>
                                                      <li
                                                        key={
                                                          "twShare" +
                                                          sector.level_name.en +
                                                          iu._id
                                                        }
                                                      >
                                                        <TwitterShareButton
                                                          className={
                                                            "tw" +
                                                            sector.level_name.en +
                                                            iu._id
                                                          }
                                                          // Disable calling the dialog if we don't have a url yet.
                                                          openShareDialogOnClick={
                                                            this.state.url !== "none"
                                                          }
                                                          url={this.state.url}
                                                          onClick={() =>
                                                            this.onClickHandler(
                                                              "tw",
                                                              sector.level_name.en,
                                                              iu._id
                                                            )
                                                          }
                                                        >
                                                          <TwitterIcon size={25} />
                                                        </TwitterShareButton>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                              {this.optionData[
                                                sector.level_name.en + iu._id
                                              ]?.hasOwnProperty(
                                                "tableData"
                                              ) && (
                                                  <li
                                                    onClick={() =>
                                                      adddata(
                                                        iu.name?.[this.props.lang]
                                                      )
                                                    }
                                                  >
                                                    <Tooltip title="Download" placement="right">
                                                      <img src="assets/lib/images/home/download.svg" />
                                                    </Tooltip>
                                                  </li>
                                                )}
                                              {/* {this.state.selectedChartType ==
                                              "table" ? (
                                                <li onClick={adddata}>
                                                  <img src="assets/lib/images/home/download.svg" />
                                                </li>
                                              ) : (
                                                <li
                                                  onClick={(e) => {
                                                    this.downloadChart(
                                                      sector.level_name.en,
                                                      iu._id,
                                                      iu.name?.en
                                                    );
                                                  }}
                                                >
                                                  <img src="assets/lib/images/home/download.svg" />
                                                </li>
                                              )} */}
                                              {!this.optionData[
                                                sector.level_name.en + iu._id
                                              ]?.hasOwnProperty(
                                                "tableData"
                                              ) && (
                                                  <li
                                                    onClick={(e) => {
                                                      this.downloadChart(
                                                        sector.level_name.en,
                                                        iu._id,
                                                        iu.name?.en
                                                      );
                                                    }}
                                                  >
                                                    <Tooltip title="Download" placement="right">
                                                      <img src="assets/lib/images/home/download.svg" />
                                                    </Tooltip>
                                                  </li>
                                                )}
                                            </ul>
                                          </div>
                                        </div>
                                        {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src="assets/lib/images/home/dots.svg" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="javascript:void(0);">
                                
                              </Dropdown.Item>
                              <Dropdown.Item href="javascript:void(0);" >
                                
                              </Dropdown.Item>
                              <Dropdown.Item href="javascript:void(0);" >
                                
                              </Dropdown.Item>                              
                            </Dropdown.Menu>
                          </Dropdown> */}
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}


                                </div> }
                              </div>

                              {this.state?.responsedChart?.[sector.level_name.en+iu._id] && <div className="chart_dropdown d-flex align-items-center justify-content-between">
                                <div className="inner_chart_drop d-flex align-items-center">
                                  {this.state.source[
                                    sector.level_name.en + iu._id
                                  ] ? (
                                    <>
                                      <div className="react_drop">
                                        <label className="drop_labels">
                                          {/* Subgroup */}
                                          {
                                            disasterProfileJSON?.Subgroup?.[
                                            this.props.lang
                                            ]
                                          }
                                        </label>
                                        <ReactMultiSelectCheckboxes
                                          options={
                                            this.state.subgroupsLabel[
                                              sector.level_name.en + iu._id
                                            ]
                                              ? this.state.subgroupsLabel[
                                              sector.level_name.en + iu._id
                                              ]
                                              : []
                                          }
                                          placeholderButtonLabel=""
                                          getDropdownButtonLabel={({ placeholderButtonLabel, value }) =>
                                            this.getDropdownButtonLabel({ placeholderButtonLabel, value }, this)
                                          }
                                          value={
                                            this.state.selectedOptionsSG[
                                              sector.level_name.en + iu._id
                                            ]
                                              ? this.state.selectedOptionsSG[
                                              sector.level_name.en + iu._id
                                              ]
                                              : []
                                          }
                                          onChange={(v, e) =>
                                            this.onChangeSG(
                                              v,
                                              e,
                                              sector.level_name.en,
                                              iu._id
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {this.state.source[
                                    sector.level_name.en + iu._id
                                  ] ? (
                                    
                                    <>
                                      <div className="react_drop">
                                        <label className="drop_labels">
                                          {/* Time Period */}
                                          {
                                            disasterProfileJSON?.[
                                            "time-period"
                                            ]?.[this.props.lang]
                                          }
                                        </label>
                                        <ReactMultiSelectCheckboxes
                                          options={
                                            this.state.timePeriods[
                                              sector.level_name.en + iu._id
                                            ]
                                              ? this.state.timePeriods[
                                              sector.level_name.en + iu._id
                                              ]
                                              : []
                                          }
                                          placeholderButtonLabel=""
                                          getDropdownButtonLabel={({ placeholderButtonLabel, value }) =>
                                            this.getDropdownButtonLabel({ placeholderButtonLabel, value }, this)
                                          }
                                          value={
                                            this.state.selectedOptionsTP[
                                              sector.level_name.en + iu._id
                                            ]
                                              ? this.state.selectedOptionsTP[
                                              sector.level_name.en + iu._id
                                              ]
                                              : []
                                          }
                                          onChange={(v, e) =>
                                            this.onChangeTP(
                                              v,
                                              e,
                                              sector.level_name.en,
                                              iu._id
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {/* {this.state.source[
                                    sector.level_name.en + iu._id
                                  ] ? (
                                    <>
                                      <div className="react_drop">
                                        <label className="drop_labels">
                                          Source
                                        </label>
                                        <div className="user_selct">
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="src-select"
                                            value={this.state.selectedSource[sector.level_name.en+iu._id]}
                                            label="Source"
                                            onChange={(e) => this.changeSource(sector.level_name.en, iu._id, e.target.value)}
                                          >
                                            {
                                              this.state.selectedSrc[sector.level_name.en+iu._id]?.map(src => {
                                                return <MenuItem value={src}>{src}</MenuItem>;
                                              })
                                            }
                                          </Select>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )} */}
                                </div>

                                {this.state.source[
                                  sector.level_name.en + iu._id
                                ] ? (
                                  <div className="multi_chart_drop">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img
                                          src={
                                            this.state.chartType?.[
                                              sector.level_name.en + iu._id
                                            ]?.path
                                          }
                                        />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Tooltip title={disasterProfileJSON.bar[this.props.lang]} placement="right"><Dropdown.Item
                                          onClick={() =>
                                            this.onSelect(
                                              sector.level_name.en,
                                              iu._id,
                                              this.chartIcon["bar"],
                                              "bar"
                                            )
                                          }
                                        >
                                          <img src={this.chartIcon["bar"]} />
                                        </Dropdown.Item></Tooltip>
                                        <Tooltip title={disasterProfileJSON.line[this.props.lang]} placement="right"><Dropdown.Item
                                          onClick={() =>
                                            this.onSelect(
                                              sector.level_name.en,
                                              iu._id,
                                              this.chartIcon["line"],
                                              "line"
                                            )
                                          }
                                        >
                                          <img src={this.chartIcon["line"]} />
                                        </Dropdown.Item></Tooltip>
                                        <Tooltip title="Pie" placement="right"><Dropdown.Item
                                          onClick={() =>
                                            this.onSelect(
                                              sector.level_name.en,
                                              iu._id,
                                              this.chartIcon["pie"],
                                              "pie"
                                            )
                                          }
                                        >
                                          <img src={this.chartIcon["pie"]} />
                                        </Dropdown.Item></Tooltip>
                                        <Tooltip title={disasterProfileJSON.table[this.props.lang]} placement="right"><Dropdown.Item
                                          onClick={() =>
                                            this.onSelect(
                                              sector.level_name.en,
                                              iu._id,
                                              this.chartIcon["table"],
                                              "table",
                                              iu.name?.[this.props.lang]
                                            )
                                          }
                                        >
                                          <img src={this.chartIcon["table"]} />
                                        </Dropdown.Item></Tooltip>

                                        {/* <Dropdown.Item >Table</Dropdown.Item>
                              <Dropdown.Item >Treemap</Dropdown.Item> */}
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src={this.state.chartType}></img>
                            </Dropdown.Toggle>
  
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => this.setState({ 'chartType': "assets/lib/images/home/line_chart.svg" })} ><img src="assets/lib/images/home/line_chart.svg" /></Dropdown.Item>
                              <Dropdown.Item onClick={() => this.setState({ 'chartType': "assets/lib/images/home/multi_bar_chart.svg" })} ><img src="assets/lib/images/home/multi_bar_chart.svg" /></Dropdown.Item>
                              <Dropdown.Item onClick={() => this.setState({ 'chartType': "assets/lib/images/home/multi_chart_icon1.svg" })} ><img src="assets/lib/images/home/multi_chart_icon1.svg" /></Dropdown.Item>
                              <Dropdown.Item onClick={() => this.setState({ 'chartType': "assets/lib/images/home/pie_chart.svg" })} ><img src="assets/lib/images/home/pie_chart.svg" /></Dropdown.Item>
                              <Dropdown.Item onClick={() => this.setState({ 'chartType': "assets/lib/images/home/tree_chart.svg" })} ><img src="assets/lib/images/home/tree_chart.svg" /></Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown> */}
                                  </div>
                                ) : null}
                              </div>}
                            </div>
                            <div
                              className={
                                this.optionData[
                                  sector.level_name.en + iu._id
                                ]?.hasOwnProperty("tableData") ?? false
                                  ? "d-none"
                                  : "chart_img_box text-center"
                              }
                            >
                              <ReactEcharts
                                ref={(e) => {
                                  this.chartRefs[
                                    sector.level_name.en + iu._id
                                  ] = e;
                                }}
                                option={this.defOption}
                                style={{
                                  height: "300px",
                                  left: 0,
                                  top: 0,
                                  width: "100%",
                                }}
                                opts={{ renderer: "svg" }}
                              />
                              
                              {/* <img src="assets/lib/images/home/bar_chart.svg" alt="" className='img-fluid' /> */}
                            </div>
                            <div
                              className={
                                this.optionData[
                                  sector.level_name.en + iu._id
                                ]?.hasOwnProperty("tableData") ?? false
                                  ? "chart_img_box text-center overflow-auto"
                                  : "d-none"
                              }
                            >
                              <div className="inner_table">
                                {this.state?.addTableData?.length > 0 ? (
                                  <CSVLink
                                    data={this.state?.addTableData}
                                    ref={(r) => (this.surveyLink = r)}
                                    headers={headers}
                                    filename={fileNameFormate}
                                    className="d-flex"
                                  />
                                ) : null}

                                {this.state.allChartData?.length > 0 ? (
                                  <CSVLink
                                    data={this.state.allChartData}
                                    ref={(r) => (this.allData = r)}
                                    headers={headersForAllData}
                                    filename={fileNameFormate}
                                    className="d-flex"
                                  />
                                ) : null}

                                <table className="table-chart table table-info table-sm">
                                  <tbody>
                                    {this.state.selectedOptionsTP[
                                      sector.level_name.en + iu._id
                                    ] &&
                                      this.optionData[
                                        sector.level_name.en + iu._id
                                      ]?.hasOwnProperty("tableData") &&
                                      this.state.selectedOptionsTP[
                                        sector.level_name.en + iu._id
                                      ].map((time, index) => {
                                        let data =
                                          this.optionData[
                                            sector.level_name.en + iu._id
                                          ].tableData;

                                        return (
                                          <>
                                            {index === 0 && (
                                              <tr>
                                                {this.state.selectedOptionsSG[
                                                  sector.level_name.en + iu._id
                                                ] &&
                                                  this.state.selectedOptionsSG[
                                                    sector.level_name.en +
                                                    iu._id
                                                  ].map((subgroup, index) => {
                                                    return (
                                                      <>
                                                        {index == 0 && (
                                                          <th className="">
                                                            Time Period
                                                          </th>
                                                        )}
                                                        {subgroup.label !=
                                                          "All" && (
                                                            <th className="">
                                                              {subgroup.label}
                                                            </th>
                                                          )}
                                                      </>
                                                    );
                                                  })}
                                              </tr>
                                            )}

                                            <tr>
                                              {time.label != "All" && (
                                                <th className="">
                                                  {time.label}
                                                </th>
                                              )}
                                              {this.state.selectedOptionsSG[
                                                sector.level_name.en + iu._id
                                              ] &&
                                                this.state.selectedOptionsSG[
                                                  sector.level_name.en + iu._id
                                                ].map((subgroup) => {
                                                  return (
                                                    <>
                                                      {time.label != "All" &&
                                                        subgroup.label !=
                                                        "All" && (
                                                          <td className="">
                                                            {data[time.label]?.[
                                                              subgroup.label
                                                            ] ?? "-"}
                                                          </td>
                                                        )}
                                                    </>
                                                  );
                                                })}
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="chart_bottom_box d-flex align-items-center justify-content-between">
                              {this.state?.responsedChart?.[sector.level_name.en+iu._id] &&<div className="chart_source_area">
                                {/* <p>Source <img src="assets/lib/images/home/hover_arrow.svg" /></p> */}
                                {this.state.source[
                                  sector.level_name.en + iu._id
                                ] ? (
                                  // <>
                                  //   <ul className="maps_source">
                                  //     <li>
                                  //       <Tooltip
                                  //         title={
                                  //           this.state.source[
                                  //           sector.level_name.en + iu._id
                                  //           ]
                                  //         }
                                  //         placement="top"
                                  //       >
                                  //         <Link to="">
                                  //           {/* Source */}
                                  //           {
                                  //             disasterProfileJSON?.Source?.[
                                  //             this.props.lang
                                  //             ]
                                  //           }
                                  //           <img src="assets/lib/images/home/hover_arrow.svg" />
                                  //         </Link>
                                  //       </Tooltip>

                                  //     </li>
                                  //   </ul>
                                  //   <p
                                  //     className="download_source"
                                  //     style={{
                                  //       display: "none",
                                  //       fontSize: "12px",
                                  //       color: "#666",
                                  //     }}
                                  //   >

                                  //     {disasterProfileJSON?.Source?.[
                                  //       this.props.lang
                                  //     ]}:
                                  //     {
                                  //       this.state.source[
                                  //       sector.level_name.en + iu._id
                                  //       ]
                                  //     }
                                  //   </p>
                                  // </>
                                  <>
                                 { console.log(this.state.source[sector.level_name.en + iu._id],">>>>JKSAHDKGBDF")}
                                  <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                  <Select
                                   
      
                                            labelId="demo-simple-select-label"
                                            id="src-select"
                                            // value={this.state.source[sector.level_name.en+iu._id]}
                                            value={this.state.source[sector.level_name.en + iu._id]}
                                            label="source"
                                            defaultValue={this.state.source[sector.level_name.en + iu._id]}
                                            onChange={(e) => this.changeSource(sector.level_name.en, iu._id, e.target.value)}
                                          >
                                            {/* {
                                              this.state.selectedSrc[sector.level_name.en+iu._id]?.map(src => {
                                                return <MenuItem value={src}>{this.state.source[sector.level_name.en + iu._id]}</MenuItem>;
                                              })
                                            } */}
                                            <MenuItem value={this.state.source[sector.level_name.en + iu._id]}>{this.state.source[sector.level_name.en + iu._id]}</MenuItem>
                                          </Select>
                                  
                            </FormControl>
                                        {/* <label className="drop_labels">
                                          Source
                                        </label>
                                        <div className="user_selct">
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="src-select"
                                            value={this.state.selectedSource[sector.level_name.en+iu._id]}
                                            label="Source"
                                            onChange={(e) => this.changeSource(sector.level_name.en, iu._id, e.target.value)}
                                          >
                                            {
                                              this.state.selectedSrc[sector.level_name.en+iu._id]?.map(src => {
                                                return <MenuItem value={src}>{src}</MenuItem>;
                                              })
                                            }
                                          </Select>
                                        </div> */}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>}
                              {/* <div className="color_data d-flex align-items-center">
                      <p>Destroyed <span className='color_one same_size'></span></p>
                      <p>Damaged <span className='color_two same_size'></span></p>
                      <p>Destroyed-REGR <span className='color_three same_size'></span></p>
                      <p>Damaged-REGR <span className='color_four same_size'></span></p>
                    </div> */}
                            </div>
                          </div>
                        </div>
                        )
                        
                        }
                        
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
            </>
          );
        })}
      </section>

      <div
        className={
          this.state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

      <div className={this.state.modal ? "overlay dblock" : "overlay"}></div>
      <div id="copy" className="col-md-6" style={{ display: "none" }}></div>

      <div
        className="modal fade customize-indicator"
        id="customize_indicator"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title">
                {
                  disasterProfileJSON?.["customize-indicator"]?.[
                  this.props.lang
                  ]
                }
              </h5>
              <div className="search-1">
                <form action="">
                  <input
                    type="search"
                    className="search form-control"
                    placeholder={`${disasterProfileJSON.placeholdersearch[this.props.lang]}`}
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value })
                    }}
                  />
                  <img src="assets/lib/images/search.svg" alt="" />
                </form>
              </div>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">
                  <img src="assets/lib/images/cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="del1">
                  <table className="table indicator-table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            className="allChecked"
                            defaultChecked={this.state.defChecked}
                            onChange={(e) => this.handleSelect(e)}
                          />
                        </th>
                        <th>#</th>
                        <th>
                          {
                            disasterProfileJSON?.["select-indicator"]?.[
                            this.props.lang
                            ]
                          }
                        </th>
                      </tr>
                    </thead>

                    {this.state.ogSectorIus.map((sector) => {
                      return (
                        <tbody>
                          {sector.iusList.map((iu, index) => {
                            return (
                              <>
                                <tr className={iu?.name[this?.props?.lang]?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()) ? "search" : "d-none"}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      defaultChecked="checked"
                                      data-chkids={JSON.stringify(iu)}
                                      name="abc[]"
                                      className={"chkbx_" + index}
                                      onChange={(e) => this.checkChanges(e)}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      data-ids={JSON.stringify(iu)}
                                      data-value={index + 1}
                                      name="xyz[]"
                                      defaultValue={index + 1}
                                      onKeyPress={(e) => this.handleKeyPress(index)}
                                    />
                                  </td>
                                  <td>
                                    {/* {iu.name.en} | {iu.unit.en} */}
                                    {iu.name?.[this.props.lang]} |{" "}
                                    {iu.unit?.[this.props.lang]}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      );
                    })}
                  </table>
                </div>
                {/* <ul className="legend mb-0">
                  <li>
                    <span className="colorDiv red-color"></span>
                    <p>
                      {
                        disasterProfileJSON?.["baseline-indicator"]?.[
                        this.props.lang
                        ]
                      }
                    </p>
                  </li>
                  <li>
                    <span className="colorDiv black-color"></span>
                    <p>
                      {
                        disasterProfileJSON?.["aggregated-indicator"]?.[
                        this.props.lang
                        ]
                      }
                    </p>
                  </li>
                </ul> */}
                <div className="text-center w-100">
                  <button
                    type="button"
                    className="btn theme-btn d-inline-flex align-items-center"
                    disabled={!this.state.saveBtnDisabledOrEnabled}
                    onClick={(e) => this.getSuffleData(e)}
                  >
                    {/* Save */}
                    {
                      disasterProfileJSON?.save?.[
                      this.props.lang
                      ]
                    }
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM0 6.75H15V5.25H0V6.75Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// function mapStateToProps(state) {
//   console.log('redux',state)
//   return {
//     lang: state.lang?.lang,
//     disasterNameType: state?.areaName?.disaterName,
//     disaterCodeType: state?.areaName?.disastercode,
//     formStateAreaName:state?.formSlice?.areaCode,
//     formStateAreaCode:state?.formSlice?.disasterCode,
//     formStateGlovalLevel:state?.formSlice.glovalLevel
//   };
// }

// const mapDispatchToProps = { setGlovalLevel};


// export default connect(mapStateToProps,mapDispatchToProps)(dataanalyticsTemplate);

export default dataanalyticsTemplate;
