import React,  {useState} from "react";
// ======================================================= Dropdown End
import Dropdown from 'react-bootstrap/Dropdown';
// ======================================================= Dropdown End
// ======================================================= Form Field Start
    import TextField from '@mui/material/TextField';
    import Button from '@mui/material/Button';
    import Table from 'react-bootstrap/Table';
// ======================================================= Form Field End
// ======================================================= Tab Start
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// ======================================================= Tab End
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function ViewDataaggregate() {
// ======================================================= Dropdown Start
const [aggregateList, setAggregate] = useState('');


const [value, setValue] = useState(3);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

// ======================================================= Dropdown End

    return (
        <>
            <div class="modal fade timePeriodPopup dataentryModal dev_modal" id="dataaggregateview" tabindex="-1" role="dialog" aria-labelledby="dataentryCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header align-items-center">
                        <div class="summary_head">
                            <h5 class="modal-title" id="dataentryTitle">Aggregate</h5>
                        </div>                       
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body viewModal p-0">
                        <div className="rightTab_form ">
                            <Box sx={{ borderBottom: 1 }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={<div className='label-text tabsection1' disabled ><div className="rtf_num">1</div><div className="rtf_text">Setup</div></div>}  {...a11yProps(0)}/>
                                    <Tab label={<div className='label-text tabsection1' disabled><div className="rtf_num">2</div><div className="rtf_text">Data Entry</div></div>}  {...a11yProps(1)}/>
                                    <Tab label={<div className='label-text tabsection1'><div className="rtf_num">3</div><div className="rtf_text">Data Validation</div></div>}  {...a11yProps(2)}/>
                                    <Tab label={<div className='label-text tabsection1'><div className="rtf_num">4</div><div className="rtf_text">Data Aggregate</div></div>}  {...a11yProps(3)}/>
                                </Tabs>
                            </Box>
                        </div>  
                        <div className="viewModal_body">
                            <div className="row align-items-center mb-30 justify-content-center">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <label>Aggregate</label>
                                        <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{aggregateList}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setAggregate('This is a test')}}> This is a test </Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setAggregate('test 2')}}>test 2</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>                                    
                                        </div>
                                    </div>
                                </div>         
                            </div>
                            <div className="row align-items-center mb-30 justify-content-center">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <label>Time Period Format</label>
                                        <div className="mat-form-field-wrapper">
                                            <Dropdown disabled>
                                                <Dropdown.Toggle disabled>YYYY.MM</Dropdown.Toggle>                                                    
                                            </Dropdown>                                                
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style datePicker">
                                        <label>Time Period</label>
                                        <div className="mat-form-field-wrapper">
                                            <TextField disabled placeholder="2023.01" />                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <label>Type</label>
                                        <div className="mat-form-field-wrapper">
                                            <Dropdown disabled>
                                                <Dropdown.Toggle disabled>Aggregate</Dropdown.Toggle>                                                    
                                            </Dropdown>                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-30 formula_area">
                                <div className="col-md-6">
                                    <h5>Select Question</h5>

                                        <div className="calc_table">
                                            <Table>
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th className="popup_icon_drop d-flex align-items-center"> Question 
                                            
                                                    <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                    <img src="assets/lib/images/popup_icon.svg"></img>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                    <div className="calc_search d-flex align-items-center">
                                                            <input type="search" placeholder="Search..." />
                                                            <img class="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                                        </div>
                                                    <a class="dropdown-item">Sort A - Z</a>
                                                    <a class="dropdown-item"> Sort Z - A</a>
                                                    <a class="dropdown-item">Something else here</a>
                                                    </div>
                                                    </div>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td  class="check_table"><span  class="chec_lable"><span  class="example-margin">1</span></span></td>
                                                <td >How many members have in family</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown pt-36">
                                        <label>Aggregate Function</label>
                                        <div className="mat-form-field-wrapper">
                                            <Dropdown disabled>
                                                <Dropdown.Toggle disabled>Max</Dropdown.Toggle>                                                    
                                            </Dropdown>                                                
                                        </div>
                                    </div>
                                
                                    <h5>Formula</h5>
                                    

                                    <div className="main_decimal_box">
                                    <div className="mat-form-field-wrapper">
                                            <TextField className="border-none" id="outlined-basic" multiline rows={4} defaultValue="Q1" />                                        
                                    </div>

                                    <div className="decimal_box d-flex align-items-center justify-content-between py-3 px-2 btn_saveNext ">   
                                        <div className="form-group mb-0 inputform_style fp_dropdown w-100">
                                            <label>Decimal Place</label>
                                            <div className="mat-form-field-wrapper">
                                                <TextField type="number" />                                         
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                        

                                </div>
                            </div>

                            <div className="indicatorUnit">
                                <div className="col-md-12">
                                    <div className="row align-items-end mb-0">
                                        <div className="col-md-12">
                                            <h4>Indicator - Unit - Subgroup Mapping</h4>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Indicator | Unit</label>
                                                <div className="mat-form-field-wrapper">
                                                    <Dropdown disabled>
                                                        <Dropdown.Toggle disabled>Cases falling under Dowry Prohibition Act |  Number</Dropdown.Toggle>                                                    
                                                    </Dropdown>                                                
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Subgroup </label>
                                                <div className="mat-form-field-wrapper">
                                                    <Dropdown disabled>
                                                        <Dropdown.Toggle disabled>Total</Dropdown.Toggle>                                                    
                                                    </Dropdown>                                                
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                                <Button variant="contained" className="mb-0 text-capitalize mr-3 cancelbtn previous" data-dismiss="modal" data-toggle="modal" data-target="#datavalidationview"><img src="assets/lib/images/home/arrow_right.svg" /> Previous</Button>                   
                        </div>                        
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}