import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataTable from "react-data-table-component";
import { Tooltip } from "@material-ui/core";
import AdminService from "../../../services/admin.service";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function LanguageTab(props) {
  const [data, setData] = useState([]);
  const [resource, setResource] = useState([]);
  const [indicator, setIndicator] = useState([]);
  const [metadata, setMetaData] = useState([]);
  const [subgroupData, setSubgroupData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [disasterData, setDisasterData] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [subSectionData, setSubSectionData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [classificationData, setClassificationData] = useState([]);
  const [gridData, setGridData] = useState([]);

  // const [indData, setIndData] = useState([]);
  // const [indState, setIndState] = useState({});
  const [areaState, setAreaState] = useState({});
  const [resourceState, setResourceState] = useState({});
  const [indicatorState, setIndicatorState] = useState({});
  const [metadataState, setMetaDataState] = useState({});
  const [subgroupState, setSubgroupDataState] = useState({});
  const [unitState, setUnitState] = useState({});
  const [sourceState, setSourceState] = useState({});
  const [disasterState, setDisasterState] = useState({});
  const [optionState, setOptionState] = useState({});
  const [sectionState, setSectionState] = useState({});
  const [subSectionState, setSubSectionState] = useState({});
  const [questionState, setQuestionState] = useState({});
  const [formState, setFormState] = useState({});
  const [classificationState, setClassificationState] = useState({});
  const [gridState, setGridState] = useState({});

  const [search, setSearch] = useState(false);

  const [searchClassificationData, setSearchClassificationData] = useState();
  const [searchAreaData, setSearchAreaData] = useState();
  const [searchIndicatorData, setSearchIndicatorData] = useState();
  const [searchMetadata, setSearchMetadata] = useState();
  const [searchSubgroupData, setSearchSubgroupData] = useState();
  const [searchUnitData, setSearchUnitData] = useState();
  const [searchSourceData, setSearchSourceData] = useState();
  const [searchResource, setSearchResource] = useState();
  const [searchDisasterData, setSearchDisasterData] = useState();
  const [searchOptionData, setSearchOptionData] = useState();
  const [searchSectionData, setSearchSectionData] = useState();
  const [searchQuestionData, setSearchQuestionData] = useState();
  const [searchFormData, setSearchFormData] = useState();
  const [searchSubSectionData, setSearchSubSectionData] = useState();
  const [searchGridData, setSearchGridData] = useState();


  const onSearch = (event, value) => {
    let keys = event.target.value;

    let tab1 = classificationData;
    let tab1F = null;
    let tab2 = data;
    let tab2F = null;
    let tab3 = indicator;
    let tab3F = null;
    let tab4 = metadata;
    let tab4F = null;
    let tab5 = subgroupData;
    let tab5F = null;
    let tab6 = unitData;
    let tab6F = null;
    let tab7 = sourceData;
    let tab7F = null;
    let tab8 = resource;

    let tab8F = null;
    let tab9 = disasterData;
    let tab9F = null;
    let tab10 = optionData;
    let tab10F = null;
    let tab11 = sectionData;
    let tab11F = null;
    let tab12 = questionData;
    let tab12F = null;
    let tab13 = formData;
    let tab13F = null;
    let tab14 = subSectionData;

    let tab14F = null;
    let tab15 = gridData;
    let tab15F = null;

    switch (value) {
      case 0:
        if (keys?.length > 0) {
          setSearch(true);
          tab1F = tab1?.filter((item) =>
            item?.level_name_all?.en
              ?.toLowerCase()
              ?.includes(keys?.toLowerCase())
          );
          setSearchClassificationData(tab1F);
        } else {
          setSearch(false);
          setSearchClassificationData(tab1);
        }

        break;

      case 1:
        if (keys?.length > 0) {
          setSearch(true);
          tab2F = tab2?.filter((item) =>
            item?.name_all?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchAreaData(tab2F);
        } else {
          setSearch(false);
          setSearchAreaData(tab2);
        }

        break;

      case 2:

        if (keys?.length > 0) {
          setSearch(true);
          tab3F = tab3?.filter((item) =>
            item?.name_all?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchIndicatorData(tab3F)
        }
        else {
          setSearch(false);
          setSearchIndicatorData(tab3)
        }
        break;

      case 3:
        if (keys?.length > 0) {
          setSearch(true);
          tab4F = tab4?.filter((item) =>
            item?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchMetadata(tab4F)
        }
        else {
          setSearch(false);
          setSearchMetadata(tab4)
        }
        break;
      case 4:
        if (keys?.length > 0) {
          setSearch(true);
          tab5F = tab5?.filter((item) =>
            item?.subgroup?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchSubgroupData(tab5F)
        }
        else {
          setSearch(false);
          setSearchSubgroupData(tab5)
        }
        break;

      case 5:
        if (keys?.length > 0) {
          setSearch(true);
          tab6F = tab6?.filter((item) =>
            item?.unit_all?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchUnitData(tab6F)
        }
        else {
          setSearch(false);
          setSearchUnitData(tab6)
        }
        break;

      case 6:
        if (keys?.length > 0) {
          setSearch(true);
          tab7F = tab7?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchSourceData(tab7F)
        }
        else {
          setSearch(false);
          setSearchSourceData(tab7)
        }
        break;

      case 7:
        if (keys?.length > 0) {
          setSearch(true);
          tab8F = tab8?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchResource(tab8F)
        }
        else {
          setSearch(false);
          setSearchResource(tab8)
        }
        break;

      case 8:
        if (keys?.length > 0) {
          setSearch(true);
          tab9F = tab9?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchDisasterData(tab9F)
        }
        else {
          setSearch(false);
          setSearchDisasterData(tab9)
        }
        break;

      case 9:
        if (keys?.length > 0) {
          setSearch(true);
          tab10F = tab10?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchOptionData(tab10F)
        }
        else {
          setSearch(false);
          setSearchOptionData(tab10)
        }
        break;

      case 10:
        if (keys?.length > 0) {
          setSearch(true);
          tab11F = tab11?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchSectionData(tab11F)
        }
        else {
          setSearch(false);
          setSearchSectionData(tab11)
        }
        break;

      case 11:
        if (keys?.length > 0) {
          setSearch(true);
          tab12F = tab12?.filter((item) =>
            item?.title?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchQuestionData(tab12F)
        }
        else {
          setSearch(false);
          setSearchQuestionData(tab12)
        }
        break;

      case 12:

        if (keys?.length > 0) {
          setSearch(true);
          tab13F = tab13?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchFormData(tab13F)
        }
        else {
          setSearch(false);
          setSearchFormData(tab13)
        }
        break;

      case 13:

        if (keys?.length > 0) {
          setSearch(true);
          tab14F = tab14?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchSubSectionData(tab14F)
        }
        else {
          setSearch(false);
          setSearchSubSectionData(tab14)
        }
        break;

      case 14:

        if (keys?.length > 0) {
          setSearch(true);
          tab15F = tab15?.filter((item) =>
            item?.name?.en?.toLowerCase()?.includes(keys?.toLowerCase())
          );
          setSearchGridData(tab15F)
        }
        else {
          setSearch(false);
          setSearchGridData(tab15)
        }
        break;
    }
  };

  ///area lang

  const columns = [
    {
      name: "English (EN)",
      selector: (row) => row.name_all.en,
      sortable: true,
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveState(ele.name_all.en, "pt", e);
              }}
              placeholder={ele.name}
              value={areaState[ele.name_all.en]["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveState(ele.name_all.en, "tet", e);
              }}
              placeholder={ele.name}
              value={areaState[ele.name_all.en]["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => save("area", row.name_all.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const save = (selector, key) => {
    let dataToSend = areaState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveState = (key, lang, e) => {
    let s = areaState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setAreaState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name_all: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setData(d);
  };

  const getData = async () => {
    AdminService.httpGet("/data-retrieval/area/getAreaData")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = areaState;
          d.forEach((ele) => {
            s[ele.name_all.en] = {
              en: ele.name_all.en ? ele.name_all.en : "",
              pt: ele.name_all.pt ? ele.name_all.pt : "",
              tet: ele.name_all.tet ? ele.name_all.tet : "",
            };
          });
          setData(d);
          setAreaState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getResourceData();
    getIndicatorData();
    getMetaData();
    getClassificationData();
    getSourceData();
    getDisasterData();
    getOptionData();
    getSectionData();
    getQuestionData();
    getFormData();
    getSubSectionData();
    getGridData();
  }, [props]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {

    setValue(newValue);
    setSearch(false);
    props.getBackIndex(newValue);
  };

  // Classsification Lang
  const columns1 = [
    {
      name: "English (EN)",
      selector: (row) => row?.level_name_all?.["en"],
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveClassificationDataState(
                  ele?.level_name_all?.["en"],
                  "pt",
                  e
                );
              }}
              placeholder={ele?.level_name_all?.["en"]}
              value={classificationState[ele?.level_name_all?.["en"]]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveClassificationDataState(
                  ele?.level_name_all?.["en"],
                  "tet",
                  e
                );
              }}
              placeholder={ele?.level_name_all?.["en"]}
              value={classificationState[ele?.level_name_all?.["en"]]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div
            onClick={() =>
              saveClassificationData(
                "classifications",
                row?.level_name_all?.["en"]
              )
            }
          >
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveClassificationData = (selector, key) => {
    let dataToSend = classificationState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveClassificationDataState = (key, lang, e) => {
    let s = classificationState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setClassificationState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        level_name_all: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setClassificationData(d);
  };

  const getClassificationData = async () => {
    AdminService.httpPost("/data-retrieval/ius/get-classifications")
      .then((res) => {
        if (res) {
          let loop = [];
          let d = res.data;
          let s = classificationState;
          d.forEach((ele) => {
            let count = 0;

            if (loop.length == 0) {
              loop.push(ele);
            }

            loop.forEach((element) => {
              if (element.level_name_all.en == ele.level_name_all.en) {
                count = 1;
              }
            });

            if (count != 1) {
              loop.push(ele);
            }

            s[ele?.level_name_all?.en] = {
              en: ele?.level_name_all?.["en"]
                ? ele?.level_name_all?.["en"]
                : "",
              pt: ele?.level_name_all?.["pt"]
                ? ele?.level_name_all?.["pt"]
                : "",
              tet: ele?.level_name_all?.["tet"]
                ? ele?.level_name_all?.["tet"]
                : "",
            };
          });
          setClassificationData(loop);
          setClassificationState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////indicator
  const columns2 = [
    {
      name: "English (EN)",
      selector: (row) => row.name_all.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveIndicatorState(ele.name_all.en, "pt", e);
              }}
              placeholder={ele.name}
              value={indicatorState[ele.name_all.en]["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveIndicatorState(ele.name_all.en, "tet", e);
              }}
              placeholder={ele.name}
              value={indicatorState[ele.name_all.en]["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveindicator("indicators", row.name_all.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveindicator = (selector, key) => {
    let dataToSend = indicatorState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveIndicatorState = (key, lang, e) => {
    let s = indicatorState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setIndicatorState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name_all: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setIndicator(d);
  };

  const getIndicatorData = async () => {
    AdminService.httpPost("/data-retrieval/ius/get-indicator")
      .then((res) => {
        if (res) {
          let loopIndicator = [];
          let loop = [];
          let loop2 = [];
          let d = res.data;
          let s = subgroupState;
          let u = unitState;
          let i = indicatorState;
          d.forEach((ele) => {

            let countIndicator = 0;
            let count = 0;
            let count2 = 0;
            let countSubgroup = 0;

            if (loopIndicator?.length == 0) {
              loop.push(ele);
            }
            loopIndicator.forEach((element) => {
              if (element?.name_all?.en == ele?.name_all?.en) {
                count = 1;
              }
            });
            if (count != 1) {
              loopIndicator.push(ele);
            }

            if (loop?.length == 0) {
              loop.push(ele);
            }

            loop.forEach((element) => {
              if (element?.subgroup?.name?.en == ele?.subgroup?.name?.en) {
                countSubgroup = 1;
              }
            });

            if (countSubgroup != 1) {
              loop.push(ele);
            }

            if (loop2.length == 0) {
              loop2.push(ele);
            }

            loop2.forEach((element) => {
              if (element?.unit === ele?.unit) {
                count2 = 1;
              }
            });

            if (count2 != 1) {
              loop2.push(ele);
            }

            i[ele.name_all?.en] = {
              en: ele.name_all.en ? ele.name_all.en : "",
              pt: ele.name_all.pt ? ele.name_all.pt : "",
              tet: ele.name_all.tet ? ele.name_all.tet : "",
            };

            u[ele?.unit_all?.en] = {
              en: ele?.unit_all?.["en"] ? ele?.unit_all?.["en"] : "",
              pt: ele?.unit_all?.["pt"] ? ele?.unit_all?.["pt"] : "",
              tet: ele?.unit_all?.["tet"] ? ele?.unit_all?.["tet"] : "",
            };

            s[ele?.subgroup?.name?.en] = {
              en: ele?.subgroup?.name?.en ? ele?.subgroup?.name?.en : "",
              pt: ele?.subgroup?.name?.pt ? ele?.subgroup?.name?.pt : "",
              tet: ele?.subgroup?.name?.tet ? ele?.subgroup?.name?.tet : "",
            };
          });
          setSubgroupData(loop);
          setSubgroupDataState(s);
          // setIndicator(d);
          setIndicator(loopIndicator);
          setIndicatorState(i);
          setUnitData(loop2);
          setUnitState(u);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //metadata lang/////

  const columns3 = [
    {
      name: "English (EN)",
      selector: (row) => row?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveMetaDataState(ele?.en, "pt", e);
              }}
              placeholder={ele?.en}
              value={metadataState[ele?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveMetaDataState(ele?.en, "tet", e);
              }}
              placeholder={ele?.en}
              value={metadataState[ele?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => savemetadata("metadata", row?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const savemetadata = (selector, key) => {
    let dataToSend = metadataState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveMetaDataState = (key, lang, e) => {
    let s = metadataState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setMetaDataState(s);
    }

    let d = [];
    for (const k in s) {

      d.push({
        en: s[k]["en"],
        pt: s[k]["pt"],
        tet: s[k]["tet"],
      });
    }

    setMetaData(d);
  };

  const getMetaData = async () => {
    AdminService.httpPost("/data-retrieval/metadata/get-list-language ")
      .then((res) => {
        if (res) {
          let data = res?.allData;

          let s = metadataState;
          data?.map((item) => {

            s[item?.en] = {
              en: item?.en ? item?.en : "",
              pt: item?.pt ? item?.pt : "",
              tet: item?.tet ? item?.tet : "",
              type: item?.key,
            };
          });

          setMetaData(data);
          setMetaDataState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Subgroup lang
  const columns4 = [
    {
      name: "English (EN)",
      selector: (row) => row?.subgroup?.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSubgroupDataState(ele?.subgroup?.name?.en, "pt", e);
              }}
              placeholder={ele?.subgroup?.name?.en}
              value={subgroupState[ele?.subgroup?.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSubgroupDataState(ele?.subgroup?.name?.en, "tet", e);
              }}
              placeholder={ele?.subgroup?.name?.en}
              value={subgroupState[ele?.subgroup?.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div
            onClick={() => saveSubgroupData("subgroup", row?.subgroup.name.en)}
          >
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveSubgroupData = (selector, key) => {
    let dataToSend = subgroupState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        if (res.status == 200) {
          toast.success(`Records updated successfully`);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveSubgroupDataState = (key, lang, e) => {
    let s = subgroupState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setSubgroupDataState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        subgroup: {
          name: {
            en: s[k]["en"],
            pt: s[k]["pt"],
            tet: s[k]["tet"],
          },
        },
      });
    }

    setSubgroupData(d);
  };


  ///unit
  const columns5 = [
    {
      name: "English (EN)",
      selector: (row) => row?.unit_all?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveUnitDataState(ele?.unit_all?.en, "pt", e);
              }}
              placeholder={ele?.unit_all?.en}
              value={unitState[ele?.unit_all?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveUnitDataState(ele?.unit_all?.en, "tet", e);
              }}
              placeholder={ele?.unit_all?.en}
              value={unitState[ele?.unit_all?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveUnitData("units", row?.unit_all?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveUnitData = (selector, key) => {
    let dataToSend = unitState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveUnitDataState = (key, lang, e) => {
    let s = unitState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setUnitState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        unit_all: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }
    setUnitData(d);
  };

  //////Source Lang////////
  const columns6 = [
    {
      name: "English (EN)",
      selector: (row) => row?.name?.["en"],
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSourceDataState(ele?.name?.["en"], "pt", e);
              }}
              placeholder={ele?.name?.["en"]}
              value={sourceState[ele?.name?.["en"]]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSourceDataState(ele?.name?.["en"], "tet", e);
              }}
              placeholder={ele?.name?.["en"]}
              value={sourceState[ele?.name?.["en"]]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveSourceData("source", row?.name?.["en"])}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveSourceData = (selector, key) => {
    let dataToSend = sourceState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveSourceDataState = (key, lang, e) => {
    let s = sourceState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setSourceState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setSourceData(d);
  };

  const getSourceData = async () => {
    AdminService.httpGet("/data-retrieval/ius/get-all-sources")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = sourceState;
          d.forEach((ele) => {
            s[ele?.name?.en] = {
              en: ele?.name?.["en"] ? ele?.name?.["en"] : "",
              pt: ele?.name?.["pt"] ? ele?.name?.["pt"] : "",
              tet: ele?.name?.["tet"] ? ele?.name?.["tet"] : "",
            };
          });
          setSourceData(d);
          setSourceState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////////////resource/////////////

  const columns7 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveResourceState(ele.name?.en, "pt", e, ele);
              }}
              placeholder={ele?.name.en}
              value={resourceState[ele.name?.en]["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveResourceState(ele.name?.en, "tet", e, ele);
              }}
              placeholder={ele?.name.en}
              value={resourceState[ele.name?.en]["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveResource("resource", row.name?.en, row)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveResource = (selector, key, row) => {

    let dataToSend = resourceState[key];
    dataToSend["selector"] = selector;

    dataToSend["type"] = row?.type;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveResourceState = (key, lang, e, row) => {

    let s = resourceState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setResourceState(s);
    }

    let d = [];

    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
        type: s[k]["type"],
      });
    }

    setResource(d);
  };

  const getResourceData = async () => {
    AdminService.httpGet("/data-retrieval/resources/get-all-resources")
      .then((res) => {
        if (res) {
          let arr = [];

          for (let i = 0; i < res?.data.length; i++) {
            let title = res?.data[i]?.title;
            let description = res?.data[i]?.description;

            arr.push({
              title: title,
              description: description,
            });
          }

          let array = [];
          arr.forEach((item, i, key) => {
            let titleData = item?.title;
            let descData = item?.description;
            array.push({
              name: titleData,
              type: "title",
            });
            array.push({
              name: descData,
              type: "description",
            });
          });

          let s = resourceState;
          array?.forEach((item) => {

            s[item?.name?.en] = {
              en: item?.name?.en ? item?.name?.en : "",
              pt: item?.name?.pt ? item?.name?.pt : "",
              tet: item?.name?.tet ? item?.name?.tet : "",
              type: item?.type,
            };
          });

          setResource(array);
          setResourceState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////////////Disaster//////////////
  const columns8 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          {
            console.log(ele, "hdhjfgbjhfhj")
          }
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveDisasterState(ele.name?.en + ele?.type, "pt", e, ele);
              }}
              placeholder={ele?.name.en}
              value={disasterState[ele.name?.en + ele?.type]["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveDisasterState(ele.name?.en + ele?.type, "tet", e, ele);
              }}
              placeholder={ele?.name.en}
              value={disasterState[ele.name?.en + ele?.type]["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveDisaster("disaster", row.name?.en + row?.type, row)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveDisaster = (selector, key, row) => {

    let dataToSend = disasterState[key];
    dataToSend["selector"] = selector;
    dataToSend["type"] = row?.type;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveDisasterState = (key, lang, e, row) => {
    let s = disasterState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setDisasterState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
        type: s[k]["type"],
      });
    }
    setDisasterData(d);
  };

  const getDisasterData = async () => {
    AdminService.httpGet("/data-retrieval/disaster/getDisasterData")
      .then((res) => {
        if (res) {
          let arr = [];
          // let array = [];

          for (let i = 0; i < res?.data.length; i++) {

            let title = res?.data[i]?.title;
            let description = res?.data[i]?.description;

            arr.push({
              title: title,
              description: description,
            });
          }

          let array = [];
          arr.forEach((item, i, key) => {
            let titleData = item?.title;
            let descData = item?.description;
            array.push({
              name: titleData,
              type: "title",
            });
            array.push({
              name: descData,
              type: "description",
            });
          });

          let s = disasterState;
          array?.forEach((item) => {
            s[item?.name?.en + item?.type] = {
              en: item?.name?.en ? item?.name?.en : "",
              pt: item?.name?.pt ? item?.name?.pt : "",
              tet: item?.name?.tet ? item?.name?.tet : "",
              type: item?.type,
            };
          });

          setDisasterData(array);
          setDisasterState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///////Option///////
  const columns9 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveOptionState(ele?.name?.en, "pt", e);
              }}
              placeholder={ele?.name?.en}
              value={optionState[ele?.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveOptionState(ele?.name?.en, "tet", e);
              }}
              placeholder={ele?.name?.en}
              value={optionState[ele?.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveOption("options", row?.name?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveOption = (selector, key) => {
    let dataToSend = optionState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveOptionState = (key, lang, e) => {
    let s = optionState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setOptionState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setOptionData(d);
  };

  const getOptionData = async () => {
    AdminService.httpGet("/data-retrieval/option/get-option")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = optionState;

          d.forEach((ele) => {
            s[ele.name?.en] = {
              en: ele.name?.en ? ele.name?.en : "",
              pt: ele.name?.pt ? ele.name?.pt : "",
              tet: ele.name?.tet ? ele.name?.tet : "",
            };
          });
          setOptionData(d);
          setOptionState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /////Section/////
  const columns10 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSectionState(ele?.name?.en, "pt", e);
              }}
              placeholder={ele?.name?.en}
              value={sectionState[ele?.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSectionState(ele?.name?.en, "tet", e);
              }}
              placeholder={ele?.name?.en}
              value={sectionState[ele?.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveSection("section", row?.name?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveSection = (selector, key) => {
    let dataToSend = sectionState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveSectionState = (key, lang, e) => {
    let s = sectionState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setSectionState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setSectionData(d);
  };

  const getSectionData = async () => {
    AdminService.httpGet("/data-retrieval/section/get-section")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = sectionState;

          d.forEach((ele) => {
            s[ele.name?.en] = {
              en: ele.name?.en ? ele.name?.en : "",
              pt: ele.name?.pt ? ele.name?.pt : "",
              tet: ele.name?.tet ? ele.name?.tet : "",
            };
          });
          setSectionData(d);
          setSectionState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////Sub-Section/////
  const columns13 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSubSectionState(ele?.name?.en, "pt", e);
              }}
              placeholder={ele?.name?.en}
              value={subSectionState[ele?.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveSubSectionState(ele?.name?.en, "tet", e);
              }}
              placeholder={ele?.name?.en}
              value={subSectionState[ele?.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveSubSection("sub-section", row?.name?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveSubSection = (selector, key) => {
    let dataToSend = subSectionState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveSubSectionState = (key, lang, e) => {
    let s = subSectionState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setSubSectionState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setSubSectionData(d);
  };

  const getSubSectionData = async () => {
    AdminService.httpGet("/data-retrieval/section/get-sub-section")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = subSectionState;

          d.forEach((ele) => {
            s[ele.name?.en] = {
              en: ele.name?.en ? ele.name?.en : "",
              pt: ele.name?.pt ? ele.name?.pt : "",
              tet: ele.name?.tet ? ele.name?.tet : "",
            };
          });
          setSubSectionData(d);
          setSubSectionState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////Question/////
  const columns11 = [
    {
      name: "English (EN)",
      selector: (row) => row.title?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveQuestionState(ele?.title?.en, "pt", e);
              }}
              placeholder={ele?.title?.en}
              value={questionState[ele?.title?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveQuestionState(ele?.title?.en, "tet", e);
              }}
              placeholder={ele?.title?.en}
              value={questionState[ele?.title?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveQuestion("question", row?.title?.en)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveQuestion = (selector, key) => {
    let dataToSend = questionState[key];
    dataToSend["selector"] = selector;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveQuestionState = (key, lang, e) => {
    let s = questionState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setQuestionState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        title: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
      });
    }

    setQuestionData(d);
  };

  const getQuestionData = async () => {
    AdminService.httpGet("/data-retrieval/question/get-question-translate-list")
      .then((res) => {
        if (res) {
          let d = res.data;
          let s = questionState;

          d.forEach((ele) => {
            s[ele.title?.en] = {
              en: ele.title?.en ? ele.title?.en : "",
              pt: ele.title?.pt ? ele.title?.pt : "",
              tet: ele.title?.tet ? ele.title?.tet : "",
            };
          });
          setQuestionData(d);
          setQuestionState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////Form////
  const columns12 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveFormState(ele.name?.en, "pt", e, ele);
              }}
              placeholder={ele?.name.en}
              value={formState[ele.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveFormState(ele.name?.en, "tet", e, ele);
              }}
              placeholder={ele?.name.en}
              value={formState[ele.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveForm("forms", row.name?.en, row)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveForm = (selector, key, row) => {
    let dataToSend = formState[key];
    dataToSend["selector"] = selector;
    dataToSend["type"] = row?.type;

    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveFormState = (key, lang, e, row) => {
    let s = formState;

    if (key in s) {
      s[key][lang] = e.target.value;
      setFormState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
        type: s[k]["type"],
      });
    }
    setFormData(d);
  };

  const getFormData = async () => {
    AdminService.httpGet("/data-retrieval/survey/get-survey-data")
      .then((res) => {
        if (res) {
          let arr = [];

          for (let i = 0; i < res?.data.length; i++) {

            let name = res?.data[i]?.name;
            let description = res?.data[i]?.description;

            arr.push({
              name: name,
              description: description,
            });
          }

          let array = [];
          arr.forEach((item, i, key) => {
            let nameData = item?.name;
            let descData = item?.description;
            array.push({
              name: nameData,
              type: "name",
            });
            array.push({
              name: descData,
              type: "description",
            });
          });

          let s = formState;
          array?.forEach((item) => {
            s[item?.name?.en] = {
              en: item?.name?.en ? item?.name?.en : "",
              pt: item?.name?.pt ? item?.name?.pt : "",
              tet: item?.name?.tet ? item?.name?.tet : "",
              type: item?.type,
            };
          });

          setFormData(array);
          setFormState(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////Grid////
  const columns14 = [
    {
      name: "English (EN)",
      selector: (row) => row.name?.en,
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Portuguese (PT)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveGridState(ele.name?.en, "pt", e, ele);
              }}
              placeholder={ele?.name.en}
              value={gridState[ele.name?.en]?.["pt"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Tetum (TET)",
      selector: (ele) => (
        <div className="inputform_style">
          <div className="languageInput">
            <TextField
              onChange={(e) => {
                saveGridState(ele?.name?.en, "tet", e, ele);
              }}
              placeholder={ele?.name.en}
              value={gridState[ele?.name?.en]?.["tet"]}
              variant="outlined"
            />
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "377px",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => (
        <>
          <div onClick={() => saveGrid("grid", row.name?.en, row)}>
            <img
              className="cursor-pointer saveBtn"
              src="assets/lib/images/home/save.svg"
            />
          </div>
        </>
      ),
      sortable: false,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const saveGrid = (selector, key, row) => {
    let dataToSend = gridState[key];
    dataToSend["selector"] = selector;
    dataToSend["type"] = row?.type;

    console.log("dataToSend Lang0", dataToSend);
    AdminService.httpUserPost("/data-import/lang/save", dataToSend)
      .then((res) => {
        toast.success(`Records updated successfully`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const saveGridState = (key, lang, e, row) => {
    let s = gridState;


    if (key in s) {
      s[key][lang] = e.target.value;
      setGridState(s);
    }

    let d = [];
    for (const k in s) {
      d.push({
        name: {
          en: s[k]["en"],
          pt: s[k]["pt"],
          tet: s[k]["tet"],
        },
        type: s[k]["type"],
      });
    }
    setGridData(d);
  };

  const getGridData = async () => {
    AdminService.httpGet("/data-retrieval/question/get-grid-questions")
      .then((res) => {
        if (res && res.data) {
          const uniqueRows = new Set();
          const uniqueColumns = new Set();
          const gridData = res.data.map(item => ({
            rows: item.grid.rows.names,
            columns: item.grid.columns.names
          }));

          gridData.forEach(item => {
            console.log("item in array obe", item)

            Object.values(item.rows).forEach(row => {
              if (row.en) { // Ensure 'en' is not undefined
                uniqueRows.add(JSON.stringify(row)); // Convert the entire row object to a string
              }
            });
            Object.values(item.columns).forEach(column => {
              if (column.en) { // Ensure 'en' is not undefined
                uniqueColumns.add(JSON.stringify(column)); // Convert the entire column object to a string
              }
            });
          });

          let gridArray = [];
          uniqueRows.forEach(rowStr => {
            const row = JSON.parse(rowStr); // Parse the row string back into an object
            if (row.en) { // Ensure 'en' is not undefined
              gridArray.push({
                name: row, // 'row' already includes 'en', 'pt', and 'tet'
                type: "rows"
              });
            }
          });
          uniqueColumns.forEach(columnStr => {
            const column = JSON.parse(columnStr); // Parse the column string back into an object
            if (column.en) { // Ensure 'en' is not undefined
              gridArray.push({
                name: column, // 'column' already includes 'en', 'pt', and 'tet'
                type: "columns"
              });
            }
          });

          let s = {};
          gridArray.forEach(item => {
            const nameObject = item.name;
            const key = nameObject.en;
            if (key) { // If 'key' is truthy, it's not undefined or empty
              s[key] = {
                en: nameObject.en || "",
                pt: nameObject.pt || "",
                tet: nameObject.tet || "",
                type: item.type
              };
            }

          });

          console.log("gridArray-", gridArray);
          setGridData(gridArray);
          setGridState(s);
        }


      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="languageTab">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
        }}
      >

        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Classification" {...a11yProps(0)} />
          <Tab label="Area" {...a11yProps(1)} />
          <Tab label="Indicator" {...a11yProps(2)} />
          <Tab label="Metadata" {...a11yProps(3)} />
          <Tab label="Subgroup" {...a11yProps(4)} />
          <Tab label="Unit" {...a11yProps(5)} />
          <Tab label="Source" {...a11yProps(6)} />
          <Tab label="Resource" {...a11yProps(7)} />
          <Tab label="Disaster" {...a11yProps(8)} />
          <Tab label="Option" {...a11yProps(9)} />
          <Tab label="Section" {...a11yProps(10)} />
          <Tab label="Question" {...a11yProps(11)} />
          <Tab label="Form" {...a11yProps(12)} />
          <Tab label="Sub-Section" {...a11yProps(13)} />
          <Tab label="Grid" {...a11yProps(14)} />
        </Tabs>
        <TabPanel value={value} index={0} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                      {/* <input type="search" autoComplete="off" className="inpuut" placeholder="Search..." /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns1}
              data={
                search == true ? searchClassificationData : classificationData
              }
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        autoComplete="off"
                        className="inpuut"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={search == true ? searchAreaData : data}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns2}
              data={search == true ? searchIndicatorData : indicator}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns3}
              // data={metadata}
              data={search == true ? searchMetadata : metadata}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns4}
              data={search == true ? searchSubgroupData : subgroupData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns5}
              data={search == true ? searchUnitData : unitData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={6} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns6}
              data={search == true ? searchSourceData : sourceData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={7} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip title="Export" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#export"
                          src="assets/lib/images/manage-form/download_db.svg"
                        />
                      </Tooltip>
                      <Tooltip title="Import" placement="top" arrow>
                        <img
                          data-toggle="modal"
                          data-target="#import"
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              columns={columns7}
              // data={resource}
              data={search == true ? searchResource : resource}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={8} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns8}
              data={search == true ? searchDisasterData : disasterData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={9} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns9}
              data={search == true ? searchOptionData : optionData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={10} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns10}
              data={search == true ? searchSectionData : sectionData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={11} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns11}
              data={search == true ? searchQuestionData : questionData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={12} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns12}
              data={search == true ? searchFormData : formData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={13} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns13}
              data={search == true ? searchSubSectionData : subSectionData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={14} className="langTabs">
          <div className="languageTable">
            <div className="questionTable h-auto">
              <div className="pb-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    {/* <div class="db_btn">
                      <Tooltip
                        title="Export"
                        data-toggle="modal"
                        data-target="#export"
                        placement="top"
                        arrow
                      >
                        <img src="assets/lib/images/manage-form/download_db.svg" />
                      </Tooltip>
                      <Tooltip
                        title="Import"
                        data-toggle="modal"
                        data-target="#import"
                        placement="top"
                        arrow
                      >
                        <img
                          className="ml-4"
                          src="assets/lib/images/manage-form/upload_db.svg"
                        />
                      </Tooltip>
                    </div> */}
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="searchbar-icon mr-0">
                      <img
                        className="search-imgs"
                        src="assets/lib/images/manage-form/search.svg"
                      />
                      <input
                        type="search"
                        autoComplete="off"
                        className="inpuut"
                        onChange={(e) => {
                          onSearch(e, value);
                        }}
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns14}
              data={search == true ? searchGridData : gridData}
              pagination
              persistTableHead
              noDataComponent="No Data"
              className="langTable"
            />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
