import React from 'react';
import Breadcrumbs from '../../Layout/BreadCrumbs/Breadcrubms';
import Footer from '../../Layout/footer';
import Header from '../../Layout/header';
import Sidebar from '../../Layout/sidebar';
import './summary.css';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'



var SummaryTemplate = function () {
// console.log("datatttttt->" , this.state);



    return (
        <>
            <div className="main summary-page" id="main">

                <div className='container-fluid areaCont h-100'>


                    <main  class="mein-section h-100">
                        <div  class="row data-summary justify-content-around align-items-center h-100">
                            <div class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats pink_box">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size" src="assets/lib/images/admin_svg/red_area.svg"/>
                                           
                                        </div>
                                        <p  class="card-category">
                                        Area <Link to="/admin/import-area" ><span>{this.state.areaData['areaCount']}<img src="assets/lib/images/admin_svg/red_arrow.svg"/></span></Link></p>
                                        <h3  class="card-title">
                                            </h3>
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                            {/* <i  aria-hidden="true" class="fa fa-calendar">
                                            </i> */}
                                  
                                  {this.state.AreacreatedAt?" Last updated on : "+this.state.AreacreatedAt :""} 
                                </div>
                                </div>
                            </div>
                            </div>

                            <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats blue_box">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size" src="assets/lib/images/admin_svg/blue_indi.svg" />
                                        </div>
                                        <p  class="card-category">
                                        Indicator <Link to="/admin/manage-indicator" ><span>{this.state.areaData['iusCount']}<img src="assets/lib/images/admin_svg/blue_arrow.svg"/></span></Link></p>
                                           
                                        {/* <h3  class="card-title">
                                            --</h3> */}
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                            {/* <i  aria-hidden="true" class="fa fa-calendar">
                                            </i> */}
                                              {this.state.IuscreatedAt?"Last updated on : "+ this.state.IuscreatedAt : ""}
                                           </div>
                                    </div>
                                </div>
                            </div>

                            <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats green_box">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size" src="assets/lib/images/admin_svg/green_data.svg" />
                                        </div>
                                        <p  class="card-category">
                                            Data <Link to="/admin/data-entry" ><span>{this.state.areaData['dataCount']}<img src="assets/lib/images/admin_svg/green_arrow.svg"/></span></Link></p>
                                        {/* <h3  class="card-title">
                                           --</h3> */}
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                            {/* <i  aria-hidden="true" class="fa fa-calendar">
                                            </i> */}
                                           {this.state.DatacreatedAt?" Last updated on : "+this.state.DatacreatedAt : ""} </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats dark_brown">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size dump_img" src="assets/lib/images/admin_svg/brown_dump.svg" />
                                        </div>
                                        <p  class="card-category">
                                            Master List <Link to="/admin/master-list" ><span>{this.state.areaData['facilityCount']}<img src="assets/lib/images/admin_svg/brown_arrow.svg"/></span></Link></p>
                                      
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                         
                                        {this.state.FacilitycreatedAt?" Last updated on : "+this.state.FacilitycreatedAt : ""} 
                                             </div>
                                    </div>
                                </div>
                            </div> */}
                            <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats dark_brown">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size dump_img" src="assets/lib/images/admin_svg/brown_dump.svg" />
                                        </div>
                                        <p  class="card-category">
                                            Manage Plan <Link to="/admin/manage-form" ><span>{this.state.areaData['manageplanCount']}<img src="assets/lib/images/admin_svg/brown_arrow.svg"/></span></Link></p>
                                      
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                           
                                        {this.state.manageplancreatedAt?" Last updated on : "+this.state.manageplancreatedAt : ""} 
                                             </div>
                                    </div>
                                </div>
                            </div>

                            <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats purple_box">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size" src="assets/lib/images/admin_svg/purple_resource.svg" />
                                        </div>
                                        <p  class="card-category">
                                        Resource <Link to="/admin/manage-resource" ><span>{this.state.areaData['resourceCount']}<img src="assets/lib/images/admin_svg/purple_arrow.svg"/></span></Link></p>
                                        {/* <h3  class="card-title">
                                        --</h3> */}
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                            {/* <i  aria-hidden="true" class="fa fa-calendar">
                                            </i> */}
                                              {this.state.ResourcecreatedAt?" Last updated on : "+this.state.ResourcecreatedAt : ""}
                                            </div>
                                    </div>
                                </div>
                            </div>

                            <div  class="col-lg-4 col-xs-12 col-sm-6">
                                <div  class="card card-stats pista_box">
                                    <div  class="card-header card-header-icon card-header-danger">
                                        <div  class="card-icon">
                                        <img  class="icon-size" src="assets/lib/images/admin_svg/pista_user.svg" />
                                      
                                        </div>
                                        <p  class="card-category">
                                        User <Link to="/admin/user-management" ><span>{this.state.areaData['userCount']}<img src="assets/lib/images/admin_svg/pista_arrow.svg"/></span></Link></p>
                                           
                                        {/* <h3  class="card-title">
                                        --</h3> */}
                                    </div>
                                    <div  class="card-footer">
                                        <div  class="stats">
                                            {/* <i  aria-hidden="true" class="fa fa-calendar">
                                            </i> */}
                                       
                                       {/* Last updated on :{this.state.ResourcecreatedAt?this.state.ResourcecreatedAt : null} */}
                                       {this.state.UsercreatedAt?" Last updated on : "+this.state.UsercreatedAt : ""}
                                    
                                           </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    </main>


                </div>


            </div>





        </>


    )


}

export default SummaryTemplate;