import React, { useEffect, useRef, useState } from 'react';
import './explorevisualise.css'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CheckboxTree from 'react-checkbox-tree';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as constants from '../../../Config/app.constants';

const Label = ({children}) => <span>{children}</span>;

class ExploreVisualiseModal extends React.Component {

constructor() {
    super();
    this.state = {
        checked: [],
        expanded: [],
        clicked: [],
        value: '1',
        show: false,
        indicatorList: [],
        fIndicatorList: [],
        selectedIndicators: [],
        subgroupList: [],
        selectedIUS: [],
        areaData: [],
        allAreaList: [],
        fAreaList: [],
        selectedAreas: [],
        sourceList: [],
        fSourceList: [],
        selectedSources: [],
        timeperiodList: [],
        fTimeperiodList: [],
        selectedTimePeriods: [],
        checkedIndicators: {},
        checkedSubgroups: {},
        loader: false,
      };

      this.nodes = [{
        value: 'adolescentTonnes',
        label: <><Label>adolescent, Tonne/s  </Label></>,
        children: [
            { value: 'selectall', label: 'Select All' },
            { value: 'populationofdelhi', label: 'population of delhi' },
            ],
        },
        {
            value: 'adolescentTonnes1',
            label: 'adolescent1, Tonne/s ',
            children: [
                { value: 'selectall1', label: 'Select All' },
                { value: 'employees', label: 'employees' },
            ],
        }
        ];
}

componentDidMount() {
    this.getData();
}

getData = () => {
    this.setState({ loader: true });
    fetch(constants.server+"api/data-retrieval/data/get-data-for-advance", { 
        method: 'get', 
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
    .then((res) => res.json())
    .then((data) => {
        let chk = this.selectAllInd(false, data.indicatorData);
        // let indList = data.indicatorData;
        this.setState({
            indicatorList: data.indicatorData,
            fIndicatorList: data.indicatorData,
            subgroupList: [],
            selectedIUS: [],
            areaData: data.areaData,
            allAreaList: data.allArea,
            fAreaList: data.allArea,
            sourceList: data.sourceData,
            fSourceList: data.sourceData,
            timeperiodList: data.timePeriodData,
            fTimeperiodList: data.timePeriodData,
            checkedIndicators: chk,
            checkedSubgroups: {},
            loader: false,
        });
        
    })
    .catch((err) => {
        this.setState({ loader: false });
        toast.error(err.message);
    });        
}

handleClose = () => this.setState({show: false});
handleShow = () => this.setState({show: true});
handleChange = (event, newValue) => {
    this.setState({value: newValue});
}

selectAllInd = (status, list=null)=> {
    let l = list?list:this.state.fIndicatorList;
    let chkIU = {};
    // chkIU['*'] = status;
    for(let i = 0; i< l?.length; i++) {
        chkIU[l[i]._id] = status;
    }
    return chkIU;
}

selectAllSg = (status, list=null)=> {
    let l = list?list:this.state.subgroupList;
    let chkIUS = {};
    chkIUS['*'] = status;
    for(let i = 0; i< l?.length; i++) {
        chkIUS[l[i]._id] = status;
    }
    return chkIUS;
}

changeSgSelection = (e, ius_id) => {
    let chkSg = this.state.checkedSubgroups;
    if(ius_id == '*' && e.target.checked) {
        chkSg = this.selectAllInd(true);
    } else if(ius_id == '*') {
        chkSg = this.selectAllInd(false);
    } else if(e.target.checked) {
        let trueChkSg = Object.entries(chkSg).filter(([key, value]) => { return value == true;});
        if(trueChkSg?.length == this.state.subgroupList?.length) {
            chkSg = this.selectAllSg(true);
        } else {
            chkSg[ius_id] = true;
        }
    } else {
        let trueChkSg = Object.entries(chkSg).filter(([key, value]) => { return value == true;});
        if(trueChkSg?.length > this.state.subgroupList?.length) {
            chkSg['*'] = false;
        } 
        chkSg[ius_id] = false;        
    }
}

changeIndSelection = (e, iu_id) => {
    let chkInd = this.state.checkedIndicators;
    // if(iu_id == '*' && e.target.checked) {
    //     chkInd = this.selectAllInd(true);
    // } else if(iu_id == '*') {
    //     chkInd = this.selectAllInd(false);
    // } else if(e.target.checked) {
    //     let trueChkInd = Object.entries(chkInd).filter(([key, value]) => { return value == true;});
    //     if(trueChkInd?.length == this.state.fIndicatorList?.length) {
    //         chkInd = this.selectAllInd(true);
    //     } else {
    //         chkInd[iu_id] = true;
    //     }
    // } else {
    //     let trueChkInd = Object.entries(chkInd).filter(([key, value]) => { return value == true;});
    //     if(trueChkInd?.length > this.state.fIndicatorList?.length) {
    //         chkInd['*'] = false;
    //     } 
    //     chkInd[iu_id] = false;        
    // }
    if(e.target.checked) {
        let trueChkInd = Object.entries(chkInd).filter(([key, value]) => { return value == true;});
        Object.keys(trueChkInd).forEach(k => {
            chkInd[k] = false;
        })
        chkInd[iu_id] = true;
    } else {
        chkInd[iu_id] = false;
    }

    // trueChkInd = Object.entries(chkInd).filter(([key, value]) => { return value == true;});
    let subgroupList;
    if(e.target.checked) {
        subgroupList = this.getSubgroups(iu_id);
    }

    let chkSg = this.selectAllSg(false, subgroupList);
    
    //setState
    this.setState({subgroupList: subgroupList, checkedIndicators: chkInd, checkedSubgroups: chkSg});
}

getSubgroups = (iu_id) => {
    let ind = this.state.indicatorList.filter(i => {
        return i._id == iu_id;
    })

    return ind?.[0]?.subgroup;
}

render() {
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="" className='tabsList '>
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>1</div>
                                        <div className='adph_text'>Indicator</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                } value="1" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>2</div>
                                        <div className='adph_text'>Area</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                    } value="2" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>3</div>
                                        <div className='adph_text'>Time Period</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                    } value="3" />
                                <Tab label={
                            <div className='d-flex align-items-center'>
                                <div className='adph_number'>4</div>
                                <div className='adph_text'>Source</div>
                                <div className='adph_count'>01</div>
                            </div>
                            } value="4" />                       
                        </TabList>
                        <div className='info-rest-icons pr-4'>
                            <ul>
                                <li>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <img src="assets/lib/images/home/information.svg" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className='d-flex flex-wrap'>
                                                <div className='ius_information'>
                                                    <h4>IUS</h4>
                                                    <ul>
                                                        <li>No IUS selected</li>
                                                    </ul>
                                                </div>
                                                <div className='ius_information'>
                                                    <h4>Area</h4>
                                                    <ul>
                                                        <li>No Area selected</li>
                                                    </ul>
                                                </div>
                                                <div className='ius_information'>
                                                    <h4>Time Period</h4>
                                                    <ul>
                                                        <li>No Time Period selected</li>
                                                    </ul>
                                                </div>
                                                <div className='ius_information'>
                                                    <h4>Source</h4>                                                        
                                                    <p className='nodata'>No Source selected</p>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li><img src="assets/lib/images/home/reset-icon.svg" /></li>
                            </ul>
                        </div>
                    </Box>
                    <TabPanel value="1">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div> 
                        <div className='ev_treeList'>    
                            <div className='row'>
                                <div className='col-md-4 ev_treeListFirst'>
                                    <h4>Indicators</h4>
                                    {/*
                                    <TreeView
                                        aria-label="file system navigator"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                    >
                                        <TreeItem nodeId="1" label="Bodies of state power and administration">
                                            <TreeItem nodeId="2" label="Administration">
                                                <TreeItem nodeId="3" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Civil servants holding public positions by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                                <TreeItem nodeId="4" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Deputies of the Jogorku Kenesh (at the end of the year) by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                            </TreeItem>                                            
                                        </TreeItem>

                                        <TreeItem nodeId="5" label="Education and science">
                                            <TreeItem nodeId="6" label="Full-time educational">
                                                <TreeItem nodeId="7" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Students of full-time educational institutions <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                                <TreeItem nodeId="8" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Teachers of full-time educational institutions (without part-timers) by gender <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />
                                            </TreeItem> 
                                            <TreeItem nodeId="9" label="General education">
                                                <TreeItem nodeId="10" label={
                                                    <>
                                                    <div className='ev_treeList_content'>
                                                        <div className='evlc_img'><img src='assets/lib/images/home/indicator.svg' /></div> 
                                                        <div className='evlc_txt'>Graduation of students of general education organizations <span>| Number</span></div>
                                                    </div>
                                                </>
                                                } />                                                
                                            </TreeItem>                                           
                                        </TreeItem>
                                    </TreeView>
                                    */}
                                    <FormGroup>
                                        {/* <FormControlLabel control={<Checkbox 
                                                                    checked={this.state.checkedIndicators?.['*']}
                                                                    onChange={(e) => this.changeIndSelection(e, '*')} />} 
                                                                label="Select All" /> */}
                                        {
                                            this.state.fIndicatorList?.map(indicator => (
                                                <FormControlLabel control={<Checkbox 
                                                                            checked={this.state.checkedIndicators?.[indicator._id]}
                                                                            onChange={(e) => this.changeIndSelection(e, indicator._id)} />} 
                                                                        label={indicator.name+' | '+indicator.unit} />
                                            ))
                                        }
                                    </FormGroup>
                                </div>
                                <div className='col-md-4 ev_treeListSecond'>
                                    <h4>Subgroup</h4>  
                                    <div className='evSubgroup'>                                
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox />} label="Select All" />
                                            {
                                                this.state.subgroupList?.map(sg => (
                                                    <FormControlLabel control={<Checkbox />} label={sg.name} />
                                                ))
                                            }
                                        </FormGroup>
                                    </div> 
                                </div>

                                <div className='col-md-4 ev_treeListThree pr-3'>
                                    <h4>Selected IUS (6)</h4>  
                                    <div className='evSelected_ius'>                                
                                        <ul>
                                            {
                                                this.state.selectedIUS?.map(ius => (
                                                    <li>
                                                        <div className='evSelected_ius_txt'>{ius?.element?.name} <span>| {ius?.element?.unit}</span> <span>| {ius?.subgroup?.name}</span> </div>
                                                        <div className='evSelected_ius_img'>
                                                            <img src="assets/lib/images/home/delete-i.svg" />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel value="2">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div> 
                        <div className='ev_treeList'>                      
                            <CheckboxTree
                                nodes={this.nodes}
                                checked={this.state.checked}
                                expanded={this.state.expanded}
                                onCheck={(checked) => this.setState({ checked })}
                                onClick={(clicked) => this.setState({ clicked })}
                                onExpand={(expanded) => this.setState({ expanded })}
                            />
                        </div> 
                    </TabPanel>

                    <TabPanel value="3">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <div className='ev_treeList'>
                            <div className='evSubgroup'>                                
                                <FormGroup>
                                    {
                                        this.state.fTimeperiodList?.map(t=> (
                                            <FormControlLabel control={<Checkbox />} label={ t.time_period } />        
                                        ))
                                    }
                                </FormGroup>
                            </div> 
                        </div>
                    </TabPanel>

                    <TabPanel value="4">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <div className='ev_treeList'>
                            <div className='evSubgroup'>                                
                                <FormGroup>
                                    {
                                        this.state.fSourceList?.map(src=> (
                                            <FormControlLabel control={<Checkbox />} label={ src.publisher } />        
                                        ))
                                    }                                    
                                </FormGroup>
                            </div> 
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
            <div className='add_query_btn'>
                <div className="add_query">
                    <ul>
                        <li>
                            <div className='add_query_content'>selection 1: indicators combination - 3 | Area - 0 | Time Period - 0 | Source - 0</div>
                            <div className='add_query_img'>
                                <span><img src='assets/lib/images/home/indicator.svg' /></span>
                                <span><img src="assets/lib/images/home/delete-i.svg" /></span>
                            </div>
                        </li>
                        <li>
                            <div className='add_query_content'>selection 1: indicators combination - 3 | Area - 0 | Time Period - 0 | Source - 0</div>
                            <div className='add_query_img'>
                                <span><img src='assets/lib/images/home/indicator.svg' /></span>
                                <span><img src="assets/lib/images/home/delete-i.svg" /></span>
                            </div>
                        </li>
                    </ul>
                </div>
                <Button className="query-btn">
                    <div className="advance_plus">
                        <span className="btn-plus">
                            <img src="assets/lib/images/home/plus.svg" className="plus_search"/>
                        </span>
                    </div>
                </Button>
            </div>
            <div className='addiDataFooter'>
                <div className='adfBtn adfleft disabled'>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
                <div className='adfok'>
                    OK
                </div>
                <div className='adfBtn adfRight'>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
            </div>



      {/* ================================================================ Modal ======================================================= */}
        <Modal centered show={this.state.show} onHide={this.handleClose} className="addiDataPopup indicator_popup">
            <Modal.Header closeButton>
              <Modal.Title>adolescent1 | <span>Tonne/s</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>    
                <div className='no_data'><p>No Data</p></div>
            </Modal.Body>            
        </Modal>
      {/* ================================================================ Modal ======================================================= */}

        </>
    );
                        }
}

export default ExploreVisualiseModal;