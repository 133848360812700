import { createSlice } from "@reduxjs/toolkit";
// import {AboutJSonData} from '../../../Config/app.constants';

const initialState = {
  lang: 'en'
}



const langlice = createSlice({
    name: 'en',
    initialState,
    reducers:{
        setLang(state = initialState, action) {
            state.lang = action.payload;

            // state.locale=action.payload.locale;
        
            // localStorage.setItem('locale', 'en');
            // localStorage.setItem('en', state.lang)
            // switch (action.type) {
            //     case AboutJSonData.LOAD:
            //       return {
            //         ...state,
            //         isLoading: true,
            //         isError: false,
            //       };
            //     case AboutJSonData.LOAD_SUCCESS:
            //       return {
            //         ...state,
            //         usersData: action.usersData,
            //         isLoading: false,
            //       };
            //     default:
            //       return state;
            //   }
        }
        
    }
});

export const { setLang } = langlice.actions;

export default langlice.reducer;