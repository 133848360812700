import { Component } from 'react'
import './area.css';
import * as jsx from './area.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
// import AddAreaModal from '../../Modals/addArea.modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import Dropdown from 'react-bootstrap/Dropdown';
import swal from 'sweetalert';
class Area extends Component {
     arr = ["dxgf"]
   
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit:false,
            editEntry:[],
            modal:false,
            loader:false,
            bulkDelete:[]
        }
    }


    //////////////===========add and edit api code========///////////////
    saveAreaData = (childData) =>{
        // console.log('jj',childData.id!=null)

        if(childData.id!=null){
        
      this.editNewAreaData(childData)

        }
        else{
            this.addNewAreaData(childData)

        }

    
       
    }


      addNewAreaData =async(data)=>{
        this.setState({ loader: true })
        AdminService.httpPost('/data-import/area/add-area', data).then((res) => {
            if (res) {
           
                let d = res.data;

  

                if(  res.success == true){
                    this.setState({ loader: false })
                    toast.success("Area added successfully");
                    this.openAddModal();
                    this.getData();
                }
         
                else{
                    this.setState({ loader: false })
                    toast.error(res.message);

                }
           
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
       

        
          
      
      }
      
      
      editNewAreaData =async(data,e)=>{
        this.setState({ loader: true })
        AdminService.httpPut('/data-import/area/update-area/'+data.id, data).then((res) => {
            if (res) {
           
                let d = res.data;

  

                if(  res.status != 0 && res.success==true){
                    this.setState({ loader: false })
                    toast.success("Area updated successfully");
                    this.openAddModal();
                    this.getData();
                }

                else if(res.status == 0 ){
                    this.setState({ loader: false })
                    toast.error('Failed To Update Area');

                }
         
                else{
                    this.setState({ loader: false })
                    toast.error(res.message);

                }
           
            }

        }).catch((err) => {
            toast.error(err);
            this.setState({ loader: false })
            console.log(err)
        });
       

        
          
      
      }
      
        

////////////////////==========serach funcation===============///////////////

    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            
            fd = d.filter(
                item => (item.area_code && item.area_code.toLowerCase().includes(val.toLowerCase())
                || (item.name && item.name.toLowerCase().includes(val.toLowerCase()))
    
               
            ));
        }
        
        this.setState({filteredData : fd});
    }
    
   ////////=======create datatable column=======/////////// 
    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => row.index+1,
        //     // sortable: false,
        //     width: "50px"
        // },
        {
            name: 'Area ID',
            selector: row => row.area_code.toUpperCase(),
            sortable: true,
        },
        {
            name: 'Area Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Area Level',
            selector: row => row.level,
            sortable: true,
          
        },
        {
            name: 'Area Parent ID',
            selector: row => row.parent_id,
            sortable: true,
            
        },
        // {
        //     name: 'Status',
        //     selector: row => row.status == 1 ? 'Active' : 'Inactive',
        //     sortable: true,
        // },
        {
            name: 'Action',
            width: "200px",
            cell: row => 
                <>
                {/* <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                         <a class="dropdown-item text-bold" onClick={(e) => this.changeDataStatus(e, row._id, row.status )}  href="javascript:;" > {row.status==1? 'Hide':'Show'}</a> 
                        <a class="dropdown-item text-bold" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a>                     
                        <a class="dropdown-item text-bold" onClick={(e)=> this.deleteEntry(e, row.area_code)} href="javascript:;">Delete</a>
                    </div>
                </div>  */}
                <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.deleteEntry(e, row.area_code)} href="javascript:;">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];  

//////=======this function use to change status 1 & 0 ===========////////////////////
    changeDataStatus(e, id, status) {
        this.setState({ loader: true })
        let changed = 0;
        
        if(status==1){
            changed = 0;
        }
        else{
            changed = 1;
        }

        let statusData = { "areaId": id, "areaStatus": changed };


        AdminService.httpPost('/data-import/area/update-status', statusData).then((res) => {
    
            if (res) {
                this.setState({ loader: false })
                let d = res;
    
                // console.log(res)
    
                if(  res.success == true){
                    toast.success("Area status updated successfully");
                    this.getData();
                }
                else{
                    toast.error(res.message);
    
                }
           
            }
    
        }).catch((err) => {
            this.setState({ loader: false })
            // console.log(err)
        });


    }



////////////===============this api is use to delete data by id ==================/////////////////


    deleteEntry = (e, id) =>{

        swal({
            title:"Are you sure you want to delete?",
            text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if(willDelete) {
                const dataToBeSend = {
                    "areaId": id,
                    
                }
            
                AdminService.httpPost('/data-import/area/delete-area', dataToBeSend).then((res) => {
    
                    if (res) {
                        this.setState({ loader: false })
                        let d = res;
            
                        // console.log(res)
            
                        if(  res.status == 1){
                            toast.success("Area deleted successfully");
                            this.getData();
                        }
                     
                        else{
                            toast.error(res.message);
            
                        }
                   
                    }
            
                }).catch((err) => {
                    this.setState({ loader: false })
                    console.log(err)
                });
              }                
            });     
    
    
    
    
    
    
      }
    

/////////////=========this funcation is use edit mode ========//////
 

    editMode (event, id) {
        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });

        this.setState({...this.state.editEntry, editEntry:edData})
        // console.log('edt',this.state.editEntry)
        this.setState({ isEdit: true })
        this.openAddModal();
       
    }


    openEditModal(){
        if($('#EditAreaModal').hasClass('width350')){
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else{
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getData();
        AdminService.checkToken();
    }
    
    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }



///////===========this api is use to get the data in data base==========//////////

    getData = async () => {
        this.setState({ loader: true, searchText: "" });

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
            
                    if(res){
                        //this.setState({loader:false})
                        let d = res.data
                        // d.forEach((ele  , index)=>{ele.index = index })
                        this.setState({filteredData: d, data:d, loader: false})
                       
                    }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

    





    // open add modal=====================//


    openAddModal = () =>{


    if(this.state.modal==false){

        this.setState({modal: true})
        
    }else{
        this.setState({...this.state.editEntry, editEntry: []})
        this.setState({modal: false})
        this.setState({isEdit:false})
        this.setState({editModeId:null})
    }

}

checkExixts(val){
    // console.log("exix",val)
    fetch(constants.server+'api/data-retrieval/area/check-areacode', { area_code:val })
      .subscribe((res) => {
        // console.log(res)
        if(res.status == 1){
          this.existError = true
          this.errorMessage = res.message
        }else{
          this.existError = false
        }
      })
  }





  handleChange = ({ selectedRows }) => {
    // console.log('selected ', selectedRows);
    this.setState({bulkDelete:selectedRows})

  };
  
  

    handleChangeData = ({ toggleCleared }) => {
        this.setState({bulkDelete:toggleCleared})

  };
  




  

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) =>{
let ids = [];
// let areaCode =[];

this.state.bulkDelete.forEach(item=>{
    ids.push(item.area_code)

})

if(ids?.length > 0){    
            swal({
                title:"Are you sure you want to delete?",
                text: "",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if(willDelete) {
                    const data= {
                        "area": ids.toString()
                    }
                    AdminService.httpPost('/data-import/area/delete-multiple-area', data).then((res) => {
        
                        if (res) {
                            this.setState({ loader: false })
                            let d = res;
                
                            console.log(res)
                           
                            if(  res.status == true){
                                toast.success("Area deleted successfully");
                                this.getData();
                            }
                         
                            else{
                                toast.error(res.message);
                
                            }
                       
                        }
                
                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                  }                
                });     
            }
        
            else{
                swal({
                    title:"No Record Selected",
                    text: "Please select at a record to delete.",
                      icon: "warning",
                      button: true,
                      
                    })
            
                    return false;
            
            
            }
            
        }








  
}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
  )(Area);
