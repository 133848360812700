import React,  {useEffect, useState} from "react";

export default function RemarksModal (props) {
  console.log("This is remarks props--", props.remarkData)


    return (
        <>
            <div class="modal fade custom-modal" id="reasonDisapproval" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>REMARKS</h4> 
                    <button type="button" id="importClose" class="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">  
                     <img src="assets/lib/images/cross.svg" alt="" />
                     </span>
                     </button>
                  </div>
                  <div className='modal-body'>
                    {/* <p>Invalid Data</p> */}
                    <p>{props.remarkData}</p>
                    {/* <p>{remark}</p> */}

                  </div>
                  <div className='modal-footer'>
                    <button data-dismiss="modal" type='button' className='btn btn-danger'>OK</button>
                  </div>
                </div>
            </div>
      </div>
        </>
    );
}