import React, { useState } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import DataTable from 'react-data-table-component';
import './web-data-entry.css';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import * as WebDataEntryModal from '../../../Common/components/web-data-entry-modal.js';


// const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

//   const handleDate = (event, newValue) => {
//     setValue(newValue);
//   };

const WebdataentryTemplate = function () {

    // ============================================================== Tabs Start
    
    // ============================================================== Tabs End

    return (
        <>
            <Header />

            <section className="webdata_entry_area">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="web_datatable w-100">
                            <subHeaderComponentMemo.default onSearch={this.onSearch} 
                                searchText={this.state.searchText} 
                                PropDeleteBulk={''}
                                value='5'
                                checkData={''}
                                nRows={this.state.filteredData?.length}
                            />
                            <DataTable
                                columns={this.columns}
                                data={this.state.filteredData}
                                direction="auto"
                                fixedHeaderScrollHeight="500px"
                                pagination
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                style={{ display: "flex" }}
                                // subHeader
                                // subHeaderComponent={subHeaderComponentMemo.default(this.onSear   ch, this.searchText)}
                                persistTableHead
                                // selectableRows
                                //   onSelectedRowsChange={this.handleChange}
                                //   clearSelectedRows={this.handleChangeData}
                                className='webdata_entry'
                                noDataComponent="No Data"
                            />
                        </div>
                    </div>
                </div>
            </section>




            <div class="modal fade maps_popup" id="form_view_popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <WebDataEntryModal.default
                    selectedRow = {this.state.selectedRow} 
                    answersData = {this.state.answersData} 
                    selectedOptionsData = {this.state.selectedOptionsData} 
                    gridQuestion = {this.state.gridQuestion} 
                    readOnly = {this.state.readOnly}
                    lang = {this.state.lang}
                    allquestion = {this.state.allquestion} 
                    count = {this.state.count} 
                    defaultPage = {this.state.defaultPage}
                    nonMandatoryQuestion = {this.state.nonMandatoryQuestion}
                    sectorCount = {this.state.sectorCount}
                    progressbar = {this.state.progressbar} 
                    counterValue = {this.state.counterValue} 
                />
            </div>



            <Footer />
        </>
    );
};

export default WebdataentryTemplate;
