import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: ''
}

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers:{
        setUserName(state,action){
            state.userName = action.payload;
            localStorage.setItem('name', state.userName)
        }
    }
});

export const { setUserName } = headerSlice.actions;

export default headerSlice.reducer;