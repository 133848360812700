import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import AdminService from '../../../services/admin.service';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Slider from '@mui/material/Slider';

import Checkbox from '@mui/material/Checkbox';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ProgressBar from 'react-bootstrap/ProgressBar';
import { json, useLocation, useNavigate, useParams } from 'react-router';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { isArray } from 'lodash';
import { setUserName } from '../../../features/usersName';
import history from '../../../services/history';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Profiletabs(props) {
    const form = useRef()

    const [completion, setCompletion] = useState(0);
    const [value, setValue] = useState(0);
    const [invalid, setInvalid] = useState('')
    const [invalid2, setInvalid2] = useState('')


    const [FormState, setFormState] = useState(
        {
            // step 1
            typeOfProfile:[],
            typeOfWaste: '',
            companyName: '',
            address: '',
            contactPerson: '',
            positionStep1: '',
            phone: '',
            fax: '',
            cin: '',
            cor: 'Eswatini',
            // step 2
            parentCompany: '',
            details: '',
            // step 3
            fullName: '',
            positionStep2: '',
            experience: '',
            refName: '',
            refContact: '',
            // step 4

            sourceName: '',
            sourceContact: '',
            process: '',
            dateOfIssueOfECC: '',
            wasteCapicity: '',
            certificate: '',

            // step 5
            AspwType: '',
            wasteType: '',
            physicalState: '',
            byCapacity: '',
            spwProcess: '',
            spwType: '',
            quantity: '',
            package: '',
            vcr: '',
            wcd: '',
            transportFrequency: '',
            humanHealth: '',
            wasteMoved: '',
            emergencyResponse: '',

            // step 6
            dumpSiteName: '',
            dumpAddress: '',
            dateOfIssue: '',
            fileIssueOfECC: '',

            // step 7

            typeOfSp: '',
            disposerName: '',
            disAddress: '',
            disMethod: '',
            ecc: false,
            declare:0,

            // Area Dropdown
            id: null,
            pareaCode: "",
            pareaID: "",
            plevel: "",
            pareaName: "",
            areaName: "",
            areaId: "",
            oldAreacode: "",

        });



    const handleChange = (event, newValue) => {
        event.preventDefault()
        setValue(newValue);
        setTabIndex(newValue )
        props.tabChnged()
        console.log(tabIndex, 'tab===============')
    };

    const [dateValue, setdateValue] = useState(null);
    const [dateValue1, setdateValue1] = useState(null);
    const [dateValue2, setdateValue2] = useState(null);
    const [dateValue3, setdateValue3] = useState(null);
    const [showTextBox, setShowTextBox] = useState(true);
    const [typeOfProfile, setTypeOfProfile] = useState([])
    const [Appli, setApli] = useState('');

    const [tabIndex, setTabIndex] = useState(0);
    const [eccDoc, setEccDoc] = useState();

    const l = useLocation();
    const location = l.pathname;

    let docpath = "";

    const getBase64 = async (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";

            let reader = new FileReader();


            reader.readAsDataURL(file);

            reader.onload = () => {

                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };

            console.log(fileInfo);
        });
    };



    const emerFile = async (event) => {

        let bfile = "";

        let files = event.target.files[0];
        console.log("uuuu---" + files)



        let file = files;
        console.log("ffff--" + files.name)

        // setFilename(files.name)
        let filename = files.name;

        console.log("fname" + filename)

        if (files && file) {

            await getBase64(file)
                .then(result => {
                    file["base64"] = result;
                    let b = file.base64;
                    console.log("File Is", file.base64);

                    bfile = b.split(",").pop()


                })



            console.log("bfile" + bfile)

            docpath = (filename + ';' + bfile);
            console.log("docpath--" + docpath)
            // setEmrDoc(docpath);

        }
    };



    const EccChange = async (event) => {

        let bfile = "";

        let files = event.target.files[0];
        console.log("uuuu---" + files)



        let file = files;
        console.log("ffff--" + files.name)

        // setFilename(files.name)
        let filename = files.name;

        console.log("fname" + filename)

        if (files && file) {

            await getBase64(file)
                .then(result => {
                    file["base64"] = result;
                    let b = file.base64;
                    console.log("File Is", file.base64);

                    bfile = b.split(",").pop()


                })



            console.log("bfile" + bfile)

            docpath = (filename + ';' + bfile);
            console.log("docpath--" + docpath)
            setEccDoc(docpath);

        }
    };



    const savedItem = JSON.parse(localStorage.getItem("userInfo"));
    console.log("saved--" + savedItem)
    let id = savedItem.id;
    console.log("id--" + id)

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleMultiSelectChange = (event) => {
        console.log("log" , event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({...FormState, typeOfProfile:value});
    };

    const handleCheckBoxChange = (e) => {

        setApli(e.target.value)
        if (e.target.value != '') {
            setShowTextBox(true)
        } else {
            setShowTextBox(false)
        }
    }

    const datepicker = (dateValue) => {
        setdateValue(dateValue);
    };
    const datepicker1 = (dateValue1) => {
        setdateValue1(dateValue1);
    };
    const datepicker2 = (dateValue2) => {
        setdateValue2(dateValue2);
    };
    const datepicker3 = (dateValue3) => {
        setdateValue3(dateValue3);
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 100,
            label: '1,00,000',
        },
    ];

    function valuetext(value) {
        return `${value}°C`;
    }

    const userInfo = localStorage.getItem("userInfo");
    const role = JSON.parse(userInfo);

    const saveAndExit = (event) => {
        event.preventDefault();
        console.log('Save and exit ka console------ ',event)
        console.log('target ID Of Save and EXit----- ',event.target.id)
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit1();
            } 
        })
        
    }
    const saveAndExit2 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit2();
            }
        })
    }
    const saveAndExit3 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit3();
            }
        })
    }
    const saveAndExit4 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit4();
            }
        })
    }
    const saveAndExit5 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit5();
            }
        })
    }
    const saveAndExit6 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
               props.isSaveAndExit(true);
                handleSubmit6();
            }
        })
    }
    const saveAndExit7 = (event) => {
        event.preventDefault();
        swal(
            "Are you sure?",
            "Your current form will be updated once you exit.",
            "assets/lib/images/home/tick.svg"
        ).then((ok) => {
            if (ok) {
                props.isSaveAndExit(true);
                handleSubmit7();
            }
        })
    }

    const declarationChange = (e) => {
        if (e.target.checked === true) {
            setFormState({ ...FormState, declare: true })
        } else {
            setFormState({ ...FormState, declare: false })
        }
    }

    const textChange = (event) => {

        setFormState({ ...FormState, [event.target.name]: event.target.value });

    }


    const handleSubmit1 = (e) => {
        // e.preventDefault();
   

        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
     

        const data = {
            "profile_percentage": completion,
            "company_name": FormState.companyName?.slice(0, 200)==null ||  FormState.companyName?.slice(0, 200)==''  ? 'null' : FormState.companyName?.slice(0, 200),
            "address": FormState.address?.slice(0, 200)==null||  FormState.address?.slice(0, 200)==''  ? 'null' :FormState.address?.slice(0, 200),
            "user_id": gData.id,
            "contact_person": FormState.contactPerson?.slice(0, 200)==null||  FormState.contactPerson?.slice(0, 200)==''  ? 'null' :  FormState.contactPerson?.slice(0, 200),
            "position": FormState.positionStep1?.slice(0, 200)==null||  FormState.positionStep1?.slice(0, 200)==''  ? 'null' :FormState.positionStep1?.slice(0, 200) ,
            "phone": FormState.phone==null||  FormState.phone==''  ? 'null' : parseInt(FormState.phone),
            "fax": FormState.fax==null||  FormState.fax==''  ? 'null' : parseInt(FormState.fax),
            "company_registration_no": FormState.cin==null||  FormState.cin?.slice(0, 200)==''  ? 'null' : FormState.cin,
            "company_of_registration": FormState.cor?.slice(0, 50)==null||  FormState.cor?.slice(0, 200)==''  ? 'null' : FormState.cor?.slice(0, 50),
            "area":FormState.pareaCode,
           "date_of_registration": dayjs(dateValue).format('DD/MM/YYYY')=='Invalid Date'? 'null': dayjs(dateValue).format('DD/MM/YYYY')
        }
        props.step1Call(data)
        dispatch(setUserName(FormState.contactPerson))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit2 = (e) => {
        // e.preventDefault();
        var isApplicatnt = '';


        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)

        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "has_the_applicant": Appli==null||  Appli==''  ? 'null' : Appli,
            "has_the_applicant_answer": FormState.details==null||  FormState.details?.slice(0, 200)==''  ? 'null' : FormState.details
        }

        props.step1Call(data)
        // dispatch(setProgress(completion))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit3 = (e) => {
        // e.preventDefault();
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)


        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "tc_name": FormState.fullName?.slice(0, 200)==null||  FormState.fullName?.slice(0, 200)==''  ? 'null' : FormState.fullName?.slice(0, 200),
            "tc_position": FormState.positionStep2?.slice(0, 200)==null||  FormState.positionStep2?.slice(0, 200)==''  ? 'null' :FormState.positionStep2?.slice(0, 200),
            "tc_qualifying_experience": FormState.experience==null||  FormState.experience==''  ? 'null' : FormState.experience,
            "tc_name_refree": FormState.refName?.slice(0, 200)==null||  FormState.refName?.slice(0, 200)==''  ? 'null' :  FormState.refName?.slice(0, 200),
            "tc_contact_referee": FormState.refContact==null||  FormState.refContact==''  ? 'null' : FormState.refContact,
        }
        props.step1Call(data)
        // dispatch(setProgress(completion))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit4 = (e) => {
      
        
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "special_waste_name": FormState.sourceName?.slice(0, 250)==null||  FormState.sourceName?.slice(0, 200)==''  ? 'null' : FormState.sourceName?.slice(0, 250),
            "special_waste_contact": parseInt(FormState.sourceContact)==null||  FormState.sourceContact==''  ? 'null' : parseInt(FormState.sourceContact),
            "special_waste_quantity": FormState.wasteCapicity==null ||  FormState.wasteCapicity==''  ? 'null' :  parseInt(FormState.wasteCapicity),
            "special_waste_process_type": FormState.process?.slice(0, 250)==null||  FormState.process?.slice(0, 200)==''  ? 'null' :   FormState.process?.slice(0, 250),
            "special_waste_date_of_issue": dayjs(dateValue1).format('DD/MM/YYYY')=='Invalid Date'? 'null':  dayjs(dateValue1).format('DD/MM/YYYY'),
            // "special_waste_certificate": eccDoc
        }
        props.step1Call(data)
        // dispatch(setProgress(completion))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit5 = (e) => {
        // e.preventDefault();
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)

        console.log('qty',  FormState.quantity)

        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "transportation_waste_type": FormState.AspwType?.slice(0, 200)==null||  FormState.AspwType?.slice(0, 200)==''  ? 'null' :  FormState.AspwType?.slice(0, 200),
            "transportation_waste_physical_state": FormState.physicalState?.slice(0, 200)==null||  FormState.physicalState?.slice(0, 200)==''  ? 'null' : FormState.physicalState?.slice(0, 200),
            "transportation_waste_volume": FormState.byCapacity==null ||  FormState.byCapacity==''  ? 'null' : parseInt(FormState.byCapacity),
            "transportation_waste_transport_mode": FormState.spwProcess == null || FormState.spwProcess == '' ? 'null' : FormState.spwProcess,
            "transportation_waste_monthly_type": FormState.spwType?.slice(0, 250)==null||  FormState.spwType?.slice(0, 200)==''  ? 'null' :FormState.spwType?.slice(0, 250),
            "transportation_waste_monthly_quantity": FormState.quantity===null ||  FormState.quantity===''  ? 'null': parseInt(FormState.quantity),
            "transportation_waste_monthly_vehicle_registration": FormState.vcr == null||  FormState.vcr==''  ? 'null' :parseInt( FormState.vcr),
            "transportation_waste_monthly_packaging": FormState.package?.slice(0, 250)==null||  FormState.package?.slice(0, 200)==''  ? 'null' :FormState.package?.slice(0, 250),
            "transportation_waste_monthly_description": FormState.wcd?.slice(0, 250)==null||  FormState.wcd?.slice(0, 200)==''  ? 'null' :FormState.wcd?.slice(0, 250),
            "transportation_waste_monthly_frequency": FormState.transportFrequency?.slice(0,200)==null||  FormState.transportFrequency?.slice(0, 200)==''  ? 'null' : FormState.transportFrequency?.slice(0,200),
            "transportation_waste_monthly_humanHealth": FormState.humanHealth?.slice(0, 250)==null||  FormState.humanHealth?.slice(0, 200)==''  ? 'null' :FormState.humanHealth?.slice(0, 250),
            "transportation_waste_monthly_step_moved": FormState.wasteMoved?.slice(0, 250)==null||  FormState.wasteMoved?.slice(0, 200)==''  ? 'null' : FormState.wasteMoved?.slice(0, 250),
            // "transportation_waste_monthly_response_plan": FormState.emergencyResponse==null||  FormState.emergencyResponse==''  ? 'null' : FormState.emergencyResponse,
        
        }
        props.step1Call(data)
        // dispatch(setProgress(completion))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit6 = (e) => {
        // e.preventDefault();
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)

        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "disposal_treatment_name": FormState.dumpSiteName?.slice(0, 250)==null||  FormState.dumpSiteName?.slice(0, 200)==''  ? 'null' : FormState.dumpSiteName?.slice(0, 250),
            "disposal_treatment_address": FormState.dumpAddress?.slice(0, 250)==null||  FormState.dumpAddress?.slice(0, 200)==''  ? 'null' : FormState.dumpAddress?.slice(0, 250),
            "disposal_treatment_license": dayjs(dateValue2).format('DD/MM/YYYY'),
            "disposal_treatment_date_issue_of_ECC": dayjs(dateValue3).format('DD/MM/YYYY'),
            "disposal_treatment_ECC_file": FormState.fileIssueOfECC
        }
        props.step1Call(data)
        // dispatch(setProgress(completion))
        setTabIndex(tabIndex + 1)
    }

    const handleSubmit7 = (e) => {
        // e.preventDefault();
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)

        const data = {
            "profile_percentage": completion,
            "user_id": gData.id,
            "disposal_waste_type": FormState.typeOfSp?.slice(0, 200)==null||  FormState.typeOfSp?.slice(0, 200)==''  ? 'null' :FormState.typeOfSp?.slice(0, 200),
            "disposal_waste_name": FormState.disposerName?.slice(0, 200)==null||  FormState.disposerName?.slice(0, 200)==''  ? 'null' : FormState.disposerName?.slice(0, 200),
            "disposal_waste_address": FormState.disAddress?.slice(0, 200)==null||  FormState.disAddress?.slice(0, 200)==''  ? 'null' : FormState.disAddress?.slice(0, 200),
            "disposal_waste_method": FormState.disMethod?.slice(0, 200)==null||  FormState.disMethod?.slice(0, 200)==''  ? 'null' : FormState.disMethod?.slice(0, 200),
            "declaration_signature": FormState.declare
        }
        props.step1Call(data)
        // dispatch(setProgress(completion))
        swal(
            "Successfull",
            "Profile has been updated successfully",
            "success"
        ).then((ok) => {
            if (ok) {
                navigate(role.role == '1' ? '/admin/summary' : '/dashboard/tracker')
            }
        })
        console.log(FormState.ecc)
    }


    useEffect(() => {
        console.log('got daat=================', props.sendStartData)
   
      

        if(props.sendStartData != "" && props.sendStartData != null){



            setFormState({
                ...FormState,
                typeOfProfile: props.sendStartData?.profile_type,
                typeOfWaste: props.sendStartData?.waste_type})

        }



        if (props.sendAreaData != "" && props.sendAreaData != null) {
            console.log('d',props.sendAreaData)

            // setFormState({
            //     ...FormState,
            //     typeOfProfile: obj.profile_type,
            //     typeOfWaste: obj.waste_type})

            console.log('areaData', props.sendAreaData)
            let flatLevel = [];

            props.sendAreaData.forEach((item) => {
                item.areaSubData = [];

                flatLevel.push(item);
            });

            console.log("level1", flatLevel);
            const data = setLevelData(flatLevel);
            const levelCount = getHowManyLevel(data);

            setParentAreaData(data);
        }


    
      

    }, [props])

    useEffect(()=> {
        
        console.log("Sudhanshu")

        updateProfileData(props.profileData,   props.sendAreaData);

    }, [props.profileData,   props.sendAreaData])



    const profileType = [
        "Generator",
        "Collector",
        "Recycler",
        "Importer/Exporter",
        "Disposer",
        "Reclaimer"
    ]

    // sourceName: '',
    //         sourceContact: '',
    //         process: '',
    //         dateOfIssueOfECC: '',
    //         wasteCapicity: '',
    //         certificate: '',

    //         // step 5

    //         wasteType: '',
    //         physicalState: '',
    //         byCapacity: '',
    //         spwProcess: '',
    //         spwType: '',
    //         quantity: '',
    //         package: '',
    //         vcr: '',
    //         wcd: '',
    //         transportFrepositionquency: '',
    //         humanHealth: '',
    //         wasteMoved: '',
    //         emergencyResponse: '',

    //         // step 6
    //         dumpSiteName: '',
    //         dumpAddress: '',
    //         dateOfIssue: '',
    //         fileIssueOfECC: '',

    //         // step 7

    //         typeOfSp: '',
    //         disposerName: '',
    //         disAddress: '',
    //         disMethod: '',
    //         ecc: false,

    const updateCompletion = () => {
        let completion = 0;
        let progressValue = 2.77777777778;

        // All Form Tabs Included:
        const stepKeys = ['companyName', 'address', 'contactPerson', 'positionStep1', 'phone', 'dateValue', 'fax',  'cin', 'parentCompany', 'details', 'fullName', 'positionStep2', 'experience', 'refName', 'refContact', 'sourceName', 'sourceContact', 'process', 'wasteCapicity', 'wasteType', 'physicalState', 'byCapacity', 'spwProcess', 'spwType', 'quantity', 'package', 'vcr', 'wcd', 'transportFrequency', 'humanHealth', 'wasteMoved', 'dumpSiteName', 'dumpAddress', 'typeOfSp', 'disposerName', 'disAddress', 'disMethod', 'AspwType'];
        stepKeys.forEach(key => {
            if(FormState[key]!='' && FormState[key]!=null && FormState[key]!='null'){
                console.log('checkvals',key + ':'+ typeof FormState[key]=='string'? FormState[key].trim(): FormState[key])
                if (FormState[key] && typeof FormState[key]=='string'? FormState[key].trim(): FormState[key] !== '' && FormState[key]!== null){
                    console.log()
                   return  completion += progressValue;

                } 
            }
            
        });


        setCompletion(completion);
       
        props.sendCompletion(completion);
        // dispatch(setProgress(completion))
    };


    // ===================================================================== Area Dropdown Start
    const [showList, setAreaListShow] = useState(false);
    const [showDrop, setParentList] = useState(false);
    const [callData, setParentAreaData] = useState([]);


    const handleArea = (event) => {
        setFormState({ ...FormState, [event.target.name]: event.target.value });
        this.setState((state) => {
            return {
                open: !state.open,
            };
        });
    };
    const getHowManyLevel = (obj) => {
        let res = JSON.stringify(obj).replace(/[^{|^}]/g, "");
        while (/}{/g.test(res)) {
            res = res.replace(/}{/g, "");
        }
        return res.replace(/}/g, "").length;
    };
    const setLevelData = (flat) => {
        var roots = [];

        var all = {};

        flat.forEach(function (item) {
            all[item.area_code] = item;
        });
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code];
            if (item.parent_id === null || item.parent_id === "") {
                roots.push(item);
            } else if (item.parent_id in all) {
                var p = all[item.parent_id];
                if (!("areaSubData" in p)) {
                    p.areaSubData = [];
                }
                p.areaSubData.push(item);
            }
        });

        return roots;
    };
    const state = {
        open: false,
    };
    const renderTree = (nodes) =>
        nodes.map((item) => {
            return (
                <>
                    <TreeItem
                        className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
                        key={item.area_code}
                        nodeId={item._id}
                        label={item.name}
                        onClick={() => {
                            setFormState({
                                ...FormState,
                                pareaCode: item.area_code,
                                pareaName: item.name,
                                plevel: item.level + 1,
                                pareaID: item._id,
                            });
                        }}
                    >
                        {Array.isArray(item.areaSubData) && item.areaSubData != null
                            ? renderTree(item.areaSubData)
                            : null}
                    </TreeItem>
                </>
            );
        });


    // ===================================================================== Area Dropdown End

    useEffect(()=>{
        updateCompletion();
    }, [FormState, dateValue])

    // useLayoutEffect(() => {
    //     updateCompletion();
    // }, [FormState])




    const  goToProfile = () =>{


        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        const proData = [];
        if(isArray(FormState.typeOfProfile)){
            FormState.typeOfProfile.forEach(item=>{

                if(item!=null){

                    proData.push(item)
                }

            })
        }
         
            const data = {
                user_id:gData.id,
                profile_type:proData,
                waste_type:FormState.typeOfWaste
            }
    
             if(FormState.typeOfProfile==null || FormState.typeOfProfile=='' && FormState.typeOfWaste=='' ||   FormState.typeOfWaste==null ){
                setInvalid('<p style="color:red">Please select profile types.</p>')
                setInvalid2('<p style="color:red">Please select a waste type.</p>')
                return;
            }
            else if(FormState.typeOfProfile==null || FormState.typeOfProfile=='' && FormState.typeOfWaste!='' ){
                setInvalid('<p style="color:red">Please select profile types.</p>')
                setInvalid2('')
                return;
            }
            else if(FormState.typeOfWaste==null || FormState.typeOfWaste=='' && FormState.typeOfProfile!=''){
                setInvalid2('<p style="color:red">Please select a waste type.</p>')
                setInvalid('');
                return;
            }
            else{
    
            
           
                props.step1Call(data)
               
                // console.log('adding',data);
    
                setInvalid('');
                setInvalid2('')
                // props.startData(data)
            }
    
    
        }



const updateProfileData =(data,areaData) =>{
    if (data != '' && data != null) {


        const obj = data[1];
        const obj2 = data[0];

        const dataAr = [];
        areaData?.forEach(item=>{
            if(item.area_code==obj2?.area?.[0]){
                    dataAr.push(item)
            }
        })

        console.log('area====', dataAr)

        if (obj2 != null) {
           
            


            if (obj.has_the_applicant != '' && obj.has_the_applicant != null) {
                setShowTextBox(true)
                console.log('mohit', showTextBox)
            }

            var DorDate = dayjs(obj2.date_of_registration)
            var specDate = dayjs(obj.special_waste_date_of_issue)
            var dt3 = dayjs(obj.disposal_treatment_license)
            var dt3 = dayjs(obj.disposal_treatment_date_issue_of_ECC)
            setdateValue(DorDate.$d)
            setdateValue1(specDate.$d)
            setdateValue2(dt3.$d)
            setdateValue3(dt3.$d)
            setFormState({
                ...FormState,
                declare: parseInt(obj2.declaration_signature),
                typeOfProfile: obj.profile_type,
                typeOfWaste: obj.waste_type,
                companyName: obj.company_name,
                address: obj.address,
                contactPerson: obj.contact_person,
                positionStep1: obj.position,
                phone: JSON.stringify(obj.phone)== '0' ? '':JSON.stringify(obj.phone),
                fax: JSON.stringify(obj.fax)== '0' ? "": JSON.stringify(obj.fax),
                cin: obj.company_registration_no,
                area:obj.area?.[0],
                cor: obj.company_of_registration,
                areaId:obj.area?.[0],
                // //Step 2:
                parentCompany: obj.has_the_applicant,
                details: obj.has_the_applicant_answer,

                // //Step 3:
                fullName: obj.tc_name,
                positionStep2: obj.tc_position,
                experience: obj.tc_qualifying_experience == 0 ? '': obj.tc_qualifying_experience,
                refName: obj.tc_name_refree,
                refContact: obj.tc_contact_referee == 0 ? '' : obj.tc_contact_referee,

                // // Step 4:
                sourceName: obj.special_waste_name,
                sourceContact:obj.special_waste_contact== 0 || obj.special_waste_contact== null ? '': parseInt(obj.special_waste_contact),
                process: obj.special_waste_process_type,
     
                wasteCapicity:JSON.stringify(obj.special_waste_quantity) == '0' ? '' : JSON.stringify(obj.special_waste_quantity),
               

                // //Step 5:
                AspwType:obj.transportation_waste_type,
                physicalState: obj.transportation_waste_physical_state,
                byCapacity: JSON.stringify(obj.transportation_waste_volume) == '0' ? '' : JSON.stringify(obj.transportation_waste_volume),
                spwProcess: obj.transportation_waste_transport_mode,
                spwType: obj.transportation_waste_monthly_type,
                quantity: JSON.stringify(obj.transportation_waste_monthly_quantity) == '0' ? '': JSON.stringify(obj.transportation_waste_monthly_quantity),
                package: obj.transportation_waste_monthly_packaging,
                vcr: obj.transportation_waste_monthly_vehicle_registration == 0 ? '' : obj.transportation_waste_monthly_vehicle_registration,
                wcd: obj.transportation_waste_monthly_description == 0 ? '' : obj.transportation_waste_monthly_description ,
                transportFrequency: obj.transportation_waste_monthly_frequency == 0 ? '' : obj.transportation_waste_monthly_frequency,
                humanHealth: obj.transportation_waste_monthly_humanHealth == 0 ? '' : obj.transportation_waste_monthly_humanHealth,
                wasteMoved: obj.transportation_waste_monthly_step_moved == 0 ? '' : obj.transportation_waste_monthly_step_moved,
                emergencyResponse: obj.transportation_waste_monthly_response_plan,

                // //Step 6:
                dumpSiteName: obj.disposal_treatment_name == 0 ? '' : obj.disposal_treatment_name,
                dumpAddress: obj.disposal_treatment_address == 0 ? '' : obj.disposal_treatment_address,

                fileIssueOfECC: obj.disposal_treatment_ECC_file,

                // //Step 7:
                typeOfSp: obj.disposal_waste_type == 0 ? '' : obj.disposal_waste_type,
                disposerName: obj.disposal_waste_name == 0 ? '' : obj.disposal_waste_name,
                disAddress: obj.disposal_waste_address == 0 ? '' : obj.disposal_waste_address,
                disMethod: obj.disposal_waste_method == 0 ? '' :obj.disposal_waste_method  ,




                pareaName:dataAr[0]?.name
            });

            
      

        }



    }
}



    const checkkk =(e)=>{
        console.log("eee", e.target.value);
    }
    return (
        <>

            <div className='profileInit pi_profiledrop'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style">
                                <div className="mat-form-field-wrapper">
                                    <span>Profile Type</span>
                                    <Select
                                        id="profileType"
                                        name='profileType'
                                        multiple
                                        value={FormState.typeOfProfile}
                                       
                                        className='profile-select'
                                        onChange={(e)=>{handleMultiSelectChange(e)}}
                                    >
                                        {profileType.map((profileType) => (
                                            <MenuItem
                                                key={profileType}
                                                value={profileType}
                                                className="multiselectCheckbox"
                                            >
                                                {profileType}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <div dangerouslySetInnerHTML={{__html: invalid? invalid:''}}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style">
                                <div className="mat-form-field-wrapper">
                                    <span>Waste Type</span>
                                    <Select
                                        className='profile-select'
                                        name='typeOfWaste'
                                        value={FormState.typeOfWaste}
                                        onChange={textChange}
                                    >
                                        <MenuItem value={"Solid"}>Solid</MenuItem>
                                        <MenuItem value={"Hazardous"}>Hazardous</MenuItem>
                                    </Select>
                                    <div  dangerouslySetInnerHTML={{__html: invalid2? invalid2:''}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='align-content-end col-md-2 d-flex flex-wrap'>
                            <button type='submit' onClick={goToProfile} className=''> Go </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='custom_container'>
                <div className='row myProfile'>
                    <div className='col-md-12'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='profile-text'>My Profile <span>(as a Special Waste Carrier)</span></h1>
                            <div className='progressBar'>
                                <div className='progress_number'>{`${completion.toFixed(1)}%`}</div>
                                <ProgressBar now={completion.toFixed(1)} max={100} />
                                <p class="mb-0 update-progress">Update in Progress</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row profile-tab1'>
                    <div className='col-md-12'>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className='profile_tl'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs orientation="vertical" value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab  label={<div className='label-text'><span>1</span>Authorized contact</div>} {...a11yProps(0)} />
                                        <Tab  label={<div className='label-text'><span>2</span>The Applicant</div>} {...a11yProps(1)} />
                                        <Tab label={<div className='label-text'><span>3</span>Technical Competence</div>} {...a11yProps(2)} />
                                        <Tab  label={<div className='label-text'><span>4</span>The Premises</div>} {...a11yProps(3)} />
                                        <Tab  label={<div className='label-text'><span>5</span>Generation, Handling and Storage</div>} {...a11yProps(4)} />
                                        <Tab   label={<div className='label-text'><span>6</span>Details</div>} {...a11yProps(5)} />
                                        <Tab  label={<div className='label-text'><span>7</span>Reclamation and Recycling</div>} {...a11yProps(6)} />
                                        {/* <Tab  label={<div className='label-text'><span>8</span>Treatment</div>} {...a11yProps(7)} />
                                        <Tab  label={<div className='label-text'><span>9</span>Transportation</div>} {...a11yProps(8)} />
                                        <Tab  label={<div className='label-text'><span>10</span>Disposal Site</div>} {...a11yProps(9)} /> */}
                                    </Tabs>
                                </Box>
                            </div>
                            <TabPanel value={tabIndex} index={0}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>

                                                <div className='mp_form'>
                                                    <div className='col-12'>
                                                        <h2>Authorized contact</h2>
                                                    </div>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit1} id="addUserForm">
                                                            <Stack className="w-100">
                                                                {/* <div className="row mb-30">
                                                                    <div className='col-12'>
                                                                        <p className='tab-title'>Please give details of the person we can phone or write to with questions about this application.</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Profile Type</span>
                                                                                <Select
                                                                                    id="profileType"
                                                                                    name='profileType'
                                                                                    multiple
                                                                                    value={typeOfProfile}
                                                                                    defaultValue={typeOfProfile}
                                                                            
                                                                                    onChange={handleMultiSelectChange}
                                                                                >
                                                                                    {profileType.map((profileType) => (
                                                                                        <MenuItem
                                                                                            key={profileType}
                                                                                            value={profileType}
                                                                                        
                                                                                        >
                                                                                            {profileType}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Waste Type</span>
                                                                                <Select
                                                                                    name='typeOfWaste'
                                                                                    value={FormState.typeOfWaste}
                                                                                    onChange={textChange}
                                                                                >
                                                                                    <MenuItem value={"Solid"}>Solid</MenuItem>
                                                                                    <MenuItem value={"Hazardous"}>Hazardous</MenuItem>
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Company Name</span>
                                                                                <TextValidator
                                                                                    onChange={textChange}
                                                                                    value={FormState.companyName?.slice(0, 200)} autoComplete="off"
                                                                                    className='w-100' name="companyName"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Address</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    className='w-100'
                                                                                    multiline rows={3}
                                                                                    name="address"
                                                                                    onChange={textChange}
                                                                                    value={FormState.address?.slice(0, 200)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Contact Person</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    className='w-100'
                                                                                    name="contactPerson"
                                                                                    onChange={textChange}
                                                                                    value={FormState.contactPerson?.slice(0, 200)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Position</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    className='w-100'
                                                                                    name="positionStep1"
                                                                                    onChange={textChange}
                                                                                    value={FormState.positionStep1?.slice(0, 200)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Phone</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    type='number'
                                                                                    className='w-100'
                                                                                    name="phone"
                                                                                    min="0"
                                                                                    maxlength='10'
                                                                                    onChange={textChange}
                                                                                    value={FormState.phone?.slice(0, 10)}

                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Fax</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    type='number'
                                                                                    className='w-100'
                                                                                    min="0"
                                                                                    name="fax"
                                                                                    onChange={textChange}
                                                                                    value={FormState.fax?.slice(0, 10)}
                                                                                   />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Company Registration Number</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    type='number'
                                                                                    className='w-100'
                                                                                    name="cin"
                                                                                    min="0"
                                                                                    onChange={textChange}
                                                                                    value={FormState.cin}
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Date of Registration</span>
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <Stack spacing={3}>
                                                                                        <DesktopDatePicker
                                                                                            inputFormat="DD/MM/YYYY"
                                                                                            value={dateValue}
                                                                                            maxDate={new Date()}
                                                                                            onChange={datepicker}
                                                                                            renderInput={(params) => <TextField {...params}
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    placeholder: "DD/MM/YYYY"
                                                                                                }}
                                                                                            />}
                                                                                        />
                                                                                    </Stack>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style ifs_dropdown">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Select Area</span>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                                                        <TextValidator
                                                                                            className=" w-100"
                                                                                            label=""
                                                                                            onChange={handleArea}
                                                                                            name="pareaName"
                                                                                            value={FormState.pareaName}
                                                                                        />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <TreeView
                                                                                            aria-label="file system navigator"
                                                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                                                            sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                                                        >
                                                                                            {renderTree(callData)}
                                                                                        </TreeView>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Country of Registration</span>
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    className='w-100'
                                                                                    name="cor"
                                                                                    disabled={true}
                                                                                    onChange={textChange}
                                                                                    value={FormState.cor?.slice(0, 50)}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button id='exitBtn' onClick={saveAndExit} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button id='nameBtn' variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile 1<span>(as a Special Waste Carrier)</span></h1>
                                              
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Previous Applications</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit2} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'>Has the applicant, their parent company, or any officers of the applicant</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 radioform_style ">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <FormControl>

                                                                                    <RadioGroup
                                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                                        defaultValue="managementlicenserefused"
                                                                                        name="parentCompany"

                                                                                        value={FormState.parentCompany}
                                                                                        onChange={e => { textChange(e); handleCheckBoxChange(e); }}
                                                                                    >
                                                                                        <FormControlLabel value="YES" control={<Radio />}
                                                                                            checked={FormState.parentCompany === 'YES' ? 'checked' : ''}
                                                                                            label="had an application for a waste management license / special waste management license refused?" />
                                                                                        <FormControlLabel checked={FormState.parentCompany === 'NO' ? 'checked' : ''} value="NO" control={<Radio />}
                                                                                            label="had a waste management license / special waste management license revoked?" />
                                                                                    </RadioGroup>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {showTextBox &&
                                                                    <div className="row mb-30">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group mb-0 inputform_style">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Please give details such as who applied, any license or registration number (where appropriate), and when it was rejected, refused or revoked.</span>
                                                                                    <TextValidator value={FormState.details} onChange={textChange} autoComplete="off" className='w-100' multiline rows={3} name="details" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit2} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile 2<span>(as a Special Waste Carrier)</span></h1>
                                                   
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Technical Competence</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit3} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'>Please give details of who will be responsible for the handling and packaging of the special waste at the Premises.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Full Name</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.fullName?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="fullName"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Position</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.positionStep2?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="positionStep2"
                                                                                     />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Qualifying Experience</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                 
                                                                                    value={FormState.experience}
                                                                                    onChange={textChange}
                                                                                    name="experience"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Name of referee</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.refName?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="refName"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Contact of referee</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.refContact}
                                                                                    onChange={textChange}
                                                                                    name="refContact"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className='mf_addbtn align-items-center'>
                                                                            <img src='assets/lib/images/icon/plus.svg' /> <p className='mp_paragraph mb-0'>Add detail of responsible person for the handling and packaging of the special waste</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit3} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile <span>(as a Special Waste Carrier)</span></h1>
                                                  
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Sources of Special Wastes</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit4} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(A)</span>Generation of waste</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Name of generator of special waste</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.sourceName?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="sourceName"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Contact of generator of special waste</span>
                                                                                <TextValidator type="number" autoComplete="off"
                                                                                    value={FormState.sourceContact}
                                                                                    min="0"
                                                                                    onChange={textChange}
                                                                                    className='w-100' name="sourceContact"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Process of special waste generated</span>
                                                                                {/* <FormControl fullWidth>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            onChange={handledrop}
                                                            >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.process?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="process"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Waste quantity generated/month (Tonne/s)</span>
                                                                                {/* <Slider
                                                            aria-label="Always visible"
                                                            defaultValue={45}
                                                            getAriaValueText={valuetext}
                                                            // step={10}
                                                            // marks={marks}
                                                            valueLabelDisplay="on"
                                                        /> */}
                                                                                <TextValidator
                                                                                    autoComplete="off"
                                                                                    type='number'
                                                                                    className='w-100'
                                                                                    min="0"
                                                                                    value={FormState.wasteCapicity?.slice(0, 100)}
                                                                                    onChange={textChange}
                                                                                    name="wasteCapicity" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    <div className="col-md-12">
                                                                        <div className='mf_addbtn align-items-center'>
                                                                            <img src='assets/lib/images/icon/plus.svg' /> <p className='mp_paragraph mb-0'>Add detail of generation of waste</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(B)</span> Date of issue of Environmental Compliance Certificate (ECC) of Waste</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <Stack spacing={3}>
                                                                                        <DesktopDatePicker
                                                                                            inputFormat="DD/MM/YYYY"
                                                                                            value={dateValue1}
                                                                                            onChange={datepicker1}
                                                                                            maxDate={new Date()}
                                                                                            placeholder="DD/MM/YYYY"
                                                                                            renderInput={(params) => <TextField {...params}
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    placeholder: "DD/MM/YYYY"
                                                                                                }}
                                                                                            />}
                                                                                        />
                                                                                    </Stack>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator type='file' autoComplete="off" className='w-100' name="certificate"
                                                                                    value={FormState.certificate}
                                                                                    onChange={EccChange}

                                                                                />
                                                                                <small>Max file size 5MB. Format Docx,PDF</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit4} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={4}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile <span>(as a Special Waste Carrier)</span></h1>
                                                  
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Transportation of Waste</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit5} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(A)</span>Types of special waste to be transported</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Special waste type</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.AspwType?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="AspwType" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Physical state</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.physicalState?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="physicalState" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Waste quantity generated/month (Tonne/s)</span>
                                                                                <TextValidator type='number' autoComplete="off" className='w-100'
                                                                                min="0"
                                                                                    value={FormState.byCapacity?.slice(0, 100)}
                                                                                    onChange={textChange}
                                                                                    name="byCapacity" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'><span className='mpp_absolute'>(B)</span>Mode of transport</p>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30 pb-3">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        name='spwProcess'
                                                                                        value={FormState.spwProcess}
                                                                                        onChange={textChange}
                                                                                    >
                                                                                        <MenuItem value={'Truck'}>Truck</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(C)</span> Quantities to be transported monthly</p>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Special waste type</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.spwType?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="spwType" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Waste quantity (Tonne/s)</span>
                                                                                <TextValidator type='number' 
                                                                                min="0"
                                                                                autoComplete="off"
                                                                                    value={FormState.quantity?.slice(0, 100)}
                                                                                    onChange={textChange}
                                                                                    name="quantity"
                                                                                    className='w-100' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Packaging</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.package?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="package" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Vehicle registration</span>
                                                                                <TextValidator type='number'
                                                                                min="0"
                                                                                autoComplete="off" className='w-100'
                                                                                    value={FormState.vcr?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="vcr" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Waste carrier description</span>
                                                                                <TextValidator autoComplete="off"
                                                                                    value={FormState.wcd?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    className='w-100' multiline rows={3} name="wcd" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className='mf_addbtn align-items-center'>
                                                                            <img src='assets/lib/images/icon/plus.svg' /> <p className='mp_paragraph mb-0'>Add detail of quantities of waste transported monthly</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-3">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'><span className='mpp_absolute'>(D)</span>Frequency of special waste transportation</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator autoComplete="off"
                                                                                    value={FormState.transportFrequency}
                                                                                    onChange={textChange}
                                                                                    className='w-100' multiline rows={3} name="transportFrequency" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'><span className='mpp_absolute'>(E)</span>What precautions are/will be take to prevent harm to the environment or to human health?</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator autoComplete="off"
                                                                                    value={FormState.humanHealth?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    className='w-100' multiline rows={3} name="humanHealth" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'><span className='mpp_absolute'>(F)</span>How is/will the waste be moved/handled on the Premises and what precautions are/will be taken to prevent harm to the environment or to human health?</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator autoComplete="off"
                                                                                    value={FormState.wasteMoved?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    className='w-100' multiline rows={3} name="wasteMoved" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph mb-11'><span className='mpp_absolute'>(G)</span>Please attach an emergency response plan to ensure that any emergency will be well prepared for.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator type='file' autoComplete="off" className='w-100'
                                                                                    onChange={emerFile}
                                                                                    value={FormState.emergencyResponse}
                                                                                    name="emergencyResponse" />
                                                                                <small>Max file size 5MB. Format Docx,PDF</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit5} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={5}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile <span>(as a Special Waste Carrier)</span></h1>
                                                  
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Disposal and Treatment of Special waste</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit6} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span className='position-relative'><span className='mpp_absolute'>(A)</span> Name of dump site where waste will be disposed/ recycled</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.dumpSiteName?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    name="dumpSiteName" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Address of dump site where waste will be disposed/ recycled</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.dumpAddress?.slice(0, 250)}
                                                                                    onChange={textChange}
                                                                                    multiline rows={3} name="dumpAddress" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span className='position-relative'><span className='mpp_absolute'>(B)</span> Date of issue of special waste management license</span>
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <Stack spacing={3}>
                                                                                        <DesktopDatePicker
                                                                                            inputFormat="DD/MM/YYYY"
                                                                                            value={dateValue2}
                                                                                            onChange={datepicker2}
                                                                                            placeholder="DD/MM/YYYY"
                                                                                            maxDate={new Date()}
                                                                                            renderInput={(params) => <TextField {...params}
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    placeholder: "DD/MM/YYYY"
                                                                                                }}
                                                                                            />}
                                                                                        />
                                                                                    </Stack>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(C)</span>Date of Issue of ECC for Disposal Site/ Recycling Facility</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style datePicker">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <Stack spacing={3}>
                                                                                        <DesktopDatePicker
                                                                                            inputFormat="DD/MM/YYYY"
                                                                                            value={dateValue3}
                                                                                            onChange={datepicker3}
                                                                                            placeholder="DD/MM/YYYY"
                                                                                            maxDate={new Date()}
                                                                                            renderInput={(params) => <TextField {...params}
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    placeholder: "DD/MM/YYYY"
                                                                                                }}
                                                                                            />}
                                                                                        />
                                                                                    </Stack>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextValidator type='file' autoComplete="off" className='w-100'
                                                                                    onChange={textChange}
                                                                                    value={FormState.fileIssueOfECC}
                                                                                    name="fileIssueOfECC" />
                                                                                <small>Max file size 5MB. Format Docx,PDF</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit6} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={6}>
                                <div className='my_rofile'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h1>My Profile <span>(as a Special Waste Carrier)</span></h1>
                                                  
                                                </div>
                                                <div className='mp_form'>
                                                    <h2>Disposal of Special Waste</h2>
                                                    <div className='w-100 px-110'>
                                                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit7} id="addUserForm">
                                                            <Stack className="w-100">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className='mp_paragraph'><span className='mpp_absolute'>(A)</span>Disposal of waste type</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Type of special waste</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.typeOfSp?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="typeOfSp"
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Name of disposer</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.disposerName?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="disposerName" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Address</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.disAddress?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    multiline rows={3} name="disAddress" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Disposal/ recycling method</span>
                                                                                <TextValidator autoComplete="off" className='w-100'
                                                                                    value={FormState.disMethod?.slice(0, 200)}
                                                                                    onChange={textChange}
                                                                                    name="disMethod"
                                                                                     />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className='mf_addbtn align-items-center'>
                                                                            <img src='assets/lib/images/icon/plus.svg' /> <p className='mp_paragraph mb-0'>Add detail of disposal of special waste</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-30">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-0 inputform_style declaration">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <ul>
                                                                                    <li><span className='mpp_absolute'>(B)</span><Checkbox {...label}
                                                                                        value={FormState.declare}
                                                                                        name='declare'
                                                                                      
                                                                                        checked={FormState.declare==1? true: false}
                                                                                        onChange={declarationChange} /> 
                                                                                        
                                                                                        <p><strong>Declaration:</strong> Anyone who intentionally or negligently makes a statement that is false or misleading in a material particular for the purpose of obtaining the granting or variation of a special waste management licence, commits an offence.</p></li>

                                                                                    <li><p>I/we certify that the information in this application is correct.</p></li>

                                                                                    <li><p>I/we hereby apply for a special waste management licence in respect of the particulars described in this application (including the supporting documentation).</p></li>

                                                                                    <li><p>The application fee of 530 is enclosed.</p></li>

                                                                                    <li><p>Cheque should be made payable to the Swaziland Environment Authority.</p></li>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="row mb-30">
                                                                    <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                        <div className="form-group mb-0 exit_btn exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button onClick={saveAndExit7} variant="contained" className=''>Save & Exit <img src='assets/lib/images/home/arrow_right2.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-0 save_next exit_save">
                                                                            <div className="mat-form-field-wrapper text-center">
                                                                                <Button variant="contained" type="submit">Next <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </Stack>
                                                        </ValidatorForm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}
