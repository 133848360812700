import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaName:"",
  disaterName:"",
  disastercode:""
}

const areaSliceName = createSlice({
    name: 'areaName',
    initialState,
    reducers:{
        setAreaName(state, action) {
            state.areaName = action.payload;
        },
        setDisasterTypeName(state, action) {
            state.disaterName=action.payload;
        },
        setDisasterTypeCode(state, action) {
            state.disastercode=action.payload
        }
    }
});

export const { setAreaName,setDisasterTypeName,setDisasterTypeCode } = areaSliceName.actions;

export default areaSliceName.reducer;