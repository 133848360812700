import React,  {useState} from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FixedHeaderStory from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Table from 'react-bootstrap/Table';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function AddCalculation() {
   
    const managecolumns = [
        {
            name: '#',
            width: "150px", 
            selector: row => row.question,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.Description,
            sortable: true,
        },    
        {
            name: 'Type',
            width: "200px", 
            selector: row => row.Type,
            sortable: true,
        },    
    
    
        {
            name: 'Creation Date',
            width: "150px", 
            selector: row => row.CreationDate,
            sortable: true,
        },  
        {
            name: 'Indicator-Unit-Subgroup',
            selector: row => row.IndicatorUnitSubgroup,
            sortable: true,
        }, 
        {
            name: 'Action',
            width: "200px", 
            cell: row =>
                <>
                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item"  href="javascript:void(0);" data-toggle="modal" data-target="#editsection">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                        </div>
                    </div>
                </>,
                sortable: false,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true
            
        },

    ];

    const managedata = [
        {
            id: 1,
            question: '1',
            section: 'Personal Details',
            Description: 'Members',
            Type: 'Aggregate',
            CreationDate: '16 Jan 2023 ',
            IndicatorUnitSubgroup: 'Cases falling under Dowry Prohibition Act | Number|Total',
        },
        {
            id: 2,
            question: '2',
            section: 'Personal Details',
            Description: 'Members',
            Type: 'Aggregate',
            CreationDate: '18 Jan 2023 ',
            IndicatorUnitSubgroup: 'Cases falling under Dowry Prohibition Act | Number|Total',
        }
    ]

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
      const names = [
        'JPG',
        'PNG',
      ];
      
// ======================================================= Dropdown Start
const [format, setFormat] = useState('');
const [year, setData] = useState('2023');
const [month, setMonth] = useState('01');
const [day, setDay] = useState('01');
const [type, setType] = useState('');
// ======================================================= Dropdown End


// ======================================================= Multiselect Dropdown Start

const [personName, setPersonName] = useState([]);

  const handleDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const [age, setAge] = useState('');

  const handleSelect = (event) => {
    setAge(event.target.value);
  };

  const [subgroup, subGroup] = useState('');
  const handleSelect1 = (event) => {
    subGroup(event.target.value);
  };
// ======================================================= Multiselect Dropdown End
    return (
        <>
            
            <div class="modal fade timePeriodPopup dataentryModal" id="addcalculation_popup" tabindex="-1" role="dialog" aria-labelledby="assignusersCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                    <div class="summary_head">
                            <h5 class="modal-title" id="dataentryTitle">Data Entry | <span>Covid Vaccinations</span></h5>
                            <p  class="date mb-0 p-0">Date ( 27 Jan 2023 )</p>
                        </div>  
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body pb-0 px-5">

                        <div className="row align-items-end">
                            <div className="col-md-12">
                                <div className="questionTable ms_table calculation_table">
                                    <div className="pb-3">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <div class="tables_list_data">
                                                    <img  src="assets/lib/images/manage-form/dlt.svg" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 ml-auto">
                                                <div className="searchbar-icon mr-2">
                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                                    <input type="search" autoComplete="off" className="inpuut" placeholder="Search..." />                                
                                                </div>
                                                <div class="qt_Filter extra_popup_add" data-toggle="modal" data-target="#addaggregate">
                                                    <img src="assets/lib/images/svg/add1.svg"></img>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FixedHeaderStory
                                        fixedHeader
                                        fixedHeaderScrollHeight="300px"
                                        columns={managecolumns}
                                        data={managedata}
                                        selectableRows pagination
                                        noDataComponent="No Data"
                                    />
                                </div>                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                                <Button variant="contained" className="mb-0 text-capitalize" data-dismiss="modal"> Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                        </div>                        
                    </div>
                    </div>
                </div>
            </div>



                {/* =============================================== Add Question Start ================================================== */}
                <div class="modal fade timePeriodPopup selectArea addaggregate" id="addaggregate" tabindex="-1" role="dialog" aria-labelledby="addquestionCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addquestionTitle">Add Aggregate</h5>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body pb-0">
                                <div className="add_q_form">

                                    <div className="row align-items-end mb-30">
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 inputform_style">
                                                <label>Description</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField id="outlined-basic" multiline rows={4} />                                        
                                                </div>
                                            </div>
                                        </div>        
                                    </div>

                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Time Period Format</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY')}}>YYYY</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY.MM')}}>YYYY.MM</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY.MM.DD')}}>YYYY.MM.DD</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY - YYYY')}}>YYYY - YYYY</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY.MM - YYYY.MM')}}>YYYY.MM - YYYY.MM</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setFormat('YYYY.MM.DD - YYYY.MM.DD')}}>YYYY.MM.DD - YYYY.MM.DD</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style datePicker">
                                                <label>Time Period</label>
                                                <div className="mat-form-field-wrapper" data-toggle="modal" data-target="#claculation_timeperiod">
                                                    <TextField id="outlined-basic" />                                          
                                                </div>
                                            </div>
                                        </div>          
                                    </div>
                                    <div className="row align-items-end mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Type</label>
                                                <div className="mat-form-field-wrapper">
                                                    <Dropdown>
                                                        <Dropdown.Toggle>{type}</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  onClick={(e)=>{setType('calculate')}}>Calculate</Dropdown.Item>
                                                            <Dropdown.Item  onClick={(e)=>{setType('aggregate')}}>Aggregate</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>                                        
                                                </div>
                                            </div>
                                        </div>  
                                    </div>

                                    <div id="grid" className="d-none">
                                        <div className="row align-items-end mb-30">
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <label>Column</label>
                                                    <div className="mat-form-field-wrapper d-flex align-items-center">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('1')}}>1</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('2')}}>2</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('3')}}>3</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('4')}}>4</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('5')}}>5</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <img className="arrowUp" data-toggle="modal" data-target="#columnheader" src="assets/lib/images/home/arrow_upp.svg" />                               
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <label>Row</label>
                                                    <div className="mat-form-field-wrapper d-flex align-items-center">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('1')}}>1</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('2')}}>2</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('3')}}>3</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('4')}}>4</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('5')}}>5</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>       
                                                        <img className="arrowUp" data-toggle="modal" data-target="#rowheader" src="assets/lib/images/home/arrow_upp.svg" />                                 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-30">
                                            <div className="col-md-12 areaTable">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>A</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>V</td>
                                                            <td><img className=""  data-toggle="modal" data-target="#addgridoptions" src="assets/lib/images/home/arrow_upp.svg" />  </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-end mb-3 d-none" id="image">
                                        <div className="col-md-8">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Hint</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField id="outlined-basic" />                                         
                                                </div>
                                            </div>
                                        </div>                                     
                                    </div>
                                    <div id="singleChoice" className="d-none">
                                        <div className="row align-items-end mb-3">
                                            <div className="col-md-8">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <label>Option</label>
                                                    <div className="mat-form-field-wrapper">
                                                        <TextField id="outlined-basic" />                                         
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-md-4">
                                                <div className="msBtn">
                                                    <Button variant="contained" className="mb-0 text-capitalize" data-toggle="modal" data-target="#managesection"> Manage Option</Button> 
                                                </div>
                                            </div>                                      
                                        </div>
                                        <div className="iusm_btn mb-30">                                    
                                            <div className="msBtn">
                                                <Button variant="contained" className="mb-0 text-capitalize" disabled data-toggle="modal" data-target="#indicatorunit"> Indicator - Unit - Subgroup Mapping </Button> 
                                                <img src="assets/lib/images/home/check_tick.svg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-end mb-30 d-none" id="number">
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style">
                                                <label>Default Value</label>
                                                <div className="mat-form-field-wrapper">                                       
                                                    <TextField type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style">
                                                <label>Minimum Value</label>
                                                <div className="mat-form-field-wrapper">                                       
                                                    <TextField type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style">
                                                <label>Maximum Value</label>
                                                <div className="mat-form-field-wrapper">                                       
                                                    <TextField type="number" />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>

                                    <div className="row mb-3 formula_area">
                                        <div className="col-md-6">
                                            <h5>Select Question</h5>

                                             <div className="calc_table border-color">
                                                  <Table>
                                                    <thead>
                                                        <tr>
                                                        <th>#</th>
                                                        <th className="popup_icon_drop d-flex align-items-center"> Question 
                                                 
                                                            <div className="btn-group">
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <img src="assets/lib/images/home/sorting.svg" />
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    <a class="dropdown-item" href="javascript:void(0)"> Sort A - Z </a>
                                                                    <a class="dropdown-item" href="javascript:void(0)"> Sort Z - A </a>
                                                                    <div className="areaSearch">                                        
                                                                        <input type="search" placeholder="Search..." />   
                                                                        <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                    </div>
                                                                    <a class="dropdown-item" href="javascript:void(0)">Number of Family members died in landslide</a>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <td  class="check_table"><span  className="chec_lable"><span  class="example-margin">1</span></span></td>
                                                        <td >How many members have in family</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                             </div>

                                        </div>

                                        <div className="col-md-6">
                                            <h5>Formula</h5>
                                            

                                          <div className="main_decimal_box">
                                            <div className="mat-form-field-wrapper">
                                                    <TextField className="border-none" id="outlined-basic" multiline rows={4} />                                        
                                            </div>

                                            <div className="decimal_box d-flex align-items-center justify-content-between py-2 px-2 btn_saveNext ">   
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Decimal Place</label>
                                                <div className="mat-form-field-wrapper">
                                                    <TextField type="number" />                                         
                                                </div>
                                            </div>

                                            {/* <TextField
                                            id="outlined-number"
                                            label="Decimal Place"
                                            type="number"
                                            InputLabelProps={{
                                            shrink: true,
                                            }}></TextField> */}

                                            <Button variant="contained" className="mb-0 text-capitalize top12" data-toggle="modal" data-target="#validate"> Validate</Button> 
                                            </div>
                                        </div>
                                             

                                        </div>

                                    </div>
                                    <div className="indicatorUnit border-none">
                                        <div className="col-md-12 p-0">
                                            <div className="row align-items-end mb-0">
                                                <div className="col-md-12">
                                                    <h4>Indicator - Unit - Subgroup Mapping</h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                                        <label>Indicator | Unit</label>
                                                        <div className="mat-form-field-wrapper">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="d_search">
                                                                    <TextField type="search" placeholder="Search..." />
                                                                    <img class="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                                                </div>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('Adolescent girls by category | Percent')}}>Adolescent girls by category | <span> Percent</span></Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('Breastfeeding children aged 6-23 months receiving adequate diet | Percent')}}>Breastfeeding children aged 6-23 months receiving adequate diet | <span> Percent</span></Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('YYYY.MM.DD')}}>Cases falling under Cyber Crimes/Information Technology Act | <span>Number</span></Dropdown.Item>                                                        
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                                        <label>Subgroup </label>
                                                        <div className="mat-form-field-wrapper">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>{format}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="d_search">
                                                                    <TextField type="search" placeholder="Search..." />
                                                                    <img class="search-imgs" src="assets/lib/images/manage-form/search.svg" />
                                                                </div>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('Total ')}}>Total </Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('Rural')}}>Rural</Dropdown.Item>
                                                                <Dropdown.Item  onClick={(e)=>{setFormat('Urban')}}>Urban</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row align-items-end mb-30">
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 inputform_style labelCheckbox">
                                                <FormControlLabel control={<Checkbox defaultChecked />} label="Mandatory Question" />
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            <div class="modal-footer">
                                <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                                        <Button variant="contained" className="mb-0 text-capitalize" data-dismiss="modal"> Create <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
            {/* ================================================ Add Question End =================================================== */}

            



                     {/* ================================================= Datepicker Modal Start ==================================================  */}                
                     <div className="modal fade timePeriodPopup " id="claculation_timeperiod" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLabel">Select Time Period</h5>                                
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row align-items-center mb-30" id="year">
                                    <div className="col-md-12">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Year</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div>          
                                </div>  
                                             
                                <div className="row align-items-center mb-30 d-none" id="yymm">
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Year</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Month</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div>         
                                </div>

                                <div className="row align-items-center mb-30 d-none" id="yymmdd">
                                    <div className="col-md-4">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Year</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-4">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Month</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div>     
                                    <div className="col-md-4">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <label>Day</label>
                                            <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle>{day}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('01')}}>01</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('02')}}>02</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('03')}}>03</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('04')}}>04</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('05')}}>05</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('06')}}>06</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('07')}}>07</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('08')}}>08</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('09')}}>09</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('10')}}>10</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('11')}}>11</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setDay('12')}}>12</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            </div>
                                        </div>
                                    </div>     
                                </div>                        

                                <div className="tpp_year d-none" id="fromto-yy">
                                    <h4>From</h4>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>          
                                    </div>
                                    <h4>To</h4>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>          
                                    </div>
                                </div>

                                <div className="tpp_yymm d-none" id="fromto-yymm">
                                    <h4>From</h4>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Month</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>         
                                    </div> 
                                    <h4>To</h4> 
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Month</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>         
                                    </div>                           
                                </div>

                                <div className="tpp_yymmdd d-none" id="fromto-yymmdd">
                                    <h4>From</h4>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Month</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>     
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Day</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{day}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>     
                                    </div>
                                    <h4>To</h4>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Year</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{year}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2020')}}>2020</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2021')}}>2021</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2022')}}>2022</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2023')}}>2023</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2024')}}>2024</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setData('2025')}}>2025</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Month</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{month}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setMonth('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>     
                                        <div className="col-md-4">
                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                <label>Day</label>
                                                <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle>{day}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('01')}}>01</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('02')}}>02</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('03')}}>03</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('04')}}>04</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('05')}}>05</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('06')}}>06</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('07')}}>07</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('08')}}>08</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('09')}}>09</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('10')}}>10</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('11')}}>11</Dropdown.Item>
                                                        <Dropdown.Item  onClick={(e)=>{setDay('12')}}>12</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                
                                                </div>
                                            </div>
                                        </div>     
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">                           
                                <div className="row align-items-center btn_saveNext justify-content-center mx-0">  
                                    <Button variant="contained" data-dismiss="modal">  Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                                </div>                    
                            </div>
                        </div>
                    </div>
                </div>
            {/* ================================================== Datepicker Modal End ===================================================  */}



                                    {/* ==========================validate popup start====================== */}
                                    <div class="modal fade timePeriodPopup validate" id="validate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Validate</h5>
                                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body text-center">
                                     <h5>Invalid formula!!</h5>
                                    </div>
                                    
                                    </div>
                                    </div>
                                    </div>
                                    {/* ==========================validate popup end====================== */}


                                    {/* =====================================close popup========================== */}
                                    <div class="modal fade timePeriodPopup validate" id="Undo_popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Undo</h5>
                                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body text-center">

                                      <div className="undo_content">
                                        <h3>Are you sure to undo ?</h3>
                                      </div>
                                      <div className="undo_btns">
                                      <button type="button" class="btn red" data-dismiss="modal">Yes</button>
                                        <button type="button" class="btn grey">No</button>   
                                      </div>

                                    </div>
                                    
                                    </div>
                                    </div>
                                    </div>
                                     {/* =====================================close popup end========================== */}
            
        </>
    )
    

}