import React, { Component, useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import datasearchcomp from "../../../Config/data-search-component.json";
import DataSearchResultComponent from "../data-search-results-component/index";
import $ from "jquery";

function dataSearchTemplate() {

  let sd = this.state.selIUSdata;

  return (
    <>
      <section className="ds_tab">
        <div className="container-fluid p-0 h-100">
          <div className="DataSearchTabs">
            <Tabs
              defaultActiveKey="viewdata"
              id="uncontrolled-tab-example"
              transition={false}
              activeKey={this.state.key}
              onSelect={this.handleSelect}
            >
              <Tab
                eventKey="disaster"
                title={
                  <>
                    <div className="dst_list">
                      <div className="dst_listContent">
                        {/* <span>STEP 1</span> */}
                        <span>
                          {datasearchcomp.dstlistContentstp1[this.props.lang]}
                        </span>

                        <h4>
                          {datasearchcomp.dstlistContenth4[this.props.lang]}
                        </h4>
                        {/* <h4>Disaster</h4> */}
                      </div>
                      <div className="dst_count">
                        {"*" in this.state.selDisaster
                          ? Object.keys(this.state.selDisaster)?.length - 1
                          : Object.keys(this.state.selDisaster)?.length}
                      </div>
                    </div>
                  </>
                }
                className="dstColumn"
              >
                <div className="disasterSearch">
                  <div className="dsInput">
                    <div className="dsSelectall">
                      <div className="d-flex align-items-center dataSearchFormList">
                        <input
                          type="checkbox"
                          name="disaster-select-all"
                          id="disaster-select-all"
                          onChange={(e) => this.selectDisaster(e, "*")}
                          checked={
                            "*" in this.state.selDisaster ? "checked" : ""
                          }
                        />
                        {/* <label for="disaster-select-all">Select All</label> */}
                        <label for="disaster-select-all"> {datasearchcomp.selectall[this.props.lang]}</label>

                      </div>
                    </div>
                    <input
                      type="search"
                      value={this.state.searchText}
                      onChange={(e) => this.onSearch(e.target.value)}
                      placeholder="Search..."
                      className="dsSearch"
                    />
                  </div>
                  {/* <button className='disasterReset' onClick={(e)=> this.resetSelection()}>
                                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                                    </button> */}
                </div>
                <div className="disasterTab">
                  <div className="disasterList">
                    <div className="disasterCheckbox">
                      {this.state.fDisasterData?.map((d) => (
                        <div
                          key={d._id}
                          className={this.isDisasterAvlbl(d.disaster_code)?"d-flex align-items-center dataSearchFormList":
                                                                          "disaster-disabled d-flex align-items-center dataSearchFormList"}
                        >
                          <input
                            type="checkbox"
                            name={d._id}
                            id={d._id}
                            onChange={(e) => this.selectDisaster(e, d)}
                            checked={
                              d._id in this.state.selDisaster ? "checked" : ""
                            }
                            disabled={!this.isDisasterAvlbl(d.disaster_code)}
                          />
                          <label for={d._id}>{ d.title?.[this.props.lang] } </label>
                        </div>
                      ))}
                        <div
                          key={'d-none'}
                          className="d-flex align-items-center dataSearchFormList"
                        >
                          <input
                            type="checkbox"
                            name='d-none'
                            id='d-none'
                            onChange={(e) => this.selectDisaster(e, 'none')}
                            checked={
                              'none' in this.state.selDisaster ? "checked" : ""
                            }
                          />
                          <label for='d-none'>
                          {/* Other */}
                          {/* Baseline Data */}
                          {datasearchcomp.Forms[3][this.props.lang]}
                          </label>
                        </div>
                    </div>
                    <div className="BtnForm">
                      <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />{" "}
                        {/* Reset */}
                        {datasearchcomp.btnReset[this.props.lang]} 
                      </button>
                      <button
                        className="nextBtn"
                        onClick={() => this.handletabSelect("stage")}
                        disabled={Object.entries(this.state.selDisaster)?.length > 0 ? false : true}
                      >
                        {/* Next */}
                        {datasearchcomp.nextBtn[this.props.lang]}
                      </button>
                    </div>
                    <div className="disasterSelected">
                      {/* <h4>Selected</h4> */}
                      <h4> {datasearchcomp.selected[this.props.lang]}</h4>
                     

                      {this.renderselected()}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="stage"
                title={
                  <>
                    <div className="dst_list">
                      <div className="dst_listContent">
                        {/* <span>STEP 2</span>
                                        <h4>Form</h4> */}
                        <span>
                          {datasearchcomp.dstlistContentstp2[this.props.lang]}
                        </span>

                        <h4>
                          {datasearchcomp.dstlistContentstp2h4[this.props.lang]}
                        </h4>
                      </div>
                      <div className="dst_count">
                        {this.state.selStage.includes("*")
                          ? this.state.selStage?.length - 1
                          : this.state.selStage?.length}
                      </div>
                    </div>
                  </>
                }
                className="dstColumn"
              >
                <div className="disasterSearch">
                  <div className="dsInput">
                    <div className="dsSelectall">
                      <div className="d-flex align-items-center dataSearchFormList">
                        <input
                          type="checkbox"
                          name="stage-select-all"
                          id="stage-select-all"
                          onChange={(e) => this.selectStage(e, "*")}
                          checked={
                            this.state.selStage.includes("*") ? "checked" : ""
                          }
                        />
                        {/* <label for="stage-select-all">Select All</label> */}
                          <label for="stage-select-all"> {datasearchcomp.selectall[this.props.lang]}</label>
                       
                      </div>
                    </div>
                    <input
                      type="search"
                      placeholder="Search..."
                      className="dsSearch"
                    />
                  </div>
                  {/* <button className='disasterReset' onClick={(e)=> this.resetSelection()}>
                                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                                    </button> */}
                </div>
                <div className="disasterTab">
                  <div className="disasterList">
                    <div className="disasterCheckbox">
                      {/* <div className="d-flex align-items-center dataSearchFormList"> */}
                      <div className={this.state.validStages?.length > 0 ||  Object.keys(this.state.selDisaster)?.includes('none') ?
                                                            "d-flex align-items-center dataSearchFormList":
                                                            "disable-sector d-flex align-items-center dataSearchFormList"}>
                        <input
                          type="checkbox"
                          name="stage-select-all"
                          id="stage-select-all"
                          onChange={(e) => this.selectStage(e, "*")}
                          checked={
                            this.state.selStage.includes("*") ? "checked" : ""
                          }
                        />
                        {/* <label for="stage-select-all">Select All</label> */}
                        <label for="stage-select-all"> {datasearchcomp.selectall[this.props.lang]}</label>
                      </div>
                      <div className={this.state.validStages?.includes(datasearchcomp.Forms[0]['en'])?
                                                            "d-flex align-items-center dataSearchFormList":
                                                            "disable-sector d-flex align-items-center dataSearchFormList"}>
                        <input
                          type="checkbox"
                          name="Damage-Observation"
                          id="Damage-Observation"
                          onChange={(e) =>
                            this.selectStage(e, datasearchcomp.Forms[0][this.props.lang], 0)
                          }
                          checked={
                            this.state.selStage.includes(datasearchcomp.Forms[0][this.props.lang])
                              ? "checked"
                              : ""
                          }
                          value={datasearchcomp.Forms[0][this.props.lang]}
                        />
                        <label for="Damage-Observation">
                          {/* Damage Observation */}
                          {datasearchcomp.Forms[0][this.props.lang]}

                        </label>
                      </div>
                      <div className={this.state.validStages?.includes(datasearchcomp.Forms[1]['en'])?
                                                            "d-flex align-items-center dataSearchFormList":
                                                            "disable-sector d-flex align-items-center dataSearchFormList"}>
                        <input
                          type="checkbox"
                          name="Damage-and-Need-Assessment"
                          id="Damage-and-Need-Assessment"
                          onChange={(e) =>
                            this.selectStage(e, datasearchcomp.Forms[1][this.props.lang], 1)
                          }
                          checked={
                            this.state.selStage.includes(
                              datasearchcomp.Forms[1][this.props.lang]
                            )
                              ? "checked"
                              : ""
                          }
                          value={datasearchcomp.Forms[1][this.props.lang]}
                        />
                        <label for="Damage-and-Need-Assessment">
                          {datasearchcomp.Forms[1][this.props.lang]}
                        </label>
                      </div>
                      <div className={this.state.validStages?.includes(datasearchcomp.Forms[2]['en'])?
                                                            "d-flex align-items-center dataSearchFormList":
                                                            "disable-sector d-flex align-items-center dataSearchFormList"}>
                        <input
                          type="checkbox"
                          name="Multi-sector-Rapid-Assessment"
                          id="Multi-sector-Rapid-Assessment"
                          onChange={(e) =>
                            this.selectStage(e, datasearchcomp.Forms[2][this.props.lang], 2)
                          }
                          checked={
                            this.state.selStage.includes(
                              datasearchcomp.Forms[2][this.props.lang]
                            )
                              ? "checked"
                              : ""
                          }
                          value={datasearchcomp.Forms[2][this.props.lang]}
                        />
                        <label for="Multi-sector-Rapid-Assessment">
                          {datasearchcomp.Forms[2][this.props.lang]}
                        </label>
                      </div>
                      <div className={Object.keys(this.state.selDisaster)?.includes('none')?
                                                            "d-flex align-items-center dataSearchFormList":
                                                            "disable-sector d-flex align-items-center dataSearchFormList"}>
                                                            
                        <input
                          type="checkbox"
                          name="form-Other"
                          id="form-Other"
                          onChange={(e) =>
                            this.selectStage(e, datasearchcomp?.Forms[3][this.props.lang], 3)
                          }
                          checked={
                            this.state.selStage.includes(datasearchcomp?.Forms[3][this.props.lang])
                              ? "checked"
                              : ""
                          }
                          value='Other'
                        />
                        <label for="form-Other">
                          {/* Other */}
                          {/* Baseline Data */}
                          {datasearchcomp.Forms[3][this.props.lang]}
                        </label>
                      </div>
                      
                    </div>
                    <div className="BtnForm">
                      <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />{" "}
                        {/* Reset */}
                        {datasearchcomp.btnReset[this.props.lang]} 
                      </button>
                      <button
                        className="nextBtn"
                        onClick={() => this.handletabSelect("area")}
                        disabled={this.state.selStage?.length>0?false:true}
                      >
                        {/* Next */}
                        {datasearchcomp.nextBtn[this.props.lang]}
                      </button>
                    </div>
                    <div className="disasterSelected">
                      <h4>{datasearchcomp.selected[this.props.lang]}</h4>
                      {this.renderselected()}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="area"
                title={
                  <>
                    <div className="dst_list">
                      <div className="dst_listContent">
                        {/* <span>STEP 3</span>
                                        <h4>Area</h4> */}
                        <span>
                          {datasearchcomp.dstlistContentstp3[this.props.lang]}
                        </span>

                        <h4>
                          {datasearchcomp.dstlistContentstp3h4[this.props.lang]}
                        </h4>
                      </div>
                      <div className="dst_count">
                        {this.state.areaList?.length}
                      </div>
                    </div>
                  </>
                }
                className="dstColumn"
              >
                <div className="disasterSearch">
                  <div className="dsInput">
                    <div className="dsSelectall">
                      {/* <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="Select All" />
                                            </FormGroup> */}
                    </div>
                    <input
                      type="search"
                      placeholder="Search..."
                      className="dsSearch"
                    />
                  </div>
                  {/* <button className='disasterReset' onClick={(e)=> this.resetSelection()}>
                                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                                    </button> */}
                </div>
                <div className="disasterTab">
                  <div className="disasterList">
                    <div className="disasterCheckbox">
                      <ul className="dcTreeView" id="myUL">
                        {this.rendertree(this.state.callData?.[0])}
                      </ul>
                    </div>
                    <div className="BtnForm">
                      <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />{" "}
                        {/* Reset */}
                        {datasearchcomp.btnReset[this.props.lang]} 
                      </button>
                      <button
                        className="nextBtn"
                        onClick={() => this.handletabSelect("indicator")}
                        disabled={this.state.areaList?.length > 0?false:true}
                      >
                        {/* Next */}
                        {datasearchcomp.nextBtn[this.props.lang]}
                      </button>
                    </div>

                    <div className="disasterSelected">
                      {/* <h4>Selected</h4> */}
                     <h4>{datasearchcomp.selected[this.props.lang]}</h4>
                      {this.renderselected()}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="indicator"
                title={
                  <>
                    <div className="dst_list">
                      <div className="dst_listContent">
                        {/* <span>STEP 4</span>
                                        <h4>Indicator</h4> */}
                        <span>
                          {datasearchcomp.dstlistContentstp4[this.props.lang]}
                        </span>

                        <h4>
                          {datasearchcomp.dstlistContentstp4h4[this.props.lang]}
                        </h4>
                      </div>
                      <div className="dst_count">
                        { Object.entries(this.state.selIUSdata)?.filter(([key,value]) =>  key != value?.iu_id+'#' && key != '*').length }
                        {/* '*' in this.state.selIUSdata ? Object.keys(this.state.selIUSdata)?.length - 1 : Object.keys(this.state.selIUSdata)?.length */}
                      </div>
                    </div>
                  </>
                }
                className="dstColumn"
              >
                <div className="disasterSearch">
                  <div className="dsInput">
                    <div className="dsSelectall">
                      <div className="d-flex align-items-center dataSearchFormList">
                        <input
                          type="checkbox"
                          checked={
                            "*" in this.state.selIUSdata ? "checked" : ""
                          }
                          onChange={(e) => this.selectIUS(e, "*", true, true)}
                          id="indicator-selectall"
                          name="indicator-selectall"
                        />
                        {/* <label for="indicator-selectall"> Select All</label> */}
                        <label for="indicator-selectall"> {datasearchcomp.selectall[this.props.lang]}</label>
                      </div>
                      {/* <FormGroup>
                                                <FormControlLabel control={<Checkbox
                                                    checked={'*' in this.state.selIUSdata? 'checked': ''}
                                                    onChange={(e)=> this.selectIUS(e, '*', true, true)}
                                                 />} label="Select All" />
                                            </FormGroup> */}
                    </div>
                    <input
                      type="search"
                      value={this.state.searchText}
                      onChange={(e) => this.onSearch(e.target.value)}
                      placeholder="Search..."
                      className="dsSearch"
                    />
                  </div>
                  {/* <button className='disasterReset' onClick={(e)=> this.resetSelection()}>
                                        <img src="assets/lib/images/icon/reset.svg" alt="" />
                                    </button> */}
                </div>
                <div className="disasterTab">
                  <div className="disasterList">
                    <div className="disasterCheckbox">
                      <ul className="dcTreeView treeviewIndicator" id="myUL">
                        <>
                        {this.state.fIndicatorData?.map((sectorData) => {
                          
                          return (
                            <li className={sectorData.iusList?.length == 0?"disable-sector":""} key={sectorData._id}>
                              <span class="caret indicator-caret">
                                <img src="assets/lib/images/arrow.svg" />
                              </span>

                              
                              <div className="dcTreeViewLabel">
                                <> {sectorData.level_name?.[this.props.lang]} </>
                              </div>
                              <ul className="dcTreeViewText nested">
                                {sectorData.iusList?.map((iu) => {
                                  return (
                                    <>
                                    { this.state.iuIds?.includes(iu._id) &&
                                    <li className={!!this.state.iuIds?.includes(iu._id) ? '': 'disable-sector'}>
                                      <span class="caret indicator-caret">
                                        <img src="assets/lib/images/arrow.svg" />
                                      </span>
                                      <div className="dcTreeViewLabel">
                                        <>
                                          <div className="d-md-flex d-block align-items-center">
                                            <input type="checkbox" className="align-self-center" 
                                                    id={"indicator-selectall"+iu._id}
                                                    name={"indicator-selectall"+iu._id}
                                                    onChange={(e) =>
                                                      this.selectIUS(
                                                        e,
                                                        '#',
                                                        iu,
                                                        null
                                                      )
                                                    }
                                                    checked={
                                                      (iu._id + '#') in
                                                      this.state.selIUSdata
                                                        ? "checked"
                                                        : ""
                                                    }
                                            />
                                            {iu.name?.[this.props.lang] ? iu.name?.[this.props.lang] : iu.name?.en }
                                            <span className="ml-1"> | {iu.unit?.[this.props.lang] ? iu.unit?.[this.props.lang] : iu.unit?.en}</span>
                                          </div>
                                        </>
                                      </div>

                                      <ul class="nested">
                                        {iu.subgroup?.length > 1 && iu.subgroup?.map((sg) => {
                                          return (
                                            <li>
                                              <div className={ !!this.state.iusIds?.includes(sg._id) ? "d-flex align-items-center dataSearchFormList": "d-flex align-items-center dataSearchFormList disabled"}>
                                                <input
                                                  type="checkbox"
                                                  id={iu._id + sg.name?.[this.props.lang]}
                                                  name={iu._id + sg.name?.[this.props.lang]}
                                                  checked={
                                                    (iu._id + sg.name?.[this.props.lang]) in this.state.selIUSdata ? "checked" : ""
                                                  }
                                                  onChange={(e) =>
                                                    this.selectIUS(
                                                      e,
                                                      false,
                                                      iu,
                                                      sg
                                                    )
                                                  }
                                                />
                                                <label
                                                  for={iu._id + sg.name?.[this.props.lang]}
                                                >
                                                  {<> {sg.name?.[this.props.lang]}</>}
                                                </label>
                                              </div>
                                              {/* <FormControlLabel
                                                                                                                label={<> {sg.name[this.props.lang]}</>}
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        id={iu._id+sg.name[this.props.lang]} name={iu._id+sg.name[this.props.lang]}
                                                                                                                        checked={(iu._id+sg.name[this.props.lang]) in this.state.selIUSdata? 'checked': ''}
                                                                                                                        onChange={(e)=> this.selectIUS(e, false, iu, sg)}
                                                                                                                    />
                                                                                                                }
                                                                                                            /> */}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                    }
                                    </>
                                      
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                        </>
                      </ul>
                    </div>
                    <div className="BtnForm">
                      <button
                        className="btnReset"
                        onClick={(e) => this.resetSelection()}
                      >
                        <img src="assets/lib/images/icon/reset.svg" alt="" />{" "}
                        {/* Reset */}
                        {datasearchcomp.btnReset[this.props.lang]} 
                      </button>
                      <button
                        className="nextBtn"
                        onClick={() => this.handletabSelect("viewdata")}
                        disabled={Object.entries(this.state.selIUSdata).length > (Object.keys(this.state.selIUSdata)?.includes('*')? 1 : 0)?false: true}
                      >
                        {/* Next */}
                        {datasearchcomp.nextBtn[this.props.lang]}
                      </button>
                    </div>

                    <div className="disasterSelected">
                      <h4>{datasearchcomp.selected[this.props.lang]}</h4>
                      {this.renderselected()}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="viewdata"
                title={
                  <>
                    <div className="dst_list">
                      <div className="dst_listContent">
                        {/* <span>STEP 5</span>
                                            <h4>View Data</h4> */}
                        <span>
                          {datasearchcomp.dstlistContentstp5[this.props.lang]}
                        </span>

                        <h4>
                          {datasearchcomp.dstlistContentstp5h4[this.props.lang]}
                        </h4>
                      </div>
                    </div>
                  </>
                }
                className="h-100"
              >
                <DataSearchResultComponent
                  selDisaster={this.state.selDisaster}
                  selIUSdata={this.state.selIUSdata}
                  selStage={this.state.selStage}
                  selAreaList={this.state.areaList}
                  data={this.state.results}
                  areaLevel={this.state.areaLevel}
                  callBack={this.resetSelection}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>

      <div
        className={
          this.state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>
    </>
  );
}

export default dataSearchTemplate;
