/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import React from "react";
import { Link } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import * as htmlToImage from "html-to-image";
import { CSVLink } from "react-csv";
import * as $ from "jquery";
import FormControl from "@mui/material/FormControl";
import ReactEcharts from "echarts-for-react";
import FixedHeaderStory from "react-data-table-component";
import MapView from "../mapview/mapview";
import Tooltip from "@mui/material/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import GeoData from "../geo-data/geo-data";
import DropdownButton from "react-bootstrap/DropdownButton";
import disasterProfileJSON from '../../../Config/disasterProfile.json';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';



const trackerTemplate = function () {

  let dTime = new Date().toISOString().split("T")[0].replace(/-/g, "") +
    "_" +
    new Date().getHours() +
    "" +
    new Date().getMinutes() +
    "" +
    new Date().getSeconds();

  let csvFileName = "TERS_" + this.state.selectIndicator.name +
    "_" +
    this.state.selectIndicator.unit +
    "_" +
    this.state.selectedPeriod + "_" + "Table" + "_" + dTime + ".csv";

  const columns = [
    {
      name: disasterProfileJSON?.area?.[this.props.lang],
      selector: (row) => row?.area,
      sortable: true,
    },
    {
      name: disasterProfileJSON?.value?.[this.props.lang],
      selector: (row) => row?.value,
      sortable: true,
    },
  ];

  const headers = [
    { label: disasterProfileJSON?.indicator?.[this.props.lang], key: "indicator" },
    { label: disasterProfileJSON?.unit?.[this.props.lang], key: "unit" },
    { label: disasterProfileJSON?.Subgroup?.[this.props.lang], key: "subgroup" },
    { label: disasterProfileJSON?.["time-period"]?.[this.props.lang], key: "timeperiod" },
    { label: disasterProfileJSON?.area?.[this.props.lang], key: "areaName" },
    { label: disasterProfileJSON?.area_id?.[this.props.lang], key: "areaCode" },
    { label: disasterProfileJSON?.value?.[this.props.lang], key: "areaData" },
    { label: disasterProfileJSON?.Source?.[this.props.lang], key: "source" },
    { label: disasterProfileJSON?.disasterName?.[this.props.lang], key: "disasterName" },
  ];

  const downloadCsvData = () => {
    console.log('this.state.filterTableData', this.state.filterTableData)

    let downloadData = this.state.filterTableData.map((element) => (

        {
          Area: element?.area,
          Value: element?.value === 0 ? '' : element?.value
        }
      ))

    downloadFile(arrayToCSV(downloadData), csvFileName)
  }

  const arrayToCSV = (data) => {
    const csv = data.map(row => Object.values(row));
    csv.unshift(Object.keys(data[0]));
    csv.unshift('')
    csv.unshift(`${disasterProfileJSON?.Source?.[this.props.lang]}: ${sourceData()?.[0] !== undefined ? sourceData()?.[0]?.source_lang?.pt && sourceData()?.[0]?.source_lang?.tet !== undefined ? sourceData()?.[0]?.source_lang?.[this.props?.lang] : sourceData()?.[0]?.source_lang?.['en'] : "-"}`)
    csv.unshift(this.state.selectIndicator.name + ' | ' + this.state.selectIndicator.unit)
    return csv.join('\n');
  }

  let allEmplty = true

  const downloadFile = (data, filename = 'data') => {
    let csvData = data
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  const downloadMap = () => {

    this.setState({ loader: true })

    $("#maintableDownload").css({
      background: "rgb(255, 255, 255)",
      padding: "10px",
      width: "550px"
    });
    $("#maintableDownload svg text").css({
      fill: "#333",

    });
    $("#maintableDownload").addClass('svgColorfill');
    $(".echarts-for-react").addClass('barClass');
    $("#heading_info2").css("color", "#000");
    $("#heading_info2 span.pipeMeta2").css("color", "#ddd");
    $(".metaInfo2").css("color", "rgba(0, 0, 0)");

    $(".main_three_dots").hide();
    $("#footer-logo").show();
    $(".metaInfo2").show();

    var node = document.getElementById("maintableDownload");
    let that = this;

    setTimeout(() => {
      htmlToImage
        .toJpeg(node, { quality: 0.95, width: 550, height: 550 })
        .then(function (dataUrl) {
          let dTime =
            new Date().toISOString().split("T")[0].replace(/-/g, "") +
            "_" +
            new Date().getHours() +
            "" +
            new Date().getMinutes() +
            "" +
            new Date().getSeconds();

          var a = document.createElement("a");
          a.href = dataUrl;
          a.download =
            "TERS_" +
            that.state.selectIndicator.name +
            "_" +
            that.state.selectIndicator.unit +
            "_" +
            that.state.subGroupSelected.name +
            "_" +
            that.state.selectedPeriod +
            // eslint-disable-next-line no-useless-concat
            "_" + "Chart" + "_" +
            dTime +
            ".jpeg";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          $(".main_three_dots").show();

          $("#maintableDownload").css("background", "transparent");
          $("#maintableDownload").css({ width: "", height: "", padding: "" });
          $(".echarts-for-react").removeClass('barClass');
          $("#maintableDownload").removeClass('svgColorfill');
          $(".metaInfo2").hide();
          $("#footer-logo").hide();
          $("#maintableDownload svg text").css({
            fill: "#fff",
          });
          $(".metaInfo2 p").css("color", "");
          that.setState({ loader: false })
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }, 300);
  };
  
  const filteredArr = this.state?.tableData?.reduce((acc, current) => {
    const x = acc.find(item => item.source_lang?.en === current.source_lang?.en);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  console.log(filteredArr,"filteredArr")

  const sourceData = () => {
    let d = []
    filteredArr?.map((element, index) => {
      if (element.source_lang !== "") {
        d.push(element)
      }
    })
    return d
  }



  return (
    <>
      <section className="tracker_section">
        <div className="trackerDropdown">
          <ul>
            <li className="indileft_drop">

              <div className="inner_sec_head d-inline-block">
                <span className="ul_label">
                  {disasterProfileJSON.indicator[this.props.lang]}
                </span>
                <div className="d-flex align-items-center">
                  <span
                    className="new_indicatorImg mr-2 cursor-pointer"
                    data-toggle="modal"
                    onClick={(e) => this.aaa(e, this.state.selectIndicator)}
                  >
                    <img src="assets/lib/images/home/indicator.svg" alt="indicator" />
                  </span>
                  <DropdownButton
                    align="end"
                    title={
                      <>
                        <div
                          className="ellipes"
                          title={this.state.selectIndicator?.name}
                        >
                          {this.state.selectIndicator?.name}
                        </div>
                        <div className="unit_values">
                          <span className="thin_line ps-0"> | </span>
                          {this.state.selectIndicator?.unit}
                        </div>
                      </>
                    }
                    id="dropdown-menu-align-end"
                  >
                    <ul className="treeviewList first_treeviewList" id="myUL">
                      <div className="inputSearch">
                        <input type="search" placeholder={`${disasterProfileJSON.placeholdersearch[this.props.lang]}`} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} />
                      </div>
                      {this.state.ius_list?.filter(x => x.name[this.props?.lang ?? 'en']?.toLowerCase()?.includes(this.state.searchValue?.toLowerCase()))?.map((item,ind) => {
                        return (
                          <>
                            <li className={!item.isData ? "disableMenu" : ""} key={`${item.unit?.en+ind}`}>
                              <span
                                name="indicatorType"
                                onClick={(e) => {
                                  this.indicatorChange(item);
                                }}
                              >
                                {item.name[this.props?.lang]
                                  ? item.name[this.props?.lang]
                                  : item.name?.en} | <span className="indiUnit">
                                  {item.unit[this.props?.lang]
                                    ? item.unit[this.props?.lang]
                                    : item.unit?.en}
                                </span>
                              </span>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </DropdownButton>
                </div>
              </div>
            </li>
            <li className="indileft_total">
              <span className="ul_label">
                {disasterProfileJSON.Subgroup[this.props.lang]}
              </span>
              <Dropdown>
                <Dropdown.Toggle variant="white" id="dropdown-basic">
                  {this.state.subGroupSelected?.name}
                  
                </Dropdown.Toggle>
                <Dropdown.Menu className="trackerDrop">
                  <ul className="treeviewList" id="myUL">
                    {

                      this.state.subGroupData?.map((item, ind) => {
                        return (
                          <>
                            <li key={ind+"jnkdf"}>
                              <span
                                name="indicatorType"
                                onClick={(e) => {
                                  this.sugroupChange(item);
                                }}
                              >
                                {
                                  item?.name[this.props?.lang] ? item?.name[this.props?.lang] : item?.name?.en
                                }
                              </span>
                            </li>
                          </>
                        );
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <span className="ul_label">
                {/* time period */}
                {disasterProfileJSON["time-period"][this.props.lang]}
              </span>
              <Dropdown>
                <Dropdown.Toggle variant="white" id="dropdown-basic">
                  {this.state.selectedPeriod}
                </Dropdown.Toggle>
                <Dropdown.Menu className="trackerDrop">
                  <ul className="treeviewList" id="myUL">
                    {this.state.timePeriodData?.map((item, ind) => {
                      return (
                        <>
                          <li key={ind+item.time_period}>
                            <span
                              name="indicatorType"
                              onClick={(e) => this.timePeriodChange(item)}
                            >
                              {item.time_period}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </li>

          </ul>
        </div>

        <div className="dataAnalysis_map">
          <div className="dam_box">
            <div className="mapSection row justify-content-between">
              <div className="col-md-8 h-100 main_chart_box">
                <div className="timorMap h-100 position-relative">
                  <div className={`levels_box main_drop_css ${this.state?.levelArr[0]?.level === 3 ? "level_3" : ""} ${this.state?.levelArr[0]?.level === 4 ? "level_4" : ""} `}>
                    {

                      <div className="main-arealevel d-flex">
                        <div className="pin-area">
                         <img src="assets/lib/images/pin_icon.svg" alt="" />
                        </div>

                        <div className="main_drop chart_source_area" fullwidth="true">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.props?.formStateLevel===5 ?2 :this.props?.formStateLevel}
                            label="Area"
                            onChange={(e)=> {this.levelChange(e)}}
                            // MenuProps={{
                            //   PaperProps: {
                            //     sx: {
                            //       background: "rgba(0, 0, 0, 0.5)",
                            //       border: "none !important",
                            //       marginTop: "5px",
                            //       borderRadius: 1,
                            //       padding:"0px !important",
                            //       '& .MuiMenuItem-root': {
                            //         border:"none !important",
                            //         color: "#ffffff !important"
                            //       },
                            //       "& .MuiMenuItem-root:hover": {
                            //         background: "#a6170a !important",
                            //       },
                            //     },
                            //   },
                            // }}
                          >
                            {
                              this.state?.levelArr.map((ele,i)=>(
                                <MenuItem value={ele.level} key={i+ele.labelText}>{ele.labelText}</MenuItem>
                              ))
                            }
                          </Select>
                        </div>
                      </div>
                    }
                  </div>
                  <GeoData
                    lang={this.props?.lang}
                    rangeData={this.setDataRange}
                    inddata={this.state.selectIndicator}
                    allData={this.state}
                    areaData={this.state.mainAreaData}
                    areaCode={this.state.areaObject}
                    allAreaTree={this.state.callData}
                    mapData={this.state.tableData}
                    areaCallBack={this.areaCallBack}
                    areaUpdateName={this.state?.areaObject?.name}
                    currentLevelValue={this.state?.currentLevelValue}
                    isLevel={this.state?.isLevel}
                    sourceData={this.state?.currentSource}
                    
                  />{
                    this.state.mainFilterArray.length && allEmplty
                          ?                   <ul className="maps_source">
                          <li>
                              <div className="main_drop chart_source_area">
                                  <FormControl fullwidth="true">
                                      <InputLabel id="demo-simple-select-label"
                                          sx={{
                                            color: "#ffffff !important"
                                          }}>Source</InputLabel>
                                      <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={this.state?.currentSource}
                                          label="source"
                                          onChange={(e)=> {this.sourceChange(e.target.value)}}
                                          // MenuProps={{
                                          //     PaperProps: {
                                          //         sx: {
                                          //             background: "rgba(0, 0, 0, 0.5)",
                                          //             border: "none !important",
                                          //             marginTop: "5px",
                                          //             borderRadius: 1,
                                          //             padding:"0px !important",
                                          //             '& .MuiInputBase-root': {
                                          //                 border:"none !important",
                                          //                 color: "#ffffff !important",
                                          //             },
                                          //             "& .MuiMenuItem-root:hover": {
                                          //                 background: "#a6170a !important"
                                          //             },
                                          //         },
                                          //     },
                                          // }}
                                          // sx= {{
                                          //     "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                          //         borderColor: "#ffffff !important"
                                          //     },
                                          //     "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                          //         color: "#ffffff !important"
                                          //     },
                                          //     "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
                                          //         fill: "#ffffff !important"
                                          //     },
                                          // }}
                                      >
                                      {
                                          sourceData()?.map((item,ind)=>{
                                              return <MenuItem value={item?.source_lang?.en} key={item?.source_lang?.en +ind}>{item?.source_lang?.en}</MenuItem>
                                          })
                                      }
                                   
                                      </Select>
                                  </FormControl>
                              </div>
                          </li>
                          <li>
                            <Tooltip
                              title={disasterProfileJSON?.["discTT"]?.[this.props.lang]}
                              placement="top"
                            >
                              <Link to="">
                                {disasterProfileJSON?.disclaimer?.[this.props.lang]}
                                <img src="assets/lib/images/home/map-arrow.svg" className="ml-1" alt="map-arrow"/>
                              </Link>
                            </Tooltip>
                          </li>
                        </ul>:null
                  }

                </div>
              </div>
              <div className="col-md-4 h-100 bottom_chart">
                <div className="DataAnalysisTable pl-0 position-relative">
                  <CSVLink
                    data={this.state.downloadTableData}
                    ref={(r) => (this.surveyLink = r)}
                    headers={headers}
                    filename={csvFileName}
                    className="d-flex"
                  />

                  <div className="daChart">

                    <div className="daChartBox" id="maintableDownload">

                      {
                        this.state.mainFilterArray.length &&allEmplty
                          ?
                          (
                            <div className="main_three_dots">
                              <div className="dropdown three_dots_drop">
                                <button
                                  className="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src="assets/lib/images/home/dots.svg" alt="dots"></img>
                                </button>
                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item download-btn"
                                    onClick={downloadMap}
                                  >
                                    <img src="assets/lib/images/home/download.svg" alt="download" />
                                  </a>
                                  {this.state.labelOn === 1 && (
                                    <a
                                      className="dropdown-item"
                                      onClick={(e) => this.abc(e, true)}
                                    >
                                      <img src="assets/lib/images/home/label_on.svg" alt="label" />
                                    </a>
                                  )}
                                  {this.state.labelOn === 0 && (
                                    <a
                                      className="dropdown-item"
                                      onClick={(e) => this.abc(e, false)}
                                    >
                                      <img src="assets/lib/images/home/label-off.svg" alt="label-off" />
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null
                      }
                     
                      <div
                        className="metaInfo2"
                        style={{ display: "none", fontSize: "20px", color: "#ffffff" }}
                      >
                        <p id="heading_info2">
                          {this.props.disasterNameType}<span className="pipeMeta">|</span> {this.state.selectIndicator.name} <span> - {this.state.selectIndicator.unit} <br /> {this.state.subGroupSelected.name} <span className="pipeMeta">|</span> {this.state.selectedPeriod}
                          </span>
                        </p>
                      </div>

                      <ReactEcharts
                        ref={(e) => {
                          this.chartRefs = e;
                        }}
                        option={this.defOption}
                        style={{
                          height: "300px",
                          left: 0,
                          top: 0,
                          width: "100%",
                        }}
                        opts={{ renderer: "svg" }}
                      />
                      <div
                        className="metaInfo2"
                        style={{
                          display: "none",
                          fontSize: "20px",
                          color: "#333",
                        }}
                      >

                        <p id="heading_info2" >Source: {sourceData()?.[0]?.source_lang?.pt && sourceData()?.[0]?.source_lang.tet !== undefined ? sourceData()?.[0]?.source_lang?.[this.props?.lang] : sourceData()?.[0]?.source_lang?.['en']}</p>
                      </div>
                      <div id="appendSource"></div>
                      <div
                        id="footer-logo"
                        className="col-md-4 order-3 order-md-3"
                        style={{
                          display: "none",
                          position: "absolute",
                          right: "10px",
                        }}
                      >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade maps_popup "
        id="are_value_table"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title" id="exampleModalLabel">
                  <span name="indicatorType">
                    {this.state.selectIndicator.name}
                    <span className="indiUnit"> | {this.state.selectIndicator.unit}</span>
                  </span>
                </h5>
              </div>
              <div className="d-flex align-items-center">
                {this.state.tableData.length !== 0 ? (
                  <button
                    className="btn download-btn position-relative"
                    onClick={downloadCsvData}
                  >
                    <img src="assets/lib/images/home/download.svg" alt="download" />
                  </button>
                ) : (
                  <button className="btn download-btn position-relative disableMenu">
                    <img src="assets/lib/images/home/download.svg" alt="download" />
                  </button>
                )}
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <div className="daTable">
                <FixedHeaderStory
                  noDataComponent="No Data"
                  columns={columns}
                  data={this.state.filterTableData}
                  fixedHeader
                  fixedHeaderScrollHeight="200px"
                  className="dat_tablescroll"
                />
              </div>
              <div className="source_area py-2">
                
                {/* <Tooltip title={sourceData()?.[0]?.source_lang?.pt && sourceData()?.[0]?.source_lang?.tet !== undefined ? sourceData()?.[0]?.source_lang?.[this.props?.lang] : sourceData()?.[0]?.source_lang?.['en']} placement="top"> */}
                
                <Tooltip title={this.state.currentSource} placement="top">
                  
                  <Link to="">
                    {disasterProfileJSON?.Source?.[this.props?.lang]} <img src="assets/lib/images/home/hover_arrow.svg" alt="arrow" />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======================================================== */}
      {/* {this.state.metadata !== undefined && ( */}
        <div
          className="modal fade maps_popup metadatamodal"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">
                  {this.state.selectIndicator?.name}
                  <span> | {this.state.selectIndicator?.unit}</span>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h4>{disasterProfileJSON?.mt1?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt1?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt1?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt2?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt2?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt2?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt3?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt3?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt3?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt4?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt4?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt4?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt5?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt5?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt5?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt6?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt6?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt6?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt7?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt7?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt7?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt8?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt8?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt8?.[this.props?.lang]
                    : "-"}
                </p>
                <h4>{disasterProfileJSON?.mt9?.[this.props.lang]}</h4>
                <p>
                  {this.state.selectedMetaInfo?.mt9?.[this.props?.lang]
                    ? this.state.selectedMetaInfo?.mt9?.[this.props?.lang]
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}

      <div
        className={
          this.state.loader ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif"
          style={{ marginTop: "12%", width: "30%" }} alt="comp"
        />
      </div>

      <div
        className="metaInfo2"
        style={{ display: "none", fontSize: "12px", color: "#ffffff" }}
      >
        <p >Source: Dummy</p>
        <p> Disclaimer: The boundaries and the names shown and the designations used on these maps do not imply any official endorsement or acceptance by any agency organization or government.</p>
      </div>
    </>
  );
};

export default trackerTemplate;

