import { Component } from 'react'
import './actor.css';
import * as jsx from './actor.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';

import dayjs from 'dayjs';
import swal from 'sweetalert';
import actorTemplate from './actor.module';
class Actor extends Component {
     arr = ["dxgf"]
   
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit:false,
            editEntry:[],
            modal:false,
            loader:false,
            profileData:[],
            // setValue:dayjs('2014-08-18T21:11:54')
            setOpen:false,
            openTab:'Pending',
            alignment: 'Pending',
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData:false,
            disscrollableModal : false,
            editModeId: null,
            modal: false,
            remarkdata: '',
            loader: false,
            dataLimit:10,
            actorfilterData:[],
            actorData:[],
       
            dataStatus:2,
            statusData:[
                {"label":"Pending", "value":2},
                {"label":"Approved", "value":1},
                {"label":"Disapproved", "value":3}
            ]
     
            // open:false,
        }
    }


        handleChange = (event, newAlignment) => {
            this.setState({alignment:newAlignment, openTab: newAlignment});
            this.getData(newAlignment);
        };

    

handleDate = (newValue) => {
  this.setState({setValue:newValue});
};




    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.date_of_registration && item.date_of_registration.toLowerCase().includes(val.toLowerCase())
                      || (item.usersData?.[0]?.email && item.usersData?.[0]?.email.toLowerCase().includes(val.toLowerCase()))
              
            ));
        }
        
        this.setState({filteredData : fd});
    }
    


    columnsPending = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Registration Date',
            selector: row => row.date_of_registration,
            sortable: true,
            // width: "300px"  
            
        },
    
        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "350px"  
        },
        {
            name: 'Profile Status',
            selector: row => 'pending',
            sortable: true,
            // width: "300px"   
        },
      
    
    {
        name: 'Action',
        width: "300px",
        cell: row =>
            <>
            
                    
            
                        <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                             <a className="pop-up-btn dropdown-item" onClick={(e)=>this.approveData(row._id,2)} >
                                <span _ngcontent-nud-c5="" title="Approve">
                                        Approve 
                                </span>
                            </a>
                            <a className="pop-up-btn dropdown-item" onClick={(e)=>{this.disapproveData(row._id,3)}} >
                                <span _ngcontent-nud-c5="" title="Disapprove">
                                    Disapprove
                                </span>
                            </a>
                            </div>
                        </div>
                    
                    

                    
                      

               
            </>,
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
    },
];

    columnsApproved = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Approvel Date',
            selector: row => row.approval_date,
            sortable: true,
            // width: "300px"  
        },
    
        {
            name: 'Last Access Date',
            selector: row => row.updatedAt,
            sortable: true,
            // width: "300px"  
        },
        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "300px"  
        },
      
        {
            name: 'Action',
            width: "300px"  ,
            cell: row => 
                <>
                

                <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a> */}
                        {/* <a class="dropdown-item  text-bold" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a> */}
                        <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#actorprofile"  onClick={(e)=>this.sendActorData(row)}>View Profile</a>
                            {/* <img className='cursor-pointer' dataToggle="modal" dataTarget="#actorprofile" 
                            src="assets/lib/images/home/eyes.svg" /> </a> */}
                        <a class="dropdown-item text-danger text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:;">Delete</a>
                    </div>
                </div> 

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ]; 

    columnsRejected = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Rejected Date',
            selector: row => row.createdAt,
            sortable: true,
            // width: "300px"  
        },
    
        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "300px"  
        },
        {
            name: 'Profile Status',
            selector: row => 'Rejected',
            sortable: true,
            // width: "300px"  
        },
      
        {
            name: 'Action',
            width: "300px"   ,
            cell: row => 
                <>
                

                <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a> */}
                        {/* <a class="dropdown-item  text-bold" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a> */}
                     
                        <a class="dropdown-item text-danger text-bold"  onClick={(e)=> this.deleteEntry(e, row._id)} href="javascript:;">Delete</a>
                    </div>
                </div> 

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];  

    cancelModal = async (e) => {

        if(this.state.disaaprovest==false){
            this.setState({disaaprovest:true})
            this.setState({remarkdata:e})
        }
        else{
            
            this.setState({disaaprovest:false})
        }
      
    }

    openViewModal = async (e) => {

        swal({
            title:"REASON OF DISAPPROVAL",
            text: e
          
            })
      
    }

    sendStatus = (e) => {
        this.state.dataStatus=e;
        this.getData(e);
    }

    getDataByStatus = (e) => {
        console.log("data-----"+e.target.value)
        const val = e.target.value;
        this.setState({dataStatus:val})
        console.log("------aa--"+val)
        console.log("data-----"+e.target.value)
        console.log("state--"+this.state.dataStatus)
        
        this.getData(e.target.value);
    }


    publishAll =  (e) => {

        console.log("length++--"+this.state.filteredData)
        if(this.state.data==""){
            toast.error("No record found to approve");
        }else{

        
        if(this.state.publishModal==false){
            this.setState({publishModal:true})
            this.setState({remarkdata:e})
        }
        else{
            
            this.setState({publishModal:false})
        }
       }
      
    }








    sendActorData(row){
        this.setState({profileData:row});

        console.log('row',row)
    }
    // /==========change status api=============
     
    changeStatus(e, id, status,value) {
        if(value==0){
            value=1;
        }else{
            value=0;
        }
  

        const dataToBeSend = {
            
            show: value
        
        }


        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
    
            if (res) {
                this.setState({ loader: false })
                let d = res;
    
                // console.log(res)
                // this.getData(this.state.dataStatus);
                        
                if(  res.message == 'Status update successfully!'){
                    toast.success("Status update successfully!");
                    // this.openAddModal();
                    // this.getData();
                }
                else{
                    toast.success("Failed to Status update");

                }
            
                
    
                
           
            }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }




    deleteEntry = (e, id) =>{

        swal({
            title:"Are you sure?",
            text: "Once deleted, you will not be able to recover this actor!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if(willDelete) {
                const dataToBeSend = {
                    "id": id
                }
               
                AdminService.httpPost('/data-import/actor/delete-actor', dataToBeSend).then((res) => {
    
                    if (res) {
                        this.setState({ loader: false })
                        let d = res;
            
                        console.log(res)
            
                        if(  res.message == "Actor delete successfully!"){
                            toast.success("Actor delete successfuly!");
                            this.getData();
                        }
                        else{
                            toast.success("Failed to delete actor");
            
                        }
                   
                    }
            
                }).catch((err) => {
                    this.setState({ loader: false })
                    console.log(err)
                });
              }                
            });     
    
    
    
    
    
    
      }
    


 

    editMode (event, id) {
        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });

        this.setState({...this.state.editEntry, editEntry:edData})
        console.log('edt',this.state.editEntry)
        this.setState({ isEdit: true })
        this.openAddModal();
       
    }


    openEditModal(){
        if($('#EditAreaModal').hasClass('width350')){
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else{
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.getData();
        this.getRoleData();
        AdminService.checkToken();
    }
    
    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }

///////////get api------------------/////////////

    getData = async (alignment = null) => {
        this.setState({ loader: true })
        let actorState = constants.actorByState[this.state.alignment];
        console.log("actorState=========>"+actorState)
        if(alignment) {
             actorState = constants.actorByState[alignment];
            //  console.log("actorState1111111111--"+actorState)
        } 
        AdminService.httpGet('/data-import/actor/getByStatus/'+actorState).then((res)=>{
                    if(res){
                        this.setState({loader:false})
                        let d = res.data;
                        
                        this.setState({filteredData: d, data:d})
                      
                        
                        // this.forceUpdate();
                        this.setState({ loader: false })
                    }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

 
    // open add modal=====================//


    openAddModal = () =>{


    if(this.state.modal==false){

        this.setState({modal: true})
        
    }else{
        this.setState({...this.state.editEntry, editEntry: []})
        this.setState({modal: false})
        this.setState({isEdit:false})
        this.setState({editModeId:null})
    }

}
////=========appprove data api////==========
approveData = (id1,status) => {

    this.setState({loader:false})
    swal({
        title:"Are you sure?",
        // text: "Once Approved, you will not be able to disapprove this data!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
            if(willDelete) {
                const dataToBeSend = {
                    id: id1, 
                    status: 1
                
                }
               


    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res)=>{
                if(res){
                    this.setState({loader:false})
                    let d = res.data;
                    this.setState({filteredData: d, data:d})
                    this.getData();
                    if(  res.success == true){
                        toast.success("Data approve successfully!");
                        // this.openAddModal();
                        // this.getData();
                    }
                    else{
                        toast.success("Failed to approve data");

                    }
                }

    }).catch((err)=>{
        this.setState({loader:false})
        console.log(err)
    });
}                
    });   





}



////=========disappprove data api////==========
disapproveData = (id,status) => {
    this.setState({loader:false})

    swal({
        title:"Are you sure?",
        text: "Once Disapproved, you will not be able to approve this data!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
            if(willDelete) {

    const dataToBeSend = {
        id: id,               
        
        status: 3
    
    }


    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res)=>{
                if(res){
                    this.setState({loader:false})
                    let d = res.data;
                    this.setState({filteredData: d, data:d})
                    this.getData();
                    if(  res.success == true){
                        toast.success("Data disapprove successfully!");
                        // this.openAddModal();
                        this.getData();
                    }
                    else{
                        toast.success("Failed to disapprove data");

                    }
                }

    }).catch((err)=>{
        this.setState({loader:false})
        console.log(err)
    });
            }
});  


}
/////////////////==========role and actor  data get==========//////////////
getRoleData = async () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-retrieval/data/masterData').then((res)=>{
                if(res){
                    
                    let d = res[0]?.data.Actors;
                  
                    console.log("somratest========>",d);
                    this.setState({actorData: d, data:d})
                   
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}





}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
  )(Actor);
