import React, { Component } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Slider from "react-slick";
import { HashLink as Link } from "react-router-hash-link";
import GeoData from "../geo-data/geo-data.js";
import { resourceImagePath } from "../../../Config/app.constants";
import CountUp, { useCountUp } from "react-countup";
import homeJson from "../../../Config/home.json";

var homeTemplate = function (props) {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slide = () => {
    this.starts.forEach((start) => {
      start();
    });
  };

  const boxes = document.querySelectorAll(".box");
  const checkBoxes = () => {
    const triggerBottom = (window.innerHeight / 5) * 4;
    boxes.forEach((box) => {
      const boxTop = box.getBoundingClientRect().top;
      if (boxTop < triggerBottom) box.classList.add("box_show");
      else box.classList.remove("box_show");
    });
  };
  window.addEventListener("scroll", checkBoxes);
  checkBoxes();

  const delayboxes = document.querySelectorAll(".delayboxes");
  const delaycheckBoxes = () => {
    const triggerBottom = (window.innerHeight / 5) * 4;
    delayboxes.forEach((delayboxes) => {
      const delayboxeTop = delayboxes.getBoundingClientRect().top;
      if (delayboxeTop < triggerBottom) delayboxes.classList.add("delay_show");
      else delayboxes.classList.remove("delay_show");
    });
  };
  window.addEventListener("scroll", delaycheckBoxes);
  delaycheckBoxes();

  const rightboxes = document.querySelectorAll(".rightboxes");
  const rightcheckBoxes = () => {
    const triggerBottom = (window.innerHeight / 5) * 4;
    rightboxes.forEach((rightboxes) => {
      const delayboxeTop = rightboxes.getBoundingClientRect().top;
      if (delayboxeTop < triggerBottom)
        rightboxes.classList.add("right_delay_show");
      else rightboxes.classList.remove("right_delay_show");
    });
  };
  window.addEventListener("scroll", rightcheckBoxes);
  rightcheckBoxes();

  const seletedLangValue = this.props.lang;

  // console.log(this.state?.keyData?["name"], "getvalueprops");

  return (
    <>
      <Header />

      <section className="banner_area">
        <div className="container-fluid h-100 w-100 p-0">
          <div className="row h-100 w-100 m-0">
            <div className="banner_content da_text ">
              <div className="inner_banner_content">
                <Slider {...settings}>
                  {this.state.keyData.map((item) => {
                    let tetkeyExist = item?.indicator.hasOwnProperty("tet");
                    let ptkeyExist = item?.indicator.hasOwnProperty("pt");

                    return (
                      <div>
                        <p>
                          <span className="extra_bold">
                            <CountUp
                              end={item.value}
                              duration={2}
                              delay={0}
                              formattingFn={(num) => num.toLocaleString()}
                            >
                              {({ countUpRef, start }) => {
                                this.countUpRefs.push(countUpRef);
                                this.starts.push(start);
                                return (
                                  <div>
                                    <span ref={countUpRef} />
                                  </div>
                                );
                              }}
                            </CountUp>
                          </span>
                          {/* <span className='medium_bold' > {item?.indicator?.en} </span> */}
                          <span>
                            {tetkeyExist || ptkeyExist
                              ? item?.indicator[seletedLangValue]
                              : item?.indicator?.en} {homeJson.in?.[this.props.lang]}
                          </span>
                          <br />
                          <span className="extra_bold"></span>
                          {item?.timeperiod} {homeJson["due-to"]?.[this.props.lang]}
                          <span className="extra_bold">
                            {` ${item?.disasterData?.[this.props.lang] ?? ""}`}
                          </span>
                          <br />
                          {/* <span className='extra_bold' > {item?.disasterData} </span>  */}
                          {/* in */}
                          {homeJson.in?.[this.props.lang]} 
                          <span className="extra_bold">
                            {/* {item?.areaData?.name?.en} */}
                            &nbsp;{item?.areaData?.name.hasOwnProperty(
                              seletedLangValue
                            )
                              ? item?.areaData?.name[seletedLangValue]
                              : item?.areaData?.name?.en}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </Slider>
                <span className="source-disclaimer position-relative">
                  <span className="download_label_text">
                    {/* Source */}
                    {homeJson?.source?.[this.props.lang]}
                    <img
                      src="assets/lib/images/icon/arrow-disclaimer.svg"
                      alt=""
                    />
                  </span>
                  <div class="disclaimer-popper">
                    {/* <h3>Source</h3> */}
                    <h3>{homeJson.source?.[this.props.lang]}</h3>
                    {/* <p>Aggregated data</p> */}
                    <p>{homeJson?.["aggregated-data"]?.[this.props.lang]}</p>
                  </div>
                </span>
              </div>
            </div>
            <div className="h-100 w-100">
              <div class="video-bg h-100 w-100">
                <div class="video-wrapper h-100 w-100 position-relative">
                  <video autoPlay muted loop>
                    <source
                      src="assets/lib/images/homepage_video/timor_leste_video.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <div className="undp_rdtl_logo">
                    <img src="assets/images/rdtl_undp_logo.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="data_analytics overflow-hidden">
        <div className="custom_container">
          <div className="row align-items-center">
            <div className="col-md-5 delayboxes">
              <img
                className="imgWidth"
                src="assets/lib/images/home/data_analytics.svg"
              />
            </div>
            <div className="col-md-7 box">
              <div className="da_text new_datext">
                {/* <h2 className=''>DATA <br /><strong className='red_content'>ANALYSIS</strong></h2> */}
                <h2 className="">
                  {homeJson?.data[this.props.lang]} <br />
                  <strong className="red_content">
                    {homeJson?.analyse[this.props.lang]}
                  </strong>
                </h2>
                {/* <p className=''>An interactive data dashboard that helps to track and monitor the disaster indicators across Timor-Leste and its sub-nationals</p> */}
                <p className="">
                  {homeJson?.["data-analysis-para"][this.props.lang]}
                </p>
                {/* <Link to='/dashboard/data-analysis' className='explore_btn '>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link> */}
                <Link to="/dashboard/data-analysis" className="explore_btn ">
                  {homeJson?.["btn-text"][this.props.lang]}
                  <img className="" src="assets/lib/images/home/arrow.svg" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wmt overflow-hidden">
        <div className="custom_container">
          <div className="row align-items-center">
            <div className="col-md-6 rightboxes">
              <div className="da_text">
                {/* <h2 className=''>DATA<br /><strong className="white_content">SEARCH</strong></h2> */}
                <h2 className="">
                  {homeJson?.data[this.props.lang]}
                  <br />
                  <strong className="white_content">
                    {homeJson?.search[this.props.lang]}
                  </strong>
                </h2>
                {/* <p className=''>An interactive disaster data tool that helps to track and monitor the development status across Timor-Leste and its sub-nationals based on a set of development and investment indicators.</p> */}
                <p className="">
                  {homeJson?.["data-search-description"][this.props.lang]}
                </p>
                {/* <Link to="/dashboard/data-search" className='explore_btn '>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link> */}
                <Link to="/dashboard/data-search" className="explore_btn ">
                  {homeJson?.["btn-text"][this.props.lang]}
                  <img className="" src="assets/lib/images/home/arrow.svg" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 text-end delayboxes">
              <img
                className="imgWidth"
                src="assets/lib/images/home/data_search.svg"
              />
              {/* <div className='geoData'>
                  <GeoData dumpDataMain={this.state.fecData}/>
                </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="data_analytics last_section overflow-hidden">
        <div className="custom_container">
          <div className="row align-items-center">
            <div className="col-md-5 delayboxes">
              <img
                className="imgWidth"
                src="assets/lib/images/home/information-kiosk.svg"
              />
            </div>
            <div className="col-md-7 box">
              <div className="da_text new_datext">
                {/* <h2 className=''>DISASTER <br /><strong className='red_content'>PROFILE</strong></h2> */}
                <h2 className="">
                  {homeJson?.disaster[this.props.lang]} <br />
                  <strong className="red_content">
                    {homeJson?.profile[this.props.lang]}
                  </strong>
                </h2>
                {/* <p className=''>Compare and analyse disaster statistics accessible through compelling data visualizations to improve the understanding of disaster and development data and facilitate analyse that inform policy choices.</p> */}
                <p className="">
                  {homeJson?.["disaster-pro-para"][this.props.lang]}
                </p>
                <Link to="/dashboard/profile" className="explore_btn ">
                  {homeJson?.["btn-text"][this.props.lang]}
                  <img className="" src="assets/lib/images/home/arrow.svg" />
                </Link>
                {/* <Link to="/dashboard/profile" className='explore_btn '>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default homeTemplate;
