import { createSlice } from "@reduxjs/toolkit";

const initialState = {
glovalLevel:0,
  level:2,
  areaCode:"ASITLS",
  disasterCode:"",
  disasterName:"",
  areaName:"Timor-Leste"
}

const areaSliceName = createSlice({
    name: 'aareaName',
    initialState,
    reducers:{
        setGlovalLevel(state, action) {
            state.glovalLevel = action.payload;
        },
        setHeaderLevel(state, action) {
            state.level = action.payload;
        },
        setHeaderAreaCode(state, action) {
            state.areaCode = action.payload;
        },
        setHeaderDisasterCode(state, action) {
            state.disasterCode=action.payload
        },
        setHeaderDisasterName(state, action) {
            state.disasterName=action.payload
        },
        setHeaderAreaName(state, action) {
            state.areaName=action.payload
        },
    }
});

export const { setGlovalLevel,setHeaderLevel,setHeaderAreaCode,setHeaderDisasterCode,setHeaderDisasterName ,setHeaderAreaName} = areaSliceName.actions;

export default areaSliceName.reducer;