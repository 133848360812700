import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './features/loginSlice'
import headerReducer from './features/usersName'
import areaReducer from './features/areaSlice'
import langReducer from './features/langSlice'
import areaSliceName from "./features/areaNameSlice"
import formSliceName from './features/formStateSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    header: headerReducer,
    area: areaReducer,
    lang:langReducer,
    areaName:areaSliceName,
    formSlice:formSliceName
  },
})