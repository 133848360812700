import React, { useEffect, useRef, useState } from "react";
import './modal.css'
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';

import AdminService from '../../services/admin.service';
import Stack from '@mui/material/Stack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

function GeneratereportModal(props) {

    const [State, setState] = useState('Collector')
    
    // ===================================================== All Select Start
    const options = ["Reporting Date", "User Name", "Status", "Task Information"];
    const [selected, setSelected] = useState([]);
    const isAllSelected =
      options.length > 0 && selected.length === options.length;
  
    const handleChange = (event) => {
      const value = event.target.value;
    //   console.log(value);
      if (value === "all") {
        setSelected(selected.length === options.length ? [] : options);
        return;
      }
      // added below code to update selected options
      const list = [...selected];
      const index = list.indexOf(value);
      index === -1 ? list.push(value) : list.splice(index, 1);
      setSelected(list);
    };

    const getUser = async (event) => {
       
        await AdminService.httpGet('/data-retrieval/users/getUsersForReport').then((res)=>{
            
              if(res){
                  
                  let d = res.data.records;
                  let data=[];
                 d.forEach(element => {
                    data.push(element.name);
                 });
                //   console.log("filepath:"+data);
                  document.getElementById("rishav").innerHTML=data;
              }
          
          }).catch((err)=>{
          
          console.log(err)
          })

      };
  
    // console.log(selected)
  
    const listItem = options.map((option) => {
      return (
        <div  className="d-flex align-items-center  pb-3" key={option}>
          <Checkbox
            value={option}
            onChange={handleChange}
            checked={selected.includes(option)}
          />
          <span>{option}</span>
        </div>
      );
    });
    // ===================================================== All Select End
// ====================================================== Form Validation Start
       
 const handleSubmit = (e) => {
    e.preventDefault(); 
}

const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleDate = (newValue) => {
    setValue(newValue);
  };
// ====================================================== Form Validation End
  return (
    <>
       <section className="generate_report px-4 h-100">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="gr_dropdown">
                        <span>Task Type</span>
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                {State}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="javascript:void(0)" onClick={(e)=>{setState('Collector')}}>Collector</Dropdown.Item>
                                <Dropdown.Item href="javascript:void(0)" onClick={(e)=>{setState('Collector 2')}}>Collector 2</Dropdown.Item>
                                <Dropdown.Item href="javascript:void(0)" onClick={(e)=>{setState('Collector 3')}}>Collector 3</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="gr_settings mt-1">
                <div className="row mx-0">
                    <div className="col-md-6 px-0">
                        <div className="report_settings">
                            <h2>Report Settings</h2>
                            <div className="rs_form">
                                <ValidatorForm className="main_react_form w-100" onSubmit={handleSubmit}>         
                                    <Stack className="w-100">
                                        <div className="row align-items-center mb-40">
                                            <div className="col-md-3">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>Reporting Date</label>
                                                </span>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style datePicker">
                                                    <div className="mat-form-field-wrapper">
                                                        <ul className="rs_formlist">
                                                            <li>
                                                                <span className="date_formto">From</span>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={1}>
                                                                        <DesktopDatePicker                                                   
                                                                            inputFormat="DD/MM/YYYY"
                                                                            value={value}
                                                                            onChange={handleDate}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                            />                                                 
                                                                    </Stack>
                                                                </LocalizationProvider>    
                                                            </li>
                                                            <li>
                                                                <span className="date_formto">To</span>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={1}>
                                                                        <DesktopDatePicker                                                   
                                                                            inputFormat="DD/MM/YYYY"
                                                                            value={value}
                                                                            onChange={handleDate}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                            />                                                 
                                                                    </Stack>
                                                                </LocalizationProvider>    
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-40">
                                            <div className="col-md-3">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>User Name</label>
                                                </span>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        {/* <TextValidator className="w-100" name="indicator" id="rishav" validators={['required']} onClick={(e) => getUser()} errorMessages={['this field is required']}/> */}
                                                        <select  id="rishav" name="indicator" validators={['required']} onClick={(e) => getUser()} errorMessages={['This field is required']}>
                                                            
                                                            </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center">
                                                            <div className="col-md-3">
                                                                <span className="mat-form-field-label-wrapper">
                                                                    <label>Dump site status</label>
                                                                </span>
                                                            </div>
                                                            <div className="col-md-9 d-flex align-items-center justify-content-between">
                                                                <div className="form-group mb-0 radioform_style">                                                        
                                                                    <FormControl>
                                                                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" defaultValue="legal" name="row-radio-buttons-group"
                                                                        >
                                                                            <FormControlLabel value="legal" control={<Radio id="flexRadioDefault1" />} label="Legal"  />
                                                                            <FormControlLabel value="illegal" control={<Radio id="flexRadioDefault2" />} label="Illegal" />                                                
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                    </Stack>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 px-0">
                        <div className="report_settings">
                            <h2>Manage Columns</h2>
                            <div className="rs_list">                                
                                <div className="d-flex align-items-center pb-3">
                                    <Checkbox value="all" onChange={handleChange} checked={isAllSelected} />
                                    <span>All</span>
                                </div>
                                {listItem}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="gr_button">
                <div className="row">
                    <div className="col-md-12"> 
                        <Button variant="contained" className="gr_download">Download Report</Button>                       
                    </div>                    
                </div>
            </div>           
       </section>
    </>
  );
              
}
export default GeneratereportModal;
