import React, { useEffect, useRef, useState } from 'react';
import './addidata.css'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CheckboxTree from 'react-checkbox-tree';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';




// class AddiDataScreenModal = (props) => {

    

    

    // const [state, setState] = useState({
    //     checked: [],
    //     expanded: [],
    //     clicked: []
    // });

    

    

    
const Label = ({children}) => <span>{children}</span>;

class AddiDataScreenModal extends React.Component {
        
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);


//     const Label = ({children}) => <span>{children}</span>;
//     const nodes = [{
//         value: 'adolescentTonnes',
//         label: <><Label>adolescent, Tonne/s  <img className='adp_indicator' onClick={handleShow} src='assets/lib/images/home/indicator.svg' /></Label></>,
//         children: [
//             { value: 'selectall', label: 'Select All' },
//             { value: 'populationofdelhi', label: 'population of delhi' },
//         ],
//     },
//     {
//         value: 'adolescentTonnes1',
//         label: 'adolescent1, Tonne/s ',
//         children: [
//             { value: 'selectall1', label: 'Select All' },
//             { value: 'employees', label: 'employees' },
//         ],
//     }
// ];

//     const [state, setState] = useState({
//         checked: [],
//         expanded: [],
//         clicked: []
//     });

//     const [value, setValue] = useState('1');

//     const handleChange = (event, newValue) => {
//       setValue(newValue);
//     };

constructor() {
    super();
    this.state = {
        checked: [],
        expanded: [],
        clicked: [],
        value: '1',
        show: false
      };

      this.nodes = [{
        value: 'adolescentTonnes',
        label: <><Label>adolescent, Tonne/s  <img className='adp_indicator' onClick={this.handleShow} src='assets/lib/images/home/indicator.svg' /></Label></>,
        children: [
            { value: 'selectall', label: 'Select All' },
            { value: 'populationofdelhi', label: 'population of delhi' },
            ],
        },
        {
            value: 'adolescentTonnes1',
            label: 'adolescent1, Tonne/s ',
            children: [
                { value: 'selectall1', label: 'Select All' },
                { value: 'employees', label: 'employees' },
            ],
        }
        ];
}

handleClose = () => this.setState({show: false});
handleShow = () => this.setState({show: true});
handleChange = (event, newValue) => {
    this.setState({value: newValue});
}
render() {
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="" className='tabsList '>
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>1</div>
                                        <div className='adph_text'>Indicator</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                    } value="1" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>2</div>
                                        <div className='adph_text'>Area</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                    } value="2" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>3</div>
                                        <div className='adph_text'>Time Period</div>
                                        <div className='adph_count'>01</div>
                                    </div>
                                    } value="3" />
                                <Tab label={
                            <div className='d-flex align-items-end'>
                                <div className='adph_number'>4</div>
                                <div className='adph_text'>Source</div>
                                <div className='adph_count'>01</div>
                            </div>
                            } value="4" />                       
                        </TabList>
                        <div className='info-rest-icons pr-4'>
                                <ul>
                                    <li>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <img src="assets/lib/images/home/information.svg" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='ius_information'>
                                                        <h4>IUS</h4>
                                                        <ul>
                                                            <li>No IUS selected</li>
                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Area</h4>
                                                        <ul>
                                                            <li>No Area selected</li>
                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Time Period</h4>
                                                        <ul>
                                                            <li>No Time Period selected</li>
                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Source</h4>                                                        
                                                        <p className='nodata'>No Source selected</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    <li><img src="assets/lib/images/home/reset-icon.svg" /></li>
                                </ul>
                            </div>
                    </Box>
                    <TabPanel value="1">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <CheckboxTree
                            nodes={this.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        />
                    </TabPanel>

                    <TabPanel value="2">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <CheckboxTree
                            nodes={this.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        />
                    </TabPanel>
                    <TabPanel value="3">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <CheckboxTree
                            nodes={this.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        />
                    </TabPanel>
                    <TabPanel value="4">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
                                    <FormControlLabel control={<Checkbox />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autocomplete="off" name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <CheckboxTree
                            nodes={this.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
            <div className='addiDataFooter'>
                <div className='adfBtn adfleft disabled'>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
                <div className='adfok'>
                    OK
                </div>
                <div className='adfBtn adfRight'>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
            </div>



      {/* ================================================================ Modal ======================================================= */}
        <Modal centered show={this.state.show} onHide={this.handleClose} className="addiDataPopup indicator_popup">
            <Modal.Header closeButton>
              <Modal.Title>adolescent1 | <span>Tonne/s</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>    
                <div className='no_data'><p>No Data</p></div>
            </Modal.Body>            
        </Modal>
      {/* ================================================================ Modal ======================================================= */}












        </>
    );
                        }
}

export default AddiDataScreenModal;