/* eslint-disable no-loop-func */
import { Component } from "react";
import "./tracker.css";
import * as jsx from "./tracker.module.jsx";
import adminService from "../../../services/admin.service";
import "react-toastify/dist/ReactToastify.css";
import eventBus from "../../../features/eventBus";
import * as $ from "jquery";
import { connect } from "react-redux";
import AdminService from "../../../services/admin.service";
import {setHeaderAreaName,setHeaderLevel,setHeaderDisasterCode,setGlovalLevel,setHeaderAreaCode,setHeaderDisasterName} from "../../../features/formStateSlice"
import {setAreaName,setDisasterTypeName,setDisasterTypeCode} from '../../../features/areaNameSlice'
import {setAreaCode} from '../../../features/areaSlice'
class TrackerPage extends Component {
  route = this.props?.location?.pathname
  constructor(props) {
    super(props);
    this.rangeData = [];
    this.state = {
      sourceDataIndex:null,
      value: 0,
      indicatorType: (
        <>
          Number of houses destroyed <span>| Number</span>
        </>
      ),
      totalType: "Total",
      yearType: "2021",
      loader: false,
      changeAreaName: "ASITLS",
      setValue: null,
      downloadTableData: [],
      filterTableData:[],

      data: [],
      tableData: [],
      areaObject: null,
      callData:[],
      show:false,

      mainAreaData: [],
      selectIndicator: {
        id: "",
        name: "",
        unit: "",
      },
      subGroupData: [],
      timePeriodData: [],
      selectedLevel:[],
      subGroupSelected: {
        id: "",
        name: "",
      },
      slectedPeriod: "",
      defOptions: [],
      source: [],
      dataRanges: [],
      labelOn: 1,
      metadata: [],
      selectedMetaInfo: [],
      searchValue:"",
      searchValueData:[],
      levelData:[],
      currentLevelValue:2,
      currentSource:"",
      isLevel: false,
      levelArr: [],
      tableDataExit:[],
      areaName:this.props?.formStateAreaName,
      areaCode:this.props?.formStateAreaCode,
      disaterName:this.props?.formStateDisasterName,
      areaLabel:this.props?.formStateLevel,
      filterChartDataTable:[],
      sourceStoredata:"",
      mainFilterArray:[]
    };

    this.chartRefs = "";
    this.index = [];
    this.chartLoaded = [];

    this.defOption = {
      title: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 14,
          fontFamily: "Mulish",
          fontWeight: "400",
        },
        text: "Loading...",
        left: "center",
        top: "center",
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          type: "bar",
          barMaxheight: "100px",
          barMinheight: "100px",
        },
      ],
    };
    this.charts = ["bar", "line", "pie"];
    this.chartIcon = {
      bar: "assets/lib/images/home/multi_bar_chart.svg", //assets/lib/images/charts/bar_chart.svg
      line: "assets/lib/images/home/line_chart.svg", //assets/lib/images/charts/line.svg
      pie: "assets/lib/images/home/pie_chart.svg", //assets/lib/images/charts/pie.svg
    };
    this.optionData = [];
  }

  componentDidMount() {
    $(document).on('click', '.main_drop_css  .dropdown-menu', function (e) {
      e.stopPropagation();
    });

    let flag = false;
    var legendData = [];

    eventBus.on("triggerArea", (data) => {

      this.rangeData = data.rangeArr;
      legendData.push(data.rangeArr);
      this.setState({ dataRanges: data.rangeArr, isLevel: false, currentSource:"" });
      if (!flag) {
        this.getAllAreaData();
      }
      flag = true;
    });

    // change disaster_type
    if(this.props?.disaterCodeType !==""){
      this.getIusList();
    }
    eventBus.on("selectedDisaster", (data) => {
      console.log(data?.code,"eventValue>>>>>")

      if(this.props?.disaterCodeType !==""){
        this.getIusList(data?.code);
      }
      this.getAllAreaData();
      this.onload();
      this.setState({levelData:this.props?.levelValue,currentSource:""})
    });

    eventBus.on("changeArea", (data) => {
      this.props.setHeaderLevel(data.level+1)
      this.setState({ changeAreaName: data.message, areaObject: data, currentLevelValue: data.level+1, callData:data.mainAreaTree,currentSource:"" });
      var type = "bar";
      this.renderChart(
        this.state.selectIndicator.id,
        this.state.subGroupSelected.id,
        this.state.selectedPeriod,
        type,
        data.message,
        data.level+1,
        this.state.currentSource
        
      );
      this.getAllAreaData();
    });
  }

  onload=()=>{
    this.setState({ changeAreaName: this.props?.formStateAreaName, currentLevelValue: this.props.formStateLevel  });
}

  showDropBox = () =>{
    if(this.state.show){
      this.setState({show:false})
    }
    else{
      this.setState({show:true})
    }
  }

  handleChange = (e, value) => {
    this.setState({ [e]: value });
  };

  handleTotal = (e, value) => {
    this.setState({ [e]: value });
  };

  handleYear = (e, value) => {
    this.setState({ [e]: value });
  };


  getDataForSelection(tps, iusIds, areaCode) {
    let  dataToSend = {
            time_period: tps,
            ius_id: iusIds,
            area: [areaCode],
          }

    const req = adminService
      .httpUserPost("/data-retrieval/data/get-chart-data", dataToSend)
      .then((result) => {
         
      });
  }
  areaCallBack = (areaData) => {
    if(areaData.level+1 <= 4){
    this.props.setHeaderLevel(areaData.level+1)
    var evObj = {
      message: areaData.message,
      name: areaData.name,
      // level: areaData.level,
      level:areaData.level+1,
      children: areaData.children,
    };

    this.setState({ changeAreaName: areaData.message, areaObject: evObj, currentLevelValue:areaData.level+1 });
    var type = "bar";
    this.renderChart(
      this.state.selectIndicator.id,
      this.state.subGroupSelected.id,
      this.state.selectedPeriod,
      type,
      areaData.message,
      this.state?.currentLevelValue,
      this.state.currentSource
    );
    this.getAllAreaData();
    }
  };

  getIusList(disaster_code) {
    
    this.setState({ loader: true });
    console.log(disaster_code,this.props?.disaterCodeType,"this.props?.disaterCodeType")
    const request = adminService
      .httpNoAuthGet(`/data-retrieval/data/sector-wise-data/${disaster_code === undefined ?  this.props?.disaterCodeType : disaster_code}`)
      .then((res) => {
        console.log(res,">sdvnvkjdn")
        // let tIU = res?.sectorIus?.[0]?.iusList.filter(item => (item.name?.en == "Number of deaths"));
        let tIU = res?.sectorIus?.[0]?.iusList;

        
        if (tIU !== undefined) {
          let item = tIU?.[0];
        //   console.log(res?.sectorIus?.[0]?.iusList,"asdfghjklkjh")
        //   const uniqueData = [];
        //   const seenIds = new Set();
        //   res?.sectorIus?.[0]?.iusList.map(item => {
        //     const id = item._id;
        
        //     // Check if the id is already seen
        //     if (!seenIds.has(id)) {
        //         uniqueData.push(item);
        //         seenIds.add(id);
        //     }
        //  });
            const groupedData = {};

            tIU.forEach(item => {
              const id = item._id;

              if (!groupedData[id]) {
                  groupedData[id] = {
                      ...item,
                      subgroupArray: [item.subgroup[0]]
                  };
              } else {
                  groupedData[id].subgroupArray.push(item.subgroup[0]);
              }
          });

          const resultArray = Object.values(groupedData);

          console.log(resultArray,"resultArray");


          this.setState({
            ...this.state,
            sectorIus: res.sectorIus,
            ius_list:resultArray,
            selectIndicator: {
              id: item?._id,
              name: item?.name?.[this.props?.lang],
              unit: item?.unit?.[this.props?.lang],
            },
            subGroupData: Array.isArray(item?.subgroup) ? item?.subgroup : [item?.subgroup] ,
            timePeriodData: item?.time_period_list,
            selectedPeriod: item?.time_period_list?.[0]?.time_period,
            subGroupSelected: {
              id: item?.subgroup?.[0]?._id,
              name: item?.subgroup?.[0]?.name?.[this.props?.lang],
            },
            metadata: item?.metadata == null ? [] : item?.metadata[0],  
          });

          let def = item ? item : res?.sectorIus?.[0]?.iusList?.[0];
          var type = "bar";
          this.renderChart(
            def?._id,
            def?.subgroup?.[0]?._id,
            def?.time_period_list?.[0]?.time_period,
            type,
            this.state.changeAreaName,
            this.state?.currentLevelValue,
            this.state.currentSource
          );
          this.getAllAreaData();

          this.setState({
            loader: false,
            selectIndicator: {
              id: def?._id,
			  name: def?.name[this.props?.lang]
			  ? def?.name[this.props?.lang]
			  : def?.name?.en,
              unit:  def?.unit[this.props?.lang]
			  ? def?.unit[this.props?.lang]
			  : def?.unit?.en,
            },
            subGroupData: Array.isArray(def?.subgroup) ? def?.subgroup : [def?.subgroup],
            timePeriodData: def?.time_period_list,
            selectedPeriod: def?.time_period_list?.[0]?.time_period,
            subGroupSelected: {
              id: def?.subgroup?.[0]?._id,
              name: def?.subgroup?.[0]?.name?.[this.props?.lang]?def?.subgroup?.[0]?.name?.[this.props?.lang]:def?.subgroup?.[0]?.name?.en,
            },
          });
        } else {
          this.setState({
            loader: false,
            selectIndicator: {},
            subGroupData: [],
            selectedPeriod: [],
            subGroupSelected: {},
            tableData: [],
            ius_list: res?.sectorIus?.[0]?.iusList,
          });

          let refElem = this.chartRefs?.getEchartsInstance();
          let tableData = [];

          this.optionData = {
            title: {
              show: tableData.length === 0,
              textStyle: {
                color: "grey",
                fontSize: 12,
              },
              text: "No data",
              left: "center",
              top: "center",
            },
            legend: {
              show: false,
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              bottom: "3%",
              top: "10%",
            },
            xAxis: [
              {
                show: tableData.length,
                type: "value",
                axisLabel: {
                  color: "#fff",
                },
                axisLine: {
                  lineStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            yAxis: {
              show: tableData.length,
              type: "category",
              axisLabel: {
                color: "#fff",
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.7)",
                  type: "dashed",
                },
              },
              data: [],
              inverse: true,
            },
            series: [
              {
                name: "",
                type: type,
                label: {
                  show: false,
                  position: "top",
                  formatter: function (params) {
                    return params.value.toLocaleString();
                  },
                },
                emphasis: {
                  focus: "series",
                },
                lineStyle: {
                  color: "#FFCBC6",
                  width: 1,
                },
                itemStyle: {
                  borderWidth: 1,
                  borderColor: "#FFCBC6",
                  color: "#FFCBC6",
                },
                data: [],
              },
            ],
          };
          //  
          refElem.clear();
          refElem.setOption(this.optionData);
        }
      })
      .catch((err)=>{
        console.log('ius_list',err)
      })
  }

  timePeriodChange(item) {
    this.setState({
      selectedPeriod: item?.time_period,
      currentSource:"",
    });

    var type = "bar";
    this.renderChart(
      this.state.selectIndicator.id,
      this.state.subGroupSelected.id,
      item?.time_period,
      type,
      this.state.changeAreaName,
      // this.state?.currentLevelValue
      this.props?.formStateLevel,
      this.state.currentSource
    );

    this.getAllAreaData();
  }

  indicatorChange(item) {
    const subgroupDataUniques = [];
    // console.log(this.state.currentSource,"cjcsdgfbfb")

    // if (item?.subgroup?.length === 1) {
    //   this.state.sectorIus?.[0]?.iusList?.forEach((iusItem) => {
    //     if (item?._id === iusItem?._id) {
    //       subgroupDataUniques.push(iusItem?.subgroup?.[0]);
    //     }
    //   });
    // }
    console.log(this.state.currentSource,"cjcsdgfbfb")
    

    this.setState({
      selectIndicator: {
        id: item._id,
        name: item.name[this.props?.lang]?item.name[this.props?.lang]:item.name.en,
        unit: item.unit[this.props?.lang]?item.unit[this.props?.lang]:item.unit?.en,
      },
      subGroupData:item?.subgroupArray,
      timePeriodData: item?.time_period_list,
      selectedPeriod: item?.time_period_list?.[0]?.time_period,
      subGroupSelected: {
        id: item?.subgroup?.[0]?._id,
        name: item?.subgroup?.[0]?.name[this.props?.lang],
      },
      currentSource:""
    });


    var type = "bar";
    this.renderChart(
      item._id,
      item?.subgroup?.[0]?._id,
      item?.time_period_list?.[0]?.time_period,
      type,
      this.state.changeAreaName,
      this.state?.currentLevelValue,
      this.state.currentSource
    );

  //  return;
    this.getAllAreaData();
  }

  sugroupChange(item) {
    this.setState({ subGroupSelected: { id: item._id, name: item.name[this.props?.lang] },
      currentSource:""
     });
     console.log(this.state.currentSource,">>>>111")

    // var type = this.getRandomChart();
    var type = "bar";
    this.renderChart(
      this.state.selectIndicator.id,
      item._id,
      this.state.selectedPeriod,
      type,
      this.state.changeAreaName,
      this.state?.currentLevelValue,
      this.state.currentSource
    );
    this.getAllAreaData();
  }

  setDataRange = (data) => {
    this.setState({ dataRanges: data,});
     
  };

  render() {
    return jsx.default.bind(this)();
  }
  
  getAllAreaData = () => {
    this.setState({ loader: true });

    AdminService.httpGet("/data-retrieval/area/getAreaData")
      .then((res) => {
        if (res) {
          let d = res.data;
          let testObj = { message: "", name: "", level: "", children: [] };
          let flatLevel2 = [];
          d?.forEach((item) => { 
            var obj = {
              value: item.area_code,
              label: item?.name_all?.[this.props.lang]?item?.name_all?.[this.props.lang]:item?.name_all?.en,
              parent: item.parent_id,
              level:item.level
            }
            flatLevel2.push(obj)
          })
          const list = []

          d?.forEach((item) => {
            if (item.area_code === this.state.changeAreaName) {
              testObj["message"] = item?.area_code;
              testObj["name"] = item?.name_all?.[this.props.lang];
              testObj["level"] = item?.level;
              let level = item?.level;
              level = level === 5? level - 1: level;
              
              if(window.location.hash === '#/dashboard/data-analysis'){
                for (let i=level; i<4; i++) {
                  if(i+1 ===2){
                    list.push({'level': i+1, 'labelText': 'Municipality '})
                  }else if(i+1 ===3){
                    list.push({'level': i+1, 'labelText': 'Admin Post '})
                  }else{
                    list.push({'level': i+1, 'labelText': 'Suco '})
                  }
                  // list.push({'level': i+1, 'labelText': 'Level '+(i+1)})
                }
              }

              // if(window.location.hash === '#/dashboard/data-analysis'){
              //   console.log("listt0", list,item?.level)
              //   for (let i=this.props?.formStateLevel-1; i<4; i++) {
              //     console.log("listt1", list,item?.level)
              //     if(i+1 ===2){
              //       console.log("listt2", list,item?.level)
              //       list.push({'level': i+1, 'labelText': 'Municipality '})
              //     }else if(i+1 ===3){
              //       console.log("listt3", list,item?.level)
              //       list.push({'level': i+1, 'labelText': 'Admin Post '})
              //     }else{
              //       console.log("listt4", list,item?.level)
              //       list.push({'level': i+1, 'labelText': 'Suco '})
              //     }
              //     // list.push({'level': i+1, 'labelText': 'Level '+(i+1)})
              //   }
              // }
              
            }
          });

          d?.forEach((item) => {
            if (item.parent_id === this.state.changeAreaName) {
              var dtObj = {
                label: item.name_all?.[this.props.lang],
                code: item.area_code,
                parent: item.parent_id,
                level: item.level,
                value: item.area_code,
              };
              testObj["children"].push(dtObj);
            }
          });

          this.setState({
            loader: false,
            mainAreaData: d,
            areaObject: testObj,
            levelArr: list,
            callData:flatLevel2
          });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
         
      });
  };

  findrange(data) {
    let valueData = [];
    let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];

    data?.forEach((item) => {
      if (item.code !== this.state.areaCode?.message) {
        valueData.push(+item.value);
      }
    });

    let s = new Set(valueData);
    let unicValData = [...s];

    unicValData.sort(function (a, b) {
      return a - b;
    });

    let unitVal = 1;

    var finalLegends = [];
    var collectionAreaData = [];
    if (unicValData?.length >= 4) {
      unitVal = Math.floor(unicValData?.length / 4);
    }
    let j = 0;
    let k = 0;
    let from = unicValData[0];
    let to = unicValData[0];

    for (let i = 0; i < unicValData.length; i++) {

      if (j++ >= unitVal && k < 3) {
        j = 0;
        let obj = {
          color: colors[k++],
          value: "" + from + " - " + to,
        };

        from = to + 1;
        finalLegends.push(obj);
      }

      let valMapData = data?.filter(
        (item) =>
          item.value === unicValData[i] &&
          item.code !== this.state.areaCode?.message
      );

      valMapData.forEach((item) => {
        let obj = {
          color: colors[k],
          value: item.value,
          area: item.area,
          code: item.code,
        };
        collectionAreaData.push(obj);
      });

      to = unicValData[i];
    }

    let obj = {
      color: colors[k++],
      value: "" + from + " - " + to,
    };

    finalLegends.push(obj);

    return finalLegends;

  }

  equalSizeBreak(dataarr) {
    dataarr = dataarr.filter((e) => e !== "-");
    let colorArr = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];
    let findZero = dataarr.filter((e) => e === "0");
    let min = Math.min.apply(Math, dataarr);
    let max = Math.max.apply(Math, dataarr);
    let res = [];
    let chunkCount = findZero.length ? 1 : 4;
    let diff = Math.round((max - min) / chunkCount);

    if (!isNaN(min) || !isNaN(max) || !isNaN(diff)) {
      let tempfrom = min;
      let tempto = max;

      for (let i = 0; i < chunkCount; i++) {
        if (tempfrom !== tempto) {
          let newtoval = tempfrom + diff;
          let obj = {};
          if (i === 3) {
            obj = { from: tempfrom, to: tempto, color: colorArr[i] };
          } else {
            obj = { from: tempfrom, to: newtoval, color: colorArr[i] };
          }
          res.push(obj);
          tempfrom = newtoval + 1;
        }
      }

    }

    res.push({
      from: null,
      to: null,
      name: "no data",
      color: "#DDDDDD",
      stroke: "#555555",
    });

    return res;
  }


   renderChart(iuId, iusIds, tps, type, areaName, level, curSource) {

    this.setState({ loader: true });

    var areaNameData = [];
    areaNameData = areaName?.split(",");
    let refElem = this.chartRefs?.getEchartsInstance();
    let dataToSend

    if(this.props.formStateGlovalLevel < 4){
      dataToSend = {
        time_period: [tps],
        ius_id: [iusIds],
        area:  [this.props?.formStateAreaName],
        disasterCode:  this.props?.formStateAreaCode,
        level: this.props.formStateLevel
      };
    }
    
    else{
      this.props?.setHeaderAreaCode('ASITLS')
      this.props?.setHeaderLevel(2)
      this.props?.setHeaderDisasterCode(this.props?.formStateAreaCode)
      this.props?.setGlovalLevel(0)
      this.props?.setHeaderAreaName("Timor-Leste")
      this.props?.setHeaderDisasterName('INUNDASAUN')

      dataToSend = {
        time_period: [tps],
        ius_id: [iusIds],
        area:  ['ASITLS'],
        disasterCode:  this.props?.formStateAreaCode,
        level:2
      };

    }

    if(dataToSend?.ius_id[0] !== '' && dataToSend?.area[0] !== ''){

    AdminService.httpUserPost("/data-retrieval/data/get-table-data", dataToSend)
      .then((res) => {
        if (res) {

          let filterLevelData = res?.data.filter(ele => ele.code === areaNameData[0] || ele.level === this.props.formStateLevel)

          let d = filterLevelData;
          let emptyArray = [];
          let areaArr = [];
          let dataArr = [];
          let finalArr = [];
        
          for (let i = 0; i < d?.length; i++) {

            let elem = d[i];
            let datajson = {};
            let exitKey= elem?.hasOwnProperty("source_lang")

            if(exitKey){
              setTimeout(()=>{
                datajson.areaName = elem?.area_all[this.props?.lang]?  elem?.area_all[this.props?.lang]:elem?.area_all?.en;
                datajson.areaData = elem?.value;
                datajson.source = elem?.source_lang ==="" ? elem?.source_lang:elem?.source_lang[this.props?.lang];
                datajson.timeperiod = tps;
                datajson.subgroup = this.state.subGroupSelected.name;
                datajson.indicator = this.state.selectIndicator.name;
                datajson.areaCode = elem?.code;
                datajson.unit = this.state.selectIndicator.unit;
                datajson.disasterName= this.props?.disasterNameType;
              },1000)
            }else{
              setTimeout(()=>{
                datajson.areaName = elem?.area_all[this.props?.lang]?  elem?.area_all[this.props?.lang]:elem?.area_all?.en;
                datajson.areaData = elem?.value;
                datajson.timeperiod = tps;
                datajson.subgroup = this.state.subGroupSelected.name;
                datajson.indicator = this.state.selectIndicator.name;
                datajson.areaCode = elem?.code;
                datajson.unit = this.state.selectIndicator.unit;
                datajson.disasterName= this.props?.disasterNameType;
              },1000)
            }
          
            if(elem?._id !== ""){

              let obj = {
                value: +elem?.value,
                name: elem?.area_all[this.props?.lang]?  elem?.area_all[this.props?.lang]:elem?.area_all?.en,
                code: elem?.code,
                source_lang:elem?.source_lang
              };

              dataArr.push(obj);
            }  
           
            if (elem?.code !== areaName) {
              finalArr.push(+elem?.value);
            }
         
            emptyArray.push(datajson);
          }

          let range = this.findrange(d);

          dataArr.forEach((elem) => {

            let colorBydata = range.filter(
              (x) =>
                x.value.split(" - ")[0] <= +elem?.value &&
                x.value.split(" - ")[1] >= +elem?.value
            );

            elem.itemStyle = {
              color: colorBydata.length === 0 ? "#FFCBC6" : colorBydata[0].color,
            };
          });

          dataArr.sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else if (a.value < b.value) {
              return 1;
            } else {
              return 1;
            }
          });


          let filtered_dataArr1 = dataArr.filter((e) => e.code !== areaName );
          // let agregatedSource = filtered_dataArr1?.filter(dt=> {return dt?.source_lang?.en.toLowerCase()?.includes("aggregated")})
          let agregatedSource = filtered_dataArr1?.filter(dt=> {return dt?.source_lang?.en.toLowerCase()})
          console.log(agregatedSource[0],"111bb",curSource,"curSourcezz")
          // let finalSource= curSource!=="" && curSource!==undefined? curSource: agregatedSource[0]?.source_lang?.en;
          let finalSource=  agregatedSource[0]?.source_lang?.en;

          let dfSource = finalSource!=='' && finalSource!==undefined ? finalSource : filtered_dataArr1?.[0]?.source_lang?.en;
     
          let filtered_dataArr = filtered_dataArr1.filter((e) => e?.source_lang?.en?.toLowerCase().includes(dfSource?.toLowerCase()) );

          if (filtered_dataArr.length > 0 && filtered_dataArr.length < 5) {

            for (var i = filtered_dataArr.length; i <= 10; i++) {
              filtered_dataArr.push({ value: "", name: "", code: "" });
            }

          }

          areaArr = filtered_dataArr.map((ele) => ele.name);

        //  console.log('jfgjkdfkfdkui',curSource,  agregatedSource,filtered_dataArr1)

          console.log(finalSource,">>>>>bbb");
          console.log(filtered_dataArr1,">>>>>>aaa")
          this.setState({
            // currentSource:finalSource=='' && finalSource!==undefined ? finalSource : filtered_dataArr1?.[0]?.source_lang?.en,
            tableDataExit:filtered_dataArr,
            currentSource: filtered_dataArr1?.[0]?.source_lang?.en,
            mainFilterArray:filtered_dataArr1
          })

          this.optionData = {
            title: {
              show: filtered_dataArr.length === 0,
              textStyle: {
                color: "#fff",
                fontSize: 12,
                fontWeight: 400,
              },
              text: "No data",
              left: "center",
              top: "center",
            },
            legend: {
              show: false,
              textStyle: {
                color: "#fff",
              },
            },
            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              containLabel: true,
              left: "10%",
              bottom: "3%",
              top: "10%",
            },
            xAxis: [
              {
                type: "value",
                axisLabel: {
                  color: "#fff",
                },
                axisLine: {
                  lineStyle: {
                    color: "#fff",
                  },
                },
                splitLine: {
                  show: false,
                },
                show: false,
              },
            ],
            yAxis: {
              show: filtered_dataArr.length,
              axisTick: false,
              type: "category",
              axisLabel: {
                color: "#fff",
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.7)",
                  type: "dashed",
                },
              },
              data: areaArr ,
              inverse: true,
            },
           dataZoom: {
             start: 1,
             yAxisIndex: [0],
              type: "inside"
            },
            series: [
              {
                name: "",
                type: type,
                label: {
                  color: "#fff",
                  show: this.state.labelOn ? false : true,
                  position: "outside",
                  style: {
                    textOutline: 0,
                  },
                },
                lineStyle: {
                  color: "#FFCBC6",
                  textOutline: 0,
                  width: 1,
                },
                itemStyle: {
                  borderWidth: 0,
                  borderColor: "#FFCBC6",
                  textOutline: 0,
                  color: "#FFCBC6",
                },
                data: filtered_dataArr,
              },
            ],
          };

          refElem?.clear();
          refElem?.setOption(this.optionData);
          d.sort((a, b) => {

            if (a.value > b.value) {
                return 1
            } else if (a.value < b.value) {
                return 1
            } else {
                return 1
            }
          });

        

          let filtered_d = d.filter((e) => e?.source_lang?.en?.toLowerCase().includes(dfSource?.toLowerCase()) );
        
          console.log('ssa8',filtered_d, d)
          // let filtered_dataArr = filtered_dataArr1.filter((e) => e?.source_lang?.en?.toLowerCase().includes(dfSource?.toLowerCase()) );

          

          d.forEach((item) => {
            if(item._id === ""){
              // eslint-disable-next-line no-self-assign
              item.value =item?.value;
              item.area = item?.area_all[this.props?.lang]?  item?.area_all[this.props?.lang]:item?.area_all?.en
            }
            else{
              item.value = Number(item.value);
              item.area = item?.area_all[this.props?.lang]?  item?.area_all[this.props?.lang]:item?.area_all?.en
            }
          });

         let filterTableData=[]
          // eslint-disable-next-line array-callback-return
          filtered_d.map((item)=>{
            const addJson={};
            if(item._id === ""){
              addJson.value = "-";
              addJson.area = item?.area_all[this.props?.lang]?  item?.area_all[this.props?.lang]:item?.area_all?.en
            }else{
              addJson.value = Number(item.value);
              addJson.area = item?.area_all[this.props?.lang]?  item?.area_all[this.props?.lang]:item?.area_all?.en
            }
            filterTableData.push(addJson)
          })

          let splicedData;
          let concatedFilterTableData ;
          // eslint-disable-next-line array-callback-return
          filterTableData.map((data,i)=>{
            if(data.area === this.props.areaName){
              splicedData = filterTableData.splice(i, 0);
            }
          })

          if(splicedData?.length > 0){
            concatedFilterTableData = splicedData?.concat(filterTableData)

          }
          else{
            concatedFilterTableData = filterTableData

          }

        
          console.log('ds4343', splicedData,  concatedFilterTableData,filterTableData)

          this.setState({
            loader: false,
            tableData: d,
            downloadTableData: emptyArray,
            currentLevelValue: level,
            filterTableData:concatedFilterTableData
          });
         
        }

      })
      .catch((err) => {
        this.setState({ loader: false });
         console.log('tabledata',err);
      });
    }
  }


  sourceChange = (value)=>{
  

    var type = "bar";
    this.renderChart(
      this.state.selectIndicator.id,
      this.state.subGroupSelected.id,
      this.state.selectedPeriod,      
      
      type,
      this.state.changeAreaName,
      this.props.formStateLevel,
      value

      );


      this.setState({
        currentSource:value
      })

      console.log(value,'sasasa')
  }



  abc(e, isChecked) {

    let refElem = this.chartRefs?.getEchartsInstance();
    let isLabel = "aa";

    if (isChecked) {
      refElem?.setOption({
        series: [
          {
            label: {
              color: "#fff",
              show: true,
              position: "outside",
            },
          },
        ],
      });
      isLabel = 0;
    } else {
      refElem?.setOption({
        series: [
          {
            label: {
              show: false,
              position: "outside",
            },
          },
        ],
      });
      isLabel = 1;
    }
     
    this.setState({ labelOn: isLabel });
  }

  // aaa = (e, data) => {
    
  //   let meta = this.state.ius_list.filter(e => e._id === data.id)
  //   this.setState({ selectedMetaInfo: meta[0]?.metadata[0]})
  //   $("#exampleModal").modal("show");

  // };

  aaa = (e, data) => {
    let meta = this.state.ius_list.filter(e => e._id === data.id);
    this.setState({ selectedMetaInfo: meta[0]?.metadata[0] }, () => {
        console.log(this.state.metadata, "this.state.metadata");
        $("#exampleModal").modal("show");
    });
  };


  levelChange= async (e)=>{

    let levelItem = await e.target.value;

    this.setState({
      currentLevelValue: levelItem,
      isLevel:true
    });

    await this.props.setHeaderLevel(levelItem)

    var type = "bar";
    this.renderChart(
      this.state.selectIndicator.id,
      this.state.subGroupSelected.id,
      this.state.selectedPeriod,      
      
      type,
      this.state.changeAreaName,
      levelItem,
      this.state.currentSource
      );
    };

  }

function mapStateToProps(state) {

    return {
      area:state.area?.areaCode,
      lang: state.lang?.lang ,
      disasterNameType:state?.areaName?.disaterName,
      disaterCodeType:state?.areaName?.disastercode,
      levelValue:state?.currentLevel?.level,
      areaName:state?.areaName?.areaName,
      formStateAreaName:state?.formSlice?.areaCode,
      formStateAreaCode:state?.formSlice?.disasterCode,
      formStateDisasterName:state?.formSlice?.disasterName,
      formStateLevel:state?.formSlice?.level,
      formStateGlovalLevel:state?.formSlice.glovalLevel
    }
 }

 const mapDispatchToProps = { setAreaCode,setHeaderDisasterName,setHeaderAreaName,setHeaderLevel ,setHeaderDisasterCode,setHeaderAreaCode,setGlovalLevel,setAreaName,setDisasterTypeName,setDisasterTypeCode};

export default connect(mapStateToProps, mapDispatchToProps)(TrackerPage);
