import React, { useEffect, useRef, useState } from "react";
import './modal.css'
import * as constants from '../../Config/app.constants';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmptyObject } from "jquery";
import $ from 'jquery';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
function AddAreaModal(props) {

  const form = useRef()

  $(document).on('click', 'someyourContainer .dropdown-menu', function (e) {
    e.stopPropagation();
  });

  const token = useSelector((state) => state.login.token)
  let editData = { _id: null, name: { "en": '' }, area_code: null };
  const [, forceUpdate] = useState();
  const [show, setShow] = useState(false);
  const [data, setdata] = useState([]);
  const [showList, setAreaListShow] = useState(false);
  const [showDrop, setParentList] = useState(false);
  const [callData, setParentAreaData] = useState([]);
  const [cTree, setcTreeData] = useState({ currentItem: { ...callData[0] } })
  const [treeData, setTreeData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [parentID, setParentId] = useState();
  const [selectedDrop, setSelectedName] = useState('Select Parent Area');
  const [editFormData, setFormData] = useState(editData);
  // const [areaCode, setAreaCode] = useState({ areaCode: '', areaName: '' });


  const [FormState, setFormState] = useState(
    {
      id: null,
      pareaCode: '',
      pareaID: '',
      plevel: '',
      pareaName: '',
      areaName: '',
      areaId: '',
      oldAreacode: ''


    });

  useEffect(() => {
    // console.log('gdd', props.editData[0])

    if (props.editData == null || props.editData == undefined || props.editData.length == 0) {

      setFormState(
        {
          ...FormState, id: null,
          pareaCode: '',
          pareaID: '',
          plevel: '',
          pareaName: '',
          areaName: '',
          areaId: '',
          oldAreacode: ''


        })

    } else {
      let data = props.editData[0];


      let parentArea = {};


      if (data.level === 1) {
        parentArea = {};
        // console.log('dtmn1', data.level)
      }
      else {

        props.sendData.map(item => {

          if (item.area_code == data.parent_id) {
            parentArea = item;
            // console.log('dtmn2', parentArea)
            // setAreaCode({areaName:parentArea, areaCode:item.area_code });
            // forceUpdate();
            // console.log(areaCode);
          }

        })


      }


      // setAreaCode({areaName:parentArea });
      // console.log(areaCode)
      let pareaCode = data.pareaCode;
      let pareaName = data.pareaName;
      if (parentArea.area_code) {
        pareaCode = parentArea.area_code;
        pareaName = parentArea.name;
      }

      setFormState(
        {
          ...FormState, id: data._id,
          pareaCode: data.parent_id,
          pareaID: pareaCode,
          plevel: data.level,
          pareaName: pareaName,
          areaName: data.name,
          areaId: data.area_code,
          oldAreacode: data.area_code
        });
      // forceUpdate();

    }






    if (props.sendData != '' && props.sendData != null) {

      let flatLevel = [];


      props.sendData.forEach((item) => {


        item.areaSubData = [];

        flatLevel.push(item)


      })


      // console.log('level1', flatLevel)
      const data = setLevelData(flatLevel)
      const levelCount = getHowManyLevel(data);


      setParentAreaData(data)




    }
  }, [props]);


  // area tree sctructure=========================//

  const getHowManyLevel = (obj) => {
    let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
    while (/}{/g.test(res)) {
      res = res.replace(/}{/g, '')
    }
    return res.replace(/}/g, '').length
  }




  const setLevelData = (flat) => {

    var roots = []

    var all = {}

    flat.forEach(function (item) {
      all[item.area_code] = item
    })
    Object.keys(all).forEach(function (area_code) {
      var item = all[area_code]
      if (item.parent_id === null || item.parent_id === '') {
        roots.push(item)
      } else if (item.parent_id in all) {
        var p = all[item.parent_id]
        if (!('areaSubData' in p)) {
          p.areaSubData = []
        }
        p.areaSubData.push(item)
      }
    })

    return roots
  }
  const state = {
    open: false,
  };


  const handleChange = (event) => {

    setFormState({ ...FormState, [event.target.name]: event.target.value });


  }

  // send form data to parent ====//

  const onAreaSubmit = (event) => {
    event.preventDefault();

let found = props.sendData?.filter(item=>{
  return FormState.areaId==item.area_code && FormState.id!=item._id;
   

})



    if(found?.length < 1){

 
    let dataTobe;
    if (FormState.id != null) {

      dataTobe = {
        id: FormState.id,
        areaName: FormState.areaName,
        areaCode: FormState.areaId,
        parentId: FormState.pareaCode,
        level: FormState.plevel,
        oldAreacode: FormState.oldAreacode,
        status: 1
      }


    }
    else {


      dataTobe = {
        id: null,
        areaName: FormState.areaName,
        areaCode: FormState.areaId,
        parentId: FormState.pareaCode,
        level: FormState.plevel==""? 1:FormState.plevel,
        status: 1
      }


    }

    // console.log("test", dataTobe)
    props.parentCallback(dataTobe);

    setFormState({
      ...FormState,
      id: null,
      pareaCode: '',
      pareaID: '',
      plevel: '',
      pareaName: '',
      areaName: '',
      areaId: '',
    })
  }else{
      toast.error('Area id already exist!')
      return false;
  }

  }

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }

  // show area list===============//

  const showAreaList = () => {
    if (showList) {
      setAreaListShow(false)
    }
    else {
      setAreaListShow(true)
    }
  }


  const openParentDrop = () => {
    if (showDrop) {
      setParentList(false)


    }
    else {
      setParentList(true)
    }
  }




  // const [areaDetail, setareaDetail] = useState({
  //   id: null,
  //   areaName: '',
  //   areaCode: '',
  //   parentId: '',
  //   level: 1,
  //   status: 1
  // })


  // // useEffect(() => {
  // //   console.log("cccc");
  // // }, []);

  // let name, value
  // const inputhandle = (e) => {
  //   // console.log("ee-" ,e.target.value);
  //   name = e.target.name;
  //   value = e.target.value;
  //   setareaDetail({ ...areaDetail, [name]: value });
  //   console.log(areaDetail);
  // };




  //   const saveArea = async(e)=>{
  //     if(data != ''){

  //         updatearea(data._id)

  //     }
  //     else{
  //         // addarea(e);
  //     }

  //   }


  ///add api




  function onclickParent(id, code) {

    // console.log('id', id, 'code', code)
  }


  //////////<==========get area data api =================>///////
  const getData = () => {
    // console.log("DSfdgS");
    // this.setState({loader:true})S
    fetch(constants.server + "api/data-retrieval/area/getAreaData", {
      method: 'get',
      headers: new Headers({
        'Authorization': token,
        'Content-Type': 'application/json'
      })
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data", data.data);
        setdata(data.data)
      })
      .catch((err) => {
        // this.setState({loader:false})
        // console.log(err.message);
      });
  }


  const renderTree = (nodes) => (

    nodes.map((item) => {
      return (<>

        <TreeItem
          className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
          key={item.area_code} nodeId={item._id} label={item.name}
          onClick={() => {
            setFormState({
              ...FormState,
              pareaCode: item.area_code,
              pareaName: item.name,
              plevel: item.level + 1,
              pareaID: item._id
            })
          }} >

          {Array.isArray(item.areaSubData) && item.areaSubData != null
            ? renderTree(item.areaSubData)
            : null}
        </TreeItem>

      </>);


    })


  );



  return (
    <>



      <div className="modal fade" id="area_id_area_name" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document" >
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title area_id_title" id="exampleModalLabel" >Map Area List</h5>
              <button aria-label="Close" onClick={getData} className="close" data-dismiss="modal" type="button">
                <span aria-hidden="true" >
                  <img src="assets/lib/images/cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body p-4">
              <table className="table table-striped">
                <thead >
                  <tr className="d-flex w-100">
                    <th className="b-none a">Area Name</th>
                    <th className="b-none b">Area ID</th>
                  </tr>
                </thead>

                <tbody className="table_scroll">


                  {
                    data.map((item) => {
                      return (
                        <>
                          <tr className="d-flex w-100 ng-star-inserted" >
                            <td className="border-none a">
                              {item.name}
                            </td>
                            <td className="border-none b">

                              {item.area_code}

                            </td>
                          </tr>
                        </>
                      )

                    })
                  }

                </tbody>



              </table>
            </div>
          </div>
        </div>
      </div>



      <div className="area-form">

        <ValidatorForm
          className="main_react_form w-100"
          ref={form}
          onSubmit={onAreaSubmit}
        >


          <Stack spacing={2} sx={{ width: 300 }} className="w-100">

            {/* <form onSubmit={onAreaSubmit}
          noValidate="" style={{ 'position': 'relative' }} id="addAreaForm" className="ng-untouched ng-pristine ng-invalid" > */}
            {/* {props.editMode!=null &&
            <input type="hidden" id="isEditID" name="isUpdate" value={editFormData._id}/>
  } */}

            {/* {props.editMode==null && */}
            <input type="hidden" id="editIDBox" name="isUpdate" value={null} />
            {/* } */}



            <div className="gallery-collections mt-0">
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Parent Area</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style ifs_dropdown parentArea">
                    <div className="mat-form-field-wrapper">

                      <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                          <TextValidator
                            className=" w-100"


                            label=""
                            onChange={handleChange}

                            name="pareaName"
                            value={FormState.pareaName}
                         
                          />

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <TreeView
                            disabled={props.editData?.[0]?.level == 1 ? true : false}
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}

                            sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                          >
                            {renderTree(callData)}

                          </TreeView>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label ng-tns-c5-39 mat-empty mat-form-field-empty ng-star-inserted" id="mat-form-field-label-33" htmlFor="mat-input-16" aria-owns="mat-input-16">
                      Area ID </label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <TextValidator
                        className="w-100"

                        onChange={handleChange}
                        name="areaId"
                        value={FormState.areaId}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />




                    </div>
                  </div>

                </div>

                <div
                  className="col-md-3 pl-0">
                  <div
                    className="">
                    <div
                      className="tree-select-indicator-header area_drop im_area">
                      <div
                        className="dropdown">
                        <span onClick={getData}
                          className="map_area_a" data-target="#area_id_area_name" data-toggle="modal">
                          <img
                            className="cursor-pointer" src="assets/lib/images/eyes-view.svg" />
                          View Map Area List</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="area-form">
                <div className="row m-t-b">
                  <div className="col-md-12">
                  </div>
                </div>
                <span className="text-danger parent_area_code">
                </span>

              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label ng-tns-c5-38 mat-empty mat-form-field-empty ng-star-inserted" id="mat-form-field-label-31" htmlFor="mat-input-15" aria-owns="mat-input-15">   Area Name
                    </label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">

                      <TextValidator
                        className="w-100"

                        onChange={handleChange}
                        name="areaName"
                        value={FormState.areaName}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />





                    </div>

                  </div>
                </div>
              </div>


            </div>


            <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                <Button variant="contained" type="submit" className="addbtn">
                  {FormState.id != null ? 'Update' : 'Add'}
                </Button>
              </div>

              {/* <button type="submit" className="btn btn-blue1 btn-block text-white mat-flat-button mat-button-base mat-warn ng-star-inserted" color="warn" mat-flat-button="">
                <span className="mat-button-wrapper">

                {props.editMode!=null? 'Update Area': 'Add'}

                  
                </span>
                <div
                  className="mat-button-ripple mat-ripple"
                  matripple=""
                ></div>
                <div className="mat-button-focus-overlay"></div>
              </button> */}

            </div>
            {/* </form> */}
          </Stack>
        </ValidatorForm>
      </div>





    </>

  );
}

export default AddAreaModal;
