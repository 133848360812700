import React, { Component } from "react";
import "./data-analytics.css";
import * as jsx from "./data-analytics.module.jsx";
import * as constants from "../../../Config/app.constants";
import adminService from "../../../services/admin.service";
import eventBus from "../../../features/eventBus";
import "react-toastify/dist/ReactToastify.css";
import domtoimage from "dom-to-image";
import { connect } from "react-redux";
import * as $ from "jquery";
import types from "react-cropper";
import {setGlovalLevel} from "../../../features/formStateSlice"
import disasterProfileJSON from "../../../Config/disasterProfile.json";
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";
import { ContactlessOutlined } from "@mui/icons-material";

class DataanalyticsPage extends Component {
  constructor() {
    super();
    this.currentAreaCode = "ASITLS";
    
    this.colors = {
      0: "#A6170A",
      1: "#108348",
      2: "#0E9650",
      3: "#009F4E",
      4: "#10B260",
      5: "#12BD66",
      6: "#0AC163",
      7: "#30D07E",
      8: "#37E98E",
      9: "#65F6AC",
      10: "#9DFFCD",
      11: "#BEFFDE",
    };

    this.state = {
      age: -1,
      chartType: "assets/lib/images/home/multi_chart_icon1.svg",
      value: 0,
      loader: false,
      isCounting: 0,
      selectedItem: [],
      selectvalue: "2022",
      timeperiodvalue: "Total",
      defOptions: [],
      selectedOptions: [],
      indicatorData: [],
      sectorIus: [],
      sectorMap: {},
      areaList: [],
      ius_list: {},
      optionData: [],
      timePeriods: [],
      // subgroups: [],
      subgroupsLabel: [],
      selectedOptionsTP: [],
      selectedOptionsSG: [],
      source: [],
      labelon: false,
      url: "none",
      sector: "",
      iuId: "",
      buttonType: "",
      chartType: [],
      currentAreaName: "Timor-Leste",
      defChecked: true,
      selectedChartType: "",
      selectedCharId: "",
      addTableData: [],
      ogSectorIus: [],
      searchText: "",
      search: false,
      saveBtnDisabledOrEnabled: true,
      // loading : "",
      filterDataBySource:'All',
      allSources:{},
      selectedSrc: {},
      selectedSource: {},
      timeperiods:{},
      subgroups:{}
    };

    this.chartRefs = [];
    this.index = [];
    this.chartLoaded = [];

    this.defOption = {
      title: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 14,
          fontFamily: "Mulish",
          fontWeight: "400",
        },
        text: "Loading...",
        left: "center",
        top: "center",
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        show: false,
      },
      series: [],
    };
    this.charts = ["bar", "line", "pie"];
    this.chartIcon = {
      bar: "assets/lib/images/home/bar-chart.svg", //assets/lib/images/charts/bar_chart.svg
      line: "assets/lib/images/home/line_chart.svg", //assets/lib/images/charts/line.svg
      pie: "assets/lib/images/home/pie_chart.svg", //assets/lib/images/charts/pie.svg
      table: "assets/lib/images/home/white_tb.svg", // assets/lib/images/home/table.svg
    };
    this.optionData = [];
  }

  items = ["2022"];
  timeperiod = ["Total"];

  componentWillUnmount() {
    eventBus.remove("changeArea");
  }

  onSelect(sector, iuId, path, type, headingName) {
    // console.log('onselect',sector,iuId,path,type,headingName);
    let chartTypes = this.state.chartType;
    chartTypes[sector + iuId] = { path: path, type: type };

    this.setState({ chartType: chartTypes });
    this.getChartForSubgroup(sector, iuId, type );
    this.setState({ selectedChartType: type, selectedCharId: headingName });
  }

  handleChange = (event) => {
    this.setState({ age: event.target.value });
  };

  checkChanges = (event) => {
    let checkedIds = [];
    var values3 = $('input[name="abc[]"]')
      .map(function () {
        checkedIds.push($(this).is(":checked"));

        return $(this).is(":checked");
      })
      .get();

      
    let isChecked = !values3.includes(false);
    $(".allChecked").prop("checked", isChecked);
    this.setState({ defChecked: isChecked });
    //  console.log('changes',checkedIds.includes(true))

    this.setState({ saveBtnDisabledOrEnabled: checkedIds.includes(true) });
    // console.log("arr2", values3, checkedIds, isChecked)
  };

  handleSelect = (event) => {
    // console.log("search", event.target.checked)
    $('input[name="abc[]"]').prop("checked", event.target.checked);

    let checkedIds = [];
    // $(".cust_ius").val("")
    var values3 = $('input[name="abc[]"]')
      .map(function () {
        checkedIds.push($(this).is(":checked"));

        return $(this).is(":checked");
      })
      .get();

    if (!event.target.checked) {
      $('input[name="xyz[]"]').each(function () {
        this.value = "";
      });
    } else {
      $('input[name="xyz[]"]').each(function () {
        this.value = $(this).data("value");
      });
    }

    this.setState({ saveBtnDisabledOrEnabled: checkedIds.includes(true) });
    // console.log('handleselect',checkedIds,checkedIds.includes(true),this.state.saveBtnDisabledOrEnabled )
  };
  checkSelectedIndicator() {
    // console.log(this.state.ogSectorIus, this.state.sectorIus);
    // $(".chkbx_"+2).prop("checked", true);

    $('input[name="xyz[]"]').each(function () {
      this.value = $(this).data("value");
    });

    this.state.ogSectorIus[0].iusList.forEach((x, i) => {
      if (this.state.sectorIus[0].iusList.some((y) => x._id == y._id)) {
        this.handleKeyPress(i);
      } else {
        $(".chkbx_" + i).prop("checked", false);
      }
    });
  }

  handleKeyPress = (index) => {
    // console.log(index);
    $(".chkbx_" + index).prop("checked", true);
    let checkedIds = [];
    var values3 = $('input[name="abc[]"]')
      .map(function () {
        checkedIds.push($(this).is(":checked"));

        return $(this).is(":checked");
      })
      .get();

    let isChecked = !values3.includes(false);
    $(".allChecked").prop("checked", isChecked);
    this.setState({ defChecked: isChecked });
    //  console.log('changes',checkedIds, values3, isChecked)

    this.setState({ saveBtnDisabledOrEnabled: checkedIds.includes(true) });
  };

  checkAll = (event) => {
    $('input[name="abc[]"]').prop("checked", true);
    $(".allChecked").prop("checked", true);

    let checkedIds = [];
    var values3 = $('input[name="abc[]"]')
      .map(function () {
        checkedIds.push($(this).is(":checked"));

        return $(this).is(":checked");
      })
      .get();

    this.setState({ saveBtnDisabledOrEnabled: checkedIds.includes(true) });
  };

  getSuffleData = (event) => {
    $("#customize_indicator").modal("hide");
    this.setState({ loader: true });
    // console.log("suffle", event)
    // console.log("suff", $("input[name='xyz[]']").value, $("input[name='xyz[]']"))
    let arr2 = [];
    let checkedIds = [];
    var values3 = $('input[name="abc[]"]')
      .map(function () {
        let d1 = JSON.parse(this.getAttribute("data-chkids"));
        d1.isEnabled = $(this).is(":checked");
        checkedIds.push($(this).is(":checked"));
        arr2.push(d1);
        return this.getAttribute("data-chkids");
      })
      .get();
    // console.log("arr2", arr2, checkedIds)

    let arr = [];
    var values2 = $('input[name="xyz[]"]')
      .map(function () {
        let v1 = Number(this.value);
        // console.log(v1)
        if (v1 != 0) {
          this.setAttribute("data-value", v1);
        }
        let d1 = JSON.parse(this.getAttribute("data-ids"));
        d1.index1 = Number(this.value);
        arr.push(d1);
        return this.getAttribute("data-ids");
      })
      .get();

    arr.sort((a, b) => {
      if (a.index1 > b.index1) {
        return 1;
      } else if (a.index1 < b.index1) {
        return -1;
      } else return 1;
    });

    let qwe = arr.filter((e) => {
      let q = arr2.filter((w) => w._id == e._id);
      if (q.length) {
        e.isEnabled = q[0].isEnabled;
      }
    });

    // arr.sort(function (a, b) {
    //   return b.isEnabled - a.isEnabled || a.index1 - b.index1;
    // });
    // shuffledData
    let finalArr = arr.filter((e) => e.isEnabled == true);
    // let getFilterData=finalArr<1? arr :finalArr
    let key = this.state.sectorIus[0];
    let obj = {
      _id: key._id,
      level_id: key.level_id,
      level_name: key.level_name,
      status: key.status,
      type: key.type,
      createdAt: key.createdAt,
      updatedAt: key.updatedAt,
      iusList: finalArr,
    };

    this.setState({ loader: false, sectorIus: [obj] });
    this.initializeCharts([obj]);

    // console.log("suffl", this.state, obj, arr);
  };

  search = (value) => {
    let input = value;
    input = input.toLowerCase();
    let x = document.getElementsByClassName("search");

    for (var i = 0; i < x.length; i++) {
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "none";
      } else {
        x[i].style.display = "";
      }
    }
  };

  //  componentWillReceiveProps = (newProps)=>{

  //    console.log("This is language check--", disasterProfileJSON?.loading?.[newProps.lang])
  //  }

  componentDidMount() {

    // console.log('useeffectcalled')
    
    if (this.props?.disaterCodeType !== "") {
      this.getData();
      this.getkeyFact(this.props?.disaterCodeType);
    }

    eventBus.on("selectedDisaster", (data) => {
      let disaster_code = data.code;
      if (this.props?.disaterCodeType !== "") {
        this.getData(disaster_code);
        this.getkeyFact(disaster_code);
      }

      // this.getkeyFact(disaster_code);
      // this.getData(disaster_code);
    });

    eventBus.on("changeArea", (data) => {
      // console.log("Sudhanshu data:", data.name);
      this.setState({
        loader: true,
        isCounting: this.state.isCounting + 1,
        currentAreaName: data.name,
      });
      // console.log('chageareadata',data);
      this.currentAreaCode = data.message;
      $(window).scrollTop(0);
      this.initializeCharts();
      this.getkeyFact();
      this.setState({ loader: false });
    });
    let that = this;

    $(window).on("scroll", () => {
      let height = $(window).height();
      let scrollY = $(window).scrollTop();
      let multiple = Math.floor(scrollY / height);
      if (multiple > 0) {
        let startInd = multiple * 4 - 2;
        // console.log(multiple, "multiple", startInd);

        for (let i = startInd; i <= startInd + 4; i++) {
          if (i in that.index && !that.chartLoaded[i]) {
            // console.log("loading value");
            that.chartLoaded[i] = true;
            
            this.getChartForSubgroup(that.index[i].sector, that.index[i].iuId );
          }
        }
      }
    });

  }

  getkeyFact(disastercode) {
    this.setState({
      loader:true,
    })
    const dataToBeSend = [

      {
        "subgroup": "Total",
        "unit": "Number",
        "indicator": "Number of affected households"
    },
    {
        "subgroup": "Total",
        "unit": "Number",
        "indicator": "Number of family members injured"
    },
    {
        "subgroup": "Total",
        "unit": "Number",
        "indicator": "Number of family members lost"
    },
    {
        "subgroup": "Total",
        "unit": "Number",
        "indicator": "Number of chronic sick people in the household"
    }



      // {
      //   subgroup: "Total",
      //   unit: "Number",
      //   indicator: "Number of affected houses",
      // },
      // {
      //   subgroup: "Total",
      //   unit: "Number",
      //   indicator: "Number of deaths",
      // },
      // {
      //   subgroup: "Total",
      //   unit: "Number",
      //   indicator: "Number of injured people",
      // },
      // {
      //   subgroup: "Total",
      //   unit: "Number",
      //   indicator: "Number of missing people",
      // },
    ];
    let obj = {
      selectedIUS: dataToBeSend,
      // areacode: this.currentAreaCode,
      // disaster: this.props?.disaterCodeType,
      areacode: this.props?.formStateAreaName,
      disaster: disastercode,
    };

    adminService
      .httpUserPost("/data-retrieval/data/get-selected-keyfact", obj)
      .then((res) => {
        if (res) {
          let resData = [];
          res.data.forEach((el) => {
            // console.log("This is response data---==> ", res);
            let sector = el.sector.replace(/\s+/g, "-").toLowerCase();
            let obj = {
              name: el.indicator,
              unit: el.unit,
              period: el.timeperiod,
              subgroup: el.subgroup,
              value: el.value != "" ? Number(el.value) : "",
              source: el.source,
              metaData: el.metadata,
              sector: sector,
              iu_id: el.iu_id,
            };
            resData.push(obj);
          });
          this.setState({
            loader: false,
            indicatorData: resData,
            isCounting: this.state.isCounting + 1,
          });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  }

  getData(disaster_code) {
    // console.log(
    //   "this.props?.disaterCodeType",
    //   disaster_code == "",
    //   disaster_code
    // );
    this.setState({ loader: true });
    // console.log(
    //   "this.props?.disaterCodeType",
    //   this.props?.disaterCodeType,
    //   disaster_code
    // );
    // console.log(disaster_code, this.props?.disaterCodeType);
    const request = adminService
      // .httpNoAuthGet("/data-retrieval/data/sector-wise-data/" + this.props?.disaterCodeType)
      .httpNoAuthGet(
        `/data-retrieval/data/sector-wise-data/${
          disaster_code == undefined
            ? this.props?.disaterCodeType
            : disaster_code
        }`
      )
      .then((res) => {
        let sIus = res.sectorIus.map((x) => {
          console.log('sectorIus',x)
          x.iusList = x.iusList.filter((y) => y.isData != 0);
          return x;
        });
        this.setState({
          ...this.state,
          sectorIus: sIus,
          ogSectorIus: sIus,
          sectorMap: res.sectorMap,
          areaList: res.areaList,
          ius_list: res.ius_list,
        });

        this.getChart();
        this.initializeCharts();
        this.setState({ loader: false });
      });
  }

  getChart() {
    // debugger;
    let res = this.state.sectorIus;
    let timePeriods = [];
    let subgroups = {};
    let subgroupsLabel = [];
    let chartTypes = [];
    // console.log(res);
    res?.forEach((sector) => {
      sector.iusList?.forEach((iu) => {
        let tp = iu.time_period_list.map((item) => {
          return { label: item.time_period, value: item.time_period };
        });
        timePeriods[sector.level_name.en + iu._id] = [
          { label: disasterProfileJSON?.all?.[this.props?.lang], value: "*" },
          ...tp,
        ];
        
        subgroups[sector.level_name.en + iu._id] = iu.subgroup;
        chartTypes[sector.level_name.en + iu._id] = {
          path: this.chartIcon["bar"],
          type: "bar",
        };
        let sg = iu.subgroup
          .filter((it) => {
            if (it.name?.en == null) {
              return false;
            }
            return true;
          })
          .map((item) => {
            // return {'label': item.name.en, 'value' : item._id};
            return { label: item.name?.[this.props?.lang], value: item._id };
          });
        subgroupsLabel[sector.level_name.en + iu._id] = [
          { label: disasterProfileJSON?.all?.[this.props?.lang], value: "*" },
          ...sg,
        ];
      });
    });
    console.log("Sudhanshu timePeriods", timePeriods);
    this.setState({
      timePeriods: timePeriods,
      subgroups: subgroups,
      subgroupsLabel: subgroupsLabel,
      chartType: chartTypes,
    });
  }

  initializeCharts(sIUS = null) {
    // console.log("this.state", this.state)
    let ind = [];
    let chrtLoaded = [];
    let sectorIUS = sIUS ? sIUS : this.state.sectorIus;
    let initNoData = {};
    sectorIUS.map((sector, indValue) => {
      sector.iusList?.map((iu) => {
        ind.push({ sector: sector.level_name.en, iuId: iu._id });
        chrtLoaded.push(false);
        let stp = this.state.selectedOptionsTP;
        stp[sector.level_name.en + iu._id] =
          this.state.timePeriods[sector.level_name.en + iu._id];
        initNoData[sector.level_name.en + iu._id] = false;
        this.setState({ selectedOptionsTP: stp });

        let ssg = this.state.selectedOptionsSG;
        ssg[sector.level_name.en + iu._id] =
          this.state.subgroupsLabel[sector.level_name.en + iu._id];
        this.setState({
          selectedOptionsSG: ssg,
          isCounting: this.state.isCounting + 1,
        });
        // this.getChartForSubgroup(sector.level_name, iu._id);
      });
      this.setState({
        responsedChart : initNoData
      });
      this.index = ind;
      this.chartLoaded = chrtLoaded;
      if (ind.length > indValue) {
        // console.log(ind.length,"ind.length")
      }
      // console.log(ind.length,"ind.length")
      if (ind.length > indValue) {
        
        this.chartLoaded[indValue] = true;
        this.getChartForSubgroup(
          this.index[indValue].sector,
          this.index[indValue].iuId
        );
      }
      if (ind.length > 1) {
        
        this.chartLoaded[1] = true;
        this.getChartForSubgroup(this.index[1].sector, this.index[1].iuId);
      }
    });

    // this.getkeyFact()
  }

  getRandomChart() {
    let i = Math.floor(Math.random() * this.charts.length);
    return this.charts[i];
  }

  getChartForSubgroup(sector, iuId, type = "random", flag = true) {
    // debugger;
    let elem = this.chartRefs[sector + iuId]?.getEchartsInstance();
    let iusIds = [];
    let ssg = this.state.selectedOptionsSG[sector + iuId].filter(
      (item) => item.value !== "*"
    );
    ssg?.map((ius) => {
      iusIds.push(ius.value);
    });
    let stp = Array.isArray(this.state.selectedOptionsTP[sector + iuId])
      ? this.state.selectedOptionsTP[sector + iuId].filter(
          (item) => item.value !== "*"
        )
      : [];
    let tps = [];
    stp?.map((tp) => {
      tps.push(tp.value);
    });
    // debugger;
    // console.log("9999", type, tps, iusIds, this.currentAreaCode);
    if (iusIds.length == 0 || tps.length == 0) {
      this.optionData[sector + iuId] = {
        title: {
          show: true,
          textStyle: {
            color: "#fff",
            fontSize: 14,
            fontFamily: "Mulish",
            fontWeight: "400",
          },
          text: `${disasterProfileJSON.noData[this.props.lang]}`,
          left: "center",
          top: "center",
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [],
        source: "",
      };

      elem.clear();
      elem.setOption(this.optionData[sector + iuId]);
      return;
    }

    if (type == "random") {
      type = this.getRandomChart();
    }
    let chartTypes = this.state.chartType;
    chartTypes[sector + iuId] = { path: this.chartIcon[type], type: type };
    this.setState({ chartType: chartTypes });

    this.renderChart(sector, iuId, iusIds, tps, type, flag);
    // console.log(sector, iuId, iusIds, tps, type,"sector, iuId, iusIds, tps, type")
  }

  renderChart(sector, iuId, iusIds, tps, type) {
    // console.log('dataAnalytics',this.props?.formStateAreaName,this.props?.formStateAreaCode)
    // console.log(sector, iuId, iusIds, tps, type);
    this.setState({
      loader:true,
    })
    let elem = this.chartRefs[sector + iuId]?.getEchartsInstance();
    let dataToSend = {
      time_period: tps,
      ius_id: iusIds,
      // area: [this.currentAreaCode],
      // disaster: this.props.disaterCodeType,
      area: [this.props?.formStateAreaName],
      disaster: this.props?.formStateAreaCode,
    };
    // console.log("this.props", this.props);
    // console.log('getchartdatacalled');
    // console.log('reload',this.props.formStateGlovalLevel)
    this.setState({loader: true});
    const req = adminService
      .httpPost("/data-retrieval/data/get-chart-data", dataToSend)
      .then((result) => {
        // console.log('get-chart-data',result?.data?.length);
        // this.setState({ responsedChartData:result.data})
        let obj = this.state.responsedChart;
        if(result?.data?.length > 0) {
          obj[sector+iuId] = true;
        }        
        this.setState({
          responsedChart:obj
        })
        // console.log('get-chart-data',obj);
        let image = document.getElementById("img" + sector + iuId);
        if (image) {
          image.src = "assets/lib/images/home/label_on.svg";
        }

        let dataForTable = {};
        let adddata = [];
        console.log('type',type)
        if (type == "table") {
          result?.data?.forEach((x) => {
            // console.log('table',x);
            let dataJsonAdd = {};
            dataJsonAdd.first = x.time_period?.start_time_period;
            dataJsonAdd.sec = x.value;
            dataJsonAdd.third = x?.indicator?.en;
            dataJsonAdd.fourth =
              x?.subgroupData?.subgroup?.name?.[this.props.lang];
            dataJsonAdd.fiveth = x?.source_lang?.[this.props.lang];
            adddata.push(dataJsonAdd);

            if (dataForTable[x.time_period.start_time_period] == undefined) {
              dataForTable[x.time_period.start_time_period] = {
                [x.subgroupData.subgroup.name?.[this.props.lang]]: x.value,
              };
              dataForTable[x.value] = { [x.value]: x.value };
            } else {
              dataForTable[x.time_period.start_time_period][
                x.subgroupData.subgroup.name?.[this.props.lang]
              ] = x.value;
              // dataForTable[x?.time_period?.new_value][x?.new_value] = x?.new_value;
            }
          });
          // addTableData
          this.setState({ addTableData: adddata,loader:false });
          // console.log(this.state.addTableData,"dataJsonAdd")
        }

        if (result.data && result.data.length > 0) {
          //if time period is single and subgroup is single
          // if (tps.length == 1 && iusIds.length == 1) {
            if (result.data.length==1) {
            // $(`#div-${sector}${iuId}`).find(".da_dropdown").hide();
            this.optionData[sector + iuId] = {
              title: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 50,
                  fontFamily: "Mulish",
                  fontWeight: "700",
                },
                text: (+result.data?.[0]?.value)?.toLocaleString() ,
                left: "center",
                top: "center",
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
              source: "",
            };
            // console.log('tps.length',tps.length)
          } else if (tps.length == 1 && iusIds.length > 1) {
            // debugger;
            //if time period is single and subgroups are multiple then subgroups will come on x-axis
            // console.log('tps.length',tps.length)
            let iusArr = [];
            let vData = [];
            let legendData = [];
            let colorPalette = [
              "#440A05",
              "#7D1B12",
              "#B8372B",
              "#CF655B",
              "#ED9C94",
              "#FFCBC6",
            ];
            let tpName = "";

            if (type == "bar") {
              let seri = [];
              let i = 0;
              result.data.forEach((row) => {
                iusArr.push(
                  row.subgroupData?.subgroup?.name?.[this.props.lang]
                );
                vData.push(+row.value);
                tpName = row.time_period?.start_time_period;
                seri.push({
                  name: row.subgroupData?.subgroup?.name?.[this.props.lang],
                  type: type,
                  // stack: 'Total',
                  data: [+row.value],
                  label: {
                    show: false,
                    position: "top",
                    formatter: function (params) {
                      return (+params.value).toLocaleString();
                    },
                    color: "#fff",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  symbolSize: 10,
                  lineStyle: {
                    color: colorPalette[i],
                    width: 5,
                    opacity: 1,
                  },
                  smooth: true,
                  itemStyle: {
                    borderWidth: 1,
                    borderColor: colorPalette[i],
                    color: colorPalette[i],
                  },
                });
                i = i++ > 5 ? 0 : i;
              });
              this.optionData[sector + iuId] = {
                legend: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
                tooltip: {
                  trigger: "item",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                grid: {
                  containLabel: true,
                  left: "10%",
                  bottom: "3%",
                  top: "10%",
                },
                xAxis: [
                  {
                    type: "category",
                    data: [tpName],
                    axisLabel: {
                      color: "#fff",
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#fff",
                      },
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value",
                    axisLabel: {
                      color: "#fff",
                    },
                    splitLine: {
                      lineStyle: {
                        color: "rgba(255, 255, 255, 0.7)",
                        type: "dashed",
                      },
                    },
                  },
                ],
                series: seri,
              };
            } else if (type == "line") {
              let seri = [];
              let i = 0;
              result.data.forEach((row) => {
                iusArr.push(
                  row.subgroupData?.subgroup?.name?.[this.props.lang]
                );
                vData.push(+row.value);
                tpName = row.time_period?.start_time_period;
                seri.push({
                  name: row.subgroupData?.subgroup?.name?.[this.props.lang],
                  type: type,
                  // stack: 'Total',
                  data: [+row.value],

                  label: {
                    show: false,
                    position: "top",
                    formatter: function (params) {
                      return (+params.value).toLocaleString();
                    },
                    color: "#fff",
                  },
                  symbol: "circle",
                  symbolSize: 12,
                  lineStyle: {
                    color: "440A05",
                    width: 20,
                    opacity: 1,
                  },
                  // emphasis: {
                  //   focus: "series",
                  //   itemStyle: {
                  //     color: '#440A05'
                  //   }
                  // },
                  smooth: true,
                  itemStyle: {
                    borderWidth: 1,
                    borderColor: "#440A05",
                    color: "#440A05",
                  },
                });
                i = i++ > 5 ? 0 : i;
              });
              this.optionData[sector + iuId] = {
                legend: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
                tooltip: {
                  trigger: "item",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                grid: {
                  containLabel: true,
                  left: "10%",
                  bottom: "3%",
                  top: "10%",
                },
                xAxis: [
                  {
                    type: "category",
                    data: [tpName],
                    axisLabel: {
                      color: "#fff",
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#fff",
                      },
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value",
                    axisLabel: {
                      color: "#fff",
                    },
                    splitLine: {
                      lineStyle: {
                        color: "rgba(255, 255, 255, 0.7)",
                        type: "dashed",
                      },
                    },
                  },
                ],
                series: seri,
              };
            } else if (type == "pie") {
              console.log('inpie1',result.data,this.currentAreaCode)
              result.data.forEach((row) => {
                if (row.area_code === this.currentAreaCode) {
                  iusArr.push(
                    row.subgroupData?.subgroup?.name?.[this.props.lang]
                  );
                  vData.push(row.value);
                  tpName = row.time_period?.start_time_period;
                }
              });
              this.optionData[sector + iuId] = {
                tooltip: {
                  trigger: "item",
                },
                legend: {
                  type: "scroll",
                  orient: "vertical",
                  right: 10,
                  top: 20,
                  bottom: 20,
                  data: iusArr,
                  formatter: function (name) {
                    return name.length > 17
                      ? name.substring(0, 17) + "..."
                      : name;
                  },
                  tooltip: {
                    show: true,
                    trigger: "item",
                  },
                  textStyle: {
                    color: "#fff",
                  },
                },
                series: [
                  {
                    name: tpName,
                    type: "pie",
                    // radius: '75%',
                    center: ["40%", "50%"],
                    data: vData,
                    color: colorPalette,
                    label: {
                      show: false,
                      color: "#fff",
                    },
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                      },
                    },
                  },
                ],
              };
            } else if (type == "table") {
              this.optionData[sector + iuId] = {
                tableData: dataForTable,
              };
            }
          } else {
            // console.log('tps.length',tps.length,iusIds.length)
            let colorPalette = [
              "#440A05",
              "#7D1B12",
              "#B8372B",
              "#CF655B",
              "#ED9C94",
              "#FFCBC6",
            ];
            //if time period is multiple then it will come on x-axis
            if (type == "line") {
              let tpArr = [];
              let vData = [];
              let sgNames = [];
              let finalData = {};
              result.data.forEach((row) => {
                let tp = row.time_period?.start_time_period;
                let sgName =
                  row.subgroupData?.subgroup?.name?.[this.props.lang];
                if (!tpArr.includes(tp)) {
                  tpArr.push(tp);
                }
                if (!sgNames.includes(sgName)) {
                  sgNames.push(sgName);
                }
              });

              for (let j = 0; j < tpArr.length; j++) {
                let vd = {};
                let td = result.data.filter(
                  (x) => x.time_period.start_time_period == tpArr[j]
                );
                let flag = {};
                sgNames?.forEach((sg) => {
                  let remainder = td.filter(
                    (x) =>
                      x.subgroupData?.subgroup?.name?.[this.props?.lang] == sg
                  );
                  if (!(sg in finalData)) {
                    finalData[sg] = [];
                  }
                  if (remainder?.length > 0) {
                    finalData[sg][j] = +remainder[0].value;
                  } else {
                    finalData[sg][j] = NaN;
                  }
                });
              }

              let seri = [];
              let i = 0;
              sgNames.forEach((sg, i) => {
                seri.push({
                  name: sg,
                  data: finalData[sg],
                  type: type,

                  label: {
                    show: false,
                    position: "top",
                    formatter: function (params) {
                      return (+params.value).toLocaleString();
                    },
                    color: "#fff",
                  },
                  // emphasis: {
                  //   focus: "series",
                  //   itemStyle: {
                  //   color: "#440A05",
                  // },
                  // },
                  symbol: "circle",
                  symbolSize: 12,
                  lineStyle: {
                    width: 5,
                    opacity: 1,
                  },
                  smooth: true,
                  itemStyle: {
                    borderWidth: 1,
                    borderColor: "#440A05",
                    color: "#440A05",
                  },
                });
                i = i++ > 5 ? 0 : i;
              });

              this.optionData[sector + iuId] = {
                tooltip: {
                  trigger: "item",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                legend: {
                  data: sgNames,
                  // orient: 'vertical',
                  // top: 'center',
                  // icon: 'rect',
                  // right: 0,
                  bottom: 0,
                  // height: 'auto',
                  type: "scroll",
                  show: true,
                  formatter: function (name) {
                    return name.length > 17
                      ? name.substring(0, 17) + "..."
                      : name;
                  },
                  tooltip: {
                    show: true,
                    trigger: "item",
                  },
                  textStyle: {
                    color: "#fff",
                  },
                },
                grid: {
                  containLabel: true,
                  left: "10%",
                  // height: 'auto',
                  // bottom: '3%',
                  top: "10%",
                  y2: 30,
                },
                xAxis: {
                  type: "category",
                  data: tpArr,
                  axisLabel: {
                    color: "#fff",
                  },
                  axisLine: {
                    lineStyle: {
                      color: "#fff",
                    },
                  },
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    color: "#fff",
                  },
                  splitLine: {
                    lineStyle: {
                      color: "#fff",
                      type: "dashed",
                    },
                  },
                },
                toolbox: {
                  show: false,
                  orient: "vertical",
                  left: "right",
                  top: "center",
                  feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: true },
                    magicType: { show: true, type: ["line"] },
                    // restore: { show: true },
                    saveAsImage: { show: true, title: "Download" },
                  },
                },
                series: seri,
              };
            } else if (type == "bar") {
              let tpArr = [];
              let vData = [];
              let sgNames = [];
              let finalData = {};
              result.data.forEach((row) => {
                let tp = row.time_period?.start_time_period;
                let sgName =
                  row.subgroupData?.subgroup?.name?.[this.props.lang];
                if (!tpArr.includes(tp)) {
                  tpArr.push(tp);
                }
                if (!sgNames.includes(sgName)) {
                  sgNames.push(sgName);
                }
              });

              for (let j = 0; j < tpArr.length; j++) {
                let vd = {};
                let td = result.data.filter(
                  (x) => x.time_period.start_time_period == tpArr[j]
                );
                let flag = {};
                sgNames?.forEach((sg) => {
                  let remainder = td.filter(
                    (x) =>
                      x.subgroupData?.subgroup?.name?.[this.props.lang] == sg
                  );
                  if (!(sg in finalData)) {
                    finalData[sg] = [];
                  }
                  if (remainder?.length > 0) {
                    finalData[sg][j] = +remainder[0].value;
                  } else {
                    finalData[sg][j] = NaN;
                  }
                });
              }

              let seri = [];
              let i = 0;
              sgNames.forEach((sg, i) => {
                seri.push({
                  name: sg,
                  data: finalData[sg],
                  type: type,
                  label: {
                    show: false,
                    position: "top",
                    formatter: function (params) {
                      return (+params.value).toLocaleString();
                    },
                    color: "#fff",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  symbolSize: 10,
                  lineStyle: {
                    color: colorPalette[i],
                    width: 5,
                    opacity: 1,
                  },
                  smooth: true,
                  itemStyle: {
                    borderWidth: 1,
                    borderColor: colorPalette[i],
                    color: colorPalette[i],
                    //borderColor: this.colors[i],
                    //color: this.colors[i]
                  },
                });
                i = i++ > 5 ? 0 : i;
              });

              this.optionData[sector + iuId] = {
                tooltip: {
                  trigger: "item",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                legend: {
                  data: sgNames,
                  // orient: 'vertical',
                  // top: 'center',
                  // icon: 'rect',
                  // right: 0,
                  bottom: 0,
                  // height: 'auto',
                  type: "scroll",
                  show: true,
                  formatter: function (name) {
                    return name.length > 17
                      ? name.substring(0, 17) + "..."
                      : name;
                  },
                  tooltip: {
                    show: true,
                    trigger: "item",
                  },
                  textStyle: {
                    color: "#fff",
                  },
                },
                grid: {
                  containLabel: true,
                  left: "10%",
                  // height: 'auto',
                  // bottom: '3%',
                  top: "10%",
                  y2: 30,
                },
                xAxis: {
                  type: "category",
                  data: tpArr,
                  axisLabel: {
                    color: "#fff",
                  },
                  axisLine: {
                    lineStyle: {
                      color: "#fff",
                    },
                  },
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    color: "#fff",
                  },
                  splitLine: {
                    lineStyle: {
                      color: "#fff",
                      type: "dashed",
                    },
                  },
                },
                toolbox: {
                  show: false,
                  orient: "vertical",
                  left: "right",
                  top: "center",
                  feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: true },
                    magicType: { show: true, type: ["bar"] },
                    // restore: { show: true },
                    saveAsImage: { show: true, title: "Download" },
                  },
                },
                series: seri,
              };
            } else if (type == "pie") {
              console.log('inpie2')
              let legendData = [];
              let colorPalette = [
                "#440A05",
                "#7D1B12",
                "#B8372B",
                "#CF655B",
                "#ED9C94",
                "#FFCBC6",
              ];
              let vData = [];
              let seri = [];
              result.data.forEach((row) => {
                if (row.area_code == this.currentAreaCode) {
                  let tp = row.time_period?.start_time_period;
                  let sgName =
                    row.subgroupData?.subgroup?.name?.[this.props.lang];
                  legendData.push(sgName + "-" + tp);
                  seri.push({
                    name: sgName + "-" + tp,
                    value: row.value,
                  });
                }
              });

              this.optionData[sector + iuId] = {
                tooltip: {
                  trigger: "item",
                },
                legend: {
                  type: "scroll",
                  orient: "vertical",
                  right: 10,
                  top: 20,
                  bottom: 20,
                  data: legendData,
                  formatter: function (name) {
                    return name.length > 17
                      ? name.substring(0, 17) + "..."
                      : name;
                  },
                  tooltip: {
                    show: true,
                    trigger: "item",
                  },
                  textStyle: {
                    color: "#fff",
                  },
                  pageIconColor: "rgba(255, 255, 255, 1)",
                    // pageIconInactiveColor: "rgba(225, 225, 225, 1)",
                    pageIconSize: [13, 13],
                    pageTextStyle: {
                      color: "rgba(255, 255, 255, 1)",
                      fontSize: 12
                    }
                },
                series: [
                  {
                    name: "",
                    type: "pie",
                    // radius: '55%',
                    center: ["40%", "50%"],
                    data: seri,
                    color: colorPalette,
                    label: {
                      show: false,
                      color: "#fff",
                    },
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                      },
                    },
                  },
                ],
              };
            } else if (type == "table") {
              this.optionData[sector + iuId] = {
                tableData: dataForTable,
              };
            }
          }

          let src = this.state.source;
          src[sector + iuId] = result.data[0]?.source_lang?.[this.props.lang]??result.data[0]?.source_lang?.['en'];
        
          this.setState({ source: src });
        } else {
          if (type == "table") {
            this.optionData[sector + iuId] = {
              tableData: dataForTable,
            };
          } else {
            this.optionData[sector + iuId] = {
              title: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Mulish",
                  fontWeight: "400",
                },
                text: `${disasterProfileJSON.noData[this.props.lang]}`,
                left: "center",
                top: "center",
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
              source: "",
            };
          }
        }
        elem?.clear();
        elem?.setOption(this.optionData[sector + iuId]);
        this.setState({loader: false});
        // console.log("vvvv", type, this.optionData[sector + iuId]);
        // console.log(this.chartRefs, this.optionData);
      });
  }

  labelChart(sector, iuId, selcttype) {
    // console.log(selcttype, "get current value");

    let image = document.getElementById("img" + sector + iuId);

    if (image.getAttribute("src") === "assets/lib/images/home/label_on.svg") {
      image.src = "assets/lib/images/home/label-off.svg";
    } else {
      image.src = "assets/lib/images/home/label_on.svg";
    }
    this.setState({labelon: !(this.state.labelon)});

    let ref = this.chartRefs[sector + iuId];
    if (ref) {
      let ele = ref.getEchartsInstance();
      let option = this.optionData[sector + iuId];
      let newSeries = [];
      option?.series?.forEach((seri) => {
        seri.label = {
          show: !seri.label.show,
          position: "top",
          formatter: function (params) {
            return (+params.value).toLocaleString();
          },
          color: "#fff",
        };
        newSeries.push(seri);
      });
      option.series = newSeries;
      ele.clear();
      this.optionData[sector + iuId] = option;
      ele.setOption(option);
    }
    $(".chartLabelcollapse ").removeClass("show");
  }

  getUrlFromService = (sector, iuId) => {
    return new Promise((resolve, reject) => {
      this.setState({ loader: true });
      let node1 = document.getElementById("div-" + sector + iuId);
      let node2 = document.getElementById("copy");
      node2.innerHTML = node1.innerHTML;
      node2.style.display = "block";
      $("#copy").find(".da_dropdown").remove();
      $("#copy").find(".maps_source").remove();
      // $("#copy").find(".social_link").remove();
      $("#copy").find(".download_source").show();
      // $("#copy").find(".hArea").html(`<p>${this.state.currentAreaName}</p>`);
      // $("#copy").find(".hArea").show();
      $("#copy").find(".hArea")[0].style.display = "block";
      // $("#copy").find(".hArea")[1].style.display = "block";
      domtoimage.toBlob(node2).then((blob) => {
        let file = [];
        let bfile = "";
        let path = "";
        // this.saveFile(blob);
        getBase64(blob).then((result) => {
          file["base64"] = result;
          let b = file.base64;

          bfile = b.split(",").pop();

          path = "" + sector + iuId + ".png" + ";" + bfile;

          node2.style.display = "none";
          let dataToSend = {
            image: path,
          };
          adminService
            .httpPost("/data-import/resources/add-image", dataToSend)
            .then((res) => {
              this.setState({ loader: false });
              if (res.success == true) {
                resolve(constants.resourceImagePath + res.file);
              } else {
                reject(null);
              }
            });
        });
      });
    });
  };

  onClickHandler = (btnType, sector, iuId) => {
    $(".chartLabelcollapse").removeClass("show");
    $(".shareBtn").removeClass("show");
    $(
      ".chartLabel .chartLabelcollapse .card.card-body ul li.multiCollapseShare"
    ).addClass("collapsed");
    // Be sure to check for the "none" state, so we don't trigger an infinite loop.
    if (this.state.url === "none") {
      this.getUrlFromService(sector, iuId).then(
        (newUrl) => {
          this.setState({
            url: newUrl,
            sector: sector,
            iuId: iuId,
            buttonType: btnType,
          });
        },
        (newUrl) => {
          this.setState({ url: "none", sector: "", iuId: "", buttonType: "" });
        }
      );
    }
  };

  dropdownClose = () => {
    $(".shareBtn").removeClass("show");
    $(
      ".chartLabel .chartLabelcollapse .card.card-body ul li.multiCollapseShare"
    ).addClass("collapsed");
  };

  componentDidUpdate() {
    if (this.state.url != null && this.state.url != "none") {
      $(
        "." + this.state.buttonType + this.state.sector + this.state.iuId
      ).trigger("click");
      this.setState({ url: "none", sector: "", iuId: "", buttonType: "" });
    }
  }

  downloadChart = (sector, iuId, indName) => {
    $(".chartLabelcollapse ").removeClass("show");
    $(".multi_chart_drop").hide();
    $(".react_drop").hide();
    // let ref = this.chartRefs[sector+iuId];
    // if(ref){
    //   let ele = ref.getEchartsInstance();
    //   let option = this.optionData[sector+iuId]
    //   option.legend.type = 'plain'
    //   option.legend.rigth = '0'
    //   ele.setOption(option)

    // }

    // this.optionData[sector+iuId] = {...this.optionData[sector+iuId],...{legend:{type:'plain',orient: 'vertical'}}}
    // console.log(this.optionData,sector+iuId);

    this.setState({ loader: true });
    let node1 = document.getElementById("div-" + sector + iuId);
    // node1.style.position = 'fixed'
    // node1.style.height = '100vh'
    // node1.style.height = '100vw'
    // node1.style.top = '0'
    let node2 = document.getElementById("copy");
    node2.innerHTML = node1.innerHTML;
    node2.style.display = "block";
    $("#copy").find(".da_dropdown").remove();
    $("#copy").find(".maps_source").remove();
    $("#copy").find(".social_link").remove();
    $("#copy").find(".metadata").remove();
    $("#copy").find(".shareBtn").remove();
    $("#copy").find(".chartLabelcollapse").remove();
    $("#copy").find(".download_source").show();
    $("#copy").find(".inner_chart_box").css("background-color", "#fff");
    $("#copy").find(".chart_bottom_box").css("background-color", "#fff");
    $("#copy").find(".chart_head h4").css("color", "#333");
    $("#copy").find(".chart_head h4").css("display", "block");
    $("#copy").find(".chart_head h4 .light-text").css({"color": "#333", "display": "inline-block", "min-width": "75px"});
    $("#copy").find(".inner_chart_drop button").css("color", "#333");
    // $(".echarts-for-react svg g path").css('stroke', '#000')
    $("#copy")
      .find(".inner_chart_drop .react_drop .drop_labels")
      .css("color", "#333");
    $("#copy")
      .find(".multi_chart_drop .dropdown button img")
      .css("filter", "brightness(0)");
    $("#copy").find("svg g text").css("fill", "#333");
    $("#copy")
      .find(".chart_dropdown .multi_chart_drop .dropdown button")
      .css("border-color", "#333 !important");
    $("#copy").find(".chart_head h4").css("width", "100%");
    // $("#copy").find(".hArea").html(`${this.state.currentAreaName}</p>`).show();
    // $("#copy").find(".hArea").show();
    $("#copy").find(".hArea")[0].style.display = "block";
    // $("#copy").find(".hArea")[1].style.display = "block";
    domtoimage.toBlob(node2).then((blob) => {
      this.saveFile(blob, indName, this.state.currentAreaName);
      node2.style.display = "none";
      $(".multi_chart_drop").show();
      $(".react_drop").show();
      this.setState({ loader: false });
    });
    // if(ref){
    //   let ele = ref.getEchartsInstance();
    //   let option = this.optionData[sector+iuId]
    //   option.legend.type = 'scroll'
    //   option.legend.right = '0'
    //   ele.setOption(option)
    // }
  };

  saveFile = async (blob, indName, areaName) => {
    const a = document.createElement("a");
    const dt = Date.now();
    a.download = `TERS_${indName}_${areaName}_${dt}.png`;
    a.href = URL.createObjectURL(blob);
    a.click();
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  };

  // =======================================================
  render() {
    const d = this.props;
    return jsx.default.bind(this)();
  }

  setSelectedItem(item) {
    this.setState({ selectvalue: item });
  }

  settimeperiod(timeperiod) {
    this.setState({ timeperiodvalue: timeperiod });
  }
  // =======================================================

  getDropdownButtonLabel({ placeholderButtonLabel, value }, that) {
    if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
      let temp = that ? disasterProfileJSON.all[this.props.lang] : "All";
      return `${placeholderButtonLabel} ${temp}`;
    } else if (value && Array.isArray(value)) {
      if (value?.length == 1) {
        return `${placeholderButtonLabel} ${value[0].label}`;
      }
      return `${placeholderButtonLabel} ${value?.length} ${
        disasterProfileJSON.selected[this.props.lang]
      }`;
    } else {
      return `${placeholderButtonLabel} ${value}`;
    }
  }

  onChangeTP = (value, event, sector, iuid) => {
    // debugger;
    // this.fromCalled = 'onChangeTP'
    console.log("Sudhanshu here onChangeTP");
    let stp = {}
    if (event.action === "select-option" && event.option.value === "*") {
      console.log('de-select 0')
      stp = this.state.selectedOptionsTP;
      stp[sector + iuid] = this.state.timePeriods[sector + iuid];
      this.setState({ selectedOptionsTP: stp });
    } 
    else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      console.log('de-select 1')
       stp = this.state.selectedOptionsTP;
      stp[sector + iuid] = [];
      this.setState({ selectedOptionsTP: stp });
    } 
    else if (event.action === "deselect-option") {
      stp = this.state.selectedOptionsTP;
      stp[sector + iuid] = value.filter(
        (o) => o.value !== event.option.value && o.value !== "*"
        );
        console.log('de-select 2',value, event.option.value)
      this.setState({ selectedOptionsTP: stp });
    } 
    else if (
      value.length ===
      this.state.timePeriods[sector + iuid].length - 1
    ) {
      console.log('de-select 3',value.length ,
      this.state.timePeriods[sector + iuid].length - 1)
       stp = this.state.selectedOptionsTP;
      stp[sector + iuid] = this.state.timePeriods[sector + iuid];
      this.setState({ selectedOptionsTP: stp });
    } else {
      console.log('de-select 4',value.length,
      this.state.timePeriods[sector + iuid].length - 1)
      stp = this.state.selectedOptionsTP;
      stp[sector + iuid] = value;
      this.setState({ selectedOptionsTP: stp });
    }

    this.setSources(sector, iuid, this.state.selectedOptionsSG, stp);

    this.getChartForSubgroup(
      sector,
      iuid,
      this.state.chartType[sector + iuid]?.type,
      false
    );
  };

  onChangeSG = (value, event, sector, iuid) => {
    // this.fromCalled = 'onChangeSG'
    // console.log("Sudhanshu here onChangeSG");
    // console.log('settp',value)
    // console.log('settp',event)
    // console.log('settp',sector)
    // console.log('settp',iuid)
    // console.log('settp',this.state.selectedOptionsSG)
    let stp = {};
    if (event.action === "select-option" && event.option.value === "*") {
      console.log('onChangeSG 0');
      stp = this.state.selectedOptionsSG;
      stp[sector + iuid] = this.state.subgroupsLabel[sector + iuid];
      // this.setState({ selectedOptionsSG: stp });
      this.setState({ selectedOptionsSG: stp });
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      console.log('onChangeSG 1');
      stp = this.state.selectedOptionsSG;
      stp[sector + iuid] = [];
      this.setState({ selectedOptionsSG: stp });
    } else if (event.action === "deselect-option") {
      console.log('onChangeSG 2');
      stp = this.state.selectedOptionsSG;
      stp[sector + iuid] = value.filter(
        (o) => o.value !== event.option.value && o.value !== "*"
      );
      this.setState({ selectedOptionsSG: stp });
    } else if (
      value.length ===
      this.state.subgroupsLabel[sector + iuid].length - 1
    ) {
      console.log('onChangeSG 3');
      stp = this.state.selectedOptionsSG;
      stp[sector + iuid] = this.state.subgroupsLabel[sector + iuid];
      this.setState({ selectedOptionsSG: stp });
    } else {
      console.log('onChangeSG 4');
      stp = this.state.selectedOptionsSG;
      stp[sector + iuid] = value;
      this.setState({ selectedOptionsSG: stp });
    }
    this.setTP(stp, sector, iuid);
    
    this.getChartForSubgroup(
      sector,
      iuid,
      this.state.chartType[sector + iuid]?.type,
      false
    );
  };
  
  setTP = (stp, sector, iuid) => {
    console.log('settp',this.state.timeperiods)

    // console.log("Sudhanshu here in setTP");
    // console.log('setTp',stp,sector,iuid)
    let sg = stp[sector+iuid];
    let tp = [];
    // console.log('settp',stp,stp[sector+iuid])
    sg?.forEach(x => {
      if(x.value != '*') {
        console.log('allsources',this.state.timeperiods[sector+iuid+x],iuid,x)
        // console.log('settp',this.state.timeperiods?.[sector+iuid+x?.label])
        // console.log('settp',this.state.timeperiods)
        // console.log('settp',sector+iuid+x?.label)
        // console.log('settp',x)

        if((sector+iuid+x?.label) in this.state.timeperiods) {
          // console.log('settp',this.state.timeperiods?.[sector+iuid+x])
          tp.push(...this.state.timeperiods[sector+iuid+x?.label]);
        } 
        
      }      
    })
    // console.log('settp',tp,)
    let t = this.state.timePeriods;
    tp = tp?.map((item) => {
      return { label: item, value: item };
    });
    tp = tp?[
      { label: disasterProfileJSON?.all?.[this.props?.lang], value: "*" },
      ...tp
    ]:[];
    // console.log('settp',tp)
    t[sector+iuid] = tp;
        // console.log('settp',t)
    // this.setState({timePeriods: t,selectedOptionsTP: t});
      this.setState({timePeriods: t});
    this.setSources(sector, iuid, stp, t);
  }

  setSources = (sector, iuid, ssg = null, stp = null, allSources = null) => {
    console.log('setsourcers',stp);
    let a = allSources ? allSources : this.state.allSources;
    console.log("Sudhanshu Allsources:", a);
    let sg = ssg != null ? ssg[sector+iuid]:this.state.subgroups[sector+iuid];
    let src = [];
    let tp;

    sg?.forEach(y => {
      console.log("Sudhanshu y stp", y, stp);
      if(y.value != '*') {
        if((sector+iuid) in stp) {
          console.log("Sudhanshu time", stp[sector+iuid]);
          tp = stp[sector+iuid];
          tp?.forEach(x => {
            if(x.value != '*') {
              console.log('setset', this.state.timePeriods,this.state.selectedOptionsTP,a[sector + iuid + y.label + x.label],a,sector + iuid + y.label + x.label)
              // src = src?[...a[sector+iuid+y+x], ...src]: a[sector+iuid+y+x];
              if(a[sector+iuid+y.label+x.label] != undefined){
                if(!src?.includes(a[sector+iuid+y.label+x.label]?.[0])){
                  src = src?[...a[sector+iuid+y.label+x.label], ...src]: a[sector+iuid+y.label+x.label];
                }
              }
            }  
          })
        } else {
          console.log("Sudhanshu time2", stp[sector+iuid+y.label]);
          tp = stp? stp[sector+iuid+y.label]:this.state.timeperiods[sector+iuid+y.label];
          tp?.forEach(x => {
            console.log("Sudhanshu x", x);
            if(x != '*') {
              // src = src?[...a[sector+iuid+y+x], ...src]: a[sector+iuid+y+x];
              if(!src?.includes(a[sector+iuid+y.label+x]?.[0])){
                src = src?[...a[sector+iuid+y.label+x], ...src]: a[sector+iuid+y.label+x];
              }
            }  
          })
        }        
      
          
      }      
    })
    console.log('srcsrc',src)
   
    let s = this.state.selectedSrc;
    let source = this.state.selectedSource;
    // s[sector+iuid] = [...(new Set(...src))];
    s[sector+iuid] = src;
    source[sector+iuid] = src?.[0];
    this.setState({selectedSrc: s, selectedSource: source });
    console.log('sgsg',this.state.selectedSrc,this.state.selectedSource)
    console.log('setsourcers',stp);

  }

  changeSource = (sector, iuid, val) => {
    this.fromCalled = 'other'
    let source = this.state.selectedSource;
    source[sector+iuid] = val;
    this.setState({selectedSource: source});
    //rerender 
    this.getChartForSubgroup(
      sector,
      iuid,
      this.state.chartType[sector + iuid]?.type,
      false
    );
  }
  
}

function mapStateToProps(state) {
  // console.log('redux',state)
  return {
    lang: state.lang?.lang,
    disasterNameType: state?.areaName?.disaterName,
    disaterCodeType: state?.areaName?.disastercode,
    formStateAreaName:state?.formSlice?.areaCode,
    formStateAreaCode:state?.formSlice?.disasterCode,
    formStateGlovalLevel:state?.formSlice.glovalLevel
  };
}

const mapDispatchToProps = { setGlovalLevel};


export default connect(mapStateToProps,mapDispatchToProps)(DataanalyticsPage);

const getBase64 = async (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};
