import React from 'react';
import Profiletabs from './tab';
import Inittabs from './init';



var profileTemplate = function () {  


  return (
    <>
   {this.state.profileOpen==true? 
   <Profiletabs 
    isSaveAndExit={this.isSaveAndExit}
    tabChnged={this.getDataAgain} 
    sendCompletion={this.completion} 
    sendAreaData={this.state.sendAreaData} 
    sendStartData={this.state.sendStartData} 
    step1Call={this.saveStep1} 
    profileData={this.state.pData} 
    /> : <Inittabs step1Call={this.saveStep1}  startData={this.getStartData} />}   
      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>
    </>
  );
};


export default profileTemplate;
