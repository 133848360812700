import { Component, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { is } from 'date-fns/locale';
import Dropdown from 'react-bootstrap/Dropdown';
import { dataentryfile } from "../../../../Config/app.constants";

import Select from '@mui/material/Select';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

// class InProgressView extends Component {
function InProgressView({ getData, dataEnteryOptions, selectedArea, data, dataEntryQuestion, viewMode, surveyRecord, dataentryFormData }) {
    const [selectedSection, setSelectedSection] = useState('')
    const [orderedDataEntryQustions, setOrderedDataEntryQuestions] = useState('')
    const [questionAnswersCount, setQuestionAnswersCount] = useState([])
    const [answers, setAnswers] = useState({})
    const [loader, setLoader] = useState(false)
    const [modalViewType, setModalViewType] = useState({})
    const [area, setArea] = useState('');
    // let count = []
    const [FormState, setFormState] = useState(
        {
            'id': '',
            'formName': '',
            'questions': [],
            'options': []
        });
    const day = new Date(data?.createdAt);
    const m = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const currentDate = day.getDate() + ' ' + m[day.getMonth()] + ' ' + day.getFullYear();

    console.log('surveyRecord', surveyRecord)

    const saveAnswers = (id, type, value) => {
        // console.log(value);
        let preData = answers
        if (preData.hasOwnProperty(id)) {
            preData[id][type] = value
        } else {
            preData[id] = { [type]: value, ...preData[id] }
        }
        setAnswers({ ...preData })
        // console.log(answers);
    }

    const saveGridQuestion = (id, options) => {
        console.log('options', id, options)
        let ans = {}
        options.map((option) => {
            // console.log('op',option.grid_col + option.grid_row ,':', option.ans)
            let key = option.grid_row + option.grid_col
            ans[key] = option.ans
        })
        console.log('ans', ans)
        saveAnswers('id' + id, 'answer', ans)
    }

    const handleChange2 = (event) => {
        console.log(event.target.value);
        let areaObj = data.area_info.filter(x => x._id == event.target.value)[0]
        getData(null, areaObj, event.target.value)
        setArea(event.target.value);
    };



    useEffect(() => {
        if (dataEntryQuestion != null) {
            const dataEntryQuestionInOrdered = Object.keys(dataEntryQuestion).sort().reduce(
                (obj, key) => {
                    obj[key] = dataEntryQuestion[key];
                    return obj;
                },
                {}
            );
            setSelectedSection(Object.keys(dataEntryQuestionInOrdered)[0])
            setOrderedDataEntryQuestions(dataEntryQuestionInOrdered)
            console.log('dataEntryQuestionn', Object.keys(dataEntryQuestionInOrdered)[0])

            let count = []
            Object.keys(dataEntryQuestionInOrdered)?.map((section) => {
                dataEntryQuestionInOrdered[section].map((qtn) => {
                    let qtnId = 'id' + qtn._id
                    console.log('qtn', qtn)
                    Object.keys(answers).map((id) => {
                        // console.log('ansss', id, qtnId, qtnId == id)
                        if (qtnId == id) {
                            let obj = {}

                            obj = {
                                section: section
                            }
                            count.push(obj)
                        }
                    })
                })
            })
            setQuestionAnswersCount(count)
        }
    }, [viewMode, answers])

    useEffect(() => {
        setLoader(true)
        setAnswers({})
        if (selectedArea != undefined && data != undefined) {
            // setDisasters(props.data.disaster_info[0])
            setArea(selectedArea._id)
            // setArea(props.data.area_info[0]?.name?.en)
        }
        if (surveyRecord != null && surveyRecord != null) {
            let answers = surveyRecord.question_answer
            Object.values(dataEntryQuestion).forEach(section => {
                section.forEach(question => {
                    if (question.type != 'grid') {
                        let answer = answers?.filter(a => a.question_id == question._id) ?? []
                        if (answer.length > 0) {
                            let multiString = answer[0]?.options.map(field => field.selected_option.en).join(', ');
                            saveAnswers('id' + question._id, 'answer', ["single_select", "multi_select"].includes(question.type) ? multiString : answer[0].options[0].ans)
                            saveAnswers('id' + question._id, 'timeFormat', answer[0].options[0].time_period_format)
                            saveAnswers('id' + question._id, 'timePeriod', answer[0].options[0].time_period)
                        }
                    } else if (question.type == 'grid') {
                        let answer = answers?.filter(a => a.question_id == question._id) ?? []
                        if (answer.length > 0) {
                            saveGridQuestion(question._id, answer[0].options)
                            saveAnswers('id' + question._id, 'timeFormat', answer[0].options[0].time_period_format)
                            saveAnswers('id' + question._id, 'timePeriod', answer[0].options[0].time_period)
                        }
                    }
                })
            })


        }
        setLoader(false)
    }, [getData, dataEnteryOptions, selectedArea, data, dataEntryQuestion, viewMode, surveyRecord, dataentryFormData])

    useEffect(() => {
        if (dataEntryQuestion != null) {
            if (dataEnteryOptions && dataEntryQuestion && dataentryFormData) {
                setFormState({
                    'questions': dataEntryQuestion,
                    'options': dataEnteryOptions
                })
            }
        }
    }, [dataEnteryOptions, dataEntryQuestion])

    console.log('ddaattaa', data?.household?.length, data?.household?.[0]?.household?.length);
    return (
        <>
            <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
                <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
            </div>
            <div class="modal fade timePeriodPopup dataentryModal dev_modal" id="inprogressview" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='inner_modal_head'>
                                <h5 class="modal-title" id="exampleModalLabel">{dataentryFormData?.name}</h5>
                                <p class="date mb-0 p-0">Created Date ( {currentDate} )</p>
                            </div>

                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body overflow-hidden" style={{ 'max-height': 'fit-content' }}>
                            <div class="row tabs_row justify-content-between">
                                <div class="col-3 main_tabs">
                                    <div class="nav flex-column nav-pills left_maintab" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {
                                            orderedDataEntryQustions != null && orderedDataEntryQustions != undefined ? Object.keys(orderedDataEntryQustions)?.map((section) => {
                                                return (
                                                    <>
                                                        <button class={`nav-link ${selectedSection == section ? 'active' : null}`} id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={() => { setSelectedSection(section) }}>{section}
                                                            <span>{questionAnswersCount.filter((x) => x.section == section).length}/{orderedDataEntryQustions[section]?.length}</span>
                                                        </button>
                                                    </>
                                                )
                                            }) : null
                                        }
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div className="row align-items-center mb-30 justify-content-center">
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style">
                                                <div className="mat-form-field-wrapper">
                                                    <label>Area {area?.name?.en}</label>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={area}
                                                        onChange={(e) => handleChange2(e)}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {/* {
                                                            data != null ? data.area_info.map(x => {
                                                                return <MenuItem value={x._id}>{x.name.en}</MenuItem>
                                                            }) : ''
                                                        } */}
                                                        {
                                                            // data?.household?.length == 0 ?
                                                            data?.household?.length != 0 ?
                                                                data?.household?.[0]?.household?.length == 0 ?
                                                                    data != null ? data.area_info.map(x => {
                                                                        return <MenuItem value={x._id}>{x.name.en}</MenuItem>
                                                                    }) : ''
                                                                    :



                                                                    data != null ? data.area_info.map(x => {
                                                                        return (
                                                                            data?.household?.map((h) => {
                                                                                // console.log('menu',h,h._id , x.area_code,h._id == x.area_code)
                                                                                if (h._id == x.area_code) {
                                                                                    return (
                                                                                        h.household.map((menu) => {
                                                                                            console.log('menu', x.area_code + menu, selectedArea)
                                                                                            return <MenuItem value={x.area_code + '-' + menu}>{x.name.en}-Household {menu}</MenuItem>
                                                                                        })
                                                                                    )
                                                                                }
                                                                            })
                                                                        )
                                                                    }) : ''
                                                                :
                                                                data != null ? data.area_info.map(x => {
                                                                    return <MenuItem value={x._id}>{x.name.en}</MenuItem>
                                                                }) : ''

                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane tabsNewmodal fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                            <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                {/* <div className='col-md-12'>
                                                    <div className="row align-items-center mb-30 justify-content-center">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <div className="mat-form-field-wrapper">

                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={area}
                                                                        label="area"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12 p-0 head_box">
                                                    <h4>Incident</h4>
                                                </div>
                                                <div className='pt-4 w-100 pb-4'>
                                                    {
                                                        orderedDataEntryQustions != null && orderedDataEntryQustions != undefined ?
                                                            selectedSection != '' ?
                                                                orderedDataEntryQustions[selectedSection]?.map((questions, index) => {
                                                                    console.log('dataEntryQuestion', questions)
                                                                    return (

                                                                        <div className='main_inputs w-100 px-5'>
                                                                            <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q{index + 1}</h4></div><div class="col-md-11 pl-0"><p className='mb-0'>{questions.title.en}</p></div></div>
                                                                            <div className='row align-items-center mb-4'>
                                                                                <div className="inner_form_area w-100 d-flex align-items-center">
                                                                                    <div class="col-md-1 pt-3"></div>



                                                                                    <div className='col-md-11 d-flex align-items-center p-0'>
                                                                                        {
                                                                                            questions.type == "text" ?
                                                                                                <div className="col-md-6 p-0">
                                                                                                    <div className="form-group mb-0 inputform_style">
                                                                                                        <label>Answer</label>
                                                                                                        <div className="mat-form-field-wrapper">
                                                                                                            <TextField id="outlined-basic" variant="outlined" disabled value={answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : ''} onClick={() => console.log(answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '')} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                questions.type == "date" || questions.type == "time" ?
                                                                                                    <div className="col-md-6 p-0">
                                                                                                        <div className="form-group mb-0 inputform_style">
                                                                                                            <label>Answer</label>
                                                                                                            <div className="mat-form-field-wrapper">
                                                                                                                <TextField id="outlined-basic" variant="outlined" disabled value={answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : ''} onClick={() => console.log(answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '')} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    questions.type == "single_select" ?
                                                                                                        <div className="col-md-6 p-0">
                                                                                                            <div className="form-group mb-0 inputform_style">
                                                                                                                <label>Answer</label>
                                                                                                                <div className="mat-form-field-wrapper">
                                                                                                                    <TextField id="outlined-basic" variant="outlined" disabled value={answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : ''} onClick={() => console.log(answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '')} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        questions.type == "multi_select" ?
                                                                                                            <div className="col-md-6 p-0">
                                                                                                                <div className="form-group mb-0 inputform_style">
                                                                                                                    <label>Answer</label>
                                                                                                                    <div className="mat-form-field-wrapper">
                                                                                                                        <TextField id="outlined-basic" variant="outlined" disabled value={answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : ''} onClick={() => console.log(answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '')} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> :
                                                                                                            questions.type == "numeric" ?
                                                                                                                <div className="col-md-6 p-0">
                                                                                                                    <div className="form-group mb-0 inputform_style">
                                                                                                                        <label>Answer</label>
                                                                                                                        <div className="mat-form-field-wrapper">
                                                                                                                            <TextField id="outlined-basic" variant="outlined" disabled value={answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : ''} onClick={() => console.log(answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '')} />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> :
                                                                                                                questions.type == "image" || questions.type == "document" || questions.type == "audio" ?
                                                                                                                    <div className="row align-items-center mb-5">
                                                                                                                        <div className="col-md-11 pl-0">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-md-12">
                                                                                                                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                                                                        <label>Answer</label>
                                                                                                                                        <div className="mat-form-field-wrapper inputFile">
                                                                                                                                            <p className="image-name d-inline-block mt-2 d-flex" >
                                                                                                                                                {answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '-'}
                                                                                                                                                {answers.hasOwnProperty('id' + questions._id) ?
                                                                                                                                                    <img data-toggle="modal" data-target="#showImage3" src="assets/lib/images/home/hover_arrow.svg" className="ml-1" style={{ cursor: "pointer", width: "12px" }} onClick={() => {
                                                                                                                                                        setModalViewType({
                                                                                                                                                            ans: answers.hasOwnProperty('id' + questions._id) ? answers[`id${questions._id}`]['answer'] : '',
                                                                                                                                                            type: questions.type
                                                                                                                                                        })
                                                                                                                                                    }}
                                                                                                                                                    />
                                                                                                                                                    :
                                                                                                                                                    ''
                                                                                                                                                }
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    questions.type == 'grid' ?
                                                                                                                        <div className="row align-items-center mb-5">
                                                                                                                            <div className="col-md-12 pl-0 pr-0">
                                                                                                                                <div className="row align-items-center membersTable mx-0">
                                                                                                                                    <div className="col-md-2"></div>
                                                                                                                                    {
                                                                                                                                        Object.values(JSON.parse(questions.grid).columns.names).map((c, i) => {
                                                                                                                                            if (JSON.parse(questions.grid).columns.count > i) {

                                                                                                                                                return (
                                                                                                                                                    <div className="col-md-2">
                                                                                                                                                        <h4 className="col-head text-center">{c.en}</h4>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div className="main-row">
                                                                                                                                    <div className="row mb-3 mx-0">
                                                                                                                                        <div className="col-md-4">
                                                                                                                                            {
                                                                                                                                                Object.values(JSON.parse(questions.grid).rows.names).map((r, i) => {
                                                                                                                                                    if (JSON.parse(questions.grid).rows.count > i) {

                                                                                                                                                        return (
                                                                                                                                                            <h4>{r.en}</h4>
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        {
                                                                                                                                            [0, 1, 2, 3, 4].map(c => {
                                                                                                                                                if (c < JSON.parse(questions.grid).columns.count) {
                                                                                                                                                    return (
                                                                                                                                                        <div className="col-md-8">
                                                                                                                                                            {
                                                                                                                                                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(r => {
                                                                                                                                                                    if (r < JSON.parse(questions.grid).rows.count) {
                                                                                                                                                                        return (
                                                                                                                                                                            <div className="form-group mb-0 inputform_style fp_dropdown">
                                                                                                                                                                                <label>Answer</label>
                                                                                                                                                                                <div className="mat-form-field-wrapper">
                                                                                                                                                                                    {
                                                                                                                                                                                        JSON.parse(questions.grid).questions['' + r + c].type == "Textbox" || JSON.parse(questions.grid).questions['' + r + c].type == "Numeric" || JSON.parse(questions.grid).questions['' + r + c].type == "Single Choice" ?
                                                                                                                                                                                            <TextField disabled value={answers['id' + questions._id]?.answer?.['' + r + c] ?? null} onChange={(e) => { saveGridQuestion('id' + questions._id, '' + r + c, e.target.value) }} type={JSON.parse(questions.grid).questions['' + r + c].type == "Textbox" ? 'text' : JSON.parse(questions.grid).questions['' + r + c].type == "Numeric" ? 'number' : ''} />
                                                                                                                                                                                            :
                                                                                                                                                                                            <Dropdown>
                                                                                                                                                                                                <Dropdown.Toggle>{answers['id' + questions._id]?.answer?.['' + r + c]?.name ?? null}</Dropdown.Toggle>
                                                                                                                                                                                                <Dropdown.Menu>
                                                                                                                                                                                                    {
                                                                                                                                                                                                        JSON.parse(questions.grid).questions['' + r + c].singleChoice.selectedOption.map(option => {
                                                                                                                                                                                                            return (
                                                                                                                                                                                                                <Dropdown.Item onClick={(e) => { saveGridQuestion('id' + questions._id, '' + r + c, option) }}>{option.name}</Dropdown.Item>
                                                                                                                                                                                                            )
                                                                                                                                                                                                        })
                                                                                                                                                                                                    }
                                                                                                                                                                                                </Dropdown.Menu>
                                                                                                                                                                                            </Dropdown>

                                                                                                                                                                                    }
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                })
                                                                                                                                                            }

                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : null
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-secondary previous_btn" data-dismiss="modal"><img src="assets/lib/images/home/arrow_right.svg" /> Previous </button>
                            <button type="button" class="btn btn-primary">Next <img src="assets/lib/images/home/arrow_right.svg" /></button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Model for audio, image */}
            <div class="modal fade showImage3 timePeriodPopup" id="showImage3" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header align-items-center border-0">
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="popupImageSize">
                                {
                                    modalViewType.type == 'audio' ?
                                        <audio controls className="w-100">
                                            <source src={`${dataentryfile}${modalViewType.ans}`} type="audio/mpeg" />
                                        </audio> :
                                        <img src={`${dataentryfile}${modalViewType.ans}`} class="d-block mx-auto w-100" />
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}


export default InProgressView;


