/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {MapContainer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./geo-data.css";
import * as htmlToImage from "html-to-image";
import "leaflet-boundary-canvas";
import geoDataJSON from '../../../Config/geo-data.json'
import Tooltip from "@mui/material/Tooltip";
import * as $ from "jquery";
import { setAreaCode } from "../../../features/areaSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAreaName } from "../../../features/areaNameSlice";
import { setHeaderAreaCode, setHeaderAreaName, setHeaderDisasterCode, setHeaderDisasterName, setHeaderLevel } from "../../../features/formStateSlice";

function GeoData(props) {
  const headerLevel = useSelector((state) => state.formSlice.level)
  const jsonFile = "assets/map/json/TLS_L02_2019.json";

  var exceptions = {
    Eswatini: [-8.93310440198275, 125.34997343166705],
    Eswatini: [-9.577847111130273, 124.36356441621412],
  };

  var southWest = L.latLng(-10.934071337478107, 122.49039867597014),
    northEast = L.latLng(-7.66263661277406, 128.34636052662762);
  var bounds = L.latLngBounds(southWest, northEast);

  const [isLabel, setIsLabel] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [legends, setLegendData] = useState([]);
  const [currentArea, setCurrentArea] = useState("ASITLS");
  const [colorArea, setColorArea] = useState([]);
  const [reset, setReset] = useState(false);
  const [mapObj, setMapObj] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isCompass, setCompass] = useState(false);
  const [map, setMap] = useState(null);
  const [total, setTotal] = useState(0);
  const [dupTotal, setDupTotal] = useState();
  const seletedLangValue = useSelector((state) => state?.lang?.lang)
  const seletedDisaterNameTYpe = useSelector((state) => state?.areaName?.disaterName)
  const seletedDisaterCode = useSelector((state) => state?.areaName?.disasterCode)
  const formStateDisasterCode = useSelector((state)=> state?.formSlice?.disasterCode)

  const dispatch = useDispatch();

  dispatch(setAreaName(props?.areaUpdateName));

  const addChildLayers = async (area, colorData) => {
    if (area !== null) {
      setCurrentLevel(area?.level + 1);
      const response = await fetch(
        `assets/map/json/TLS_L0${headerLevel}_2019.json`
      );
      const responseLevel = await fetch(
        `assets/map/json/TLS_L0${headerLevel}_2019.json`
      );
      const mainGeoData = await response.json();

      const levelGeoData = await responseLevel.json();

      const geoJSON = [];

      props?.mapData?.forEach((item) => {

        if (props?.headerLevel > area?.level + 1) {

          levelGeoData?.features?.forEach((geo2) => {

            if (item.code === geo2?.properties?.["ID_"]) {
              geo2.properties["value"] = item?.value;
              geo2.properties["name1"] = item?.area;
              geoJSON.push(geo2);
              console.log(geo2,"mainGeoData13")
            }
          })

        } else {
          mainGeoData?.features?.forEach((geo) => {
            if (item.code === geo?.properties?.["ID_"]) {
              geo.properties["value"] = item?.value;
              geo.properties["name1"] = item?.area;
              geoJSON.push(geo);
            }
          });
        }
      });

      mainGeoData["features"] = geoJSON;


      fetchGeoJSON(mainGeoData, colorData);
    } else {
      const response = await fetch(jsonFile);
      const mainGeoData = await response.json();
      fetchGeoJSON(mainGeoData, colorData);
    }
  };


  const areaClick = async (area,forRestLevel = '') => {
    var testObj = {
      message: null,
      name: null,
      level: null,
      children: [],
    };

    props.allAreaTree?.map((item) => {
      if (item.value === area) {
        testObj["name"] = item.label;
        testObj["message"] = item.value;
        testObj["level"] =forRestLevel !== '' ? forRestLevel : headerLevel;

      }
    });

    props.allAreaTree?.map((item) => {
      if (item.parent_id === area) {
        let dtObj = {
          label: item.label,
          code: item.value,
          parent: item.parent,
          level: forRestLevel !== '' ? forRestLevel : headerLevel,
          value: 23,
        };
        testObj["children"].push(dtObj);
      }
    });
    dispatch(setAreaCode(area));
    props.areaCallBack(testObj);
  };
  
  useEffect(() => {

    console.log( props.mapData,"filterLevelDatanew")
    if (!map) return;
    if (props.mapData != null && props.mapData.length > 1 && props.mapData !== undefined) {
      setCurrentArea(props.areaCode);
      let valueData = [];
      let colors = ["#FFCBC6", "#ED9C94", "#CF655B", "#B8372B"];
      let areaObj = props.mapData?.filter((o) => {
        return o.code === props.areaCode?.message
      });


      let srcDt =  props.mapData?.filter((t)=> { return t?.source_lang?.en?.toLowerCase().includes(props?.sourceData?.toLowerCase()) && t?.level===currentLevel-1})
      console.log('srcDt', srcDt, valueData, currentLevel)

  
      if(srcDt?.length  == 0 && areaObj?.length > 0 ){
        if(areaObj?.[0]?.['value'] != undefined){
              areaObj[0]['value'] = ""
        }
      }
 
       
       setTotal(areaObj?.[0]);
    
      setDupTotal(areaObj?.[0]);
      props.mapData?.filter((ele)=> ele?.source_lang?.en?.toLowerCase().includes(props?.sourceData?.toLowerCase()))?.forEach((item) => {
        if (item.code !== props.areaCode?.message && item?.value !== "") {
          valueData.push(item.value);
        }
      });



   
      let s = new Set(valueData);
      let unicValData = [...s];
      unicValData.sort(function (a, b) {
        return a - b;
      });

      let unitVal = 1;

      var finalLegends = [];
      var collectionAreaData = [];
      if (unicValData?.length >= 4) {
        unitVal = Math.floor(unicValData?.length / 5);
      }
      console.log(unitVal,"unicValData")
      let j = 0;
      let k = 0;
      let from = unicValData[0];
      let to = unicValData[0];
      for (let i = 0; i < unicValData.length; i++) {
        if (j++ >= unitVal && k < 3) {
          j = 0;

           
          let obj = {
            color: colors[k++],
            value: "" + from + " - " + to,
          };

          let decimalLengths=from = to?.toString().split('.')[1]?.length
          if(decimalLengths ===1){
            let adon = +to + 0.1;
           from = parseFloat(adon.toFixed(2))
          } else if(decimalLengths ===2){
            let adon = to + 0.01
            from =  parseFloat(adon.toFixed(2))
          }
          else{
            from= to + 1
          }

         
           console.log(from,"from")
          finalLegends.push(obj);
          console.log(obj,"jdjcskj")
        }

        let valMapData = props.mapData?.filter(
          (item) =>
            item.value === unicValData[i] && item.code !== props.areaCode?.message
        );



        // eslint-disable-next-line no-loop-func
        valMapData?.filter((ele)=> ele?.source_lang?.en?.toLowerCase().includes(props?.sourceData?.toLowerCase())).forEach((item) => {
          if (item?.value !== "") {
            let obj = {
              color: colors[k],
              value: item.value,
              area: item.area_all[seletedLangValue] ? item?.area_all[seletedLangValue] : item?.area_all?.en,
              code: item.code,
              source:item.source_lang
            };
            collectionAreaData.push(obj);
          }
        });
        to = unicValData[i];
      }
 

      let obj = {
        color: colors[k++],
        value: "" + from + " - " + to,
      };
      finalLegends.push(obj);

      setLegendData(finalLegends);
      props.rangeData(finalLegends);
      addChildLayers(props.areaCode, collectionAreaData);
      setColorArea(collectionAreaData);
    } else {
      setLegendData([]);
      setColorArea([]);
    }
    setIsLabel(false)

  
    let width = $(window).width();
    if (width < 1950 && width > 1701) {
      map.options.minZoom = 8.5;
      map.options.setZoom = 8.5;
      map.options.zoom = 8.5;
    } else if (width < 1700 && width > 1490) {
      map.options.minZoom = 8.47;
      map.options.setZoom = 8.47;
      map.options.zoom = 8.47;
    } else if (width < 1400) {
      map.options.minZoom = 4.30;
      map.options.setZoom = 4.30;
      map.options.zoom = 4.30;
    }
    else{
      map.options.minZoom = 2.30;
      map.options.setZoom = 2.30;
      map.options.zoom = 2.30;
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.areaData, props.areaCode, props.mapData, map, props.isLevel,headerLevel,props?.sourceData,props.currentLevel]);


  useEffect(() => {
    let ht = $('.wmt_chart').height()
    $('.wmt_chart svg').attr('height', ht - 20)
    $('.wmt_chart svg').css('margin-top', '50px')

  }, [])



  const mapZoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };

  const mapReset = async() => {
   await  dispatch(setHeaderLevel(2));
    dispatch(setHeaderAreaCode('ASITLS'))
    dispatch(setHeaderDisasterCode(formStateDisasterCode))
    dispatch( setHeaderDisasterName('INUNDASAUN'))
    dispatch(setHeaderAreaName("Timor-Leste"))
    addChildLayers(null, null);
    map.fitBounds(mapObj.getBounds());
    setReset(false);
    areaClick('ASITLS',1)
  };

  const mapZoomIn = () => {
    map.setZoom(map.getZoom() + 1);
  };

  const sourceData = () => {
    let d = []
    props.allData?.tableData?.map((element, index) => {
      if (element.source_lang !== "") {
        d.push(element)
      }
    })
    return d

  }

  const center = [-8.657933501999876, 125.63539257200003];

  const fetchGeoJSON = async (geoJSON, colorData) => {
    if (!map) {
      return;
    }
    map.eachLayer(function (layer) {
      if (!!layer.toGeoJSON) {
        map.removeLayer(layer);
      }
    });

    const mapObjc = new L.geoJSON(geoJSON, {
      style: countriesStyle,
      onEachFeature: onEachFeatures,
    }).addTo(map);

    function countriesStyle(feature) {
      return {
        fillColor: "#d2d2d2",
        fillOpacity: 1,
        color: "#fff",
        opacity: 1,
        weight: 1,
      };
    }


    function onEachFeatures(feature, layer) {
      colorData?.map((item) => {
        console.log('306dd', item?.code, colorData, feature)
        if (item?.code === feature.properties.ID_) {
          
          return layer.setStyle({
            fillColor: item.color,
          });
        }
      });

      layer.on({

        mouseover: function (e) {
          layer.setStyle({
            opacity: 0.1,
          });
          setTotal({
            value: e.sourceTarget.feature.properties.value,
            area: e.sourceTarget.feature.properties.hasOwnProperty('name1') ? e.sourceTarget.feature.properties.name1 : e.sourceTarget.feature.properties.NAME1_,
          });
        },
        mouseout: function () {
          layer.setStyle({
            opacity: 1,
          });
          setTotal(dupTotal);
        },
        click: function (e) {
          setTotal({
            value: e.sourceTarget.feature.properties.value,
            area: e.sourceTarget.feature.properties.hasOwnProperty('name1') ? e.sourceTarget.feature.properties.name1 : e.sourceTarget.feature.properties.NAME1_,
          });
          areaClick(feature.properties.ID_);
        },
      });

      var label = L.marker(
        exceptions[feature.properties.NAME1_] || layer.getBounds().getCenter(),
        {
          icon: L.divIcon({
            html: "<p >sddd" + feature.properties.NAME1_ + "</p>",
            iconSize: [0, 0],
          }),
        }
      ).addTo(map);

      label.bindTooltip(
        colorData?.length > - 0
          ? colorData
            ?.map((item) => {
              if (item?.code === feature.properties.ID_) {
                feature.properties.value = item.value;
                feature.properties.name1 = item.area;
                return (
                  "<div className='map_datavalues'>" +
                  feature.properties.name1 +
                  " <br>" +
                  item?.value +
                  "</div>"
                );
              } else {
                return "";
              }
            })
            .join("")
          : "<div className='map_datavalues'>" +
          feature.properties.NAME1_,
        {
          permanent: true,
          direction: "center",
          className: "mapchartlabel",
        }
      );
    }

    await map.fitBounds(mapObjc.getBounds());

    await setMapObj(mapObjc);
  };

  const xyz = (e) => {
    //  
  };

  const showLabel = (e) => {
    if (isLabel) {
      setIsLabel(false);
      $(".leaflet-tooltip").removeClass("show1");
    } else {
      setIsLabel(true);
      $(".leaflet-tooltip").addClass("show1");
    }
  };

  const downloadMap = () => {
    $("#mainDownload").css("background", "rgba(255, 255, 255)");
    $(".wmt_chart").css({ width: "1100px", marginTop: "-40px" });
    $(".legendsNumber").css({ position: "relative", bottom: "unset" });
    $("#mainDownload").css({
      width: "100%",
      height: "100%",
      padding: "10px",
    });
    $("#heading_info").css("color", "rgba(0, 0, 0)");
    $("#heading_info span.pipeMeta").css("color", "#ddd");
    $(".legendsNumber p, .legendsNumber ul li").css("color", "rgba(0, 0, 0)");
    $(".metaInfo").css("color", "rgba(0, 0, 0)");
    $(".legends, .filter, .content_arrow ").hide();
    $(".metaInfo").show();

    htmlToImage
      .toJpeg(document.getElementById("mainDownload"), {
        quality: 0.95,
        height: 650,
      })
      .then(function (dataUrl) {
        let dTime =
          new Date().toISOString().split("T")[0].replace(/-/g, "") +
          "_" +
          new Date().getHours() +
          "" +
          new Date().getMinutes() +
          "" +
          new Date().getSeconds();

        var a = document.createElement("a");
        a.download =
          "TERS_" +
          props.inddata.name +
          "_" +
          props.inddata.unit +
          "_" +
          props.allData.subGroupSelected.name +
          "_" +
          props.allData.selectedPeriod +
          "_" + "Map" + "_" +
          dTime +
          ".jpeg";
        a.href = dataUrl;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        $(".legends, .filter, .content_arrow ").show();
        $("#mainDownload").css("background", "transparent");
        $("#mainDownload").css({ width: "", height: "", padding: "" });
        $(".wmt_chart").css({ width: "", height: "" });
        $(".metaInfo").hide();
        $(".legendsNumber").css({ position: "absolute", bottom: "-24px" });
        $(".metaInfo p, .legendsNumber p, .legendsNumber ul li").css("color", "");
        setLoader(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <>
      <div className="mapData">
        {
          <h2>{total?.value === ""  ?  "-" : total?.value}</h2>
        }
        <h4>{total?.area}</h4>
      </div>
      <div className="legends">
        <ul>
          <li
            data-toggle="modal"
            onClick={(e) => xyz(e)}
            data-target="#are_value_table"
          >
            <Tooltip title={geoDataJSON?.["table"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} >
                <img src="assets/lib/images/home/table.svg" alt="table" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["zoomIn"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapZoomIn}>
                <img src="assets/lib/images/home/zoom-in.svg" alt="zoom-in" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["zoomOut"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapZoomOut}>
                <img src="assets/lib/images/home/zoom-out.svg" alt="zoom-out" />
              </a>
            </Tooltip>
          </li>
          <li className="wmtm_reset">
            <Tooltip title={geoDataJSON?.["reset"]?.[seletedLangValue]} placement="right">
              <a style={{ cursor: "pointer" }} to="" onClick={mapReset}>
                <img src="assets/lib/images/home/reset.svg" alt="rest" />
              </a>
            </Tooltip>
          </li>
          <li>
            {
               isLabel ? <Tooltip title={geoDataJSON?.["labelOff"]?.[seletedLangValue]} placement="right"> 
               <a style={{ cursor: "pointer" }} onClick={(e) => showLabel(e)}>
                 <img
                 src="assets/lib/images/home/label-off.svg" 
                 alt="onof"
                 className="img-label"
               />
               </a>
               </Tooltip>:<Tooltip title={geoDataJSON?.["labelOn"]?.[seletedLangValue]} placement="right"> 
                <a style={{ cursor: "pointer" }} onClick={(e) => showLabel(e)}>
               <img 
                src="assets/lib/images/home/label_on.svg"
               alt="on" 
               className="img-label"/>
                </a>
              </Tooltip>
            }
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["Download"]?.[seletedLangValue]} placement="right">
              <a to="" style={{ cursor: "pointer" }} onClick={downloadMap}>
                <img src="assets/lib/images/home/download.svg" alt="download" />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={geoDataJSON?.["northPole"]?.[seletedLangValue]} placement="right">
              <a
                to=""
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  isCompass === true ? setCompass(false) : setCompass(true);
                }}
              >
                <img src="assets/lib/images/home/north_pole.svg" alt="north-pole" />
              </a>
            </Tooltip>
          </li>
        </ul>
      </div>
      <div className="mapBox" id="mainDownload">
        <div
          className="metaInfo"
          style={{ display: "none", fontSize: "20px", color: "#ffffff" }}
        >
          <p id="heading_info">
            {seletedDisaterNameTYpe} <span className="pipeMeta">|</span> {props.inddata.name} <span> - {props.inddata.unit} <br /> {props.allData.subGroupSelected.name} <span className="pipeMeta">|</span> {props.allData.selectedPeriod}
            </span>
          </p>
        </div>

        <div className="wmt_chart">
          <MapContainer
            minZoom={20}
            maxBounds={bounds}
            center={center}
            maxZoom={20}
            whenCreated={setMap}
            scrollWheelZoom={false}
          ></MapContainer>
        </div>

        <div className="legendsNumber">
          <p> {props.inddata.unit}</p>
          <ul>
            {legends?.map((item, index) => {
              return (
                <>

                  {
                    item.value !== "undefined - undefined" ? <li key={index}>
                      <span className={"lnColor" + (index + 1)}></span>
                      {item.value}
                    </li> : ""
                  }

                </>
              );
            })}

            <li>
              <span className="lnColor5"></span>{geoDataJSON?.["No Data"]?.[seletedLangValue]}
            </li>
          </ul>
        </div>

        <div
          className="metaInfo"
          style={{ display: "none", fontSize: "12px", color: "#ffffff" }}
        >
          <p className="mb-2">Source: {sourceData()?.[0]?.source_lang?.pt && sourceData()?.[0]?.source_lang?.tet !== undefined ? sourceData()?.[0]?.source_lang?.[props?.lang]: sourceData()?.[0]?.source_lang?.['en']}</p> 
          <p>
          {geoDataJSON['disclaimer'][seletedLangValue]}: {geoDataJSON['onDownDiscP'][props.lang]}</p>
        </div>

        {isCompass === true && (
          <span className="comapss">
            <img src="assets/map/compass.svg" align="right" alt="compass" />
          </span>
        )}
      </div>

      <div
        className={
          loader === true ? "loader text-center " : "loader text-center hide"
        }
      >
        <img
          src="assets/images/Comp-2.gif" alt="comp-3"
          style={{ marginTop: "12%", width: "30%" }}
        />
      </div>

    </>
  );
}

export default GeoData;
