import React, { useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import GeneratereportModal from '../../Modals/generateReport.modal';
import * as $ from 'jquery';
import dayjs from 'dayjs';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const handleChange = ({ selectedRows }) => {
  // You can set state or dispatch with something like Redux so we can use the retrieved data
  console.log('Selected Rows: ', selectedRows);
};

export const Export = ({ onExport, disabledDownload }) => (
  <Button variant="contained" className="download cancelbtn" disabled={disabledDownload} onClick={onExport}>Download</Button>
);


  // const  handleChange = selected => {
  //     this.setState({
  //       selectedUsers: selected
  //     });
  //   };

const generatereportTemplate = function () {
  
  return (
    <>


      <div className="main dataApprove generatereports" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection data_approve gr_hsBox' id="areaSection">
            <div className='col-lg-12 col-md-12'>
              <div className="row header-div new-h-d pt-0 pl-0">
                <div className="col-md-12 pl-2 position-relative gr_hs">
                  <div class="">
                    <subHeaderComponentMemo.default parentCallBack={this.sendStatus}
                      onSearch={this.onSearch}
                      searchText={this.state.searchText}
                      value='40'
                      checkData={this.state.bulkDelete}
                      nRows={this.state.filteredData?.length}
                    /> {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}
                  </div>

                  <div className='grf_form d-flex'>                    
                    <div className='grf_list'>
                      <div className='grf_form_date align-self-center'>Select Date</div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={this.state.fromDt}
                              id="dateFrom"
                              maxDate={moment().toDate()}
                              onChange={this.handleDate}
                              renderInput={(params) => <TextField {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD/MM/YYYY"
                                }}
                              />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              id="dateTo"
                              required
                              value={this.state.fromTo}
                              maxDate={moment().toDate()}
                              onChange={this.handleDate1}
                              renderInput={(params) => <TextField {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD/MM/YYYY"
                                }}
                              />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <div className='grf_suser'>
                        <ReactMultiSelectCheckboxes
                          // options={this.state.defOptions}
                          
                          options={[{label: "All", value: "*"}, ...this.state.defOptions]}
                          required
                          placeholderButtonLabel="Select Users"
                          getDropdownButtonLabel={this.getDropdownButtonLabel}
                          value={this.state.selectedUsers}
                          id="selectUser"
                          hideSelectedOptions={false}
                          // checked={this.state.checked == true? true : false}
                          onChange={this.onChange}
                          // onChange={this.handleChange}

                        />
                       






                      </div>
                      <div className='grfBtn'>

                        {/* <Button variant="contained" className="apply" onClick={(e)=>{this.getData(document.getElementById("dateFrom").value,document.getElementById("dateTo").value,this.state.selectedUsers)}} >Apply</Button> */}
                        <Button variant="contained" className="apply"  disabled={this.state.disabledApply} onClick={(e) => { this.getData(this.state.fromDt, this.state.fromTo, this.state.selectedUsers) }} >Apply</Button>
                        {/* <Button variant="contained" className="apply" onClick={(e)=>{checkedData()}} >Apply</Button> */}
                        <Export disabledDownload={this.state.disabledDownload} onExport={() => this.downloadCSVFromJson()} />
                        
                      </div>
                    </div>
                    <div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
            {/* <GeneratereportModal />               */}
            <DataTable
            // actions={actionsMemo}
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              noDataComponent="No Data"
              // selectableRows
              // onSelectedRowsChange={handleChange}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              className='dataTableScroll'
            />
          </section>
        </div>
      </div>


      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>



      <div className={this.state.modal ? 'overlay dblock' : 'overlay'} ></div>



    </>
  );
};

export default generatereportTemplate;