import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import $ from 'jquery';
import adminService from '../../../services/admin.service'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { ValidatorForm } from 'react-material-ui-form-validator';

export default function ForgotPassword() {


    const [searchParams] = useSearchParams();
    const refForm = useRef();
    const passwordRef = useRef();
    const email = searchParams.get("email")
    const code = searchParams.get("code")

    const [loader, setLoader] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [validationError, setValidationError] = useState("");
    const [inputError, setInputError] = useState(false);

    const navigate = useNavigate();

    function validatePassword(newPassword){
        if(newPassword.length < 8){
            setValidationError("Password must be at least 8 characters")
        } else {
            setValidationError('')
        }
    }

    // useEffect(() => {
    //     ValidatorForm.addValidationRule('passwordMismatch', (value) => {
            
    //         if (value !== newPassword) {
    //             return false;
    //         }
    //         return true;
    //     });
    // }, [confirmPassword])

    // useEffect(()=>{
    //     const data ={
    //         email: email,
    //         code: code
    //     }
    //     adminService.httpUserPost('/data-import/users/forgetPasswordMail', data).then((res)=>{
    //         if(res.success=== true){
    //             navigate('/forgetPassword')
    //         }
    //     })
    // },[])
    
    let makeid=(length)=>{
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }
    
    const savePassword = (e) => {
        
        setNewPassword(passwordRef.current.value);
        setInputError(false)
    }

    const handleConfirmPassChange = (e) =>{
        setConfirmPassword(e.target.value)
        setInputError(false);
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        let passwordString = makeid(10) + newPassword;
        let password = btoa(passwordString);
        console.log(password)
        console.log('FORM SUBMITTED')
        const data = {
            email: email,
            code: code,
            password: password,
        }

        if(confirmPassword != newPassword){
            setInputError(true)
        }
        else{
            adminService.httpUserPost('/data-import/users/forgetPassword', data).then((res) => {
                console.log(data)
                console.log('RESPONSE: ',res)
                if (res.success === true) {
                    setLoader(false)
                    swal(
                        `${res.message}`,
                        'You can now login to your account with your new password.',
                        'success'
                    ).then((ok) => {
                        if (ok) {
                            setLoader(true)
                            navigate('/login')
                            setLoader(false)
                        }
                    })
                }
                else if (res.success === false) {
                    setLoader(true)
                    swal(
                        `${res.message}`,
                        'Sorry, we were unable to reset your password.',
                        'error'
                    ).then((ok) => {
                        if (ok) {
                            setLoader(true)
                            navigate('/login')
                            setLoader(false)
                        }
                    })
                    setLoader(false)
                } if (newPassword !== confirmPassword) {
                    toast.error("Password does not match.");
                    return;
                }
            }).catch(error => {
                toast.error(error.message)
            })
        }

    }



    const [passwordType,  setPasswordType] = useState("password");    
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }

    const [passwordType1, setPasswordType1] = useState("password");
    const togglePassword1 =()=>{
        if(passwordType1==="password")
        {
         setPasswordType1("text")
         return;
        }
        setPasswordType1("password")
    }
    
    return (
        <>
            <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
                <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
            </div>
                {/* <form onSubmit={handleSubmit}>
                    <input type='password' value={newPassword} onChange={() => setNewPassword()} id="newPassword" label="New Password" variant="filled" required />
                    <input type='password' value={confirmPassword} onChange={() => setConfirmPassword()} id="confirmPassword" label="Confirm Password" variant="filled" required />
                    <button type='submit' variant="contained">Submit</button>
                </form> */}
                <div className='forgotPassword'>
                    <div className='forgotPassword_form'>
                        <div className='fp_logo'><img src="assets/images/logo_wmis.svg" /></div>
                        <div className='fp_title'>Forgot Password?</div>
                        <div className='fp_form'>
                            <form ref={refForm} onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-12 mb-3'>
                                        <label>New Password</label>
                                        <div className='position-relative'>
                                            <input type={passwordType} value={newPassword} onChange={event => { savePassword(); validatePassword(event.target.value)}} id="newPassword" ref={passwordRef} label="New Password" variant="filled" required />
                                            {validationError && <p style={{color: "red", fontSize: "small", marginBottom: '0'}}>{validationError}</p>}
                                            <div className="input-group-btn" onClick={togglePassword}>
                                                { passwordType==="password"? <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg> :<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityOffIcon"><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path></svg> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <label>Confirm Password</label>
                                        <div className='position-relative'>
                                            <input type={passwordType1} value={confirmPassword} onChange={(e) => handleConfirmPassChange(e)}
                                            onBlur={inputError}
                                            id="confirmPassword" label="Confirm Password" variant="filled" required />
                                            {inputError && <p style={{color: "red", fontSize: "small", marginBottom: '0'}}>Password does not match</p>}
                                            <div className="input-group-btn" onClick={togglePassword1}>
                                                { passwordType1==="password"? <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg> :<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityOffIcon"><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path></svg> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-3'>
                                        <button type='submit' variant="contained">Submit <img src="assets/lib/images/home/arrow_right.svg" /></button>
                                    </div>
                                </div>                              
                            </form>
                        </div>
                    </div>
                </div>

        </>
    )
}
