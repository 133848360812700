import React, { useState, useEffect, useRef } from "react";
import './data-search-result.css';
import ReactEcharts from 'echarts-for-react';
import * as echarts from "echarts";

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from '@mui/material/Button';
import DataTable from 'react-data-table-component';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ExploreVisualiseModal from "../data-search/explore-visualise-modal";

import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from '@mui/material/Tooltip';
import { width } from "@mui/system";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DataSearchChart(props) {
    const formatUtil = echarts.format;
    const chart = useRef(null);
    const { data, type } = props;
    const [title, setTitle] = useState('');
    const [showData, setShowData] = useState([]);
    let chartInstance = null;

    const defOption = {
        title: {
          show: true,
          textStyle:{
            color:'#333',
            fontSize:14,
            fontFamily: "Mulish",
            fontWeight: "400"
          },
          text: "Loading...",
          left: 'center',
          top: 'center'
        },
        xAxis: {
            show: false
        },
        yAxis: {
            show: false
        },
        series: []
    };    
    
    useEffect(() => {
        // debugger;
        let uc = getUseCase();
        renderChart(uc, type);
    }, [props.data, props.type]);

    const getUseCase = () => {
        
        let [ind, unit, subgroup, area, timeperiod] = ['', '', '', '', ''];
        let [cind, cunit, csubgroup, carea, ctimeperiod] = [0, 0, 0, 0, 0];
        let d = [];

        for (let row of data) {
            if(ind == '') {
                ind = row.element.name?.en;
                cind += 1;
                
            } else if(ind != row.element.name?.en) {
                cind = cind <= 1? cind + 1: cind ;
            }

            if(unit == '') {
                unit = row.element.unit?.en
                cunit += 1;
            } else if(unit != row.element.unit?.en) {
                 
                cunit = cunit <= 1? cunit + 1: cunit ;
            }

            if(subgroup == '') {
                subgroup = row.element.subgroup?.[0]?.name?.en;
                csubgroup += 1;
            } else if(subgroup != row.element.subgroup?.[0]?.name?.en) {
                
                csubgroup = csubgroup <= 1? csubgroup + 1: csubgroup ;
            }

            if(area == '') {
                area = row.element.areaDetails?.[0]?.name?.en
                carea += 1;
            } else if(area != row.element.areaDetails?.[0]?.name?.en) {
                
                carea = carea <= 1? carea + 1: carea ;
            }

            if(timeperiod == '') {
                timeperiod = row.element?.data?.[0]?.[0]?.time_period?.start_time_period;
                ctimeperiod += 1;
            } else if(timeperiod != row.element?.data?.[0]?.[0]?.time_period?.start_time_period) {
                ctimeperiod = ctimeperiod <= 1? ctimeperiod + 1: ctimeperiod ;
            }

            d.push({
                'indicator': row.element.name?.en,
                'unit': row.element.unit?.en,
                'subgroup': row.element.subgroup?.[0]?.name?.en,
                'area': row.element.areaDetails?.[0]?.name?.en,
                'timeperiod': row.element?.data?.[0]?.[0]?.time_period?.start_time_period,
                'value': (+row.element?.data?.[0]?.[0]?.new_value)
            });
        }

        setShowData(d);
        console.log(cind, cunit, csubgroup, carea, ctimeperiod)
        if (cind && cunit && csubgroup && carea && ctimeperiod) {
            return useCaseMatrix[cind][cunit][csubgroup][carea][ctimeperiod];
        }
        return null;        
    }

    // useEffect(() => {
    //     return () => {
    //     chartInstance && chartInstance.dispose();
    //     };
    // }, []);

    const renderChart = (useCase, type) => {
        const renderInstance = echarts.getInstanceByDom(chart.current);
        if (renderInstance) {
            chartInstance = renderInstance;
        } else {
            chartInstance = echarts.init(chart.current);
        }    
        setTitle(getTitle(useCase?.useCase, type));
        let optionData = getOptionData(useCase?.useCase, type);
        // let elem = chart.current?.getEchartsInstance();
        chartInstance.clear();
        chartInstance.setOption(optionData);
    }

    const getTitle = (useCase, type) => {
        let title = "dummy title";
        if(type == 'line' || type == 'bar' || type == 'column' || type == 'funnel') {
            switch(useCase) {
                case 'UC1':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC2':
                    title = showData?.[0]?.area+" - s"+showData?.[0]?.indicator+" | "+showData?.[0]?.unit;
                    break; 
                case 'UC3':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" ("+showData?.[0]?.timeperiod+")";
                    break; 
                case 'UC4':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" | "+showData?.[0]?.subgroup;
                    break; 
                case 'UC5':
                    title = showData?.[0]?.area+" - "+ showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" ("+showData?.[0]?.timeperiod+")" ;
                    break; 
    
                case 'UC6':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" ("+showData?.[0]?.timeperiod+")";
                    break; 
                case 'UC7':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit;
                    break; 
                case 'UC8':
                    title = showData?.[0]?.unit+" | "+showData?.[0]?.subgroup;
                    break; 
                case 'UC9':
                    title = showData?.[0]?.subgroup;
                    break; 
                case 'UC10':
                    title = "";
                    break; 
                case 'UC11':
                    title = showData?.[0]?.indicator+" | "+ showData?.[0]?.subgroup;
                    break; 
                case 'UC12':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.indicator+" | "+showData?.[0]?.subgroup;
                    break; 
                case 'UC13':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break; 
                case 'UC14':
                    title = showData?.[0]?.unit+" | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break; 
                case 'UC15':
                    title = showData?.[0]?.indicator+ " ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC16':
                    title = showData?.[0]?.subgroup+ " ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC17':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.indicator+" | "+showData?.[0]?.unit;
                    break;
                case 'UC18':
                    title = showData?.[0]?.area+" - " +showData?.[0]?.unit +" | " +showData?.[0]?.subgroup;
                    break;
                case 'UC19':
                    title = showData?.[0]?.unit +" | " +showData?.[0]?.subgroup +" ("+ showData?.[0]?.timeperiod+")";
                    break;
                case 'UC20':
                    title = showData?.[0]?.area+ " - " +showData?.[0]?.unit +" ("+ showData?.[0]?.timeperiod+")";
                    break;
                case'UC21':
                    title = showData?.[0].indicator;
                    break;
                case 'UC22':
                    title = showData?.[0].indicator+ " | "+showData?.[0].subgroup+" ("+showData?.[0].timeperiod+")" ;
                    break;
                case 'UC23':
                    title = showData?.[0].area+ " - "+showData?.[0].indicator;
                    break;
                case 'UC24':
                    title = showData?.[0].indicator+ " (" +showData?.[0].timeperiod+")";
                    break;
                case 'UC25':
                    title = showData?.[0].unit+ " (" +showData?.[0].timeperiod+")";
                    break;
                case 'UC26':
                    title = showData?.[0].unit;
                    break;
                case 'UC27':
                    title = showData?.[0]?.area+" - " +showData?.[0]?.unit;
                    break;
                case 'UC28':
                    title = showData?.[0]?.area+" - " +showData?.[0]?.subgroup;
                    break;
                case 'UC29':
                    title = showData?.[0]?.subgroup+" (" +showData?.[0]?.timeperiod+ ")";
                    break;
                case 'UC30':
                    title = showData?.[0]?.timeperiod;
                    break;
                case 'UC31':
                    title = showData?.[0]?.timeperiod;
                    break;
                case 'UC32':
                    title = showData?.[0]?.area;
                    break;
            }
        } else if(type == 'pie' || type == 'doughnut' || type == 'treemap' || type == 'radar') {

            switch(useCase) {
                case 'UC1':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC2':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+ " | " +showData?.[0]?.subgroup;
                    break;
                case 'UC3':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC4':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit+" | "+showData?.[0]?.subgroup;
                    break;
                case 'UC5':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.indicator+" | "+showData?.[0]?.unit;
                    break;
                case 'UC6':
                    title = "";
                    break;
                case 'UC7':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.unit;
                    break;
                case 'UC8':
                    title = showData?.[0]?.unit+" | "+showData?.[0]?.subgroup;
                    break;
                case 'UC9':
                    title = showData?.[0]?.subgroup;
                    break;
                case 'UC10':
                    title = "";
                    break;
                case 'UC11':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.subgroup;
                    break;
                case 'UC12':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.indicator+" | "+showData?.[0]?.subgroup;
                    break;
                case 'UC13':
                    title = showData?.[0]?.indicator+" | "+showData?.[0]?.subgroup+" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC14':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.unit+" | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC15':
                    title = showData?.[0]?.area +showData?.[0]?.indicator +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC16':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.subgroup+" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC17':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.unit+" | "+showData?.[0]?.indicator;
                    break;
                case 'UC18':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.unit+" | "+showData?.[0]?.subgroup;
                    break;
                case 'UC19':
                    title = showData?.[0]?.unit+" | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC20':
                    title = showData?.[0]?.area+" - "+showData?.[0]?.unit +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC21':
                    title = showData?.[0]?.indicator;
                    break;
                case 'UC22':
                    title = showData?.[0]?.indicator+ " | "+showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC23':
                    title =  showData?.[0]?.area+" - "+showData?.[0]?.indicator;
                    break;
                case 'UC24':
                    title = showData?.[0]?.indicator +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC25':
                    title = showData?.[0]?.unit +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC26':
                    title = showData?.[0]?.unit;
                    break;
                case 'UC27':
                    title = showData?.[0]?.area+" - "+ showData?.[0]?.unit;
                    break;
                case 'UC28':
                    title = showData?.[0]?.area+" - "+ showData?.[0]?.subgroup;
                    break;
                
                case 'UC29':
                    title = showData?.[0]?.subgroup +" ("+showData?.[0]?.timeperiod+")";
                    break;
                case 'UC30':
                    title = showData?.[0]?.area+ " ("+showData?.[0]?.timeperiod+")";
                    break;

                case 'UC31':
                    title = showData?.[0]?.timeperiod;
                    break;
                case 'UC32':
                    title = showData?.[0]?.area;
                    break;

                }  
        }
        
        
        return title;
    }

    const getOptionData = (useCase, type) => {
        // console.log("Sudhanshu data:", data);
        // console.log("Sudhanshu useCase:", useCase);
        // console.log("Sudhanshu type:", type);
        let oData = {
            title: {
              show: true,
              textStyle:{
                color:'#333',
                fontSize:14,
                fontFamily: "Mulish",
                fontWeight: "400"
              },
              text: "No Data",
              left: 'center',
              top: 'center'
            },
            xAxis: {
                show: false
            },
            yAxis: {
                show: false
            },
            series: [],
            source: ""
        };

        if(useCase == 'UC1') {
            oData = {
                title: {
                  show: true,
                  textStyle:{
                    color: '#333',//'#B8372B',
                    fontSize: 50,
                    fontFamily: "Mulish",
                    fontWeight: "400"
                  },
                  text: (+showData?.[0]?.value).toLocaleString(),//(+result.data?.[0]?.new_value)?.toLocaleString(),
                  left: 'center',
                  top: 'center'
                },
                xAxis: {
                    show: false
                },
                yAxis: {
                    show: false
                },
                series: [],
                source: ""
            };
            return oData;
        }
        
        if(type == 'line' || type == 'bar' || type == 'funnel') {
            if(useCase == 'UC2' || useCase == 'UC12') { 
                //TP(multiple) on x-axis and Subgroup on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.timeperiod;

                    let sgName = row.subgroup;
                    if(useCase == 'UC12') {
                        sgName = row.unit;
                    }
                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            

                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }                                              
                });

                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }
                
                let seri = [];
                
                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }
                

            } else if(useCase == 'UC3' 
                        || useCase == 'UC7' 
                        || useCase == 'UC8' 
                        || useCase == 'UC9' 
                        || useCase == 'UC10'
                        || useCase == 'UC11'
                        || useCase == 'UC13') {
                //Area(multiple) on x-axis and others on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.area;
                    if(useCase != 'UC3' && useCase != 'UC13') {
                        tp = tp+"-"+row.timeperiod;
                    }

                    let sgName;
                    if(useCase == 'UC8') {
                        sgName = row.indicator;
                    } else if(useCase == 'UC9') {
                        sgName = row.indicator + "-" + row.unit;
                    } else if(useCase == 'UC10') {
                        sgName = row.indicator + "-" + row.unit+"-"+row.subgroup;
                    } else if(useCase == 'UC11' || useCase == 'UC13') {
                        sgName = row.unit;
                    } else {
                        sgName = row.subgroup;
                    }

                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }                                  
                                
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }
                
                let seri = [];

                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }

            } else if(useCase == 'UC4') {
                //TP(multiple) on x-axis and Area on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.timeperiod;
                            
                    let sgName = row.area;

                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }      

                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }      
                });
                
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }

                let seri = [];
    
                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }
                

            } else if(useCase == 'UC5') {
                //if subgroup is multiple and on x-axis and value on y-axis
                let sgArr = [];
                let vData = [];
                let legendData = [];
                let colorPalette = ['#A6170A', '#ED9C94', '#CF655B', '#B8372B', '#7D1B12', '#440A05'];
                //let tpName = "";
                showData.forEach(row=> {
                    sgArr.push(row.subgroup);
                    vData.push(row.value);
                });

                if (type == 'line' || type == 'bar') {
                    oData = {
                        legend: {
                          show: false,
                          textStyle: {
                            color: "#333"
                          }
                        },
                        tooltip: {
                          trigger: 'item',
                          axisPointer: {
                            type: 'shadow'
                          }
                        },
                        grid:{
                          containLabel: true,
                          left: '10%',
                          bottom: '3%',
                          top: '10%'
                         },
                        xAxis: [{
                            type: 'category',
                            data: sgArr,
                            axisLabel: {
                              color: "#333"
                            },
                            axisLine: {
                              lineStyle: {
                                color: "#333"
                              }
                            }                    
                        }],
                        yAxis: [{
                            type: 'value',
                            axisLabel: {
                              color: "#333"
                            },
                            splitLine: {
                              lineStyle: {
                                color: "rgba(255, 255, 255, 0.7)",
                                type: "dashed"
                              }
                            }
                        }],
                        series: [{ 
                            name: 'Value',
                            data: vData,
                            type: type,
                            label: { 
                              show: false, 
                              position: 'top',
                              formatter: function(params) {
                                return (+params.value).toLocaleString();
                              }
                            },
                            emphasis: {
                              focus: 'series'
                            },
                            lineStyle: {
                              color: '#A6170A',
                              width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                            }
                        }]
                      };
                } else if(type == 'funnel') {
                    
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgArr,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: [
                            { 
                                name: "Value",
                                data: vData,
                                type: type,
                                left: '10%',
                                top: 60,
                                bottom: 60,
                                width: '80%',
                                // min: 0,
                                // max: 100,
                                minSize: '0%',
                                maxSize: '100%',
                                sort: 'ascending',
                                gap: 2,
                                label: { 
                                    show: false, 
                                    position: 'inside',
                                    formatter: function(params) {
                                        return (+params.value).toLocaleString();
                                    }
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                lineStyle: {
                                    color: '#A6170A',
                                    //color: this.colors[i],
                                    width: 1
                                },
                                itemStyle: {
                                    borderWidth: 1,
                                    borderColor: '#A6170A',
                                    color: '#A6170A'
                                    //borderColor: this.colors[i],
                                    //color: this.colors[i]
                                }
                            }
                        ]
                    };
                }
                
            } else if(useCase == 'UC6') {
                //if subgroup is multiple, on x-axis and area on y-axis
                let tpArr = []; 
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let sgName = row.subgroup;
                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }
                    let tp = row.area;
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }
                
                let seri = [];

                if (type == 'line' || type == 'bar') {
                    tpArr.forEach((tp, i) => {
                        seri.push({ 
                        name: tp,
                        data: vData[tp],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                            return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                    //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: tpArr,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: sgNames,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {

                    tpArr.forEach((tp, i) => {
                        seri.push({ 
                            name: tp,
                            data: vData[tp],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: tpArr,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }
    
                
            } else if(useCase == 'UC14'
                    || useCase == 'UC15'
                    || useCase == 'UC16'
                    || useCase == 'UC22') { 
                //tp(Indicator/US/IU multiple) on x-axis and sg(Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.indicator;
                    if(useCase == 'UC14') {
                        tp = row.indicator;
                    } else if(useCase == 'UC15') {
                        tp = row.unit+"-"+row.subgroup;
                    } else if(useCase == 'UC16') {
                        tp = row.indicator+"-"+row.unit;
                    } else if(useCase == 'UC22') {
                        tp = row.unit;
                    } 
                    
                    let sgName = row.area;
                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }

                let seri = [];
    
                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            label: { 
                                show: false, 
                                position: 'top',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }
                

            } else if(useCase == 'UC17' 
                    || useCase == 'UC18'
                    || useCase == 'UC19'
                    || useCase == 'UC20') { 
                //tp(Subgroup/Indicator multiple) on x-axis and sg(timeperiod/Area/Subgroup) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.subgroup;
                    if(useCase != 'UC17') {
                        tp = row.indicator;
                    }

                    let sgName = row.timeperiod;
                    if(useCase == 'UC19') {
                        sgName = row.area;
                    } else if(useCase == 'UC20') {
                        sgName = row.subgroup;
                    }

                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }


                let seri = [];

                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
    
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }

            } else if(useCase == 'UC21'
                    || useCase == 'UC23'
                    || useCase == 'UC24') { 
                //tp(US multiple) on x-axis and sg(Area/TP) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.unit+"-"+row.subgroup;

                    let sgName = row.area+'-'+row.timeperiod;
                    if(useCase == 'UC23') {
                        sgName = row.area;
                    } else if(useCase == 'UC24') {
                        sgName = row.timeperiod;
                    }

                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }                
                let seri = [];

                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
    
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }
                

            } else if(useCase == 'UC25' 
                    || useCase == 'UC26'
                    || useCase == 'UC27'
                    || useCase == 'UC28'
                    || useCase == 'UC29') { 
                //tp(IU multiple) on x-axis and sg(timeperiod/Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.indicator;
                    if(useCase == 'UC25' 
                    || useCase == 'UC26'
                    || useCase == 'UC27') {
                        tp = tp+'-'+row.subgroup;
                    } else if(useCase == 'UC28'
                            || useCase == 'UC29') {
                        tp = tp+'-'+row.unit
                    }

                    let sgName = row.area;
                    if(useCase == 'UC26') {
                        sgName = sgName+'-'+row.timeperiod;
                    } else if(useCase == 'UC27' || useCase == 'UC28') {
                        sgName = row.timeperiod;
                    }

                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }
                
                let seri = [];

                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            label: { 
                                show: false, 
                                position: 'top',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
    
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }

                

            } else if(useCase == 'UC30' 
                    || useCase == 'UC31'
                    || useCase == 'UC32') { 
                //tp(IUS multiple) on x-axis and sg(timeperiod/Area) on Y-axis
                let tpArr = [];
                let vData = {};
                let d = {};
                let sgNames = [];
                showData.forEach(row=> {
                    let tp = row.indicator+'-'+row.unit+'-'+row.subgroup;
                    
                    let sgName = row.area;
                    if(useCase == 'UC32') {
                        sgName = row.timeperiod;
                    }
                    
                    if(!sgNames.includes(sgName)) {
                        sgNames.push(sgName);
                    }            
                    
                    if(!tpArr.includes(tp)) {
                        tpArr.push(tp);
                        if(!(tp in d)) {
                            d[tp] = {};
                        }
                        d[tp][sgName] = row.value;
                    }            
                });
                for(let t of tpArr) {
                    for(let sg of sgNames) {
                        if(sg in vData) {
                            vData[sg].push(d[t][sg]);
                        } else {
                            vData[sg] = new Array(d[t][sg]);
                        }
                    }
                }
                
                let seri = [];

                if (type == 'line' || type == 'bar') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                        name: sg,
                        data: vData[sg],
                        type: type,
                        label: { 
                            show: false, 
                            position: 'top',
                            formatter: function(params) {
                                return (+params.value).toLocaleString();
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            color: '#A6170A',
                            //color: this.colors[i],
                            width: 1
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#A6170A',
                            color: '#A6170A'
                            //borderColor: this.colors[i],
                            //color: this.colors[i]
                        }
                        });
                    });
    
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        xAxis: {
                            type: 'category',
                            data: tpArr,
                            axisLabel: {
                                color: "#333"
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#333"
                                }
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                color: "#333"
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#333",
                                    type: "dashed"
                                }
                            }
                        },
                        toolbox: {
                            show: false,
                            orient: 'vertical',
                            left: 'right',
                            top: 'center',
                            feature: {
                                mark: { show: true },
                                dataView: { show: true, readOnly: true },
                                magicType: { show: true, type: ['bar', 'line'] },
                                // restore: { show: true },
                                saveAsImage: { show: true, title: 'Download' }
                            }
                        },
                        series: seri
                    };
    
                } else if(type == 'funnel') {
                    sgNames.forEach((sg, i) => {
                        seri.push({ 
                            name: sg,
                            data: vData[sg],
                            type: type,
                            left: '10%',
                            top: 60,
                            bottom: 60,
                            width: '80%',
                            // min: 0,
                            // max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'ascending',
                            gap: 2,
                            label: { 
                                show: false, 
                                position: 'inside',
                                formatter: function(params) {
                                    return (+params.value).toLocaleString();
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                color: '#A6170A',
                                //color: this.colors[i],
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#A6170A',
                                color: '#A6170A'
                                //borderColor: this.colors[i],
                                //color: this.colors[i]
                            }
                        });
                    });
        
                    oData = {
                        tooltip: {
                            trigger: 'item',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: sgNames,
                            // orient: 'vertical',
                            // top: 'center',
                            // icon: 'rect',
                            // right: 0,
                            bottom: 0,
                            // height: 'auto',
                            type: 'scroll',
                            show: true,
                            formatter: function (name) {
                                return name.length > 17?name.substring(0, 17) + '...':name;
                            },
                            tooltip: {
                                show: true,
                                trigger: 'item'
                            },
                            textStyle: {
                                color: "#333"
                            }
                        },
                        grid:{
                            containLabel: true,
                            left: '10%',
                            // height: 'auto',
                            // bottom: '3%',
                            top: '10%',
                            y2:30,
                        },
                        series: seri
                    };
                }


            } 
        } else if(type == 'pie') {
            let colorPalette = ['#B8372B', '#CF655B', '#ED9C94', '#FFCBC6'];
            if(useCase == 'UC2' 
            || useCase == 'UC3'
            || useCase == 'UC5'
            || useCase == 'UC14') {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                // let legendData = [];                
                let tpName = "";
                showData.forEach(row=> {
                    if(useCase == 'UC2') {
                        iusArr.push(row.timeperiod);
                    } else if(useCase == 'UC3') {
                        iusArr.push(row.area);
                    } else if(useCase == 'UC5') {
                        iusArr.push(row.subgroup);
                    } else if(useCase == 'UC14') {
                        iusArr.push(row.indicator);
                    }

                    vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: iusArr,
                        formatter: function (name) {
                          return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                          show: true,
                          trigger: 'item'
                        },
                        textStyle: {
                          color: "#333"
                        }
                    },                
                    series: [
                        {
                          name: "",
                          type: 'pie',
                          // radius: '75%',
                          center: ['40%', '50%'],
                          data: vData,
                          color: colorPalette,
                          label: {
                            show: false
                          },                      
                          emphasis: {
                              itemStyle: {
                                  shadowBlur: 10,
                                  shadowOffsetX: 0,
                                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                              }
                            }
                          
                        }                        
                      ]
                  };
            } else if(useCase == 'UC4' 
                    || useCase == 'UC6'
                    || useCase == 'UC12'
                    || useCase == 'UC13'
                    || useCase == 'UC15'
                    || useCase == 'UC16'
                    || useCase == 'UC17'
                    || useCase == 'UC18'
                    || useCase == 'UC19'
                    || useCase == 'UC20'
                    || useCase == 'UC22'
                    || useCase == 'UC23'
                    || useCase == 'UC24') {
                //2-dimensions are multiple
            
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.area;
                    let tp = row.timeperiod;                    
                    if(useCase == 'UC6') {
                        sgName = row.subgroup;
                        tp = row.area;                        
                    } else if(useCase == 'UC12') {
                        sgName = row.timeperiod;
                        tp = row.unit;                        
                    } else if(useCase == 'UC13') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC15') {
                        sgName = row.subgroup;
                        tp = row.unit;                        
                    } else if(useCase == 'UC16') {
                        sgName = row.indicator;
                        tp = row.unit;                        
                    } else if(useCase == 'UC17') {
                        sgName = row.subgroup;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC18') {
                        sgName = row.indicator;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC19') {
                        sgName = row.indicator;
                        tp = row.area;                        
                    } else if(useCase == 'UC20') {
                        sgName = row.indicator;
                        tp = row.subgroup;                        
                    } else if(useCase == 'UC22') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.timeperiod;
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.area;
                    }
                    legendData.push(sgName+'-'+tp);
                    seri.push({
                        name: sgName+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: legendData,
                        formatter: function (name) {
                            return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item'
                        },
                        textStyle: {
                            color: "#333",
                        }
                    },
                    series: [
                        {
                            name: "",
                            type: 'pie',
                            // radius: '55%',
                            center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: false
                            },                      
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            } else if(useCase == 'UC7' 
                    || useCase == 'UC8'
                    || useCase == 'UC9'
                    || useCase == 'UC10'
                    || useCase == 'UC11'
                    || useCase == 'UC21'
                    || useCase == 'UC25'
                    || useCase == 'UC26'
                    || useCase == 'UC27'
                    || useCase == 'UC28'
                    || useCase == 'UC29'
                    || useCase == 'UC30'
                    || useCase == 'UC31'
                    || useCase == 'UC32') {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if(useCase == 'UC8') {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC9') {
                        sgName = row.indicator+'|'+row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC10') {
                        sgName = row.indicator+'|'+row.unit+'|'+row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC11') {
                        sgName = row.area; 
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if(useCase == 'UC21') {
                        sgName = row.unit+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC25') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC26') {
                        sgName = row.indicator+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC27') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC28') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC29') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.area;
                    } else if(useCase == 'UC30') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.subgroup;
                    } else if(useCase == 'UC31') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC32') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    legendData.push(sgName+'-'+area+'-'+tp);
                    seri.push({
                        name: sgName+'-'+area+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: legendData,
                        formatter: function (name) {
                            return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item'
                        },
                        textStyle: {
                            color: "#333",
                        }
                    },
                    series: [
                        {
                            name: "",
                            type: 'pie',
                            // radius: '55%',
                            center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: false
                            },                      
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            }
        } else if(type == 'doughnut') {
            let colorPalette = ['#B8372B', '#CF655B', '#ED9C94', '#FFCBC6'];
            if(useCase == 'UC2' 
            || useCase == 'UC3'
            || useCase == 'UC5'
            || useCase == 'UC14') {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                // let legendData = [];                
                let tpName = "";
                showData.forEach(row=> {
                    if(useCase == 'UC2') {
                        iusArr.push(row.timeperiod);
                    } else if(useCase == 'UC3') {
                        iusArr.push(row.area);
                    } else if(useCase == 'UC5') {
                        iusArr.push(row.subgroup);
                    } else if(useCase == 'UC14') {
                        iusArr.push(row.indicator);
                    }

                    vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: iusArr,
                        formatter: function (name) {
                          return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                          show: true,
                          trigger: 'item'
                        },
                        textStyle: {
                          color: "#333"
                        }
                    },                
                    series: [
                        {
                          name: "",
                          type: 'pie',
                          radius: ['40%', '70%'],
                        //   center: ['40%', '50%'],
                          data: vData,
                          color: colorPalette,
                          label: {
                            show: false
                          },                      
                          emphasis: {
                              itemStyle: {
                                  shadowBlur: 10,
                                  shadowOffsetX: 0,
                                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                              }
                            }
                          
                        }                        
                      ]
                  };
            } else if(useCase == 'UC4' 
                    || useCase == 'UC6'
                    || useCase == 'UC12'
                    || useCase == 'UC13'
                    || useCase == 'UC15'
                    || useCase == 'UC16'
                    || useCase == 'UC17'
                    || useCase == 'UC18'
                    || useCase == 'UC19'
                    || useCase == 'UC20'
                    || useCase == 'UC22'
                    || useCase == 'UC23'
                    || useCase == 'UC24') {
                //2-dimensions are multiple
            
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.area;
                    let tp = row.timeperiod;                    
                    if(useCase == 'UC6') {
                        sgName = row.subgroup;
                        tp = row.area;                        
                    } else if(useCase == 'UC12') {
                        sgName = row.timeperiod;
                        tp = row.unit;                        
                    } else if(useCase == 'UC13') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC15') {
                        sgName = row.subgroup;
                        tp = row.unit;                        
                    } else if(useCase == 'UC16') {
                        sgName = row.indicator;
                        tp = row.unit;                        
                    } else if(useCase == 'UC17') {
                        sgName = row.subgroup;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC18') {
                        sgName = row.indicator;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC19') {
                        sgName = row.indicator;
                        tp = row.area;                        
                    } else if(useCase == 'UC20') {
                        sgName = row.indicator;
                        tp = row.subgroup;                        
                    } else if(useCase == 'UC22') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.timeperiod;
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.area;
                    }
                    legendData.push(sgName+'-'+tp);
                    seri.push({
                        name: sgName+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: legendData,
                        formatter: function (name) {
                            return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item'
                        },
                        textStyle: {
                            color: "#333",
                        }
                    },
                    series: [
                        {
                            name: "",
                            type: 'pie',
                            radius: ['40%', '70%'],
                            // center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: false
                            },                      
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            } else if(useCase == 'UC7' 
                    || useCase == 'UC8'
                    || useCase == 'UC9'
                    || useCase == 'UC10'
                    || useCase == 'UC11'
                    || useCase == 'UC21'
                    || useCase == 'UC25'
                    || useCase == 'UC26'
                    || useCase == 'UC27'
                    || useCase == 'UC28'
                    || useCase == 'UC29'
                    || useCase == 'UC30'
                    || useCase == 'UC31'
                    || useCase == 'UC32') {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if(useCase == 'UC8') {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC9') {
                        sgName = row.indicator+'|'+row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC10') {
                        sgName = row.indicator+'|'+row.unit+'|'+row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC11') {
                        sgName = row.area; 
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if(useCase == 'UC21') {
                        sgName = row.unit+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC25') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC26') {
                        sgName = row.indicator+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC27') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC28') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC29') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.area;
                    } else if(useCase == 'UC30') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.subgroup;
                    } else if(useCase == 'UC31') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC32') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    legendData.push(sgName+'-'+area+'-'+tp);
                    seri.push({
                        name: sgName+'-'+area+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: legendData,
                        formatter: function (name) {
                            return name.length > 17?name.substring(0, 17) + '...':name;
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item'
                        },
                        textStyle: {
                            color: "#333",
                        }
                    },
                    series: [
                        {
                            name: "",
                            type: 'pie',
                            radius: ['40%', '70%'],
                            // center: ['40%', '50%'],
                            data: seri,
                            color: colorPalette,
                            label: {
                                show: false
                            },                      
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            }
        } else if(type == 'treemap') {
            let colorPalette = ['#B8372B', '#CF655B', '#ED9C94', '#FFCBC6'];
            if(useCase == 'UC2' 
            || useCase == 'UC3'
            || useCase == 'UC5'
            || useCase == 'UC14') {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                // let legendData = [];                
                let tpName = "";
                showData.forEach(row=> {
                    if(useCase == 'UC2') {
                        tpName = row.timeperiod;
                    } else if(useCase == 'UC3') {
                        tpName = row.area;
                    } else if(useCase == 'UC5') {
                        tpName = row.subgroup;
                    } else if(useCase == 'UC14') {
                        tpName = row.indicator;
                    }

                    vData.push({name: tpName, value: row.value});
                    // tpName = row.time_period?.start_time_period;
                });

                oData = {

                    tooltip: {
                        formatter: function (info) {
                          var value = info.value;
                          var treePathInfo = info.treePathInfo;
                          var treePath = [];
                          for (var i = 1; i < treePathInfo.length; i++) {
                            treePath.push(treePathInfo[i].name);
                          }
                          return [
                            '<div class="tooltip-title">' +
                              formatUtil.encodeHTML(treePath.join('/')) +
                              '</div>',
                                formatUtil.addCommas(value)
                          ].join('');
                        }
                      },
                    series: [
                        
                        {
                            
                          name: "",
                          type: type,
                          data: vData,
                        //   color: colorPalette,
                          label: {
                            show: false
                          },
                          levels: [
                            {
                                itemStyle: {
                                  borderWidth: 0,
                                  gapWidth: 5
                                }
                              },
                              {
                                itemStyle: {
                                  gapWidth: 1
                                }
                              },
                              {
                                colorSaturation: [0.35, 0.5],
                                itemStyle: {
                                  gapWidth: 1,
                                  borderColorSaturation: 0.6
                                }
                              }
                          ]                      
                        }    
                                            
                      ]
                  };
            } else if(useCase == 'UC4' 
                    || useCase == 'UC6'
                    || useCase == 'UC12'
                    || useCase == 'UC13'
                    || useCase == 'UC15'
                    || useCase == 'UC16'
                    || useCase == 'UC17'
                    || useCase == 'UC18'
                    || useCase == 'UC19'
                    || useCase == 'UC20'
                    || useCase == 'UC22'
                    || useCase == 'UC23'
                    || useCase == 'UC24') {
                //2-dimensions are multiple
            
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.area;
                    let tp = row.timeperiod;                    
                    if(useCase == 'UC6') {
                        sgName = row.subgroup;
                        tp = row.area;                        
                    } else if(useCase == 'UC12') {
                        sgName = row.timeperiod;
                        tp = row.unit;                        
                    } else if(useCase == 'UC13') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC15') {
                        sgName = row.subgroup;
                        tp = row.unit;                        
                    } else if(useCase == 'UC16') {
                        sgName = row.indicator;
                        tp = row.unit;                        
                    } else if(useCase == 'UC17') {
                        sgName = row.subgroup;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC18') {
                        sgName = row.indicator;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC19') {
                        sgName = row.indicator;
                        tp = row.area;                        
                    } else if(useCase == 'UC20') {
                        sgName = row.indicator;
                        tp = row.subgroup;                        
                    } else if(useCase == 'UC22') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.timeperiod;
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.area;
                    }
                    // legendData.push(sgName+'-'+tp);
                    seri.push({
                        name: sgName+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        formatter: function (info) {
                          var value = info.value;
                          var treePathInfo = info.treePathInfo;
                          var treePath = [];
                          for (var i = 1; i < treePathInfo.length; i++) {
                            treePath.push(treePathInfo[i].name);
                          }
                          return [
                            '<div class="tooltip-title">' +
                              formatUtil.encodeHTML(treePath.join('/')) +
                              '</div>',
                                formatUtil.addCommas(value)
                          ].join('');
                        }
                      },
                    series: [
                        {
                          name: "",
                          type: type,
                          data: seri,
                          color: colorPalette,
                          label: {
                            show: false
                          }                          
                        }                        
                      ]
                  };
            } else if(useCase == 'UC7' 
                    || useCase == 'UC8'
                    || useCase == 'UC9'
                    || useCase == 'UC10'
                    || useCase == 'UC11'
                    || useCase == 'UC21'
                    || useCase == 'UC25'
                    || useCase == 'UC26'
                    || useCase == 'UC27'
                    || useCase == 'UC28'
                    || useCase == 'UC29'
                    || useCase == 'UC30'
                    || useCase == 'UC31'
                    || useCase == 'UC32') {
                //3+ dimensions are multiple
                let legendData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if(useCase == 'UC8') {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC9') {
                        sgName = row.indicator+'|'+row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC10') {
                        sgName = row.indicator+'|'+row.unit+'|'+row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC11') {
                        sgName = row.area; 
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if(useCase == 'UC21') {
                        sgName = row.unit+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC25') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC26') {
                        sgName = row.indicator+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC27') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC28') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC29') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.area;
                    } else if(useCase == 'UC30') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.subgroup;
                    } else if(useCase == 'UC31') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC32') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    // legendData.push(sgName+'-'+area+'-'+tp);
                    seri.push({
                        name: sgName+'-'+area+'-'+tp,
                        value: row.value
                    });
                });
                
                oData = {
                    tooltip: {
                        formatter: function (info) {
                          var value = info.value;
                          var treePathInfo = info.treePathInfo;
                          var treePath = [];
                          for (var i = 1; i < treePathInfo.length; i++) {
                            treePath.push(treePathInfo[i].name);
                          }
                          return [
                            '<div class="tooltip-title">' +
                              formatUtil.encodeHTML(treePath.join('/')) +
                              '</div>',
                                formatUtil.addCommas(value)
                          ].join('');
                        }
                      },
                    series: [
                        {
                          name: "",
                          type: type,
                          data: seri,
                          color: colorPalette,
                          label: {
                            show: false
                          }                          
                        }                        
                    ]
                };
            }
        } else if(type == 'radar') {
            let colorPalette = ['#B8372B', '#CF655B', '#ED9C94', '#FFCBC6'];
            if(useCase == 'UC2' 
            || useCase == 'UC3'
            || useCase == 'UC5'
            || useCase == 'UC14') {
                //1-dimension multiple
                let iusArr = [];
                let vData = [];
                // let legendData = [];                
                let tpName = "";
                let max = 0;
                showData.forEach(row=> {
                    if(useCase == 'UC2') {
                        iusArr.push({name: row.timeperiod});
                    } else if(useCase == 'UC3') {
                        iusArr.push({name: row.area});
                    } else if(useCase == 'UC5') {
                        iusArr.push({name: row.subgroup});
                    } else if(useCase == 'UC14') {
                        iusArr.push({name: row.indicator});
                    }
                    max = (+row.value) > max ? (+row.value) : max;
                    vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                iusArr = iusArr.map((ele) => {
                    return {max : max, name: ele.name};
                })

                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    radar: {
                        // shape: 'circle',
                        indicator: iusArr
                      },
                    // legend: {
                    //     type: 'scroll',
                    //     orient: 'vertical',
                    //     right: 10,
                    //     top: 20,
                    //     bottom: 20,
                    //     data: iusArr,
                    //     formatter: function (name) {
                    //       return name.length > 17?name.substring(0, 17) + '...':name;
                    //     },
                    //     tooltip: {
                    //       show: true,
                    //       trigger: 'item'
                    //     },
                    //     textStyle: {
                    //       color: "#fff"
                    //     }
                    // },                
                    series: [
                        {
                          name: "",
                          type: type,
                          data: [
                                {
                                    value: vData
                                }
                            ],
                        }                        
                      ]
                  };
            } else if(useCase == 'UC4' 
                    || useCase == 'UC6'
                    || useCase == 'UC12'
                    || useCase == 'UC13'
                    || useCase == 'UC15'
                    || useCase == 'UC16'
                    || useCase == 'UC17'
                    || useCase == 'UC18'
                    || useCase == 'UC19'
                    || useCase == 'UC20'
                    || useCase == 'UC22'
                    || useCase == 'UC23'
                    || useCase == 'UC24') {
                //2-dimensions are multiple
            
                let legendData = [];
                let vData = [];
                let max = 0;
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.area;
                    let tp = row.timeperiod;                    
                    if(useCase == 'UC6') {
                        sgName = row.subgroup;
                        tp = row.area;                        
                    } else if(useCase == 'UC12') {
                        sgName = row.timeperiod;
                        tp = row.unit;                        
                    } else if(useCase == 'UC13') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC15') {
                        sgName = row.subgroup;
                        tp = row.unit;                        
                    } else if(useCase == 'UC16') {
                        sgName = row.indicator;
                        tp = row.unit;                        
                    } else if(useCase == 'UC17') {
                        sgName = row.subgroup;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC18') {
                        sgName = row.indicator;
                        tp = row.timeperiod;                        
                    } else if(useCase == 'UC19') {
                        sgName = row.indicator;
                        tp = row.area;                        
                    } else if(useCase == 'UC20') {
                        sgName = row.indicator;
                        tp = row.subgroup;                        
                    } else if(useCase == 'UC22') {
                        sgName = row.area;
                        tp = row.unit;                        
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.timeperiod;
                    } else if(useCase == 'UC23') {
                        sgName = row.unit+"|"+row.subgroup; 
                        tp = row.area;
                    }
                    legendData.push({name: sgName+'-'+tp});
                    max = (+row.value) > max ? (+row.value) : max;
                    vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                legendData = legendData.map((ele) => {
                    return {max : max, name: ele.name};
                })
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    radar: {
                        // shape: 'circle',
                        indicator: legendData
                      },
                    // legend: {
                    //     type: 'scroll',
                    //     orient: 'vertical',
                    //     right: 10,
                    //     top: 20,
                    //     bottom: 20,
                    //     data: iusArr,
                    //     formatter: function (name) {
                    //       return name.length > 17?name.substring(0, 17) + '...':name;
                    //     },
                    //     tooltip: {
                    //       show: true,
                    //       trigger: 'item'
                    //     },
                    //     textStyle: {
                    //       color: "#fff"
                    //     }
                    // },                
                    series: [
                        {
                          name: "",
                          type: type,
                          data: [
                                {
                                    value: vData
                                }
                            ],
                        }                        
                      ]
                  };
            } else if(useCase == 'UC7' 
                    || useCase == 'UC8'
                    || useCase == 'UC9'
                    || useCase == 'UC10'
                    || useCase == 'UC11'
                    || useCase == 'UC21'
                    || useCase == 'UC25'
                    || useCase == 'UC26'
                    || useCase == 'UC27'
                    || useCase == 'UC28'
                    || useCase == 'UC29'
                    || useCase == 'UC30'
                    || useCase == 'UC31'
                    || useCase == 'UC32') {
                //3+ dimensions are multiple
                let legendData = [];
                let max = 0;
                let vData = [];
                let seri = [];
                showData.forEach(row=> {
                    let sgName = row.subgroup;
                    let area = row.area;
                    let tp = row.timeperiod;
                    if(useCase == 'UC8') {
                        sgName = row.indicator;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC9') {
                        sgName = row.indicator+'|'+row.unit;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC10') {
                        sgName = row.indicator+'|'+row.unit+'|'+row.subgroup;
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC11') {
                        sgName = row.area; 
                        area = row.timeperiod;
                        tp = row.unit;
                    } else if(useCase == 'UC21') {
                        sgName = row.unit+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC25') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC26') {
                        sgName = row.indicator+"|"+row.subgroup; 
                        area = row.area;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC27') {
                        sgName = row.indicator; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC28') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.timeperiod;
                    } else if(useCase == 'UC29') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.area;
                    } else if(useCase == 'UC30') {
                        sgName = row.indicator; 
                        area = row.unit;
                        tp = row.subgroup;
                    } else if(useCase == 'UC31') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.area;
                    } else if(useCase == 'UC32') {
                        sgName = row.indicator+'|'+row.unit; 
                        area = row.subgroup;
                        tp = row.timeperiod;
                    }
                    
                    legendData.push({name: sgName+'-'+area+'-'+tp});
                    max = (+row.value) > max ? (+row.value) : max;
                    vData.push(row.value);
                    // tpName = row.time_period?.start_time_period;
                });

                legendData = legendData.map((ele) => {
                    return {max : max, name: ele.name};
                })
                
                oData = {
                    tooltip: {
                        trigger: 'item'
                    },
                    radar: {
                        // shape: 'circle',
                        indicator: legendData
                      },
                    // legend: {
                    //     type: 'scroll',
                    //     orient: 'vertical',
                    //     right: 10,
                    //     top: 20,
                    //     bottom: 20,
                    //     data: iusArr,
                    //     formatter: function (name) {
                    //       return name.length > 17?name.substring(0, 17) + '...':name;
                    //     },
                    //     tooltip: {
                    //       show: true,
                    //       trigger: 'item'
                    //     },
                    //     textStyle: {
                    //       color: "#fff"
                    //     }
                    // },                
                    series: [
                        {
                          name: "",
                          type: type,
                          data: [
                                {
                                    value: vData
                                }
                            ],
                        }                        
                      ]
                  };
            }
        }

        return oData;
    }
    
    return (
        <>
        <div className="dsr_barchart">
            <div className="dsr_barchart_section">
                <div className="dsr_barchart_heading"></div>{/** Area */}
                <div className="dsr_barchart_list">
                    <ul>
                        <li><a href="javascript:void(0);"><img src="assets/lib/images/home/label_on.svg" /></a></li>
                        <li><a href="javascript:void(0);"><img src="assets/lib/images/home/resets.svg" /></a></li>
                        <li><a href="javascript:void(0);"><img src="assets/lib/images/home/setting.svg" /></a></li>
                        <li><a href="javascript:void(0);"><img src="assets/lib/images/home/download.svg" /></a></li>
                    </ul>
                </div>
            </div>
            <div className="drs_indicators">
                <span className="drs_indicators_img">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.49963 6.69922H7.09961V8.09924H7.59961V13.1992H7.09961V14.5992H9.99963V13.1992H9.49963V6.69922Z" fill="#BE0026"/>
                    <path d="M7.40039 4.19958C7.40039 4.79958 7.90043 5.29956 8.50043 5.29956C9.10043 5.29956 9.6004 4.79958 9.6004 4.19958C9.6004 3.59958 9.10043 3.09961 8.50043 3.09961C7.90043 3.09961 7.40039 3.59958 7.40039 4.19958Z" fill="#BE0026"/>
                    <path d="M16 12.9C16 14.6 14.6 16 12.9 16H4.09998C2.39998 16 1 14.6 1 12.9V4.1001C1 2.4001 2.39998 1 4.09998 1H12.9C14.6 1 16 2.4001 16 4.1001V12.9Z" stroke="#BE0026" stroke-miterlimit="10"/>
                    </svg>
                </span>
                {/* <div className="drs_indicators_text">Effect of Cyclon <span>| Number</span> <span>| Total</span></div> */}
                <div className="drs_indicators_text">{title}</div>
            </div>
        </div>
        <div className={showData?.length == 1?"single-value drsChartss" : "multiple-value drsChartss"}>
            {/* <ReactEcharts
                ref={chart}
                option={defOption}
                style={{ height: "300px", left: 0, top: 0, width: "100%" }}
                opts={{ renderer: "svg" }}
            /> */}
            <div
                ref={chart}
                style={{
                    height: "600px",
                    width:'1100px'
                }}
                className='chartsdsr'
            />

        </div>
        </>
    );

}

//indicator, unit, subgroup, area, timeperiod
const useCaseMatrix = {
    1 : {//indicator
        1 : {//unit
            1 : {//subgroup
                1 : {//area
                    1 : {//timeperiod
                        useCase: 'UC1'
                    },
                    2 : {
                        useCase: 'UC2'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC3'
                    },
                    2 : {
                        useCase: 'UC4'
                    }
                }
            },
            2 : {
                1 : {
                    1 : {
                        useCase: 'UC5'
                    },
                    2 : {
                        useCase: 'UC17'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC6'
                    },
                    2 : {
                        useCase: 'UC7'
                    }
                }
    
            }
        },
        2 : {
            1 : {
                1 : {
                    1 : {
                        useCase: 'UC13'
                    },
                    2 : {
                        useCase: 'UC12'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC22'
                    },
                    2 : {
                        useCase: 'UC11'
                    }
                }
            },
            2 : {
                1 : {
                    1 : {
                        useCase: 'UC15'
                    },
                    2 : {
                        useCase: 'UC23'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC24'
                    },
                    2 : {
                        useCase: 'UC21'
                    }
                }
    
            }
        }    
    },
    2 : {
        1 : {
            1 : {
                1 : {
                    1 : {
                        useCase: 'UC14'
                    },
                    2 : {
                        useCase: 'UC18'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC19'
                    },
                    2 : {
                        useCase: 'UC8'
                    }
                }
            },
            2 : {
                1 : {
                    1 : {
                        useCase: 'UC20'
                    },
                    2 : {
                        useCase: 'UC27'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC25'
                    },
                    2 : {
                        useCase: 'UC26'
                    }
                }
    
            }
        },
        2 : {
            1 : {
                1 : {
                    1 : {
                        useCase: 'UC16'
                    },
                    2 : {
                        useCase: 'UC28'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC29'
                    },
                    2 : {
                        useCase: 'UC9'
                    }
                }
            },
            2 : {
                1 : {
                    1 : {
                        useCase: 'UC30'
                    },
                    2 : {
                        useCase: 'UC32'
                    }
                },
                2 : {
                    1 : {
                        useCase: 'UC31'
                    },
                    2 : {
                        useCase: 'UC10'
                    }
                }
    
            }
        }    
    }
};

export default DataSearchChart;
