import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { NavHashLink as NavLink } from 'react-router-hash-link';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from "react-bootstrap/Dropdown";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';

import './layout.css';
import '../../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import headerAndFooterJson from '../../Config/headerAndFooter.json'

import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import $ from 'jquery';
// ========================================

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LoginForm from '../../Common/components/login-form';


import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/loginSlice';
import { setLang } from '../../features/langSlice';
import { setUserName } from '../../features/usersName';
import { store } from '../../store';
import adminService from '../../services/admin.service';


import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import eventBus from '../../features/eventBus';
import CheckboxTree from 'react-checkbox-tree';
import { setDisasterTypeCode, setDisasterTypeName } from '../../features/areaNameSlice';
import { setHeaderAreaCode, setHeaderDisasterCode, setHeaderDisasterName, setHeaderLevel, setGlovalLevel, setHeaderAreaName } from '../../features/formStateSlice';

function CircularProgressWithLabel(props) {
  let progressState = store.getState();
  // console.log("PROGRESS STATE",progressState?.lang.lang)

  const userInfo = localStorage.getItem('userInfo');
  const role = JSON.parse(userInfo);
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <Typography variant="caption" component="div" color="text.secondary" className='cp_completed'>
          <strong>{`${(role.profile_percentage== null ? progressState.header.progress : role.profile_percentage).toFixed(1)}%`}</strong> Completed
        </Typography> */}
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
// ========================================
function Header(props) {
  // console.log(props,"props setprops")






  let progressState = store.getState();

  let language = progressState?.lang?.lang;

  const logClass = 'dropdown-menu log_out_specific logout_box open'
  const [dropClass, changeClasses] = useState(false);


  // const progresss = useSelector((state) => state.header.progress);
  const areaCode = useSelector((state) => state.area.areaCode)
  const headerFormStateAreaName = useSelector((state) => state.formSlice.areaName)

  const dispatch = useDispatch();
  const location = useLocation();
  const route = location.pathname;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [areaExpand, setAreaExpanded] = useState(["ASITLS"]);
  const [, forceUpdate] = useState();
  const [status, setStatus] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [areaChecked, setAreaChecked] = useState([])
  const [defaultData, setDefaultData] = useState([]);
  const [statusColor, setStatusColor] = useState();
  const [allTreeData, setAllTreeData] = useState([]);

  const [percentage, setPercentage] = useState();
  const [userName, setUserName] = useState();
  const [callData, setParentAreaData] = useState([]);
  const [progressEvent, setProgressEvent] = useState()
  const [flatAreas, setFlatArea] = useState([])
  const [userRole, setRole] = useState();
  const [name, setName] = useState();
  const [FormState, setFormState] = useState({
    id: null,
    pareaCode: "ASITLS",
    pareaID: "",
    plevel: "1",
    pareaName: "Timor-Leste",
    areaName: "Timor-Leste",
    areaId: "",
    oldAreacode: ""
  });
  // console.log(FormState,"FormState")

  const [disaster_name, setDisasterName] = useState();
  const [loading, setLoading] = useState(false)

  //  var dataMan = '';
  //  const userInfo = localStorage.getItem('userInfo');
  //  var role = null;
  //  if(userInfo) {
  //    role = JSON.parse(userInfo);
  //  } 
  const seletedLangValue = useSelector((state) => state?.lang?.lang)
  const formStateDisasterCode = useSelector((state) => state?.formSlice?.disasterCode)
  const formStateDisasterName = useSelector((state) => state?.formSlice?.disasterName)

  // useEffect(()=>{
  //   console.log('dispacth')
  //   dispatch(setHeaderAreaCode(FormState.pareaCode))
  //   dispatch(setHeaderDisasterCode(changeDisaterCode))
  // },[FormState.pareaCode,changeDisaterCode])

  useEffect(() => {


    // getDisasterList()

    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const datamn = adminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {
      // setLoading(true)
      if (res) {

        let d = res.data;
        //  setLoading(false)
        if (d != "" && d != null && d?.length > 0) {

          let flatLevel = [];
          let flatLevel2 = [];
          let flatLevel3 = [];
          let flatLevel4 = [];
          // var filteredData = d?.filter(dx=> console.log("This is dx--",dx))
          var filteredData = d?.filter(dx => dx.level != 4)



          if (route == '/dashboard/data-analysis') {
            filteredData?.forEach((item) => {


              var obj = {
                value: item.area_code,
                label: item?.name_all?.[seletedLangValue] ? item?.name_all?.[seletedLangValue] : item?.name_all?.en,
                parent: item.parent_id,
                level: item.level

              }
              flatLevel.push(obj)


            });

          } else {
            d?.forEach((item) => {


              var obj = {
                value: item.area_code,
                label: item?.name_all?.[seletedLangValue] ? item?.name_all?.[seletedLangValue] : item?.name_all?.en,
                parent: item.parent_id,
                level: item.level

              }
              flatLevel.push(obj)


            });

          }


          d?.forEach((item) => {

            var obj = {
              value: item.area_code,
              label: item?.name_all?.[seletedLangValue] ? item?.name_all?.[seletedLangValue] : item?.name_all?.en,
              parent: item.parent_id,
              level: item.level

            }
            flatLevel2.push(obj)



          });



          setFlatArea(flatLevel)
          const data = setLevelData(flatLevel);

          // const data2 = setLevelData(flatLevel2);
          // console.log("This is areaData data--->", data)

          setParentAreaData(data);
          setAllTreeData(flatLevel2)

          // console.log('flatLevel2',flatLevel2,item?.level > FormState.plevel)
          // console.log('useEffectCalled')
          // getDisasterList()
        }
      }

    }).catch((err) => {

      console.log(err)
    });

    // eventBus.remove('completion');
  }, [props, seletedLangValue]);

  useEffect(() => {
    getDisasterList()
  }, [seletedLangValue, route])

  // useEffect(async()=>{
  //   await adminService.httpGet('/data-retrieval/disaster/get-header-disaster-list').then((res) => {
  //     console.log("responese data-", res.data)
  //     let data = res.data;
  //     if(formStateDisasterName && formStateDisasterCode){
  //       let filterData = data.filter(item=> item.disaster_code == formStateDisasterCode)
  //       dispatch(setHeaderDisasterCode(filterData[0]?.disaster_code));
  //       dispatch(setHeaderDisasterName(filterData[0]?.title?.[seletedLangValue]));
  //       // setDisastertype(data)
  //       console.log("filterData", filterData)

  //     }
  //   })
  //   console.log("sdsadsadad", defaultData, formStateDisasterName, formStateDisasterCode)

  // },[defaultData, seletedLangValue])

  const handleChange = (event) => {
    setFormState({ ...FormState, [event.target.name]: event.target.value });
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };







  const options = [

    { value: 'en', text: 'EN' },
    { value: 'tet', text: 'TET' },
    { value: 'pt', text: 'PT' }
  ];

  // const [selectedLang, setSelectedLang] = useState(options[0].value);
  const [selectedLang, setSelectedLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang') || options[0].value;
    setFormState({ ...FormState, pareaName: headerAndFooterJson.timor[storedLang] });
    dispatch(setHeaderAreaName(headerAndFooterJson.timor[storedLang]))
    // dispatch(setHeaderAreaCode(FormState.pareaCode))
    // dispatch(setHeaderDisasterCode(changeDisaterCode))
    return storedLang;
  });


  const handleChangeNev = event => {
    const selectedValue = event.target.value;
    setSelectedLang(selectedValue);
    localStorage.setItem('selectedLang', selectedValue);
    setFormState({ ...FormState, pareaName: headerAndFooterJson.timor[selectedValue] });
    dispatch(setHeaderAreaName(headerAndFooterJson.timor[selectedValue]))
    // dispatch(setHeaderAreaCode(FormState.pareaCode))
    // dispatch(setHeaderDisasterCode(changeDisaterCode))
    // console.log('change');
  };

  useEffect(() => {
    console.log("hiiiiiiiiiiiihh", selectedLang)
    dispatch(setLang(selectedLang));
  }, [selectedLang])
  // const handleChangeNev = event => {
  //   console.log(event.target.value,"lang value change");
  //   setSelectedLang(event.target.value);
  //   dispatch(setLang(event.target.value));
  //   // let setVale= localStorage.setItem('perc', e)
  // };





  useEffect(async () => {
    if (FormState.pareaCode != areaCode) {
      let area = flatAreas.filter(a => a.value == areaCode);
      // console.log('arrea',area)
      if (area?.[0]?.label != undefined) {
        setFormState({ ...FormState, pareaCode: areaCode, pareaName: area?.[0]?.label });
        dispatch(setHeaderAreaName(area?.[0]?.label))
      }
      dispatch(setHeaderAreaCode(areaCode))
      dispatch(setHeaderDisasterCode(formStateDisasterCode))
      // console.log('change',area?.[0]?.label);
      setAreaChecked([areaCode]);
      let ae = new Set(['ASITLS', ...areaExpand, areaCode]);
      setAreaExpanded([...ae]);
    }
  }, [areaCode])


  const getDisasterList = () => {
    if (route == '/dashboard/data-analysis' || route == '/dashboard/profile') {
      setLoading(true)
      let list = adminService.httpGet('/data-retrieval/disaster/get-header-disaster-list').then((res) => {
        console.log("hiiiiiiiiiiiihh 1", formStateDisasterName)
        res.data.forEach(e => {
          // e.description = e.description.en
          e.description = e.description?.hasOwnProperty(seletedLangValue) ? e.description?.[seletedLangValue] : e.description?.en
          // e.title = e.title.en
          e.title = e.title?.hasOwnProperty(seletedLangValue) ? e.title?.[seletedLangValue] : e.title.en
        })
        let filtered = res?.data.filter(ele => ele.isData > 0 && ele.isIus == 1);
        setDefaultData(res.data);
        res.data.push({
          description: 'None',
          disaster_code: 'none',
          isData: 1,
          isIus: 1,
          title: 'Baseline Data',
          _id: 'None'
        })
        setLoading(false)

        res.data.sort((a, b) => (a.title > b.title) ? 1 : (b.title > a.title) ? -1 : 0)
        res.data.forEach(function (item, i) {
          if (item.title === "Baseline Data") {
            res.data.splice(i, 1);
            res.data.push(item);
          }
        });

        setDisastertype(res.data)
        if (formStateDisasterName && formStateDisasterCode) {
          let filterData = res.data.filter(item => item.disaster_code === formStateDisasterCode)
          // dispatch(setHeaderDisasterCode(filterData[0]?.disaster_code));
          // dispatch(setDisasterTypeCode(filterData[0]?.disaster_code));
          dispatch(setDisasterTypeName(filterData[0]?.title));
          dispatch(setHeaderDisasterName(filterData[0]?.title));
          // setDisastertype(data)
          let sortData = filtered.sort((a, b) => a.title.localeCompare(b.title))
          eventBus.dispatch("selectedDisaster", { 'message': FormState?.pareaCode, 'name': FormState?.pareaName, 'title': filterData[0]?.title, 'code': formStateDisasterCode });
          console.log("filterData 1", filterData[0])

        } else {
          // setDisasterName(changeName?.disaterName)
          console.log("filterData 2", filtered[0])
          setDisasterName(filtered[0]?.title)
          dispatch(setDisasterTypeName(filtered[0]?.title));
          dispatch(setHeaderDisasterName(filtered[0]?.title));
          dispatch(setDisasterTypeCode(filtered[0]?.disaster_code));
          dispatch(setHeaderDisasterCode(filtered[0]?.disaster_code));

          let sortData = filtered.sort((a, b) => a.title.localeCompare(b.title))
          eventBus.dispatch("selectedDisaster", { 'message': FormState?.pareaCode, 'name': FormState?.pareaName, 'title': filtered[0]?.title, 'code': sortData[0]?.disaster_code });
        }


        // dispatch(setHeaderDisasterName(res.data))
        // dispatch(setHeaderDisasterCode(filtered[0]?.disaster_code))

      })
    }
  }


  const getDisaster = (e) => {

    setDisasterName(e.title)
    dispatch(setDisasterTypeName(e.title));
    dispatch(setDisasterTypeCode(e?.disaster_code));
    dispatch(setHeaderDisasterCode(e?.disaster_code))
    dispatch(setHeaderDisasterName(e.title))
    eventBus.dispatch("selectedDisaster", { 'message': FormState?.pareaCode, 'name': FormState?.pareaName, 'title': e?.title, 'code': e?.disaster_code });
  }



  // if(e.treeDepth==3){
  //   e = e.parent
  // }
  // console.log("clicka", e)
  // var val = e?.pa;
  // var val = e?.value;




  const setLevelData = (flat) => {



    var roots = [];

    var all = {};

    flat.forEach(function (item) {

      all[item.value] = item;
    });


    Object.keys(all).forEach(function (value) {
      var item = all[value];
      if (item.parent === null || item.parent === "") {

        roots.push(item);
      } else if (item.parent in all) {
        var p = all[item.parent];
        if (!("children" in p)) {

          p.children = [];

        }
        p.children.push(item);
      }
    });


    return roots;
  };

  const getAreaList = async (e) => {

    // debugger;
    // if(e.treeDepth==3){
    //   e = e.parent
    // }
    // console.log("clicka", e)
    // var val = e?.pa;
    var val = e?.value;
    // console.log('levelss',e?.treeDepth,e?.treeDepth + 1,e?.label)


    setFormState({ ...FormState, pareaCode: val, pareaName: e?.label });
    dispatch(setHeaderAreaName(e?.label))
    // console.log('change')
    await dispatch(setGlovalLevel(e?.treeDepth + 1))
    dispatch(setHeaderAreaCode(val))
    // dispatch(setHeaderDisasterCode(formStateDisasterCode))
    await dispatch(setHeaderLevel(e?.treeDepth + 1))
    setAreaChecked([val])
    eventBus.dispatch("changeArea", { 'message': e?.value, 'name': e?.label, 'level': e?.treeDepth + 1, 'children': e?.children, 'parent': e?.parent.value, 'mainAreaTree': allTreeData });
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };


  const openDropdownLogout = () => {

    if (dropClass) {
      changeClasses(false)
    }
    else {
      changeClasses(true)
    }
  };

  const navigate = useNavigate();

  const handleLogout = (e) => {
    dispatch(logout())
    handleDrawerClose();
    // console.log('successfully logged out')
    navigate("/")
  }

  const items = ["Eswatini", "Eswatini 2", "Eswatini 3"];
  const [selectedItem, setSelectedItem] = useState("Eswatini");

  const years = ["2020", "2021", "2022", "2023"];
  const [selectedYears, setSelectedYears] = useState("2020");

  const [searchshow, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const searchShow = () => setShow(true);

  // ================================================================================
  const [state, setState] = useState({
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleDrawerClose = () => {
    setState(false);
  };
  // ================================================================================

  // =======================================

  // const [progress, setProgress] = useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // =======================================

  const userInfo = localStorage.getItem('userInfo');
  const role = JSON.parse(userInfo);



  // console.log("role.profile_percentage"+role.profile_percentage)

  // console.log('PROFILE PERCENTAGE NEWWWWW',role.profile_percentage)

  // console.log('PROGRESS COUNTT:--- ',progressState.header.userName)

  useEffect(() => {

    $(document).ready(function () {
      $(document).on('click', '.treeviewList li', function (ele) {
        $('.trackerDrop').removeClass('show')

      });
      // $(document).on('click','button#dropdown-basic', function(ele){
      //   ele.stopPropagation();
      //   $(this).closest('ul').find('.trackerDrop.dropdown-menu').removeClass('show')
      //   $(this).closest('li').find('.trackerDrop.dropdown-menu').addClass('show')
      //   $(this).closest('button.rct-collapse.rct-collapse-btn').find('.treeviewCheckbox').addClass('show')
      // });
    });

    if (role?.profileStatus == null) {
      setStatus('Pending')
    }
    else if (role?.profileStatus == 2) {
      setStatus('Pending')
    }
    else if (role?.profileStatus == 1) {
      setStatus('Approved')
    }
    else if (role?.profileStatus == 3) {
      setStatus('Disapproved')
    }
  }, [status])

  useEffect(() => {

    // if(role?.profile_percentage == null){
    //   setPercentage(0)
    // }

    let perc = localStorage.getItem('perc');

    if (progressEvent != null && progressEvent != undefined && parseFloat(perc) > 0) {

      setPercentage(progressEvent?.toFixed(1))
    }
    else if (perc != undefined && perc != null) {

      setPercentage(parseFloat(perc)?.toFixed(1))
    }
    else {

      setPercentage(parseFloat(role?.profile_percentage)?.toFixed(1))

    }


    // console.log("reload krke progressEvent:---", progressEvent)
    // if(progressEvent){
    //   setPercentage(progressEvent)
    // }
    // else if(!progressEvent || progressEvent==undefined){
    //   let perc = localStorage.getItem('perc');
    //   console.log('this is percen=====================', perc)
    //   if(perc && perc!=null && perc!=''){


    //       let crPerc = perc!=null &&  perc!=''? parseFloat(perc)?.toFixed(1) : 0 ;
    //       setPercentage(crPerc)
    //       console.log('diljit',crPerc, percentage)




    //   }
    //   else{
    //     // console.log('case else',role?.profile_percentage?.toFixed(1))
    //     // setPercentage(role?.profile_percentage?.toFixed(1))
    //   }

    //   // setPercentage(role?.profile_percentage?.toFixed(1))
    // }
    // else if(!localStorage.getItem('perc')){
    //   setPercentage(role?.profile_percentage?.toFixed(1))
    // }
    // else if(role?.profile_percentage == 0){
    //   setPercentage(progressEvent)
    // }
    // else if(role?.profile_percentage == 0 && !progressEvent){
    //   setPercentage(0)
    // }
    // else if(role.profile_percentage == null){
    //   let perc = localStorage.getItem('perc');
    //   if(perc && perc!=null && perc!=''){
    //     setPercentage(perc!=null && perc!=''? parseFloat(perc)?.toFixed(1) : 0)
    //   }
    // }
    // else if(isNaN(progressEvent)){
    //   setPercentage(role?.profile_percentage?.toFixed(1))
    // }
  }, [progressEvent])

  useEffect(() => {
    if (!role?.profileStatus) {
      setStatusColor('pending')
    }
    else if (role?.profileStatus == 1) {
      setStatusColor('approved')
    }
    else if (role?.profileStatus == 2) {
      setStatusColor('pending')
    }
    else if (role?.profileStatus == 3) {
      setStatusColor('disapproved')
    }
  }, [statusColor])



  // useEffect(()=>{
  //   let un = localStorage.getItem('name')
  //   let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  //   // console.log("USERINFO FREEEE : ----", userInfo.name)
  //   // if(userInfo.name){
  //   //   setUserName(userInfo.name)
  //   // }
  //   if(un){
  //     setUserName(un)
  //   }
  //   else if(!un || un == null || !progressState.header.userName){
  //     setUserName(role.name);
  //   }
  // }, [])

  // useEffect(()=>{
  //   let un = localStorage.getItem('name')

  //   if(role?.name || un!==null){
  //     setUserName(un)
  //   } else if(role?.name && !un){
  //     setUserName(role?.name)
  //   } else if(!un){
  //     setUserName(role?.name)
  //   } else if(!role.name && !un){
  //     setUserName('')
  //   } else if(role.name && un){
  //     setUserName(un)
  //   }else if(!role?.name && un == null){
  //     setUserName('')
  //   } else if(role.name == null || un == "null"){
  //     setUserName('')
  //   } else {
  //     setUserName(progressState.header.userName)
  //   }

  // }, [FormState,userName])

  useEffect(() => {
    let un = localStorage.getItem('name')
    if (role?.name == "" && un != 'null') {
      setUserName(un)
    } else if (role?.name == 'null' && !un) {
      setUserName('')
    }
    else if (role?.name == "" && un == 'null' || un == '') {
      setUserName("")
    }
    else if (role?.name == "") {
      setUserName("")
    } else if (role?.name == null && un) {
      setUserName(un)
    } else if (!un) {
      setUserName(role?.name)
    } else if (!role.name && !un) {
      setUserName('')
    } else if (role.name && un) {
      setUserName(un)
    } else {
      setUserName(progressState.header.userName)
    }

  }, [FormState, userName])












  const [area, setArea] = useState('TIMOR LESTE');
  const [disasterType, setDisastertype] = useState([]);


  //  handleClick1(() =>{
  //   $('body').toggleClass('test')
  //  })


  eventBus.on("selectedDisaster", (data) => {
    let disaster_code = data
  })
  const changeName = useSelector((state) => state?.areaName)

  var dataManager = '';
  switch (role?.role) {
    case 1:
      dataManager = '/admin/summary'
      break;
    case 2:
      dataManager = '/admin/data-entry'
      break;
    case 4:
      dataManager = '/admin/data-approve'
      break;
    case 7:
      dataManager = '/admin/manage-form'
      break;
    default:
      dataManager = ''
  }
  console.log(formStateDisasterCode, "disasterType>>>>>")
  useEffect(() => {
    console.log("filterData 3", disasterType)
    if (!formStateDisasterCode) {
      dispatch(setHeaderDisasterName(disasterType?.[0]?.title))
      dispatch(setHeaderDisasterCode(disasterType?.[0]?.disaster_code))
    }

  }, [disasterType])



  return (
    <>
      <div className={loading ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>
      <header className={` ${route != '/dashboard/data-search-old' && route != '/dashboard/data-analysis' && route != '/dashboard/profile' ? 'dashboard_logo main_dashhead' : 'dashboard_logo db_header'}
                           ${route != '/dashboard/data-search-result' && route != '/dashboard/data-search' ? '' : 'datasearch-result'} 
                            ${route == '/web-data-entry' ? 'web_data_header' : ''}
                            ${selectedLang == options[0].value ? 'english' : (selectedLang == options[1].value ? 'tetum' : 'portuegese')}
                            `} >

        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className={route != '/dashboard/data-search-old' && route != '/dashboard/data-analysis' && route != '/dashboard/profile' ? 'col-md-auto mainHeaderLogo' : 'col-md-auto mainHeaderLogo'}>
              {(route != '/dashboard/data-analysis') && route != '/dashboard/data-search-old' && route != '/about' && route != '/dashboard/profile' && route != '/dashboard/data-search-result' && route != '/dashboard/data-search' &&
                <div className="admin_logo main_logo">
                  <Link to="/home" activeclassname="active"><img src="assets/images/logo_wmis.svg" className='custom-timorlogo img-fluid' /></Link>
                </div>
              }
              {(route == '/about') &&
                <div className="admin_logo main_logo">
                  <Link to="/home" activeclassname="active"><img src="assets/images/about-logo.svg" className='custom-timorlogo img-fluid' /></Link>
                </div>
              }
              {(route == '/dashboard/data-analysis') &&
                <div className="admin_logo dataanalysis_logo">
                  <Link to="/home" activeclassname="active" className='innerLogo'><img src="assets/lib/images/header/timor_logo.svg" /></Link>
                  <Link to="" activeclassname="active" className='pointerNone'><img src="assets/lib/images/header/data-analysis-logo.svg" /></Link>
                </div>
              }
              {(route == '/dashboard/data-search-old') &&
                <div className="admin_logo datasearch_logo">
                  <Link to="/home" activeclassname="active" className='innerLogo'><img src="assets/lib/images/header/timor_logo.svg" /></Link>
                  <Link to="/home" activeclassname="active" className='pointerNone'><img src="assets/lib/images/header/datasearch.svg" /></Link>
                </div>
              }
              {(route == '/dashboard/profile') &&
                <div className="admin_logo dataanalysis_logo">
                  <Link to="/home" activeclassname="active" className='innerLogo'><img src="assets/lib/images/header/timor_logo.svg" /></Link>
                  <Link to="" activeclassname="active" className='pointerNone'><img src="assets/lib/images/header/profile-logo.svg" /></Link>
                </div>
              }
              {(route == '/dashboard/data-search-result') &&
                <div className="admin_logo">
                  <Link to="/home" activeclassname="active"><img src="assets/lib/images/header/datasearch-result.svg" /></Link>
                </div>
              }
              {(route == '/dashboard/data-search') &&
                <div className="admin_logo">
                  <Link to="/home" activeclassname="active" className='innerLogo'><img src="assets/lib/images/header/timor_logo.svg" /></Link>
                  <Link to="/home" activeclassname="active" className='pointerNone'><img src="assets/lib/images/header/datasearch-result.svg" /></Link>
                </div>
              }
            </div>
            {(route == '/dashboard/data-analysis' || route == '/dashboard/profile') &&
              <div className='col-md-auto mainHeaderInputs'>
                <ul className='headerDropdown'>
                  <li>
                    <div className='d-inline-block tracker_head dataAnalytics_head'>
                      <div className='header_dropdown hd_tracker'>
                        <span>{headerAndFooterJson?.disaster?.[seletedLangValue]}</span>
                        <ul>
                          <li>
                            <Dropdown>
                              <Dropdown.Toggle variant="white" id="dropdown-basic">
                                {/* {disaster_name} */}
                                {formStateDisasterName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='trackerDrop'>
                                {
                                  disasterType?.map((ele, ind) => {

                                    return <Dropdown.Item className={!(ele.isData && ele.isIus) ? 'disableMenu' : ''} onClick={(e) => { getDisaster(ele) }} key={ind + 1} >{ele.title ? ele.title : ele.title?.[seletedLangValue]}</Dropdown.Item>
                                  })
                                }
                              </Dropdown.Menu>

                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className='area_main_drop'>
                    <div className='d-inline-block tracker_head dataAnalytics_head'>
                      <div className='header_dropdown hd_tracker'>
                        <span>{headerAndFooterJson?.area?.[seletedLangValue]}</span>
                        <ul>
                          <li>
                            {<Dropdown>
                              <Dropdown.Toggle variant="black" id="dropdown-basic">


                                {
                                  // route !== "/dashboard/profile"? (changeName?.areaName == undefined ? FormState.pareaName : changeName?.areaName) : FormState.pareaName 
                                  // route !== "/dashboard/profile"? (changeName?.areaName == undefined ? FormState.pareaName :FormState.pareaName) : FormState.pareaName
                                  headerFormStateAreaName
                                }





                                {/* { FormState.pareaName } */}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="treeviewCheckbox">
                                <CheckboxTree
                                  nodes={callData}
                                  checked={areaChecked}
                                  expanded={areaExpand}
                                  onClick={(e) => getAreaList(e)}
                                  onExpand={(expanded) => setAreaExpanded(expanded)}
                                />
                              </Dropdown.Menu>
                            </Dropdown>}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>


              </div>
            }
            <div className={route != '/dashboard/profile' && route != '/dashboard/data-search-old' && route != '/dashboard/data-analysis' ? 'col-md-auto mainHeaderMenu' : 'col-md-auto mainHeaderMenu'}>
              <div className={route != '/dashboard/profile' ? 'headerright web_headerright' : 'headerright'}>


                <select value={selectedLang} onChange={handleChangeNev} className={(["/home", "/about", "/information-kiosk", "/contact", "/web-data-entry"].includes(route)) ? 'selectLanguage home_lang' : 'selectLanguage'}>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
                <div className={route != '/dashboard/profile' && route != '/dashboard/data-analysis' && route != '/dashboard/data-search' && route != '/dashboard/data-search-result' ? 'd-flex align-items-center w-100 justify-content-end pt-2' : 'd-flex align-items-end w-100 justify-content-end'}>
                  {route != '/dashboard/profile' && route != '/dashboard/data-analysis' && route != '/dashboard/data-search' && route != '/dashboard/data-search-result' &&
                    <>
                      {/* <select className='selectLanguage'>
                      <option>EN</option>         
                      <option>PT</option>             thin_line ps-0
                      <option>TET</option>             
                    </select> */}
                      <div className='humburger_menu navbar-light'>
                        <button type="button" className="btn navbar-toggler" data-toggle="dropdown">
                          {/* <img className="img-fluid" src="assets/images/humburger.svg"  alt="Menu" /> */}
                          <span className="navbar-toggler-icon"></span>
                        </button>



                        <Navbar expand="lg">

                          <Navbar.Toggle aria-controls="basic-navbar-nav" />

                          <Navbar.Collapse id="basic-navbar-nav" className='dropdown-menu'>
                            <button type="button" className="hmClose btn btn-secondary">
                              <img src="assets/lib/images/close.svg" />
                            </button>
                            <Nav className="me-auto d-flex flex-wrap">
                              <li><NavLink to='/home#' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span><img src='assets/lib/images/home/home_icon.svg' /> {headerAndFooterJson?.home?.[seletedLangValue]}</span></NavLink></li>
                              <li><NavLink to='/about' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.about?.[seletedLangValue]}</span></NavLink></li>
                              <li><NavLink to='/information-kiosk#' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.['information-kiosk']?.[seletedLangValue]}</span></NavLink></li>
                              <li><NavLink to='/dashboard/data-analysis' className='nav-link hb_list' ><span>{headerAndFooterJson?.['data-analysis']?.[seletedLangValue]}</span></NavLink></li>
                              <li><NavLink to='/dashboard/data-search#' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.['data-search']?.[seletedLangValue]}</span></NavLink></li>
                              <li><NavLink to='/dashboard/profile' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.['disaster-profile']?.[seletedLangValue]}</span></NavLink></li>
                              {(role?.role == '1' || role?.role == '2' || role?.role == '6' || role?.role == '7') && <li><NavLink to='/web-data-entry#' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.['web-data-entry']?.[seletedLangValue]}</span></NavLink></li>}
                              <li><NavLink to='/contact#' className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.contact?.[seletedLangValue]}</span></NavLink></li>
                              {(role?.role == '1' || role?.role == '2' || role?.role == '4' || role?.role == '7') && <li><NavLink to={dataManager} className='nav-link hb_list' activeclassname="active" onClick={handleDrawerClose}><span>{headerAndFooterJson?.['data-manager']?.[seletedLangValue]}</span></NavLink></li>}
                            </Nav>
                          </Navbar.Collapse>
                        </Navbar>
                      </div>
                      {/* <div className='hsearch' onClick={searchShow}>
                      <img src='assets/lib/images/home/search.svg' />
                    </div> */}
                    </>
                  }

                  {localStorage.getItem('userInfo') ?
                    <div className="rt_dropdown">
                      <div className={role.role !== 1 ? 'admin_user au_circle' : 'admin_user au_circle a_user'} onClick={handleClick}>
                        {/* <div className='adminUser_circle'  >
                    { role?.role !== 1 ? <CircularProgressWithLabel
                      value={`${progressEvent ? progressEvent.toFixed(1) : percentage}`}
                    /> :
                    <p className=''>Admin</p>
                    }
                  </div> */}
                        <img src='assets/lib/images/home/user_admin.svg' />
                      </div>


                      <Menu className='dropDown_actor'
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handlePopClose}
                        onClick={handlePopClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >

                        {/* <MenuItem>
                    Name
                  </MenuItem> */}
                        {/* <MenuItem>
                    Profile: 50%
                  </MenuItem>
                  <MenuItem>
                    Status: Pending
                  </MenuItem> */}

                        <MenuItem onClick={handleLogout}>
                          Logout
                        </MenuItem>
                      </Menu>

                      {/* <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Language
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#">EN</a>
                  <a className="dropdown-item" href='#'>pt</a>
                  <a className="dropdown-item" href='#'>tet</a>
                 
                </div>
              </div> */}

                    </div>
                    :

                    <div className='admin_user'>
                      <img src='assets/lib/images/home/user_admin.svg' id="homeUserProfile" data-toggle='modal' data-target='#login' />


                    </div>
                  }

                  {(route == '/dashboard/profile' || route == '/dashboard/data-analysis' || route == '/dashboard/data-search-old' || route == '/dashboard/data-search' || route == '/dashboard/data-search-result') &&
                    <>
                      {/* <Navbar expand="lg">
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <NavLink to='/dashboard/data-analysis#' className='nav-link' activeclassname="active">Tracker</NavLink>
                          <NavLink to='/dashboard/profile#' className='nav-link' activeclassname="active">Data Analytics</NavLink>
                                                 </Nav>
                      </Navbar.Collapse>
                    </Navbar> */}
                      {/* =============================================================== Search Popup ======================================================= */}
                      <div>
                        {['right'].map((anchor) => (
                          <React.Fragment key={anchor} className="humburgerMenu">
                            <div className='hsearch' onClick={toggleDrawer(anchor, true)}>
                              <img src='assets/lib/images/home/humburger.svg' />
                            </div>
                            <SwipeableDrawer className="hm_width" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
                              <Button variant="secondary" className='hmClose hmbtnClose' onClick={handleDrawerClose}>
                                <img src='assets/lib/images/close.svg' />
                              </Button>
                              <Navbar expand="lg" className='hmMenu'>
                                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                <Navbar.Collapse id="basic-navbar-nav">
                                  <Nav className="me-auto">
                                    {/* <li><NavLink to='/home#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}> Home</NavLink></li> */}
                                    <li><NavLink to='/about#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['about']?.[seletedLangValue]}
                                      {/* <p className='detail_para'>{headerAndFooterJson?.['about-desc']?.[selectedLang]}</p> */}
                                    </NavLink>

                                    </li>
                                    <li><NavLink to='/information-kiosk#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['information-kiosk']?.[seletedLangValue]} <p className='detail_para'>{headerAndFooterJson?.['information-kiosk-desc']?.[selectedLang]}</p></NavLink>

                                    </li>
                                    <li><NavLink to='/dashboard/data-analysis#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['data-analysis']?.[seletedLangValue]}
                                      <p className='detail_para'>{headerAndFooterJson?.['data-analysis-desc']?.[selectedLang]}</p>
                                    </NavLink></li>
                                    <li><NavLink to='/dashboard/data-search#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['data-search']?.[seletedLangValue]}
                                      <p className='detail_para'> {headerAndFooterJson?.['data-search-desc']?.[selectedLang]}</p>
                                    </NavLink></li>
                                    <li><NavLink to='/dashboard/profile' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['disaster-profile']?.[seletedLangValue]}
                                      <p className='detail_para'>{headerAndFooterJson?.['disaster-profile-desc']?.[selectedLang]}</p>
                                    </NavLink></li>
                                    {(role?.role == '1' || role?.role == '2' || role?.role == '6' || role?.role == '7') && <li><NavLink to='/web-data-entry#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['web-data-entry']?.[seletedLangValue]}</NavLink></li>}
                                    {/* <li><NavLink to='/contact#' className='nav-link' activeclassname="active" onClick={handleDrawerClose}>Contact</NavLink></li> */}
                                    {(role?.role == '1' || role?.role == '2' || role?.role == '4' || role?.role == '7') && <li><NavLink to={dataManager} className='nav-link' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.['data-manager']?.[seletedLangValue]}</NavLink></li>}
                                    <li className='d-flex justify-content-center'>
                                      <div className='both_page_nav'>
                                        <NavLink to='/home#' className='nav-link home_nav' activeclassname="active" onClick={handleDrawerClose}> {headerAndFooterJson?.home?.[seletedLangValue]}</NavLink>
                                        <NavLink to='/contact#' className='nav-link home_nav' activeclassname="active" onClick={handleDrawerClose}>{headerAndFooterJson?.contact?.[seletedLangValue]}</NavLink>
                                        {
                                          (role?.role > 0) ?
                                            <div className='nav-link loginDash' onClick={handleLogout}>{headerAndFooterJson?.logout?.[seletedLangValue]}</div>
                                            :
                                            <div data-toggle='modal' data-target='#login' className='nav-link loginDash' onClick={handleDrawerClose}>{headerAndFooterJson?.login?.[seletedLangValue]}</div>
                                        }
                                      </div>
                                    </li>
                                  </Nav>
                                </Navbar.Collapse>
                              </Navbar>
                            </SwipeableDrawer>
                          </React.Fragment>
                        ))}
                      </div>
                      {/* =============================================================== Search Popup =================================================*/}
                    </>
                  }
                </div>




              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="modal fade login_popup" id="login" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h4 className="modal-title h4" id="exampleModalLabel">
                {/* Login */}
                {headerAndFooterJson?.login?.[seletedLangValue]}
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <img src="assets/lib/images/grey_cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body p-0">
              <LoginForm />
            </div>

          </div>
        </div>
      </div>

      {/* =============================================================== Search Popup ========================================================= */}
      <Modal size="lg" className='search_popup' centered show={searchshow} onHide={handleClose}>
        <Modal.Body>
          <TextField type="search" id="search" placeholder='Search' variant="outlined" />
          <Button variant="secondary" className='searcBtn'>
            <img src='assets/lib/images/home/search.svg' />
          </Button>
        </Modal.Body>
        <Button variant="secondary" className='searcClose' onClick={handleClose}>
          <img src='assets/lib/images/close.svg' />
        </Button>
      </Modal>
      {/* =============================================================== Search Popup ========================================================= */}
      {/* =============================================================== Loader Start ========================================================= */}
      <div className='loaderImg' style={{ display: 'none' }}>
        <img src='assets/lib/images/home/loader.gif' />
      </div>


      {/* ================================================================ Loader End ========================================================== */}


    </>

  );
}


export default Header;
//