import { Component } from 'react'

import '../../admin/pages/manage-form/manage-form.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';


const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

class NewModal extends Component {




    render() {
        return (
            <>

                <div class="modal fade timePeriodPopup dataentryModal dev_modal" id="NewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div className='inner_modal_head'>
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <p class="date mb-0 p-0">Date ( 05 Apr 2023 )</p>
                                </div>

                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row tabs_row justify-content-between">
                                    <div class="col-3 main_tabs">
                                        <div class="nav flex-column nav-pills left_maintab" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <button class="nav-link active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Incident <span>5/5</span></button>
                                            <button class="nav-link" id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">General Information <span>0/5</span></button>
                                            <button class="nav-link" id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Geographic Information <span>5/5</span></button>
                                            <button class="nav-link" id="v-pills-settings-tab" data-toggle="pill" data-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Demography Data<span>5/5</span></button>
                                            <button class="nav-link " id="v-pills-settings-six" data-toggle="pill" data-target="#v-pills-six" type="button" role="tab" aria-controls="v-pills-six" aria-selected="false"><div className='accordin_tab w-100 d-flex justify-content-between'>Basic Needed and Support</div> </button>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="tab-content" id="v-pills-tabContent">
                                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>Incident</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-5'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q2</h4></div><div class="col-md-11 pl-0"><p>
                                                            Title of Observer (Example, District Engineer)

                                                        </p></div></div>
                                                        <div className='row align-items-center mb-5'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>


                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">

                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>General Information</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>

                                                            Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-2'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>



                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>Geographic Information</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>

                                                            Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-2'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>



                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">

                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>Geographic Information</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>

                                                            Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-2'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>



                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-five" role="tabpanel" aria-labelledby="v-pills-settings-five">
                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>Demography Data</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>

                                                            Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-2'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>



                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-six" role="tabpanel" aria-labelledby="v-pills-settings-six">
                                                <div className="inputform_style d-flex align-items-center flex-wrap form-group">
                                                    <div className="col-md-12 p-0 head_box">
                                                        <h4>Basic Needed and Support</h4>
                                                    </div>

                                                    <div className='main_inputs'>
                                                        <div class="row align-items-center mb-2"><div class="col-md-1"><h4>Q1</h4></div><div class="col-md-11 pl-0"><p>

                                                            Hazard/Disaster Start Date (dd-mm-yy)</p></div></div>
                                                        <div className='row align-items-center mb-2'>
                                                            <div className="inner_form_area w-100 d-flex align-items-center">
                                                                <div class="col-md-1 pt-3"><h4>Ans</h4></div>

                                                                <div className='col-md-11 d-flex align-items-center p-0'>
                                                                    <div className="col-md-6 p-0">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <label>User Name</label>
                                                                            <div className="mat-form-field-wrapper">
                                                                                <TextField id="outlined-basic" placeholder='dd-mm-yy' variant="outlined" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-3 time_period_input'>
                                                                        <Box
                                                                            component="form"
                                                                            sx={{
                                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                                            }}
                                                                            noValidate
                                                                            autoComplete="off"
                                                                        >

                                                                            <label>Time Period Format</label>
                                                                            <TextField
                                                                                id="outlined-select-currency"
                                                                                select
                                                                                label=""
                                                                                defaultValue="EUR"
                                                                            // helperText="Please select your currency"
                                                                            >
                                                                                {currencies.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>

                                                                        </Box>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            Time Period</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <Stack spacing={1}>
                                                                                <DesktopDatePicker
                                                                                    inputFormat="DD/MM/YYYY"
                                                                                    // value={value}
                                                                                    // onChange={handleDate}
                                                                                    renderInput={(params) => <TextField {...params} />}
                                                                                />
                                                                            </Stack>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>



                                                        {/* <div className="col-md-12">
                                                            <div className="form-group mb-0 inputform_style">
                                                                <label>Comments</label>
                                                                <div className="mat-form-field-wrapper">
                                                                    <TextField multiline rows={4} />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-secondary previous_btn" data-dismiss="modal"><img src="assets/lib/images/home/arrow_right.svg" /> Previous </button>
                                <button type="button" class="btn btn-primary">Next <img src="assets/lib/images/home/arrow_right.svg" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        );

    }


}


export default NewModal;
