import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import './data-search-result.css';
import * as jsx from './data-search-result.module';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from '@mui/material/Button';
import DataTable from 'react-data-table-component';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ExploreVisualiseModal from "../data-search/explore-visualise-modal";

import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from '@mui/material/Tooltip';
import DataSearchChart from "./data-search-chart";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const top100Films = [
{ title: 'The Shawshank Redemption', year: 1994 },
{ title: 'The Godfather', year: 1972 },
{ title: 'The Godfather: Part II', year: 1974 },
{ title: 'The Dark Knight', year: 2008 },
{ title: '12 Angry Men', year: 1957 },
{ title: "Schindler's List", year: 1993 },
{ title: 'Pulp Fiction', year: 1994 },  
];

function DataSearchresults(props) {
// ================================================================================= Data Table
    const [drsTotal, setDrstotal] = useState('Total');
    const [drsYear, setYear] = useState('2023');
    const [data, setData] = useState([]);
    const [results, setResults] = useState([]);    
    const location = useLocation();
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [state, setState] = useState({
                                        'subgroups': [],
                                        'timeperiods': [],
                                        'dataValue': [],
                                        'selSG': [],
                                        'selTP': []
                                    });

    useEffect(()=> {
        setResults(location.state?.data);
        
        // console.log("Sudhanshu data:", location.state?.data)
        let d = [];
        location?.state?.data.forEach((element, index) => {
            let valid = false;
            let sg = state.subgroups;
            sg[index] = [];
            let tp = state.timeperiods;
            tp[index] = {};
            let data = state.dataValue;
            data[index] = {};
            let sSG = state.selSG;
            let sTP = state.selTP;
            //for each subgroup
            try{
                element.subgroup.forEach((s, i)=>{
                    if(s.name.en) {
                        
                        sg[index].push(s?.name?.en);
                        //for each timeperiod in a subgroup
                        element.data[i].forEach((t, j)=> {
                            if(t.time_period.start_time_period) {
                                valid = true     
                                if(!(s?.name?.en in tp[index])) {
                                    tp[index][s?.name?.en] = [];
                                    data[index][s?.name?.en] = {};
                                }
                                tp[index][s?.name?.en].push(t.time_period?.start_time_period);
                                data[index][s?.name?.en][t.time_period?.start_time_period] = t.new_value;
                            } else {
                                valid = false;
                            }
                        })
                        
                    } else {
                        valid = false;
                    }
                    
                })
                sSG[index] = sg?.[index]?.[0];
                sTP[index] = tp?.[index]?.[sg?.[index]?.[0]]?.[0];
    
                setState({
                    'subgroups': sg,
                    'timeperiods': tp,
                    'dataValue': data,
                    'selSG': sSG,
                    'selTP': sTP
                });
                if(valid) {
                    d.push({
                        id: index,
                        title: <><div className="dsrTitle"><span>{element.areaDetails?.[0]?.name?.en} -</span> {element.name?.en}</div></>,
                        data: data?.[index],
                        unit: element.unit?.en,
                        table: <><div className="dsr_tableChart"><a href="javascript:void(0);"><img src="assets/lib/images/home/tablechart.svg" /></a></div></>,
                        element: element,
                    })
                }
            } catch(e) {
                console.log(e);
            }
            
        });
        setData(d);
    }, [location.state?.data]);
    useEffect(()=> {
        setColumns([
                {
                    name: <>Area - Indicator</>,
                    selector: row => row.title,
                    sortable: true,
                    maxWidth:'1180px'
                },
                {
                    name: '',
                    width: "150px",
                    cell: row =>        
                            <>
                                <div className="dsr_Dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            {state.selSG[row.id]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {state.subgroups[row.id].map(sg=> {
                                                let tip = '-';
                                                try{
                                                    tip = state.selTP[row.id]+': '+state.dataValue[row.id][sg][state.selTP?.[row.id]]?state.dataValue[row.id][sg][state.selTP?.[row.id]]:'-';
                                                } catch (e) {
                                                    tip = state.selTP[row.id]+': - ';
                                                }
                                                
                                                return (
                                                    <Tooltip title={tip} placement="right">
                                                        <Dropdown.Item href="javascript:;"> {sg} </Dropdown.Item>
                                                    </Tooltip> 
                                                );
                                            })}
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            {state.selTP[row.id]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {state.timeperiods[row.id][state.selSG[row.id]].map(tp=> {
                                                let tip = '-';
                                                try{
                                                    tip = state.selSG[row.id]+': '+state.dataValue[row.id][state.selSG[row.id]][tp];
                                                } catch (e) {
                                                    tip = state.selTP[row.id]+': - ';
                                                }
                                                return (
                                                    <Tooltip title={tip} placement="right">
                                                        <Dropdown.Item href="javascript:;"> {tp} </Dropdown.Item>
                                                    </Tooltip> 
                                                );
                                            })}
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </div>
                            </>,
                    sortable: false,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true
                },
                {
                    name: '',
                    selector: row => <> <div className="dsrUnit"><strong>{row.data?.[state.selSG?.[row.id]]?.[state.selTP?.[row.id]]}</strong> <span>{row.unit}</span></div></>,
                    width: "350px",
                    sortable: false,
                },
                {
                    name: '',
                    selector: row => row.table,
                    width: "75px",
                    sortable: false,
                },
            ]
        );
    }, [data]);

    const selectedRowsChange = (selectedData) => {
        setSelectedRows(selectedData.selectedRows);
    }

    const [type, setType] = useState('table');

    const changeType = (t) => {
        setType(t);
    }
    
    
        // ================================================================================= Data Table
        // ============================================================================ Data Table
        const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
        // ============================================================================ Data Table

        return (
            <>

                <secction className="data_search_tab">
                    <div className="container-fluid p-0 h-100">    
                        <div className="upper_tab_area">
                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                className=""
                            >
                                <Tab eventKey="home" title="Quick Data Search" className="quickDatasearch">
                                    <div className="d-flex align-items-center">
                                        <div className="dataSearchInput">
                                            <ul>
                                                <li>
                                                    <Autocomplete
                                                    multiple
                                                    id="checkboxes-tags-demo"
                                                    options={top100Films}
                                                    disableCloseOnSelect
                                                    onChange={()=> {
                                                        
                                                    }}
                                                    getOptionLabel={(option) => option.title}                      
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.title}
                                                        </li>
                                                    )}
                                                    style={{ }}
                                                    renderInput={(params) => (
                                                        <TextField {...params}  placeholder="Search Disaster Indicator" />
                                                    )}
                                                    />
                                                </li>
                                                <li>
                                                    <Autocomplete
                                                    multiple
                                                    id="checkboxes-tags-demo"
                                                    options={top100Films}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.title}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.title}
                                                        </li>
                                                    )}
                                                    style={{  }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder="Select Geographic Areas" />
                                                    )}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex align-items-center dsr_button">
                                            <Button variant="contained" className=""><img src="assets/lib/images/search.svg" /></Button>
                                            <Button variant="contained" data-toggle="modal" data-target="#dataSearchadvance"><img src="assets/lib/images/home/advance-search.svg" /></Button>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Visualize" className="active show quickVisualize">
                                    <ul className="chartList">
                                        <li className={(type=='table'?'active c-enabled':' c-enabled')} onClick={(e)=>changeType('table')} ><img src="assets/lib/images/charts/icon-1.svg" /></li>
                                        <li className={(type=='line'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('line')} ><img src="assets/lib/images/charts/icon-2.svg" /></li>
                                        <li className={(type=='bar'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('bar')} ><img src="assets/lib/images/charts/icon-3.svg" /></li>
                                        {/* <li className={(type=='column'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('column')} ><img src="assets/lib/images/charts/icon-4.svg" /></li> */}
                                        <li className={(type=='pie'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('pie')} ><img src="assets/lib/images/charts/icon-5.svg" /></li>
                                        <li className={(type=='doughnut'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('doughnut')} ><img src="assets/lib/images/charts/icon-6.svg" /></li>
                                        {/* <li className={(type=='scatter'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('scatter')} ><img src="assets/lib/images/charts/icon-7.svg" /></li> */}
                                        {/* <li className={(type=='funnel'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('funnel')} ><img src="assets/lib/images/charts/icon-8.svg" /></li> */}
                                        {/* <li className={(type=='geo'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('geo')} ><img src="assets/lib/images/charts/icon-9.svg" /></li> */}
                                        {/* <li className={(type==''?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('table')} ><img src="assets/lib/images/charts/icon-10.svg" /></li> */}
                                        <li className={(type=='treemap'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('treemap')} ><img src="assets/lib/images/charts/icon-11.svg" /></li>
                                        <li className={(type=='radar'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('radar')} ><img src="assets/lib/images/charts/icon-12.svg" /></li>
                                        {/* <li className={(type=='venn'?'active ':' ')+(selectedRows.length>0? 'c-enabled': 'c-disabled')} onClick={(e)=>changeType('venn')} ><img src="assets/lib/images/charts/icon-13.svg" /></li> */}
                                    </ul>

                                    <div className={(type=='table'?'visualizeSection':' visualizeSection d-none')}>
                                        <div className="mostRecent">{data?.length} most recent results</div>                                            
                                        <DataTable 
                                            columns={columns} 
                                            data={data} 
                                            selectableRows 
                                            selectableRowsComponent={Checkbox}
                                            selectableRowsComponentProps={{ inkDisabled: true }} 
                                            onSelectedRowsChange={selectedRowsChange}
                                            sortIcon={<ArrowDownwardIcon />}
                                            persistTableHead
                                            className="dsr_table"
                                            noDataComponent="No Data"
                                            paginationRowsPerPageOptions={[10, 25, 50,100]}
                                        />
                                    </div>
                                    <div className={(type != 'table'?'visualizeSection':' visualizeSection d-none')}>
                                        <DataSearchChart
                                            data = {selectedRows}
                                            type = {type}
                                        />  
                                    </div>
                                    {/* <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3">
                                        <Tab eventKey="home" title={<><img src="assets/lib/images/charts/icon-1.svg" /></>}>
                                            <div className="mostRecent">{data?.length} most recent results</div>
                                            
                                            <DataTable 
                                                columns={columns} 
                                                data={data} 
                                                selectableRows 
                                                selectableRowsComponent={Checkbox}
                                                selectableRowsComponentProps={{ inkDisabled: true }} 
                                                onSelectedRowsChange={selectedRowsChange}
                                                sortIcon={<ArrowDownwardIcon />}
                                                persistTableHead
                                                className="dsr_table"
                                            />
                                        </Tab>

                                        <Tab className="chartListSection" eventKey="profile2" title={
                                            <>
                                                <ul className="chartList">
                                                    <li className="active"><img src="assets/lib/images/charts/icon-2.svg" /></li>
                                                    <li><img src="assets/lib/images/charts/icon-3.svg" /></li>
                                                </ul>
                                            </>
                                        }>
                                            <DataSearchChart
                                                data = {selectedRows}
                                                type = {'bar'}
                                            />                                            
                                        </Tab>

                                        
                                    </Tabs> */}
                                </Tab>
                            </Tabs>

                        </div>        
                    </div>
                </secction>

                {/* =========================================================================================== */}
                    <div class="modal fade exploreVisualise" id="dataSearchadvance" tabindex="-1" aria-labelledby="DataLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">                                
                            <div class="modal-body">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt="" /></span>
                                </button>
                                <ExploreVisualiseModal />
                            </div>                                   
                        </div>
                    </div>
                    </div>
                {/* =========================================================================================== */}

            </>
        );






}

export default DataSearchresults;
