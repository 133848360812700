import React, { Component } from 'react'
import '../../dashboard/pages/web-data-entry/web-data-entry.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import $ from 'jquery';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ProgressBar from 'react-bootstrap/ProgressBar';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Validator from '../../features/validator';
import { Select, Button, Checkbox, ListItemText, OutlinedInput, Autocomplete } from '@mui/material';
// import TextField from '@mui/material/TextField';
import { TextField } from "@material-ui/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Radio from '@mui/material/Radio';
import { Box } from '@mui/material';
import { Input } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import AdminService from '../../services/admin.service';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import webdataentryj from "../../Config/web-data-enrty.json";

import { map } from 'jquery';

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { Iron } from '@mui/icons-material';
const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];
// ============================================================== Tabs Start
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


class WebDataEntryModal extends Component {

    
    constructor(props) {
        // let questionData = []
        // props.selectedRow?.questionsList?.forEach(element => {
        //     questionData.push(element)
        // });
        // console.log("questionData",questionData)
        super(props);
        this.ITEM_HEIGHT = 48;
        this.ITEM_PADDING_TOP = 8;
        this.data=null;
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        this.form = React.createRef();
        this.status = false;
        this.count=0;
        this.commentQuestion = ['Q004', 'Q020', 'Q061', 'Q092', 'Q117', 'Q139','Q154', 'Q199', 'Q247', 'Q209', 'Q236', 'Q256', 'Q266', 'Q277', 'Q285', 'Q293', 'Q297', 'Q265'];

        this.countDefault = 0;
        this.nonMandatoryQuestion = [];
        this.sectionpush = []
        this.indexValue = []
        this.gridRowHeading = []
        this.gridRowHeadingMain = []
        this.message = "";
        // this.long = 0;
        // this.lat = 0;

        this.state = {
            value: 0,
            lang: 'en',
            fromDisasterDate: '',
            currentTab: 0,
            checkValid: '',
            secName: '',
            progressCount: 0,
            progessQuestion: [],
            progressValue: 0,
            latitude: null,
            longitude: null,
            validated: true,
            btnNextEnabled: false,
            selectedRow: props.selectedRow,
            formId: "data",
            section_Data: [],
            answers: {},
            allQuestion: [],
            multiSelect: {},
            selectedOptions: {},
            gridQuestion: {},
            checked: {},
            counterValue: {},
            readonlyState : false,
            columnName: null,
            rowName: null,
            numCols: null,
            numRows: null,
            selectedMultiName: {},
            subSectionQuestions: null,
            expanded: [],
        }
    }


    componentDidUpdate = async (prevState) => {
        
        // console.log("111-",this.countDefault,prevState.defaultPage)
        if (this.state.currentTab == 0 && prevState.defaultPage==true && this.countDefault==0) {
            $("#v-pills-home-tab-0").trigger('click');
            this.countDefault=1;
        }
        // if(navigator.geolocation){
        //      navigator.geolocation.getCurrentPosition(async function (position){
                 
        //      })
        //  }


         $('.nav-link').on('click', function() {
            $(this).parent('.nav-pills').find('.nav-link').siblings().removeClass('active');
        });

    }

    componentWillReceiveProps = (newProps) => {
        // console.log("11122")
       
        // console.log("propsdata", newProps.selectedRow, newProps, this.state.selectedRow)
        // console.log("newProps", newProps)
        this.nonMandatoryQuestion = newProps?.nonMandatoryQuestion;
        this.setState({ fromDisasterDate : newProps?.selectedRow?.form_date, selectedRow: newProps.selectedRow, lang: newProps?.lang,answers:newProps.answersData ,selectedOptions:newProps.selectedOptionsData,gridQuestion:newProps.gridQuestion, readonlyState : newProps.readOnly,
            counterValue : newProps.counterValue, progessQuestion : newProps.allquestion, progressValue : newProps?.progressbar, progressCount : newProps?.count, sectorCount : newProps?.sectorCount })
        let sectiondata = []


      
            var sortQuestion = newProps.selectedRow?.questionsList?.sort(function (a, b) { return (a?.createdAt > b?.createdAt) ? 1 : -1 });
            
            let newSectionData = {}

            sortQuestion?.forEach(question=>{
                
                if(newSectionData.hasOwnProperty(question.section_id)){
                    newSectionData[question.section_id].question.push(question)
                    newSectionData[question.section_id].question.sort((a,b)=> a.title.en.localeCompare(b.title.en)  )
                    if(!newSectionData[question.section_id].sub_section.some(x=> x._id === question.sub_sectionDetails[0]?._id ) && question.sub_section_id!=null ){
                        newSectionData[question.section_id].sub_section.push(question.sub_sectionDetails[0])
                        
                        newSectionData[question.section_id].sub_section.sort((a,b)=> a.name.en.localeCompare(b.name.en)  )
                    }
                }else{
                    newSectionData[question.section_id] = {
                        section:question.sectionDetails,
                        question:[question],
                        sub_section:question.sub_section_id!=null?[question.sub_sectionDetails[0]]:[]
                    }
                }
            })
            
            sectiondata = Object.values(newSectionData)?.sort(function (a, b) { return (a?.section?.[0]?.name?.en > b?.section?.[0]?.name?.en) ? 1 : -1 });
            
        let finalData = []
        if (sectiondata.length > 0) {
           
            let obj3 = {
                sectionDetails: sectiondata,
                _id: newProps.selectedRow?._id,
                survey_id: newProps.selectedRow?.survey_id,
                form_id: newProps.selectedRow?.form_id,
                tot_questions: newProps.selmulti_selectectedRow?.tot_questions,
                status: newProps.selectedRow?.status,
                name: newProps.selectedRow?.name,
                manageForm: newProps.selectedRow?.manageForm,
                description: newProps.selectedRow?.description,
                createdAt: newProps.selectedRow?.createdAt,
                area: newProps.selectedRow?.area,
                areaCode: newProps.selectedRow?.areaCode,
                disaster: newProps.selectedRow?.disaster,
            }
            finalData.push(obj3)

        }
        if (sectiondata.length > 0 && newProps.selectedRow?._id != this.state.formId) {
            this.setState({ section_Data: finalData, formId: newProps.selectedRow?._id, secName: finalData?.[0]?.sectionDetails?.[0].section?.[0]?.name?.en })
            
        }

        // console.log("questionData", finalData)
       

    }


    handleChange = (event, newValue) => {
        // console.log("111233")
    }

    getAnswerValue = (n, t, isMand) => {
        // console.log("111555")
        // console.log("getAnswerValue", t, n);
        this.setState({ answers: { ...this.state.answers, [n]: t } })

        if (isMand == true && this.state.answers[n] !== null && this.state.answers != '') {
            this.setState({ btnNextEnabled: true })

        }

    }

    getGridValue = async (quesId, e, row, col, sectorName, mand, remove, grid_id, datatype, quesName) => {
        // console.log("11166")
        let subArray = []
        if(remove!==true){
            let defaultObj = this.state.gridQuestion?.[quesId] == undefined ? {} : this.state.gridQuestion?.[quesId];
            
            
            if (Object.keys(defaultObj).length != 0 && defaultObj != undefined) {
    
                let selectedData = defaultObj.filter(data => data?.row != row || data?.col != col);
                let obj = {
                    "row": row,
                    "col": col,
                    "ans": e,
                    "grid_id" : grid_id
                }
                // console.log("gridQuestion++", selectedData)
                selectedData?.forEach(elem => {
                    subArray.push(elem)
                });
                subArray.push(obj)
    
    
            } else {
                let obj = {
                    "row": row,
                    "col": col,
                    "ans": e,
                    "grid_id" : grid_id
                }
                subArray.push(obj)
    
            }
        }

        // console.log("row--", row,col, quesId, e, sectorName)
        // console.log("gridQuestionstate", this.state.gridQuestion?.[quesId], row, col, this.state.gridQuestion)
        
        
        if(remove == true){
            if(this.state.gridQuestion.hasOwnProperty(quesId)){

                let thisIsObject = Object.keys(this.state.gridQuestion).filter(key =>
                    key !== quesId).reduce((obj, key) => {
                        obj[key] = this.state.gridQuestion[key];
                        return obj;
                    }, {}
                    );
                    await this.setState({ gridQuestion: thisIsObject }) 
                    // console.log("thisIsObject", thisIsObject)
            }
          
               
            // this.updateCompletion(quesId, sectorName, "true", mand)
        }else{
            // this.updateCompletion(quesId, sectorName, "false", mand)
            // console.log("gridQuestionsubArray333333333", subArray)
            await this.setState({ gridQuestion: { ...this.state.gridQuestion, [quesId]: subArray } })
        }

        if(datatype=="numeric"){
            this.totalValue(quesId, remove, subArray);
        }
        

        $('#' + quesId).attr('style', 'display:none; color:#A6170A !important');
        // console.log('gridQuestion', this.state.gridQuestion)
    }

    getInputValue = async (id, e, quesName, optionName, sectorName, mand) => {
        // console.log("11177", e, optionName)
        // console.log("secnamedata", sectorName, e, quesName, optionName, this.state.counterValue)
        if (e === "" && e!==0) {
            // console.log("recorddata------")
            if(this.state.answers.hasOwnProperty(id)){
                // console.log("recorddata------")
                let thisIsObject = Object.keys(this.state.answers).filter(key =>
                    key !== id).reduce((obj, key) => {
                        obj[key] = this.state.answers[key];
                        return obj;
                    }, {}
                    );
                    await this.setState({ answers: thisIsObject })
            }
            
            if(this.commentQuestion?.includes(quesName)){
                this.addNonMandatoryQuestion(quesName,false)
            }
            // return;
            // this.updateCompletion(id, sectorName, "true", mand)
            // console.log("anserdata", thisIsObject)

        } else {

            // console.log("this.state.answers",this.state.answers?.[id], id, e)
            // console.log("recorddata++++")
          await  this.setState({ answers: { ...this.state.answers, [id]: e } })
            
            
        }
        // console.log("nonMandatoryQuestion", this.nonMandatoryQuestion)
        

        $('#' + id).attr('style', 'display:none; color:#A6170A !important');

        if (quesName == 'Q003') {
            if (quesName == 'Q003' && optionName == 'Other') {


                this.addNonMandatoryQuestion("Q003a",true)
                $('.Q003a').removeClass('d-none', true);
               
                
    
            }else{
                this.addNonMandatoryQuestion("Q003a",false)
                $('.Q003a').addClass('d-none', true);
               
                let data8 = $('[name ="Q003a"]')?.map(function(){return $(this).data('qid');}).get(0);
               
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand);
               

                
                

            }
        }

        if (quesName == 'Q021') {
            if (quesName == 'Q021' && optionName == 'Yes') {
                this.addNonMandatoryQuestion("Q022",true)
                this.addNonMandatoryQuestion("Q023",true)
                this.addNonMandatoryQuestion("Q024",true)
                this.addNonMandatoryQuestion("Q025",true)
                this.addNonMandatoryQuestion("Q026",true)
                this.addNonMandatoryQuestion("Q027",true)
                this.addNonMandatoryQuestion("Q028",true)
                this.addNonMandatoryQuestion("Q029",true)
                this.addNonMandatoryQuestion("Q030",true)
                
                $('.Q022').removeClass('d-none', true);
                $('.Q023').removeClass('d-none', true);
                $('.Q024').removeClass('d-none', true);
                $('.Q025').removeClass('d-none', true);
                $('.Q026').removeClass('d-none', true);
                $('.Q027').removeClass('d-none', true);
                $('.Q028').removeClass('d-none', true);
                $('.Q029').removeClass('d-none', true);
                $('.Q030').removeClass('d-none', true);
                
    
            }else{

                $('.Q022').addClass('d-none', true);
                $('.Q023').addClass('d-none', true);
                $('.Q024').addClass('d-none', true);
                $('.Q025').addClass('d-none', true);
                $('.Q026').addClass('d-none', true);
                $('.Q027').addClass('d-none', true);
                $('.Q028').addClass('d-none', true);
                $('.Q029').addClass('d-none', true);
                $('.Q030').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q022",false)
                this.addNonMandatoryQuestion("Q023",false)
                this.addNonMandatoryQuestion("Q024",false)
                this.addNonMandatoryQuestion("Q025",false)
                this.addNonMandatoryQuestion("Q026",false)
                this.addNonMandatoryQuestion("Q027",false)
                this.addNonMandatoryQuestion("Q028",false)
                this.addNonMandatoryQuestion("Q029",false)
                this.addNonMandatoryQuestion("Q030",false)

                let data1 = $('[name ="Q022"]').data('qid');
                // console.log("data1", data1)
               await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "", "", "Q022")
                let data2 = $('[name ="Q023"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "", "", "Q023")
                let data3 = $('[name ="Q024"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "", "", "Q024")
                let data4 = $('[name ="Q025"]').data('qid');
                await this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "", "", "Q025")
                let data5 = $('[name ="Q026"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "", "", "Q026")
                let data6 = $('[name ="Q027"]').data('qid');
                await this.getGridValue(data6?.id, "", 0, 0, data6?.sectorName, data6?.mand, true, "", "", "Q027")
                let data7 = $('[name ="Q028"]').data('qid');
                await this.getGridValue(data7?.id, "", 0, 0, data7?.sectorName, data7?.mand, true, "", "", "Q028")

                // let data8 = $('[name ="Q029"]').data('qid');
                let data8 = $('[name ="Q029"]')?.map(function(){return $(this).data('qid');}).get(0);
                // console.log("data8-------------",data8)
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand);
                let data9 = $('[name ="Q030"]')?.map(function(){return $(this).data('qid');}).get(0);
                
                await this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand);

                
                return

            }
        }

        if (quesName == 'Q031') {
            if (quesName == 'Q031' && optionName == 'Yes') {

                $('.Q032').removeClass('d-none', true);
                $('.Q033').removeClass('d-none', true);
                $('.Q034').removeClass('d-none', true);
                $('.Q035').removeClass('d-none', true);
                $('.Q036').removeClass('d-none', true);
                $('.Q037').removeClass('d-none', true);
                $('.Q038').removeClass('d-none', true);
                $('.Q039').removeClass('d-none', true);
                $('.Q040').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q032",true)
                this.addNonMandatoryQuestion("Q033",true)
                this.addNonMandatoryQuestion("Q034",true)
                this.addNonMandatoryQuestion("Q035",true)
                this.addNonMandatoryQuestion("Q036",true)
                this.addNonMandatoryQuestion("Q037",true)
                this.addNonMandatoryQuestion("Q038",true)
                this.addNonMandatoryQuestion("Q039",true)
                this.addNonMandatoryQuestion("Q040",true)
    
            }else{
                let data1 = $('[name ="Q032"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q033"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q034"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q035"]').data('qid');
                await  this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q036"]').data('qid');
                await  this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                let data6 = $('[name ="Q037"]').data('qid');
                await   this.getGridValue(data6?.id, "", 0, 0, data6?.sectorName, data6?.mand, true, "")
                let data7 = $('[name ="Q038"]').data('qid');
                await  this.getGridValue(data7?.id, "", 0, 0, data7?.sectorName, data7?.mand, true, "")


                let data8 = $('[name ="Q039"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                let data9 = $('[name ="Q040"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand)

                $('.Q032').addClass('d-none', true);
                $('.Q033').addClass('d-none', true);
                $('.Q034').addClass('d-none', true);
                $('.Q035').addClass('d-none', true);
                $('.Q036').addClass('d-none', true);
                $('.Q037').addClass('d-none', true);
                $('.Q038').addClass('d-none', true);
                $('.Q039').addClass('d-none', true);
                $('.Q040').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q032",false)
                this.addNonMandatoryQuestion("Q033",false)
                this.addNonMandatoryQuestion("Q034",false)
                this.addNonMandatoryQuestion("Q035",false)
                this.addNonMandatoryQuestion("Q036",false)
                this.addNonMandatoryQuestion("Q037",false)
                this.addNonMandatoryQuestion("Q038",false)
                this.addNonMandatoryQuestion("Q039",false)
                this.addNonMandatoryQuestion("Q040",false)

            }
        }

        if (quesName == 'Q041') {
            if (quesName == 'Q041' && optionName == 'Yes') {

                $('.Q042').removeClass('d-none', true);
                $('.Q043').removeClass('d-none', true);
                $('.Q044').removeClass('d-none', true);
                $('.Q045').removeClass('d-none', true);
                $('.Q046').removeClass('d-none', true);
                $('.Q047').removeClass('d-none', true);
                $('.Q048').removeClass('d-none', true);
                $('.Q049').removeClass('d-none', true);
                $('.Q050').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q042",true)
                this.addNonMandatoryQuestion("Q043",true)
                this.addNonMandatoryQuestion("Q044",true)
                this.addNonMandatoryQuestion("Q045",true)
                this.addNonMandatoryQuestion("Q046",true)
                this.addNonMandatoryQuestion("Q047",true)
                this.addNonMandatoryQuestion("Q048",true)
                this.addNonMandatoryQuestion("Q049",true)
                this.addNonMandatoryQuestion("Q050",true)
    
            }else{
                let data1 = $('[name ="Q042"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q043"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q044"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q045"]').data('qid');
                await this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q046"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                let data6 = $('[name ="Q047"]').data('qid');
                await this.getGridValue(data6?.id, "", 0, 0, data6?.sectorName, data6?.mand, true, "")
                let data7 = $('[name ="Q048"]').data('qid');
                await this.getGridValue(data7?.id, "", 0, 0, data7?.sectorName, data7?.mand, true, "")

                let data8 = $('[name ="Q049"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                let data9 = $('[name ="Q050"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand)

                $('.Q042').addClass('d-none', true);
                $('.Q043').addClass('d-none', true);
                $('.Q044').addClass('d-none', true);
                $('.Q045').addClass('d-none', true);
                $('.Q046').addClass('d-none', true);
                $('.Q047').addClass('d-none', true);
                $('.Q048').addClass('d-none', true);
                $('.Q049').addClass('d-none', true);
                $('.Q050').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q042",false)
                this.addNonMandatoryQuestion("Q043",false)
                this.addNonMandatoryQuestion("Q044",false)
                this.addNonMandatoryQuestion("Q045",false)
                this.addNonMandatoryQuestion("Q046",false)
                this.addNonMandatoryQuestion("Q047",false)
                this.addNonMandatoryQuestion("Q048",false)
                this.addNonMandatoryQuestion("Q049",false)
                this.addNonMandatoryQuestion("Q050",false)

            }
        }

        if (quesName == 'Q051') {
            if (quesName == 'Q051' && optionName == 'Yes') {

                $('.Q052').removeClass('d-none', true);
                $('.Q053').removeClass('d-none', true);
                $('.Q054').removeClass('d-none', true);
                $('.Q055').removeClass('d-none', true);
                $('.Q056').removeClass('d-none', true);
                $('.Q057').removeClass('d-none', true);
                $('.Q058').removeClass('d-none', true);
                $('.Q059').removeClass('d-none', true);
                $('.Q050').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q052",true)
                this.addNonMandatoryQuestion("Q053",true)
                this.addNonMandatoryQuestion("Q054",true)
                this.addNonMandatoryQuestion("Q055",true)
                this.addNonMandatoryQuestion("Q056",true)
                this.addNonMandatoryQuestion("Q057",true)
                this.addNonMandatoryQuestion("Q058",true)
                this.addNonMandatoryQuestion("Q059",true)
                this.addNonMandatoryQuestion("Q060",true)
    
            }else{
                let data1 = $('[name ="Q052"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q053"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q054"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q055"]').data('qid');
                await  this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q056"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                let data6 = $('[name ="Q057"]').data('qid');
                await  this.getGridValue(data6?.id, "", 0, 0, data6?.sectorName, data6?.mand, true, "")
                let data7 = $('[name ="Q058"]').data('qid');
                await this.getGridValue(data7?.id, "", 0, 0, data7?.sectorName, data7?.mand, true, "")


                let data8 = $('[name ="Q059"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                let data9 = $('[name ="Q060"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand)

                $('.Q052').addClass('d-none', true);
                $('.Q053').addClass('d-none', true);
                $('.Q054').addClass('d-none', true);
                $('.Q055').addClass('d-none', true);
                $('.Q056').addClass('d-none', true);
                $('.Q057').addClass('d-none', true);
                $('.Q058').addClass('d-none', true);
                $('.Q059').addClass('d-none', true);
                $('.Q060').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q052",false)
                this.addNonMandatoryQuestion("Q053",false)
                this.addNonMandatoryQuestion("Q054",false)
                this.addNonMandatoryQuestion("Q055",false)
                this.addNonMandatoryQuestion("Q056",false)
                this.addNonMandatoryQuestion("Q057",false)
                this.addNonMandatoryQuestion("Q058",false)
                this.addNonMandatoryQuestion("Q059",false)
                this.addNonMandatoryQuestion("Q060",false)


            }
        }

        if (quesName == 'Q062') {
            if (quesName == 'Q062' && optionName == 'Yes') {

                $('.Q063').removeClass('d-none', true);
                $('.Q064').removeClass('d-none', true);
                $('.Q065').removeClass('d-none', true);
                $('.Q066').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q063",true)
                this.addNonMandatoryQuestion("Q064",true)
                this.addNonMandatoryQuestion("Q065",true)
                this.addNonMandatoryQuestion("Q066",true)
                
    
            }else{
                let data1 = $('[name ="Q064"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q065"]').data('qid');
                await  this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q066"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q063"]').map(function(){return $(this).data('qid');}).get(0);
                
                // .data('qid');

            //     $("input[id='task']")
            //   .map(function(){return $(this).val();}).get();
                // console.log("data8------",data8)
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q063').addClass('d-none', true);
                $('.Q064').addClass('d-none', true);
                $('.Q065').addClass('d-none', true);
                $('.Q066').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q063",false)
                this.addNonMandatoryQuestion("Q064",false)
                this.addNonMandatoryQuestion("Q065",false)
                this.addNonMandatoryQuestion("Q066",false)
               

            }
        }

        if (quesName == 'Q063') {
            if (quesName == 'Q063' && optionName == 'Damaged') {

                
                $('.Q064').removeClass('d-none', true);
                $('.Q065').removeClass('d-none', true);
                $('.Q066').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q064",true)
                this.addNonMandatoryQuestion("Q065",true)
                this.addNonMandatoryQuestion("Q066",true)
    
            }else{
                let data1 = $('[name ="Q064"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q065"]').data('qid');
                await  this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q066"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q064').addClass('d-none', true);
                $('.Q065').addClass('d-none', true);
                $('.Q066').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q064",false)
                this.addNonMandatoryQuestion("Q065",false)
                this.addNonMandatoryQuestion("Q066",false)
               

            }
        }

        if (quesName == 'Q067') {
            if (quesName == 'Q067' && optionName == 'Yes') {

                $('.Q068').removeClass('d-none', true);
                $('.Q069').removeClass('d-none', true);
                $('.Q070').removeClass('d-none', true);
                $('.Q071').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q068",true)
                this.addNonMandatoryQuestion("Q069",true)
                this.addNonMandatoryQuestion("Q070",true)
                this.addNonMandatoryQuestion("Q071",true)
    
            }else{
                let data1 = $('[name ="Q069"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q070"]').data('qid');
                await  this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q071"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q068"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q068').addClass('d-none', true);
                $('.Q069').addClass('d-none', true);
                $('.Q070').addClass('d-none', true);
                $('.Q071').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q068",false)
                this.addNonMandatoryQuestion("Q069",false)
                this.addNonMandatoryQuestion("Q070",false)
                this.addNonMandatoryQuestion("Q071",false)

            }
        }

        if (quesName == 'Q068') {
            if (quesName == 'Q068' && optionName == 'Damaged') {

                
                $('.Q069').removeClass('d-none', true);
                $('.Q070').removeClass('d-none', true);
                $('.Q071').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q069",true)
                this.addNonMandatoryQuestion("Q070",true)
                this.addNonMandatoryQuestion("Q071",true)
    
            }else{
                let data1 = $('[name ="Q069"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q070"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q071"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q069').addClass('d-none', true);
                $('.Q070').addClass('d-none', true);
                $('.Q071').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q069",false)
                this.addNonMandatoryQuestion("Q070",false)
                this.addNonMandatoryQuestion("Q071",false)
               

            }
        }

        if (quesName == 'Q072') {
            if (quesName == 'Q072' && optionName == 'Yes') {

                $('.Q073').removeClass('d-none', true);
                $('.Q074').removeClass('d-none', true);
                $('.Q075').removeClass('d-none', true);
                $('.Q076').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q073",true)
                this.addNonMandatoryQuestion("Q074",true)
                this.addNonMandatoryQuestion("Q075",true)
                this.addNonMandatoryQuestion("Q076",true)
    
            }else{
                let data1 = $('[name ="Q074"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q075"]').data('qid');
                await  this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q076"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q073"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q073').addClass('d-none', true);
                $('.Q074').addClass('d-none', true);
                $('.Q075').addClass('d-none', true);
                $('.Q076').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q073",false)
                this.addNonMandatoryQuestion("Q074",false)
                this.addNonMandatoryQuestion("Q075",false)
                this.addNonMandatoryQuestion("Q076",false)

            }
        }

        if (quesName == 'Q073') {
            if (quesName == 'Q073' && optionName == 'Damaged') {

                
                $('.Q074').removeClass('d-none', true);
                $('.Q075').removeClass('d-none', true);
                $('.Q076').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q074",true)
                this.addNonMandatoryQuestion("Q075",true)
                this.addNonMandatoryQuestion("Q076",true)
    
            }else{
                let data1 = $('[name ="Q074"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q075"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q076"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q074').addClass('d-none', true);
                $('.Q075').addClass('d-none', true);
                $('.Q076').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q074",false)
                this.addNonMandatoryQuestion("Q075",false)
                this.addNonMandatoryQuestion("Q076",false)
               

            }
        }

        if (quesName == 'Q077') {
            if (quesName == 'Q077' && optionName == 'Yes') {

                $('.Q078').removeClass('d-none', true);
                $('.Q079').removeClass('d-none', true);
                $('.Q080').removeClass('d-none', true);
                $('.Q081').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q078",true)
                this.addNonMandatoryQuestion("Q079",true)
                this.addNonMandatoryQuestion("Q080",true)
                this.addNonMandatoryQuestion("Q081",true)
    
            }else{
                let data1 = $('[name ="Q079"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q080"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q081"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q078"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q078').addClass('d-none', true);
                $('.Q079').addClass('d-none', true);
                $('.Q080').addClass('d-none', true);
                $('.Q081').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q078",false)
                this.addNonMandatoryQuestion("Q079",false)
                this.addNonMandatoryQuestion("Q080",false)
                this.addNonMandatoryQuestion("Q081",false)

            }
        }

        if (quesName == 'Q078') {
            if (quesName == 'Q078' && optionName == 'Damaged') {

                
                $('.Q079').removeClass('d-none', true);
                $('.Q080').removeClass('d-none', true);
                $('.Q081').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q079",true)
                this.addNonMandatoryQuestion("Q080",true)
                this.addNonMandatoryQuestion("Q081",true)
    
            }else{
                let data1 = $('[name ="Q079"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q080"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q081"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q079').addClass('d-none', true);
                $('.Q080').addClass('d-none', true);
                $('.Q081').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q079",false)
                this.addNonMandatoryQuestion("Q080",false)
                this.addNonMandatoryQuestion("Q081",false)
               

            }
        }

        if (quesName == 'Q082') {
            if (quesName == 'Q082' && optionName == 'Yes') {

                $('.Q083').removeClass('d-none', true);
                $('.Q084').removeClass('d-none', true);
                $('.Q085').removeClass('d-none', true);
                $('.Q086').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q083",true)
                this.addNonMandatoryQuestion("Q084",true)
                this.addNonMandatoryQuestion("Q085",true)
                this.addNonMandatoryQuestion("Q086",true)
    
            }else{
                let data1 = $('[name ="Q086"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q084"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q085"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q083"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q083').addClass('d-none', true);
                $('.Q084').addClass('d-none', true);
                $('.Q085').addClass('d-none', true);
                $('.Q086').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q083",false)
                this.addNonMandatoryQuestion("Q084",false)
                this.addNonMandatoryQuestion("Q085",false)
                this.addNonMandatoryQuestion("Q086",false)
               

            }
        }

        if (quesName == 'Q083') {
            if (quesName == 'Q083' && optionName == 'Damaged') {

                
                $('.Q084').removeClass('d-none', true);
                $('.Q085').removeClass('d-none', true);
                $('.Q086').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q084",true)
                this.addNonMandatoryQuestion("Q085",true)
                this.addNonMandatoryQuestion("Q086",true)
    
            }else{
                let data1 = $('[name ="Q084"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q085"]').data('qid');
                await  this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q086"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q084').addClass('d-none', true);
                $('.Q085').addClass('d-none', true);
                $('.Q086').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q084",false)
                this.addNonMandatoryQuestion("Q085",false)
                this.addNonMandatoryQuestion("Q086",false)
               

            }
        }

        if (quesName == 'Q087') {
            if (quesName == 'Q087' && optionName == 'Yes') {

                $('.Q088').removeClass('d-none', true);
                $('.Q089').removeClass('d-none', true);
                $('.Q090').removeClass('d-none', true);
                $('.Q091').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q088",true)
                this.addNonMandatoryQuestion("Q089",true)
                this.addNonMandatoryQuestion("Q090",true)
                this.addNonMandatoryQuestion("Q091",true)
    
    
            }else{
                let data1 = $('[name ="Q089"]').data('qid');
                await  this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q090"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q091"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                
                let data8 = $('[name ="Q088"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                

                
                $('.Q088').addClass('d-none', true);
                $('.Q089').addClass('d-none', true);
                $('.Q090').addClass('d-none', true);
                $('.Q091').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q088",false)
                this.addNonMandatoryQuestion("Q089",false)
                this.addNonMandatoryQuestion("Q090",false)
                this.addNonMandatoryQuestion("Q091",false)
               

            }
        }

        if (quesName == 'Q088') {
            if (quesName == 'Q088' && optionName == 'Damaged') {

                
                $('.Q089').removeClass('d-none', true);
                $('.Q090').removeClass('d-none', true);
                $('.Q091').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q089",true)
                this.addNonMandatoryQuestion("Q090",true)
                this.addNonMandatoryQuestion("Q091",true)
    
            }else{
                let data1 = $('[name ="Q089"]').data('qid');
                await this.getGridValue(data1?.id, "", 0, 0, data1?.sectorName, data1?.mand, true, "")
                let data2 = $('[name ="Q090"]').data('qid');
                await this.getGridValue(data2?.id, "", 0, 0, data2?.sectorName, data2?.mand, true, "")
                let data3 = $('[name ="Q091"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
               
                $('.Q089').addClass('d-none', true);
                $('.Q090').addClass('d-none', true);
                $('.Q091').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q089",false)
                this.addNonMandatoryQuestion("Q090",false)
                this.addNonMandatoryQuestion("Q091",false)
               

            }
        }
        if (quesName == 'Q093') {
            if (quesName == 'Q093' && optionName == 'Yes') {

                
                $('.Q094').removeClass('d-none', true);
                $('.Q095').removeClass('d-none', true);
                $('.Q096').removeClass('d-none', true);
                $('.Q097').removeClass('d-none', true);
                $('.Q098').removeClass('d-none', true);
                $('.Q099').removeClass('d-none', true);
                $('.Q100').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q094",true)
                this.addNonMandatoryQuestion("Q095",true)
                this.addNonMandatoryQuestion("Q096",true)
                this.addNonMandatoryQuestion("Q097",true)
                this.addNonMandatoryQuestion("Q098",true)
                this.addNonMandatoryQuestion("Q099",true)
                this.addNonMandatoryQuestion("Q100",true)
                
                
    
            }else{
                let data1 = $('[name ="Q094"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q095"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q096"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q097"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q098"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q099"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data7 = $('[name ="Q100"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                
               
                $('.Q094').addClass('d-none', true);
                $('.Q095').addClass('d-none', true);
                $('.Q096').addClass('d-none', true);
                $('.Q097').addClass('d-none', true);
                $('.Q098').addClass('d-none', true);
                $('.Q099').addClass('d-none', true);
                $('.Q100').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q094",false)
                this.addNonMandatoryQuestion("Q095",false)
                this.addNonMandatoryQuestion("Q096",false)
                this.addNonMandatoryQuestion("Q097",false)
                this.addNonMandatoryQuestion("Q098",false)
                this.addNonMandatoryQuestion("Q099",false)
                this.addNonMandatoryQuestion("Q100",false)
                
               

            }
        }

        if (quesName == 'Q094') {
            if (quesName == 'Q094' && optionName == 'Yes') {

                
                
                $('.Q095').removeClass('d-none', true);
                $('.Q096').removeClass('d-none', true);
                $('.Q097').removeClass('d-none', true);
                $('.Q098').removeClass('d-none', true);
                $('.Q099').removeClass('d-none', true);
                $('.Q100').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q095",true)
                this.addNonMandatoryQuestion("Q096",true)
                this.addNonMandatoryQuestion("Q097",true)
                this.addNonMandatoryQuestion("Q098",true)
                this.addNonMandatoryQuestion("Q099",true)
                this.addNonMandatoryQuestion("Q100",true)
                
    
            }else{
               
                let data2 = $('[name ="Q095"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q096"]')?.map(function(){return $(this).data('qid');}).get(0);
                await    this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q097"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q098"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q099"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data7 = $('[name ="Q100"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                
               
                
                $('.Q095').addClass('d-none', true);
                $('.Q096').addClass('d-none', true);
                $('.Q097').addClass('d-none', true);
                $('.Q098').addClass('d-none', true);
                $('.Q099').addClass('d-none', true);
                $('.Q100').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q095",false)
                this.addNonMandatoryQuestion("Q096",false)
                this.addNonMandatoryQuestion("Q097",false)
                this.addNonMandatoryQuestion("Q098",false)
                this.addNonMandatoryQuestion("Q099",false)
                this.addNonMandatoryQuestion("Q100",false)
                
               

            }
        }

        if (quesName == 'Q096') {
            if (quesName == 'Q096' && optionName == 'Yes') {

                
                $('.Q097').removeClass('d-none', true);
                $('.Q098').removeClass('d-none', true);
                $('.Q099').removeClass('d-none', true);
                $('.Q100').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q097",true)
                this.addNonMandatoryQuestion("Q098",true)
                this.addNonMandatoryQuestion("Q099",true)
                this.addNonMandatoryQuestion("Q100",true)
    
            }else{
                let data4 = $('[name ="Q097"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q098"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q099"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data7 = $('[name ="Q100"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
               
                $('.Q097').addClass('d-none', true);
                $('.Q098').addClass('d-none', true);
                $('.Q099').addClass('d-none', true);
                $('.Q100').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q097",false)
                this.addNonMandatoryQuestion("Q098",false)
                this.addNonMandatoryQuestion("Q099",false)
                this.addNonMandatoryQuestion("Q100",false)
               

            }
        }

        if (quesName == 'Q097') {
            if (quesName == 'Q097' && optionName == 'Yes') {

                $('.Q098').removeClass('d-none', true);
                $('.Q099').removeClass('d-none', true);
                $('.Q100').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q098",true)
                this.addNonMandatoryQuestion("Q099",true)
                this.addNonMandatoryQuestion("Q100",true)
    
            }else{
                let data5 = $('[name ="Q098"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q099"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data7 = $('[name ="Q100"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                $('.Q098').addClass('d-none', true);
                $('.Q099').addClass('d-none', true);
                $('.Q100').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q098",false)
                this.addNonMandatoryQuestion("Q099",false)
                this.addNonMandatoryQuestion("Q100",false)

            }
        }
        
        if (quesName == 'Q099') {
            if (quesName == 'Q099' && optionName == 'Yes') {

                $('.Q100').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q100",true)
    
            }else{
                let data7 = $('[name ="Q100"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                $('.Q100').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q100",false)

            }
        }
        

        if (quesName == 'Q101') {
            if (quesName == 'Q101' && optionName == 'Yes') {

                $('.Q102').removeClass('d-none', true);
                $('.Q103').removeClass('d-none', true);
                $('.Q104').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q102",true)
                this.addNonMandatoryQuestion("Q103",true)
                this.addNonMandatoryQuestion("Q104",true)
                
    
            }else{
                let data1 = $('[name ="Q103"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q104"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q102"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                $('.Q102').addClass('d-none', true);
                $('.Q103').addClass('d-none', true);
                $('.Q104').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q102",false)
                this.addNonMandatoryQuestion("Q103",false)
                this.addNonMandatoryQuestion("Q104",false)
                
               

            }
        }

        if (quesName == 'Q102') {
            if (quesName == 'Q102' && optionName == 'Yes') {

                
                $('.Q103').removeClass('d-none', true);
                $('.Q104').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q103",true)
                this.addNonMandatoryQuestion("Q104",true)
    
            }else{
                let data1 = $('[name ="Q103"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q104"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                $('.Q103').addClass('d-none', true);
                $('.Q104').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q103",false)
                this.addNonMandatoryQuestion("Q104",false)
               

            }
        }

        if (quesName == 'Q105') {
            if (quesName == 'Q105' && optionName == 'Yes') {

                
                $('.Q106').removeClass('d-none', true);
                $('.Q107').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q106",true)
                this.addNonMandatoryQuestion("Q107",true)
    
            }else{
                let data1 = $('[name ="Q106"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q107"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
               
                $('.Q106').addClass('d-none', true);
                $('.Q107').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q106",false)
                this.addNonMandatoryQuestion("Q107",false)
               

            }
        }

        if (quesName == 'Q108') {
            if (quesName == 'Q108' && optionName == 'Yes') {

                $('.Q109').removeClass('d-none', true);
                $('.Q110').removeClass('d-none', true);
                $('.Q111').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q109",true)
                this.addNonMandatoryQuestion("Q110",true)
                this.addNonMandatoryQuestion("Q111",true)
    
            }else{
                let data1 = $('[name ="Q109"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q110"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q111"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
               
                $('.Q109').addClass('d-none', true);
                $('.Q110').addClass('d-none', true);
                $('.Q111').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q109",false)
                this.addNonMandatoryQuestion("Q110",false)
                this.addNonMandatoryQuestion("Q111",false)
               

            }
        }

        if (quesName == 'Q109') {
            if (quesName == 'Q109' && optionName == 'Yes') {

                
                $('.Q110').removeClass('d-none', true);
                $('.Q111').removeClass('d-none', true);
            
                this.addNonMandatoryQuestion("Q110",true)
                this.addNonMandatoryQuestion("Q111",true)
    
            }else{
               
                let data2 = $('[name ="Q110"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q111"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
               
              
                $('.Q110').addClass('d-none', true);
                $('.Q111').addClass('d-none', true);
              
                this.addNonMandatoryQuestion("Q110",false)
                this.addNonMandatoryQuestion("Q111",false)
               

            }
        }

        if (quesName == 'Q112') {
            if (quesName == 'Q112' && optionName == 'Yes') {

                
                $('.Q113').removeClass('d-none', true);
                $('.Q114').removeClass('d-none', true);
                $('.Q115').removeClass('d-none', true);
                $('.Q116').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q113",true)
                this.addNonMandatoryQuestion("Q114",true)
                this.addNonMandatoryQuestion("Q115",true)
                this.addNonMandatoryQuestion("Q116",true)
                
    
            }else{
                let data1 = $('[name ="Q113"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q114"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q115"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q116"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q113').addClass('d-none', true);
                $('.Q114').addClass('d-none', true);
                $('.Q115').addClass('d-none', true);
                $('.Q116').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q113",false)
                this.addNonMandatoryQuestion("Q114",false)
                this.addNonMandatoryQuestion("Q115",false)
                this.addNonMandatoryQuestion("Q116",false)
               

            }
        }

        if (quesName == 'Q115') {
            if (quesName == 'Q115' && optionName == 'Yes') {

                $('.Q116').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q116",true)
    
            }else{
                let data4 = $('[name ="Q116"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q116').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q116",false)
               

            }
        }

        if (quesName == 'Q118') {
            if (quesName == 'Q118' && optionName == 'Yes') {

                $('.Q119').removeClass('d-none', true);
                $('.Q120').removeClass('d-none', true);
                $('.Q121').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q119",true)
                this.addNonMandatoryQuestion("Q120",true)
                this.addNonMandatoryQuestion("Q121",true)
    
            }else{
                
                let data3 = $('[name ="Q119"]').data('qid');
                await this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q120"]').data('qid');
                await   this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q121"]').data('qid');
                await  this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")

                $('.Q119').addClass('d-none', true);
                $('.Q120').addClass('d-none', true);
                $('.Q121').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q119",false)
                this.addNonMandatoryQuestion("Q120",false)
                this.addNonMandatoryQuestion("Q121",false)
               

            }
        }

        if (quesName == 'Q122') {
            if (quesName == 'Q122' && optionName == 'Yes') {

                $('.Q123').removeClass('d-none', true);
                $('.Q124').removeClass('d-none', true);
                $('.Q125').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q123",true)
                this.addNonMandatoryQuestion("Q124",true)
                this.addNonMandatoryQuestion("Q125",true)
    
            }else{
                
                let data3 = $('[name ="Q123"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q124"]').data('qid');
                await this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q125"]').data('qid');
                await  this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")

                $('.Q123').addClass('d-none', true);
                $('.Q124').addClass('d-none', true);
                $('.Q125').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q123",false)
                this.addNonMandatoryQuestion("Q124",false)
                this.addNonMandatoryQuestion("Q125",false)
               

            }
        }

        if (quesName == 'Q126') {
            if (quesName == 'Q126' && optionName == 'Yes') {

                $('.Q127').removeClass('d-none', true);
                $('.Q128').removeClass('d-none', true);
                $('.Q129').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q127",true)
                this.addNonMandatoryQuestion("Q128",true)
                this.addNonMandatoryQuestion("Q129",true)
    
            }else{
                
                let data3 = $('[name ="Q127"]').data('qid');
                await  this.getGridValue(data3?.id, "", 0, 0, data3?.sectorName, data3?.mand, true, "")
                let data4 = $('[name ="Q128"]').data('qid');
                await  this.getGridValue(data4?.id, "", 0, 0, data4?.sectorName, data4?.mand, true, "")
                let data5 = $('[name ="Q129"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")

                $('.Q127').addClass('d-none', true);
                $('.Q128').addClass('d-none', true);
                $('.Q129').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q127",false)
                this.addNonMandatoryQuestion("Q128",false)
                this.addNonMandatoryQuestion("Q129",false)
               

            }
        }

        if (quesName == 'Q130') {
            if (quesName == 'Q130' && optionName == 'Yes') {

                $('.Q131').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q131",true)
    
            }else{
                
                let data4 = $('[name ="Q131"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q131').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q131",false)
               

            }
        }

        if (quesName == 'Q132') {
            if (quesName == 'Q132' && optionName == 'Yes') {

                $('.Q133').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q133",true)
    
            }else{
                
                let data4 = $('[name ="Q133"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q133').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q133",false)
               

            }
        }

        if (quesName == 'Q134') {
            if (quesName == 'Q134' && optionName == 'Yes') {

                $('.Q135').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q135",true)
    
            }else{
                
                let data4 = $('[name ="Q135"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q135').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q135",false)
               

            }
        }

        if (quesName == 'Q136') {
            if (quesName == 'Q136' && optionName == 'Yes') {

                $('.Q137').removeClass('d-none', true);
                $('.Q138').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q137",true)
                this.addNonMandatoryQuestion("Q138",true)
    
            }else{
                
                let data4 = $('[name ="Q138"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q137"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
               
                $('.Q137').addClass('d-none', true);
                $('.Q138').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q137",false)
                this.addNonMandatoryQuestion("Q138",false)

            }
        }

        if (quesName == 'Q140') {
            if (quesName == 'Q140' && optionName == 'Yes') {

                $('.Q141').removeClass('d-none', true);
                $('.Q142').removeClass('d-none', true);

                $('.Q143').removeClass('d-none', true);
                $('.Q144').removeClass('d-none', true);

                $('.Q145').removeClass('d-none', true);
                $('.Q146').removeClass('d-none', true);

                $('.Q147').removeClass('d-none', true);
                $('.Q148').removeClass('d-none', true);

                $('.Q149').removeClass('d-none', true);
                $('.Q150').removeClass('d-none', true);

                $('.Q151').removeClass('d-none', true);
                $('.Q152').removeClass('d-none', true);
                $('.Q153').removeClass('d-none', true);
                // $('.Q154').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q141",true)
                this.addNonMandatoryQuestion("Q142",true)
                this.addNonMandatoryQuestion("Q143",true)
                this.addNonMandatoryQuestion("Q144",true)
                this.addNonMandatoryQuestion("Q145",true)
                this.addNonMandatoryQuestion("Q146",true)
                this.addNonMandatoryQuestion("Q147",true)
                this.addNonMandatoryQuestion("Q148",true)
                this.addNonMandatoryQuestion("Q149",true)
                this.addNonMandatoryQuestion("Q150",true)
                this.addNonMandatoryQuestion("Q151",true)
                this.addNonMandatoryQuestion("Q152",true)
                this.addNonMandatoryQuestion("Q153",true)
                // this.addNonMandatoryQuestion("Q154",true)
    
            }else{
                let data2 = $('[name ="Q141"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q142"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q143"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q144"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q145"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data7 = $('[name ="Q146"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                let data8 = $('[name ="Q147"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                let data9 = $('[name ="Q148"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand)
                let data10 = $('[name ="Q149"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data10?.id, "", "", "", data10?.sectorName, data10?.mand)

                let data11 = $('[name ="Q150"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data11?.id, "", "", "", data11?.sectorName, data11?.mand)
                let data12 = $('[name ="Q151"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data12?.id, "", "", "", data12?.sectorName, data12?.mand)
                let data13 = $('[name ="Q152"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data13?.id, "", "", "", data13?.sectorName, data13?.mand)
                let data14 = $('[name ="Q153"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data14?.id, "", "", "", data14?.sectorName, data14?.mand)
                // let data15 = $('[name ="Q154"]')?.map(function(){return $(this).data('qid');}).get(0);
                // await   this.getInputValue(data15?.id, "", "", "", data15?.sectorName, data15?.mand)
               
                $('.Q141').addClass('d-none', true);
                $('.Q142').addClass('d-none', true);

                $('.Q143').addClass('d-none', true);
                $('.Q144').addClass('d-none', true);

                $('.Q145').addClass('d-none', true);
                $('.Q146').addClass('d-none', true);

                $('.Q147').addClass('d-none', true);
                $('.Q148').addClass('d-none', true);

                $('.Q149').addClass('d-none', true);
                $('.Q150').addClass('d-none', true);

                $('.Q151').addClass('d-none', true);
                $('.Q152').addClass('d-none', true);
                $('.Q153').addClass('d-none', true);
                // $('.Q154').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q141",false)
                this.addNonMandatoryQuestion("Q142",false)
                this.addNonMandatoryQuestion("Q143",false)
                this.addNonMandatoryQuestion("Q144",false)
                this.addNonMandatoryQuestion("Q145",false)
                this.addNonMandatoryQuestion("Q146",false)
                this.addNonMandatoryQuestion("Q147",false)
                this.addNonMandatoryQuestion("Q148",false)
                this.addNonMandatoryQuestion("Q149",false)
                this.addNonMandatoryQuestion("Q150",false)
                this.addNonMandatoryQuestion("Q151",false)
                this.addNonMandatoryQuestion("Q152",false)
                this.addNonMandatoryQuestion("Q153",false)
                // this.addNonMandatoryQuestion("Q154",false)
               

            }
        }

        if (quesName == 'Q141') {
            if (quesName == 'Q141' && optionName == 'Yes') {

                $('.Q142').removeClass('d-none', true);
                $('.Q143').removeClass('d-none', true);
                $('.Q144').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q142",true)
                this.addNonMandatoryQuestion("Q143",true)
                this.addNonMandatoryQuestion("Q144",true)
    
            }else{
                
                let data1 = $('[name ="Q142"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q143"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q144"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
               
                $('.Q142').addClass('d-none', true);
                $('.Q143').addClass('d-none', true);
                $('.Q144').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q142",false)
                this.addNonMandatoryQuestion("Q143",false)
                this.addNonMandatoryQuestion("Q144",false)
               

            }
        }

        if (quesName == 'Q145') {
            if (quesName == 'Q145' && optionName == 'Yes') {

                $('.Q146').removeClass('d-none', true);
                $('.Q147').removeClass('d-none', true);
                $('.Q148').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q146",true)
                this.addNonMandatoryQuestion("Q147",true)
                this.addNonMandatoryQuestion("Q148",true)
    
            }else{
                
                let data1 = $('[name ="Q146"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q147"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q148"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
               
                $('.Q146').addClass('d-none', true);
                $('.Q147').addClass('d-none', true);
                $('.Q148').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q146",false)
                this.addNonMandatoryQuestion("Q147",false)
                this.addNonMandatoryQuestion("Q148",false)
               

            }
        }

        if (quesName == 'Q149') {
            if (quesName == 'Q149' && optionName == 'Yes') {

                $('.Q150').removeClass('d-none', true);
                $('.Q151').removeClass('d-none', true);
                $('.Q152').removeClass('d-none', true);
                $('.Q153').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q150",true)
                this.addNonMandatoryQuestion("Q151",true)
                this.addNonMandatoryQuestion("Q152",true)
                this.addNonMandatoryQuestion("Q153",true)
                
    
            }else{
                
                let data1 = $('[name ="Q150"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q151"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q152"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q153"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
               
                $('.Q150').addClass('d-none', true);
                $('.Q151').addClass('d-none', true);
                $('.Q152').addClass('d-none', true);
                $('.Q153').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q150",false)
                this.addNonMandatoryQuestion("Q151",false)
                this.addNonMandatoryQuestion("Q152",false)
                this.addNonMandatoryQuestion("Q153",false)

            }
        }

        if (quesName == 'Q155') {
            if (quesName == 'Q155' && optionName == 'Yes') {

                $('.Q156').removeClass('d-none', true);
                $('.Q157').removeClass('d-none', true);
                $('.Q158').removeClass('d-none', true);
                $('.Q159').removeClass('d-none', true);
                $('.Q160').removeClass('d-none', true);
                $('.Q161').removeClass('d-none', true);
                $('.Q162').removeClass('d-none', true);
                $('.Q163').removeClass('d-none', true);
                $('.Q164').removeClass('d-none', true);
                $('.Q165').removeClass('d-none', true);
                $('.Q166').removeClass('d-none', true);
                $('.Q167').removeClass('d-none', true);
                $('.Q168').removeClass('d-none', true);
                $('.Q169').removeClass('d-none', true);
                $('.Q170').removeClass('d-none', true);
                $('.Q171').removeClass('d-none', true);
                $('.Q172').removeClass('d-none', true);
                $('.Q173').removeClass('d-none', true);
                $('.Q174').removeClass('d-none', true);
                $('.Q175').removeClass('d-none', true);
                $('.Q176').removeClass('d-none', true);
                $('.Q177').removeClass('d-none', true);
                $('.Q178').removeClass('d-none', true);
                $('.Q179').removeClass('d-none', true);
                $('.Q180').removeClass('d-none', true);
                $('.Q181').removeClass('d-none', true);
                $('.Q182').removeClass('d-none', true);
                $('.Q183').removeClass('d-none', true);
                $('.Q184').removeClass('d-none', true);
                $('.Q185').removeClass('d-none', true);
                $('.Q186').removeClass('d-none', true);
                $('.Q187').removeClass('d-none', true);
                $('.Q188').removeClass('d-none', true);
                $('.Q189').removeClass('d-none', true);
                $('.Q190').removeClass('d-none', true);
                $('.Q191').removeClass('d-none', true);
                $('.Q192').removeClass('d-none', true);
                $('.Q193').removeClass('d-none', true);
                $('.Q194').removeClass('d-none', true);
                $('.Q195').removeClass('d-none', true);
                $('.Q196').removeClass('d-none', true);
                $('.Q197').removeClass('d-none', true);
                $('.Q198').removeClass('d-none', true);
                // $('.Q199').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q156",true)
                this.addNonMandatoryQuestion("Q157",true)
                this.addNonMandatoryQuestion("Q158",true)
                this.addNonMandatoryQuestion("Q159",true)
                this.addNonMandatoryQuestion("Q160",true)
                this.addNonMandatoryQuestion("Q161",true)
                this.addNonMandatoryQuestion("Q162",true)
                this.addNonMandatoryQuestion("Q163",true)
                this.addNonMandatoryQuestion("Q164",true)
                this.addNonMandatoryQuestion("Q165",true)
                this.addNonMandatoryQuestion("Q166",true)
                this.addNonMandatoryQuestion("Q167",true)
                this.addNonMandatoryQuestion("Q168",true)
                this.addNonMandatoryQuestion("Q169",true)
                this.addNonMandatoryQuestion("Q170",true)
                this.addNonMandatoryQuestion("Q171",true)
                this.addNonMandatoryQuestion("Q172",true)
                this.addNonMandatoryQuestion("Q173",true)
                this.addNonMandatoryQuestion("Q174",true)
                this.addNonMandatoryQuestion("Q175",true)
                this.addNonMandatoryQuestion("Q176",true)
                this.addNonMandatoryQuestion("Q177",true)
                this.addNonMandatoryQuestion("Q178",true)
                this.addNonMandatoryQuestion("Q179",true)
                this.addNonMandatoryQuestion("Q180",true)
                this.addNonMandatoryQuestion("Q181",true)
                this.addNonMandatoryQuestion("Q182",true)
                this.addNonMandatoryQuestion("Q183",true)

                this.addNonMandatoryQuestion("Q184",true)
                this.addNonMandatoryQuestion("Q185",true)
                this.addNonMandatoryQuestion("Q186",true)
                this.addNonMandatoryQuestion("Q187",true)
                this.addNonMandatoryQuestion("Q188",true)
                this.addNonMandatoryQuestion("Q189",true)
                this.addNonMandatoryQuestion("Q190",true)
                this.addNonMandatoryQuestion("Q191",true)
                this.addNonMandatoryQuestion("Q192",true)
                this.addNonMandatoryQuestion("Q193",true)
                this.addNonMandatoryQuestion("Q194",true)
                this.addNonMandatoryQuestion("Q195",true)
                this.addNonMandatoryQuestion("Q196",true)
                this.addNonMandatoryQuestion("Q197",true)
                this.addNonMandatoryQuestion("Q198",true)
                // this.addNonMandatoryQuestion("Q199",true)

            }else{

                let data111 = $('[name ="Q156"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data111?.id, "", "", "", data111?.sectorName, data111?.mand)
                
                let data1 = $('[name ="Q157"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q158"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q159"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q160"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q161"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q162"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                let data61 = $('[name ="Q163"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data61?.id, "", "", "", data61?.sectorName, data61?.mand)
                let data7 = $('[name ="Q164"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand)
                let data8 = $('[name ="Q165"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data8?.id, "", "", "", data8?.sectorName, data8?.mand)
                let data9 = $('[name ="Q166"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data9?.id, "", "", "", data9?.sectorName, data9?.mand)
                let data10 = $('[name ="Q167"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data10?.id, "", "", "", data10?.sectorName, data10?.mand)
                let data11 = $('[name ="Q168"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data11?.id, "", "", "", data11?.sectorName, data11?.mand)
                let data12 = $('[name ="Q169"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data12?.id, "", "", "", data12?.sectorName, data12?.mand)
                let data121 = $('[name ="Q170"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data121?.id, "", "", "", data121?.sectorName, data121?.mand)
                let data13 = $('[name ="Q171"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data13?.id, "", "", "", data13?.sectorName, data13?.mand)
                let data14 = $('[name ="Q172"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data14?.id, "", "", "", data14?.sectorName, data14?.mand)
                let data15 = $('[name ="Q173"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data15?.id, "", "", "", data15?.sectorName, data15?.mand)
                let data16 = $('[name ="Q174"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data16?.id, "", "", "", data16?.sectorName, data16?.mand)
                let data17 = $('[name ="Q175"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data17?.id, "", "", "", data17?.sectorName, data17?.mand)
                let data18 = $('[name ="Q176"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data18?.id, "", "", "", data18?.sectorName, data18?.mand)
                let data181 = $('[name ="Q177"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data181?.id, "", "", "", data181?.sectorName, data181?.mand)
                let data19 = $('[name ="Q178"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data19?.id, "", "", "", data19?.sectorName, data19?.mand)
                let data20 = $('[name ="Q179"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data20?.id, "", "", "", data20?.sectorName, data20?.mand)
                let data21 = $('[name ="Q180"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data21?.id, "", "", "", data21?.sectorName, data21?.mand)
                let data22 = $('[name ="Q181"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data22?.id, "", "", "", data22?.sectorName, data22?.mand)
                let data23 = $('[name ="Q182"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data23?.id, "", "", "", data23?.sectorName, data23?.mand)
                let data24 = $('[name ="Q183"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data24?.id, "", "", "", data24?.sectorName, data24?.mand)
                let data241 = $('[name ="Q184"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data241?.id, "", "", "", data241?.sectorName, data241?.mand)
                let data25 = $('[name ="Q185"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data25?.id, "", "", "", data25?.sectorName, data25?.mand)
                let data26 = $('[name ="Q186"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data26?.id, "", "", "", data26?.sectorName, data26?.mand)
                let data27 = $('[name ="Q187"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data27?.id, "", "", "", data27?.sectorName, data27?.mand)
                let data28 = $('[name ="Q188"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data28?.id, "", "", "", data28?.sectorName, data28?.mand)
                let data29 = $('[name ="Q189"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data29?.id, "", "", "", data29?.sectorName, data29?.mand)
                let data30 = $('[name ="Q190"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data30?.id, "", "", "", data30?.sectorName, data30?.mand)
                let data301 = $('[name ="Q191"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data301?.id, "", "", "", data301?.sectorName, data301?.mand)
                let data302 = $('[name ="Q192"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data302?.id, "", "", "", data302?.sectorName, data302?.mand)

                let data31 = $('[name ="Q193"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data31?.id, "", "", "", data31?.sectorName, data31?.mand)
                let data32 = $('[name ="Q194"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data32?.id, "", "", "", data32?.sectorName, data32?.mand)
                let data33 = $('[name ="Q195"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data33?.id, "", "", "", data33?.sectorName, data33?.mand)
                let data34 = $('[name ="Q196"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data34?.id, "", "", "", data34?.sectorName, data34?.mand)
                let data35 = $('[name ="Q197"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data35?.id, "", "", "", data35?.sectorName, data35?.mand)
                let data36 = $('[name ="Q198"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data36?.id, "", "", "", data36?.sectorName, data36?.mand)
               
                $('.Q156').addClass('d-none', true);
                $('.Q157').addClass('d-none', true);
                $('.Q158').addClass('d-none', true);
                $('.Q159').addClass('d-none', true);
                $('.Q160').addClass('d-none', true);
                $('.Q161').addClass('d-none', true);
                $('.Q162').addClass('d-none', true);
                $('.Q163').addClass('d-none', true);
                $('.Q164').addClass('d-none', true);
                $('.Q165').addClass('d-none', true);
                $('.Q166').addClass('d-none', true);
                $('.Q167').addClass('d-none', true);
                $('.Q168').addClass('d-none', true);
                $('.Q169').addClass('d-none', true);
                $('.Q170').addClass('d-none', true);
                $('.Q171').addClass('d-none', true);
                $('.Q172').addClass('d-none', true);
                $('.Q173').addClass('d-none', true);
                $('.Q174').addClass('d-none', true);
                $('.Q175').addClass('d-none', true);
                $('.Q176').addClass('d-none', true);
                $('.Q177').addClass('d-none', true);
                $('.Q178').addClass('d-none', true);
                $('.Q179').addClass('d-none', true);
                $('.Q180').addClass('d-none', true);
                $('.Q181').addClass('d-none', true);
                $('.Q182').addClass('d-none', true);
                $('.Q183').addClass('d-none', true);
                $('.Q184').addClass('d-none', true);
                $('.Q185').addClass('d-none', true);
                $('.Q186').addClass('d-none', true);
                $('.Q187').addClass('d-none', true);
                $('.Q188').addClass('d-none', true);
                $('.Q189').addClass('d-none', true);
                $('.Q190').addClass('d-none', true);
                $('.Q191').addClass('d-none', true);
                $('.Q192').addClass('d-none', true);
                $('.Q193').addClass('d-none', true);
                $('.Q194').addClass('d-none', true);
                $('.Q195').addClass('d-none', true);
                $('.Q196').addClass('d-none', true);
                $('.Q197').addClass('d-none', true);
                $('.Q198').addClass('d-none', true);
                // $('.Q199').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q156",false)
                this.addNonMandatoryQuestion("Q157",false)
                this.addNonMandatoryQuestion("Q158",false)
                this.addNonMandatoryQuestion("Q159",false)
                this.addNonMandatoryQuestion("Q160",false)
                this.addNonMandatoryQuestion("Q161",false)
                this.addNonMandatoryQuestion("Q162",false)
                this.addNonMandatoryQuestion("Q163",false)
                this.addNonMandatoryQuestion("Q164",false)
                this.addNonMandatoryQuestion("Q165",false)
                this.addNonMandatoryQuestion("Q166",false)
                this.addNonMandatoryQuestion("Q167",false)
                this.addNonMandatoryQuestion("Q168",false)
                this.addNonMandatoryQuestion("Q169",false)
                this.addNonMandatoryQuestion("Q170",false)
                this.addNonMandatoryQuestion("Q171",false)
                this.addNonMandatoryQuestion("Q172",false)
                this.addNonMandatoryQuestion("Q173",false)
                this.addNonMandatoryQuestion("Q174",false)
                this.addNonMandatoryQuestion("Q175",false)
                this.addNonMandatoryQuestion("Q176",false)
                this.addNonMandatoryQuestion("Q177",false)
                this.addNonMandatoryQuestion("Q178",false)
                this.addNonMandatoryQuestion("Q179",false)
                this.addNonMandatoryQuestion("Q180",false)
                this.addNonMandatoryQuestion("Q181",false)
                this.addNonMandatoryQuestion("Q182",false)
                this.addNonMandatoryQuestion("Q183",false)

                this.addNonMandatoryQuestion("Q184",false)
                this.addNonMandatoryQuestion("Q185",false)
                this.addNonMandatoryQuestion("Q186",false)
                this.addNonMandatoryQuestion("Q187",false)
                this.addNonMandatoryQuestion("Q188",false)
                this.addNonMandatoryQuestion("Q189",false)
                this.addNonMandatoryQuestion("Q190",false)
                this.addNonMandatoryQuestion("Q191",false)
                this.addNonMandatoryQuestion("Q192",false)
                this.addNonMandatoryQuestion("Q193",false)
                this.addNonMandatoryQuestion("Q194",false)
                this.addNonMandatoryQuestion("Q195",false)
                this.addNonMandatoryQuestion("Q196",false)
                this.addNonMandatoryQuestion("Q197",false)
                this.addNonMandatoryQuestion("Q198",false)
                // this.addNonMandatoryQuestion("Q199",false)
               

            }
        }

        if (quesName == 'Q156') {
            if (quesName == 'Q156' && optionName == 'Yes') {

                // if(($('[name ="Q157"]').val())!=""){
                //     console.log("---")
                // }else{
                //     console.log("-++++--")
                // }
                // console.log("recorddata",$('[name ="Q157"]').val())
                $('.Q157').removeClass('d-none', true);
                $('.Q158').removeClass('d-none', true);
                $('.Q159').removeClass('d-none', true);
                $('.Q160').removeClass('d-none', true);
                $('.Q161').removeClass('d-none', true);
                $('.Q162').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q157",true)
                this.addNonMandatoryQuestion("Q158",true)
                this.addNonMandatoryQuestion("Q159",true)
                this.addNonMandatoryQuestion("Q160",true)
                this.addNonMandatoryQuestion("Q161",true)
                this.addNonMandatoryQuestion("Q162",true)
    
            }else{
                
                $('.Q157').addClass('d-none', true);
                $('.Q158').addClass('d-none', true);
                $('.Q159').addClass('d-none', true);
                $('.Q160').addClass('d-none', true);
                $('.Q161').addClass('d-none', true);
                $('.Q162').addClass('d-none', true);

                let data1 = $('[name ="Q157"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q158"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q159"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q160"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q161"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q162"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
                this.addNonMandatoryQuestion("Q157",false)
                this.addNonMandatoryQuestion("Q158",false)
                this.addNonMandatoryQuestion("Q159",false)
                this.addNonMandatoryQuestion("Q160",false)
                this.addNonMandatoryQuestion("Q161",false)
                this.addNonMandatoryQuestion("Q162",false)
            

            }
        }

        if (quesName == 'Q163') {
            if (quesName == 'Q163' && optionName == 'Yes') {

                $('.Q164').removeClass('d-none', true);
                $('.Q165').removeClass('d-none', true);
                $('.Q166').removeClass('d-none', true);
                $('.Q167').removeClass('d-none', true);
                $('.Q168').removeClass('d-none', true);
                $('.Q169').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q164",true)
                this.addNonMandatoryQuestion("Q165",true)
                this.addNonMandatoryQuestion("Q166",true)
                this.addNonMandatoryQuestion("Q167",true)
                this.addNonMandatoryQuestion("Q168",true)
                this.addNonMandatoryQuestion("Q169",true)
    
            }else{
                
                let data1 = $('[name ="Q164"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q165"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q166"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q167"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q168"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q169"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
               
                $('.Q164').addClass('d-none', true);
                $('.Q165').addClass('d-none', true);
                $('.Q166').addClass('d-none', true);
                $('.Q167').addClass('d-none', true);
                $('.Q168').addClass('d-none', true);
                $('.Q169').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q164",false)
                this.addNonMandatoryQuestion("Q165",false)
                this.addNonMandatoryQuestion("Q166",false)
                this.addNonMandatoryQuestion("Q167",false)
                this.addNonMandatoryQuestion("Q168",false)
                this.addNonMandatoryQuestion("Q169",false)

            }
        }
        if (quesName == 'Q170') {
            if (quesName == 'Q170' && optionName == 'Yes') {

                $('.Q171').removeClass('d-none', true);
                $('.Q172').removeClass('d-none', true);
                $('.Q173').removeClass('d-none', true);
                $('.Q174').removeClass('d-none', true);
                $('.Q175').removeClass('d-none', true);
                $('.Q176').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q171",true)
                this.addNonMandatoryQuestion("Q172",true)
                this.addNonMandatoryQuestion("Q173",true)
                this.addNonMandatoryQuestion("Q174",true)
                this.addNonMandatoryQuestion("Q175",true)
                this.addNonMandatoryQuestion("Q176",true)
    
            }else{
                
                let data1 = $('[name ="Q171"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q172"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q173"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q174"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q175"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q176"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
               
                $('.Q171').addClass('d-none', true);
                $('.Q172').addClass('d-none', true);
                $('.Q173').addClass('d-none', true);
                $('.Q174').addClass('d-none', true);
                $('.Q175').addClass('d-none', true);
                $('.Q176').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q171",false)
                this.addNonMandatoryQuestion("Q172",false)
                this.addNonMandatoryQuestion("Q173",false)
                this.addNonMandatoryQuestion("Q174",false)
                this.addNonMandatoryQuestion("Q175",false)
                this.addNonMandatoryQuestion("Q176",false)

            }
        }

        if (quesName == 'Q177') {
            if (quesName == 'Q177' && optionName == 'Yes') {

                $('.Q178').removeClass('d-none', true);
                $('.Q179').removeClass('d-none', true);
                $('.Q180').removeClass('d-none', true);
                $('.Q181').removeClass('d-none', true);
                $('.Q182').removeClass('d-none', true);
                $('.Q183').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q178",true)
                this.addNonMandatoryQuestion("Q179",true)
                this.addNonMandatoryQuestion("Q180",true)
                this.addNonMandatoryQuestion("Q181",true)
                this.addNonMandatoryQuestion("Q182",true)
                this.addNonMandatoryQuestion("Q183",true)
    
            }else{
                
                let data1 = $('[name ="Q178"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q179"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q180"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q181"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q182"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q183"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
               
                $('.Q178').addClass('d-none', true);
                $('.Q179').addClass('d-none', true);
                $('.Q180').addClass('d-none', true);
                $('.Q181').addClass('d-none', true);
                $('.Q182').addClass('d-none', true);
                $('.Q183').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q178",false)
                this.addNonMandatoryQuestion("Q179",false)
                this.addNonMandatoryQuestion("Q180",false)
                this.addNonMandatoryQuestion("Q181",false)
                this.addNonMandatoryQuestion("Q182",false)
                this.addNonMandatoryQuestion("Q183",false)

            }
        }

        if (quesName == 'Q184') {
            if (quesName == 'Q184' && optionName == 'Yes') {

                $('.Q185').removeClass('d-none', true);
                $('.Q186').removeClass('d-none', true);
                $('.Q187').removeClass('d-none', true);
                $('.Q188').removeClass('d-none', true);
                $('.Q189').removeClass('d-none', true);
                $('.Q190').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q185",true)
                this.addNonMandatoryQuestion("Q186",true)
                this.addNonMandatoryQuestion("Q187",true)
                this.addNonMandatoryQuestion("Q188",true)
                this.addNonMandatoryQuestion("Q189",true)
                this.addNonMandatoryQuestion("Q190",true)
    
            }else{
                
                let data1 = $('[name ="Q185"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q186"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q187"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q188"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q189"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q190"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
               
                $('.Q185').addClass('d-none', true);
                $('.Q186').addClass('d-none', true);
                $('.Q187').addClass('d-none', true);
                $('.Q188').addClass('d-none', true);
                $('.Q189').addClass('d-none', true);
                $('.Q190').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q185",false)
                this.addNonMandatoryQuestion("Q186",false)
                this.addNonMandatoryQuestion("Q187",false)
                this.addNonMandatoryQuestion("Q188",false)
                this.addNonMandatoryQuestion("Q189",false)
                this.addNonMandatoryQuestion("Q190",false)

            }
        }

        if (quesName == 'Q191') {
            if (quesName == 'Q191' && optionName == 'Yes') {

                $('.Q192').removeClass('d-none', true);
                $('.Q193').removeClass('d-none', true);
                $('.Q194').removeClass('d-none', true);
                $('.Q195').removeClass('d-none', true);
                $('.Q196').removeClass('d-none', true);
                $('.Q197').removeClass('d-none', true);
                $('.Q198').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q192",true)
                this.addNonMandatoryQuestion("Q193",true)
                this.addNonMandatoryQuestion("Q194",true)
                this.addNonMandatoryQuestion("Q195",true)
                this.addNonMandatoryQuestion("Q196",true)
                this.addNonMandatoryQuestion("Q197",true)
                this.addNonMandatoryQuestion("Q198",true)
    
            }else{
                
                let data1 = $('[name ="Q193"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand)
                let data2 = $('[name ="Q194"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand)
                let data3 = $('[name ="Q195"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand)
                let data4 = $('[name ="Q196"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand)
                let data5 = $('[name ="Q197"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand)
                let data6 = $('[name ="Q198"]')?.map(function(){return $(this).data('qid');}).get(0);
                await   this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand)
               
                $('.Q192').addClass('d-none', true);
                $('.Q193').addClass('d-none', true);
                $('.Q194').addClass('d-none', true);
                $('.Q195').addClass('d-none', true);
                $('.Q196').addClass('d-none', true);
                $('.Q197').addClass('d-none', true);
                $('.Q198').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q192",false)
                this.addNonMandatoryQuestion("Q193",false)
                this.addNonMandatoryQuestion("Q194",false)
                this.addNonMandatoryQuestion("Q195",false)
                this.addNonMandatoryQuestion("Q196",false)
                this.addNonMandatoryQuestion("Q197",false)
                this.addNonMandatoryQuestion("Q198",false)

            }
        }

        if (quesName == 'Q200') {
            if (quesName == 'Q200' && optionName !== 'All attending') {

                $('.Q201').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q201",true)
    
            }else{
                
                let data1 = $('[name ="Q201"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                $('.Q201').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q201",false)
               

            }
        }

        if (quesName == 'Q202') {
            if (quesName == 'Q202' && optionName !== 'All attending') {

                $('.Q203').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q203",true)
    
            }else{
                
                let data1 = $('[name ="Q203"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                $('.Q203').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q203",false)
               

            }
        }

        if (quesName == 'Q204') {
            if (quesName == 'Q204' && optionName !== 'All attending') {

                $('.Q205').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q205",true)
    
            }else{
                
                let data1 = $('[name ="Q205"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                $('.Q205').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q205",false)
               

            }
        }

        if (quesName == 'Q257') {
            if ((quesName == 'Q257' && optionName == 'Many households cannot access') || (quesName == 'Q257' && optionName == 'Few households')) {

                $('.Q258').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q258",true)
    
            }else{
                
                let data1 = $('[name ="Q258"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                $('.Q258').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q258",false)
               

            }
        }

        if (quesName == 'Q206') {
            if (quesName == 'Q206' && optionName !== 'All have access') {

                $('.Q207').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q207",true)
    
            }else{
                
                let data1 = $('[name ="Q207"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                $('.Q207').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q207",false)
               

            }
        }

        if (quesName == 'Q210') {
            if (quesName == 'Q210' && optionName == 'Unsafe') {

                $('.Q211').removeClass('d-none', true);
                $('.Q212').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q211",true)
                this.addNonMandatoryQuestion("Q212",true)
    
            }else{
                let data5 = $('[name ="Q212"]').data('qid');
                await  this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                let data1 = $('[name ="Q211"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                $('.Q211').addClass('d-none', true);
                $('.Q212').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q211",false)
                this.addNonMandatoryQuestion("Q212",false)
               

            }
        }

        if (quesName == 'Q211') {
            if (quesName == 'Q211' && optionName == 'Yes') {

                $('.Q212').removeClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q212",true)
    
            }else{
                let data5 = $('[name ="Q212"]').data('qid');
                await   this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q212').addClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q212",false)
               

            }
        }

        if (quesName == 'Q213') {
            if (quesName == 'Q213' && optionName == 'Yes') {

                $('.Q214').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q214",true)
                
    
            }else{
                let data5 = $('[name ="Q214"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q214').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q214",false)
                
               

            }
        }

        if (quesName == 'Q215') {
            if (quesName == 'Q215' && optionName == 'Yes') {

                $('.Q216').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q216",true)
                
    
            }else{
                let data1 = $('[name ="Q216"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                $('.Q216').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q216",false)
                
               

            }
        }

        if (quesName == 'Q217') {
            if (quesName == 'Q217' && optionName == 'Yes') {

                $('.Q218').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q218",true)
                
    
            }else{
                let data1 = $('[name ="Q218"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                $('.Q218').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q218",false)
                
               

            }
        }

        if (quesName == 'Q219') {
            if (quesName == 'Q219' && optionName == 'Yes') {

                $('.Q220').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q220",true)
                
    
            }else{
                let data5 = $('[name ="Q220"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q220').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q220",false)
                
               

            }
        }

        if (quesName == 'Q221') {
            if (quesName == 'Q221' && optionName == 'Yes') {

                $('.Q222').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q222",true)
    
            }else{
                let data5 = $('[name ="Q222"]').data('qid');
                await   this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q222').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q222",false)
               

            }
        }
        if (quesName == 'Q223') {
            if (quesName == 'Q223' && optionName == 'Yes') {

                $('.Q224').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q224",true)
    
            }else{
                let data5 = $('[name ="Q224"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q224').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q224",false)
               

            }
        }

        if (quesName == 'Q225') {
            if (quesName == 'Q225' && optionName == 'Yes') {

                $('.Q226').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q226",true)
    
            }else{
                let data5 = $('[name ="Q226"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q226').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q226",false)
               

            }
        }

        if (quesName == 'Q227') {
            if (quesName == 'Q227' && optionName == 'Yes') {

                $('.Q228').removeClass('d-none', true);
                $('.Q229').removeClass('d-none', true);
                $('.Q230').removeClass('d-none', true);
                $('.Q231').removeClass('d-none', true);
                $('.Q232').removeClass('d-none', true);
                $('.Q233').removeClass('d-none', true);
                $('.Q234').removeClass('d-none', true);
                $('.Q235').removeClass('d-none', true);

                this.addNonMandatoryQuestion("Q228",true)
                this.addNonMandatoryQuestion("Q229",true)
                this.addNonMandatoryQuestion("Q230",true)
                this.addNonMandatoryQuestion("Q231",true)
                this.addNonMandatoryQuestion("Q232",true)
                this.addNonMandatoryQuestion("Q233",true)
                this.addNonMandatoryQuestion("Q234",true)
                this.addNonMandatoryQuestion("Q235",true)
                
    
            }else{
                let data1 = $('[name ="Q228"]')?.map(function(){return $(this).data('qid');}).get(0);
              await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               
                let data12 = $('[name ="Q229"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data12?.id, "", "", "", data12?.sectorName, data12?.mand);
                let data13 = $('[name ="Q230"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data13?.id, "", "", "", data13?.sectorName, data13?.mand);
                let data2 = $('[name ="Q231"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand);
                let data3 = $('[name ="Q232"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand);
                let data4 = $('[name ="Q234"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand);
                let data41 = $('[name ="Q233"]')?.map(function(){return $(this).data('qid');}).get(0);
                await this.getInputValue(data41?.id, "", "", "", data41?.sectorName, data41?.mand);
                let data42 = $('[name ="Q235"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data42?.id, "", "", "", data42?.sectorName, data42?.mand);

                $('.Q228').addClass('d-none', true);
                $('.Q229').addClass('d-none', true);
                $('.Q230').addClass('d-none', true);
                $('.Q231').addClass('d-none', true);
                $('.Q232').addClass('d-none', true);
                $('.Q233').addClass('d-none', true);
                $('.Q234').addClass('d-none', true);
                $('.Q235').addClass('d-none', true);

                this.addNonMandatoryQuestion("Q228",false)
                this.addNonMandatoryQuestion("Q229",false)
                this.addNonMandatoryQuestion("Q230",false)
                this.addNonMandatoryQuestion("Q231",false)
                this.addNonMandatoryQuestion("Q232",false)
                this.addNonMandatoryQuestion("Q233",false)
                this.addNonMandatoryQuestion("Q234",false)
                this.addNonMandatoryQuestion("Q235",false)
               

            }
        }


        if (quesName == 'Q237') {
            if (quesName == 'Q237' && optionName == 'Yes') {

                $('.Q238').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q238",true)
    
            }else{
                let data1 = $('[name ="Q238"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q238').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q238",false)
               

            }
        }

        if (quesName == 'Q239') {
            if (quesName == 'Q239' && optionName == 'Yes') {

                $('.Q240').removeClass('d-none', true);
                $('.Q241').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q240",true)
                this.addNonMandatoryQuestion("Q241",true)
    
            }else{
                let data1 = $('[name ="Q240"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                let data5 = $('[name ="Q241"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q241').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q241",false)
                $('.Q240').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q240",false)
               

            }
        }

        if (quesName == 'Q242') {
            if (quesName == 'Q242' && optionName == 'No') {

                $('.Q243').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q243",true)
    
            }else{
                let data1 = $('[name ="Q243"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q243').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q243",false)
               

            }
        }

        if (quesName == 'Q244') {
            if (quesName == 'Q244' && optionName == 'Yes') {

                $('.Q245').removeClass('d-none', true);
                $('.Q246').removeClass('d-none', true);
                // $('.Q247').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q245",true)
                this.addNonMandatoryQuestion("Q246",true)
                // this.addNonMandatoryQuestion("Q247",true)
    
            }else{
                let data1 = $('[name ="Q245"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                // let data2 = $('[name ="Q247"]')?.map(function(){return $(this).data('qid');}).get(0);
                // await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand);
                let data5 = $('[name ="Q246"]').data('qid');
                await this.getGridValue(data5?.id, "", 0, 0, data5?.sectorName, data5?.mand, true, "")
                $('.Q245').addClass('d-none', true);
                $('.Q246').addClass('d-none', true);
                // $('.Q247').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q245",false)
                this.addNonMandatoryQuestion("Q246",false)
                // this.addNonMandatoryQuestion("Q247",false)
               

            }
        }

        if (quesName == 'Q249') {
            if (quesName == 'Q249' && optionName == 'Yes') {

                $('.Q250').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q250",true)
    
            }else{
                let data1 = $('[name ="Q250"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q250').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q250",false)
               

            }
        }

        if (quesName == 'Q252') {
            if (quesName == 'Q252' && optionName == 'Yes') {

                $('.Q253').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q253",true)
    
            }else{
                let data1 = $('[name ="Q253"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q253').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q253",false)
               

            }
        }

        if (quesName == 'Q254') {
            if (quesName == 'Q254' && optionName == 'Yes') {

                $('.Q255').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q255",true)
    
            }else{
                let data1 = $('[name ="Q255"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q255').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q255",false)
               

            }
        }

        if (quesName == 'Q269') {
            if (quesName == 'Q269' && optionName == 'Yes') {

                $('.Q270').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q270",true)
    
            }else{
                let data1 = $('[name ="Q270"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);

                $('.Q270').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q270",false)
               

            }
        }

        if (quesName == 'Q275') {
            if (quesName == 'Q275' && optionName == 'Yes') {

                $('.Q276').removeClass('d-none', true);
                // $('.Q277').removeClass('d-none', true);
                this.addNonMandatoryQuestion("Q276",true)
                // this.addNonMandatoryQuestion("Q277",true)
    
            }else{
                let data1 = $('[name ="Q276"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                // let data2 = $('[name ="Q277"]')?.map(function(){return $(this).data('qid');}).get(0);
                // await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand);

                $('.Q276').addClass('d-none', true);
                // $('.Q277').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q276",false)
                // this.addNonMandatoryQuestion("Q277",false)
               

            }
        }

        if (quesName == 'Q278') {
            if (quesName == 'Q278' && optionName == 'Yes') {

                $('.Q279').removeClass('d-none', true);
                $('.Q280').removeClass('d-none', true);
                $('.Q281').removeClass('d-none', true);
                $('.Q282').removeClass('d-none', true);
                $('.Q283').removeClass('d-none', true);
                $('.Q284').removeClass('d-none', true);
                // $('.Q285').removeClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q279",true)
                this.addNonMandatoryQuestion("Q280",true)
                this.addNonMandatoryQuestion("Q281",true)
                this.addNonMandatoryQuestion("Q282",true)
                this.addNonMandatoryQuestion("Q283",true)
                this.addNonMandatoryQuestion("Q284",true)
                // this.addNonMandatoryQuestion("Q285",true)
    
            }else{
                let data1 = $('[name ="Q279"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                let data2 = $('[name ="Q280"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data2?.id, "", "", "", data2?.sectorName, data2?.mand);
                let data3 = $('[name ="Q281"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data3?.id, "", "", "", data3?.sectorName, data3?.mand);
                let data4 = $('[name ="Q282"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data4?.id, "", "", "", data4?.sectorName, data4?.mand);
                let data5 = $('[name ="Q283"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data5?.id, "", "", "", data5?.sectorName, data5?.mand);
                let data6 = $('[name ="Q284"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data6?.id, "", "", "", data6?.sectorName, data6?.mand);
                // let data7 = $('[name ="Q285"]')?.map(function(){return $(this).data('qid');}).get(0);
                // await  this.getInputValue(data7?.id, "", "", "", data7?.sectorName, data7?.mand);
               

                $('.Q279').addClass('d-none', true);
                $('.Q280').addClass('d-none', true);
                $('.Q281').addClass('d-none', true);
                $('.Q282').addClass('d-none', true);
                $('.Q283').addClass('d-none', true);
                $('.Q284').addClass('d-none', true);
                // $('.Q285').addClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q279",false)
                this.addNonMandatoryQuestion("Q280",false)
                this.addNonMandatoryQuestion("Q281",false)
                this.addNonMandatoryQuestion("Q282",false)
                this.addNonMandatoryQuestion("Q283",false)
                this.addNonMandatoryQuestion("Q284",false)
                // this.addNonMandatoryQuestion("Q285",false)
                
               

            }
        }

        if (quesName == 'Q279') {
            if (quesName == 'Q279' && optionName == 'Yes') {

                $('.Q280').removeClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q280",true)
    
            }else{
                let data1 = $('[name ="Q280"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               

                $('.Q280').addClass('d-none', true);
             
                this.addNonMandatoryQuestion("Q280",false)
               

            }
        }

        if (quesName == 'Q281') {
            if (quesName == 'Q281' && optionName == 'Yes') {

                $('.Q282').removeClass('d-none', true);
                
                this.addNonMandatoryQuestion("Q282",true)
    
            }else{
                let data1 = $('[name ="Q282"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
               

                $('.Q282').addClass('d-none', true);
             
                this.addNonMandatoryQuestion("Q282",false)
               

            }
        }

       

    }


   
    componentDidMount = () => {
        // console.log("11188")
        // console.log("props--++++---", this.props.selectedRow)
        // this.updateCompletion()
       
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.setState({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    // console.log("This is the error occurred while retreiving user's location--", error)
                }
            );
        } else {
            // console.log("This is error occurred")
        }

    }







    handleChkboxChange = (quesId, event, data, _id, sectorName, mand, quesName) => {
        // console.log("1000011")
        let d = {};
        let value = _id;
        let names = data?.name?.en;
        const isChecked = event.target.checked;
        let selectedOptions = this.state.selectedOptions?.[quesId] == undefined ? [] : this.state.selectedOptions?.[quesId];
        let selectedNames = this.state.selectedMultiName?.[quesId] == undefined ? [] : this.state.selectedMultiName?.[quesId];

        // console.log("selectedOptions", selectedOptions)
        if (isChecked && !selectedOptions.includes(value)) {

            selectedOptions.push(value);
            selectedNames.push(names)

        } else if (!isChecked && selectedOptions.includes(value)) {

            selectedOptions = selectedOptions.filter(option => option !== value);
            selectedNames = selectedNames.filter(option => option !== names);
        }


        





        // this.updateCompletion(quesId, sectorName, "false", mand)
        this.setState({ selectedOptions: { ...this.state.selectedOptions, [quesId]: selectedOptions }, selectedMultiName: { ...this.state.selectedMultiName, [quesId]: selectedNames } });

        this.multicheckValidation(quesName, selectedNames);
        // this.setState({ answers: { ...this.state.answers, [id]: e } })
        // this.setState({ selectedNames });

        // console.log("selectedOptions", this.state.selectedOptions)
    };

    multicheckValidation = async (quesName, selectedNames) => {
        // console.log("13747447611")
        if (quesName == 'Priority for education necessity') {

            if (selectedNames.includes('Other')) {
                $('[name="Describe_other_education_necessity"]').prop('disabled', false);
            }else{
                let data1 = $('[name ="Describe_other_education_necessity"]').data('qid');
                let data2 = $('[name ="Number_of_students_require_uniform"]').data('qid');
                

                // console.log("rishavdata",data1, data2)
               await this.getInputValue(data1?.id, "", data1?.quesName, "", data1?.sectorName, data1?.mand)
               await this.getInputValue(data2?.id, "", data2?.quesName, "", data2?.sectorName, data2?.mand)
                $('[name="Describe_other_education_necessity"]').prop('disabled', true);
                $('[name="Number_of_students_require_uniform"]').prop('disabled', true);
            }
        }
        if (quesName == 'Type of health facility available') {

            if (selectedNames.includes('Other')) {
                $('[name="Describe_other_health_facility"]').prop('disabled', false);
            } else {
                $('[name="Describe_other_health_facility"]').prop('disabled', true);
                let data1 = $('[name ="Describe_other_health_facility"]').data('qid');
               this.getInputValue(data1?.id, "", data1?.quesName, "", data1?.sectorName, data1?.mand)
            }
        }

        if (quesName == 'Type of health problems mostly preoccupy') {

            if (selectedNames.includes('Other health problems')) {
                $('[name="Describe_other_health_problem"]').prop('disabled', false);
            }else{
                let data1 = $('[name ="Describe_other_health_problem"]').data('qid');
               this.getInputValue(data1?.id, "", data1?.quesName, "", data1?.sectorName, data1?.mand)
                $('[name="Describe_other_health_problem"]').prop('disabled', true);

            }       $('[name="Number of people aged 6-17 years died"]').prop('disabled', false);
            $('[name="Number of people aged 18-59 years died"]').prop('disabled', false);
            $('[name="Number of people aged 60+ years died"]').prop('disabled', false);
            $('[name="Number of people with physical disability died"]').prop('disabled', false);
            $('[name="Number of people with seeing disability died"]').prop('disabled', false);
            $('[name="Number of people with hearing disability died"]').prop('disabled', false);
            $('[name="Number_of_pregnant_women_died"]').prop('disabled', false);
            $('[name="Number_of_breastfeeding_mothers_died"]').prop('disabled', false);
        }
        if (quesName == 'Kind of non-food support needed by households') {

            if (selectedNames.includes('Other')) {
                $('[name="Other_support"]').prop('disabled', false);
            }else{
                let data1 = $('[name ="Other_support"]').data('qid');
                this.getInputValue(data1?.id, "", data1?.quesName, "", data1?.sectorName, data1?.mand)
                $('[name="Other_support"]').prop('disabled', true);

            }
        }

        if (quesName == 'Q267') {

            if (selectedNames.includes('Other health problems')) {
                
                $('.Q268').removeClass('d-none', true);
        
                this.addNonMandatoryQuestion("Q268",true)
            }else{
                let data1 = $('[name ="Q268"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                $('.Q268').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q268",false)

            }
        }


        if (quesName == 'Q271') {
            const itemsToFind = ['Other health problems', 'Other'];

            if (itemsToFind.some(item => selectedNames.includes(item))) {
                
                $('.Q272').removeClass('d-none', true);
        
                this.addNonMandatoryQuestion("Q272",true)
            }else{
                let data1 = $('[name ="Q272"]')?.map(function(){return $(this).data('qid');}).get(0);
                await  this.getInputValue(data1?.id, "", "", "", data1?.sectorName, data1?.mand);
                $('.Q272').addClass('d-none', true);
                this.addNonMandatoryQuestion("Q272",false)

            }
        }

    }



    getinputType = (type, id, option, max, min, isMand, grid, quesName, sectorName, grid_org,decimalPoint) => {
        // console.log("11--1")
        var sortOption = option?.sort(function (a, b) { return new Date(a?.[0]?.createdAt) - new Date(b?.[0]?.createdAt) });

        if (type == 'text') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            let obj = JSON.stringify(obj1)
          
            // console.log("data-----tttt", this.state.answers?.[id])
            return (
                <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">

                        <textarea readOnly={this.state.readonlyState==true? true : false}  
                        className=" w-100" 
                        data-qid={obj} 
                        id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} 
                        name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}  
                        disabled={false} required={isMand} 
                        
                        value={this.state.answers?.[id] == undefined ? '' : this.state.answers?.[id]} 
                        onChange={(e) => this.getInputValue(id, e.target.value, quesName, "", sectorName, isMand)} label="" variant="outlined"
                        />
                        <div className="" >
                            <p
                                className=" w-100"
                                name={id}
                                id={id}
                                style={{ display: "none", color: '#A6170A !important' }}
                            >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                        </div>
                    </div>
                </div>
            );


        }

        else if (type == 'numeric') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            let obj = JSON.stringify(obj1)
            if (quesName?.includes('Contact')) {
                return (


                    <div>
                        <input id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                        name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                        disabled={false} required={isMand} min='0'
                        readOnly={this.state.readonlyState==true? true : false}  
                            inputProps={{ maxLength: 10, style: { textAlign: 'left' } }}
                            onKeyDown={this.handleKeyDown}
                            onKeyPress={(event) => {
                                const keyCode = event.keyCode || event.which;
                                const keyValue = String.fromCharCode(keyCode);
                                const isValidInput = /^[0-9\b]+$/.test(keyValue);
                                const isDeleteOrBackspace = keyCode === 46 || keyCode === 8;

                                if (!isValidInput && !isDeleteOrBackspace) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                let val = parseInt(e.target.value, 10);
                                if (isNaN(val)) {
                                    this.getInputValue(id, "", quesName, "", sectorName, isMand)
                                } else {
                                    // is A Number
                                    val = val >= 0 ? val : 0;
                                    //   this.setState({ number: val });
                                    this.getInputValue(id, val, quesName, "", sectorName, isMand)
                                }
                            }}
                            value={this.state.answers?.[id] == undefined ? "" : this.state.answers?.[id]} 
                            data-qid={obj} 
                            type="text" label="" variant="outlined" />
                        <div className="" >
                            <p
                                className=" w-100"
                                name={id}
                                id={id}
                                style={{ display: "none", color: '#A6170A !important' }}
                                >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                        </div>
                    </div>

                )
            }
            else {
                

                return (


                    <div>
                        <input id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}  disabled={false} required={isMand} 
                           name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} 
                           onKeyDown={this.handleKeyDown}
                           readOnly={this.state.readonlyState==true? true : false}  
                           onChange={(e) => {
                                // let val = parseFloat(e.target.value, 10);
                                let val = e.target.value;

                                let [integerPart, decimalPart] = val.replace(/[^0-9.]/g, '').split('.');
                                decimalPart = decimalPart ? `.${decimalPart.slice(0, decimalPoint)}` : '';
                                val = parseFloat(`${integerPart}${decimalPart}`);

                                if (isNaN(val)) {
                                    // console.log("number--", val)
                                    this.getInputValue(id, "", quesName, "", sectorName, isMand)
                                } else {
                                    // is A Number
                                    
                                    val = val >= 0 ? val : 0;

                                    //   this.setState({ number: val });
                                    this.getInputValue(id, val, quesName, "", sectorName, isMand)
                                }
                            }}
                            
                            value={this.state.answers?.[id] == undefined ? "" : this.state.answers?.[id]} 
                            data-qid={obj}
                             type="number"  label="" variant="outlined" />
                        <div className="" >
                            <p
                                className=" w-100"
                                name={id}
                                id={id}
                                style={{ display: "none", color: '#A6170A !important' }}
                                >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                        </div>
                    </div>

                )
            }
        }




        else if (type == 'time') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            let obj = JSON.stringify(obj1)
            // console.log("answertime", this.state.value)
            return (

                <div className='timePicker_web'>
                    {/* <input
                        type="time"
                        id="appt"
                        placeholder="HH:MM"
                        required={isMand}
                        value={this.state.answers?.[id]}
                      
                        onChange={(e) => this.getInputValue(id, e.target.value, quesName, "", sectorName)}
                        name="appt" /> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack>
                            <DesktopTimePicker
                                id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false}
                                value={this.state.answers?.[id] == undefined ? this.getInputValue(id, moment().format("h:mm a"), quesName, "", sectorName, isMand) : dayjs(this.state.answers?.[id], "h:mm a")}
                                data-qid={obj} 
                                onChange={(newValue) => {
                                    // setValue(newValue);
                                    // console.log("datatime", dayjs(newValue), dayjs("09:25 PM", "h:mm a"))
                                    // console.log("datatime",newValue.toString(), dayjs(newValue).format("h:mm a"))
                                    this.getInputValue(id, dayjs(newValue).format("h:mm a"), quesName, "", sectorName, isMand)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>

                    <div className="" >
                        <p
                            className=" w-100"
                            name={id}
                            id={id}
                            style={{ display: "none", color: '#A6170A !important' }}
                            >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                    </div>
                </div>

            )

        }


        else if (type == 'date') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            
            let obj = JSON.stringify(obj1)

            const finalDate = new Date(this.state.fromDisasterDate);
            finalDate.setHours(10);
            
            if(quesName == 'Q001'){

                return (

                    <div className=''>
                        <input type="date" 
                        // min={finalDate.toISOString().slice(0, 10)}  
                        max={new Date().toISOString().slice(0, 10)} 
                        id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false} class="form-control" required={isMand}
                            value={this.state.answers?.[id] == undefined ? this.getInputValue(id, moment().format('YYYY-MM-DD'), quesName, "", sectorName, isMand) : this.state.answers?.[id]} onChange={(e) => this.getInputValue(id, e.target.value, quesName, "", sectorName, isMand)}
                            aria-describedby="emailHelp" data-qid={obj} placeholder="DD/MM/YY" />
    
                        <div className="" >
                            <p
                                className=" w-100"
                                name={id}
                                id={id}
                                style={{ display: "none", color: '#A6170A !important' }}
                                >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>
    
                        </div>
                    </div>
    
    
    
                )

            }else{


                return (

                    <div className=''>
                        <input type="date" 
                        min={finalDate.toISOString().slice(0, 10)}  
                        max={new Date().toISOString().slice(0, 10)} 
                        id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false} class="form-control" required={isMand}
                            value={this.state.answers?.[id] == undefined ? this.getInputValue(id, moment().format('YYYY-MM-DD'), quesName, "", sectorName, isMand) : this.state.answers?.[id]} onChange={(e) => this.getInputValue(id, e.target.value, quesName, "", sectorName, isMand)}
                            aria-describedby="emailHelp" data-qid={obj} placeholder="DD/MM/YY" />
    
                        <div className="" >
                            <p
                                className=" w-100"
                                name={id}
                                id={id}
                                style={{ display: "none", color: '#A6170A !important' }}
                                >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>
    
                        </div>
                    </div>
    
    
    
                )
            }

            

        }

        else if (type == 'single_select') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []

            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            let obj = JSON.stringify(obj1)

            return (
                <div alignItems="center" style={{ paddingVertical: 20, paddingHorizontal: 10 }}>

                    <Select
                        labelId="demo-select-small"
                        readOnly={this.state.readonlyState==true? true : false}  
                        id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false}
                        name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                        required={isMand}
                        data-qid={obj}
                        style={{ width: '100%' }}
                        // value={age}
                        value={this.state.answers?.[id]==undefined? "" : this.state.answers?.[id]}

                        
                        onChange={this.handleChange}
                    >

                        {sortOption?.map(item => {

                            return (
                                <MenuItem  
                                onClick={(e) => this.getInputValue(id, item?.[0]?._id, quesName, item?.[0]?.name?.en, sectorName, isMand)} 
                                value={item?.[0]?._id}>{item?.[0]?.name?.[this.state.lang]}</MenuItem>
                            )
                        })

                        }

                    </Select>

                   

                    <div className="" >
                        <p
                            className=" w-100"
                            name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                            
                            data-qid={obj}
                            id={id}
                            style={{ display: "none", color: '#A6170A !important' }}
                            >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                    </div>

                </div>
            )

        }


        else if (type == 'multi_select') {
            this.gridRowHeading=[]
            this.gridRowHeadingMain = []
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            let obj = JSON.stringify(obj1)



            let data = [];


            // console.log("this.state.selectedOptions", this.state.selectedOptions?.[id])
            return (
                <div>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                        name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false}
                        multiple
                        value={this.state.selectedOptions?.[id] == undefined ? [] : this.state.selectedOptions?.[id]}
                        style={{ width: '100%' }}
                        data-qid={obj}
                        input={<OutlinedInput  />}
                        MenuProps={this.MenuProps}
                        renderValue={(selected) =>{
                            let str = []
                            selected.forEach(x=>{
                                let op = sortOption.filter(y=> y[0]._id == x)[0]
                                str.push(op[0]?.name?.[this.props.lang])
                            })
                            return str.join(',')
                        }}
                    >

                        {sortOption.map((item) => {
                            return (
                                item?.map((data) => {
                                    return (
                                        <>
                                            <MenuItem key={data?._id} value={this.state.selectedOptions?.[id] == undefined ? [] : this.state.selectedOptions?.[id]} >
                                                <Checkbox checked={(this.state.selectedOptions?.[id] == undefined ? [] : this.state.selectedOptions?.[id])?.includes(data?._id)} onChange={(e) => { this.handleChkboxChange(id, e, data, data?._id, sectorName, isMand, quesName) }} />
                                                <ListItemText primary={data?.name?.[this.props.lang]} />
                                            </MenuItem>
                                        </>
                                    )

                                })
                            )
                        })}
                    </Select>
                    <div className="" >
                        <p
                            className=" w-100"
                            name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                            
                            data-qid={obj}
                            id={id}
                            style={{ display: "none", color: '#A6170A !important' }}
                            >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                    </div>

                    {/* <select accessibilityLabel="Select"
                     placeholder="Select" multiple >
                          {
                            sortOption?.map(item => {
                                item?.map((data)=>{
                                    return (
                                        <option value={data?._id}>{data?.name?.en} </option>
                                        // <Select.Item label={item?.[0]?.name?.en} value={item?.[0]?._id} />
                                    )

                                })

                            })
                        }
                    </select> */}

                    {/* <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            style={{width:'100%'}}
                            // value={age}
                            multiple={true}

                            // onChange={this.handleChange}
                        >

                      {   sortOption?.map(item => {

                                return (
                            <MenuItem  value={item?.[0]?._id}>{item?.[0]?.name}</MenuItem>
                                )
                                })

                                }

                        </Select> */}

                </div>
            )

        }


        else if (type == 'image') {

            this.gridRowHeading=[]
            this.gridRowHeadingMain = []

            return (
                <div>
                    <Button style={{ backgroundColor: '#A6170A', marginBottom: 20, color: '#fff', }} onPress={() => { this.onOpen() }}>Choose Image From Galley</Button>

                    <img style={{ width: '100%', height: 200, borderWidth: 1, borderColor: 'red' }} source={{ uri: `data:image/jpeg;base64,${this.state.imgURL}` }} />

                    <div className="" >
                        <p
                            className=" w-100"
                            name={id}
                            id={id}
                            style={{ display: "none", color: '#A6170A !important' }}
                        >The field is mandatory</p>

                    </div>
                </div>
            )

        }


        else if (type == 'grid') {
            let obj1={
                "quesName" : quesName,
                "id" : id,
                "type" : type,
                "sectorName" : sectorName,
                "mand" : isMand
            }
            $('.ede_gridgender').closest('.wde_grid').addClass('mw-uctop');
          
            let obj = JSON.stringify(obj1)
            // console.log("grid",grid, id)
            let gridData = JSON.parse(grid?.[0])
            
            let columnsData = [];
            let rowsData = [];

        

            for (const [key, value] of Object.entries(gridData?.columns?.names)) {

                columnsData.push(value)
            }

            console.log(gridData?.rows?.names,"check data roe")
            for (const [key, value] of Object.entries(gridData?.rows?.names)) {

                rowsData.push(value)
            }

            const data = [];

            gridData?.questions?.['00']?.singleChoice?.selectedOption?.forEach(item => {

                data.push({ "label": item?.name, "value": item?._id })

            })


            

            return (<>
             
                    <div className='wde_gridBox'>
                        <div className='wde_gridss'>
                        {rowsData?.slice(0, gridData?.rows?.count)?.map((row, ir) => (
                            <div className='wde_gridinput' key={ir}>
                                    {/* <div> { row} </div> */}
                                    
                                    {
                                        console.log(row,this.gridRowHeading,"djsnjdsb")
                                    }
                                {this.gridRowHeading?.includes(row?.en)? '' :  
                                <div className='ede_gridgender abc'>
                                    {/* {console.log("maletra",webdataentryj?.[row?.en.split(' ').join('_')]?.[this.props.lang],"change file and chart")} */}
                                    <div className="gglabel"> {webdataentryj?.[row?.en.split(' ').join('_')]?.[this.props.lang]} </div>
                                    <span style={{display:"none"}}>{this.gridRowHeading?.push(row)?.en}</span>
                                    
                                    
                                 </div> 
                                }
                              
                                

                                {/* <div style={{ textAlign: 'center', color: '#333', fontWeight: 'bold', padding: '8px', border: '1px solid #ddd', fontSize: '16px' }}>
                                    {row}
                                </div> */}
                                
                                {columnsData?.slice(0, gridData?.columns?.count)?.map((item, ic) => (
                                    <div className='wde_gridinput2' key={ic}>
                                        {/* <p onClick={()=> console.log(this.state.gridQuestion,gridData)} >T</p> */}
                                        {gridData?.questions?.[''+ic+ir ]?.type == 'Single Choice' ? (
                                            <div style={{ width: '100%' }}>
                                                
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                                                    name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} disabled={false}
                                                    label="Age"
                                                    data-qid={obj}
                                                    style={{ width: '200px' }}
                                                    value={(this.state.gridQuestion[id]?.filter(x=> x.row==ir && x.col==ic)[0]?.ans)}
                                                >
                                                    {data.map((option) => (
                                                        <MenuItem key={option.value} value={option.value} onClick={(e) => this.getGridValue(id, option.value, ir, ic, sectorName, isMand, false, (grid_org?.filter(x=> x.row==(ir+1).toString() && x.column==(ic+1).toString())[0]?._id), "singlechoice")}> 
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        ) :
                                            (
                                                gridData?.questions?.[''+ir+ic]?.type === 'Textbox' ? (
                                                    <input value={(this.state.gridQuestion[id]?.filter(x=> x.row==ir && x.col==ic)[0]?.ans) } type="text"
                                                     id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} 
                                                     required={isMand} onChange={(e) => this.getGridValue(id, e.target.value, ir, ic, sectorName, isMand,false, (grid_org?.filter(x=> x.row==(ir+1).toString() && x.column==(ic+1).toString())[0]?._id), "text", quesName)} 
                                                     style={{ outline: 'none', border: '0px', width: '100%', height: '30px', fontSize: '12px' }} placeholder={webdataentryj.Enter_text[this.state.lang]} />
                                                ) : (
                                                    <input onKeyDown={this.handleKeyDown} min='0' data-qid={obj} abc={(this.state.gridQuestion[id]?.filter(x=> x.row==ir && x.col==ic)[0]?.ans)? 'abc' : 'def'} value={(this.state.gridQuestion[id]?.filter(x=> x.row==ir && x.col==ic)[0]?.ans)? (this.state.gridQuestion[id]?.filter(x=> x.row==ir && x.col==ic)[0]?.ans) : "" } type="number"  id={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")} name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}  required={isMand} 
                                                    onChange={(e) => {
                                                        let val = e.target.value;
                                                        let [integerPart, decimalPart] = val.replace(/[^0-9.]/g, '').split('.');
                                                        decimalPart = decimalPart ? `.${decimalPart.slice(0, gridData?.questions?.['00']?.numeric?.decimal)}` : '';
                                                        val = parseFloat(`${integerPart}${decimalPart}`);
                                                        val = val >= 0 ? val : 0;
                                                        this.getGridValue(id, val, ir, ic, sectorName, isMand, false,  (grid_org?.filter(x=> x.row==(ir+1).toString() && x.column==(ic+1).toString())[0]?._id), "numeric", quesName);  }} style={{ outline: 'none', border: '0px', width: '100%', height: '30px', fontSize: '12px' }} placeholder={webdataentryj.Enter_Value[this.state.lang]} />
                                                )
                                            )}
                                    </div>
                                ))}
                                
                                
                            </div>
                        ))}
                        <div className='wde_gridinput disabled'>
                            {
                                gridData?.questions?.['00']?.type!== 'Textbox' &&
                            <>
                            {this.gridRowHeading?.includes("Total")? '' :  
                                <div className='ede_gridgender abc'>
                                   
                                   <span className="gglabel">{webdataentryj.Total[this.state.lang]}</span>
                                    <span style={{display:"none"}}>{this.gridRowHeading?.push("Total")}</span>
                                    
                                    
                                 </div> 
                                }
                            
                            
                        <div className='wde_gridinput2'>
                        {/* {console.log( gridData?.questions?.['00']?.numeric?.decimal,"asdfghjkhgdff")} */}
                        <input
                         onChange={(e) => {
                            this.totalValue(id, false, this.state.gridQuestion[id],gridData?.questions?.['00']?.numeric?.decimal)
                        }}
                         onKeyDown={this.handleKeyDown} min='0' data-qid={obj} value={(this.state.gridQuestion[id]==undefined? "" :  this.totalValue(id, false, this.state.gridQuestion[id],gridData?.questions?.['00']?.numeric?.decimal) ) }  type="number" id={id+'total'}   required={isMand}  style={{ outline: 'none', border: 'none', width: '100%', height: '30px', fontSize: '12px' }} placeholder="" />
                        </div>
                        </>
                        }
                        </div>
                        </div>
                        <div className="" >
                    <p
                        className=" w-100"
                        name={quesName?.replace(/ /g, "_").replace(/\//g, "_").replace(/[{()}]/g, "_")}
                            
                        data-qid={obj}
                        id={id}
                        style={{ display: "none", color: '#A6170A !important' }}
                        >{webdataentryj.The_field_is_mandatory[this.state.lang]}</p>

                </div>
                    </div>
                    {/* </tbody>
                </table> */}
               
            </>

            )

            

        }

    }

    totalValue = (quesId, remove, data,decimalNumber=0) => {
        console.log("data-----", data)
        if(remove!=true){
            let value = data.reduce((n, {ans}) => n +(isNaN(parseFloat(ans)) ? 0 : parseFloat(ans)), 0)
            // console.log("data1-----", $("input[name='Q012']")
            // .map(function(){return $(this).val();}).get())

            // let [integerPart, decimalPart] = value.toFixed(+decimalNumber).split('.');
            // decimalPart = decimalPart ? `.${decimalPart.slice(0, +decimalNumber)}` : '';
            // value = parseFloat(`${integerPart}${decimalPart}`);
            
            $('#'+quesId+'total').val(value);
                
        }

      }

    convert = (str) => {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join(".");
      }

      saveData = async (fd, isComp, calculation) => {
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        let id = savedItem.id;
        // console.log("id--" + id)
        var todayDate = new Date().toISOString().slice(0, 10);
        let dataToSend = [];
        let disasterDate = this.convert($('[name ="Q005"]').val())
        // console.log("disasterDate", disasterDate)

        let question = []
        let multiQuestion = []
        this.state.selectedRow?.questionsList?.forEach(elem => {

            if (Object.keys(this.state.answers)?.includes(elem._id) == true) {
                let obj = {

                    "question_id": elem._id,
                    "options": [
                        {
                            "ans": this.state.answers?.[elem?._id],
                            "time_period": disasterDate,
                            "source": savedItem?.name,
                            "time_period_format": "YYYY.MM.DD"
                        }

                    ]

                }
                question.push(obj)


            } else if (Object.keys(this.state.selectedOptions)?.includes(elem._id) == true) {
                let optionData = []
                this.state.selectedOptions?.[elem._id]?.forEach(element => {

                    let obj = {
                        "ans": element,
                        "time_period": disasterDate,
                        "source": savedItem?.name,
                        "time_period_format": "YYYY.MM.DD"
                    }

                    optionData.push(obj)
                });
                let obj2 = {

                    "question_id": elem._id,
                    "options": optionData

                }
                question.push(obj2)



            } else if (Object.keys(this.state.gridQuestion)?.includes(elem._id) == true) {
                let optionData = []
                // console.log("elementData",this.state.gridQuestion?.[elem?._id])
                this.state.gridQuestion?.[elem._id]?.forEach(element => {

                    let obj = {
                        "ans": element?.ans,
                        "time_period": disasterDate,
                        "source": savedItem?.name,
                        "time_period_format": "YYYY.MM.DD",
                        "grid_col": element?.col,
                        "grid_row": element?.row,
                        "grid_id" : element?.grid_id
                    }
                    // console.log("element", element)
                    optionData.push(obj)
                });
                let obj2 = {

                    "question_id": elem._id,
                    "options": optionData

                }
                question.push(obj2)
            }

        });
        // console.log("elementquestion", question)
        // this.state.allQuestion?.forEach(element => {
        //     question.push(element)
        // });
        

        dataToSend = {
            "user_id": id,
            "survey_id": this.state.section_Data?.[0]?.survey_id,
            "area_code": this.state.section_Data?.[0]?.areaCode,
            "manageform_id": this.state.section_Data?.[0]?._id,
            "lat": this.state.latitude,
            "long": this.state.longitude,
            "disasterCode" : this.state?.selectedRow?.disasterCode,
            "defaultsource": true,
            "form_percentage": Math.round(calculation),
            "defaulttimeperiod": true,
            "defaultsourcevalue": this.state.section_Data?.[0]?.form_id + '|' + savedItem.name,
            "save_status": 0,
            "is_completed": 0,
            "all_files": "[]",
            "question_answer": question
        }
        // console.log("question", question, dataToSend, this.state.section_Data)

        await AdminService.httpPost('/data-import/survey-data-entry/add-survey-response', dataToSend).then((res) => {

            // console.log("res", res)
            if (res.status == 1) {
                this.setState({ allQuestion: question })
                this.status = true
            } else {
                this.status = false
                this.message = res.message;
            }

        }).catch((err) => {
            this.setState({ loader: false })
            // console.log("This is errorrrrr ok ok ok---", err)
        });
    }

    forwardData = async (isComp) => {
        if (isComp == 1) {
            if (this.state.progressValue != 100) {
                toast.error("Please completely fill the form")
            }
        }
        this.addNonMandatoryQuestion('Q229',false)
        this.addNonMandatoryQuestion('Q231',false)
        this.addNonMandatoryQuestion('Q233',false)
        this.addNonMandatoryQuestion('Q235',false)
        
        
        let success = true

        let fd = this.state.section_Data?.[0]?.sectionDetails?.filter(row => row?.section?.[0]?.name?.en == this.state.secName || (row?.sub_section?.filter(data => data?.name?.en == this.state.secName))?.length>0 );
        
        if (this.state.answers == undefined) {
            
        }

        let reqData = fd?.[0]?.question?.filter(row => row?.mandatory == true && (row?.sectionDetails?.[0]?.name?.en == this.state.secName || row?.sub_sectionDetails?.[0]?.name?.en == this.state.secName));
        let reqDatanonmandatory = fd?.[0]?.question?.filter(row =>  (row?.sectionDetails?.[0]?.name?.en == this.state.secName || row?.sub_sectionDetails?.[0]?.name?.en == this.state.secName) && this.nonMandatoryQuestion?.includes(row.title?.en?.split(".")[0]));

        // elem?.title?.[this.state.lang]?.split(".")[0]
        let formempty = ""
        reqData?.map((elem) => {

            if (Object.keys(this.state.answers)?.includes(elem._id) == false && Object.keys(this.state.selectedOptions)?.includes(elem._id) == false && Object.keys(this.state.gridQuestion)?.includes(elem._id) == false) {
                success = false;
                $("#" + elem._id).val("please input");
                
                $('#' + elem._id).attr('style', 'display:block; color:#A6170A !important');
                formempty = "notfill"
                
            }


        })

        reqDatanonmandatory?.map((elem) => {

            if (Object.keys(this.state.answers)?.includes(elem._id) == false  && Object.keys(this.state.selectedOptions)?.includes(elem._id) == false && Object.keys(this.state.gridQuestion)?.includes(elem._id) == false) {
                success = false;
                
                $("#" + elem._id).val("please input");
                
                $('#' + elem._id).attr('style', 'display:block; color:#A6170A !important');
                formempty = "notfill"
                
            }


        })
       
        if (formempty == "notfill") {
            toast.error(webdataentryj.Please_fill_all_the_mandatory_input_field[this.state.lang])
        }
        if (success == true) {
            let reqDatadata = fd?.[0]?.question?.filter(row => row?.sectionDetails?.[0]?.name?.en == this.state.secName || row?.sub_sectionDetails?.[0]?.name?.en == this.state.secName);
            let count = this.state.progressCount;
            let calculation = this.state.progressValue;
            if(this.state.sectorCount?.includes(this.state.secName)){

            }else{
                count+= reqData?.length;
                let reqData1 = this.state.selectedRow?.questionsList?.filter(row => row?.mandatory == true);
                let maxValue = 100;
                
                calculation = (count * maxValue) / reqData1?.length;
                
            }
            
            if ((this.indexValue?.length - 1) == this.state.currentTab) {
                  let objdata = await this.finalCalculation();
                  calculation = objdata.calculation;
                  count = objdata.count;
                  
                    // alert("data")
            }
            
            
            let status = await this.saveData(fd, isComp, calculation);
            // console.log("status", this.status)

            if ((this.indexValue?.length - 1) == this.state.currentTab && this.status) {
                toast.success("Form saved successfully!");
                $("#modal_close").trigger('click');
                window.location.reload(false);
            }

            if (this.status) {
               
                this.setState({progressValue : calculation, progressCount : count})
                if(this.state.sectorCount?.includes(this.state.secName)){

                }else{
                    // count+= reqData?.length;
                    this.setState({ sectorCount: [ ...this.state.sectorCount,  this.state.secName ] })
                }
                this.checkcounterValue(reqDatadata)
              
               


                let val = this.state.currentTab;
                var pic1 = "#v-pills-home-tab-" + ((val) + 1)
                
                this.setState({ currentTab: ((val) + 1), secName: this.indexValue[val + 1] })

                $(pic1).trigger('click');
            }
            else {
                
                toast.error(this.message);
            }


        }

    }

    checkcounterValue = (fd) => {
        let count = 0;
        fd?.forEach(elem => {
            if(Object.keys(this.state.answers)?.includes(elem._id)){
                    count+=1
            }else if(Object.keys(this.state.selectedOptions)?.includes(elem._id)){
                count+=1
            }else if(Object.keys(this.state.gridQuestion)?.includes(elem._id)){
                count+=1
            }
            
            
        });

        this.setState({counterValue : { ...this.state.counterValue, [this.state.secName]: count }})
 

    }

    finalCalculation = () => {
        let countdata = 0;
        let reqData1 = this.state.selectedRow?.questionsList?.filter(row => row?.mandatory == true);
        // console.log("calculation5",reqData1)
        reqData1?.map(elem => {

            if (Object.keys(this.state.answers)?.includes(elem._id) == true || Object.keys(this.state.selectedOptions)?.includes(elem._id) == true || Object.keys(this.state.gridQuestion)?.includes(elem._id) == true) {
                        
                    countdata+=1;

            }  

        });
        // console.log("calculation4",countdata, Number(reqData1?.length))
       let calculation = (Number(countdata) * Number(100)) / Number(reqData1?.length);

        return {"calculation" : calculation, "count" : countdata};

    }

    handleSubmit = (e) => {
        
    }

    addNonMandatoryQuestion = (ques, show) => {
        // console.log("nonMandatoryQuestion", this.nonMandatoryQuestion)
        if(show === true){
                if(!this.nonMandatoryQuestion?.includes(ques)){
                        this.nonMandatoryQuestion?.push(ques);
                }
        }else {
            // this.nonMandatoryQuestion?.splice(ques);

            const index = this.nonMandatoryQuestion?.indexOf(ques);
            if (index > -1) { // only splice array when item is found
                this.nonMandatoryQuestion?.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }



    backwardData = () => {
        let val = this.state.currentTab;
        var pic1 = "#v-pills-home-tab-" + ((val) - 1)
        

        this.setState({ currentTab: ((val) - 1) })

        $(pic1).trigger('click');


    }

    handleButtonClick = (event, nodeIds) => {
        this.setState({ expanded: [] }) // set the state to an empty array to collapse the tree view
    }

    handleTreeViewClick = (event, nodeId) => {
        this.setState({expanded: nodeId})
    };


    handleKeyDown = (e) => {
        
        if (e.key === '-' || e.key === 'Subtract') {
            e.preventDefault();
        }
    }

    SetCurrentTab = (ele) => {
        setTimeout(()=>{
            $('.nav-link').removeClass('active');
            this.setState({ currentTab: this.indexValue?.indexOf(ele?.name?.en), secName: ele?.name?.en, expanded: [] });
            }, 2000);
       
    };

    parentCurrentTab = (element) => {
        setTimeout(()=>{
            $('.nav-link').removeClass('active');
            this.setState({ currentTab: this.indexValue?.indexOf(element?.section?.[0]?.name?.en), secName: element?.section?.[0]?.name?.en, expanded: [] });
            }, 2000);
       
    };

    render() {

        return (
            <div class="modal-dialog modal-dialog-centered modal-lg" > 

                <div class="modal-content" >
                    <div class="modal-header">
                        <div className="content_div d-flex align-items-center w-100 justify-content-between">
                            <div className="form_name">
                                <h5 class="modal-title"   id="exampleModalLabel">{this.state.selectedRow?.name?.[this.props.lang]}</h5>
                            </div>
                            <div className="progress_bar-area d-flex align-items-center justify-content-between">
                                <div className="area_disaster_div d-flex align-items-end">
                                    <div className="area_inner d-flex align-items-end areaInner_pipe">
                                        <span className='map_pointer'>
                                            <img src="assets/lib/images/icon/map-pointer.svg" />
                                        </span>
                                        <div>
                                            <p>{webdataentryj.area[this.props.lang]}</p>
                                            <h5>{this.state?.selectedRow?.area}</h5>
                                        </div>
                                    </div>
                                    <div className="area_inner">
                                        <p>{webdataentryj.disasterype[this.props.lang]}</p>
                                        <h5>{this.state?.selectedRow?.disaster?.[this.state.lang]}</h5>
                                    </div>

                                </div>

                                <div className="main_progress_bar">
                                    <div className='pb_user'>
                                        <div className='pbUser'>
                                           
                                            <p>{JSON.parse(localStorage.getItem("userInfo"))?.name}</p>
                                            <small>{moment().format('YYYY-MM-DD')}</small>
                                        </div>
                                        <div>
                                            <img src='assets/lib/images/home/user_admin.svg' />
                                        </div>
                                    </div>
                                    <div className='pbProgress'>
                                        <h3>{Math.round(this.state.progressValue)}%</h3>
                                        <ProgressBar now={this.state.progressValue} max={100} />
                                        <p>{webdataentryj.updateinprogress[this.props.lang]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="btn-close" id='modal_close' data-dismiss="modal" onClick={() => window.location.reload(false)} aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row tabs_row justify-content-between">
                            <div class="col-3 main_tabs webDataTabs">
                                <div class="nav flex-column nav-pills left_maintab" id="v-pills-tab" role="tablist" aria-orientation="vertical">



                                    {
                                            
                                        this.state.section_Data?.[0]?.sectionDetails?.map((element, i) => {

                                            
                                            if (element?.sub_section !== undefined && element?.sub_section.length>0) {
                                                
                                                return (
                                                    <>
                                                       
                                                        
                                                      <TreeView
                                                        key={i}
                                                      
                                                            className='nav-link'
                                                            defaultExpanded={['3']}
                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                            // expanded={this.state.expanded} // pass the expanded state as a prop
                                                            // onNodeToggle={this.handleTreeViewClick}
                                                        >
                                                            <TreeItem nodeId='3' label={element?.section?.[0]?.name?.[this.state.lang]}>
                                                              {
                                                                    element?.sub_section?.map((ele, j) => {

                                                                        if(!this.indexValue.includes(ele?.name?.en)){
                                                                            // console.log("this.value",this.indexValue)
                                                                                this.indexValue.push(ele?.name?.en)
                                                                        }

                                                                        // var pic = "#v-pills-home-" +i+"-"+j;
                                                                        // var pic1 = "v-pills-home-tab-"  +i+"-"+j;
                                                                        var pic = "#v-pills-home-" +this.indexValue?.indexOf(ele?.name?.en)
                                                                        var pic1 = "v-pills-home-tab-"  +this.indexValue?.indexOf(ele?.name?.en)
                                                                        
                                                                            return (
                                                                                <TreeItem nodeId={this.indexValue?.indexOf(ele?.name?.en)}
                                                                                label={
                                                                                    <>
                                                                                        {/* <button id={pic1} className='nav-link' 
                                                                                        onClick={() => this.setState({ currentTab: this.indexValue?.indexOf(ele?.name?.en), secName: ele?.name?.en, expanded: [i] })} 
                                                                                        aria-controls={"v-pills-home-subtab" + i} aria-selected="true" data-toggle="pill" data-target={pic} >
            
                                                                                            {ele?.name?.[this.state.lang]} <span> {this.state.counterValue?.[ele?.name?.en] == undefined ? 0 : this.state.counterValue?.[ele?.name?.en]} / {element?.question?.filter(row => row.sub_section_id== ele?._id).length}</span>
                                                                                        </button> */}


                                                                                        <button class="nav-link" id={pic1} data-toggle="pill" data-target={pic} type="button" onClick={() => this.SetCurrentTab(ele)}
                                                                                            // onClick={() => this.setState({ currentTab: this.indexValue?.indexOf(ele?.name?.en), secName: ele?.name?.en, expanded: [] })} 
                                                                                            role="tab" aria-controls="v-pills-home" aria-selected="false">{ele?.name?.[this.state.lang]}
                                                                                            <span> {this.state.counterValue?.[ele?.name?.en] == undefined ? 0 : this.state.counterValue?.[ele?.name?.en]} / {element?.question?.filter(row => row.sub_section_id== ele?._id).length}</span></button>
                                                                                    </>
                                                                                }
                                                                            />
                                                                                
                                                                            )
                                                                    })


                                                                    


                                                              }
                           
                                                            </TreeItem>


                                                        </TreeView>


                                                    </>
                                                );
                                            } else {

                                                if(!this.indexValue.includes(element?.section?.[0]?.name?.en)){
                                                    // console.log("this.value",this.indexValue)
                                                    this.indexValue.push(element?.section?.[0]?.name?.en)
                                                }
                                                var pic = "#v-pills-home-" +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                var pic1 = "v-pills-home-tab-"  +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                
                                                
                                                return (
                                                    <button class="nav-link" id={pic1} data-toggle="pill" data-target={pic} type="button" 
                                                    // onClick={() => this.setState({ currentTab: this.indexValue?.indexOf(element?.section?.[0]?.name?.en), secName: element?.section?.[0]?.name?.en, expanded: [] })} 
                                                    onClick={() => this.parentCurrentTab(element)}
                                                    role="tab" aria-controls="v-pills-home" aria-selected="false">{element?.section?.[0]?.name?.[this.state.lang]}
                                                     <span> {this.state.counterValue?.[element?.section?.[0]?.name?.en] == undefined ? 0 : this.state.counterValue?.[element?.section?.[0]?.name?.en]} / {element?.question?.length}</span></button>
                                                )
                                            }


                                            
                                        })
                                            
                                    }


                                  
                                </div>
                            </div>
                            <div class="col-9">
                                <ValidatorForm className={this.state.readonlyState? "main_react_form w-100 readonlywebdata" : "main_react_form w-100"}  ref={this.form} onSubmit={(e) => this.handleSubmit(e)} id="addActivityForm">

                                    <div class="tab-content" id="v-pills-tabContent">




                                        { 
                                            this.state.section_Data?.[0]?.sectionDetails?.map((element, i) => {

                                                                    
                          
                                                
                                                

                                                

                                                if (element?.sub_section !== undefined && element?.sub_section?.length>0) {
                                                
                                                    return (
                                                        <>
                                                           
                                                                  {
                                                                        element?.sub_section?.map((ele, j) => {
    
                                                                            

                                                                            if(!this.indexValue.includes(ele?.name?.en)){
                                                                                this.indexValue.push(ele?.name?.en)
                                                                        }

                                                                        var pic = "v-pills-home-"  +this.indexValue?.indexOf(ele?.name?.en)
                                                                        var pic1 = "v-pills-home-tab-" +this.indexValue?.indexOf(ele?.name?.en)
                                                                        
                                                                            // console.log("pic", pic, pic1)
                                                                                return (
                                                       
                                                                                    <div class="tab-pane fade  " id={pic} role="tabpanel" aria-labelledby={pic1}>

                                                                                    <div className="inputform_style_new d-flex flex-wrap form-group inputform_style ifs_dropdown ">
                                                                                        <div className="col-md-12 p-0 head_box">
                                                                                            <h4>{ele?.name?.[this.state.lang]}</h4>
                                                                                        </div>
                        
                                                                                        {
                                                                                            element.question?.map(elem => {
                        
                        
                                                                                                if (ele?._id == elem?.sub_section_id) {
                        
                        
                                                                                                    let show = 'block'
                                                                                                    return (
                                                                                                        <>
                        
                                                                                                      
                                                                                                                <div className={elem?.type=='grid'? `col-md-12 wde_section wde_grid ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} ` : `col-md-12 wde_section ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]) || this.commentQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} `}>
                                                                                                                    <div className='wde_qustion'>
                                                                                                                     <label><span>{ elem?.title?.[this.state.lang]?.split(".")[0]}</span> { elem?.title?.[this.state.lang]?.split(".")[1]}</label>
                                                                                                                    {/* <label>{elem?.title?.[this.state.lang]}</label> */}
                                                                                                                    {this.getinputType(elem?.type,
                                                                                                                        elem?._id,
                                                                                                                        elem?.optionList,
                                                                                                                        elem?.maximum_numeric_value,
                                                                                                                        elem?.minimum_numeric_value,
                                                                                                                        elem?.mandatory,
                                                                                                                        elem?.grid,
                                                                                                                        elem?.title?.en?.split(".")[0],
                                                                                                                        // element?.sub_section?.name?.en
                                                                                                                        ele?.name?.en,
                                                                                                                        elem?.grid_org,
                                                                                                                        elem?.decimal_value,
                                                                                                                    )}
                        
                        
                                                                                                                </div>
                                                                                                            </div>
                        
                                                                                                        </>
                                                                                                    )
                                                                                                }
                        
                        
                        
                        
                        
                                                                                            })
                        
                                                                                        }
                        
                        
                                                                                    </div>
                        
                        
                                                                                </div>
                        
                                                                                )
                                                                        })
    
    
                                                                        
    
    
                                                                  }
                               
                                                                
    
    
                                                            
    
    
                                                        </>
                                                    );
                                                } else {
                                                    if(!this.indexValue.includes(element?.section?.[0]?.name?.en)){
                                                        this.indexValue.push(element?.section?.[0]?.name?.en)
                                                }

                                                var pic = "v-pills-home-"  +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                var pic1 = "v-pills-home-tab-" +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                    
                                                    return (
                                                        
                                                        <div class="tab-pane fade  " id={pic} role="tabpanel" aria-labelledby={pic1}>

                                                            <div className="inputform_style_new d-flex flex-wrap form-group inputform_style ifs_dropdown ">
                                                                <div className="col-md-12 p-0 head_box">
                                                                    <h4>{element?.section?.[0]?.name?.[this.state.lang]}</h4>
                                                                </div>


                                                                {element.question?.map(elem => {

                                                                    let show = 'block'


                                                           
                                                                    return (
                                                                        <>

                                                                        <div className={elem?.type=='grid'? `col-md-12 wde_section wde_grid ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} ` : `col-md-12 wde_section ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]) || this.commentQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} `}>
                                                                                <div className='wde_qustion'>
                                                                                    <label><span>{ elem?.title?.[this.state.lang]?.split(".")[0]}</span> { elem?.title?.[this.state.lang]?.split(".")[1]}</label>
                                                                                    {/* <label>{elem?.title?.[this.state.lang]}</label> */}
                                                                                    {this.getinputType(elem?.type,
                                                                                        elem?._id,
                                                                                        elem?.optionList,
                                                                                        elem?.maximum_numeric_value,
                                                                                        elem?.minimum_numeric_value,
                                                                                        elem?.mandatory,
                                                                                        elem?.grid,
                                                                                        elem?.title?.en?.split(".")[0],
                                                                                        element?.section?.[0]?.name?.en,
                                                                                        elem?.grid_org,
                                                                                        elem?.decimal_value,
                                                                                    )}


                                                                                </div>
                                                                            </div>



                                                                            

                                                                        </>
                                                                    )



                                                                })

                                                                }


                                                            </div>


                                                        </div>
                                                    )
                                                }

                                            })

                                            // : null

                                        }

                                        { 
                                            this.state.section_Data?.[0]?.sectionDetails?.map((element, i) => {

                                               
                                                
                                                

                                                // console.log('showhide',element?.sub_section !== undefined && element?.sub_section?.length>0);

                                                if (element?.sub_section !== undefined && element?.sub_section?.length>0) {
                                                
                                                    return (
                                                        <>
                                                           
                                                           <div class="tab-pane fade  " id={pic} role="tabpanel" aria-labelledby={pic1}>
                                                                  {
                                                                        element?.sub_section?.map((ele, j) => {
    
                                                                            

                                                                            if(!this.indexValue.includes(ele?.name?.en)){
                                                                                this.indexValue.push(ele?.name?.en)
                                                                        }

                                                                        var pic = "v-pills-home-"  +this.indexValue?.indexOf(ele?.name?.en)
                                                                        var pic1 = "v-pills-home-tab-" +this.indexValue?.indexOf(ele?.name?.en)
                                                                        
                                                                            
                                                                                return (
                                                       

                                                                                    <div className="inputform_style_new d-flex flex-wrap form-group inputform_style ifs_dropdown ">
                                                                                        <div className="col-md-12 p-0 head_box">
                                                                                            <h4>{ele?.name?.[this.state.lang]}</h4>
                                                                                        </div>
                        
                                                                                        {
                                                                                            element.question?.map(elem => {
                        
                        
                                                                                                if (ele?._id == elem?.sub_section_id) {
                        
                        
                                                                                                    let show = 'block'
                                                                                                    return (
                                                                                                        <>
                        
                                                                                                      
                                                                                                                <div className={elem?.type=='grid'? `col-md-12 wde_section wde_grid ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} ` : `col-md-12 wde_section ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]) || this.commentQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'} `}>
                                                                                                                    <div className='wde_qustion'>
                                                                                                                     <label><span>{ elem?.title?.[this.state.lang]?.split(".")[0]}</span> { elem?.title?.[this.state.lang]?.split(".")[1]}</label>
                                                                                                                    {/* <label>{elem?.title?.[this.state.lang]}</label> */}
                                                                                                                    {this.getinputType(elem?.type,
                                                                                                                        elem?._id,
                                                                                                                        elem?.optionList,
                                                                                                                        elem?.maximum_numeric_value,
                                                                                                                        elem?.minimum_numeric_value,
                                                                                                                        elem?.mandatory,
                                                                                                                        elem?.grid,
                                                                                                                        elem?.title?.en?.split(".")[0],
                                                                                                                        // element?.sub_section?.name?.en
                                                                                                                        ele?.name?.en,
                                                                                                                        elem?.grid_org,
                                                                                                                        elem?.decimal_value,
                                                                                                                    )}
                        
                        
                                                                                                                </div>
                                                                                                            </div>
                        
                                                                                                        </>
                                                                                                    )
                                                                                                }
                        
                        
                        
                        
                        
                                                                                            })
                        
                                                                                        }
                        
                        
                                                                                    </div>
                        
                        
                        
                                                                    )
                                                                })
                                                                
                                                                
                                                                
                                                                
                                                                
                                                            }
                                                            </div>
                               
                                                                
    
    
                                                            
    
    
                                                        </>
                                                    );
                                                } else {
                                                    if(!this.indexValue.includes(element?.section?.[0]?.name?.en)){
                                                        this.indexValue.push(element?.section?.[0]?.name?.en)
                                                }

                                                var pic = "v-pills-home-"  +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                var pic1 = "v-pills-home-tab-" +this.indexValue?.indexOf(element?.section?.[0]?.name?.en)
                                                    
                                                    return (
                                                        
                                                        <div class="tab-pane fade  " id={pic} role="tabpanel" aria-labelledby={pic1}>

                                                            <div className="inputform_style_new d-flex flex-wrap form-group inputform_style ifs_dropdown ">
                                                                <div className="col-md-12 p-0 head_box">
                                                                    <h4>{element?.section?.[0]?.name?.[this.state.lang]}</h4>
                                                                </div>


                                                                {element.question?.map(elem => {

                                                                    let show = 'block'


                                                           
                                                                    return (
                                                                        <>

                                                                        <div className={elem?.type=='grid'? `col-md-12 wde_section wde_grid ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]))? '' : 'd-none'}  ` : `col-md-12 wde_section ${elem?.title?.en?.split(".")[0]} ${(elem?.mandatory || this.nonMandatoryQuestion?.includes(elem?.title?.en?.split(".")[0]) || this.commentQuestion?.includes(elem?.title?.en?.split(".")[0]) )? '' : 'd-none'} `}>
                                                                                <div className='wde_qustion'>
                                                                                    <label><span>{ elem?.title?.[this.state.lang]?.split(".")[0]}</span> { elem?.title?.[this.state.lang]?.split(".")[1]}</label>
                                                                                    {/* <label>{elem?.title?.[this.state.lang]}</label> */}
                                                                                    {this.getinputType(elem?.type,
                                                                                        elem?._id,
                                                                                        elem?.optionList,
                                                                                        elem?.maximum_numeric_value,
                                                                                        elem?.minimum_numeric_value,
                                                                                        elem?.mandatory,
                                                                                        elem?.grid,
                                                                                        elem?.title?.en?.split(".")[0],
                                                                                        element?.section?.[0]?.name?.en,
                                                                                        elem?.grid_org,
                                                                                        elem?.decimal_value,
                                                                                    )}


                                                                                </div>
                                                                            </div>



                                                                            

                                                                        </>
                                                                    )



                                                                })

                                                                }


                                                            </div>


                                                        </div>
                                                    )
                                                }

                                            })

                                            
                                        }


                                   



                                        {/* <div class="tab-pane fade" id="v-pills-five" role="tabpanel" aria-labelledby="v-pills-settings-five">5</div>
                                    <div class="tab-pane fade" id="v-pills-six" role="tabpanel" aria-labelledby="v-pills-settings-six">6</div>
                                    <div class="tab-pane fade" id="v-pills-seven" role="tabpanel" aria-labelledby="v-pills-settings-seven">7</div>
                                    <div class="tab-pane fade" id="v-pills-eight" role="tabpanel" aria-labelledby="v-pills-settings-eight">8</div>
                                    <div class="tab-pane fade" id="v-pills-nine" role="tabpanel" aria-labelledby="v-pills-settings-nine">9</div>
                                    <div class="tab-pane fade" id="v-pills-ten" role="tabpanel" aria-labelledby="v-pills-settings-ten">10</div>
                                    <div class="tab-pane fade" id="v-pills-eleven" role="tabpanel" aria-labelledby="v-pills-settings-eleven">11</div>
                                    <div class="tab-pane fade" id="v-pills-twelve" role="tabpanel" aria-labelledby="v-pills-settings-twelve">12</div>
                                    <div class="tab-pane fade" id="v-pills-thirteen" role="tabpanel" aria-labelledby="v-pills-settings-thirteen">13</div>
                                    <div class="tab-pane fade" id="v-pills-fourteen" role="tabpanel" aria-labelledby="v-pills-settings-fourteen">14</div>
                                    <div class="tab-pane fade" id="v-pills-fifteen" role="tabpanel" aria-labelledby="v-pills-settings-fifteen">15</div> */}

                                        <div className='next_previous_btn_box d-flex align-items-center justify-content-center'>
                                        
                                            {this.state.currentTab < (this.indexValue?.length - 1) && this.state.readonlyState!==true &&
                                                <Button className="addbtn" ref={this.form} onClick={() => this.forwardData(0)} variant="contained" type="button">
                                                    {webdataentryj.saveandnext[this.state.lang]} <img src="assets/lib/images/home/arrow_right.svg" />
                                                </Button>
                                            }
                                            {this.state.currentTab == (this.indexValue?.length - 1) && this.state.readonlyState!==true &&
                                                <Button className="addbtn" ref={this.form} onClick={() => this.forwardData(0)} variant="contained" type="button">

                                                    {webdataentryj.saveandsubmit[this.state.lang]}
                                                </Button>
                                            }

                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}

            </div>
            
        );
    }


}

export default WebDataEntryModal;
