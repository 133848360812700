import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
// import AddAreaModal from '../../Modals/addArea.modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Sonnet from '../../components/Sonnet';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import ProfileModal from '../../../Common/components/modal/profile.modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormMasterModal from "../../Modals/formMaster.modal";
// import Datasummarypopup from "./modal/data-summary";
// import Dataentrypopup from "./modal/dataentry";
// import AddCalculation from "./modal/add-calculation";
// import ManageFormModal from '../../Modals/manageForm.modal';
// import ViewDataentry from "./modal/dataentry-view";
// import ViewDatavalidation from "./modal/datavalidation-view";
// import ViewDataaggregate from "./modal/dataaggregate-view";

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const formMasterTemplate = function () {
  const  openCloseModal = async () => {

        this.setState({scrollableModal:false})
  
}
const handleSubmit = (e) => {
  e.preventDefault();
  let dataToSend = [];

  dataToSend = {
 
          "id": this.state.id,
          "registrationdate":this.state.date_of_registration,
          "approvaldate":this.state.approvaldate,
          "role":this.state.role,
          "location":this.state.location,
            
         }
 
    
 console.log("test done by itm",dataToSend)
 
     
    
    }


const  closeModalClose = async () => {

  
        this.setState({disaaprovest:false})
    
  
}

const  closeModalClosePublis = async () => {


      this.setState({publishModal:false})
  

}

const  closeDeleteModal = async () => {

  
    this.setState({deleteApprovedData:false})


}

  const DataTableMain = () =>{
    // console.log("data-->" ,this.state.openTab ,  this.columnsPending , this.columnsApproved   ,this.columnsRejected);


return (
  <>
  <DataTable
              columns={this.columnsPending}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50,100]}
              style={{ display: "flex" }}
               selectableRows
              persistTableHead
              noDataComponent="No Data"
              className="managePlan"
            />
  </>
)

 }

  return (
    <>

      
      <div className="main actor_page" id="main">
        <div className='container-fluid areaCont'>
        <div> 
          {/* <button type="button" onClick={(e)=>this.fetchData(e)} class="btn btn-primary" id="download_icius_master_template_with_data">
          Download IUS Json </button> */}

          {/* <button type="button" onClick={(e)=>this.fetchQuesData(e)} class="btn btn-primary" id="download_icius_master_template_with_data">
          Download Question Json </button> */}
        </div>
          <section className='areaSection area_margin' id="areaSection">
          
            <subHeaderComponentMemo.default onSearch={this.onSearch} searchText={this.state.searchText} />                                                       
                <div className="tab_box ma_tables formMasterTable">                   
                  <div className="manageActor">            
                    <DataTableMain />
                  </div>                      
                </div>
              
                  
          
          </section>
        </div>
        
      </div>






      

      

      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form manageFormModal width350': 'gallery-sidebar add-area-modal add-form manageFormModal width0'}>

      <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className="gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img
                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
            </div>
            <div className="rightTab_step">
            {/* <div class="right-tab-heading are_head">
              <h2 class="form-heading">
                <span class="form-heading_he">
                  <span class="ddf">
                    {this.state.isEdit==true ? 'Edit Form': 'Create Form'}
                  </span>
                </span>
              </h2>
            </div> */}
            <FormMasterModal             
            isEdit = {this.state.isEdit}
            areaData = {this.state.areaData}
            mainViewData={this.state.mainViewData}
            editModeId = {this.state.editModeId}
            copyForm = {this.state.copyForm}
            saveCopyForm = {this.createCopy}
            closeFunc = {this.openAddModal}
            />
          </div>


   
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>


      {/* <ProfileModal profileData={this.state.profileData}/>
      
      <Datasummarypopup />

      <Dataentrypopup />
      <AddCalculation />
      <ViewDataentry />
      <ViewDatavalidation />
      <ViewDataaggregate /> */}


      
      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

    </>


  );
};

export default formMasterTemplate;