import { Component } from 'react'
import './language.css'
import * as jsx from './language.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";

class LanguagePage extends Component {
   
    constructor() {
        super()
        this.state = {
            filteredDatatest:[],
            valueData : 0
                }

     
    }

    render() {
        return jsx.default.bind(this)();
    }
    getIndex = (data)=>{
      this.setState({valueData: data})
      console.log('propsData', data)
    }

    
      // To sort data
      getSortedData() {
        AdminService.httpGet('/data-retrieval/area/getAreaDataSort')
          .then((res) => {
            this.rows = res.data;
            this.filterData = res.data;
            this.page.totalElements = res.data.length;
    
            this.changePageSize();
          });
      }


        getData = async () => {
            this.setState({ loader: true })
            AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
                        if(res){
                            let d = res.data;
                            this.setState({loader: false, filteredDatatest: d, data:d})
                        }
    
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
        }


    
      }



export default connect(
   
  )(LanguagePage);