import { Component } from 'react'
import './disaster.css';
import * as jsx from './disaster.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import { Tooltip } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';

class Disaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            isEdit: false,
            editModeId: null,
            modal: false,
            assignModal: false,
            loader: false,
            allIndicatorList: null,
            allUnitList: null,
            allSubgroupDimensionList: null,
            allDimensionList: null,
            sectorSubsectorList: null,
            bulkDelete: [],
            goalTargetList: null,
            disasterID: null,
            isAssigned: [],
            disasterName: null,
            selectedAssignedData: [],
        }

    }



    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => ((item.disaster_code && item.disaster_code.toLowerCase().includes(val.toLowerCase()))
                    || (item.title.en && item.title?.["en"].toLowerCase().includes(val.toLowerCase()))
                    || (item.description.en && item.description?.["en"].toLowerCase().includes(val.toLowerCase()))
                ));
        }
        this.setState({ filteredData: fd });
    }

    sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.description.en;
        const b = rowB?.description.en;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    columns = [
        {
            name: 'Disaster Code',
            selector: row => row.disaster_code,
            sortable: true,
        },
        {
            name: 'Title',
            selector: row => row.title?.en,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row =><Tooltip title={row.description?.en} placement='top'><p>{row.description?.en}</p></Tooltip>,
            sortable: true,
            sortFunction: this.sortWithToolTip
        },
        {
            name: 'Action',
            width: '200px',
            cell: row =>
                <>
                    {/* <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a onClick={(e) => { this.takeAssignedID(row) }} class="dropdown-item" href="javascript:void(0);" data-toggle="modal" data-target='#assignmodal'>Assign Indicator</a>

                            <a class="dropdown-item" onClick={(e) => this.editMode(e, row._id)} href="javascript:;">Edit</a>
                             <a class="dropdown-item" onClick={(e) => this.download(e, row._id)} href="javascript:;">Download</a> 
                            <a class="dropdown-item" onClick={(e) => { this.deleteEntry(row._id) }} href="javascript:;">Delete</a>
                            <a class="dropdown-item"  onClick={(e)=> this.removeRow(e, row.subgroup_id)} href="javascript:;">Delete</a>
                        </div>
                    </div> */}

                    <Dropdown className='table-action'>
                        <Dropdown.Toggle id="dropdown-basic">
                            Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { this.takeAssignedID(row) }} href="javascript:;" data-toggle="modal" data-target='#assignmodal'>Assign Indicator</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.deleteEntry(row._id) }} href="javascript:;">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];



    takeAssignedID = (event = null) => {
        // console.log("HEYy", event)
        let disasterid = '';
        // console.log("This is the id taken--", id)
        if(event) {
            disasterid = event?._id;
            this.setState({ disasterID: event?._id, disasterName: event?.title?.en })
        } else {
            disasterid = this.state.disasterID
        }
        

        console.log("THis is disaster name---->>>", event?.title)
        const data = {
            "_id": disasterid
        }

        console.log("This is data", data)

        AdminService.httpPost("/data-import/disaster/get-assign-indicator", data).then((res) => {
            if (res) {

                let d = res.data;
                console.log("this data---", d)
                let finalarray = [];
                d?.forEach(element => {
                    console.log("This is response elementttsssss-", element)
                    finalarray.push(element)
                });
                console.log("This is response of get assign indicator-", finalarray)

                let finalArrayWithoutNullID = finalarray?.filter((x)=> x?._id != null)


                // setIsAssigned(finalarray)
                this.setState({ isAssigned: finalArrayWithoutNullID })

                // finalarray?.forEach(data => {
                //     this.setState({ selectedAssignedData: data?._id })

                // })

                // let id = [];
                // this.state.isAssigned?.forEach(data => {
                //     let d = data?._id
                //     id.push(d)
                // })

                // setData(id)
                // this.setState({selectedAssignedData: id})

                // if(this.state.isAssigned?._id === this.state.indicatorData?.iu_id){
                //     console.log("This is the selected indicatorss----->", this.state.isAssigned)

                //     this.setState({selectedAssignedData: this.state.isAssigned})

                // } else{
                //     console.log("errorrrrrrrrrrrr")
                // }


            }





        })

    }


    componentDidMount() {
        this.getData();
        this.getAllIndicators();
        adminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }

    callBack = () => {
        this.setState({
            modal: false,
            isEdit: false,
            editModeId: null
        });
        this.forceUpdate();
        this.getData();

    }
    /////open edit model function

    editMode(event, row_id) {
        console.log("callllllllll");
        event.preventDefault();
        this.setState({
            isEdit: true,
            modal: true
        });

        this.state.data.forEach((item) => {
            if (item._id == row_id) {
                this.setState({ editModeId: item });
            }
        });

        this.openEditModal()

    }
    
    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({
                modal: false,
                isEdit: false,
                editModeId: null
            })
        }

    }

    // openModal = () =>{

    //     if(this.state.modal==false){
    //         this.setState({ modal: true})
    //     }else{
    //         this.setState({ modal: false,
    //                         isEdit:false,
    //                         editModeId:null});
    //     }
    //     this.forceUpdate();
    // }

    // editMode (event, id) {
    //     event.preventDefault();

    //     this.state.data.forEach((item)=>{
    //         if(item._id==id){
    //             this.setState({isEdit:true, editModeId:item, modal: true});
    //             this.forceUpdate();
    //             return;
    //         }
    //     });

    //     // this.openAddModal();

    //     // $('#openDataModal').trigger('click');

    // }

    //////////=========checkbox delete============/////////

    // delete entry===========//
    deleteEntry = (id) => {

        swal({
            title: "Are you sure delete this?",
            // text: "Once deleted, you will not be able to recover this disaster!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        "_id": id
                    }

                    adminService.httpPost('/data-import/disaster/delete-disaster', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            console.log(res)

                            if (res.status == true) {
                                toast.success("Disaster deleted successfully");
                                this.getData();
                            }
                            else {
                                toast.error(res?.message);

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                        toast.error(err);
                    });
                }
            });






    }


    ////////////////get api///////////
    getData = async () => {
        this.setState({ loader: true })

        adminService.httpGet('/data-retrieval/disaster/getDisasterData').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
                console.log("get disaster", d)
                // d.forEach((ele  , index)=>{ele.index = index })
                this.setState({ filteredData: d, data: d })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });






    }


    //add and edit api/////

    saveDisasterData = (saveData) => {

        if (this.state.isEdit && saveData.id != null) {
            const data = {
                "id": saveData.id,
                "title": saveData.title,
                "description": saveData.description,
                
            }
            console.log("This is saveData---", data)

            adminService.httpPut('/data-import/disaster/update/' + saveData.id, data).then((res) => {
                if (res) {

                    let d = res.data;
                    if (res.status != 0 && res.success == true) {
                        this.setState({ loader: false })
                        toast.success("Disaster updated successfully");
                        this.openAddModal();
                        this.getData();
                    }

                    else {
                        this.getData();
                        this.openAddModal();
                        this.setState({ loader: false})
                        toast.error(res.message);

                    }

                }

            }).catch((err) => {
                this.setState({ loader: false })
                toast.error("Failed to update Disaster");
                console.log(err)
            });

        }
        else {
            const getdata = {
                "id": null,
                "title": saveData.title,
                "description": saveData.description,

            }



            adminService.httpPost('/data-import/disaster/add', saveData).then((res) => {
                if (res) {
                    console.log("This is response of disaster add", res)
                    let d = res.data;


                    if (res.success == true) {
                        this.setState({ loader: false })
                        toast.success("Disaster added successfully");
                        this.openAddModal();
                        this.getData();
                    }

                    else {
                        this.setState({ loader: false })
                        toast.error(res.message);

                    }
                }
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
        }


    }







    ///multiple delete

    handleChange = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];

        console.log("id===", ids)

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
        })
        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete this disaster?",
                // text: "Once deleted, you will not be able to recover this disaster",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        adminService.httpPost('/data-import/disaster/delete-multiple-disaster', data).then((res) => {

                            if (res) {
                                let d = res.data;
                                if (res.status == true) {
                                    toast.success("Disaster deleted successfully");
                                    this.getData();
                                }
                                else {
                                    toast.error("Failed to delete Disaster");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }

        else {
            swal({
                title: "No Record Selected",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }
    }


    // get all indicators===================//

    getAllIndicators = async () => {


        adminService.httpPost('/data-retrieval/disaster/get-indicator').then((res) => {
            if (res) {
                console.log("THIS IS GETALLINDICATOR----->", res)

                let d = res.data;



                this.setState({ indicatorData: d, rowIndData: d })
                console.log("indicator", this.state.indicatorData)



            }

        }).catch((err) => {

            console.log(err)
        });






    }


}

const mapStateToProps = (state) => ({
    token: state.login.token,
    lang: state.lang.lang
});

export default connect(
    mapStateToProps
)(Disaster);
