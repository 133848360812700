import { Component } from 'react'
import './home.css';
import * as jsx from './home.module.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";

class HomePage extends Component {
    constructor() {
        super()
        this.state = {
            fecData:[],
            resData:[],
            keyData: [],
            loader:false,
            data:[]
        }

        this.countUpRefs = [];
        this.starts = [];
    }
        
    render() {
        return jsx.default.bind(this)();
    }

    componentDidMount() {
        this.getResource();
        this.getFacilities();
        this.getkeyFact();
      
    }



    getFacilities(){
        const dataToBeSend = {}
        AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res)=>{
            if(res){
                this.setState({loader:false})
                let d = res.data;

   
                this.setState({fecData: d})
                console.log(this.state.fecData)
            }

}).catch((err) => {
    this.setState({ loader: false })
    console.log(err)
});
    }
   

    getResource (){
        const dataToBeSend = {}
        AdminService.httpGet('/data-retrieval/resources/get-all-resources', dataToBeSend).then((res)=>{
            if(res){
                this.setState({loader:false})
                let d = res.data;

   
                this.setState({resData: d, data:d})
           
            }

}).catch((err) => {
    this.setState({ loader: false })
    console.log(err)
});
    }


    ////key fects////

    
    getkeyFact(){
        
        // const dataToBeSend = [
        //     {
        //         "subgroup": "Total",
        //         "unit": "Number",
        //         "indicator": "Number of affected households"
        //     },
        //     {
                     
        //         "subgroup": "Total",
        //         "unit": "Number",
        //         "indicator": "Number of breastfeeding mothers affected"
        //     },
        //     {
        //         "subgroup": "Total",
        //         "unit": "Number",
        //         "indicator": "Number of old age (60+ years) in the household"
        //     },
            
        //     // {
        //     //     "subgroup": "Total",
        //     //     "unit": "Number",
        //     //     "indicator": "Number of destroyed houses"
        //     // }
        // ]

        const dataToBeSend = [
            {
                "subgroup": "Total",
                "unit": "Number",
                "indicator": "Number of affected households"
            },
            {
                "subgroup": "Total",
                "unit": "Number",
                "indicator": "Number of breastfeeding mothers affected"
            },
            {
                "subgroup": "Total",
                "unit": "Number",
                "indicator": "Number of old age (60+ years) in the household"
            }
        ];

        // let obj = { 'selectedIUS': dataToBeSend, 'areacode': 'ASITLS', disaster : 'null' }
        let obj = {
            "selectedIUS": dataToBeSend,
            "areacode": ["Timor-Leste", "Aileu", "Manufahi"],
            "disaster": [ "Landslide", "Flood", "Strong wind"]
        };

        AdminService.httpUserPost('/data-retrieval/data/get-selected-homekeyfact', obj).then((res)=>{
            if(res){
                // console.log("Sudhanshu res.data:: ", res.data)
                let d = res.data;
                d = d?.filter(x => x.value != null);
                d = d?.filter(x => (x.indicator.en == "Number of affected households" 
                                    && x.areaData.name.en == "Timor-Leste"
                                    && x.disasterData.en == "Flood") ||
                                    (x.indicator.en == "Number of breastfeeding mothers affected" 
                                    && x.areaData.name.en == "Aileu"
                                    && x.disasterData.en == "Flood") ||
                                    (x.indicator.en == "Number of old age (60+ years) in the household" 
                                    && x.areaData.name.en == "Manufahi"
                                    && x.disasterData.en == "Strong wind")
                            );
                let groupedD = d.reduce((group, data) => {
                    let ius_id = data.ius_id;
                    let area_code = data.areaData.area_code;
                    let disaster = data.disasterData.en;
                    group[""+ius_id+area_code+disaster] = group[""+ius_id+area_code+disaster] ?? [];
                    group[""+ius_id+area_code+disaster].push(data);
                    return group;
                }, {});
                let finalData = [];
                for(const [key, list] of Object.entries(groupedD)) {
                    // console.log("Sudhanshu groupedD:: ", list, groupedD);
                    finalData.push(list.sort((a,b) => 
                        a.timeperiod.localeCompare(b.timeperiod, { numeric: true })
                    )?.[0]);
                }
                // console.log("Sudhanshu finaldata:: ", finalData)
                this.setState({keyData: finalData, loader:false})
                // console.log("This is keydata here-->", this.state.keyData)
           
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

}
function mapStateToProps(state) {
    return {
      lang: state.lang?.lang
    }
 }


export default connect(mapStateToProps)(HomePage);