import { Component } from 'react'
import './indicator.css';
import * as jsx from './indicator.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import adminService from "../../../services/admin.service";
import { connect } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
class Indicator extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            isEdit:false,
            editModeId:null,
            modal:false,
            inddata:[],
            loader:false,
            allIndicatorList:null,
            allUnitList:null,
            allSubgroupDimensionList:null,
            allDimensionList:null,
            sectorSubsectorList:null,
            bulkDelete :[],
            goalTargetList:null
        }
        
    }

    onSearch = (val) => {
        this.setState({ searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => ((item.indicator && item.indicator.toLowerCase().includes(val.toLowerCase()))
                || (item.subgroup && item.subgroup.toLowerCase().includes(val.toLowerCase()))
                || (item.unit && item.unit.toLowerCase().includes(val.toLowerCase()))
            ));
        }
        this.setState({ filteredData : fd});
    }

     
    sortWithToolTip = (rowA, rowB) => {
        const a = rowA?.indicator;
        const b = rowB?.unit;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => row.index + 1,
        //     sortable: false,
        //     width:'50px'
        // },
      
        {
            name: 'Indicator',
            selector: row => <><div className='metaDataindicator'>{row.indicator} <span class="indicatorImg" data-toggle="modal" data-target="#indicatormetadata"  onClick={(e) => this.openIncPopup(e, row._id)}><img src="assets/lib/images/home/indicator.svg" /></span></div> </>,
            sortable: true,            
            width: "350px",
            sortFunction: this.sortWithToolTip
            
        },
        {
            name: 'Unit',
            selector: row => row.unit,
            sortable: true,
        },
        {
            name: 'Subgroup',
            selector: row => row.subgroup,
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: row => row.subgroupStatus?'Active':'Inactive',
        //     sortable: true,
        // },
        {
            name: 'Is Disaster Indicator',
            // selector: row => {console.log("This is isDisasterIndicator column console-->", row)},
            selector: row => row?.isDisasterIndicator === true ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Action',
            width:'200px',
            cell: row => 
                <>
                {/* <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.subgroup_id)}  > {row.subgroupStatus==0?<>Show</>:<>Hide</>}</a> 
                        <a class="dropdown-item" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a>
                        <a class="dropdown-item"  onClick={(e)=> this.removeRow(e, row.subgroup_id)} href="javascript:;">Delete</a>
                    </div>
                </div>  */}
                <Dropdown className='table-action'>
                    <Dropdown.Toggle id="dropdown-basic">
                        Select
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> this.removeRow(e, row.subgroup_id)} href="javascript:;">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];      

    componentDidMount() {
        this.getData();
        adminService.checkToken();
    }
    
    render() {
        return jsx.default.bind(this)();
    }

    callBack = () => {
        this.setState({ modal: false,
            isEdit:false,
            editModeId:null});
        this.forceUpdate();
        this.getData();
        
    }

    openModal = () =>{
        
        if(this.state.modal==false){
            this.setState({ modal: true})
        }else{
            this.setState({ modal: false,
                            isEdit:false,
                            editModeId:null});
        }
        this.forceUpdate();
    }


    openIncPopup (event, id) {
        event.preventDefault();
        
        this.state.data.forEach((item)=>{
            if(item._id==id){
                this.setState({ inddata:item});
                this.forceUpdate();
                return;
            }
        });
    }

    editMode (event, id) {
        event.preventDefault();
        
        this.state.data.forEach((item)=>{
            if(item._id==id){
                this.setState({isEdit:true, editModeId:item, modal: true});
                this.forceUpdate();
                return;
            }
        });

        // this.openAddModal();
        
        // $('#openDataModal').trigger('click');
       
    }

  //////////=========checkbox delete============/////////

  deleteProducts = (id) =>{

    
    swal({
        title:"Are you sure you want to delete?",
        text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if(willDelete) {
            const data= {
                "selectedIds":  this.state.checkedBoxes
            }
            adminService.httpPost('/data-import/ius/delete-multiple-indicator', data).then((res) => {

                if (res) {
                    this.setState({ loader: false })
                    let d = res;
        
                    console.log(res)
        
                    if(res.status == 1){
                        toast.success("Indicator/s deleted successfully");
                        this.getData();
                    }
                    else{
                        toast.error(res.message);
        
                    }
               
                }
        
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
          }                
        });     
    }





    changeStatus(event, id, ind_no) {
        let statusData = { "iuid": id, "iusid": ind_no};
        fetch(constants.server+"api/data-retrieval/ius/change-indicator-status", {
            method: 'POST',
            body: JSON.stringify(statusData),
            headers: {
                'Authorization': this.props.token, 
                'Content-type': 'application/json; charset=UTF-8',
             },
        })
        .then((res) => res.json())
        .then((data) => {
            this.getData();
            toast.success("Status updated successfully");
        })
        .catch((err) => {
            toast.error("Error in status update");
        });
    }

    removeRow(event, id) {
        event.preventDefault();
        
        swal({
            title:"Are you sure you want to delete?",
            text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if(willDelete) {
                let data = { "ius_id" : id};
                const req = adminService.httpPost('/data-import/ius/delete-indicator', data).then((res)=> {
                    if(res.status == 0) {
                        toast.error(res.message);
                    } else {
                        this.getData();
                        toast.success("Indicator deleted successfully");
                    }
                  
                }).catch(err => {
                  toast.error("Error in deleting indicator");
                });
              }                
            });            
    }

    getData = () => {
        this.setState({ loader: true, searchText: "" });
        
        fetch(constants.server+"api/data-retrieval/ius/get-indicator", { 
            method: 'post', 
            headers: new Headers({
                'Authorization': this.props.token, 
                'Content-Type': 'application/json'
                }),
            body: null
            })
            .then((res) => res.json())
            .then((data) => {
                
                let d = data.rowData;
                let allIndicatorList = data?.formData?.indicator;
                let allUnitList = data?.formData?.unit;
                let allSubgroupDimensionList = data?.formData?.sbgroup_dimension;
                let allDimensionList = data?.formData?.all_dimension;
                let sectorSubsectorList = data?.formData?.sectorSubsectorList;
                let goalTargetList = data.formData?.goalTargetList;
                goalTargetList = goalTargetList?.sort((a, b) => a.level_name.localeCompare(b.level_name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }));
                d.forEach((ele  , index)=>{ele.index = index })
                console.log("Sudhanshu data:", d);
                this.setState({ data: d, 
                                filteredData: d, 
                                allIndicatorList: allIndicatorList,
                                allUnitList: allUnitList,
                                allSubgroupDimensionList: allSubgroupDimensionList,
                                allDimensionList: allDimensionList,
                                sectorSubsectorList: sectorSubsectorList,
                                goalTargetList: goalTargetList,
                                loader: false
                            });
                
            })
            .catch((err) => {
                this.setState({ loader: false });
                toast.error(err.message);
            });        
    }


///multiple delete

handleChange = ({ selectedRows }) => {
  
    this.setState({bulkDelete:selectedRows})

  };
  
  

    handleChangeData = ({ toggleCleared }) => {
        this.setState({bulkDelete:toggleCleared})

  };
  




  

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) =>{
let ids = [];

this.state.bulkDelete.forEach(item=>{
    ids.push(item._id)
})
if(ids?.length > 0){
    
            swal({
                title:"Are you sure you want to delete?",
                text: "",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if(willDelete) {
                    const data= {
                        "_id": ids.toString()
                    }
                    adminService.httpPost('/data-import/ius/delete-multiple-indicator', data).then((res) => {
        
                        if (res) {
                            this.setState({ loader: false })
                            let d = res;
                
                            console.log(res)
                
                            if(res.status==1){
                                toast.success("Indicator deleted successfully");
                                this.getData();
                            }
                            else{
                                toast.error(res.message);
                
                            }
                       
                        }
                
                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                  }                
                });     
            }
        
            else{
                swal({
                    title:"No Record Selected",
                    text: "Please select at a record to delete.",
                      icon: "warning",
                      button: true,
                      
                    })
            
                    return false;
            
            
            }
        }            



}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
  )(Indicator);
