
import React, { useEffect, useRef, useState } from "react";
import './modal.css'

import "react-toastify/dist/ReactToastify.css";
import { isEmptyObject } from "jquery";
import $ from 'jquery';

import { omit } from 'lodash'
import Select from 'react-select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';




import Autocomplete from '@mui/material/Autocomplete';

function AddDisasterModal(props) {
  const form = useRef()
  const [error, setError] = useState('');

  const [state, setState] = useState({
    "id": '',
    "title": '',
    "description": '',
  });



  useEffect(() => {
    console.log("This is props edit mode----> ", props.editMode)
    if (props.isEdit) {
      setState({

        "id": props.editMode?._id,
        "title": props.editMode?.title.en,
        "description": props.editMode?.description.en,

      });
    } else {

      setState({
        "id": '',
        "title": '',
        "description": '',

      });
    }

  }, [props]);






  const inputhandle = (e) => {
    let indicator, unit


    indicator = e.target.indicator;
    unit = e.target.unit;
    e.persist();
    validate(e, indicator, unit);

    //Let's set these values in state
    setValues({
      ...values,
      [indicator]: unit,
    })
  };



  const [values, setValues] = useState({});
  //Errors

  const validate = (e, indicator, value) => {
    //A function to validate each input values



  }

  //console.log("test",props.indicatorData)

  // ====================================================== Form Validation Start



  const handleSubmit = (e) => {
    e.preventDefault();

    const {id, title, description} = state;

      const data = {
        id: id,
        title: title,
        description: description,
      };

      props.parentCallback(data);



      // const data = {
      //   "id": state.id,

      //   "title": title,
      //   "description": description,
      // }
      
      // props.parentCallback(data);


    //  console.log('mt',data)

  }



  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });


  }

  // ====================================================== Form Validation End



  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }


  return (
    <>
      <div className="user-form">
        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit} id="addUserForm">
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            {/* <form onSubmit={(e)=>updateData(e)} noValidate="" style={{ 'position': 'relative' }} id="addUserForm" className="ng-untouched ng-pristine ng-invalid" > */}
            <div className="gallery-collections mt-0 metadata_form">

              <div className="row mb-30">
                <div className="col-md-3 pt-2">
                  <span className="mat-form-field-label-wrapper">
                    <label>Title</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <TextValidator name="title" className="w-100"
                        onChange={(e) => { handleChange(e) }} value={error ? '' : state?.title}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-30">
                <div className="col-md-3 pt-2">
                  <span className="mat-form-field-label-wrapper">
                    <label>Description</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style descriptionInput">
                    <div className="mat-form-field-wrapper">
                      <TextValidator autoComplete="off" name="description" multiline rows={3} className="w-100"
                        onChange={(e) => { handleChange(e) }} id="description" value={state?.description}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                <Button variant="contained" className="addbtn" type="submit">
                  {props.isEdit ? 'Update ' : 'Add '}
                </Button>
              </div>
            </div>

            {/* <div className="gallery-sidebar-button text-center new_gallery-sidebar-button">
                <button type="submit"  className="btn btn-danger mdsubmitBtn" > {props.isEdit? 'Update': 'Add' }</button>
            
                
                  

                  
                </div> */}
            {/* </form>  */}
          </Stack>
        </ValidatorForm>

      </div>
    </>
  );

}
export default AddDisasterModal;