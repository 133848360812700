import React, { useEffect, useState, useRef } from 'react'
import login from '../../pages/login';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { toast } from "react-toastify";
import adminService from "../../../services/admin.service";
import swal from 'sweetalert';

function AddRegisterNowModal(props) {



  // swal({  
      
  //   title: 'Welcome to',
  //   text: 'Waste Management Information System',
  //   className: "swal-welcome-msg",
  //   content: 'I will close in <b></b> milliseconds.',
  //   confirmButtonText: "Ok",
       
  // })

  const regForm = useRef();

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const handleClose = ()=> {
    setShow(false);
    props.isModal(false);
  }
  const handleShow = () => {
 
    $(document).ready(function(){
      $('#homeUserProfile').trigger('click');
    })

    setShow(true); props.isModal(true);
  }

  useEffect(()=> {
    setEmail("")
    setPassword("")
    setConfirmPassword("")
  }, [show]);
  useEffect(()=>{
    ValidatorForm.addValidationRule('passwordLength', (value) => {
      if (password.length < 8) {
          return false;
      }
      return true;
  });
  }, [password])

  useEffect(()=>{
    ValidatorForm.addValidationRule('passwordMismatch', (value) => {
      if (value !==password) {
          return false;
      }
      return true;
  });
  }, [confirmPassword])

  const makeid = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  const regSubmit = (e) => {
    console.log("hi")
    e.preventDefault();
    
    if(confirmPassword !== password){
      setShow(true);  
      return;
    }

    let passwordString = makeid(10) + password
    var password10 = btoa(passwordString);
    
    const data = {
      email: email,
      password: password10
    }
    
    adminService.httpUserPost('/data-import/users/register', data).then((res) => {  

      if (res.success === true) {
        setShow(false);

        swal({
          title: 'A verification mail is sent to your email ID. Please confirm to complete the registration.',
          html: '<img src="assets/lib/images/icon/registration-icon.svg">',
          icon: "warning",
          className: "swal-back",
          // "swal-back swal-register",
          showCancelButton: false,
        }).then((ok)=>{
          if(ok){
              // this.setState({
              //     loader: true,
              //     name: "",
              //     email: "",
              //     message: ""
              // })
          }
      })
      } 
       else {
        toast.error(res.message)
      }

    }).catch(error => {
      toast.error(error.message)
    })

    

    // return fetch(`${constants.server}/api/data-import/users/register`, {
    //   method: "POST",
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     email: email,
    //     password: password,
    //     confirmPassword: confirmPassword
    //   })
    // }).then((response) => handleResponse(response))
    //   .catch((error) => {
    //     console.error(error);
    //     throw new Error(error)
    //   })
  }


  //   async function registration() {
  //     let formError = "";

  //     let that = this;
  //     console.log("dsgdfS");
  //     // console.log("email---" , email);
  //     const data =  await  fetch(constants.server+"api/data-import/users/register",{

  //       method: 'POST', 
  //       body: JSON.stringify({
  //         email: email,
  //         password: password,
  //         confirmPassword: confirmPassword
  //     }),
  //     headers: {
  //         'Content-type': 'application/json; charset=UTF-8',
  //      },
  //     })
  //     .then((res) => res.json())
  //     .then((data) => {
  //         if(data?.token) {
  //           that.state.loggedIn = true;


  //           history.push('/login');
  //         } else {
  //           toast.error(formError);
  //         }            
  //     })
  //     .catch((err) => {

  //         formError = err.message;
  //         that.setState({formErrors: {error: formError}});
  //         toast.error(formError);
  //     });
  // }

  // useEffect(() => {
  //   ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
  //     console.log(value, password)
  //     if (value !== password) {
  //       return false;
  //     }
  //     else if(value == password){
  //       // ValidatorForm.removeValidationRule('isPasswordMatch');
  //       return true;
  //     }
  //   });

  // }, [])


  return (
    <>

      <a variant="primary" className="pointer fog-pass " onClick={handleShow}>Register Here</a>
      <Modal
        show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='register_popup'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Registration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="register_modal_body">
          <ValidatorForm
          ref = {regForm}
            onSubmit={(e)=>{regSubmit(e)}}

          >

            <label className='register_label'>Email</label>
            <TextValidator
              fullWidth
              // label="Email"
              onChange={(e) => { setEmail(e.target.value) }}
              name="email"
              value={email}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'email is not valid']}
              className="register_input"
              //placeholder="Email"
            />
            <label className='register_label'>Password</label>
            <TextValidator
              fullWidth
              // label="Name"
              onChange={(e) => { setPassword(e.target.value) }}
              name="password"
              value={password}
              validators={['passwordLength','required']}
              errorMessages={['Min Password length: 8','This field is required']}
              className="register_input"
              //placeholder="Password"
              type="password"
            />
            <label className='register_label'>Confirm Password</label>
            <TextValidator
              fullWidth
              // label="Name"
              onChange={(e) => { setConfirmPassword(e.target.value) }}
              name="confirm password"
              value={confirmPassword}
              validators={['passwordMismatch','required']}
              errorMessages={['Password Does Not Match','This field is required']}
              className="register_input"
              //placeholder="Confirm Password"
              type="password"
            />

            {/* <Box sx={{ zIndex: 999999999 }}>
      <FormControl fullWidth >
      <label className='register_label'>Register as</label>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
      
          label="Age"
      
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty
Registe</MenuItem>
        </Select>
      </FormControl>
      </Box>  */}
      
            <Button
              className='mt-4'
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
            >
              Register <img src="assets/lib/images/white_arrow.svg"></img>
            </Button>
          </ValidatorForm>

          <div className="bottom_link d-flex align-items-center justify-content-center">
              Already have an account? <span data-toggle='modal' data-target='#login' data-dismiss='modal' onClick={handleClose}>Login</span>           
          </div>

        </Modal.Body>
      </Modal>


    </>
  )
}



export default AddRegisterNowModal;