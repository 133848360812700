
import React, { useCallback, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
// ======================================================= Tab Start
import PropTypes from 'prop-types';
import $ from 'jquery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// ======================================================= Tab End
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// ======================================================= Dropdown End
import Dropdown from 'react-bootstrap/Dropdown';
// ======================================================= Dropdown End
// ======================================================= Modal start
import Modal from 'react-bootstrap/Modal';
// ======================================================= Modal End
// ======================================================= Table Start
import Table from 'react-bootstrap/Table';
// ======================================================= Table End
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
// ======================================================= Checkbox Start
import Checkbox from '@mui/material/Checkbox';
// ======================================================= Checkbox End
// ======================================================= Datatable Start
import FixedHeaderStory from 'react-data-table-component';
// ======================================================= Datatable End
import Tooltip from '@mui/material/Tooltip';

import AdminService from '../../services/admin.service';
import RecursiveTreeView from "../pages/manage-form/area";

// ======================================================== Multiselect Dropdown Start
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Stack } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import swal from 'sweetalert'
// ======================================================== Date
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toDate } from "date-fns";
// ======================================================== Date

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'JPG',
  'PNG',
];

const document = [
    'PDF',
    'Doc',
  ];


  var  areaSelected =[]
// ======================================================== Multiselect Dropdown End

const ManageFormModal = (props) => {


// console.log("data--->" , props.editModeId);
    $('.tabsection1').on('click', function(e){
        e.stopPropagation()
        e.preventDefault()
        $('.tabSection2').addClass('d-none')
        $('.tabSection1').removeClass('d-none')
    })

    $('.tabsection2').on('click', function(e){
        e.stopPropagation()
        e.preventDefault()
        $('.tabSection1').addClass('d-none')
        $('.tabSection2').removeClass('d-none')
    })

    const form = useRef()
    const [copyForm, setCopyForm] = useState({
        target:'',
        copy:''

    })
    const [FormState, setFormState] = useState(
    {
      'id': '',
      'formName':'',
      'description': '',
      'assigned_user': [],
      disaster_type:''
    });


    const assignedAreasToUser = JSON.parse(localStorage.getItem('userInfo')).area
    const role = JSON.parse(localStorage.getItem('userInfo')).role

    const [areaData,setAreaData] = useState([])
    const [users,setUsers] = useState([])
    const [approvers,setApprovers] = useState([])
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    // sort by  property ASCENDING And DESCENDING (A - Z / Z - A) //
    const [sortOrderArea,setSortOrderArea] = useState(true)
    const [sortOrderUser,setSortOrderUser] = useState(true)
    const [areaSearchValue,setAreaSearchValue] = useState('')
    const [userSearchValue,setUserSearchValue] = useState('')
    const [areaAssignSearch,setAreaAssignSearch] =useState('')



    const [formTypeApprove,setFormTypeapprove] = useState(false)

    const setAssignUsersToForm = ()=>{
        let preValue = []
        // console.log(assignTableData);


        formTypeApprove?
        assignTableData.forEach(row=>{
           if(preValue.length>0 && preValue.some(user=> user.user_id == row.dataEntry._id) ){
            preValue.forEach(data=>{
                if(data.user_id==row.dataEntry._id){
                    data.area_code.push(row.area.area_code)
                }
            })
           }else{
            preValue.push({
                area_code:[row.area.area_code],
                user_id:row.dataEntry._id,
                dataapprove_user:row.approver._id
            })
           }
        })
        :
        assignTableData.forEach(row=>{
           if(preValue.length>0 && preValue.some(user=> user.user_id == row.approver._id) ){
            preValue.forEach(data=>{
                if(data.user_id==row.approver._id){
                    data.area_code.push(row.area.area_code)
                }
            })
           }else{
            preValue.push({
                area_code:[row.area.area_code],
                user_id:row.approver._id
            })
           }
        })
        // console.log(preValue);
        formFilling(preValue,'assigned_user')
        
    }
    useEffect(()=>{
        handleClearRows()
        // console.log(props);
        if(props.activeForm === "New survey"){
            setFormTypeapprove(true)
        }else{
            setFormTypeapprove(false)
        }
        setAreaData(props.areaData)
        setUsers(props.users)
        setApprovers(props.approvers)
        // console.log(props, props.editModeData);
        if(props.isEdit ==true){
            setSurveyEditMode(true)
            // console.log("data-------" , props);
            setFormState({
                // 'id' : props.editModeData._id,
                // 'formName':props.editModeData.name,
                // 'description':props.editModeData.description,
                // 'assigned_user': props.editModeData.assigned_user

                'form_id':props.formId,
                'formName':props.activeForm,
                'description':props.editModeData==null?'':props.editModeData.description?.en,
                'disaster_type':props.editModeData==null?'':props.editModeData.disaster_type,
                'survey_id':props.surveyId,
                'assigned_user':props.editModeData==null?[]:props.editModeData.assigned_user,
                'from_date':props.editModeData==null?`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`:props.editModeData.from_date,
                'to_date':props?.editModeData?.to_date??`${tomorrow.getFullYear()}-${tomorrow.getMonth()+1}-${tomorrow.getDate()}`
            })

            // setTimeFormatValue(props.editModeData.time_period_format)
            // getQuestions(props.editModeData._id)
            // get_mangage_section()
            let fromDate =props.editModeData==null? today:new Date(props.editModeData.from_date)
            let toDate =  props.editModeData==null?tomorrow:new Date(props.editModeData.to_date)
            setFromdate(fromDate)
            setTodate(toDate)
            if(props.editModeData!=null){
                console.log(props.editModeData.assigned_user)
                let areas = []
                let tableData = []
                formTypeApprove?


                props.editModeData.assigned_user.forEach(preUser=>{
                    areas.push(...preUser.area_code)
                    let approver = {}
                    let area = {}
                    let dataEntry = {}
                    users.forEach(y=>{
                        // console.log(y._id,preUser.user_id);
                        if(y._id==preUser.user_id){
                            approver = y
                        }

                        preUser.area_code.forEach(areaCodes=>{
                            areaData.forEach(x=>{
                                if(areaCodes == x.area_code){
                                    area = x
                                }
                            })
                        })
                    })
                    tableData.push({approver:approver,area:area})
                }):
                props.editModeData.assigned_user.forEach(preUser=>{
                    areas.push(...preUser.area_code)
                    let approver = {}
                    let area = {}
                    users.forEach(y=>{
                        // console.log(y._id,preUser.user_id);
                        if(y._id==preUser.user_id){
                            approver = y
                        }
                    })
                    preUser.area_code.forEach(areaCodes=>{
                        areaData.forEach(x=>{
                            if(areaCodes == x.area_code){
                                area = x
                            }
                        })
                        tableData.push({approver:approver,area:area})
                    })
                })
                // console.log("dataValidate: ", tableData);
                setAssignTableData(tableData)
                setFinalSelectedAreas(areas)
            }

        }else{
            let namePlan=props.isEdit == false ? "":"plan"
            let pname = namePlan + props.activeForm+' '+`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
            setSurveyEditMode(false)
            setFormState({
             
                'form_id':props.formId,
                // 'formName':'Plan '+props.activeForm,
                'formName':pname,
                'description':'',
                'disaster_type':'',
                'survey_id':props.surveyId,
                'assigned_user':[],
                'from_date':`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`,
                'to_date':`${tomorrow.getFullYear()}-${tomorrow.getMonth()+1}-${tomorrow.getDate()}`

            })
            let fromDate =`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
            let toDate = `${tomorrow.getFullYear()}-${tomorrow.getMonth()+1}-${tomorrow.getDate()}`
            setFromdate(fromDate)
            setTodate(toDate)
            setAssignTableData([])
            setFinalSelectedAreas([])

            // if(props.editModeData!=null){
            //     let areas = []
            //     let tableData = []
            //     props.editModeData.assigned_user.forEach(preUser=>{
            //         areas.push(...preUser.area_code)
            //         let user = {}
            //         let area = {}
            //         users.forEach(y=>{
            //             // console.log(y._id,preUser.user_id);
            //             if(y._id==preUser.user_id){
            //                 user = y
            //             }
            //             preUser.area_code.forEach(areaCodes=>{
            //                 areaData.forEach(x=>{
            //                     if(areaCodes == x.area_code){
            //                         area = x
            //                     }
            //                 })
            //             })
            //         })
            //         tableData.push({user:user,area:area})
            //     })
            //     // console.log(tableData);
            //     setAssignTableData(tableData)
            //     setSelectedAreas(areas)



            // }
        }



        if(props.copyForm){

            setCopyForm({...copyForm, target:props.copyForm.name})

        }

        
    } ,[props])

    
    const [timeFormtValue ,setTimeFormatValue] = useState(0); 
    const [timeFormat ,setTimeFormat] = useState([
        { label: "YYYY", value: "YYYY" },
        { label: "YYYY.MM", value: "YYYY.MM" },
        { label: "YYYY.MM.DD", value: "YYYY.MM.DD" },
        { label: "YYYY - YYYY", value: "YYYY - YYYY" },
        { label: "YYYY.MM - YYYY.MM", value: "YYYY.MM - YYYY.MM" },
        { label: "YYYY.MM.DD - YYYY.MM.DD", value: "YYYY.MM.DD - YYYY.MM.DD" }
    ]);

    const [timeValue ,setTimeValue] = useState(0); 
    const [timeSelect ,setTime] = useState([
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
        { label: "2025", value: "2025" }
    ]);




    const submitCopyForm = (e) =>{

        e.preventDefault();

        const data = {
            "from_survey_id": props.copyForm._id,
            "survey_name": copyForm.copy
        }

        props.saveCopyForm(data);

    }

// ======================================================= Tab Start
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }      
    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    };      
    function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
    }
    const [value, setValue] = useState(0);
    const [assigned_list ,setassigned_list ] = useState([])
    const handleChange = (event) => {
        // console.log("ev: ",event.target.value )
        // // setValue(newValue);
        // console.log('abc', FormState)
        // console.log('abc1', FormState.errors)
        
        if(event.target.name=='time_period_format'){
            let selectedVal = event.target.value;
            setTimeFormatValue(selectedVal);
            // setFormState({ ...FormState, [event.target.name]: selectedVal });
          }else if(event.target.name=='time_period'){
            let selectedVal = event.target.value;
            setTimeValue(selectedVal);
            // setFormState({ ...FormState, [event.target.name]: selectedVal });
          }
          else{
            // setFormState({ ...FormState, [event.target.name]: event.target.value });
          }
    };

   
// ======================================================= Tab Start
// ======================================================= Dropdown Start
    const [format, setFormat] = useState('');
    const [section, setSection] = useState([])
    const [mandatory  ,setmandatory] = useState('')

    const [year, setData] = useState('2023');
    const [month, setMonth] = useState('01');
    const [day, setDay] = useState('01');
    const [indicatorUnit,setIndicatorUnit] = useState()
    const [subgroupList,setSubgroupList] = useState()
     const [subgroupid,setSubgroupid] = useState()
     const [iuid,setiuid] = useState()
    const [subgroup2,setSubgroup] = useState()
    const [qustion_text , setqustion_text ] = useState()
    const [dateFormat,setDateFormat] = useState()
    const [defaultValue  ,setdefaultValue] = useState('')
    
    const [miniValue  ,setminiValue] = useState('')
    const [decimal, setDecimal] = useState("");
    const [maxvalue  ,setmaxvalue] = useState('')
    const [hint , setHint ] = useState('')
    
    const [sizeInImage , setsizeInImage] = useState()



    const [row,setRow] = useState('1')
    const [column,setColumn] = useState('1')
    const [gridColumns,setGridColumns] = useState({
        1:'A',
        2:'B',
        3:'C',
        4:'D',
        5:'E'
    })
    const [gridRows,setGridRows] = useState({
        1:'V',
        2:'W',
        3:'X',
        4:'Y',
        5:'Z'
    })

    const [tempGridNames,setTempGridNames] = useState({
        1:'A',
        2:'B',
        3:'C',
        4:'D',
        5:'E'
    })
    const [gridQuestions,setGridQuestions] = useState({})
    const [activeGridQuestion, setActiveGridQuestion] = useState({type:'',indicatorUnit:'',subgroup:'',numeric:{default:0,minimum:0,maximum:0},singleChoice:{selectedOption:[],iusMap:{}}}) 
    const [activeGrid,setActiveGrid] = useState()
    const [gridSubgroups,setGridSubgroups] = useState([])
    const [gridSelectedOption,setGridSelectedOption] = useState([])
    const [gridOptionMapping,setGridOptionMapping] = useState([])


    const checkGridValidation = (key)=>{
        // console.log(gridQuestions[key]?.type);
        if(gridQuestions[key]?.type==''){
            return false
        }else if(gridQuestions[key]?.type == 'Textbox'){
            if(gridQuestions[key]?.indicatorUnit=='' || gridQuestions[key]?.subgroup==''){
                return false
            }else{
                return true
            }
        }else if(gridQuestions[key]?.type == 'Numeric'){
            if(gridQuestions[key]?.numeric.default<1 || gridQuestions[key]?.numeric.default>199 || gridQuestions[key]?.numeric.minimum<1 || gridQuestions[key]?.numeric.minimum>199 || gridQuestions[key]?.numeric.maximum<1 || gridQuestions[key]?.numeric.maximum>199  ){
                return false
            }else if(gridQuestions[key]?.indicatorUnit=='' || gridQuestions[key]?.subgroup==''){
                return false
            }else{
                return true
            }
        }else if(gridQuestions[key]?.type == 'Single Choice'){
            if(gridQuestions[key]?.singleChoice?.selectedOption.length==0){
                return false
            }else{
                let status = true
                gridQuestions[key]?.singleChoice?.selectedOption.forEach(x=>{
                    if(gridQuestions[key]?.singleChoice?.iusMap[x?.name]?.indicatorUnit == ''  || gridQuestions[key]?.singleChoice?.iusMap[x?.name]?.subgroup == ''  ){
                        status = false
                    }
                })
                return status 
            }
        }
    }
    

    const changeNumericValue = (key,value)=>{
        let preValue = activeGridQuestion
        preValue.numeric[key] = value
        // console.log("changeNumericValue:: ", preValue, value)
        setActiveGridQuestion({...activeGridQuestion,...preValue})
    }
    const changeSelectedOption = ({selectedRows})=>{
        setGridSelectedOption(selectedRows)
    }
    const confirmGridSelectedOption = ()=>{
        let preValue = activeGridQuestion
        preValue.singleChoice.selectedOption = gridSelectedOption
        gridSelectedOption.forEach(x=>{
            preValue.singleChoice.iusMap[x.name] = {
                indicatorUnit:'',
                subgroup:''
            }
        })
        // console.log(gridSelectedOption);
        setActiveGridQuestion({...preValue,...activeGridQuestion})
    }
    const changeGridMapIndicator = (value,key)=>{
        let preValue = activeGridQuestion
        preValue.singleChoice.iusMap[key].indicatorUnit = value
        // console.log(key,value,preValue);
        setActiveGridQuestion({...preValue,...activeGridQuestion})
    }
    const changeGridSubgroup = (value, key)=>{
        let preValue = activeGridQuestion
        preValue.singleChoice.iusMap[key].subgroup = value
        setActiveGridQuestion({...preValue,...activeGridQuestion})
    }
    const changeSingleChoiceValue = ()=>{
        
    }
    const changeActiveQuestion = (key,value)=>{
        let preValue = activeGridQuestion
        preValue[key] = value
        if(key=='indicatorUnit'){
            preValue.subgroup = ''
        }
        // console.log("changeActiveQuestion:: ", preValue, value)
        setActiveGridQuestion({...activeGridQuestion,...preValue})
    }

    const saveGridQuestion = ()=>{
       let preValue = gridQuestions
       preValue[activeGrid] = activeGridQuestion
    //    console.log("saveGridQuestion:: ", gridQuestions, activeGridQuestion)
       setGridQuestions({...gridQuestions,...preValue})
    }

// ======================================================= Dropdown End
// ======================================================= Datepicker Modal Start
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   const  [userchecked , setuserchecked] = useState(false)
   const [questionData  , setQuestionData] = useState([])
// ======================================================= Datepicker Modal End
// ======================================================= Data Table Start
const columns = [
    {
        name: 'Area',
        selector: row => row.area.name,
        sortable: true,
    },
    
    ...formTypeApprove?  [ {
                name: 'Data Entry',
                selector: row =>row.dataEntry.name, 
                sortable: true,
            }]:[],

    {
        name: formTypeApprove?"Data Approver":"Data Entry",
        selector: row => <Tooltip title={row.approver.name} placement="top">
        <p>{row.approver.name}</p>
        </Tooltip>, 
        sortable: true,
    },
    {
        name: 'Action',
        width: "200px"   ,
        cell: row => 
                <>
                    <img src="assets/lib/images/manage-form/trashDlt.svg" onClick={()=>{deleteClick(row)}} />
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        
    },
];

const [assignTableData,setAssignTableData] =  useState([])


// console.log("questionData" , questionData);
const data1 =questionData
const [manageSection , setmanageSection] = useState()
const [getmanageSection , setgetmanageSection] = useState([])
const [manageOption , setmanageOption] = useState()
const [getmanageOption , setgetmanageOption] = useState([])
const updateOption = ()=>{
    let obj = {
        name:activeOption.name
    }
    // console.log(activeOption);
    AdminService.httpPut(`/data-import/option/update/${activeOption._id}`,obj).then(x=>{
        toast.success(x.message)
        get_mangage_option()
    }).catch(err=>{
        toast.error('Something went wrong')
    })
}

const deleteOption = (row)=>{;
    // console.log(row);
    AdminService.httpPost(`/data-import/option/delete`,{id:row._id}).then(x=>{
        toast.success(x.message)
        get_mangage_option()
    }).catch(err=>{
        toast.error('something went wrong')
    })
}
const deleteMultiOption = ()=>{
    let ids = []
    selectedOption.forEach(x=>{
        ids.push(x._id)
    })
    AdminService.httpPost(`/data-import/option/selected/delete`,{id:JSON.stringify(ids)}).then(x=>{
        toast.success(x.message)
        get_mangage_option()
    }).catch(err=>{
        toast.error('something went wrong')
    })
}
const managecolumns = [
    {
        name: '#',
        selector: row => (row.question +1),
        sortable: true,
    },
    {
        name: 'Section',
        selector: row => row.section,
        sortable: true,
    },    
    {
        name: 'Action',
        width: "200px"   ,
        cell: row =>
            <>
                <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item"  href="javascript:void(0);" data-toggle="modal" data-target="#editsection">Edit</a>
                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                    </div>
                </div>
            </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        
    },
];



const managecolumnsOption = [
    {
        name: '#',
        selector: row => (row.question+1),
        sortable: true,
    },
    {
        name: 'Option',
        selector: row => row.option,
        sortable: true,
    },    
    {
        name: 'Action',
        width: "200px"   ,
        cell: row =>
            <>
                <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" onClick= {()=>{{setActiveOption(row)}}}   href="javascript:void(0);" data-toggle="modal" data-target="#editsection" >Edit</a>
                        <a class="dropdown-item" onClick={()=>{deleteOption(row)}} href="javascript:void(0);">Delete</a>
                    </div>
                </div>
            </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        
    },
];
const managedata = getmanageSection





// ======================================================= Data Table End

// ======================================================= Multiselect Dropdown Start

const [personName, setPersonName] = useState([])

const [userdata , setuserdata] = useState([])
const [area  , setarea] = useState([
    {
        checkbox : false,
        val :'Chhattisgarh',
        disable: false
        
    },
    {
        checkbox : false,
        val :'Delhi',
        disable: false

    },
    {
        checkbox : false,
        val :'Panjab',
        disable: false

    },
    {
        checkbox : false,
        val :'new Delhi',
        disable: false

    },
    {
        checkbox : false,
        val :'himachal pradesh',
        disable: false

    }

])

// disable_bg


const [selectedTableRows,setSelectedTableRows] = useState([])
const handleTable = ( selectedtableRows ) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', selectedtableRows.selectedRows);
    setSelectedTableRows([...selectedtableRows.selectedRows])
};
const [userSelected , setuserSelected] = useState([])

const [approver,setApprover] = useState({})

// console.log('userSelected',userSelected);
const selectDataEntryUsers = (action,user)=> {
    let preData = userSelected
    // console.log(user);
    if(action){
        preData.push(user)
    }else{
       preData =  preData.filter(x=> x._id != user._id )
    }
    // console.log(preData);
    setuserSelected([...preData])

    

}
const deleteAssignedAreas = ()=>{
    let preValue = assignTableData
    selectedTableRows.forEach(x=>{
        let index = preValue.indexOf(x)
        if(index!=-1){
            preValue.splice(index,1)
        }
        
    })
    setSelectedTableRows([])
    setAssignTableData([...preValue])
    handleClearRows()
    
}
const deleteClick =(row)=>{
    let preValue = assignTableData
    let index = preValue.indexOf(row)
    if(index!=-1){
        preValue.splice(index,1)
    }
    setAssignTableData([...preValue])
}
// ---------------------------







const setFormData = () => { 
    
    
    let obj  = {
        form_id: props.formId,
        name: FormState.formName??props.activeForm,
        description: FormState.description,
        disaster_type: FormState.disaster_type,
        from_date: FormState.from_date,
        to_date: FormState.to_date,
        survey_id: FormState.survey_id,
        assigned_user: FormState.assigned_user,
        status: 1,
    }
    // console.log(obj)
    // console.log(props,FormState,"props,FormState")
    // return false
    if(surveyEditMode) {
        let editSId = props.editModeData._id  /////FormState.id
        AdminService.httpPut(`/data-import/survey/updateForm/${editSId}` ,obj ).then((res)=>{
            // console.log('update->' , obj);
            if(res.success==true){
                toast.success(res.message)
                props.closeFormModal();
            }
        
        })
    } else {
        AdminService.httpPost('/data-import/survey/add-manage-form' ,obj ).then((res)=>{
            // console.log('check->' , obj);
            if(res.status){
                toast.success(res.message)
                props.closeFormModal();
            }else{
                toast.error(res.message)

            }
        
        })
    }
}


const onFormSubmit = (event) => {
    event.preventDefault();
    // console.log("FormState:: ", FormState)
  }



// ======================================================= Multiselect Dropdown Start


  const handleDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );



  };


  const [age, setAge] = useState('');
  const [searchSeaction   ,setseachSection] = useState('')
  const [indiUnitSearch,SetIndiUnitSearch] = useState('')
  const [subgroupSearch,SetSubgroupSearch] = useState('')
  const [questionEditMode , setQuestionEditMode] = useState(false)
  const [surveyEditMode , setSurveyEditMode] = useState(false)
  const handleSelect = (event) => {
    setAge(event.target.value);
  };

  const [subgroup, subGroup] = useState('');
  const [iuData  ,setiuData] = useState([])
  const [editModeId1  , seteditmodeId1] = useState('')


  const handleSelect1 = (event) => {
    subGroup(event.target.value);
  };

  const [selectedOption,setSelectedOption] = useState([])
  
  const [finalSelectedOption,setFinalSelectedOption] = useState([])
  const [mappingIndicatorValue,setmappingIndicatorValue] = useState({})
  const [finalMapingValue,setFinalMapvalue] = useState({})
  const [activeOption,setActiveOption] = useState({name:''})


  const selectManageOption =  ({selectedRows})=>{
    // console.log("selectedRows" , selectedRows);
    setSelectedOption(selectedRows)
  }

  const confirmSelectedOption = ()=>{
    // console.log("confirmSelectedOption-------->" , selectedOption);
    setFinalSelectedOption(selectedOption)

    let indicatorValueMap = {}
    selectedOption.forEach(x=>{
       if(!indicatorValueMap.hasOwnProperty(x.name)){
        indicatorValueMap[x.name] = {
            indicatorUnit:'',
            subgroup:''
        }
       } 
    })
    setmappingIndicatorValue(indicatorValueMap)
    setFinalMapvalue(indicatorValueMap)

  }

  const clearInitialMapping = ()=>{
      let indicatorValueMap = mappingIndicatorValue
      Object.keys(indicatorValueMap).forEach(x=>{
          indicatorValueMap[x] = {
              indicatorUnit:'',
              subgroup:''
            } 
        })
    setmappingIndicatorValue({...mappingIndicatorValue, ...indicatorValueMap})
  }


  const checkMappingValidation = ()=>{
    let status = true
    // console.log(finalMapingValue)
    if(Object.keys(finalMapingValue).length>0){
        Object.keys(finalMapingValue).forEach(x=>{
            if(finalMapingValue[x].indicatorUnit == '' || finalMapingValue[x].subgroup == '' ) {status = false}
        })
    }else{
        status = false
    }

    return status
  }

  const  changeMapIndicatorValue =  (value,key)=>{
      let preValue = mappingIndicatorValue
    //   console.log("daaaaa" , value , key);
    preValue[key].indicatorUnit = value
    setmappingIndicatorValue({...mappingIndicatorValue, ...preValue})
  }

  const changeMapSubgroupVAlue = (value,key)=>{
    // console.log("datttttttt0" , value , key);
    let preValue = mappingIndicatorValue
    preValue[key].subgroup = value
    setmappingIndicatorValue({...mappingIndicatorValue, ...preValue})
  }

  const [isOpen, setIsOpen]= useState(false)
  
  const assignAreaModal = (event) => {    
    setIsOpen(true)
    setTimeout(() => {
        $('#selectarea').modal('show')
    }, 2000)

    setTimeout(()=> {
        $(".caret").each(function(index) {
            // alert("Sudhanshu 1");
            $(this).unbind().on("click", function(){
                // alert("Sudhanshu 2");
                $(this).parent('li').children('.nested').toggleClass('active')
                $(this).toggleClass('caret-down')
            });
        });
    }, 1500);
    
}

const [isUserOpen, setIsUserOpen]= useState(false)

  const  getdata =()=>{
    setIsUserOpen(true)
    setTimeout(() => {
        $('#assignusers').modal('show')
    }, 2000)
    // console.log("caaaaaaaaaaallllll");
    // https://itmcloud.org/ters_apigateway/api/data-retrieval/users/data-entry-user
    // AdminService.httpGet('/data-retrieval/users/data-entry-user').then((res)=>{
        
    //     res.data.records.forEach((ele)=>{
    //         ele.checkbox=  false
    //         if(ele.areaData.length){
                
    //             ele.area = ele.areaData[0].name
    //         }else{
    //             ele.area = ''
    //         }
    //     })
        
    //     // console.log("data------<" , res.data.records);
    //         setuserdata(res.data.records)
    // })
   
        
  }

 const [indicatorUnitData,setIndicatorUnitData] = useState({})
 const  get_mangage_section= ()=> {
    
        AdminService.httpGet('/data-retrieval/section/get-section').then((res)=>{
    
      res.data.forEach((ele , index)=>{
             ele.question  = index
              ele.section = ele.name
        })
    setgetmanageSection(res.data)
        // console.log("data------<" , res.data);
            
    })

    AdminService.httpGet('/data-retrieval/ius/get-form-ius-maping').then((res)=>{
        let obj = {}
        res.data.forEach(x=>{
            obj[`${x.name} | ${x.unit}`] = x.subgroup
        })
        // console.log(res);
        setiuData(res.data)
        setIndicatorUnitData(obj)
    })
    let obj = {}
    for(let i = 0;i<5;i++){
        for(let j = 0;j<5;j++){
            obj[''+i+j] = {
                type:'',
                indicatorUnit:'',
                subgroup:'',
                numeric:{
                    default:0,
                    minimum:0,
                    maximum:0
                },
                singleChoice:{selectedOption:[],iusMap:{}}
            }
        }
    }
    setGridQuestions(obj)

    // console.log("setGridQuestions:: ", obj, indicatorUnitData);
       

 
  }

  const  get_mangage_option=()=> {


    AdminService.httpGet('/data-retrieval/option/get-option').then((res)=>{
    
      res.data.forEach((ele , index)=>{
             ele.question  = index
              ele.option = ele.name
        })
    setgetmanageOption(res.data)
        // console.log("data------<" , res.data);
            
    })
       

 
  }


    
    
    
const assignval =()=>{
    let preValue = assignTableData
    // console.log(preValue,checkedAreaForAssign,userSelected);
    // return false
    formTypeApprove? checkedAreaForAssign.forEach((area)=>{
        userSelected.forEach(user=>{
            preValue.push({ area: area, dataEntry  :user ,approver:approver  });
        })
    }):checkedAreaForAssign.forEach(area=>{
        preValue.push({ area: area ,approver:approver  })
    })
    setAssignTableData([...preValue])
    console.log(preValue)
    setCheckedAreaForAssign([])
    setuserSelected([])
    setApprover({})
}




const addsection =()=>{
// console.log("vall->" ,manageSection);
// https://itmcloud.org/gimtstagingapigateway//api
// Payload:
// {
//     "name": "Test"
// }

let obj  = {
    "name" : manageSection
}
AdminService.httpPost('/data-import/section/add' ,obj ).then((res)=>{
    // console.log('callllll->' , obj);
    if(res.status==201){
        toast.success(res.message)
        
        setmanageSection('')
        get_mangage_section()
    }

})
}




const addoption =()=>{
    // console.log("vall->" ,manageSection);
    // https://itmcloud.org/gimtstagingapigateway//api
    // Payload:
    // {
    //     "name": "Test"
    // }
    
    let obj  = {
        "name" : manageOption
    }
    AdminService.httpPost('/data-import/option/add' ,obj ).then((res)=>{
        // console.log('callllll->' , obj);
        if(res.status==201){
            toast.success(res.message)
            
            setmanageOption('')
            get_mangage_option()
        }
    
    })
    }
    

 



// get Question ------------------------------------
const getQuestions =async (id)=>{
  
    let data111= []
   AdminService.httpGet('/data-retrieval/section/get-section').then((res)=>{
    
    res.data.forEach((ele , index)=>{
           ele.question  = index
            ele.section = ele.name
      })
      data111 =res.data
    })
   
    AdminService.httpGet(`/data-retrieval/survey/get-survey/${id}`  ).then((res)=>{
        // console.log('check->'  , res.survey[0].questionsList);
        // {
        //     id: 1,
        //     question: 'ukkj',
        //     section: 'Personal Details',
        //     type: 'Date',
        //     mandatoryquestion: 'Yes',
        // },

    
            
        res.survey[0].questionsList.forEach((ele)=>{
            ele.question = ele.title
             ele.mandatoryquestion= ele.mandatory  ==true ? 'Yes' : 'No'

            //  console.log("getmanageSection" , getmanageSection);
             data111.forEach((item)=>{
                 if(ele.section_id = item._id){
                    // console.log("item" , item.name);
                    ele.section = item.name
                }
             })

        })

        // console.log("qqqqqq" , res.survey[0].questionsList , section );
        setQuestionData( res.survey[0].questionsList)

    
    }) 
}

// add question Api -----------------------------------
const  addquestion = ()=>{
 
    const  optionArr=  []
    const  options  =[]
    var iuID = ''
    let slectedGridquestions = []
    // console.log("add-personName" , format, finalMapingValue , finalSelectedOption);
    // return false

    if(format =='Single Choice' || format =='Multi Choice' ){
        Object.entries(finalMapingValue).forEach(function([key , value ], index) {
            let iuID = ''
            const iuname = value.indicatorUnit.split('|')
            iuData.forEach((ele1)=>{ if(ele1.name+' '==iuname[0]){  iuID =  ele1._id }    })
            let optionObj = { "iu": iuID, "ius_id": value.subgroup._id , 'option' : key   }
                optionArr.push(optionObj)    
        });
   
      optionArr.forEach((ele)=>{
        finalSelectedOption.forEach((item)=>{
                if(ele.option==item.name){
                    ele.option_text = item._id
                }
        })
      })
    } 
    else if(format == 'Grid'){
        // console.log("gridQuestions:: ", gridQuestions)

        for(const tm in gridQuestions){
            if(gridQuestions[tm]['type'] != '' ){
                let type = gridQuestions[tm]['type']
                let cr = String(tm).split('')
                if(type == "Textbox"){ 
                    type = 'text'
                    let obj = {
                        "column": Number(cr[1]) + 1,
                        "row": Number(cr[0]) + 1,
                        "type": type,
                        "column_name": "A",
                        "row_name": "V",
                        "options": [
                            {
                                "ius_id": gridQuestions[tm]['subgroup']._id,
                                "option_text": ""
                            }
                        ]
                    }
                    slectedGridquestions.push(obj)
                }
                else if(type == "Numeric"){ 
                    type = 'numeric'
                    let obj = {
                        "column": Number(cr[1]) + 1,
                        "row": Number(cr[0]) + 1,
                        "type": "numeric",
                        "column_name": "B",
                        "row_name": "V",
                        "default_numeric_value": gridQuestions[tm]['numeric'].default,
                        "maximum_numeric_value": gridQuestions[tm]['numeric'].maximum,
                        "minimum_numeric_value": gridQuestions[tm]['numeric'].minimum,
                        "decimal_value":gridQuestions[tm]['numeric'].decimal,

                        "options": [
                            {
                                "ius_id": gridQuestions[tm]['subgroup']._id,
                                "option_text": ""
                            }
                        ],
                        "aggregate": true
                    }
                    slectedGridquestions.push(obj)
                }
                // else if(type == 'Single Choice'){ 
                //     type = 'single_select' 
                //     let obj = {
                //         "column": Number(cr[1]) + 1,
                //         "row": Number(cr[0]) + 1,
                //         "column_name": "B",
                //         "row_name": "W",
                //         "type": "single_select",
                //         "options": [
                //             {
                //                 "ius_id": "63e4a43da5268a00354cc8fe",
                //                 "option_text": "63c5393347de6300122db9a2"
                //             },
                //             {
                //                 "ius_id": "63e4a43ca5268a00354cc85e",
                //                 "option_text": "63c538e847de6300122db9a1"
                //             },
                //             {
                //                 "ius_id": "63e4c5544e64c200612afc49",
                //                 "option_text": "63c538de47de6300122db9a0"
                //             }
                //         ]
                //     }
                // }
                
            }
        }

        // console.log("slectedGridquestions:: ", slectedGridquestions)
        // if(slectedGridquestions.length){
        //     JSON.stringify(slectedGridquestions)
        // }
        // return
    }
    else{

         options.push(
            {
                "option_text": "",
                "ius_id": subgroupid._id
            }
         )

        const iuname = indicatorUnit.split('|')
        // console.log("data---" ,iuData , iuname , indicatorUnit );
        iuData.forEach((ele)=>{  
            if(ele.name+' '==iuname[0]){  iuID =  ele._id }   
        })
    
    }
    //   console.log("dat11111" ,optionArr  , format);


    // return false

    let temp_format  =format
    let imageFormat = personName
    let filetype = {
        "file_format": imageFormat,
        "file_size": sizeInImage,
        "hint": hint
    }
    if(temp_format=='Image'){temp_format='image'}
    if(temp_format == 'Document'){temp_format='document'}
    if(temp_format == 'Single Choice'){temp_format='single_select'}
    if(temp_format == 'Multi Choice'){temp_format='multi_select'}
    // return false

    
   
    
  
    let obj  = {
        "id": "",
        "section_id": section._id,
        "title": qustion_text,
        "ques_type":  temp_format,
        "mandatory": mandatory,
        "aggregate": true,
        "file": "",
        "grid": slectedGridquestions.length > 0 ? JSON.stringify(slectedGridquestions) : "",
        "dependent_ques_id": "",
        "dependent_option_id": "",
        "iu": temp_format=='single_select' || temp_format=='multi_select' ? '': iuID,
        "ius_id":   temp_format=='single_select' ||temp_format== 'multi_select' ? '':  subgroupid._id,
        "time_period_format": dateFormat,
        "file_format": imageFormat,
        "file_size": sizeInImage,
        "file_hint": hint,
        "default_numeric_value": defaultValue,
        "maximum_numeric_value":    maxvalue,
        "minimum_numeric_value": miniValue,
        decimal_value: decimal,
        "options_list": temp_format=='single_select' || temp_format=='multi_select' ? finalSelectedOption : '',
        "row": 1,
        "column": 1,
        "options":  temp_format=='single_select' || temp_format=='multi_select' ?  JSON.stringify(optionArr) : JSON.stringify(options),
        "survey_id": FormState.id,
        "filetype" : JSON.stringify(filetype)
    }
   

    
    // console.log("atattt00", obj, format, subgroupid);
    // return false
    AdminService.httpPost('/data-import/question/add' ,obj ).then((res)=>{
        // console.log('check->' , props.editModeId._id);
        if(res.status==true){
            getQuestions(props.editModeId._id)
            toast.success(res.message)
           
        }else{
            toast.success('something went wrong')
        }
    
    })

}
//  Edit Quesition ------------------------------------

const QuestionEdit = async(row)=>{
  
    setQuestionEditMode(true)
    // QuestionEditId = row._id
    seteditmodeId1(row._id)
    const iusId =row.options[0].ius_id
    // console.log("Data-----12"    ,row);
    
// const iuname = indicatorUnit.split('|')
//     console.log("data---" ,iuData , iuname , indicatorUnit );
let  iusdata = ''
let  iudata = {}
let check = false



    iuData.forEach((ele)=>{
        if(check==false){

            iudata = ele
        }
        ele.subgroup.forEach((item)=>{
            if(item._id  ==iusId){
                // console.log("iusdata" , item);
                iusdata  = item.name
                check = true
                
            }
        })
        // if(ele._id==row.){
        
        //     iuID =  ele._id
        //    }   
    })


    getmanageSection.forEach((ele)=>{
        if(ele._id==row.section_id){
             setSection(ele)
        }
    })
    const finalIndicator= `${iudata.name} | ${iudata.unit}`

    // console.log("data-23--setIndicatorUnit>" ,finalIndicator);
setqustion_text(row.title)
setFormat(row.type)
setIndicatorUnit(finalIndicator)
setSubgroupList(indicatorUnitData[finalIndicator])
setSubgroup(iusdata)
setdefaultValue(row.default_numeric_value)

setminiValue(row.minimum_numeric_value)
setmaxvalue(row.maximum_numeric_value)
setDecimal(row.decimal_value)
setSubgroupid(iusId)
if(row.type=='image'){
    setFormat('Image')
    setHint(row.file.hint)
    setsizeInImage(row.file.file_size)
    
    setPersonName(row.file.file_format)
}
if(row.type=='single_select'){
    setFormat('Single Choice')
    const tempOption = []
    const  iusId = []
   
 const tempOptiondata = await    AdminService.httpGet('/data-retrieval/option/get-option')

          row.options.forEach((item)=>{
            tempOptiondata.data.forEach((ele , i)=>{

                // console.log("eleeeee-<" ,item);
                  if (item.option_text ==ele._id ){
                    ele.option = ele.name
                    ele.question = 
                
                    tempOption.push(ele)
                
                }
           })
           iusId.push(item)

        })



        let  iusdata = []
        let iudata =  {}
        let check = false
             const  allfinalIndicators = []

        // console.log("datyattttttttt" , iusId);
        iusId.forEach((id)=>{
         iuData.forEach((ele)=>{
         if(check==false){
             iudata = ele
         }
         ele.subgroup.forEach((item)=>{
         
             if(item._id===id.ius_id){
                
                 iusdata.push({ "item" : `${ele.name} | ${ele.unit}` , ius: item.name , "optionId" : id.option_text , ius_id : id.ius_id})
                //  console.log("iusdatamain" , iusdata);
                 check = true
                 
             }
         })
         
        })

        
        // allfinalIndicators.push(`${iudata.name} | ${iudata.unit}`)

        
        
    })
    
    // console.log("iusdatamain11"  , iusdata , iudata);
   
        
    let indicatorValueMap = {}


    iusdata.forEach((ele)=>{
             tempOption.forEach(x=>{
                //  console.log("datttttttttttttttt" ,  x);
                //  console.log("=======>>>", x, ele)
                    if(ele.optionId  ==x._id){
                 if(!indicatorValueMap.hasOwnProperty(x.name)){
                    

                    indicatorValueMap[x.name] = {
                        indicatorUnit:ele.item,
                        subgroup:{
                            "_id": ele.ius_id,
                            "name": ele.ius
                        },
                        _id : ele.optionId
                    }

                } 
            }
            })
        })
        setmappingIndicatorValue(indicatorValueMap)
        setFinalMapvalue(indicatorValueMap)
        setFinalSelectedOption(tempOption)
        setSelectedOption(tempOption)
        
        
        // console.log("Data---12-->"  ,indicatorValueMap  , tempOption);
}


if(row.type=='document'){ setFormat('Document')
setsizeInImage(row.file.file_size)
    
    setPersonName(row.file.file_format)
}
// console.log( "data111",iusId)

}

 const updateQuestion = ()=>{
    const  optionArr=  []
    const  options  =[]
    var iuID = ''
    // console.log("personName" , finalMapingValue , finalSelectedOption);
    // return false

        if(format =='Single Choice' || format =='Multi Choice' ){
    Object.entries(finalMapingValue).forEach(function([key , value ], index) {
        let iuID = ''
        const iuname = value.indicatorUnit.split('|')
        iuData.forEach((ele1)=>{ if(ele1.name+' '==iuname[0]){  iuID =  ele1._id }    })
        let optionObj = { "iu": iuID, "ius_id": value.subgroup._id , 'option' : key   }
            optionArr.push(optionObj)

            
      });
   
      optionArr.forEach((ele)=>{
        finalSelectedOption.forEach((item)=>{
                if(ele.option==item.name){
                    ele.option_text = item._id
                }
        })
      })
    }else{

         options.push(
            {
                "option_text": "",
                "ius_id": subgroupid._id
            }
         )


       

        const iuname = indicatorUnit.split('|')
        // console.log("data---" ,iuData , iuname , indicatorUnit );
        iuData.forEach((ele)=>{  
            if(ele.name+' '==iuname[0]){  iuID =  ele._id }   
        })
    
    }
    //   console.log("dat11111" ,optionArr  , format);


    // return false

    let temp_format  =format
    let imageFormat = personName
    let filetype = {
        "file_format": imageFormat,
        "file_size": sizeInImage,
        "hint": hint
    }
    if(temp_format=='Image'){temp_format='image'}
    if(temp_format == 'Document'){temp_format='document'}
    if(temp_format == 'Single Choice'){temp_format='single_select'}
    if(temp_format == 'Multi Choice'){temp_format='multi_select'}
    // return false

    
   
    
  
    let obj  = {
        "id": editModeId1,
        "section_id": section._id,
        "title": qustion_text,
        "ques_type":  temp_format,
        "mandatory": mandatory,
        "aggregate": true,
        "file": "",
        "dependent_ques_id": "",
        "dependent_option_id": "",
        "iu": temp_format=='single_select' || temp_format=='multi_select' ? '': iuID,
        "ius_id":   temp_format=='single_select' ||temp_format== 'multi_select' ? '':  subgroupid._id,
        "time_period_format": dateFormat,
        "file_format": imageFormat,
        "file_size": sizeInImage,
        "file_hint": hint,
        "default_numeric_value": defaultValue,
        "maximum_numeric_value":    maxvalue,
        "minimum_numeric_value": miniValue,
        "decimal_value": decimal,
        "options_list": temp_format=='single_select' || temp_format=='multi_select' ? finalSelectedOption : '',
        "row": 1,
        "column": 1,
        "options":  temp_format=='single_select' || temp_format=='multi_select' ?  JSON.stringify(optionArr) : JSON.stringify(options),
        "survey_id": FormState.id,
        "filetype" : JSON.stringify(filetype)
    }
   

    
    // console.log("atattt00"  , obj  , editModeId1);
    // return false
    AdminService.httpPut(`/data-import/question/update/${editModeId1}` ,obj ).then((res)=>{
        // console.log('check->' , props.editModeId._id);
        if(res.status==true){
            toast.success(res.message)
            getQuestions(props.editModeId._id)
        }else{
            toast.success('something went wrong')
        }
    
    })

    get_mangage_section()

    getQuestions(props.editModeId._id)
}

// Delete Question ---------------------------------------------------------------
const deleteEntry = (id) =>{

    swal({
        title:"Are you sure?",
        text: "Once deleted, you will not be able to recover this Question!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if(willDelete) {
            const dataToBeSend = {
                "id": id,
                
            }
        
            AdminService.httpPost('/data-import/question/delete-question', dataToBeSend).then((res) => {

                if (res) {
                    
                        toast.success(res.message)
                        getQuestions(props.editModeId._id)
                }
        
            }).catch((err) => {
                // this.setState({ loader: false })
                console.log(err)
            });
          }                
        });     



     


  }


  const copyQuestion= (id)=>{
    const dataToBeSend = {
        "id": id,
        
    }
    AdminService.httpPost('/data-import/question/copy-question', dataToBeSend).then((res) => {

        if (res) {
            
                toast.success(res.message)
                getQuestions(props.editModeId._id)
        }

    }).catch((err) => {
        // this.setState({ loader: false })
        console.log(err)
    });
  }


 
  const checkQuestionfun  = ()=>{
    setQuestionEditMode(false)
    setqustion_text('')
    setFormat('')
    setdefaultValue()
    setminiValue()
    setmaxvalue()
    setDecimal("")
   
    setSubgroup()
    setIndicatorUnit()
    
    setSection('')
    get_mangage_section()


    // setSubgroupid('')

    
    
    
  }

//   const callingFunc =()=>{
//     // if(props.editModeId){
        
//     //     getQuestions(props.editModeId._id)
//     // }


//   }



//   https://itmcloud.org/gimtstagingapigateway//api/data-import/question/copy-question
//   console.log("Data----->callll" , );
// ======================================================= Multiselect Dropdown End


const rowSelectCritera = (selectedRows) => {
   
    let name = ''
    selectedOption.forEach((ele)=>{
        if(selectedRows.name==ele.name){
                name = ele.name
        }
    })

                if(selectedRows.name==name){
                    // console.log("dddddddddd");
                    return true
                }else{
                    return false
                }
            
        



    
};

// ======================================= Disaster Type
const [disaster, setDisaster] = useState('');

  const disasterType = (event) => {
    setDisaster(event.target.value);
  };
// ======================================= Disaster Type

// useEffect(()=>{
    

//     // $('.caret').unbind().click(function(){
//     //     $(this).parent('li').children('.nested').toggleClass('active')
//     //     $(this).toggleClass('caret-down')
//     //     // this.parentElement.querySelector(".nested").classList.toggle("active");
//     //     // this.classList.toggle("caret-down");
//     //   }) 

//     // var toggler = document.getElementsByClassName("caret");
    
    
// // var i;

// // for (i = 0; i < toggler.length; i++) {
// //   toggler[i].addEventListener("click", function() {
// //     this.parentElement.querySelector(".nested").classList.toggle("active");
// //     this.classList.toggle("caret-down");
// //   });
// // }

//   }, [props]);

// =========================================== Date
    const today = new Date()
    const tomorrow = new Date()
    const [fromdate, setFromdate] = useState(today);
    const [todate, setTodate] = useState(tomorrow.setDate(tomorrow.getDate()+1) );

  const handleFromdate = (newValue) => {

    setFromdate(newValue);
    setTodate(newValue)
    let date = `${newValue.$y}-${newValue.$M+1}-${newValue.$D}`
    formFilling(date,'from_date')
  };
  const handleTodate = (newValue) => {
    setTodate(newValue);
    let date = `${newValue.$y}-${newValue.$M+1}-${newValue.$D}`
    formFilling(date,'to_date')
  };
// =========================================== Date



// =======Select Area Function=============

const [selectedAreas,setSelectedAreas] = useState([])
const [finalSelectedAreas,setFinalSelectedAreas] = useState([])
const [viewSelectedAreas,setViewSelectedAreas] = useState(false)
const [tggleChecked,setToggleCheckd] = useState(false)

const checkBoxClick = (action,value)=>{
    let preValue = selectedAreas
    if(action){
        preValue.push(value)
    }else{
        let index = preValue.indexOf(value)
        if(index>-1){
            preValue.splice(index,1)
        }
    }
    setSelectedAreas([...preValue])
}

const allSelected = (level,parent)=>{
    let status = true
    areaData.forEach(area=>{
        if(!selectedAreas.includes(area.area_code) && area.level == level && area.parent_id == parent && (assignedAreasToUser.includes(area.area_code) || role==1 ) ){
            status = false
        }
    })
    return status
}

const onAllSelect = (level,action,parent)=>{
    // console.log(level,action,parent);
    let preValue = selectedAreas
    if(action){
        areaData.forEach(area=>{
            if(area.level==level && !preValue.includes(area.area_code) && area.parent_id == parent && (assignedAreasToUser.includes(area.area_code) || role==1 ) ){
                preValue.push(area.area_code)
            }
        })
    }else{
        areaData.forEach(area=>{
            if(area.level==level && preValue.indexOf(area.area_code)!=-1 && area.parent_id == parent ){
                preValue.splice(preValue.indexOf(area.area_code),1)
            }
        })
    }

    setSelectedAreas([...preValue])

}

const hideArea = (area)=>{
    if(viewSelectedAreas && !selectedAreas.includes(area)){
        return true
    }else{
        return false
    }
}


// =======Select Area Function=============

// ================assign users=============
const [checkedAreaForAssign,setCheckedAreaForAssign] = useState([])

const checkAssignArea = (action,value)=>{
    let preValue = checkedAreaForAssign
    if(action){
        preValue.push(value)
    }else{
        let index = preValue.indexOf(value)
        if(index>-1){
            preValue.splice(index,1)
        }  
    }
    setCheckedAreaForAssign([...preValue])
    setApprover({})
    console.log('checkedAreaForAssign',checkedAreaForAssign);
}

const assignAllAreas = (action)=>{
    if(action){
        let preValue = checkedAreaForAssign
        areaData.forEach(area=>{
            if(selectedAreas.includes(area.area_code) && !preValue.includes(area) && !assignTableData.some(x=>x.area.area_code==area.area_code)  ){
                preValue.push(area)
            }
        })
        setCheckedAreaForAssign([...preValue])
        // console.log('checkedAreaForAssign',checkedAreaForAssign);
    }else{
        setCheckedAreaForAssign([])
    }
}


const isUserDisabled = (areaList) => {
    if(checkedAreaForAssign.length==0){
        return true
    }
    let disable = false
    let checkedAreas = checkedAreaForAssign.map(area => area.area_code);
    let assignedAreas =  areaList.map(area => area.area_code[0]);

    // console.log('assignedAreas',checkedAreas.some(x=> !assignedAreas.some(y=> x.includes(y) ) ));
    // return checkedAreas.some(x=> !assignedAreas.some(y=> x.includes(y) ) ) //for enable child of assigned area
    return checkedAreas.some(x=> !assignedAreas.includes(x) ) // for assigned area only
}


const formFilling = (value,target)=>{
    let preValue = FormState
    preValue[target] = value
    setFormState({...preValue})
    // console.log(FormState);
}

// console.log('selectedAreas',selectedAreas);

// ================assign users=============

return (

        <>
            <div className="rightTab_Heading position-relative">
                <h2>{props.isEdit==true ? 'Edit Plan': 'Plan Setup'}</h2>
            </div>

            <div  className="user-form">
            <ValidatorForm
                className="main_react_form w-100"
                ref={form}
                onSubmit={onFormSubmit}
            >   
                <div className="tabSection1">
                    <div className="mf_form">
                        <div className="mt_formTop">
                            {/* <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <label>Id</label>
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator name="formid" autoComplete="off" className="w-100" value={props.formId} disabled />                                              
                                        </div>
                                    </div>
                                </div>          
                            </div> */}

                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <label>Name</label>
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator name="formName" autoComplete="off" className="w-100" onChange={(e) => { formFilling(e.target.value,'formName') }} value={ FormState.formName }  validators={['required']} errorMessages={['This field is required']} />                                              
                                        </div>
                                    </div>
                                </div>          
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <label>Description</label>
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator autoComplete="off" className="w-100" name="description" onChange={(e) => { formFilling(e.target.value,'description') }} value={FormState.description} id="outlined-basic" multiline rows={4} />                                          
                                        </div>
                                    </div>
                                </div>          
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <label>Disaster Type</label>
                                        <div className="mat-form-field-wrapper">
                                            <Select
                                                value={FormState.disaster_type}
                                                onChange={(e)=>{formFilling(e.target.value,'disaster_type')}}
                                                displayEmpty                                                
                                            >
                                                {props.disasterList.map((item,i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={item._id}
                                                    >
                                                        {item.title.en}
                                                    </MenuItem>
                                                ))}  
                                                {/* <MenuItem value={10}>Heavy Rain</MenuItem>
                                                <MenuItem value={20}>Heavy Rain1</MenuItem> */}
                                            </Select>                                          
                                        </div>
                                    </div>
                                </div>          
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown">
                                        <label>From Date</label>
                                        <div className="mat-form-field-wrapper">                                        
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={1}>
                                                    <DesktopDatePicker                                                   
                                                        inputFormat="DD/MM/YYYY"
                                                        value={fromdate}
                                                        onChange={handleFromdate}
                                                        minDate={new Date()}
                                                        renderInput={(params) => <TextField {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true
                                                        }} />}
                                                        format="YYYY-MM-DD"
                                                        />                                                 
                                                </Stack>
                                            </LocalizationProvider>                                         
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style handleTodate">
                                        <label title={todate} >To Date</label>
                                        <div className="mat-form-field-wrapper">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={1}>
                                                    <DesktopDatePicker                                                   
                                                        inputFormat="DD/MM/YYYY"
                                                        value={todate}
                                                        minDate={fromdate}
                                                        onChange={handleTodate}
                                                        renderInput={(params) => <TextField {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true
                                                        }} />}
                                                        format="YYYY-MM-DD"
                                                    />                                                 
                                                </Stack>
                                            </LocalizationProvider>                         
                                        </div>
                                    </div>
                                </div>          
                            </div>
                            
                            <div className="row align-items-center mb-30 assign_user">
                                <div className="col-md-6 text-right">  
                                {/* <p onClick={()=>console.log(finalSelectedAreas,selectedAreas)} >test</p>   */}
                                    <Button variant="contained" onClick={(e) => {assignAreaModal(e);setSelectedAreas([...finalSelectedAreas])} }>                                    
                                        {/* <img src="assets/lib/images/home/area-i.svg" /> */}
                                        Assign Area 
                                        {
                                            finalSelectedAreas.length>0?
                                            <img className="checktick" src="assets/lib/images/home/check_tick.svg" />:''
                                        }
                                    </Button>                            
                                </div>   
                                <div className="col-md-6">    
                                    <Button variant="contained" onClick={(e) => getdata(e)} className= {finalSelectedAreas.length>0?"": "btnDisable"} disabled={finalSelectedAreas.length<1} >
                                        {/* <img src="assets/lib/images/home/user-i.svg" /> */}
                                        Assign User 
                                        {
                                            FormState.assigned_user.length> 0 && finalSelectedAreas.length !==0 ?
                                            <img className="checktick" src="assets/lib/images/home/check_tick.svg" />:''
                                        }
                                    </Button>                            
                                </div>          
                            </div>
                        </div>
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0">  
                            
                            <Button disabled={ (FormState.formName == "" || FormState.description == "" || !FormState.assigned_user.length || FormState.disaster_type == "" ) ? true : false } onClick={setFormData} variant="contained"> { surveyEditMode }{props.isEdit? "Update":"Save"} <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                        </div>
                    </div>
                </div>
            </ValidatorForm>

            </div>

            {/* ===================================================== Area Start ====================================================== */}
            { isOpen &&
                <div className="modal fade timePeriodPopup selectArea" data-backdrop="static" data-keyboard="false" id="selectarea" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLabel"></h5>
                                <div className="d-flex align-items-center">
                                    <div onClick={()=>{setViewSelectedAreas(!viewSelectedAreas)}} className={`viewAll ${!viewSelectedAreas?"":"viewall_selected"}`}>
                                        <span>View Selected</span>
                                        <span className={`viewAllnum ${!viewSelectedAreas?"":"viewall_selected"}`}>{selectedAreas.length}</span>
                                    </div>
                                    
                                    <Button onClick={()=>{setSelectedAreas([])}} variant="contained" className={`clearAll ${selectedAreas.length>0?"viewall_selected":""}`}>Clear All</Button> 
                                    <div className="areaSearch">                                        
                                        <input type="search" placeholder="search..." value={areaAssignSearch} onChange={(e)=>setAreaAssignSearch(e.target.value)} />   
                                        <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                    </div>
                                </div>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={()=>{setViewSelectedAreas(false);setSelectedAreas([]) }}></button>
                            </div>
                            <div className="modal-body">
                                {/* <p onClick={()=>console.log(assignedAreasToUser)} >test</p> */}
                                <ul className="assignAreaList">
                                    {/* <p onClick={()=>console.log(areaData, areaData.filter(a=> assignedAreasToUser.some(b=> b.includes(a.area_code)) ),assignedAreasToUser)} >test</p> */}
                                     {
                                        // filter(a=> assignedAreasToUser.includes(a.area_code) || a.areaSubData?.filter( b=> assignedAreasToUser.includes(b.area_code) || b.areaSubData?.filter(c=> assignedAreasToUser.includes(c.area_code) || c.areaSubData?.some(d=> assignedAreasToUser.includes(d.area_code) )  ).length > 0 ).length>0)
                                        areaData?.filter(a=>  role==1 ||  assignedAreasToUser.some(b=> b.includes(a.area_code)) ).map(area=>{
                                            // console.log([area],"2171")
                                            if(area.level==1){
                                                return (
                                                    <li>
                                                        {
                                                            areaData.some(x=> x.parent_id==area.area_code )
                                                            ?
                                                            <span className={hideArea(area.area_code) ? "d-none":"caret caret-dowm"}></span>
                                                            :
                                                            ''
                                                        } 
                                                        
                                                        <div className={hideArea(area.area_code) ?"d-none":"aalCheckbox"}>
                                                            <input type="checkbox" disabled={ role!=1 && !assignedAreasToUser.includes(area.area_code)} checked={selectedAreas.includes(area.area_code)} onChange={(e)=>{checkBoxClick(e.target.checked,area.area_code)}} /> 
                                                            <label onClick={()=>console.log(area)} for="timorleste">{area.name}</label>
                                                        </div>
                                                        <ul className="nested active">
                                                            {/* {console.log('areaData',areaData)}   */}
                                                            <li className={role!==1 ? "d-none":""}><div className={hideArea(area.area_code) ?"d-none":"aalCheckbox"}><input type="checkbox" checked={allSelected(2,area.area_code)} onChange={(e)=>{onAllSelect(2,e.target.checked,area.area_code)}}  /> <label >Select All</label></div></li>
                                                            {
                                                                areaData?.filter((x)=>x.name?.toLowerCase().includes(areaAssignSearch.toLowerCase())).filter(a=> role==1 || assignedAreasToUser.some(b=> b.includes(a.area_code)) ).map(area2=>{
                                                                    // console.log([area2],"2192")
                                                                    if(area2.level==2 && area2.parent_id == area.area_code ){
                                                                        return (
                                                                            <li>  {areaData.some(x=> x.parent_id == area2.area_code )? <span className={hideArea(area2.area_code) ?"d-none":"caret"}></span>:''} <div className={hideArea(area2.area_code)?"d-none":"aalCheckbox"}><input type="checkbox" checked={selectedAreas.includes(area2.area_code)} disabled={ role!=1 && !assignedAreasToUser.includes(area2.area_code)} onChange={(e)=>{checkBoxClick(e.target.checked,area2.area_code)}} /> <label for="ainaro" onClick={()=>console.log(area2)} >{area2.name}</label></div>                                          
                                                                                <ul className="nested">
                                                                                <li><div className="aalCheckbox"><input type="checkbox" disabled={ role!=1 && !assignedAreasToUser.includes(area2.area_code)} checked={allSelected(3,area2.area_code)} onChange={(e)=>{onAllSelect(3,e.target.checked, area2.area_code)}}  /> <label >Select All</label></div></li>
                                                                                    {
                                                                                        areaData.filter(a=> role==1 || assignedAreasToUser.some(b=> b.includes(a.area_code)) ).map(area3=>{
                                                                                            if(area3.level==3 && area3.parent_id==area2.area_code){
                                                                                                return  (<li>{ areaData.some(x=> x.parent_id == area3.area_code )? <span className={hideArea(area3.area_code) ?"d-none":"caret"}></span>:''}<div className={hideArea(area3.area_code)?"d-none":"aalCheckbox"}><input type="checkbox" checked={selectedAreas.includes(area3.area_code)} 
                                                                                                disabled={ role!=1 && !assignedAreasToUser.includes(area3.area_code)}
                                                                                                onChange={(e)=>{checkBoxClick(e.target.checked,area3.area_code)}} /> <label onClick={()=>console.log(area3)} >{area3.name}</label></div>
                                                                                                            <ul className="nested" >
                                                                                                            <li><div className="aalCheckbox"><input type="checkbox" disabled={ role!=1 && !assignedAreasToUser.includes(area3.area_code)} checked={allSelected(4,area3.area_code)} onChange={(e)=>{onAllSelect(4,e.target.checked, area3.area_code )}}  /> <label >Select All</label></div></li>
                                                                                                            {
                                                                                                                areaData.filter(a=> role==1 || assignedAreasToUser.some(b=> b.includes(a.area_code)) ).map(area4=>{
                                                                                                                    if(area4.level==4 && area4.parent_id == area3.area_code ){
                                                                                                                        return (
                                                                                                                            <li><div className={hideArea(area4.area_code)?"d-none":"aalCheckbox"}>
                                                                                                                                <input type="checkbox" 
                                                                                                                                disabled={ role!=1 && !assignedAreasToUser.includes(area4.area_code)}
                                                                                                                                checked={selectedAreas.includes(area4.area_code)} 
                                                                                                                                onChange={(e)=>{checkBoxClick(e.target.checked,area4.area_code)}} /> 
                                                                                                                                <label onClick={()=>console.log(area4)} >{area4.name}</label>
                                                                                                                                </div></li>
                                                                                                                        )
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                            </ul>
                                                                                                        </li>)
                                                                                            }
                                                                                        })
                                                                                    }
                                                                            
                                                                                </ul>
                                                                            </li> 
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                         
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                        })
                                    } 
                                   

                                </ul>
                                
                            </div>
                            <div class="modal-footer">                           
                                <div className="row align-items-center btn_saveNext justify-content-center mx-0 mfBtn">  
                                    <Button variant="contained" data-dismiss="modal" onClick={()=>{setViewSelectedAreas(false);setFinalSelectedAreas([...selectedAreas]) }}>  Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                                </div>                    
                            </div>
                        </div>
                    </div>
                </div>
            }  
            {/* ===================================================== Area End ====================================================== */}

            {/* =============================================== Assign Users Start ================================================== */}
            { isUserOpen &&
                <div class="modal fade timePeriodPopup selectArea assignusers" id="assignusers" tabindex="-1" role="dialog" aria-labelledby="assignusersCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="assignusersTitle">Assign Users</h5>
                        <button type="button"  className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-0 pb-0">
                        <div>
                            <div className="row m-0">
                             {/* =========================================================== Area ================================================= */}
                                <div className="col-md-6 pr-0 areaTable">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <span className="labelMargin">   <FormControlLabel onChange={(e)=>assignAllAreas(e.target.checked)}  checked={checkedAreaForAssign.length!=0 && checkedAreaForAssign.length == finalSelectedAreas.length - assignTableData.length } control={<Checkbox  />}/></span> 
                                                                Area 
                                                            <span className="areaCount">{checkedAreaForAssign.length}/{finalSelectedAreas.length}</span>
                                                        </div>
                                                        <div class="btn-group">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src="assets/lib/images/home/sorting.svg" />
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-right"         aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderArea(true)}}> Sort A - Z </a>
                                                                 <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderArea(false)}}> Sort Z - A </a>
                                                                <div className="areaSearch">                                        
                                                                <input type="search" value={areaSearchValue} onChange={(e)=> setAreaSearchValue(e.target.value) } placeholder="Search..." />   
                                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                               
                                                areaData?.filter(x=> x.name?.toLowerCase().includes(areaSearchValue.toLowerCase()))?.filter(x=>finalSelectedAreas.includes(x.area_code)).sort((a,b)=> sortOrderArea?a.name.localeCompare(b.name):b.name.localeCompare(a.name)).map((area)=>{
                                                        return (
                                                            <tr>
                                                                <td> <FormControlLabel  id="areacheckbox"   
                                                                disabled={assignTableData.some(x=>x.area.area_code==area.area_code)} 
                                                                checked={checkedAreaForAssign.some(x=> x.area_code == area.area_code)}   
                                                                onChange={(e)=>{checkAssignArea(e.target.checked,area)}} 
                                                                control={<Checkbox  />} 
                                                                label={area.name} />
                                                                </td>
                                                            </tr>
                                                        )
                                           
                                                })
                                            }
                                                          
                                        </tbody>
                                    </Table>
                                </div>
                             {/* =========================================================== Data Entry ================================================= */}
                             {
                                formTypeApprove && 

                                <div className="col-md-6 px-0 areaTable">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <span className="labelMargin"> <Checkbox  /></span> 
                                                                Data Entry 
                                                            <span className="areaCount">1/5</span>
                                                        </div>
                                                        <div class="btn-group">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src="assets/lib/images/home/sorting.svg" />
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                           
                                                            <a class="dropdown-item" href="javascript:void(0)"> Sort A - Z </a>
                                                                <a class="dropdown-item" href="javascript:void(0)"> Sort Z - A </a>
                                                                <div className="areaSearch">                                        
                                                                <input type="search" placeholder="Search..." />   
                                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {
                                                 users?.map(user=>{
                                                    return(
                                                        <>
                                                            <tr>
                                                                <td className={isUserDisabled(user.area)? "envent-none":""} > 
                                                                <FormControlLabel   
                                                                disabled={isUserDisabled(user.area)} 
                                                                checked={userSelected.some(x=> x._id == user._id )}   
                                                                control={<Checkbox onChange={(e)=>{selectDataEntryUsers(e.target.checked,user ) }} 
                                                                disabled={isUserDisabled(user.area)} 
                                                                />} label={user.name} />
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                 })
                                            }                                           
                                                           
                                        </tbody>
                                    </Table>
                                </div>
                            }
                             {/* =========================================================== Data Approver ================================================= */}
                                <div className="col-md-6 pl-0 areaTable">
                                    <Table striped bordered hover className="assignTable">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            { formTypeApprove?"Data Approver":"Data Entry"} 
                                                        </div>
                                                        <div class="btn-group">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src="assets/lib/images/home/sorting.svg" />
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderUser(true)}} > Sort A - Z </a>
                                                            <a class="dropdown-item" href="javascript:void(0)" onClick={()=>{setSortOrderUser(false)}} > Sort Z - A </a>
                                                                <div className="areaSearch">
                                                                    <input value={userSearchValue} onChange={(e)=> setUserSearchValue(e.target.value) } type="search" placeholder="Search..." />   
                                                                    <img className="search-imgs" src="assets/lib/images/manage-form/search.svg" />                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <RadioGroup className="atBody" row aria-labelledby="demo-row-radio-buttons-group-label" name="assign-radio">
                                        <tbody className="w-100 d-inline-table">
                                            { formTypeApprove &&
                                                approvers?.map(user=>{
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <FormControlLabel  name="radio" checked={approver._id == user._id} onChange={()=>setApprover(user)}  control={<Radio />} label={user.name} />
                                                            </td>                                                
                                                        </tr> 
                                                    )
                                                })
                                                
                                            }  
                                            {
                                                !formTypeApprove && 
                                                users?.filter( usr=> usr.area.some(ar=> finalSelectedAreas.includes(ar.area_code[0]) )  ).filter(x=> x.name?.toLowerCase().includes(userSearchValue.toLowerCase())).sort((a,b)=> sortOrderUser?a.name.localeCompare(b.name):b.name.localeCompare(a.name))?.map(user=>{
                                                    return (
                                                        <tr onClick={()=>console.log(user)} >
                                                            <td>
                                                                <FormControlLabel
                                                                className={isUserDisabled(user.area) && !formTypeApprove? "envent-none":"" } 
                                                                disabled={!formTypeApprove && isUserDisabled(user.area) }  
                                                                name="radio" 
                                                                checked={approver._id == user._id} 
                                                                onChange={()=>setApprover(user)}  
                                                                control={<Radio />}
                                                                label={user.name} />
                                                            </td>                                                
                                                        </tr> 
                                                    )
                                                })
                                                

                                            }    
                                            {console.log('users',users,approver)}                                                                          
                                        </tbody>
                                        </RadioGroup> 
                                    </Table>
                                </div>
                                
                            </div> 
                            <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3">  
                                <Button disabled={formTypeApprove? userSelected.length==0 || checkedAreaForAssign.length==0 || approver._id == undefined : checkedAreaForAssign.length==0 || approver._id == undefined} variant="contained" className="mb-0 text-capitalize" onClick={()=>assignval()} > Assign <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                            </div>                       
                        </div>

                        <div className="assignedList">
                            <div className="row m-0">
                                <div className="col-md-12">
                                    <p class="list_number d-flex align-items-center">
                                        Assigned List 
                                        <span class="total_num">{assignTableData.length}</span>
                                        {
                                           <button disabled={ selectedTableRows.length > 0 ? false:true}  onClick={()=>{deleteAssignedAreas()}} class="delete-icons border-0 bg-transparent"><img src="assets/lib/images/manage-form/trashDlt.svg"  /></button>
                                        }
                                       
                                    </p>
                                    
                                    <FixedHeaderStory
                                        fixedHeader
                                        clearSelectedRows={toggledClearRows}
                                        fixedHeaderScrollHeight="300px"
                                        columns={columns}
                                        data={assignTableData}
                                        selectableRows={true}
                                        onSelectedRowsChange={handleTable}
                                        persistTableHead
                                        noDataComponent="No Data"
                                        className="assignedList"
                                    />                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div className="row align-items-center btn_saveNext justify-content-center mx-0 mt-3 mb-3">  
                                <Button variant="contained" disabled={assignTableData.length==0} onClick={()=>setAssignUsersToForm()} className="mb-0 text-capitalize" data-dismiss="modal"> Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                        </div>                        
                    </div>
                    </div>
                </div>
                </div>
            }
            {/* ================================================ Assign Users End =================================================== */}


            
            {/* ===================================================== Copy Start ====================================================== */}
                {/* <div className="modal fade timePeriodPopup selectArea copyModal-manage" id="copymodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                    <ValidatorForm
                className="main_react_form w-100"
                ref={form} onSubmit={submitCopyForm}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <h5 className="modal-title" id="exampleModalLabel">Copy Form</h5>                                
                                <button type="button" id="closeCopy" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
          
                            <div className="row align-items-center ">
                                <div className="col-md-12 mb-4">
                                    <div className="form-group mb-0 inputform_style">
                                        <label> Source Form</label>
                                        <div className="mat-form-field-wrapper">                                    
                                            <TextField
                                            value={copyForm.target}
                                           style={{opacity:0.7, cursor:'not-allowed'}}
                                            dissabled={true}
                                            />   
                                        </div>
                                    </div>
                                </div>   
                                <div className="col-md-12">
                                    <div className="form-group mb-0 inputform_style">
                                        <label> Target Form</label>
                                        <div className="mat-form-field-wrapper">                                    
                                            <TextValidator
                                            className="w-100"
                                            value={copyForm.copy}
                                            onChange={(e)=>setCopyForm({...copyForm, copy:e.target.value})}
                                            autoComplete="off"
                                            validators={['required']} errorMessages={['This field is required']}
                                            />   
                                        </div>
                                    </div>
                                </div>        
                            </div>
                
                            </div>
                            <div class="modal-footer">                           
                                <div className="row align-items-center btn_saveNext justify-content-center mx-0 mfBtn">  
                                    <Button variant="contained" type="sumit">  Ok <img src="assets/lib/images/home/arrow_right.svg" /></Button>                           
                             
                                </div>                    
                            </div>
                        </div>
                        </ValidatorForm>
                    </div>
                </div> */}
            {/* ===================================================== Copy End ====================================================== */}
            
           
        </>


    );


}


export default ManageFormModal