import React, { Component } from "react";
import "./generatereport.css";
import * as jsx from "./generatereport.module.jsx";
import * as $ from "jquery";
import moment from 'moment';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constants from "../../../Config/app.constants";
import AdminService from "../../../services/admin.service";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import dayjs from "dayjs";

class Generatereport extends Component {
  arr = ["dxgf"];

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      searchText: "",
      setScrollableModal: false,
      // mainData: [],
      isEdit: false,
      disabledApply: true,
      disabledDownload: true,
      scrollableModal: false,
      disaaprovest: false,
      publishModal: false,
      deleteApprovedData: false,
      disscrollableModal: false,
      editModeId: null,
      modal: false,
      remarkdata: "",
      loader: false,
      dataLimit: 10,
      disabled: "true",
      dataStatus: 2,
      indicatorData: [],
      fromDt: "",
      fromTo: "",
      statusData: [
        { label: "Pending", value: 2 },
        { label: "Approved", value: 1 },
        { label: "Disapproved", value: 3 },
      ],
      defOptions: [
        // {"label":"User1", "value":1},
        // {"label":"User2", "value":2},
        // {"label":"User3", "value":3}
      ],
      selectedUsers: [],
    };
    // this.globalState = stateUtilityInstance.default;
  }

  onChange = async (value, event) => {
    await this.setState({ disabledApply: false });
    await this.setState({ disabledDownload: true });

    if (event.action === "select-option" && event.option.value === "*") {
      let stp = [{ label: "All", value: "*" }, ...this.state.defOptions];

      this.setState({ selectedUsers: stp });
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState({ selectedUsers: [] });
    } else if (event.action === "deselect-option") {
      this.setState({
        selectedUsers: value.filter(
          (o) => o.value !== event.option.value && o.value !== "*"
        ),
      });
    } else if (value.length === this.state.defOptions.length) {
      this.setState({
        selectedUsers: [{ label: "All", value: "*" }, ...this.state.defOptions],
      });
    } else {
      this.setState({
        selectedUsers: [...this.state.selectedUsers, event.option],
      });
      // this.selectedOptionsTP[sector+iuid].push(event.option);
    }
  };

  getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All selected`;
    } else if (value && Array.isArray(value) && value?.length != 0) {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    } else if (value?.length == 0) {
      return `${placeholderButtonLabel}`;
    } else {
      console.log("Sudhanshu value:", value);
      return `${placeholderButtonLabel}: 1 selected`;
    }
  }

  // [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  handleDate = async (newValue) => {
    // $('.apply').prop('disabled', false);
    // // $(".apply").removeAttr("disabled");
    // $('.download').prop('disabled', true);

    await this.setState({ disabledApply: false });
    await this.setState({ disabledDownload: true });
    console.log("state---", this.state.disabledApply);
    this.setState({ ...this.state, fromDt: newValue });
  };

  handleDate1 = async (newValue) => {
    // console.log("newValue",newValue, newValue.getMinutes())
    
    // $('.apply').prop('disabled', false);
    // $('.download').prop('disabled', true);
    await this.setState({ disabledApply: false });
    await this.setState({ disabledDownload: true });
    this.setState({ ...this.state, fromTo: newValue });
  };

  sendStatus = (e) => {
    this.state.dataStatus = e;
    this.getData(e);
  };

  componentDidMount() {
    // this.getData(this.state.dataStatus);
    this.getUser();
    // this.getAllIndicators();
    AdminService.checkToken();
  }

  render() {
    return jsx.default.bind(this)();
  }

  // update data limit===================//

  updateDataLimit = (e) => {
    this.setState({ dataLimit: e.target.value });
    this.getData(this.state.dataStatus);
  };

  getDataByStatus = (e) => {
    console.log("data-----" + e.target.value);
    const val = e.target.value;
    this.setState({ dataStatus: val });
    console.log("------aa--" + val);
    console.log("data-----" + e.target.value);
    console.log("state--" + this.state.dataStatus);

    this.getData(e.target.value);
  };

  // filter table and get data====================//

  onSearch = (val) => {
    this.setState({ searchText: val });
    let d = this.state.data;
    let fd = null;
    if (val == "") {
      fd = d;
    } else {
      fd = d.filter(
        (item) =>
          (item?.area_name &&
            item?.area_name["en"].toLowerCase().includes(val.toLowerCase())) ||
          (item?.source &&
            item?.source.toLowerCase().includes(val.toLowerCase())) ||
          (item?.value &&
            item?.value.toLowerCase().includes(val.toLowerCase())) ||
          (item?.time_period &&
            item?.time_period.toLowerCase().includes(val.toLowerCase())) ||
          // || (item.userData.name && item.userData.name.toLowerCase().includes(val.toLowerCase()))
          (item?.indicator &&
            item?.indicator["en"].toLowerCase().includes(val.toLowerCase())) ||
          (item?.unit &&
            item?.unit["en"].toLowerCase().includes(val.toLowerCase())) ||
          (item?.subgroup &&
            item?.subgroup["en"].toLowerCase().includes(val.toLowerCase()))
      );
    }
    this.setState({ filteredData: fd });
    console.log(
      "This is search of generate report----->",
      this.state.filteredData
    );
  };

  columns = [
    // {
    //     name: '#',
    //     selector: (row, index) => row.index+1,
    //     sortable: false,
    //     width: "50px"
    // },
    {
      name: "Area ID",
      selector: (row) => row.area_code,
      sortable: false,
      width: "100px",
    },

    {
      name: "Area Name",
      selector: (row) => row?.area_name?.en,
      sortable: true,
      width: "170px",
    },
    {
      name: "Time Period",
      selector: (row) => row.time_period,
      sortable: true,
      width: "170px",
    },
    {
      name: "Source",
      selector: (row) => (
        <Tooltip title={row?.source} placement="top">
          <p>{row?.source}</p>
        </Tooltip>
      ),
      sortable: true,
      minWidth: "250px",
    },

    {
      name: "Indicator",
      selector: (row) => row?.indicator?.en,
      sortable: true,
      width: "200px",
    },
    {
      name: "Unit",
      selector: (row) => row?.unit?.en,
      sortable: true,
      width: "120px",
    },

    {
      name: "Subgroup",
      selector: (row) => row?.subgroup?.en,
      sortable: true,
      width: "120px",
    },
    {
      name: "Data Value",
      selector: (row) => row?.value,
      sortable: true,
      width: "120px",
    },

    {
      name: "Created By",
      selector: (row) => row?.createdBy,
      sortable: true,
      width: "120px",
    },
    {
      name: "Created At",
      selector: (row) => row?.createdAt,
      sortable: true,
      width: "120px",
    },

    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      width: "120px",
    },
  ];

  openViewModal = async (e) => {
    // if(this.state.scrollableModal==false){
    //     this.setState({scrollableModal:true})
    //     this.setState({remarkdata:e})
    // }
    // else{

    //     this.setState({scrollableModal:false})
    // }

    swal({
      title: "REASON OF DISAPPROVAL",
      text: e,
    });
  };

  cancelModal = async (e) => {
    if (this.state.disaaprovest == false) {
      this.setState({ disaaprovest: true });
      this.setState({ remarkdata: e });
    } else {
      this.setState({ disaaprovest: false });
    }
  };

  publishAll = (e) => {
    console.log("length++--" + this.state.filteredData);
    if (this.state.data == "") {
      toast.error("No record found to approve");
    } else {
      if (this.state.publishModal == false) {
        this.setState({ publishModal: true });
        this.setState({ remarkdata: e });
      } else {
        this.setState({ publishModal: false });
      }
    }
  };

  deleteApproved = (e, value) => {
    if (this.state.deleteApprovedData == false) {
      this.setState({ deleteApprovedData: true });
      this.setState({ remarkdata: e });
    } else {
      this.setState({ deleteApprovedData: false });
    }
  };

  getIsoDate1 = (date) => {
    let splitDate1 = date.split("/");
    console.log("splitdate", splitDate1);
    let newDate = new Date(
      parseInt(splitDate1[2]),
      parseInt(splitDate1[0]) - 1,
      parseInt(splitDate1[1]) + 1
    );

    newDate.setUTCHours(0, 0, 0, 0);

    return newDate.toISOString();
  };

  getData = async (dateFrom, dateTo, user) => {
    let userData = [];
    user = user.filter(x=> x.value != '*');
    user.forEach((element) => {
      userData.push(element.value);
    });

    // console.log("date--"+dayjs(this.state.fromDt).format('YYYY-MM-DDTHH:mm:ss[.000Z]'))
    let dfrom = dayjs(dateFrom).format("YYYY-MM-DDTHH:mm:ss[.000Z]");
    let dto = dayjs(dateTo).format("YYYY-MM-DDTHH:mm:ss[.000Z]");

    this.setState({ loader: true });
    const dataToBeSend = {
      fromDate: dayjs(dateFrom).format("YYYY-MM-DDTHH:mm:ss[.000Z]"),
      toDate: dayjs(dateTo).format("YYYY-MM-DDTHH:mm:ss[.000Z]"),
      users: userData,
    };
    this.setState({ loader: true });
    
   

    AdminService.httpPost(
      "/data-retrieval/data/get-data-by-period",
      dataToBeSend
    )
      .then(async (res) => {
        if (res) {
          this.setState({ loader: false });
          this.setState({ disabled: "false" });
          let d = res.data;
          d.forEach((ele, index) => {
            ele.index = index;
          });
          console.log("res---" + d);
          this.setState({ filteredData: d, data: d });

          if (d.length > 0) {
            await this.setState({ disabledApply: true });
            await this.setState({ disabledDownload: false });
          } else {
            await this.setState({ disabledApply: false });
            await this.setState({ disabledDownload: true });
          }
          console.log("length----", d.length);
          // $('.apply').prop('disabled', false);
          // $('.download').prop('disabled', true);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  getUser = async () => {
    console.log("------------------------------");
    this.setState({ loader: false });

    this.setState({ loader: false });

    AdminService.httpGet("/data-retrieval/users/getUsersForReport")
      .then((res) => {
        if (res) {
          this.setState({ loader: false });

          let arr = [];
          let d = res.data.records;

          d.forEach((element) => {
            console.log("name" + element.name);
            arr.push({ label: element.name, value: element._id });
          });
          console.log("res---" + arr);

          this.setState({ defOptions: arr });
          console.log("def--------" + this.state.defOptions);
          // this.setState({filteredData: d, data:d})

          //    this.state.defOptions= [
          //         {"label":"User1", "value":1}
          //         // {"label":"User2", "value":2},
          //         // {"label":"User3", "value":3}
          //     ],
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  getDate = async () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const currentDate =
      today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    const todaysDate =
      String(currentYear) +
      "-" +
      String(currentMonth) +
      "-" +
      String(currentDate);
    return todaysDate;
  };

  downloadCSV = ({ data, fileType }) => {
    const headersForAllData = [
      { label: "Area Code", key: "area_code" },
      { label: "Area Name", key: "area_name" },
      { label: "Source", key: "source" },
      { label: "Indicator", key: "indicator" },
      { label: "Unit", key: "unit" },
      { label: "Subgroup", key: "subgroup" },
      { label: "Time Period", key: "time_period" },
      { label: "Data Value", key: "value" },
      { label: "Status", key: "status" },
      { label: "Footnote", key: "footnote" },
    ];

    const headers = headersForAllData
      .map((header) => `"${header.label}"`)
      .join(",");

    const csv = data.reduce((acc, row) => {
      const rowValues = headersForAllData
        .map((header) => {
          const key = header.key;
          let value = "";
          if (key == "area_name") {
            value = row[key] && row[key]?.en ? `"${row[key]?.en}"` : "";
          } else if (key == "indicator") {
            value = row[key] && row[key]?.en ? `"${row[key]?.en}"` : "";
          } else if (key == "subgroup") {
            value = row[key] && row[key]?.en ? `"${row[key]?.en}"` : "";
          } else if (key == "unit") {
            value = row[key] && row[key]?.en ? `"${row[key]?.en}"` : "";
          } else {
            value = row[key] ? `"${row[key]}"` : "";
          }
          return value;
        })
        .join(",");
      return acc + rowValues + "\r\n";
    }, headers + "\r\n");

    const blob = new Blob([csv], { type: fileType });

    let currentDate = new Date();

    var year = currentDate.getFullYear();
    var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    var day = ("0" + currentDate.getDate()).slice(-2);

    const a = document.createElement("a");
    a.download = "GenerateReport_" + year + "_" + month + "_" + day;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  downloadCSVFromJson = () => {
    const { filteredData } = this.state;
    const fileType = "text/csv";
    this.downloadCSV({ data: filteredData, fileType: fileType });
  };

  // fetchData = async (dateFrom, dateTo, user) => {

  //     let userData = [];
  //     user.forEach(element => {
  //         userData.push(element.value)
  //     });

  //     console.log("date--" + dayjs(this.state.fromDt).format('YYYY-MM-DDTHH:mm:ss[.000Z]'))
  //     this.setState({ loader: true })
  //     const dataToBeSend = {

  //         // "fromDate":"2022-12-15T00:00:00.000Z",
  //         // "toDate":"2023-01-03T18:30:00.000Z",
  //         // "users":["618a57dcd7854836b1c28ac2"]

  //         "fromDate": dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss[.000Z]'),
  //         "toDate": dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss[.000Z]'),
  //         "users": userData

  //     }

  //     await AdminService.httpPost('/data-retrieval/data/download-data-by-period', dataToBeSend).then((res) => {

  //         if (res) {

  //             let d = res;

  //             let filepath = constants.logFilePath + d.filepath;

  //             console.log("filepath:" + filepath);
  //             let filename = d.filepath.replace(/\.[^/.]+$/, "");

  //             let a = document.createElement("a");
  //             a.href = filepath;
  //             a.download = 'Report_Data' + this.getDate() + '.csv';
  //             // a.download = 'Report' + userData + dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss[.000Z]') +"and"+dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss[.000Z]') +'.csv';

  //             // WMIS_Report_Data_20230104
  //             document.body.appendChild(a);
  //             setTimeout(() => {
  //                 a.click();

  //             }, 1000);
  //             this.setState({ loader: false })
  //             document.body.removeChild(a);

  //         }

  //     }).catch((err) => {

  //         console.log(err)
  //     })

  // }

  approveData = (e, value) => {
    this.setState({ loader: false });
    swal({
      title: "Are you sure you want to approve this data?",
      // text: "Once Approved, you will not be able to disapprove this generatereport!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const dataToBeSend = {
          value: value,
          status: 1,
        };

        AdminService.httpPut("/data-import/data/approvedata/" + e, dataToBeSend)
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res.data;
              this.setState({ filteredData: d, data: d });
              this.getData(this.state.dataStatus);
              if (res.message == "Data approved successfully!") {
                toast.success("Data approved successfully");
                // this.openAddModal();
                // this.getData();
              } else {
                toast.error("Failed to approve data");
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  publishAllData = (e) => {
    this.setState({ loader: true });
    // const dataToBeSend = {
    //     value: value,
    //     status: 1

    // }

    AdminService.httpPost("/data-import/data/publish-all", "")
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;
          this.setState({ filteredData: d, data: d });
          this.getData(this.state.dataStatus);
          if (res.message == "All data approved successfully!") {
            toast.success("All data approved successfully");
            // this.openAddModal();
            // this.getData();
          } else {
            toast.error("Failed to approve data");
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  disapproveData = (e, value) => {
    // console.log("e--" + e);
    // console.log("value--" + value);

    this.setState({ loader: true });
    const dataToBeSend = {
      data_id: e,
      remark: value,
      status: 3,
    };

    AdminService.httpPost(
      "/data-import/data/reject-row-data-approve",
      dataToBeSend
    )
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;
          this.setState({ filteredData: d, data: d });
          this.getData(this.state.dataStatus);
          if (res.message == "Data disapproved successfully!") {
            toast.success("Data disapproved successfully");
            // this.openAddModal();
            this.getData();
          } else {
            toast.error("Failed to disapprove data");
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  enableDis = (e, value) => {
    console.log("e--" + e);
    if (value == 0) {
      value = 1;
    } else {
      value = 0;
    }

    // this.setState({loader:true})
    const dataToBeSend = {
      show: value,
    };

    AdminService.httpPut("/data-import/data/approvedata/" + e, dataToBeSend)
      .then((res) => {
        if (res) {
          this.setState({ loader: false });
          let d = res.data;
          // this.setState({filteredData: d, data:d})
          this.getData(this.state.dataStatus);

          if (res.message == "Status updated successfully!") {
            toast.success("Status updated successfully");
            // this.openAddModal();
            // this.getData();
          } else {
            toast.error("Failed to update status ");
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  deldata = (e, value) => {
    this.setState({ loader: false });
    swal({
      title: "Are you sure you want to delete?",
      // text: "Once deleted, you will not be able to recover this generatereport",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const dataToBeSend = {
          data_id: e,
        };

        AdminService.httpPost(
          "/data-import/data/del-data-approved",
          dataToBeSend
        )
          .then((res) => {
            if (res) {
              this.setState({ loader: false });
              let d = res.data;
              this.setState({ filteredData: d, data: d });
              this.getData(this.state.dataStatus);

              if (res.message == "Data deleted successfully") {
                toast.success("Data deleted successfully");
                // this.openAddModal();
                // this.getData();
              } else {
                toast.error("Failed to delete data");
              }
            }
          })
          .catch((err) => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    });
  };

  // get all indicators===================//

  // getAllIndicators = async () => {

  //     AdminService.httpGet('/data-retrieval/ius/get-ius-maping').then((res)=>{
  //                 if(res){

  //                     let d = res.data;
  //                     console.log(d)

  //                     let bindData = [];
  //                     d.forEach(item => {

  //                         bindData.push({label: item.name, value: item._id})

  //                     });
  //                     this.setState({indicatorData: bindData})

  //                 }

  //     }).catch((err)=>{

  //         console.log(err)
  //     });

  // }

  // open add modal=====================//

  openAddModal = () => {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
      this.setState({ isEdit: false });
      this.setState({ editModeId: null });
    }
  };
}

export default Generatereport;
