import { Component } from 'react'
import './style.css';
import * as jsx from './index.module';
import { connect } from "react-redux";
import $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import AdminService from '../../../services/admin.service';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import datasearchcomp from "../../../Config/data-search-component.json";

class DataSearchComponent extends Component {
    constructor() {
        super()
        this.state = {
            loader: false,
            disasterData: [],
            fDisasterData: [],
            selDisaster: {},
            selStage: [],
            selStagesEn: [],
            mainAreaData: [],
            areaList: [],
            callData: [],
            indicatorData: [],
            fIndicatorData: [],
            selIUSdata: {},
            flatLevel: [],
            areaLevel: [],
            checked: {},
            key: 'disaster',
            results: [],
            searchText: '',
            iuIds: [],
            iusIds: [], 
            resultsData: [],
            disasterCodes: [],
            validStages: [],
            validAreaCodes: [],
            disasterIUSList: {}
        }
        this.handleSelect = this.handletabSelect.bind(this);
        this.steps = {
            'disaster': 1,
            'stage': 2,
            'area': 3,
            'indicator': 4,
            'viewdata': 5
        }
    }

    resetSelection = () => {
        this.setState({
            loader: false,
            fDisasterData: this.state.disasterData,
            selDisaster: {},
            selStage: [],
            selStagesEn: [],
            areaList: [],
            areaLevel: [],
            fIndicatorData: this.state.indicatorData,
            selIUSdata: {},
            checked: {},
            key: 'disaster',
            results: [],
            searchText: '',
            iuIds: [],
            iusIds: [],
            resultsData: [],
            validStages: [],
            validAreaCodes: [],
            disasterIUSList: {}
        });
    }

    onSearch = (srchTxt) => {
        let d = this.state.disasterData;
        let fd = [];
        let fInd = [];
        if(srchTxt?.length > 0) {
            fd = d.filter(x => x.title?.en?.toLowerCase().includes(srchTxt.toLowerCase()));

            let fSectors = this.state.indicatorData?.filter(x => !!x?.level_name?.[this.props.lang]?.toLowerCase().
                                                                                includes(srchTxt.toLowerCase()));
            let fNotSectors = this.state.indicatorData?.filter(x => !(x?.level_name?.[this.props.lang]?.toLowerCase().
                                                                                includes(srchTxt.toLowerCase())));                                                                                
            fNotSectors?.forEach(sectorData => {
                let iuList = sectorData.iusList?.filter(iu => iu?.name?.[this.props.lang]?.toLowerCase().
                                                                                includes(srchTxt.toLowerCase()));
                if(iuList?.length > 0) {
                    let s = JSON.parse(JSON.stringify(sectorData));
                    s.iusList = JSON.parse(JSON.stringify(iuList));
                    fSectors.push(s);
                }
            });
            
            this.setState({
                searchText: srchTxt,
                fDisasterData: fd,
                fIndicatorData: fSectors
            });
        } else {
            this.setState({
                searchText: '',
                fDisasterData: d,
                fIndicatorData: this.state.indicatorData
            });
        }

    }

    isDisasterAvlbl(dCode) {
        let x = this.state.disasterCodes?.filter((code)=> code == dCode);
        return x?.length > 0;
    }

    handletabSelect(key) {
        let k = key;
        if (Object.keys(this.state.selIUSdata)?.length == 0) {
            k = 'indicator'
        }
        if (this.state.areaList?.length == 0) {
            k = 'area'
        }
        if (this.state.selStage?.length == 0) {
            k = 'stage'
        }
        if (Object.keys(this.state.selDisaster)?.length == 0) {
            k = 'disaster';
        }
        if (this.steps[key] < this.steps[k]) {
            k = key;
        }
        if(k == 'stage') {
            this.setState({ loader: true });
            let dCodes = [];
            Object.entries(this.state.selDisaster).forEach(([key, value]) => {
                if(key != '*') {
                    if(key == 'none') {
                        dCodes.push(key);
                    } else {
                        dCodes.push(value.disaster_code);
                        // AdminService.httpNoAuthGet('/data-retrieval/data/sector-wise-data/'+value.disaster_code).then((res) => {
                        //     if (res) {
                        //         let list = this.state.disasterIUSList;
                        //         list[value.title?.[this.props.lang]] = res?.sectorIus?.[0]?.iusList;
                        //         console.log("sectorIUS", res?.sectorIus?.[0]?.iusList);
                        //         // this.setState({ indicatorData: res.sectorIus, fIndicatorData: res.sectorIus });
                        //     }
                        // })
                        // .catch((err) => {
                        //     // this.setState({ loader: false });
                        //     console.log(err)
                        // });
                        //sector-wise-data
                    }                    
                }                
            });
            let dataToSend = {
                // area: this.state.areaList,
                disasterCode: dCodes,
                // formname: this.state.selStage?.filter(x => x != '*').map(y=> y == 'Baseline Data'? 'Other': y)
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                    console.log("res", res);
                    let validStages = [];
                    res.data?.[0]?.name.forEach(form => {
                        validStages.push(form.en);
                    })
                    this.setState({ validStages: validStages, loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if(k == 'area') {
            $('.dcTreeView > li > span.caret').parent('li').children('.nested').addClass('active')
            $('.dcTreeView > li > span.caret').addClass('caret-down');
            this.setState({ loader: true });
            let dCodes = [];
            Object.entries(this.state.selDisaster).forEach(([key, value]) => {
                if(key != '*') {
                    if(key == 'none') {
                        dCodes.push(key);
                    } else {
                        dCodes.push(value.disaster_code);
                    }                    
                }                
            });
            
            let dataToSend = {
                // area: this.state.areaList,
                disasterCode: dCodes,
                formname: this.state.selStagesEn?.filter(x => x != '*').map(y=> y == 'Baseline Data'? 'Other': y)
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                    console.log("res", res);
                    let validAreaCodes = res.data?.[0]?.area_code;                    
                    this.setState({ validAreaCodes: validAreaCodes, loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if(k == 'indicator') {
            
            //get iu, ius list which have data
            this.setState({ loader: true });
            let dCodes = [];
            Object.entries(this.state.selDisaster).forEach(([key, value]) => {
                if(key != '*') {
                    if(key == 'none') {
                        dCodes.push(key);
                    } else {
                        dCodes.push(value.disaster_code);
                    }                    
                }                
            });
            let dataToSend = {
                area: this.state.areaList,
                disasterCode: dCodes,
                formname: this.state.selStagesEn?.filter(x => x != '*').map(y=> y == 'Baseline Data'? 'Other': y)
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
                if (res) {
                    console.log("res", res);
                    let iuIds = res.data?.[0]?.iu_id;
                    // res.data?.[0]?.iu_id.forEach(id => {
                    //     iuIds.push(id[0]);
                    // })
                    this.setState({ iuIds: iuIds, iusIds: res.data?.[0]?.ius_id, loader: false });
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err)
            });
        } else if (k == 'viewdata') {
            this.setState({ loader: true });
            let dCodes = [];
            let iuIds = [];
            let iusIds = [];
            Object.entries(this.state.selDisaster).forEach(([key, value]) => {
                if(key != '*'){
                    if(key == 'none') {
                        dCodes.push(key);
                    } else {
                        dCodes.push(value.disaster_code);
                    }
                }                
            });
            Object.entries(this.state.selIUSdata).forEach(([key, data]) => {
                if(key != '*' && !key.endsWith('#')) {
                    iuIds.push(data.iu_id);
                    iusIds.push(data.ius_id);
                }                
            })

            let dataToSend = {
                "iu_id": iuIds,
                "ius_id": iusIds,
                "disasterCode": dCodes,
                "area": this.state.areaList,
                "formname": this.state.selStagesEn?.filter(x => x != '*').map(y=> y == 'Baseline Data'? 'Other': y)
            };
            AdminService.httpUserPost('/data-retrieval/data/get-iu-chart-data', dataToSend).then((res) => {
                let data = res?.data;

                console.log("This is data in iu chart--", data)

                let results = [];


                let ar = [];

                this.state.selStage?.forEach((item) => {
                    ar.push(item);
                })

                let index = 0;
                data.forEach(indicatorData => {
                    console.log("This is indicatorData in iu chart--", indicatorData)
                    indicatorData?.data?.forEach(bySG => {
                        bySG?.forEach(d => {
                            let dis = d?.disasterDetails?.title?.[this.props.lang] ? d?.disasterDetails?.title?.[this.props.lang] : d?.disasterDetails?.title?.en;
                            dis = dis == null || dis==''? datasearchcomp?.Forms[3][this.props.lang]: dis;
                            let sta = d?.surveyDetails?.name?.[this.props.lang] ? d?.surveyDetails?.name?.[this.props.lang] : d?.surveyDetails?.name?.en;
                            sta = sta == null || sta ==''? datasearchcomp?.Forms[3][this.props.lang]: sta;
                            // let dis = Object.entries(this.state.selDisaster).filter(([key, x]) => x.disaster_code == d.disasterCode);
                            results.push({
                                id: index++,
                                disaster: dis,
                                stage: sta,
                                area: d?.areaDetails?.name?.[this.props.lang] ? d?.areaDetails?.name?.[this.props.lang] : d?.areaDetails?.name?.en,
                                area_code: d?.areaDetails?.area_code,
                                area_level: d?.areaDetails?.level,
                                parent_id: d?.areaDetails?.parent_id,
                                indicator: indicatorData?.name?.[this.props.lang] ? indicatorData?.name?.[this.props.lang] : indicatorData?.name?.en,
                                unit: indicatorData?.unit?.[this.props.lang] ? indicatorData?.unit?.[this.props.lang] : indicatorData?.unit?.en,
                                // subgroup: d?.subgroupDetails?.[0]?.name?.[this.props.lang] ? d?.subgroupDetails?.[0]?.name?.[this.props.lang] : d?.subgroupDetails?.[0]?.name?.en,
                                subgroup:Array.isArray(d?.subgroupDetails)? d?.subgroupDetails?.[0]?.name?.[this.props.lang] ? d?.subgroupDetails?.[0]?.name?.[this.props.lang] : d?.subgroupDetails?.[0]?.name?.en : 
                                d?.subgroupDetails?.name?.[this.props.lang] ? d?.subgroupDetails?.name?.[this.props.lang] : d?.subgroupDetails?.name?.en,
                                timeperiod: d?.time_period?.start_time_period,
                                datavalue: (+d?.value),
                                source: d?.source_lang?.[this.props.lang] ? d?.source_lang?.[this.props.lang] : d?.source_lang?.en,
                                element: d,
                            })
                        })
                    })

                })
                this.setState({ results: results, loader: false, resultsData: data });
            }).catch((err) => {
                this.setState({ loader: false });
                console.log(err);
            });
        } 
        this.setState({ key: k });
        this.onSearch('');
    }

    componentDidMount() {

        this.getData();

        $(document).on('click', '.caret', function () {
            $(this).parent('li').children('.nested').toggleClass('active')
            $(this).toggleClass('caret-down')
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.lang) {
            this.handletabSelect(this.state.key)
        }
    }

    getData = () => {
        this.setState({ loader: true });
        AdminService.httpNoAuthGet('/data-retrieval/data/sector-wise-without-disaster').then((res) => {
            if (res) {
                this.setState({ indicatorData: res.sectorIus, fIndicatorData: res.sectorIus });
            }
        })
        .catch((err) => {
            this.setState({ loader: false });
            console.log(err)
        });

        let dataToSend = {};
        AdminService.httpUserPost('/data-retrieval/data/get-iu-filter-data', dataToSend).then((res) => {
            if (res) {
                let disasterCodes = res.data?.[0]?.disaster;
                this.setState({ disasterCodes: disasterCodes });
                console.log("disasterCodes", disasterCodes);
            }
        })
        .catch((err) => {
            console.log(err)
        });

        AdminService.httpNoAuthGet('/data-retrieval/disaster/getDisasterData').then((res) => {
            if (res) {
                console.log("This is disaster data res--", res)
                this.setState({ loader: false, disasterData: res?.data, fDisasterData: res?.data, loader: false });
            }
        })
        .catch((err) => {
            this.setState({ loader: false });
            console.log(err)
        });

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {
                let d = res.data;
                let flatLevel = [];
                if (d?.length > 0) {

                    // setFormState({...FormState, areaList:[]})

                    d?.forEach((item) => {

                        if ('ASITLS' == item.area_code) {
                            item.areaSubData = [];
                            item['level'] = 1;
                            item['parent_id'] = '';
                        }

                        var obj = {
                            value: item.area_code,
                            label: item?.name_all?.[this.props.lang],
                            parent: item.parent_id,
                            level: item.level

                        }

                        flatLevel.push(obj)
                    })

                    var data = this.setLevelData(flatLevel);


                    console.log('levelData', data)

                    // setParentAreaData(data);

                    // console.log('assaas', callData, data)
                }

                this.setState({ mainAreaData: d, callData: data, flatLevel: flatLevel });
            }
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }

    areaSelect = (e, node, area_code, parent) => {
        let aList = this.state.areaList;
        let chkd = this.state.checked;
        if (area_code == '*' && e.target.checked) {
            let newA = [];
            node.children.forEach(n => {
                if(this.state.validAreaCodes?.includes(n.value)) {
                    newA.push(n.value);
                    chkd[n.value] = true;
                    if (!aList.includes(n.value)) {
                        aList.push(n.value);
                    }
                }                
            })
            chkd["selectall" + node.level] = true;
        } else if (area_code == '*') {
            let newA = [];
            node.children.forEach(n => {
                newA.push(n.value);
                chkd[n.value] = false;
            })
            aList = aList.filter(x => !newA.includes(x));
            chkd["selectall" + node.level] = false;
        } else if (e.target.checked) {
            if (!aList.includes(area_code)) {
                aList.push(area_code);
            }
            chkd[area_code] = true;
            if (parent?.children?.filter(c => !aList.includes(c.value)).length == 0) {
                chkd["selectall" + parent.level] = true;
            }
            chkd[area_code] = true;
        } else {
            aList = aList.filter(x => x != area_code);
            chkd[area_code] = false;
            chkd["selectall" + parent?.level] = false;
        }

        let aArrs = [];
        aList.forEach(areaCde => {
            let code = areaCde;
            let areaArr = [];
            do {
                let n = this.state.flatLevel.filter(x => x.value == code);

                if (n?.length > 0) {
                    areaArr.push(n[0]?.label);
                    code = n[0]?.parent;
                } else {
                    code = '';
                }

            } while (code != '')
            aArrs.push(areaArr.reverse());
        })
        this.setState({ areaList: aList, 
                        areaLevel: aArrs, 
                        checked: chkd, 
                        selIUSdata: {} });
    }

    rendertree(node, parent = null) {
        if (node) {
            return (
                <li className={node?.children?.length > 0 ? '' : 'nosubgroup'}>
                    {
                        node?.children && node?.children?.length > 0 &&
                        (<span class="caret">
                            <img src="assets/lib/images/arrow.svg" />
                        </span>)
                    }

                    <div className='dcTreeViewLabel '>
                        <div className={this.state.validAreaCodes?.includes(node?.value)?'d-flex align-items-center dataSearchFormList':
                                                                                        'disable-sector d-flex align-items-center dataSearchFormList'}>
                            <input type="checkbox" className='d-block'
                                checked={this.state.checked[node?.value] ? 'checked' : ''}
                                id={node?.value}
                                name={node?.value}
                                onChange={(e) => this.areaSelect(e, node, node?.value, parent)}
                            />
                            <label for={node?.value}>{node?.label}</label>
                        </div>
                        {/* <FormControlLabel
                                    label={node?.label}
                                    control={
                                        <Checkbox
                                            checked={this.state.checked[node?.value]? 'checked': ''}
                                            id={node?.value} 
                                            name={node?.value}
                                            onChange={(e)=> this.areaSelect(e, node, node?.value, parent)}
                                        />
                                    }
                                /> */}
                    </div>
                    {
                        node?.children && node?.children?.length > 0 &&
                        (
                            <ul class="dcTreeViewText nested">
                                <li className="li-before-selectAll">
                                    <div className='d-flex align-items-center dataSearchFormList'>
                                        <input type="checkbox" className='d-block'
                                            checked={this.state.checked["selectall" + node.level] ? 'checked' : ''}
                                            id={"selectall" + node.level}
                                            name={"selectall" + node.level}
                                            onChange={(e) => this.areaSelect(e, node, '*', parent)}
                                        />
                                        {/* <label for={"selectall"+node.level}> Select All</label>  */}
                                        <label for={"selectall" + node.level}>{datasearchcomp.selectall[this.props.lang]}</label>

                                    </div>
                                    {/* <FormControlLabel
                                    label=" Select All"
                                    control={
                                        <Checkbox
                                            checked={this.state.checked["selectall"+node.level]? 'checked': ''} id={"selectall"+node.level} name={"selectall"+node.level}
                                            onChange={(e)=> this.areaSelect(e, node, '*', parent)}                                    
                                        />
                                    }
                                    /> */}
                                </li>
                                {
                                    node?.children?.map(child => {
                                        return this.rendertree(child, node)
                                    })
                                }

                            </ul>
                        )
                    }
                </li>
            );
        } else {
            return (<></>)
        }

    }

    renderselected() {
        return (
            <>
                <div className='disasterSelected_scroll'>
                    <div className='disasterSelected_List'>
                        {/* <h5>Disaster</h5> */}
                        <h5>{datasearchcomp.dstlistContenth4[this.props.lang]}</h5>

                        <ul>
                            {
                                Object.entries(this.state.selDisaster).map(([key, value]) => {
                                    if (key == '*') {
                                        return (<></>);
                                    } else if (key == 'none') {
                                        return (
                                            <li key={'d-none'}>{datasearchcomp.Forms[3][this.props.lang]}</li>
                                        );
                                    } else {
                                        return (
                                            <li key={value._id}>{value?.title?.[this.props.lang]}</li>
                                        );
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className='disasterSelected_List'>
                        {/* <h5>Form</h5> */}
                        <h5>{datasearchcomp.dstlistContentstp2h4[this.props.lang]}</h5>
                        <ul>
                            {
                                this.state.selStage.map(item => {
                                    
                                    if (item == '*') {
                                        return (<></>);
                                    } else {
                                        return (
                                            <li>{item}</li>
                                        );
                                    }

                                })
                            }
                        </ul>
                    </div>
                    <div className='disasterSelected_List'>
                        {/* <h5>Area</h5> */}
                        <h5>{datasearchcomp.dstlistContentstp3h4[this.props.lang]}</h5>
                        {
                            this.state.areaLevel?.map(a => {
                                return (
                                    <ul className='dsl_breadcrumb'>
                                        {
                                            a?.map(x => {
                                                console.log("This is area x--", x)
                                                return (<li>{x}</li>);
                                            })
                                        }

                                    </ul>
                                );
                            })
                        }
                    </div>
                    <div className='disasterSelected_List'>
                        {/* <h5>IUS</h5> */}
                        <h5> {datasearchcomp.dstlistContentstp4h4[this.props.lang]}</h5>
                        <ul>
                            {
                                Object.entries(this.state.selIUSdata)?.map(([key, ius]) => {
                                    return (
                                    !(ius == true) && !key.endsWith('#') &&
                                        <li>{ius.ind_name} <span>| {ius.unit}</span> <span>| {ius.subgroup_name}</span></li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }

    setLevelData = (flat) => {

        var roots = [];

        var all = {};

        flat.forEach(function (item) {

            all[item.value] = item;
        });


        Object.keys(all).forEach(function (value) {
            var item = all[value];
            if (item.parent === null || item.parent === "") {

                roots.push(item);
            } else if (item.parent in all) {
                var p = all[item.parent];
                if (!("children" in p)) {

                    p.children = [];

                }
                p.children.push(item);
            }
        });


        return roots;
    };


    selectDisaster = (e, row) => {
        
        // console.log("Sudhanshu start time:", new Date());
        // if (this.state.selStage?.length > 1 && e.target.checked) {
        //     if (row == '*' || Object.keys(this.state.selDisaster)?.length > 0) {
        //         // toast.error("One disaster can be selected with multiple stages");
        //         toast.error(datasearchcomp?.['toast.error1']?.[this.props.lang]);

        //         return;
        //     }
        // }
      
        if (row == '*' && e.target.checked) {
            let d = this.state.fDisasterData;
            let sd = {};
            for (let i = 0; i < d.length; i++) {
                if(this.isDisasterAvlbl(d[i].disaster_code)){
                    sd[d[i]._id] = d[i]
                };
                
            }
            console.log("dddddddddddddddddddd",d);
            sd['*'] = true;
            sd['none'] = datasearchcomp?.Forms[3][this.props.lang];
            
            this.setState({ selDisaster: sd,
                            selStage: [],
                            selStagesEn: [],
                            validStages: [],
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else if (row == '*') {
            // console.log("Sudhanshu before state update time:", new Date());

            this.setState({ selDisaster: {},
                            selStage: [],
                            selStagesEn: [],
                            validStages: [],
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else if (e.target.checked) {
            let sd = this.state.selDisaster;
            if(row == 'none') {
                sd['none'] = datasearchcomp?.Forms[3][this.props.lang];
            } else {
                sd[row._id] = row;
            }   
            
            let disasters = this.state.disasterCodes?.filter(x=> (x != '' && x != null));         
            
            if (Object.keys(sd)?.length == (disasters?.length+1)) {
                sd['*'] = true;
            }
            console.log("sd....",sd);
            this.setState({ selDisaster: sd,
                            selStage: [],
                            selStagesEn: [],
                            validStages: [],
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else {
            let sd = this.state.selDisaster;
            if(row == 'none') {
                delete sd[row];
            } else {
                delete sd[row._id];
            }
            
            if ('*' in sd) {
                delete sd['*'];
            }
            this.setState({ selDisaster: sd,
                            selStage: [],
                            selStagesEn: [],
                            validStages: [],
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        }

    }

    // selectDisaster = (e, row) => {
    //     if (this.state.selStage?.length > 1 && e.target.checked) {
    //       if (row == "*" || Object.keys(this.state.selDisaster)?.length > 0) {
    //         toast.error(datasearchcomp?.["toast.error1"]?.[this.props.lang]);
    //         return;
    //       }
    //     }
    
    //     const isChecked = e.target.checked;
      
    //     if (row == "*" && isChecked) {
    //       let d = this.state.fDisasterData;
    //       let sd = {};
    //       for (let i = 0; i < d.length; i++) {
    //         if (this.isDisasterAvlbl(d[i].disaster_code)) {
    //           sd[d[i]._id] = d[i];
    //         }
    //       }
    //       sd["*"] = true;
    //       sd["none"] = "Other";
    //       this.setState({ selDisaster: sd });
    //     } else if (row == "*") {
    //       // console.log("Sudhanshu before state update time:", new Date());
    
    //       this.setState({ selDisaster: {} });
    //     } else if (isChecked) {
    //       let sd = this.state.selDisaster;
    //       if (row == "none") {
    //         sd["none"] = "Other";
    //       } else {
    //         sd[row._id] = row;
    //       }
    //       if (
    //         Object.keys(sd)?.length == this.state.fDisasterData?.length + 1 &&
    //         this.state.searchText.length < 1
    //       ) {
    //         sd["*"] = true;
    //       }
    //       this.setState({ selDisaster: sd });
    //     } else {
    //       let sd = this.state.selDisaster;
    //       if (row == "none") {
    //         delete sd[row];
    //       } else {
    //         delete sd[row._id];
    //       }
    
    //       if (Object.keys(sd)?.length == this.state.fDisasterData?.length + 1) {
    
    //         delete sd["*"];
    //       }
         
    //       const isAllChecked = Object.keys(sd).length === this.state.fDisasterData.length + 1;

    //       if (isAllChecked) {
    //         sd['*'] = true;
    //       } else {
    //         delete sd['*'];
    //       }
        
    //       this.setState({ selDisaster: sd }, () => {
    //         const selectAllCheckbox = document.getElementById('disaster-select-all');
    //         if (selectAllCheckbox) {
    //           selectAllCheckbox.checked = isAllChecked;
    //         }
    //       });
          
    //     }
    //   };




    selectStage = (e, val, index=0) => {
        if (Object.keys(this.state.selDisaster)?.length > 1) {
            if (e.target.checked && ((this.state.validStages?.length > 0 && val == '*') || this.state.selStage?.length > 0)) {
                // toast.error("One stage can be selected with multiple disasters");
                toast.error(datasearchcomp?.['toast.error2']?.[this.props.lang]);

                return;
            }
        }

        if (val == '*' && e.target.checked) {
            let stage = [];
            if(Object.keys(this.state.selDisaster)?.includes('none')) {
                stage = ['*', 
                        datasearchcomp?.Forms[3][this.props.lang]
                    ];
            } else {
                stage = ['*'];
            }
            let stageEn = ['Baseline Data'];
            if(this.state.validStages?.includes(datasearchcomp.Forms[0]['en'])) {
                stage.push(datasearchcomp.Forms[0][this.props.lang]);
                stageEn.push(datasearchcomp.Forms[0]['en']);
            }
            if(this.state.validStages?.includes(datasearchcomp.Forms[1]['en'])) {
                stage.push(datasearchcomp.Forms[1][this.props.lang]);
                stageEn.push(datasearchcomp.Forms[1]['en']);
            }
            if(this.state.validStages?.includes(datasearchcomp.Forms[2]['en'])) {
                stage.push(datasearchcomp.Forms[2][this.props.lang]);
                stageEn.push(datasearchcomp.Forms[2]['en']);
            }
            // console.log("this.state.validStages",this.state.validStages)
            this.setState({ selStage: stage, 
                            selStagesEn: stageEn,
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else if (val == '*') {
            this.setState({ selStage: [], 
                            selStagesEn: [],
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else if (e.target.checked) {
            let sd = this.state.selStage;
            let sden = this.state.selStagesEn;
            if (!sd.includes(val)) {
                sd.push(val);
                sden.push(datasearchcomp.Forms[index]['en']);
                console.log("sd?.length",sd)
                if (sd?.length == (this.state.validStages?.length +1)) {
                    sd.push('*')
                }
            }
            
            this.setState({ selStage: sd, 
                            selStagesEn: sden,
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        } else {
            let sd = this.state.selStage;
            let sindex = sd.indexOf(val);
            sd.splice(sindex, 1);

            let sden = this.state.selStagesEn;
            sindex = sden.indexOf(val);
            sden.splice(sindex, 1);

            if (sd?.includes('*')) {
                index = sd.indexOf('*');
                sd.splice(index, 1);
            }
            this.setState({ selStage: sd, 
                            selStagesEn: sden,
                            areaList: [],
                            areaLevel: [],
                            checked: {},
                            selIUSdata: {} });
        }
    }

    selectIUS = (e, row, ind, subgroup) => {
        
        if (row == '*' && e.target.checked) {
            let d = this.state.fIndicatorData;
            let sd = {};
            for (let i = 0; i < d.length; i++) {
                let iu = d[i].iusList?.filter(iu => this.state.iuIds.includes(iu._id));
                for (let k = 0; k < iu?.length; k++) {
                    sd[iu[k]._id + '#'] = true;
                    let sg = iu[k].subgroup?.filter(ius => this.state.iusIds.includes(ius._id));
                    for (let j = 0; j < sg.length; j++) {
                        sd[iu[k]._id + sg[j].name?.[this.props.lang]] = {
                            iu_id: iu[k]._id,
                            ind_name: iu[k].name?.[this.props.lang]?iu[k].name?.[this.props.lang]:iu[k].name?.['en'],
                            unit: iu[k].unit?.[this.props.lang]? iu[k].unit?.[this.props.lang]:iu[k].unit?.['en'],
                            subgroup_name: sg[j].name?.[this.props.lang]?sg[j].name?.[this.props.lang]:sg[j].name?.['en'],
                            ius_id: sg[j]._id
                        };
                    }
                }
            }
            sd['*'] = true;
            console.log("sd", sd);
            this.setState({ selIUSdata: sd });
        } else if (row == '*') {
            this.setState({ selIUSdata: {} });
        } else if (row == '#' && e.target.checked) {
            // let d = this.state.fIndicatorData;
            let sd = this.state.selIUSdata;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id)) {
                    sd[ind._id + ius.name?.[this.props.lang]] = {
                        iu_id: ind._id,
                        ind_name: ind.name?.[this.props.lang]? ind.name?.[this.props.lang]: ind.name?.['en'],
                        unit: ind.unit?.[this.props.lang]?ind.unit?.[this.props.lang]:ind.unit?.['en'],
                        subgroup_name: ius.name?.[this.props.lang]?ius.name?.[this.props.lang]:ius.name?.['en'],
                        ius_id: ius._id
                    };
                }                
            })
            // sd['*'] = true;
            sd[ind._id+'#'] = {
                iu_id: ind._id,
                ind_name: ind.name?.[this.props.lang]?ind.name?.[this.props.lang]:ind.name?.['en'],
                unit: ind.unit?.[this.props.lang]?ind.unit?.[this.props.lang]:ind.unit?.['en'],
                subgroup_name: null,
                ius_id: null
            };
            this.setState({ selIUSdata: sd });
        } else if (row == '#') {
            // this.setState({ selIUSdata: {} });
            // debugger;
            let sd = this.state.selIUSdata;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id)) {
                    delete sd[ind._id + ius.name?.[this.props.lang]];
                }
            })
            // sd['*'] = true;
            delete sd[ind._id+'#'];
            if('*' in sd) {
                delete sd['*'];
            }
            this.setState({ selIUSdata: sd });
        } else if (e.target.checked) {
            let sd = this.state.selIUSdata;
            sd[ind._id + subgroup.name?.[this.props.lang]] = {
                iu_id: ind._id,
                ind_name: ind.name?.[this.props.lang]?ind.name?.[this.props.lang]:ind.name?.['en'],
                unit: ind.unit?.[this.props.lang]?ind.unit?.[this.props.lang]:ind.unit?.['en'],
                subgroup_name: subgroup.name?.[this.props.lang]? subgroup.name?.[this.props.lang]: subgroup.name?.['en'],
                ius_id: subgroup._id
            };
            let flag = true;
            ind?.subgroup?.forEach(ius => {
                if(this.state.iusIds.includes(ius._id) && !((ind._id + ius.name?.[this.props.lang]) in sd) ) {
                    flag = false;
                }
            });
            if(flag) {
                sd[ind._id+'#'] = {
                    iu_id: ind._id,
                    ind_name: ind.name?.[this.props.lang]?ind.name?.[this.props.lang]:ind.name?.['en'],
                    unit: ind.unit?.[this.props.lang]?ind.unit?.[this.props.lang]:ind.unit?.['en'],
                    subgroup_name: null,
                    ius_id: null
                };  
            }
            // if (Object.keys(sd)?.length === this.state.iusIds?.length) {
            //     sd['*'] = true;
            // }
            console.log("sd", sd);            
            this.setState({ selIUSdata: sd });
        } else {
            let sd = this.state.selIUSdata;
            delete sd[ind._id + subgroup.name?.[this.props.lang]];
            if((ind._id+'#') in sd) {
                delete sd[ind._id+'#'];
            }            
            if('*' in sd) {
                delete sd['*'];
            }
            this.setState({ selIUSdata: sd });

            console.log("selIUSdata",sd)
        }

    }

    // componentWillReceiveProps(nextProps) {
    //     // You don't have to do this check first, but it can help prevent an unneeded render
    //     if (nextProps.data !== this.state.filteredData) {
    //       this.setState({ filteredData: nextProps.data, searchText: "" });
    //     }
    // }

    render() {
        return jsx.default.bind(this)();
    }
}

function mapStateToProps(state) {
    return {
        lang: state?.lang?.lang
    }
}

export default connect(mapStateToProps)(DataSearchComponent);
